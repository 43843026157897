import React, { useCallback, useState } from "react";

import { HomeFilled } from "@ant-design/icons";
import { Button, Alert } from "antd";
import { format } from "date-fns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import Card from "../../components/Card";
import Loading from "../../components/Loading";
import api from "../../services/api";
import { notifyError } from "../../utils/notifications";
import { validaCpf } from "../../utils/validations";
import * as S from "./style";
import UsersTable from "./UsersTable";

function ListDefault() {
  const [data, setData] = useState([]);

  const [formSteps, setFormSteps] = useState(0);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [idClient, setIdClient] = useState(0);
  const [nameClient, setNameClient] = useState("");
  const [expiryDateInvoice, setExpiryDateInvoice] = useState(null);
  const [invoiceDelayDate, setInvoiceDelayDate] = useState(null);
  const [collectionInvoiceDate, setCollectionInvoiceDate] = useState(null);
  const [currentValue, setCurrentValue] = useState(null);
  const [clientStatus, setClientStatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "cnpj") setCurrentMask("99.999.999/9999-99");
    if (doc === "cartao") setCurrentMask("9999 9999 9999 9999 999");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);


  const statusTable = (status) => {
    // Possíveis status:
    // -  A=“ATRASOATIVO”;
    // -  D=“CRELIQATIVO”;
    // -  C=“ATRASOQUITADO”;
    // -  O=“CRELIQQUITADO";


    switch (status) {
      case 'A':
        return 'Atraso Ativo';
      case 'D':
        return 'Creliq Ativo';
      case 'C':
        return 'Atraso Quitado';
      case 'O':
        return 'Creliq Quitado';
      default:
        return '';
    }
  };

  const handleSubmit = (value) => {
    setLoadingTable(true);
    setSearchData(false);
    setLoadingBtn(true);
    api
      .get("get_default", {
        client_id: value,
      })
      .then((res) => {

        if (Object.values(res).length === 0 || !res.data[0]) throw new Error("O cliente não possui inadimplencias");

        setData(res.data);
        setInvoiceDelayDate(res.data[0].dataEntradaAtraso);
        setCollectionInvoiceDate(res.data[0].dataEntradaCobranca);
        setExpiryDateInvoice(res.data[0].dataVencimentoFatura);
        setClientStatus(statusTable(res.data[0].status));
        setCurrentValue(res.data[0].valorPrincipal);

        setTimeout(() => {
          setLoadingBtn(false);
        }, 1000);
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
        setLoadingBtn(false);
      });
  };

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "cnpj") text = "do CNPJ";
    if (currentDoc === "cartao") text = "do Cartão";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const handleStepOne = async (values) => {
    try {
      setLoadingBtn(true);
      let response = "";
      if (currentDoc === "client_id") {
        response = await api.get(`get_client_by_id`, {
          [`${currentDoc}`]: values.doc,
        });
      } else {
        response = await api.get(`client_${currentDoc}`, {
          [`${currentDoc}`]: values.doc
            .replace(/[./-]/g, "")
            .replace(/\s/g, ""),
        });
      }

      if (!response.data[0]) throw new Error("Cliente não encontrado");
      const resp = await api.get("clientId", {
        client_id: response.data[0].id,
      });

      if (resp) {
        setNameClient(resp.data.nome);
        setIdClient(resp.data.id);

        setTimeout(() => {
          setLoadingBtn(false);
          setFormSteps(1);
          handleSubmit(response.data[0].id);
        }, 1000);
      }
    } catch (error) {
      setLoadingBtn(false);
      notifyError(error.message);
    }
  };

  const handleResetSearch = async () => {
    await setFormSteps(0);
    await setNameClient(null);
    await setIdClient(null);
    await setSearchData(false);
    await setData(null);
    await setInvoiceDelayDate(null);
    await setCollectionInvoiceDate(null);
    await setExpiryDateInvoice(null);
    await setCurrentValue(null);
    await setClientStatus("");
  };

  return (
    <>
      <S.Container>
        <S.PageInfoContainer>
          <S.PageContent style={{ marginTop: "3%" }}>
            <HomeFilled /> / <b>Lista de Inadimplência</b>
          </S.PageContent>
          <S.UserContainer>
            {formSteps > 0 && (
              <div className="select-btn">
                <Button
                  style={{ marginLeft: 22, marginBottom: 15 }}
                  onClick={() => handleResetSearch()}
                  type="ghost"
                >
                  Nova Pesquisa
                </Button>
              </div>
            )}
            <Card title="Lista de Inadimplência" loading={loading}>
              {formSteps === 0 && (
                <Formik
                  initialValues={{ doc: "" }}
                  validationSchema={Yup.object({
                    doc: Yup.string()
                      .required(`${currentDoc.toUpperCase()} obrigatório`)
                      .test(
                        "test_cpf",
                        `Informe um ${currentDoc} válido`,
                        (value) => {
                          if (currentDoc === "client_id") return true;
                          if (currentDoc === "cpf") {
                            if (value === undefined || value === null)
                              return false;

                            const cpfFormated = value
                              .split(".")
                              .join("")
                              .replace("-", "");
                            return validaCpf(cpfFormated);
                          }
                          if (currentDoc === "cartao") {
                            const cardFormated = value
                              ? value.replace(/[./-]/g, "").replace(/\s/g, "")
                              : "";
                            let cardSize = 16;
                            if (cardFormated.length === 19) cardSize = 19;

                            return cardFormated.length === cardSize;
                          }
                        }
                      ),
                  })}
                  onSubmit={handleStepOne}
                >
                  {() => (
                    <Form>
                      <div className="box-form">
                        <S.FormRow>
                          <div>
                            <b>Tipo:</b>
                          </div>
                          <S.CpfCnpj>
                            <S.DocButton
                              selected={currentDoc === "cpf"}
                              onClick={handleCPF}
                            >
                              CPF
                            </S.DocButton>
                            <S.DocButton
                              selected={currentDoc === "client_id"}
                              onClick={handleClientId}
                            >
                              ID Cliente
                            </S.DocButton>
                          </S.CpfCnpj>
                          <Field name="doc">
                            {({ field, meta }) => (
                              <ReactInputMask
                                placeholder={setPlaceholder()}
                                type="text"
                                mask={currentMask}
                                maskChar={null}
                                autoComplete="off"
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                {...field}
                              />
                            )}
                          </Field>

                          <ErrorMessage
                            name="doc"
                            className="error"
                            component="div"
                          />
                        </S.FormRow>

                        <S.FormRow>
                          <Button
                            loading={loadingBtn}
                            htmlType="submit"
                            type="primary"
                          >
                            Buscar
                          </Button>
                        </S.FormRow>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {formSteps === 1 && (
                <>
                  <div className="flex">
                    {nameClient && (
                      <>
                        <p className="gap">
                          <span>
                            {" "}
                            <strong>Nome do Portador:</strong> {nameClient}
                          </span>
                          <span>
                            {" "}
                            <strong>ID Portador:</strong> {idClient}
                          </span>
                        </p>
                        {/* <p className="gap">
                          <span>
                            {" "}
                            <strong>Data Vencimento:</strong>{" "}
                            {expiryDateInvoice}
                          </span>
                          <span>
                            <strong>Data de atraso:</strong> {invoiceDelayDate}
                          </span>
                          <span>
                            <strong>Data de Cobrança:</strong>{" "}
                            {collectionInvoiceDate}
                          </span>
                        </p> */}
                      </>
                    )}
                  </div>
                  <div className="select-table">
                    {loadingTable && <Loading />}
                    {searchData && data?.length > 0 && (
                      <>
                        <UsersTable data={data[0].data} />
                        <p className="gap">
                          <span>
                            <strong>Status:</strong> {clientStatus}
                          </span>
                          <span>
                            <strong>Total:</strong>{" "}
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(currentValue)}
                          </span>
                        </p>
                      </>
                    )}
                    {searchData && (!data || data?.length === 0) && (
                      <div className="alert-table">
                        <Alert
                          message="Não há dados para esse portador"
                          type="warning"
                          showIcon
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Card>
          </S.UserContainer>
        </S.PageInfoContainer>
      </S.Container>
    </>
  );
}
export default React.memo(ListDefault);
