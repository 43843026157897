import moment from "moment";
import * as Yup from "yup";

import { validaRg } from "../utils/validations";
import { ufs } from "./ufs";

const ERROR = {
  REQUIRED: "Campo Obrigatório",
  POSITIVE: "O valor tem que ser um número positivo",
  EMAIL: "Deve ser um e-mail válido",
  CNPJ: "CNPJ Inválido",
  CPF: "CPF Inválido",
  RG: "RG Inválido",
  TEL: "Telefone Inválido",
  CEL: "Celular Inválido",
  CEP: "CEP Inválido",
  UF: "UF Inválido",
  NIVER: "Data de Nascimento Inválido",
};

function is18Year(date) {
  const currentDate = moment();

  const dataInserted = moment(date, "DD/MM/YYYY", "pt-BR", true);

  const yearDiff = currentDate.diff(dataInserted, "years");

  return yearDiff >= 18;
}

function validDate(date) {
  return moment(date, "DD/MM/YYYY", true).isValid();
}

function validBirthDay(date) {
  return date && date.length === 10 && validDate(date) && is18Year(date);
}

Yup.addMethod(Yup.string, "customBirthDay", function (message) {
  return this.test("customBirthDay", message, (value) => validBirthDay(value));
});

export const schemaTitular = Yup.object().shape({
  nome: Yup.string()
    .required(ERROR.REQUIRED)
    .test("lastName", "Insira pelo menos um sobrenome", (name) => {
      if (!name) {
        return false; 
      }
      const formattedName = name.trim().split(" ");
      return formattedName?.length > 1;
    })
    .matches(/^[\sa-zA-ZÀ-ÖØ-öø-ÿ]+$/, "O campo deve conter apenas letras"),
  email: Yup.string().email(ERROR.EMAIL).required(ERROR.REQUIRED).nullable(),
  birthday: Yup.string()
    .min(10, "Data inválida")
    .customBirthDay("Data inválida")
    .required("Insira Uma Data Válida")
    .nullable(),
  rg: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable()
    .matches(/^[0-9]+$/, "O campo deve conter apenas números")
    .max(15, "Permitido até 15 caracteres.")
    .min(7, "Mínimo de 7 caracteres.")
    .test('rg', 'O Rg é inválido', (value) => {
      if (!value) return false;
      const rgFormated = value
      .split(".")
      .join("")
      .replace("-", "");
    return validaRg(rgFormated);
    }),
  orgaoEmissor: Yup.string()
    .min(2, ' Orgão Emissor com unidade federativa é obrigatório')
    .required(ERROR.REQUIRED)
    .nullable()
    .matches(/^[a-zA-Z]+$/, 'Orgão Emissor deve conter apenas letras'),
  unidadeFederativaRG: Yup.string()
    .min(2, ' UF do Orgão Emissor é obrigatório').required(ERROR.REQUIRED).nullable()
    .test('unidadeFederativa', 'UF Orgão Emissor inválida', (uf) => {
        let isValid = false;
        if(uf !== undefined) {
          if(ufs.includes(uf.toUpperCase()) === true){
            isValid =  true;
          }
        }
      return isValid
    }),
  dataEmissao: Yup.string()
    .min(10, "Data inválida")
    .required("Insira Uma Data Válida")
    .nullable(),
});
