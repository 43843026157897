import styled from "styled-components";

export const TabsCardWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding-bottom: 16px;
  overflow-x: hidden;
`;

export const TabsTitlesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--neutral-color-gray-4);
  position: relative;
  margin-bottom: 30px;
`;

export const TabTitle = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 22px;
  line-height: 22px;
  cursor: pointer;

  &.active {
    background-color: var(--neutral-color-gray-1);
    color: var(--brand-color-gold-6);
    font-style: normal;
    font-weight: 600;
  }
`;

export const ActiveTab = styled.span`
  transition: width 0.3s, left 0.3s;
  position: absolute;
  height: 2px;
  bottom: 0;
  left: ${(props) =>
    `calc((100% / ${props.tabsQuantity}) * ${props.activeIndex})`};
  background-color: var(--brand-color-gold-6);
  width: ${(props) => `calc(100% / ${props.tabsQuantity})`};
`;
