import styled, { css } from "styled-components";

export const ContainerTimeLineV2 = styled.div`
  width: 100%;
  margin-top: 24px;

  .timeline-v2__header {
    width: 100%;
    padding: 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
    background: var(--Neutral-Color-Gray-1, #fff);

    display: flex;
    flex-wrap: wrap;

    p,
    h2 {
      margin: 0;
      padding: 0;
    }

    .timeline-v2__header--left {
      width: 100%;

      display: flex;
      align-items: center;
      margin-bottom: 15px;

      h2 {
        color: var(--Neutral-Color-Dark-10, #272d3b);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
      }
    }

    .timeline-v2__header--right {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ant-picker {
        width: 280px !important;
      }

      .timeline-v2__header--range {
        width: 100%;
        margin-bottom: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .timeline-v2__header--filter {
        width: auto;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        gap: 8px;

        span {
          display: flex;
          align-items: center;
        }

        input {
          width: 169px;
          height: 32px;
          margin-right: 0;
        }

        button {
          display: flex;
          padding: 5px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 2px;
          border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
          background: var(--Brand-Color-Gold-6, #ffb32c);
          /* drop-shadow/button-primary */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

          color: var(--Neutral-Color-Dark-10, #272d3b);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        .select-filter {
          width: 111px !important;
          height: 32px !important;

          .ant-select-selector {
            border-radius: 2px 0px 0px 2px;
            background: var(--Neutral-2, #fafafa);
            box-shadow: 0px -1px 0px 0px #d9d9d9 inset,
              0px 1px 0px 0px #d9d9d9 inset, 1px 0px 0px 0px #d9d9d9 inset;

            color: var(--Neutral-Color-Gray-9, #434343);
            text-align: center;
            /* Body 14/regular */
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
  }

  .timeline-v2__content {
    width: 100%;
    padding: 16px;
    border-radius: 0 0 4px 4px;
    border-left: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
    border-right: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
    border-bottom: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
    background: var(--Neutral-Color-Gray-1, #fff);
  }

  @media (min-width: 992px) {
    .timeline-v2__header {
      justify-content: space-between;
      .timeline-v2__header--left {
        width: 233px;
        margin-bottom: 0;
      }
      .timeline-v2__header--right {
        width: calc(100% - 238px);
        flex-wrap: nowrap;

        .ant-picker {
          width: 244px !important;
        }

        .timeline-v2__header--range {
          margin-bottom: 0;
          justify-content: flex-end;
        }

        .timeline-v2__header--filter {
          margin-left: 10px;
          flex-direction: row;

          input {
            width: 169px;
            height: 32px;
            margin-right: 10px;
          }

          .select-filter {
            width: 111px !important;
            height: 32px !important;
          }
        }
      }
    }
  }
`;

export const CardTimeLineHistory = styled.div`
  width: 100%;

  .timeline-v2__content--filters {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  .timeline-v2__content--empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 18px;

    p {
      padding: 0;
      margin: 0;
      color: var(--Neutral-Color-Dark-11, #212733);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .timeline-v2__content__left {
    width: 100%;
  }

  .timeline-v2__content__right {
    width: 50%;
    display: none;
  }

  .card-timeline-history__item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      .card-timeline-history__date {
        p {
          color: #ffb32c;
        }
      }

      .card-timeline-history__title {
        border-color: #ffb32c;
      }
    }

    &.active {
      .card-timeline-history__date {
        p {
          color: #ffb32c;
        }
      }

      .card-timeline-history__title {
        border-color: #ffb32c;

        .arrow {
          display: flex;
        }
      }

      .card-timeline-history__content {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .card-timeline-history__left {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .card-timeline-history__date {
      width: 47px;

      p {
        padding: 0;
        margin: 0;
        color: var(--Neutral-Color-Gray-7, #8c8c8c);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .card-timeline-history__title {
      width: calc(100% - 56px);
      margin-left: 8px;
      padding-left: 24px;
      padding-bottom: 24px;
      padding-right: 24px;
      border-left: 2px solid #e4e7ec;

      p {
        padding: 0;
        margin: 0;
      }

      .title {
        width: 100%;
        margin-bottom: 4px;
        p {
          color: var(--Neutral-Color-Dark-11, #212733);
          white-space: pre-wrap;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .protol {
        width: 100%;
        margin-bottom: 10px;
        p {
          color: var(--Neutral-Color-Gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .user {
        width: 100%;
        padding: 8px 0;
        p {
          color: var(--Neutral-Color-Gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .arrow {
        margin-top: 40px;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: none;

        svg {
          font-size: 33px;
          color: #ffb32c;
        }
      }
    }
  }

  .card-timeline-history__content {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    display: none;
    flex-wrap: wrap;

    padding-bottom: 24px;
    border-bottom: 2px solid #ffb32c;

    .timeline__call-duration {
      width: 100%;
      margin-bottom: 12px;
      border-bottom: 1px solid #e5e7eb;

      .timeline__call-duration--title {
        width: 100%;
        margin-bottom: 8px;
        p {
          color: var(--Neutral-Color-Gray-9, #434343);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }

      .timeline__call-duration--icons {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 12px;

        p {
          padding: 0;
          margin: 0;
          color: var(--Neutral-Color-Dark-10, #272d3b);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 22px;
        }

        .info-icon {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background: #ffb32c;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;

          svg {
            font-size: 20px;
          }
        }
      }
    }

    .timeline-time {
      width: 100%;
      border-bottom: 1px solid #e5e7eb;
      padding-bottom: 24px;
      margin-bottom: 24px;

      p {
        padding: 0;
        margin: 0;
        color: var(--Neutral-Color-Gray-8, #595959);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;

        svg {
          margin-right: 8px;
        }

        &:last-child {
          margin-top: 8px;
        }
      }
    }

    .generated-requests {
      width: 100%;

      border-bottom: 1px solid #e5e7eb;
      padding-bottom: 24px;
      margin-bottom: 24px;

      .generated-requests__header {
        width: 100%;
        margin-bottom: 18px;

        .generated-requests--title {
          width: 100%;
          margin-bottom: 8px;
          p {
            color: var(--Neutral-Color-Gray-9, #434343);
            white-space: pre-wrap;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            padding: 0;
            margin: 0;
          }
        }

        .generated-requests--date {
          width: 100%;

          p {
            padding: 0;
            margin: 0;
            color: var(--Neutral-Color-Gray-7, #8c8c8c);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .generated-requests--content {
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--neutral-color-gray-45, #e6e6e6);
        padding: 18px;

        .gerenated-requests--content__header {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 12px;

          .gerenated-requests--content__header--icon {
            width: 24px;
            margin-right: 12px;

            svg {
              font-size: 24px;
              color: #ccc;
            }
          }

          .gerenated-requests--content__header--text {
            width: calc(100% - 36px);
            p {
              white-space: pre-wrap;
              padding: 0;
              margin: 0;
              color: var(--Neutral-Color-Dark-10, #272d3b);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .gerenated-requests--content__footer {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          align-items: center;

          .gerenated-requests--content__footer--status {
            span {
              width: 100%;
              max-width: 122px;
              height: 28px;
              padding: 0;
              margin: 0 auto;
              border-radius: 16px;
              border: 1px solid var(--Status-Color-Success, #52c41a);
              background: rgba(82, 196, 26, 0.05);
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--Status-Color-Success, #52c41a);
              /* Footnote 12/description */
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .gerenated-requests--content__footer--action {
            button {
              width: 100%;
              max-width: 122px;
              border-radius: 2px;
              padding: 8px;
              border: 1px solid var(--Neutral-5, #d9d9d9);
              background: var(--Neutral-1, #fff);
              /* drop-shadow/button-secondary */
              box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 20px;
                color: #272d3b;
              }
            }
          }
        }
      }
    }

    .actions {
      width: 100%;

      border-bottom: 1px solid #e5e7eb;
      padding-bottom: 24px;
      margin-bottom: 24px;

      .actions__header {
        width: 100%;
        margin-bottom: 18px;
        .actions__header--title {
          width: 100%;
          margin-bottom: 5px;
          p {
            padding: 0;
            margin: 0;

            color: var(--Neutral-Color-Gray-9, #434343);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }

        .actions__header--date {
          width: 100%;

          p {
            padding: 0;
            margin: 0;
            color: var(--Neutral-Color-Gray-7, #8c8c8c);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .actions__content {
        width: 100%;

        .actions__content--action {
          width: 100%;
          display: flex;
          align-items: center;

          &.reverse {
            margin-top: 12px;
            p {
              svg {
                transform: rotate(180deg);
              }
            }
          }

          p {
            padding: 0;
            margin: 0;
            color: var(--Neutral-Color-Gray-8, #595959);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;

            display: flex;
            align-items: center;

            svg {
              font-size: 18px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .comments {
      width: 100%;
      border-bottom: 1px solid #e5e7eb;
      padding-bottom: 24px;
      margin-bottom: 24px;

      .comments__header {
        width: 100%;
        margin-bottom: 18px;
        .comments__header--title {
          width: 100%;
          margin-bottom: 5px;
          p {
            padding: 0;
            margin: 0;

            color: var(--Neutral-Color-Gray-9, #434343);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }

        .comments__header--date {
          width: 100%;

          p {
            padding: 0;
            margin: 0;
            color: var(--Neutral-Color-Gray-7, #8c8c8c);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .comments__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .comments__content--icon {
          width: 24px;
          margin-right: 16px;

          svg {
            font-size: 24px;
            color: #8c8c8c;
          }
        }

        .comments__content--message {
          width: calc(100% - 40px);

          p {
            padding: 0;
            margin: 0;
            color: var(--Neutral-Color-Gray-7, #8c8c8c);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    display: flex;
    flex-wrap: wrap;

    .timeline-v2__content__left {
      width: calc(50% - 24px);
    }
    .timeline-v2__content__right {
      width: 50%;
      display: flex;
      align-items: flex-start;
    }

    .timeline-v2__content__left {
      border-right: 4px solid #ffb32c;
      margin-right: 24px;
    }

    .timeline-v2__content__right {
      display: inherit;
    }

    .card-timeline-history__item {
      .card-timeline-history__date {
        width: 80px;
        margin-right: 24px;
      }

      .card-timeline-history__title {
        width: calc(100% - 112px);
        display: flex;
        justify-content: space-between;

        .arrow {
          margin-top: 26px;

          svg {
            transform: rotate(-92deg);
          }
        }

        .card-timeline-history__item--left {
          width: calc(100% - 40px);
        }

        .card-timeline-history__item--right {
          width: 40px;
        }
      }

      &.active {
        .card-timeline-history__content {
          display: none;
        }
      }
    }

    .card-timeline-history__content {
      &.mobile {
        display: none;
      }

      &.desktop {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 2px solid transparent;

        .timeline__call-duration {
          width: 50%;
          height: 100px;

          .timeline__call-duration--icons {
            padding-bottom: 24px;
          }
        }

        .timeline-time {
          width: 50%;
          margin-top: 27px;
          padding-left: 15px;
          height: 73px;
        }

        .generated-requests {
          .generated-requests__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .generated-requests--title {
              margin-bottom: 0;
            }

            .generated-requests--date {
              text-align: right;
            }
          }

          .generated-requests--content {
            display: flex;
            justify-content: space-between;

            .gerenated-requests--content__header {
              margin-bottom: 0;
            }

            .gerenated-requests--content__footer {
              width: 112px;
              .gerenated-requests--content__footer--status {
                span {
                  width: 69px;
                }
              }
              .gerenated-requests--content__footer--action {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button {
                  width: 32px;
                }
              }
            }
          }
        }

        .actions {
          .actions__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .actions__header--date {
              text-align: right;
            }
          }
        }

        .comments {
          .comments__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .comments__header--date {
              text-align: right;
            }
          }
        }
      }
    }
  }
`;

export const ActionsBTNSTimelineHistory = styled.div`
  width: 100%;
  margin-bottom: 24px;

  .actions-grid {
    width: 100%;
    max-width: 386px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      height: 46px;
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      background: var(--Neutral-Color-Gray-4, #f0f0f0);
      border: 0;
      border-bottom: 2px solid transparent;
      transition: all 0.5s ease-in;

      &:hover,
      &.active {
        background: var(--Neutral-Color-Gray-1, #fff);
        color: var(--Brand-Color-Gold-6, #faad14);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        border-color: #faad14;
      }
    }
  }
`;

export const LoadingTimeliveV2 = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaginationTimeliveV2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
  margin-top: 24px;
  padding-top: 24px;
`;
