import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
`;

export const BreadCrumbPage = styled.div`
  width: 100%;
  margin-bottom: 16px;

  p {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    span {
      font-weight: bold;
    }
  }
`;

export const ContentUsers = styled.div`
  width: 100%;

  border-radius: 4px;
  border: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);
  background: var(--Neutral-Color-Gray-1, #fff);

  .content-users__title {
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);

    .content-users__title--left {
      width: 100%;
      margin-bottom: 16px;

      h2 {
        padding: 0;
        margin: 0;
        color: var(--Neutral-Color-Dark-10, #272d3b);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .content-users__title--right {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input {
        width: 100%;
        margin-bottom: 16px;
        padding: 8px 12px;
        border-radius: 2px;
        border: 1px solid var(--Neutral-5, #d9d9d9);
        background: var(--Neutral-Color-Gray-1, #fff);
      }

      button {
        border-radius: 2px;
        border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
        background: var(--Brand-Color-Gold-6, #ffb32c);
        /* drop-shadow/button-primary */
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--Neutral-Color-Gray-9, #434343);
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        transition: ease 0.5s linear;

        &:hover {
          box-shadow: 0px 2px 8px #ffb32c;
        }
      }
    }

    @media(min-width: 689px) {
      .content-users__title--right {
        flex-wrap: wrap;
      }
    }
  }

  .content-users__content {
    width: 100%;
    padding: 16px;

    .rwd-table {
      width: 100%;
      background: #fff;
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      border-collapse: separate !important;
      border-spacing: 0;

      border-radius: 4px;
      border: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);

      .btns-acoes {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .btn-div {
          padding: 8px;
          border-radius: 2px;
          border: 1px solid var(--Neutral-5, #d9d9d9);
          background: var(--Neutral-1, #fff);
          /* drop-shadow/button-secondary */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
        }
      }

      p {
        &.status {
          border-radius: 16px;
          border: 1px solid var(--Status-Color-Success, #52c41a);
          background: rgba(82, 196, 26, 0.05);
          padding: 1px 8px;
          color: var(--Status-Color-Success, #52c41a);
          /* Footnote 12/description */
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: inline-flex;
          margin: 0;

          &.off {
            border: 1px solid var(--Status-Color-Error, #ff4d4f);
            background: rgba(255, 77, 79, 0.05);
            color: var(--Status-Color-Error, #ff4d4f);
          }
        }
      }

      & td {
        /* border-left: 1px solid #ccc; */
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        text-align: left;
      }

      & tr {
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;

        &:first-of-type {
          background: #f5f5f5;
        }

        &:last-of-type {
          & td {
            /* border-left: 1px solid #ccc; */
            border-bottom: 1px solid transparent !important;
            padding: 10px;
            text-align: left;
          }
        }
      }
      & th {
        display: none;
      }

      & td {
        display: block;

        &:first-child {
          padding-top: 0.5em;
        }

        &:last-child {
          padding-bottom: 0.5em;
        }

        &:before {
          content: attr(data-th) ": ";
          font-weight: bold;
          width: 6.5em;
          display: inline-block;
        }
      }

      & th,
      & td {
        text-align: left;
        margin: 0.5em 1em;
      }

      & th,
      & td:before {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      }
    }

    @media (min-width: 480px) {
      .rwd-table td:before {
        display: none;
      }

      .rwd-table th,
      .rwd-table td {
        display: table-cell;
        padding: 1em !important;
      }
      .rwd-table th:first-child,
      .rwd-table td:first-child {
        padding-left: 0;
      }
      .rwd-table th:last-child,
      .rwd-table td:last-child {
        padding-right: 0;
      }
    }

    .content-users__content--loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 16px;
      flex-wrap: wrap;

      .content-logo,
      .content-text-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }
    }
  }

  .pagination-users {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 8px;

    span {
      display: flex;
      align-items: center;
    }

    button {
      width: 32px;
      height: 32px;
      padding: 10px;
      border-radius: 2px;
      border: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
      background: var(--Neutral-Color-Gray-1, #fff);
      color: var(--Neutral-Color-Dark-10, #272d3b);
      font-size: 14px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        color: #d9d9d9;
        cursor: not-allowed;
      }

      &.active {
        border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
        color: #ffb32c;
      }
    }
  }

  @media (min-width: 768px) {
    .content-users__title {
      display: flex;
      align-items: center;
      .content-users__title--left {
        width: calc(100% - 454px);
        margin-bottom: 0;
      }
      .content-users__title--right {
        width: 454px;
        margin-bottom: 0;
        flex-direction: row;
        flex-wrap: wrap;

        input {
          margin-bottom: 0;
          width: 271px;
          margin-right: 16px;
        }

        button {
          display: flex;
          width: 167px;
        }
      }
    }
  }

  @media (max-width:768px) {
    .content-users__title .content-users__title--right {
      justify-content: center !important;
    }
  }
`;
export const NewUserButton = styled.button`
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
  background: var(--Brand-Color-Gold-6, #ffb32c);
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Neutral-Color-Gray-9, #434343);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: ease 0.5s linear;
  width: fit-content;

  /* @media(max-width:768px) {
    width: 40%;
  } */

  &:hover {
    box-shadow: 0px 2px 8px #ffb32c;
  }
}`

export const FilterByStoreButton = styled.button`
  p {
    margin: 0;
    border: 0;
    padding: 0;
    font-weight: 600;
  }

  div.selected-store-length {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ffb32c;
    height: 22px;
    width: 22px;
    padding: 8px 8px;
    margin-right: 3px;

    p {
      font-size: 14px;
    }
  }


  white-space: nowrap;
  width: fit-content;
  border-radius: 2px;
  border: 1px solid #BFBFBF;
  background: #FFFFFF;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  color: #000000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 24px; */
  transition: ease 0.5s linear;

  @media(max-width:768px) {
    width: 40%;
  }

  ${props =>
      props.fetchStoreLoading || !props.availableStoresToFilterLength ?
      css`
        &:hover {
          opacity: 0.8;
          cursor: not-allowed;
          background: #fafafa;
        }
      ` :
      css`
        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      `
  }

}`

export const ActionsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10%;

  div:hover {
    cursor: pointer;
  }

  .action-icon {
    font-size: 16px;
  }
`

export const LoadingStoresButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  column-gap: 8px;
`
