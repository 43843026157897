import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Input, Modal, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import api from '../../../services/api';
import { notifyError } from '../../../utils/notifications';
import * as S from './styles';

const { Option } = Select;
const { TextArea } = Input;

function ModalMissingData({ open, closeModal, idClient, setShowInit }) {
  const navigate = useNavigate();
  const [classifications, setClassifications] = useState([]);
  const [obs, setObs] = useState(
    'Atendimento finalizado por falta de informações'
  );
  const [classificationSelected, setClassificationSelected] = useState(0);

  const fetchClassification = useCallback(async () => {
    try {
      const classificationsTypes = await api.get('classification');
      setClassifications(classificationsTypes.data);
    } catch (error) {
      setClassifications([]);
      console.error('Nao foi possivel carregar as classificacões');
    }
  }, []);

  useEffect(() => {
    fetchClassification();
  }, [fetchClassification]);

  const handleAttendanceFinish = useCallback(async () => {
    if (Number(classificationSelected) === 0) {
      notifyError('Selecione a classificação');
      return false;
    }
    if (idClient) {
      await api.post('attendance_finish', {
        client_id: idClient,
        classification_id: classificationSelected,
        obs,
      });
    }
    closeModal();
    navigate('/home');
    setShowInit(true);
    return true;
  }, [
    classificationSelected,
    closeModal,
    navigate,
    idClient,
    obs,
    setShowInit,
  ]);

  const handleClassification = useCallback((value) => {
    setClassificationSelected(value);
  }, []);

  const handleInsertClassificationAndObs = useCallback(() => {
    setObs(obs);
    handleAttendanceFinish();
  }, [handleAttendanceFinish, obs]);

  const handleChangeObs = useCallback((event) => {
    const { value } = event.target;
    setObs(value);
  }, []);

  const handleGoBack = useCallback(() => {
    setClassificationSelected(0);
    setObs('Atendimento finalizado por falta de informações');
    setShowInit(true);
    closeModal();
  }, [closeModal, setShowInit]);

  return (
    <Modal
      visible={open}
      width={900}
      onCancel={handleGoBack}
      id="classificacao"
    >
      <S.ModalContainer>
        <S.ModalClass>
          <S.ModalTitle>
            <h1>Classificação</h1>
          </S.ModalTitle>
          <p />
          <p />
          Selecione a classificação para finalizar o atendimento:
          <S.ModalRow>
            <Select defaultValue="0" onChange={handleClassification}>
              <Option value="0">Selecione</Option>
              {classifications.map((classification) => (
                <Option key={classification.id} value={classification.id}>
                  {classification.name}
                </Option>
              ))}
            </Select>
          </S.ModalRow>
          <p />
          <p />
          Digite aqui uma observação:
          <S.ModalRow>
            <TextArea
              rows={4}
              value={obs}
              placeholder="Digite aqui a observação para finalizar o atendimento"
              onChange={handleChangeObs}
            />
          </S.ModalRow>
          <S.ModalRow>
            <S.ButtonCancelar>
              <Button id="buttonCancelarClass" onClick={handleGoBack}>
                Cancelar
              </Button>
            </S.ButtonCancelar>
            <S.ButtonConcluir>
              <Button onClick={handleInsertClassificationAndObs}>
                Concluir
              </Button>
            </S.ButtonConcluir>
          </S.ModalRow>
        </S.ModalClass>
      </S.ModalContainer>
    </Modal>
  );
}

export default memo(ModalMissingData);
