import React, { useCallback, useEffect, useState } from 'react';

import { Button, Modal } from 'antd';

import AntInput from '../../../components/AntInput';
import Loading from '../../../components/Loading';
import { usePermissions } from '../../../Context/permissionContext';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import Functionalities from './Functionalities';
import ModalClassificationsRelations from './ModalClassificationsRelations';
import ModalInstallmentLimit from './ModalInstallmentLimits';
import * as S from './style';

function EditModal({ open, addRow, permissions, selectedGroup, closeModal }) {
  const [persistedFunctionalities, setPersistedFunctionalities] = useState([]);
  const [activeFunctionalities, setActiveFunctionalities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFunctionalities, setLoadingFunctionalities] = useState(false);
  const [name, setName] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [openModalinstallment, setOpenModalinstallment] = useState(false);
  const { fetchPermissions } = usePermissions();

  const loadRolePermissions = useCallback(async () => {
    try {
      setLoadingFunctionalities(true);
      const { data } = await api.get('role_permissions', {
        role_id: selectedGroup.id,
        companie: selectedGroup.company_id,
      });
      await setName(selectedGroup.name);
      const functionalities = data.map((_p) => _p.id)
      await setActiveFunctionalities(() => functionalities);
      await setPersistedFunctionalities(() => functionalities);
    } catch (error) {
      notifyError('Falha ao listar permissões');
    } finally {
      setLoadingFunctionalities(false);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedGroup) {
      loadRolePermissions();
    } else {
      setActiveFunctionalities([]);
      setName(null);
    }
  }, [loadRolePermissions, selectedGroup]);

  const handleClose = useCallback(() => {
    if (!selectedGroup) {
      closeModal()
      return;
    }

    if (!persistedFunctionalities.length) {
      notifyError("Habilite ao menos uma permissão e clique em salvar");
      return;
    }

    closeModal();

  }, [selectedGroup, persistedFunctionalities])

  const handleName = useCallback((event) => setName(event.target.value), []);

  const create = useCallback(async () => {
    if (!name) {
      notifyError('O Nome é obrigatório');
      return;
    }

    try {
      const { role } = await api.post('role', {
        name,
        permissions: activeFunctionalities,
        description: name,
      });

      await addRow(role);
      notifySucces('Criado com Sucesso');

      await fetchPermissions();
    } catch (error) {
      notifyError('Falha ao Criar');
    } finally {
      setLoading(false);
    }
  }, [activeFunctionalities, addRow, name, fetchPermissions]);

  const update = useCallback(async () => {
    if (!activeFunctionalities.length) {
      notifyError("Habilite ao menos uma permissão para salvar");
      return;
    }

    const onOk = async () => {
      await api.put('role', {
        id: selectedGroup.id,
        permissions: activeFunctionalities,
        name,
        description: name,
      });

      notifySucces('Atualizado com Sucesso');
      closeModal();
      await fetchPermissions();
      window.location.reload();
    };

    Modal.info({
      onOk,
      title: 'Atenção',
      closable: true,
      cancelText: 'Cancelar',
      content: 'Tem certeza que deseja atualizar?',
    });
  }, [activeFunctionalities, closeModal, name, selectedGroup, fetchPermissions]); // Adicione fetchPermissions como dependência

  const save = useCallback(async () => {
    try {
      setLoading(true);
      const method = selectedGroup ? update : create;
      await method();
    } catch (error) {
      notifyError('Falha ao Criar');
    } finally {
      setLoading(false);
    }
  }, [create, selectedGroup, update]);

  return (
    <>
      <Modal visible={open} title="Grupo" width={1000} onCancel={handleClose}>
        <S.ModalContainer>
          <S.ModalRow>
            <AntInput addonBefore="Nome" onChange={handleName} value={name} />
          </S.ModalRow>

          {!!selectedGroup && (
            <Functionalities
              activeFunctionalities={activeFunctionalities}
              setActiveFunctionalities={setActiveFunctionalities}
              permissions={permissions}
              setEditModalOpen={setEditModalOpen}
              setOpenModalinstallment={setOpenModalinstallment}
            />
          )}

          {loadingFunctionalities && <Loading />}

          <S.ModalRow
            style={{
              justifyContent: 'end',
              alignContent: 'end',
              alignItems: 'end',
              marginTop: '16px',
            }}
          >
            <Button onClick={save} loading={loading} className="confirm">
              Salvar
            </Button>
          </S.ModalRow>
        </S.ModalContainer>
      </Modal>
      <ModalClassificationsRelations
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedGroupId={selectedGroup?.id}
      />
      <ModalInstallmentLimit
        openModalinstallment={openModalinstallment}
        setOpenModalinstallment={setOpenModalinstallment}
        selectedGroupId={selectedGroup?.id}
      />
    </>
  );
}

export default React.memo(EditModal);
