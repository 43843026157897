import React, { useEffect, useState } from "react";

import { MdClose } from "react-icons/md";

import * as Style from "./style";

function PopupService({ isOpen, onClose }) {
  const [currentImage, setCurrentImage] = useState();
  const [loading, setLoading] = useState(true);


  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };


  useEffect(() => {
    fetchImage(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <>
      {isOpen ? (
        <Style.WrapperPopupService>
          <div className="align-popup">
            <div className="align-popup__close">
              <MdClose onClick={onClose} />
            </div>
            <img
              style={{
                filter: `${loading ? "blur(20px)" : ""}`,
                transition: "1s filter linear",
                width: "100%",
                background: "#ccc",
              }}
              src={currentImage}
              alt="Elevaty"
            />
          </div>
        </Style.WrapperPopupService>
      ) : null}
    </>
  );
}

export default PopupService;
