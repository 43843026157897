import React, { useCallback, useState } from "react";

import {
  InfoCircleOutlined,
  ScheduleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import useInView from "react-cool-inview";

import { usePermissions } from "../Context/permissionContext";
import api from "../services/api";

function useSMS(clientId) {
  const [isLoading, setIsLoading] = useState(false);
  const [pacoteSMS, setPacoteSMS] = useState([]);
  const [openSMSModal, setOpenSMSModal] = useState(false);
  const [activePacote, setActivePacote] = useState(null);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [openCancelContractModal, setOpenCancelContractModal] = useState(false);
  const { permissions } = usePermissions();

  const [listAllSMS, setListAllSMS] = useState(false);
  const [listContractedSMS, setListContractedSMS] = useState(false);

  async function handleOpenContractModal() {
    setOpenContractModal(true);
  }

  async function handleOpenCancelContractModal() {
    setOpenCancelContractModal(true);
  }

  async function handleCloseContractModal() {
    setOpenContractModal(false);
  }

  async function handleCloseCancelContractModal() {
    setOpenCancelContractModal(false);
  }

  const columnPacoteSMS = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Descricao",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Tarifa",
      dataIndex: "valorTarifa",
      key: "valorTarifa",
      render: (valor) => <span>R$ {String(valor).replace(".", ",")}</span>,
    },
    {
      title: "Ações",
      dataIndex: "",
      key: "",
      render: (line) => (
        <div style={{ paddingLeft: "10px" }}>
          <InfoCircleOutlined
            title="Detalhes"
            onClick={async () => {
              setActivePacote(line);
              setOpenSMSModal(true);
            }}
          />
          {permissions &&
            permissions.includes("cancel_sms_package") &&
            line.contratado && (
              <StopOutlined
                title="Cancelar"
                style={{ width: "10px", marginLeft: "10px", cursor: "pointer" }}
                onClick={() => {
                  handleOpenCancelContractModal();
                  setActivePacote(line);
                }}
              />
            )}
          {permissions &&
            permissions.includes("contract_sms_package") &&
            !line.contratado && (
              <ScheduleOutlined
                title="Contratar"
                style={{ width: "10px", marginLeft: "10px", cursor: "pointer" }}
                onClick={() => {
                  handleOpenContractModal();
                  setActivePacote(line);
                }}
              />
            )}
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        data: { pacoteSmsResponse },
      } = await api.get("client/available_sms_pack", { client_id: clientId });
      const resp = await api.get("client/contrated_sms_pack", {
        client_id: clientId,
      });
      const contractedSMS = resp.data.pacoteSmsResponse;
      console.log("contractedSMS", [...pacoteSmsResponse, ...contractedSMS]);

      setListAllSMS(pacoteSmsResponse);
      setListContractedSMS(contractedSMS);
      setPacoteSMS([...pacoteSmsResponse, ...contractedSMS]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [clientId]);

  const { observe } = useInView({
    threshold: 0.1,
    onEnter: ({ unobserve }) => {
      if (clientId) {
        fetchData().then(unobserve);
      }
    },
  });

  return {
    isLoading,
    ref: observe,
    pacoteSMS,
    reload: fetchData,
    columnPacoteSMS,
    openSMSModal,
    setOpenSMSModal,
    activePacote,
    openContractModal,
    handleCloseContractModal,
    openCancelContractModal,
    handleCloseCancelContractModal,
    listAllSMS,
    listContractedSMS,
  };
}

export default useSMS;
