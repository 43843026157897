/* eslint-disable no-restricted-syntax */
import React, { memo, useCallback, useEffect, useState, useRef } from "react";

import { Dropdown, Menu, Tooltip } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import iconMenuOrange from "../../assets/bars-solid-orange.svg";
import iconMenu from "../../assets/bars-solid.svg";
import { CompanyParams } from "../../Context/companyContext"
import { usePermissions } from "../../Context/permissionContext";
import { useClient } from "../../hooks/ClientProvider";
import Api from "../../services/api";
import { vipagemService } from "../../services/vipagemService";
import { decrypt } from "../../utils/crypto";
import { formatDateTime } from "../../utils/formatter";
import { notifyError, notifySucces } from "../../utils/notifications";
import Can from "../Can";
import ModalBlockCard from "./modals/BlockModal";
import CancelCardModal from "./modals/CancelModal";
import CancelCardPlModal from "./modals/CancelPlModal";
import ChangeClientCardModal from "./modals/ChangeClientCardModal";
import ChangeLimitCardModal from "./modals/ChangeLimitCardModal";
import HistoryBlockModal from "./modals/HistoryBlockModal";
import HistoryLimitModal from "./modals/HistoryLimitModal";
import InformationCardModal from "./modals/InformationModal";
import ModalCopyCard from "./modals/ModalCopyCard";
import ModalPrint from "./modals/ModalPrint";
import RenewPassword from "./modals/renewPassword";
import SetPasswordMenuItem from "./modals/setPassword/setPasswordMenuItem";
import ModalUnBlockCard from "./modals/UnBlockModal";
import { PrintPdf } from "./printPdf/printPdf";
import * as S from "./style";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Item } = Menu;
function CreditCard({
  card,
  unBloqCard,
  clientID,
  productID,
  bloqCard,
  cancelCard,
  isDependent,
  copyCard,
  isBandeirado,
  lojas,
  store,
  isHistoryCard = false,
  reloadCards,
  newClass,
  reloadCardsHistory,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUnBlockModalVisible, setIsUnBlockModalVisible] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isHistoryBlockModalVisible, setIsHistoryBlockModalVisible] =
    useState(false);
  const [isHistoryLimitModalVisible, setIsHistoryLimitModalVisible] =
    useState(false);
  const [isInformationModalVisible, setIsInformationModalVisible] =
    useState(false);
  const [isChangeLimitModalVisible, setIsChangeLimitModalVisible] =
    useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isCancelPlModalVisible, setIsCancelPlModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [isChangeClientCardModalVisible, setIsChangeClientCardModalVisible] = useState(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [detailBlockCard, setDetailBlockCard] = useState("");

  const [newDetailBlockCard, setNewDetailBlockCard] = useState(null);
  const [blockTypes, setBlockTypes] = useState([]);
  const [copyBlockTypes, setCopyBlockTypes] = useState([]);
  const [blockValue] = useState("0");
  const dataValidade =
    card && card.dataValidade ? card.dataValidade.split("-") : ["00", "00"];
  const { permissions } = usePermissions();
  const [dataPrintPdf, setDataPrintPdf] = useState([]);
  const [ifChangeLimit, setIfChangeLimit] = useState(true);
  const { refreshClient, client } = useClient();
  const historyBlockModalRef = useRef(null);
  const historyLimitModalRef = useRef(null);
  const { companyParams } = CompanyParams();
  const [canSecondCopyExtraValidation, setCanSecondCopyExtraValidation] = useState(false)
  const [canChangeCardExtraValidation, setCanChangeCardExtraValidation] = useState(false)

  function executeExtraValidation(companyName, invalidCardIdStatuses, validationSetter) {
    if(!companyName) return;
    if(companyParams) {
      const sanitizedCompanyName = companyName.toLowerCase().trim()
      const applyToRegex = /becker/;
      const shouldHaveExtraValidation = applyToRegex.test(sanitizedCompanyName) || localStorage.getItem("@SnxPayment:isPch");
      if(!shouldHaveExtraValidation) {
        validationSetter(true);
        return;
      }

      const currentCardIdStatus = card?.idStatusCartao;
      if(currentCardIdStatus && invalidCardIdStatuses.includes(currentCardIdStatus)) {
        console.log("aqui no false")
        validationSetter(false);
        return;
      }

      validationSetter(true);
      return;
    }
    validationSetter(false);
  }

  useEffect(() => {
    executeExtraValidation(companyParams?.name, [3, 6, 32, 35, 36, 37, 38, 39, 171, 172, 200], setCanSecondCopyExtraValidation)
    executeExtraValidation(companyParams?.name, [4, 5, 8, 12, 201], setCanChangeCardExtraValidation);
  }, [companyParams]);

  const getData = useCallback(async () => {
    try {
      const resp = await Api.get("card_block_type", { isBandeirado });
      const { data } = resp;
      await setBlockTypes(data);
    } catch (error) {
      console.log("Falha ao listar tipos de bloqueios");
    }
  }, []);

  const codProduct = client?.product?.codigoProduto;
  const descProduct = client?.product?.desc_prod;

  const getDetailsBlockCard = useCallback(async () => {
    try {
      if (card) {
        if (
          card.status === "BLOQUEADO" ||
          card.status === "BLOQUEADO_TEMPORARIO" ||
          card.status === "BLOQUEADO_PERMANENTE" ||
          !isHistoryCard ||
          card.status === "PENDENTE_TROCA" ||
          card.status === "CARTAO_TROCA" ||
          card.status === "PENDENTE_CONFIRMACAO_TROCA"
        ) {
          const { data } = await Api.get("card_block_history", {
            card_id: card.id,
            cod_product: codProduct,
          });

          const getInfo = data.sort(
            (a, b) => new Date(b.dataBloqueio) - new Date(a.dataBloqueio)
          )[0];

          setNewDetailBlockCard({
            tipoBloqueio: getInfo.tipoBloqueioCartao?.descricao
              ? getInfo.tipoBloqueioCartao?.descricao
              : null,
            observacao: getInfo.observacao ? getInfo?.observacao : null,
            dataBloqueio: getInfo.dataBloqueio
              ? formatDateTime(getInfo.dataBloqueio)
              : null,
          });

          setDetailBlockCard(
            `
              ${
                getInfo.tipoBloqueioCartao?.descricao
                  ? `Descrição: ${getInfo.tipoBloqueioCartao?.descricao}`
                  : ""
              }\n
              ${
                getInfo.observacao ? `\nObservação: ${getInfo.observacao}` : ""
              }\n
              ${
                getInfo.dataBloqueio
                  ? `\nData Bloqueio: ${formatDateTime(getInfo.dataBloqueio)}`
                  : ""
              }
             `
          );
        } else if (card.status === "CARTAO_INVALIDO") {
          const { data } = await Api.get("card_cancel_history", {
            card_id: card.id,
            cod_product: codProduct,
          });

          const getInfo = data.sort(
            (a, b) => new Date(b.dataBloqueio) - new Date(a.dataBloqueio)
          )[0];

          setNewDetailBlockCard({
            tipoBloqueio: getInfo.tipoBloqueioCartao?.descricao
              ? getInfo.tipoBloqueioCartao?.descricao
              : null,
            observacao: getInfo.observacao ? getInfo?.observacao : null,
            dataBloqueio: getInfo.dataBloqueio
              ? formatDateTime(getInfo.dataBloqueio)
              : null,
          });

          setDetailBlockCard(
            `
              ${
                getInfo.tipoBloqueioCartao?.descricao
                  ? `Descrição: ${getInfo.tipoBloqueioCartao?.descricao}`
                  : ""
              }\n
              ${
                getInfo.observacao ? `\nObservação: ${getInfo.observacao}` : ""
              }\n
              ${
                getInfo.dataBloqueio
                  ? `\nData Bloqueio: ${formatDateTime(getInfo.dataBloqueio)}`
                  : ""
              }
            `
          );
        }
      }
    } catch (erro) {
      console.error("erro ao setar statuscard e statusBlock", erro);
    }
  }, [card, codProduct, isHistoryCard]);

  const clientName = client.client?.name;
  const numeroCartaoTruncado = client.information?.numeroCartaoTruncado;
  const { diaVencimento } = client.information;
  let { limitePrincipal } = client.information;
  limitePrincipal = limitePrincipal.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  });
  const saldoDisponivel = client.information?.saldoDisponivel.toLocaleString(
    "pt-BR",
    { minimumFractionDigits: 2 }
  );

  const onBandeirarContaClick = async () => {
    try {
      await vipagemService.bandeirarConta(
        clientID,
        client?.product?.codigoProduto,
        numeroCartaoTruncado
      );

      notifySucces("Sucesso ao bandeirar conta");

      await refreshClient();
      await reloadCards(); 
    } catch (e) {
      notifyError("Erro ao bandeirar conta");
    }
  };

  const printData = useCallback(async () => {
    const data = new Date();
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    const dataAtual = `${dia}/${mes}/${ano}`;

    // TODO: Refazer em um .js a parte, local onde recebemos os dados do emissor e do cartao
    setDataPrintPdf([
      {
        nomeEmissor: "LOPES SUPERMERCADOS",
        CNPJ_Emissor: "45.827.425/0001-07",
        cartaoEmissor: `ADMINISTRADOR ${descProduct}`,

        nomeTitular: clientName,
        numeroCartao: numeroCartaoTruncado,
        opcaoVencimento: diaVencimento,
        Validade_ate: `Válido até: ${dataAtual}`,
        Validade: `São Paulo, ${dataAtual}`,
        LimiteCredito: `R$ ${limitePrincipal}`,
        LimiteRotativoDisponivel: `R$ ${saldoDisponivel}`,
      },
    ]);
  }, [
    descProduct,
    clientName,
    numeroCartaoTruncado,
    diaVencimento,
    limitePrincipal,
    saldoDisponivel
  ]);

  const statusCliente = client.client?.statusCliente;
  const isPossibleChangeLimit = useCallback(async () => {
    if (statusCliente === "B") {
      setIfChangeLimit(false);
    }
  }, [statusCliente]);

  useEffect(() => {
    try {
      if (!isHistoryCard) {
        getData();
      }
      getDetailsBlockCard();
      printData();
      isPossibleChangeLimit();
    } catch (erro) {
      console.error("erro ao listar tipos de bloqueio", erro);
    }
  }, [
    getData,
    getDetailsBlockCard,
    isHistoryCard,
    isPossibleChangeLimit,
    printData,
  ]);

  const showModalBlock = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const showModalUnBlock = useCallback(() => {
    setIsUnBlockModalVisible(true);
  }, []);

  const showHistoryBlockModal = useCallback(() => {
    setIsHistoryBlockModalVisible(true);
  }, []);

  const showHistoryLimitModal = useCallback(() => {
    setIsHistoryLimitModalVisible(true);
  }, []);

  const showInformationModal = useCallback(() => {
    setIsInformationModalVisible(true);
  }, []);

  const showChangeLimitModal = useCallback(() => {
    setIsChangeLimitModalVisible(true);
  }, []);

  const showCopyModal = useCallback(() => {
    setIsCopyModalVisible(true);
  }, []);

  const showResetPasswordModal = useCallback(() => {
    setIsResetModalVisible(true);
  }, []);

  const showCancelModal = useCallback(() => {
    setIsCancelModalVisible(true);
  }, []);

  const showCancelPlModal = useCallback(() => {
    setIsCancelPlModalVisible(true);
  }, []);

  const showPrintModal = useCallback(() => {
    setIsPrintModalVisible(true);
  }, []);

  const closeModalBlock = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const closeModalUnBlock = useCallback(() => {
    setIsUnBlockModalVisible(false);
  }, []);

  const closeModalCopy = useCallback(() => {
    setIsCopyModalVisible(false);
  }, []);

  const closeHistoryModalBlock = useCallback(() => {
    setIsHistoryBlockModalVisible(false);
  }, []);

  const closeHistoryModalLimit = useCallback(() => {
    setIsHistoryLimitModalVisible(false);
  }, []);

  const closeInformationModalBlock = useCallback(() => {
    setIsInformationModalVisible(false);
  }, []);

  const closeChangeLimitModal = useCallback(() => {
    setIsChangeLimitModalVisible(false);
  }, []);

  const closeCancelModal = useCallback(() => {
    setIsCancelModalVisible(false);
  }, []);

  const closeCancelPlModal = useCallback(() => {
    setIsCancelPlModalVisible(false);
  }, []);
  const closePrintModal = useCallback(() => {
    setIsPrintModalVisible(false);
  }, []);

  const confirmExchange = useCallback(async () => {
    try {
      await Api.post("card_confirm_exchange", {
        card_id: card.id,
        product_id: client?.product?.codigoProduto,
      });
      notifySucces("Troca realizada com sucesso.");
    } catch (error) {
      notifyError(error.message);
    } finally {
      reloadCards();
    }
  }, [card, client]);

  const reactivateCard = useCallback(async () => {
    try {
      await Api.patch("card_canceled_unblock", {
        client_id:client.client.id,
        card_id: card.id,
        cod_product: card.idProduto,
        masked_number:card.panMascarado,
        action: 'Reativado',
      });
      notifySucces("Cartão reativado com sucesso.");
    } catch (error) {
      notifyError(error.message);
    } finally {
      reloadCards();
      reloadCardsHistory();
    }
  }, [card, client]);

  const showPrintPdf = useCallback(async () => {
    let numberCardEncrypt = null;

    if (card.numeroMascarado === client.information.numeroCartaoTruncado) {
      numberCardEncrypt = client.information.numeroCartaoCriptografado;
    } else {
      numberCardEncrypt = card.numero;
    }

    Api.post("get_purchase_term", {
      encryptedCardNumber: numberCardEncrypt,
      client_id: client.client.id,
    }).then(async (res) => {
      const respDecrypt = await decrypt(res?.data);
      if(!respDecrypt) throw new Error('Erro ao gerar termo de compra')
      const data = new Date();
      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const ano = data.getFullYear();
      const dataAtual = `${dia}/${mes}/${ano}`;

      const classPrint = new PrintPdf([
        {
          nomeEmissor: "LOPES SUPERMERCADOS",
          CNPJ_Emissor: "45.827.425/0001-07",
          cartaoEmissor: `ADMINISTRADOR ${descProduct}`,

          nomeTitular: card.nome,
          numeroCartao: respDecrypt.toString().match(/.{4}/g).join(" "),
          opcaoVencimento: diaVencimento,
          Validade_ate: `Válido até: ${dataAtual}`,
          Validade: `São Paulo, ${dataAtual}`,
          LimiteCredito: `R$ ${limitePrincipal}`,
          LimiteRotativoDisponivel: `R$ ${saldoDisponivel}`,
        },
      ]);
      const document = await classPrint.MakeDoc();
      pdfMake.createPdf(document).open({}, window.open("", "_blank"));
    }).catch(function error(e) {
        notifyError(e.message);
    });
  }, [dataPrintPdf]);

  const renderTermPurchase = () => {
    if (
      (permissions.includes("purchase_term") &&
        !isHistoryCard &&
        client.client.statusCliente === "A") ||
      permissions.includes("term_purchase_blocked_accounts")
    ) {
      return (
        <Item key="9" onClick={showPrintPdf}>
          <span>Emitir termo de compra</span>
        </Item>
      );
    }

    return null;
  };

  const menu = (
    <Menu>
      {permissions && permissions.includes("view_detail_card") && (
        <Item key="0" onClick={showInformationModal}>
          <span>Mais informações</span>
        </Item>
      )}

      {permissions &&
        permissions.includes("view_history") &&
        !isHistoryCard && (
          <Item key="1" onClick={() => {
            showHistoryBlockModal();
            historyBlockModalRef.current?.getData()
          }}>
            <span>Histórico de bloqueio</span>
          </Item>
        )}

      {permissions && !isHistoryCard && (
        <Item key="2" onClick={() =>{
          showHistoryLimitModal();
          historyLimitModalRef.current?.getData()
          }}>
          <span>Histórico de limite</span>
        </Item>
      )}

      {permissions &&
        permissions.includes("change_password") &&
        !isHistoryCard && (
          <Item key="3" onClick={showResetPasswordModal}>
            <span>Resetar senha</span>
          </Item>
        )}
      {permissions && permissions.includes("set_card_password") && (
        <SetPasswordMenuItem
          cardId={card.id}
          productId={productID}
          isHistoryCard={isHistoryCard}
          clientId={clientID}
        />
      )}
      {isBandeirado &&
        permissions &&
        permissions.includes("cancel_card") &&
        !isHistoryCard && (
          <Item key="4" onClick={showCancelModal}>
            <span>Cancelar</span>
          </Item>
        )}
      {!isBandeirado &&
        permissions &&
        permissions.includes("cancel_card") &&
        !isHistoryCard && (
          <Item key="5" onClick={showCancelPlModal}>
            <span>Cancelar</span>
          </Item>
        )}
      {!isBandeirado &&
        permissions.includes("print_card") &&
        !isHistoryCard &&
        (card.statusEmbossing !== "EMBOSSADO" ? (
          <Item key="6" onClick={showPrintModal}>
            <span>Imprimir</span>
          </Item>
        ) : (
          <Item key="6" title="Este cartão já está impresso" disabled>
            <span>Imprimir</span>
          </Item>
        ))}
      {permissions.includes("second_via") && !isHistoryCard && canSecondCopyExtraValidation && (
        <Item key="7" onClick={showCopyModal}>
          <span>Solicitar 2ª via</span>
        </Item>
      )}


      {permissions &&
        ifChangeLimit &&
        permissions.includes("change_limit") &&
        !isHistoryCard && (
          <Item key="8" onClick={showChangeLimitModal}>
            <span>Alterar limite</span>
          </Item>
        )}

      {!client?.client?.isProdutoBandeirado &&
        client?.client?.canMakeTransitionToPf &&
        client?.client?.transicaoBandeira === "I" && (
          <Can permission="vipagem_bandeirar_conta">
            <Item key="8" onClick={onBandeirarContaClick}>
              <span>Solicitar a transição para bandeira</span>
            </Item>
          </Can>
        )}

      {permissions.includes("change_client_card") && canChangeCardExtraValidation && (
        <Item key="9" onClick={() => setIsChangeClientCardModalVisible(prev => !prev)}>
          <span>Solicitar Troca</span>
        </Item>
      )}

      {renderTermPurchase()}
    </Menu>
  );

  return (
    <>
      <ModalPrint
        isModalVisible={isPrintModalVisible}
        closeModal={closePrintModal}
        lojas={store}
        card={card}
        client_id={clientID}
      />

      <ModalBlockCard
        isModalVisible={isModalVisible}
        blockValue={blockValue}
        blockTypes={blockTypes}
        closeModal={closeModalBlock}
        clientID={clientID}
        productID={productID}
        card={card}
        bloqCard={bloqCard}
        setNewDetailBlockCard={setNewDetailBlockCard}
        codProduct={codProduct}
        reloadCards={reloadCards}
      />

      <ModalCopyCard
        isModalVisible={isCopyModalVisible}
        blockValue={blockValue}
        blockTypes={copyBlockTypes}
        closeModal={closeModalCopy}
        card={card}
        isDependent={isDependent}
        copyCard={copyCard}
        client_id={clientID}
        isBandeirado={isBandeirado}
        codProduct={codProduct}
        reloadCards={reloadCards}
      />
      <ModalUnBlockCard
        isModalVisible={isUnBlockModalVisible}
        closeModal={closeModalUnBlock}
        card={card}
        unBloqCard={unBloqCard}
        clientID={clientID}
        productID={productID}
        setDetailBlockCard={setDetailBlockCard}
        reloadCards={reloadCards}
      />

      {!isHistoryCard && (
        <HistoryBlockModal
          isModalVisible={isHistoryBlockModalVisible}
          closeModal={closeHistoryModalBlock}
          card={card}
          ref={historyBlockModalRef}
        />
      )}

      <HistoryLimitModal
        isModalVisible={isHistoryLimitModalVisible}
        closeModal={closeHistoryModalLimit}
        ref={historyLimitModalRef}
      />

      <InformationCardModal
        isModalVisible={isInformationModalVisible}
        closeModal={closeInformationModalBlock}
        card={card}
      />
      <ChangeLimitCardModal
        isModalVisible={isChangeLimitModalVisible}
        closeModal={closeChangeLimitModal}
        clientID={clientID}
      />

      {!isHistoryCard && (
        <CancelCardModal
          isModalVisible={isCancelModalVisible}
          closeModal={closeCancelModal}
          card={card}
          clientID={clientID}
          productID={productID}
          cancelCard={cancelCard}
          reloadCards={reloadCards}
          reloadCardsHistory={reloadCardsHistory}
        />
      )}

      <CancelCardPlModal
        isModalVisible={isCancelPlModalVisible}
        closeModal={closeCancelPlModal}
        card={card}
        cancelTypes={blockTypes}
        clientID={clientID}
        productID={productID}
        cancelCard={bloqCard}
        isBandeirado={isBandeirado}
      />

      <RenewPassword
        isModalVisible={isResetModalVisible}
        closeModal={() => {
          setIsResetModalVisible(false);
        }}
        card={card}
        clientID={clientID}
        productID={productID}
      />

      <ChangeClientCardModal
        isVisible={isChangeClientCardModalVisible}
        onCancel={() => setIsChangeClientCardModalVisible(false)}
        productId={card.id}
        client_id={clientID}
        cardNumber={card.numeroMascarado}
        cardId={card.id}
      />

      {card && (
        <S.CreditCardContainer v2={newClass}>
          <S.CreditCardTitle v2={newClass}>
            {card.status !== "ATIVO" && card.status !== "A" ? (
              <Tooltip
                title={
                  <>
                    {newDetailBlockCard && newDetailBlockCard?.tipoBloqueio && (
                      <div>Descrição: {newDetailBlockCard?.tipoBloqueio}</div>
                    )}
                    {newDetailBlockCard && newDetailBlockCard?.observacao && (
                      <div>Observação: {newDetailBlockCard?.observacao}</div>
                    )}
                    {newDetailBlockCard && newDetailBlockCard?.dataBloqueio && (
                      <div>
                        Data Bloqueio: {newDetailBlockCard?.dataBloqueio}
                      </div>
                    )}
                  </>
                }
              >
                <span className="bloqueado">
                  {card.status &&
                    card.status[0] +
                      card.status.slice(1).toLowerCase().replace("_", " ")}
                </span>
              </Tooltip>
            ) : (
              <span>
                {card.status &&
                  card.status[0] +
                    card.status.slice(1).toLowerCase().replace("_", " ")}
              </span>
            )}

            {card.status !== "B" &&
              card.status !== "BLOQUEADO" &&
              card.status !== "BLOQUEADO_TEMPORARIO" &&
              card.status !== "BLOQUEADO_PERMANENTE" &&
              !isHistoryCard &&
              card.status !== "PENDENTE_TROCA" &&
              card.status !== "CARTAO_TROCA" &&
              card.status !== "PENDENTE_CONFIRMACAO_TROCA" && (
                <Can permission="block">
                  <button type="button" onClick={showModalBlock}>
                    Bloquear
                  </button>
                </Can>
              )}
            {(card.status === "BLOQUEADO" ||
              card.status === "B" ||
              card.status === "BLOQUEADO_TEMPORARIO") &&
              !isHistoryCard && (
                <Can permission="unblock">
                  <button type="button" onClick={showModalUnBlock}>
                    Desbloquear
                  </button>
                </Can>
              )}

            {(card.status === "CARTAO_TROCA" ||
              card.status === "PENDENTE_TROCA" ||
              card.status === "PENDENTE_CONFIRMACAO_TROCA") && (
              <button type="button" onClick={confirmExchange}>
                Confirmar Troca
              </button>
            )}

            {(permissions.includes("reactivate_canceled_card") &&
              card.permiteReativar &&
              (card.status.toUpperCase() === "C" ||
              card.status.toUpperCase() === "CANCELADO")) && (
              <button type="button" onClick={reactivateCard}>
                Reativar
              </button>
            )}

            <S.CreditCardTitleMoreOption>
              <Dropdown overlay={menu} trigger={["click"]}>
                <span className="ant-dropdown-link">
                  <div className="solid-bar">
                    {newClass ? (
                      <img src={iconMenuOrange} alt="Icone menu" />
                    ) : (
                      <img src={iconMenu} alt="Icone menu" />
                    )}
                  </div>
                </span>
              </Dropdown>
            </S.CreditCardTitleMoreOption>
          </S.CreditCardTitle>
          <S.CreditCardBody>
            {newClass ? (
              <>
                <S.CreditCardRow v2={newClass}>
                  <div className="left-credit-card">
                    <span>{card.numeroMascarado}</span>
                    <span>{card.nome}</span>
                    <span>{`${dataValidade[1]}/${dataValidade[0]}`}</span>
                  </div>
                  <div className="right-credit-card">
                    {!card.virtual && (
                      <span className="new-status-card-physical">
                        Cartão Físico
                      </span>
                    )}
                    {card.virtual && (
                      <span className="new-status-card-virt">
                        Cartão Virtual
                      </span>
                    )}
                    {card.statusEmbossing === "EMBOSSADO" && (
                      <span className="new-status-card-embossing">
                        impresso
                      </span>
                    )}
                  </div>
                </S.CreditCardRow>
              </>
            ) : (
              <>
                <S.CreditCardRow>
                  <span>{card.numeroMascarado}</span>
                  {!card.virtual && (
                    <span className="credit-card-physical">Cartão Físico</span>
                  )}
                  {card.virtual && (
                    <span className="credit-card-virt">Cartão Virtual</span>
                  )}
                </S.CreditCardRow>
                <S.CreditCardRow>
                  <span>{card.nome}</span>
                </S.CreditCardRow>
                <S.CreditCardRow>
                  <span>{`${dataValidade[1]}/${dataValidade[0]}`}</span>
                  {card.statusEmbossing === "EMBOSSADO" && (
                    <span className="credit-card-embossing">impresso</span>
                  )}
                </S.CreditCardRow>
              </>
            )}
            <div className="logo-bottom">
                    <img
                      src="/images/logo-pequeno-elevaty.png"
                      alt="Logo Elevaty"
                    />
                  </div>
          </S.CreditCardBody>
        </S.CreditCardContainer>
      )}
    </>
  );
}

export default memo(CreditCard);
