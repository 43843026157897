import React, { memo } from 'react';

import { Button, Form, Input } from 'antd';

import { STATUSES } from '../../../../components/Status';
import SolicitationFileInput from '../SolicitationFileInput';
import * as S from './style';

const { TextArea } = Input;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} é obrigatório',
};
/* eslint-enable no-template-curly-in-string */

function ChangeSolicitationForm({
  onFinish,
  isLoading,
  onCancel,
  nextStatus,
  setFiles,
}) {
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <SolicitationFileInput setFiles={setFiles} />

      <Form.Item
        name="description"
        label="Observações"
        rules={[{ required: true }]}
      >
        <TextArea rows={3} maxLength={512} showCount />
      </Form.Item>

      <S.FormActionButtons>
        <Button htmlType="button" onClick={onCancel} disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          htmlType="submit"
          loading={isLoading}
          type={STATUSES.RESOLVED === nextStatus ? 'primary' : undefined}
          danger={STATUSES.CANCELLED === nextStatus}
        >
          {STATUSES.RESOLVED === nextStatus && 'Finalizar Solicitação'}
          {STATUSES.CANCELLED === nextStatus && 'Cancelar Solicitação'}
        </Button>
      </S.FormActionButtons>
    </Form>
  );
}

export default memo(ChangeSolicitationForm);
