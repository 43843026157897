import api from './api';

const endpoint = 'solicitations';

export class SolicitationService {
  static convertToFormData({ files = [], ...request } = {}) {
    const formData = new FormData();
    Object.keys(request).forEach((key) => {
      const value = request[key];
      if (value) {
        formData.append(key, value);
      }
    });
    files.forEach((file) => {
      formData.append('files[]', file, file.name);
    });
    return formData;
  }

  static create({
    client_birthday,
    company_id,
    ticket_protocol,
    client_product_id,
    client_cpf,
    client_name,
    client_card_id,
    type_id,
    description,
    priority,
    client_id,
    files,
  } = {}) {
    const formData = this.convertToFormData({
      client_birthday,
      client_id,
      company_id,
      ticket_protocol,
      client_product_id,
      client_cpf,
      client_name,
      client_card_id,
      type_id,
      description,
      priority,
      files,
    });
    return api.post(`${endpoint}`, formData, true);
  }

  static list({
    client_cpf,
    ticket_protocol,
    id,
    client_name,
    user_name,
    attendant_name,
    priority,
    status,
    type,
    page = 1,
    limit = 15,
    sorter,
  } = {}) {
    return api
      .get(endpoint, {
        id,
        client_cpf,
        page,
        limit,
        status,
        type,
        priority,
        ticket_protocol,
        client_name,
        user_name,
        attendant_name,
        sorter: sorter.order
          ? `${sorter.field ?? sorter.columnKey},${sorter.order}`
          : null,
      })
      .then((response) => ({
        ...response.data,
        data: response?.data.data.map((item) => ({
          key: item.id,
          ...item,
        })),
      }));
  }

  static initiate(id) {
    const formData = this.convertToFormData({
      description: 'Atendimento iniciado',
    });
    return api.post(`${endpoint}/${id}/initiate`, formData, true);
  }

  static attachments(id) {
    return api.get(`${endpoint}/${id}/attachments`);
  }

  static cancel(id, { description, files } = {}) {
    const formData = this.convertToFormData({ description, files });
    return api.post(`${endpoint}/${id}/cancel`, formData, true);
  }

  static finish(id, { description, files } = {}) {
    const formData = this.convertToFormData({ description, files });
    return api.post(`${endpoint}/${id}/finish`, formData, true);
  }

  static addComment(id, description ) {
    return api.post(`${endpoint}/${id}/comment`, description);
  }

  static refresh(id) {
    return api.get(`${endpoint}/${id}`);
  }
}
