import React, { useCallback, useEffect, useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { AiOutlineCloseCircle, AiOutlineFilePdf, AiOutlineFileText, AiOutlineInfoCircle, AiOutlineMore, AiOutlineSend } from 'react-icons/ai';

import userCheck from '../../../../../assets/icons/user-check.png';
import userPlus from '../../../../../assets/icons/user-plus.png';
import LoadingElevaty from '../../../../../components/LoadingElevaty';
import ModalIframe from '../../../../../components/ModalIframe'
import { usePermissions } from '../../../../../Context/permissionContext';
import { useAuth } from '../../../../../hooks/AuthProvider';
import api from '../../../../../services/api';
import { formatClientName } from '../../../../../utils/formatClientName';
import { formatCpf } from '../../../../../utils/formatCpf';
import { notifyError, notifySucces } from '../../../../../utils/notifications';
import ModalDetailInsurance from '../../../../home/modals/modalDetailInsurance';
import { ModalSendMailOrSms } from '../../../../home/modals/ModalSendMailOrSms/ModalSendMailOrSms';
import * as S from './style';


const ModalViewInsurances = ({
  client,
  open,
  closeModal,
  setDependents,
  clientId,
  clientInsurances,
  openNewInsuranceModal,
  reloadClientInsurances,
}) => {
  const { user } = useAuth();
  const { permissions } = usePermissions();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [insurances, setInsurances] = useState([]);
  const [selectedBeneficiaryForNewInsurance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMailModal, setLoadingMailModal] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [listDependents, setListDependents] = useState([]);
  const [totalInsurance, setTotalInsurance] = useState(0);
  const [setLoadingPdfBilhet] = useState(false);
  const [handleClickRow, setHandleClickRow] = useState({});
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [selectedInsuranceForCancellation, setSelectedInsuranceForCancellation] = useState(null);
  const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false);
  const [holderOrDependent, setIsHolderOrDependent] = useState('titular');
  const [isAdhesionAgreementOpen, setAdhesionAgreementOpen] = useState(false);
  const [urlAdhesionAgreement, setUrlAdhesionAgreement] = useState("");

  const handleClickSendEmail = (insurance) => {
    setHandleClickRow(insurance);
    setIsSendMailModalOpen(true);
  };

  const printTicketInsurance = useCallback(async (row) => {
    try {
      // setLoadingPdfBilhet(true);
      const resp = await api.get(
        'insurance_ticket_contracts',
        { client_id: clientId, contract_id: row.codigoAdesao },
        { 'Content-Type': 'application/pdf' },
        true
      );
      const fileURL = URL.createObjectURL(await resp.blob());
      const pdfWindow = window.open(fileURL);
      setTimeout(() => {
        pdfWindow.document.title = 'Bilhete_Contrato.pdf';
      }, 750);
      // setLoadingPdfBilhet(false);
    } catch (error) {
      // setLoadingPdfBilhet(false);
      notifyError('Seguro não possui bilhete para emitir');
    }
  }, [clientId]);

  const printTermInsurance = useCallback((row) => {
    window.open(
      `/pdf?clientId=${clientId}&contractId=${row.codigoAdesao}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, [clientId]);

  async function cancelInsurance(row) {
        try {
      await api.delete('client_insurances', {
        client_id: clientId,
        codigoAdesao: row?.codigoAdesao,
        meioCancelamento: 'SNXPAYMENT',
        usuarioCancelamento: user.username,
        lojaCancelamento: 1,
        descricaoSeguro: row.descricaoSeguro,
      });
      notifySucces(`Seguro ${row.descricaoSeguro} cancelado com sucesso`);
      reloadClientInsurances();
      setInsurances(
        insurances.filter((i) => i.codigoAdesao !== row.codigoAdesao)
      );
    } catch (error) {
      const errorMessage = error.message.split(',')[0];
      notifyError(errorMessage);
    }
    setIsCancelModalVisible(false);
  }

  const cancelInsuranceDependent = async (row) => {
    try {
      await api.delete('dependant_insurances', {
        client_id:  row?.codigoDependente,
        codigoAdesaoDependente: row.codigoAdesaoDependente,
        meioCancelamento: 'SNXPAYMENT',
        usuarioCancelamento: user?.username,
        lojaCancelamento: 1,
        descricaoSeguro: row.descricaoSeguro,
      });
      notifySucces(
        `Seguro ${row.descricaoSeguro} cancelado com sucesso`
      );

      setInsurances(
        insurances.filter((i) => i.codigoAdesao !== row.codigoAdesao)
      );
    } catch (error) {
      notifyError(`Erro ${error.message}`);
    } finally {
      setIsCancelModalVisible(false);
    }
  };

  const openDetailModal = (insuranceDetails) => {
    setSelectedInsurance(insuranceDetails);
    setIsDetailModalOpen(true);
  };

  const handleModalAdhesionAgreement = async (insurance) => {
    if (!isAdhesionAgreementOpen) {
      await setUrlAdhesionAgreement(`${window.location.protocol}//${window.location.host}/pdf?clientId=${clientId}&contractId=${insurance.codigoAdesao}`);
    } else {
      await setUrlAdhesionAgreement('');
    }
     await setAdhesionAgreementOpen(prevState => !prevState);
  }
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const loadDependents = async () => {
      if (!open) {
        return;
      }
      setLoading(true);
      try {
        const dependentData = await setDependents(clientId);
        if (isMounted) {
          setListDependents(dependentData || []);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
          setListDependents([]);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    if (clientId && open) {
      loadDependents();
    }
    return () => {
      isMounted = false;
    };
  }, [clientId, setDependents, open]);

  useEffect(() => {
    const calculateTotalInsurance = () => {
      let totalSum = 0;
      insurances.forEach((seguro) => {
        const valorParcela = parseFloat(seguro.valorParcela.replace('R$ ', '').replace(',', '.'));
        totalSum += valorParcela;
      });
      setTotalInsurance(totalSum);
    };
    calculateTotalInsurance();
  }, [insurances]);

  const handleSelection = async (person, typePerson) => {
    setIsHolderOrDependent(typePerson)
    setSelectedBeneficiary(person);
    setLoading(true);

    if (typePerson === 'titular') {
      setInsurances(clientInsurances);
      setLoading(false);
    }

    if (typePerson === 'dependente') {
      try {
        const insurancePromises = clientInsurances.map(async (insurance) => {
          const { data } = await api.get('insurance_detail', {
            client_id: clientId,
            codigo_adesao: insurance.codigoAdesao,
          });
          const dependentInsurance = data.dependentes.find(d => String(d.codigoDependente) === String(person.id));
          return dependentInsurance ? { ...insurance, ...dependentInsurance } : null;
        });
        const resolvedInsurances = (await Promise.all(insurancePromises)).filter(Boolean);
        setInsurances(resolvedInsurances);
      } catch (error) {
        console.error(error);
        setInsurances([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setSelectedBeneficiary(null);
    setListDependents([]);
    setLoading(false);
    closeModal();
  };

  const showCancelConfirmationModal = (insurance) => {
    setSelectedInsuranceForCancellation(insurance);
    setIsCancelModalVisible(true);
  };


  const onContractNewInsurance = () => {
    openNewInsuranceModal();
  };

  const handleSendInsuranceMail = async (mail) => {
    try {
      setLoadingMailModal(true);
      await api.post('send_insurance_email', {
        codigo_adesao: handleClickRow.codigoAdesao,
        client_id: client.client.id,
        client_name: client.client.name,
        ticket: client.client.ticket.protocol,
        email: mail,
        product_code: client.product.codigoProduto,
        product_description: client.product.desc_prod
      });
      setIsSendMailModalOpen(false);
      notifySucces('Enviado com sucesso');
    } catch (error) {
      notifyError('Falha ao enviar email');
    } finally {
      setLoadingMailModal(false);
    }
  }

  useEffect(() => {
    if (open) {
      handleSelection(client, 'titular');
    }
  }, [open, client]);


  const getMenu = (insurance) => {
    const menuItems = [];
    if (insurance.possuiBilhete) {
      menuItems.push(
        <S.StyledMenuItem key="emitirBilhete" onClick={() => printTicketInsurance(insurance)}>
          <AiOutlineFilePdf size={24} /> Emitir bilhete
        </S.StyledMenuItem>

      );
    }
    if (insurance.possuiTermoAdesao) {
      menuItems.push(
        <S.StyledMenuItem key="emitirTermo" onClick={() => handleModalAdhesionAgreement(insurance)}>
          <AiOutlineFileText size={24} /> Emitir termo de adesão
        </S.StyledMenuItem>
      );
    }
    if (permissions && permissions.includes('cancel_insurance')) {
      menuItems.push(
        <S.StyledMenuItem key="cancelar" onClick={() => showCancelConfirmationModal(insurance)}>
          <AiOutlineCloseCircle size={24} /> Cancelar
        </S.StyledMenuItem>
      );
    }
    menuItems.push(
      <S.StyledMenuItem key="enviar" onClick={() => handleClickSendEmail(insurance)}>
        <AiOutlineSend size={24} /> Enviar
      </S.StyledMenuItem>
    );

    menuItems.push(
      <S.StyledMenuItem key="informacoes" onClick={() => openDetailModal(insurance)}>
        <AiOutlineInfoCircle size={24} /> Informações
      </S.StyledMenuItem>
    );
    return (
      <S.StyledMenu>
        {menuItems}
      </S.StyledMenu>
    );
  };

  return (
    <S.ModalViewInsurance
      visible={open}
      title={<S.ModalTitle>Seguros Contratados</S.ModalTitle>}
      width={1013}
      onCancel={handleCancel}
      footer={null}
    >
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <>
        <S.NewSelectInsurance>
          <S.SelectedPerson>
            <h2>Para:</h2>
            <S.NewSelectedPerson>
              {[client, ...listDependents].map((person, index) => (
                <S.PersonContainer
                  key={person.id || index}
                  isSelected={selectedBeneficiary === person || selectedBeneficiaryForNewInsurance === person}
                  onClick={() => handleSelection(person, person === client ? 'titular' : 'dependente')}
                >
                  <div className='personContainer__title'>
                    <h3>
                      <img src={person === client ? userCheck : userPlus} alt={person === client ? 'user check icon' : 'user Plus icon'} />
                      {person === client ? 'Titular' : 'Dependente'}
                    </h3>
                  </div>
                  <p><strong>Nome: </strong>{formatClientName(person === client ? person?.client?.name : person?.nome)}</p>
                  <p><strong>CPF: </strong>{person === client ? person?.client?.cpf ? formatCpf(person.client.cpf) : 'CPF não disponível' : person?.cpf ? formatCpf(person.cpf) : 'CPF não disponível'}</p>
                  <div className="checkmark">
                    <CheckOutlined />
                  </div>
                </S.PersonContainer>
              ))}
            </S.NewSelectedPerson>
          </S.SelectedPerson>
          <S.VerticalDivider />
          <S.InsuranceInfo>
            <S.NewInsuranceContainer>
              {insurances.length > 0 && (
                <h2>Seguros contratados:</h2>
              )}
              <S.NewContractedInsurances>
                {insurances.length > 0 ? (
                  insurances.map((insurance) => (
                    <S.InsuranceRow key={insurance.codigoAdesao}>
                      <S.NewInsuranceInfo>
                        <p>{insurance.codigoAdesao}</p>
                        <p>{insurance.descricaoSeguro}</p>
                      </S.NewInsuranceInfo>
                      <S.InsuranceValueActions>
                        <S.NewInsuranceInfo>
                          <p>Valor:</p>
                          <p className='contractedInsurance__value'>{insurance.valorParcela}</p>
                        </S.NewInsuranceInfo>
                        <S.InsuranceActions>
                          <Dropdown
                            overlay={getMenu(insurance)}
                            trigger={['click']}
                            placement="bottomRight"
                          >
                            <AiOutlineMore className="custom-icon" />
                          </Dropdown>
                        </S.InsuranceActions>
                      </S.InsuranceValueActions>
                    </S.InsuranceRow>
                  ))
                ) : (
                  <S.NoInsuranceRow>
                    <S.LargeWarningIcon />
                    <h3> Nenhum seguro encontrado para a pessoa selecionada!</h3>
                  </S.NoInsuranceRow>
                )}
              </S.NewContractedInsurances>
              {insurances.length > 0 && (
                <S.TotalInsuranceInfo>
                  <p>Total</p>
                  <h2>R$ {totalInsurance.toFixed(2)}</h2>
                </S.TotalInsuranceInfo>
              )}
            </S.NewInsuranceContainer>
            <S.ActionButtonsContainer>
              <Button
                type="primary"
                onClick={onContractNewInsurance}
              >
                <CheckOutlined />
                {isMobile ? 'Contratar +' : 'Contratar novo seguro'}
              </Button>
            </S.ActionButtonsContainer>
          </S.InsuranceInfo>
        </S.NewSelectInsurance>
        <ModalDetailInsurance
          open={isDetailModalOpen}
          closeModal={() => setIsDetailModalOpen(false)}
          insurance={selectedInsurance}
        />
        <S.CancelaInsuranceModal
          title="Confirmar Cancelamento"
          visible={isCancelModalVisible}
          onCancel={() => setIsCancelModalVisible(false)}
          footer={null}
        >
          <p>Você confirma o cancelamento do seguro <strong> {selectedInsuranceForCancellation?.descricaoSeguro}</strong>?</p>
          <S.ActionCancelButtons>
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => setIsCancelModalVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (holderOrDependent === 'titular') {
                  cancelInsurance(selectedInsuranceForCancellation)
                } else if (holderOrDependent === 'dependente'){
                  cancelInsuranceDependent(selectedInsuranceForCancellation)
                }
              }}
            >
              Confirmar
            </Button>
          </S.ActionCancelButtons>
        </S.CancelaInsuranceModal>
      </>
      {isSendMailModalOpen && (
        <ModalSendMailOrSms
          isOpen={isSendMailModalOpen}
          isLoading={loadingMailModal}
          onClose={() => setIsSendMailModalOpen(false)}
          isSmsEnabled={false}
          clientMail={client?.client?.email}
          onSubmitMail={handleSendInsuranceMail}
        />
      )}
      <>
        <ModalIframe
        visible={isAdhesionAgreementOpen}
        onClose={handleModalAdhesionAgreement}
        url={urlAdhesionAgreement}
        title="Termo de adesão"
        />
      </>

    </S.ModalViewInsurance >
  );
};

export default ModalViewInsurances;
