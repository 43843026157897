import React, { memo, useCallback, useState } from 'react';

import { Button, Modal } from 'antd';

import Api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

function RenewPassword({
  closeModal,
  card,
  isModalVisible,
  productID,
  clientID,
}) {
  const [loading, setLoading] = useState(false);

  const reset = useCallback(async () => {
    try {
      setLoading(true);
      const resetResponse = await Api.patch('card_new_password', {
        card_id: card.id,
        cod_product: productID,
        client_id: clientID,
      });
      notifySucces(resetResponse?.data?.msg);
      closeModal();
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  }, [card, clientID, closeModal, productID]);

  return (
    <Modal
      title="Resetar senha"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
    >
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.WrapperSelectBlock>
        <h2>Deseja realmente resetar a senha do cartão?</h2>
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        <S.WrapperButtonsReset>
          {!loading && (
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              Cancelar
            </Button>
          )}
          {!loading && (
            <Button onClick={reset} type="primary">
              Resetar
            </Button>
          )}
        </S.WrapperButtonsReset>
      </S.WrapperSelectBlock>
    </Modal>
  );
}

export default memo(RenewPassword);
