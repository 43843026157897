import React, { memo, useState } from "react";

import InstallmentAnticipationDetails from "./InstallmentAnticipationDetails";
import Element from "./InstallmentAnticipationList";
import { INSTALLMENT_ANTICIPATION } from "./model/InstallmentAnticipationStep";
import Parcelamento from "./model/Parcelamento";

interface InstallmentAnticipationListProps {
  onCloseClick: () => void;
  parcelamentos: Parcelamento[];
}
function InstallmentAnticipationList({
  onCloseClick,
  parcelamentos,
}: InstallmentAnticipationListProps) {
  const [selected, setSelected] = useState<Parcelamento>();

  const [step, setStep] = useState(INSTALLMENT_ANTICIPATION.INITIAL);

  return (
    <>
      {step === INSTALLMENT_ANTICIPATION.INITIAL && (
        <Element
          parcelamentos={parcelamentos}
          onSelect={(parcelamento) => {
            setSelected(parcelamento);
            setStep(INSTALLMENT_ANTICIPATION.DETAIL);
          }}
        />
      )}
      {step === INSTALLMENT_ANTICIPATION.DETAIL && selected && (
        <InstallmentAnticipationDetails
          parcelamento={selected}
          onCloseClick={onCloseClick}
        />
      )}
    </>
  );
}

export default memo(InstallmentAnticipationList);
