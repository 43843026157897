import styled from 'styled-components';

export const WrapperSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  span {
    margin-bottom: 5px;

    red {
      font-weight: 600;
      color: #ff4d4f;
    }
  }
  .text {
    margin-bottom: 10px;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  h3 {
    width: 100%;
    text-align: center;
    background: #fa8c16;
    color: #fff;
    border-radius: 10px;
    padding: 5px 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 17px;
  margin-bottom: 2px;
  border-top: 1px solid #ccc;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
  button {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    width: 113px;
    height: 40px;
    margin-bottom: 0px;
    margin-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #272d3b;
  }
  .button-confirm {
    border: 1px solid #faad14;
    background: #faad14;
    color: #ffffff;
  }
  .button-confirm:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border: 1px solid #ccc;
    opacity: 0.5;
  }
`;

export const NewLoadingModals = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;
