import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';

import Table from '../../../../components/Table';
import { CompanyParams } from '../../../../Context/companyContext';
import api from '../../../../services/api';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import * as S from '../style';
import ButtonActionTable from './ButtonActionTable';
import ClassificationProcess from './Processes/ClassificationProcess';
import SubclassificationProcess from './Processes/SubclassificationProcess';

function ModalClassificationsRelations({
  editModalOpen,
  setEditModalOpen,
  selectedGroupId,
}) {
  const { companyParams } = CompanyParams();
  const [subClassifications, setSubClassifications] = useState([]);
  const issuersToApplyTheClassificationProcess = ["vuon"];

  async function fetchIssuerGroupSubclassifications() {
    const response = await api.get('role_classification', {
      role_id: selectedGroupId,
    });
    return response.data;
  }

  function getProcessForCurrentIssuer(issuerName) {
    const formmatedIssuerName = String(issuerName).trim().toLowerCase();
    const applyClassificationProcessToCurrentIssuer = issuersToApplyTheClassificationProcess
      .some(name => formmatedIssuerName.includes(name.trim().toLowerCase()))
    return applyClassificationProcessToCurrentIssuer ? "CLASSIFICATION" : "SUBCLASSIFICATION";
  }

  useEffect(() => {
    const setupIssuerGroupSubClassifications = async () => {
      const issuerGroupSubClassifications = await fetchIssuerGroupSubclassifications();
      setSubClassifications(issuerGroupSubClassifications);
    }
    if (editModalOpen) {
      setupIssuerGroupSubClassifications();
    }
  }, [editModalOpen])

  async function relateSubclassificationToIssuerGroup(subclassification) {
    if (!subclassification?.id) notifyError('Selecione uma classificação');

    try {
      await api.post("relate_classifications", {
        classification_id: subclassification?.id,
        role_id: selectedGroupId
      });

      const updatedIssuerGroupSubclassifications = await fetchIssuerGroupSubclassifications();
      setSubClassifications(updatedIssuerGroupSubclassifications);
      return true;

    } catch (error) {
      notifyError(`Erro ao relacionar Sub-Classificação "${subclassification?.name}"`);
      return false;
    }
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'deleted_at',
      width: 100,
      render: (row) =>
        !row ? (
          <S.StatusActive>Ativo</S.StatusActive>
        ) : (
          <S.StatusInactive>Inativo</S.StatusInactive>
        ),
    },
    {
      title: 'Ações',
      dataIndex: '',
      width: 100,
      render: (row) => {
        row.role_id = selectedGroupId;
        return (
          <ButtonActionTable
            solicitationType={row}
            setClassifications={setSubClassifications}
          />
        );
      },
    },
  ];

  return (
    <Modal
      visible={editModalOpen}
      title="Classificações do Grupo"
      width={1000}
      height={600}
      closeModal={() => setEditModalOpen(false)}
      onCancel={() => setEditModalOpen(false)}
    >
      <S.ModalContainer>
        <S.ModalRow>
          <S.ContainerSelect>
            {getProcessForCurrentIssuer(companyParams?.name) === "CLASSIFICATION" ?
              <ClassificationProcess relateSubClassificationFn={relateSubclassificationToIssuerGroup} /> :
              <SubclassificationProcess relateSubclassificationFn={relateSubclassificationToIssuerGroup} />
            }
          </S.ContainerSelect>
          <S.PageInfoContainer>
            <Table
              columns={columns}
              rows={subClassifications}
              page={5}
              showPaginationNav
            />
          </S.PageInfoContainer>
        </S.ModalRow>
      </S.ModalContainer>
    </Modal>
  );
}

export default ModalClassificationsRelations;
