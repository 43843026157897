import React from 'react';

import { Button } from 'antd';
import classnames from 'classnames';

import styles from '../style.module.scss';

function Footer({ step, goBack, onCloseClick }) {
  return (
    <div className={styles.modal__footer}>
      <span>
        <Button
          onClick={goBack}
          className={classnames({
            [styles.hidden]: step < 1,
          })}
        >
          Voltar
        </Button>
      </span>

      <div>
        <Button
          type="primary"
          onClick={onCloseClick}
          className={styles.modal__footer__button_next}
        >
          Sair
        </Button>
      </div>
    </div>
  );
}

export default React.memo(Footer);
