import React, { memo } from "react";

import { EyeOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";

import { formatDateTime } from "../../../../../../utils/formatter";
import Parcelamento from "./model/Parcelamento";

interface InstallmentAnticipationListProps {
  parcelamentos: Parcelamento[];
  onSelect: (parcelamento: Parcelamento) => void;
}
function InstallmentAnticipationList({
  parcelamentos,
  onSelect,
}: InstallmentAnticipationListProps) {
  const columns = [
    {
      title: "Nome",
      key: "nomeUsuario",
      render: (row: any) => <span>{row.nomeUsuario}</span>,
    },
    {
      title: "Data e horário de simulação",
      key: "dataSimulacao",
      render: (row: any) => <span>{formatDateTime(row.dataSimulacao)}</span>,
    },
    {
      title: "Valor financiado (R$)",
      dataIndex: "valorParcela",
      key: "valorParcela",
    },
    {
      title: "Tipo",
      render: (row: any) =>
        row?.devedorTotal === "N" ? "Fatura" : "Saldo Devedor",
    },
    {
      title: "Quant. de Parcelas",
      dataIndex: "quantidadeParcelas",
      key: "quantidadeParcelas",
    },
    {
      title: "Valor total (R$)",
      dataIndex: "valorTotal",
      key: "valorTotal",
    },
    {
      width: 100,
      title: "Ações",
      render: (row: any) => (
        <Tooltip title="Visualizar detalhes do parcelamento">
          <Button onClick={() => onSelect(row)}>
            <EyeOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={parcelamentos}
      pagination={false}
      scroll={{ y: 240 }}
      rowKey="idPagamentoAtivacao"
    />
  );
}

export default memo(InstallmentAnticipationList);
