import { List } from 'antd';
import styled from 'styled-components';

export const PurchasesTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
`;

export const PurchasesTimelineItem = styled.div`
  width: 100%;
  border-bottom: 1px solid #b2bec3;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const ListItemMeta = styled(List.Item.Meta)`
  .ant-list-item {
    &-meta-title,
    &-meta-description {
      color: black;
      font-weight: 700;
    }
  }
`;

export const PurchasesTimelineRow = styled.div`
  display: flex;
  width: 100%;
  font-size: 13px;
  margin-top: 7px;

  span {
    margin-right: 10px;
  }
  .date {
  }
  .status {
    margin-right: 15px;
    background: #f6ffed;
    border: 1px solid #52c41a;
    color: #52c41a;
    padding: 1px;
    text-align: center;
  }
  .place {
  }
  .value {
  }
  .refund {
    color: #fa8c16;
    cursor: pointer;
  }
`;

export const StyledList = styled(List)`
  .ant-pagination {
    text-align: center;

    &-item {
      padding: 0 4px;
    }
  }
`;

export const StyledH1 = styled.h1`
  font-weight: 700;
`;
