import { useCallback, useEffect, useMemo, useState } from 'react';

import Api from '../services/api';

const cache = {};

function useDependantCard(adicionais, codProduto) {
  const [loadingDependantCard, setLoadingDependantCard] = useState(false);
  const [dependantCards, setDependantCards] = useState([]);

  const getData = useCallback(async () => {
    const cacheKey = `adicional-${adicionais.map(a => a.id).join('-')}-produto-${codProduto}`;

    if (cache[cacheKey]) {
      setDependantCards(cache[cacheKey]);
      return;
    }

    setLoadingDependantCard(true);

    try {
      let adicionalCards = await Promise.all(adicionais.map(async (adicional) => {
        try {
          const adicionalData = await Api.get('adicional_cards', { adicional_id: adicional.id });
          return adicionalData.data;
        } catch (error) {
          console.error(`Erro ao buscar dados do adicional ${adicional.id}`, error);
          return [];
        }
      }));

      adicionalCards = adicionalCards.flat();
      const formatedCards = await Promise.all(adicionalCards.map(async (adicional) => {
        try {
          const { data: { permit } } = await Api.get('get_permission_print', {
            card_id: adicional.id,
            cod_produto: codProduto,
          });
          return { ...adicional, show_card: true, permit_print: permit };
        } catch (error) {
          console.error(`Cartão de id ${adicional.id} do adicional ${adicional.idAdicional} não encontrado.`, error);
          return { show_card: false };
        }
      }));

      const cardsToShow = formatedCards.filter(v => v.show_card);
      cache[cacheKey] = cardsToShow;
      setDependantCards(cardsToShow);
    } catch (error) {
      console.error("Erro ao buscar dados dos cartões dependentes", error);
    } finally {
      setLoadingDependantCard(false);
    }
  }, [adicionais, codProduto]);

  useEffect(() => {
    getData();
  }, [getData]);

  const unBloqCardDependent = useCallback(async (card) => {
    setDependantCards(previousCards => previousCards.map(previousCard => {
      if (previousCard.id === card.id) {
        return { ...previousCard, status: '', bloqueioCartao: null };
      }
      return previousCard;
    }));
  }, []);

  const bloqCardDependent = useCallback(async (card, bloqData) => {
    setDependantCards(previousCards => previousCards.map(previousCard => {
      if (previousCard.id === card.id) {
        return { ...previousCard, status: 'BLOQUEADO', bloqueioCartao: bloqData };
      }
      return previousCard;
    }));
  }, []);

  const cancelCardDependent = useCallback(async (card) => {
    setDependantCards(previousCards => previousCards.filter(previousCard => previousCard.id !== card.id));
  }, []);

  const copyCardDdependent = useCallback(async (previousId, newId) => {
    setDependantCards(cards => cards.map(card => {
      if (Number(card.id) === Number(previousId)) {
        return { ...card, id: newId, permit_print: true };
      }
      return card;
    }));
  }, []);

  return useMemo(() => ({
    loadingDependantCard,
    dependantCards,
    unBloqCardDependent,
    bloqCardDependent,
    copyCardDdependent,
    cancelCardDependent,
  }), [
    dependantCards,
    loadingDependantCard,
    unBloqCardDependent,
    bloqCardDependent,
    copyCardDdependent,
    cancelCardDependent,
  ]);
}

export default useDependantCard;
