import React, { useCallback, useState } from 'react';

import { Modal } from 'antd';
import classnames from 'classnames';

import { useClient } from '../../../../../hooks/ClientProvider';
import { InvoiceInstallmentService } from '../../../../../services/invoiceInstallmentService';
import { notifySucces, notifyError } from '../../../../../utils/notifications';
import InstallmentInformation from '../components/InstallmentInformation';
import Footer from './Footer';
import StepOneOption from './steps/StepOneOptions';
import styles from './style.module.scss';

function InvoiceInstallmentsModal({
  visible,
  onCloseClick,
  invoices,
  devedorTotal = false,
  title = 'Parcelamento de fatura',
}) {
  const [selectedInstallmentOption, setSelectedInstallmentOption] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isActivationFinished, setIsActivationFinished] = useState(false);
  const [step, setStep] = useState(0);
  const { client } = useClient();

  const onInstallmentOptionClickHandler = useCallback((option) => {
    setSelectedInstallmentOption(option[0]);
  }, []);

  const onSubmitInvoiceInstallmentClickHandler = useCallback(async () => {
    setIsLoading(true);

    try {
      await InvoiceInstallmentService.activate({
        devedorTotal,
        clientId: client.client.id,
        params: selectedInstallmentOption,
      });

      setIsActivationFinished(true);

      notifySucces('Operação realizada com sucesso!');
    } catch (e) {
      notifyError('Operação não realizada!');
    } finally {
      setIsLoading(false);
    }
  }, [client, selectedInstallmentOption]);

  const goBack = useCallback(() => {
    setStep((prevState) => prevState - 1);
  }, []);

  const goNext = useCallback(() => {
    if (step === 0) {
      return setStep((prevState) => prevState + 1);
    }

    return onSubmitInvoiceInstallmentClickHandler();
  }, [onSubmitInvoiceInstallmentClickHandler, step]);

  return (
    <Modal
      centered
      width="80%"
      visible={visible}
      onCancel={onCloseClick}
      title={title}
    >
      <StepOneOption
        devedorTotal={devedorTotal}
        setSelectedInstallmentOption={onInstallmentOptionClickHandler}
        selectedInstallmentOption={selectedInstallmentOption}
        className={classnames({
          [styles.hidden]: step !== 0,
        })}
        onCloseClick={onCloseClick}
      />

      <InstallmentInformation
        selectedInstallmentOption={selectedInstallmentOption}
        invoices={invoices}
        className={classnames({
          [styles.hidden]: step !== 1,
        })}
      />

      <Footer
        step={step}
        goNext={goNext}
        goBack={goBack}
        isLoading={isLoading}
        onCloseClick={onCloseClick}
        isActivationFinished={isActivationFinished}
        selectedInstallmentOption={selectedInstallmentOption}
      />
    </Modal>
  );
}

export default React.memo(InvoiceInstallmentsModal);
