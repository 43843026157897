import React, { memo, useEffect, useState } from 'react';

import { Divider, Skeleton } from 'antd';

import CreditCardBase from '../../../../components/CreditCardBase';
import { useClient } from '../../../../hooks/ClientProvider';
import useToggle from '../../../../hooks/useToggle';
import { vipagemService } from '../../../../services/vipagemService';
import { notifyError } from '../../../../utils/notifications';
import LeftContent from './LeftContent';
import Menu from './Menu';

function CardsInTransition({ reloadCards }) {
  const [cardsInTransition, setCardsInTransition] = useState([]);
  const [isLoading, toggleLoading] = useToggle();
  const { client } = useClient();

  const fetchCardsInTransition = async () => {
    toggleLoading();
    try {
      const result = await vipagemService.cartoesBandeiradosTransicao(
        client?.client?.id
      );

      const cardsInTransitionFormatted = result?.map((card) => ({
        ...card,
        nome: card.nomePortadorCartao,
        numeroMascarado: card.panMascarado,
        status: card.statusCartao,
        dataValidade: card.dataExpiracao,
      }));

      setCardsInTransition(cardsInTransitionFormatted);
    } catch (e) {
      notifyError('Erro ao carregar cartões em transição');
    } finally {
      toggleLoading();
    }
  };

  useEffect(() => {
    if (client?.client?.transicaoBandeira === 'T') {
      fetchCardsInTransition();
    }
  }, [client]);

  return (
    <Skeleton loading={isLoading}>
      {client?.client?.transicaoBandeira === 'T' &&
        !!cardsInTransition.length && <Divider>Cartões em transição</Divider>}
      {cardsInTransition?.map((card) => (
        <CreditCardBase
          inTransition
          menu={
            <Menu card={card} fetchCardsInTransition={fetchCardsInTransition} />
          }
          key={card?.id}
          leftContent={<LeftContent card={card} reloadCards={reloadCards} />}
          virtual={card.virtual}
          cardHolderName={card.nome}
          expirationYear={card?.dataValidade?.split('-')?.[0]}
          expirationMonth={card?.dataValidade?.split('-')?.[1]}
          statusEmbossing={card.statusEmbossing}
          maskedCreditCardNumber={card.numeroMascarado}
        />
      ))}
    </Skeleton>
  );
}

export default memo(CardsInTransition);
