import React from "react";

import { MdOutlineChevronRight } from "react-icons/md";

import Can from "../Can";
import LoadingElevaty from "../LoadingElevaty";
import * as Style from "./style";

function QuickAccessCardComponent({
  icon,
  title,
  contentLeft,
  onClickButton,
  loading = true,
  permissionButton
}) {
  return (
    <Style.QuickAccessCardComponentContent>
      {loading ? (
        <Style.QuickAccessCardComponentLoading>
          <LoadingElevaty width="100" />
        </Style.QuickAccessCardComponentLoading>
      ) : (
        <>
          <Style.QuickAccessCardComponentIcon>
            {icon}
          </Style.QuickAccessCardComponentIcon>

          <Style.QuickAccessCardComponentTitle>
            <p>{title}</p>
          </Style.QuickAccessCardComponentTitle>

          <Style.QuickAccessCardComponentSubItems>
            <div className="sub-items__left">
              {contentLeft}
            </div>

            <div className="sub-items__right">
              <Can permission={permissionButton}>
                <button type="button" onClick={onClickButton}>
                  Contratar novo <MdOutlineChevronRight />
                </button>
              </Can>
            </div>
          </Style.QuickAccessCardComponentSubItems>
        </>
      )}
    </Style.QuickAccessCardComponentContent>
  );
}

export default QuickAccessCardComponent;
