import React from "react";

import * as AntIcons from "@ant-design/icons";

const iconMapping = {
  PullRequestOutlined: AntIcons.PullRequestOutlined,
  SettingOutlined: AntIcons.SettingOutlined,
  StarOutlined: AntIcons.StarOutlined,
  UserAddOutlined: AntIcons.UserAddOutlined,
  UsergroupAddOutlined: AntIcons.UsergroupAddOutlined,
  PrinterOutlined: AntIcons.PrinterOutlined,
  UploadOutlined: AntIcons.UploadOutlined,
  CreditCardOutlined: AntIcons.CreditCardOutlined,
  UsergroupDeleteOutlined: AntIcons.UsergroupDeleteOutlined,
  RiseOutlined: AntIcons.RiseOutlined,
  DollarOutlined: AntIcons.DollarOutlined,
  FileDoneOutlined: AntIcons.FileDoneOutlined,
  ContainerOutlined: AntIcons.ContainerOutlined,
  FileTextOutlined: AntIcons.FileTextOutlined,
  OrderedListOutlined: AntIcons.OrderedListOutlined,
  HistoryOutlined: AntIcons.HistoryOutlined,
  ProfileOutlined: AntIcons.ProfileOutlined,
  LockOutlined: AntIcons.LockOutlined,
  ShopOutlined: AntIcons.ShopOutlined,
  UserOutlined: AntIcons.UserOutlined,
  ContactsOutlined: AntIcons.ContactsOutlined
};

function getIconFromName(icon) {
  const IconComponent = iconMapping[icon];
  if (IconComponent) {
    return <IconComponent />;
  }
  return null;
}

export { getIconFromName };
