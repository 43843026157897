import React, { memo } from 'react';

import ReissueCard from './Items/ReissueCard';

function Index({ card, fetchCardsInTransition }) {
  return (
    <ReissueCard
      key={1}
      card={card}
      fetchCardsInTransition={fetchCardsInTransition}
    />
  );
}

export default memo(Index);
