import styled from 'styled-components';

export const Table = styled.table`
  td {
    padding-right: 6px;
    padding-bottom: 6px;
  }
`;

export const ContainerCreateEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
export const DescriptionInfo = styled.div`
  word-break: break-all;
`;

export const ContentInfoLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: black;
  font-weight: bold;
`;

export const AttachmentIcon = styled.div`
  margin-right: 6px;
`;

export const AttachmentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  > div {
    width: 100%;
  }
`;
