import styled, { css } from "styled-components";


export const QuickAccessCardComponentLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`


export const QuickAccessCardComponentContent = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const QuickAccessCardComponentIcon = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #ffb32c;
  color: #272d3b;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
  }
`;

export const QuickAccessCardComponentTitle = styled.div`
  width: 100%;
  margin-top: 12px;

  p {
    padding: 0;
    margin: 0;

    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const QuickAccessCardComponentSubItems = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  align-items: center;
  gap: 16px;

  .sub-items__left,
  .sub-items__right {
    width: 50%;

    p {
      padding: 0;
      margin: 0;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #8c8c8c;

      strong{
        cursor: pointer;
      }
    }
  }

  .sub-items__right {
    display: flex;
    justify-content: end;
    align-items: center;

    button {
      background: #ffb32c;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;

      padding: 8px 16px 8px 16px;
      border-radius: 2px;
      border: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        font-size: 23px;
      }
    }
  }
`;
