import React, { memo, useCallback, useState } from 'react';

import BarsSolid from '../../../assets/BarsSolid';
import Can from '../../../components/Can';
import Card from '../../../components/Card';
import LoadingElevaty from '../../../components/LoadingElevaty';
import Table from '../../../components/Table';
import usePremiumAnnuity from '../../../hooks/usePremiumAnnuity';
import ModalAnnuityAdhered from '../../home/modals/ModalAnnuityAdhered';
import ModalAnnuityInformation from '../../home/modals/ModalAnnuityInformation';
import ModalListAnnuities from '../../home/modals/ModalListAnnuities';
import * as S from '../style';

function PremiumAnnuityV2() {
  const {
    ref,
    isLoadingAnnuity,
    premiumAnnuity,
    reload: reloadPremiumAnnuity,
    allBonnusAnnuity,
    columnPremiumAnnuity,
    openAnnuityModal,
    setOpenAnnuityModal,
    activeIdAnnuity,
    cancelIdAnnuity,
    openModalAnnuityInformation,
    setOpenModalAnnuityInformation,
    selectedAnnuityInformation,
  } = usePremiumAnnuity();
  const [openModal, setOpenModal] = useState(false);
  const [listAnnuities, setListAnnuities] = useState([]);

  const handleOpenModal = useCallback(async () => {
    setOpenModal(true);
    setListAnnuities(allBonnusAnnuity);
  });

  return (
    <>
      <S.WrapperSeguros className="mt-3" ref={ref}>
        {isLoadingAnnuity ? (
          <S.WrapperNewLoading >
            <LoadingElevaty />
          </S.WrapperNewLoading>
        ) : (
          <Card
            title="Anuidade contratada"
            modalTitle="Anuidade contratada"
            loading={isLoadingAnnuity}
            extra={[
              <S.MenuInsegurance>
                <Can permission="join_premium_annuity">
                  <div className="solid-bar">
                    <BarsSolid
                      alt="Icone menu"
                      onClick={() => handleOpenModal()}
                    />
                  </div>
                </Can>
              </S.MenuInsegurance>,
            ]}
          >
            <Table
              rows={premiumAnnuity}
              columns={columnPremiumAnnuity}
              page={10}
            />
          </Card>
        )}
      </S.WrapperSeguros>
      <ModalAnnuityAdhered
        open={openAnnuityModal}
        closeModal={() => {
          setOpenAnnuityModal(false);
        }}
        reloadPremiumAnnuity={reloadPremiumAnnuity}
        activeIdAnnuity={activeIdAnnuity}
        cancelIdAnnuity={cancelIdAnnuity}
      />
      <ModalListAnnuities
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        reloadPremiumAnnuity={reloadPremiumAnnuity}
        listAnnuities={listAnnuities}
      />
      <ModalAnnuityInformation
        open={openModalAnnuityInformation}
        closeModal={() => {
          setOpenModalAnnuityInformation(false);
        }}
        reloadPremiumAnnuity={reloadPremiumAnnuity}
        selectedAnnuityInformation={selectedAnnuityInformation}
      />
    </>
  );
}

export default memo(PremiumAnnuityV2);
