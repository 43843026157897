import React from 'react';

import LowerSection from './LowerSection';
import UpperSection from './UpperSection';

function InstallmentInformation({
  selectedInstallmentOption,
  className,
  invoices,
}) {
  if (!selectedInstallmentOption) {
    return null;
  }

  return (
    <div className={className}>
      <UpperSection
        selectedInstallmentOption={selectedInstallmentOption}
        invoices={invoices}
      />
      <LowerSection selectedInstallmentOption={selectedInstallmentOption} />
    </div>
  );
}

export default React.memo(InstallmentInformation);
