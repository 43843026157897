import { React, useCallback } from 'react';

import { RiAlertLine } from 'react-icons/ri';
import Modal from 'react-modal';

import api from '../../services/api';
import { notifyError } from '../../utils/notifications';
import * as Style from './styles';

function ModalCancelInvoiceSms({
  statusOpen,
  closeModal,
  clientInfo,
  numberFormat,
}) {
  const handleSituation = useCallback(() => {
    api
      .patch('patch_invoice_sms', {
        client_id: clientInfo.clientId,
        celular_id: clientInfo.telId,
        patch_type: 'cancelamento',
        numberActive: numberFormat,
      })
      .then(() => {
        closeModal();
      })
      .catch((err) => {
        closeModal();
        notifyError('Erro ao cancelar fatura digital pelo celular');
        console.error(
          'Erro ao cancelar fatura digital pelo celular',
          err.message
        );
      });
  }, [numberFormat, clientInfo]);

  return (
    <Modal
      isOpen={statusOpen}
      onRequestClose={closeModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-situacao"
    >
      <Style.WrapperHeaderSituacao>
        <div className="modal-situacao">
          <div className="modal-situacao__alert">
            <RiAlertLine />
          </div>

          <div className="modal-situacao__text">
            <p>Cancelar fatura digital deste celular?</p>
            <span>Tem certeza?</span>

            <div className="btns">
              <button onClick={closeModal} type="button">
                Não, voltar
              </button>
              <button
                className="active-button"
                onClick={() => handleSituation()}
                type="button"
              >
                Sim, tenho certeza
              </button>
            </div>
          </div>
        </div>
      </Style.WrapperHeaderSituacao>
    </Modal>
  );
}

export default ModalCancelInvoiceSms;
