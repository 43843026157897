import styled from 'styled-components';

export const ModalTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  border-bottom: 1px solid #ccc;

  h1 {
    font-size: 17px;
    line-height: 28px;
    font-weight: bold;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #ccc;
    cursor: pointer;
    margin-bottom: 4px;
  }
`;

export const CreditCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  max-height: 500px;
  padding: 10px;
  background: #f5f5f5;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #b2bec3;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 15px;
`;

export const CreditCardTitle = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 10px;

  span {
    margin-right: 10px;
  }
  & > span:nth-child(1) {
    color: #52c41a;
    border: 1px solid #52c41a;
    padding: 5px;
    display: flex;
    justify-items: center;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  & > button:nth-child(2) {
    background: #fa8c16;
    color: #fff;
    border: 1px solid #fa8c16;
    padding: 5px;
    display: flex;
    justify-items: center;
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  & > button:nth-child(3) {
    background: #1890ff;
    color: #fff;
    border: 1px solid #1890ff;
    padding: 5px;
    display: flex;
    justify-items: center;
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
`;
export const CreditCardTitleMoreOption = styled.div`
  display: flex;
  width: 20%;
  flex: 1;
  justify-content: flex-end;
  align-content: center;
  justify-items: center;

  span {
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
  }
`;

export const CreditCardBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CreditCardRow = styled.div`
  margin: 5px 0;
  max-width: 100%;
  word-wrap: break-word;
  span {
    margin: 0 5px;
  }

  .credit-card-physical {
    margin-left: 15px;
    background: #e6f7ff;
    color: #1890ff;
    border: 1px solid #1890ff;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-embossing {
    margin-left: 250px;
    background: #f5f5f5;
    color: #6f5f4f;
    border: 0.5px solid #6f5f4f;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-virt {
    margin-left: 15px;
    background: #e6f7ff;
    color: #52c41a;
    border: 1px solid #52c41a;
    padding: 2px;
    font-size: 10px;
  }
`;

export const WrapperSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  span {
    margin-bottom: 5px;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  h3 {
    width: 100%;
    text-align: center;
    background: #fa8c16;
    color: #fff;
    border-radius: 10px;
    padding: 5px 0;
  }
`;

export const ListDescription = styled.div`
  span {
    margin-right: 5px;
  }
`;
export const ListDate = styled.div`
  span {
    margin-right: 5px;
  }
`;
export const TypeBlock = styled.div`
  span {
    margin-right: 5px;
  }
`;

export const ModalRow = styled.div`
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  margin-top: 10px;

  .btns-send {
    width: 100%;

    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px 24px;

      background: #faad14;
      color: #fff;
      border: 1px solid #bfbfbf;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      &:first-of-type {
        margin-right: 5px;
        background: #ffffff;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
`;

export const ModalRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  margin-top: 10px;

  .error-message {
    text-align: right;
    font-size: 12px;
    color: #ff4d4f;
  }
`;

export const inputNameFull = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const inputCreditCard = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 38%;
  }
`;

export const inputW50 = styled.div`
  width: 100%;

  p.title {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #272d3b;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ff4d4f;
      margin-right: 5px;
    }
  }

  .grau-card {
    .ant-select-selector {
      height: 40px;
      padding: 4px 12px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }

    &.error-id {
      border: 1px solid #ff4d4f;
    }
  }

  @media (min-width: 768px) {
    width: 49%;
  }
`;

export const inputW33 = styled.div`
  width: 100%;
  position: relative;

  .input-cep {
    position: relative;
  }

  p.title {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #272d3b;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ff4d4f;
      margin-right: 5px;
    }
  }

  .spinner {
    position: absolute;
    top: 41px;
    right: 5px;
    visibility: hidden;
    transform: translate3d(0, -10px, 0);

    &.active {
      visibility: inherit;
      transition: 0.3s;
      transform: translate3d(0px, 0, 0);
    }

    svg {
      animation: spinnerAction 1s infinite linear;
    }
  }

  @-webkit-keyframes spinnerAction {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerAction {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (min-width: 768px) {
    width: 32%;
  }
`;

export const inputW40 = styled.div`
  width: 100%;

  p.title {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #272d3b;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ff4d4f;
      margin-right: 5px;
    }
  }

  .grau-card {
    .ant-select-selector {
      height: 40px;
      padding: 4px 12px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }

    &.error-id {
      border: 1px solid #ff4d4f;
    }
  }

  @media (min-width: 768px) {
    width: 39%;
  }
`;

export const inputW20 = styled.div`
  width: 100%;

  p.title {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #272d3b;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ff4d4f;
      margin-right: 5px;
    }
  }

  @media (min-width: 768px) {
    width: 19%;
  }
`;

export const grayItems = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item-gray {
    width: 49%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      padding: 0;
      margin: 0;
    }
  }
`;

export const InformationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 700px;
  padding: 10px;
  background: #f5f5f5;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #b2bec3;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 15px;

  b {
    font-weight: 700;
  }
`;

export const ModalAddWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .ant-input-group-wrapper {
    margin-right: 5px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 5px;
`;

export const WrapperButtonsReset = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: space-around;
`;
