import React from "react";

import { Table } from "antd";
import { format } from "date-fns";

import { usePermissions } from "../../../Context/permissionContext";
import * as S from "../style";

function PaymentCreliqTable({ data, handleOpenModal }) {
  const { permissions } = usePermissions();
  function formatDate(valueDate) {
    const date = new Date(valueDate);
    date.setDate(date.getDate());

    return date;
  }

  const columns = [
    {
      title: "Nº da Conta",
      dataIndex: "numeroConta",
    },

    {
      title: "Nº de Autorização",
      dataIndex: "nuAutorizacao",
    },
    {
      title: "Forma de Pagamento",
      dataIndex: "formaPagamento",
    },
    {
      title: "Data Pagamento",
      dataIndex: "dataPagamento",
      render: (dataPagamento) => (
        <>{format(formatDate(dataPagamento), "dd/MM/yyyy")}</>
      ),
    },
    {
      title: "Valor Pago",
      dataIndex: "valorPago",
      render: (valorPago) => (
        <>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valorPago)}
        </>
      ),
    },
    {
      title: "Ação",
      width: 170,
      dataIndex: "idPagamento",
      align: "center",
      render: (idPagamento) => (
        <>
          {permissions.includes("creliq_payment_cancellation") && (
              <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => handleOpenModal(idPagamento)}
              >
                Cancelar Pagamento
              </button>
          )}
        </>
      ),
    },
  ];

  return (
    <S.TableContainer>
      <Table
        className="tableListsadjustment not-global"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        rowKey={(record) => record?.idPagamento}
      />
    </S.TableContainer>
  );
}

export default React.memo(PaymentCreliqTable);
