import styled from "styled-components";

export const CreditCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 345px;
  height: 220px;
  padding: 24px;
  background: url("/images/bg-card.png") no-repeat center;
  background-size: cover;
  border-radius: 12px;
  color: #fff;
  position: relative;
`;

export const CreditCardTop = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  > button {
    /* @TODO: cor da variável está diferente */
    /* background: var(--brand-color-gold-6, #ffb32c); */
    background-color: #ffb32c;
    outline: none;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    > svg {
      width: 14px;
      height: 14px;
      color: var(--neutral-color-dark-10, #272d3b);
    }
  }

  > span {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.005em;
    margin: 0;
  }
`;
export const CreditCardLogo = styled.img`
  position: absolute;
  right: 24px;
  top: 40px;
`;

export const CreditCardInfo = styled.div`
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.075em;
  text-transform: uppercase;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .credit-card-number {
    font-size: 18px;
  }

  .validity-container {
    text-align: right;
  }

  .credit-card-validity {
    font-size: 14px;
  }
`;

export const CreditCardBottom = styled.div`
  border-top: 1px solid var(--neutral-color-gray-9, #434343);
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CreditCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 24px;
  font-size: 14px;
  line-height: 22px;
  border: 0;
  outline: none;
  background-color: #ffb32c;
  color: var(--neutral-color-dark-10, #272d3b);
  border-radius: 2px;
  font-size: 500;
`;
