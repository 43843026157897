import { useMemo } from 'react';

import { usePermissions } from '../Context/permissionContext';

export default function useCheckPermission(permission) {
  const { permissions } = usePermissions();
  return useMemo(
    () => permissions && permissions.includes(permission),
    [permission, permissions]
  );
}
