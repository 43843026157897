import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
`;

export const BreadCrumbPage = styled.div`
  width: 100%;
  margin-bottom: 16px;

  p {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    span {
      font-weight: bold;
    }
  }
`;






export const SearchUserWrapper = styled.div`
  width: 400px;
`;

export const SearchUserTitle = styled.p`
  
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px;
  width: 100%;

  hr {
    border-top: 1px solid #ccc;
  }

  div.error-id {
    border: 1px solid #c53030;
  }

  .error {
    width: 100%;
    text-align: right;
    font-size: 1rem;
    color: #c53030;
  }

  input {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 9px;
    width: 100%;
    color: #666360;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &::placeholder {
      color: #928f8f;
      font-weight: 500;
    }

    &:disabled {
      color: #444 !important;
      background: #f5f5f5;
      border: 1px solid #ccc;
    }

    &.error-id {
      border: 1px solid #c53030;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    background: var(--primaryColor);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    border: 1px solid var(--primaryColor);

    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    &:hover {
      border: 1px solid var(--primaryColor);
    }
  }
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px; 
  min-width: 96px; 
  height: auto;
  font-weight: bold;

  ${(props) =>
    props.selected
      ? css`
          background: var(--primaryColor);
          border: 1px solid var(--primaryColor);
          color: white;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}

  &:hover {
    border: 1px solid var(--primaryColor);
  }
`;


export const CpfCnpj = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const InputsLabel = styled.div`
  span {
    font-weight: bold;
  }
  .margem {
    align-items: center;
  }
`;

export const FormAccount = styled.div`
  margin: 10px 0;
  width: 72.5%;
`;

export const SearchDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  
  .title{
    font-weight: bold;
  }

  .formWrapper{
    margin-top: 15px;
  }
  .date-selection-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .select-btn {
    margin-top: 15px; 
    align-self: flex-end; 
  }
`;
;

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableContainer = styled.div`
  margin-left: 1%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 98%;

  .ant-pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .btn-cancel{
    background: #F2363D;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }
`;

export const StatusActive = styled.div`
  display: flex;
  padding: 5px;
  width: 86px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid #27ae60;
  color: #27ae60;
  display: inline-block;
`;

export const StatusInactive = styled.div`
  display: flex;
  padding: 5px;
  width: 80px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid tomato;
  color: tomato;
  display: inline-block;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;