import React, { useState } from "react";

import * as S from "./style";

export function TabsCard({ tabs = [] }) {
  const [activeTab, setActiveTab] = useState(0);

  const activeTabContent = tabs.find(
    (_, tabIndex) => tabIndex === activeTab
  ).content;

  const tabsQuantity = tabs.length;

  return (
    <S.TabsCardWrapper>
      <S.TabsTitlesContainer>
        {tabs.map((tab, tabIndex) => (
          <S.TabTitle
            key={`tab-title-${tab.title}`}
            className={activeTab === tabIndex ? "active" : ""}
            onClick={() => setActiveTab(tabIndex)}
          >
            {tab.title}
          </S.TabTitle>
        ))}
        <S.ActiveTab activeIndex={activeTab} tabsQuantity={tabsQuantity} />
      </S.TabsTitlesContainer>
      {activeTabContent}
    </S.TabsCardWrapper>
  );
}
