import { memo, React, useCallback, useState, useEffect } from 'react';

import { Button, Modal } from 'antd';

import { useClient } from '../../../hooks/ClientProvider';
import * as S from '../../../pages/home/modals/styles';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import AntInput from '../../AntInput';

const { confirm } = Modal;

function ChangeLimitCardModal({
  isModalVisible,
  closeModal,
  reloadCards = null
}) {
  const [limitePrincipal, setLimitePrincipal] = useState('');
  const [alterLimit, setAlterLimit] = useState(true);
  const [erroNewLimit, setErroNewLimit] = useState(false);
  const [valueMask, setValueMask] = useState("");
  const [newLimit, setNewLimit] = useState("");

  const { client } = useClient();

  async function getData() {
    await api
      .get('client_information', { client_id: client.client.id })
      .then(async (response) => {
        await setLimitePrincipal(response.data.limitePrincipal);
      })
      .catch((erro) => {
        console.error('Falha', erro);
      });
  }

  useEffect(() => {
    if (client) getData();
  }, [client, isModalVisible]);

  const handleCancel = useCallback(() => {
    setValueMask('');
    setNewLimit('');
    setAlterLimit(true);
    closeModal();
  }, [closeModal, setNewLimit, setValueMask]);

  const changeNewLimit = useCallback(
    async (value) => {
      value = value.replace(/[^0-9]/g, "");
      if(value){
        const options = { minimumFractionDigits: 2 };
        const result = new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value) / 100
        );
        setValueMask(`R$ ${result}`);
        setNewLimit(result.replace(/[\.,]/g, match => (match === '.' ? '' : match === ',' ? '.' : match)));
      }
  },
    [setNewLimit, setValueMask]
  );

  const handleChangeLimit = useCallback(
    async (limit, limiteAtual) => {
      try {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(Number(limit))) {
          setErroNewLimit(true);
          return;
        }
        limit = Math.trunc(limit);
        await setNewLimit(limit);
        await api.patch('card_limit', {
          client_id: client.client.id,
          limiteAplicado: Number(limit),
          limiteProxPeriodo: Number(limit),
          limiteAnterior: limiteAtual,
        });
        setLimitePrincipal(limit);
        notifySucces(
          `Limite alterado para R$ ${Math.trunc(limit)},00 com sucesso`
        );
        if (typeof reloadCards === 'function') {
          reloadCards();
        }
      } catch (error) {
        if (erroNewLimit === false) {
          notifyError('Falha ao atualizar limite');
        } else if (erroNewLimit === true) {
          notifyError('Favor inserir um valor numérico válido');
        }
      } finally {
        setValueMask('');
        setNewLimit('');
        closeModal();
      }
    },
    [client, closeModal, erroNewLimit, setErroNewLimit, setNewLimit]
  );

  const handleAlterLimit = useCallback(
    async (limit, limiteAtual) => {
      if(parseInt(limit,10) === limiteAtual || limit <= 0){
        setAlterLimit(true);
      } else if (Math.trunc(limit) > 999999) {
        setAlterLimit(true);
        notifyError('Não é possível ter um limite com mais de 6 dígitos');
      }else{
         setAlterLimit(false);
      }
    },
    []
  );

  const showConfirm = (newLimit, limitePrincipal) => {
    confirm({
      title:  `Alterar limite para R$${Number(newLimit).toFixed(2).replace('.', ',')}?`,
      onOk() {
       handleChangeLimit(newLimit, limitePrincipal);
      },
      onCancel() {
        setValueMask('');
        setNewLimit('');
        closeModal();
      },
    });
  };

  return (
    <Modal
      title="Alterar limite do cartão"
      visible={isModalVisible}
      onCancel={handleCancel}
    >
      <b>
        <span>Limite atual</span>
      </b>
      <AntInput
        type="text"
        readonly="readonly"
        value={`R$ ${Number(limitePrincipal).toFixed(2).replace('.', ',')}`}
      />
      <p />
      <p />
      <p />
      <b>Valor do novo limite</b>
      <AntInput
        type="text"
        placeholder=" R$ 1.000,00"
        value={valueMask}
        onChange={(e) => changeNewLimit(e.target.value)}
        onKeyUp={() => {handleAlterLimit(newLimit, limitePrincipal)}}
      />
      <S.ModalRow>
        <S.ButtonAtualizar>
          <Button
          onClick={() => {
          setNewLimit(newLimit);
          showConfirm(newLimit,limitePrincipal);
          }}
          disabled={alterLimit} >
            Atualizar
          </Button>
        </S.ButtonAtualizar>
      </S.ModalRow>
    </Modal>
  );
}
export default memo(ChangeLimitCardModal);
