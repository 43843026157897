/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;

function Select({ name, children, ...props }) {
  const selectRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => selectValue,
    });
  }, [fieldName, registerField, selectValue]);

  return (
    <AntdSelect
      onChange={(value) => setSelectValue(value)}
      ref={selectRef}
      {...props}
    >
      {children}
    </AntdSelect>
  );
}

export { Option };
export default Select;
