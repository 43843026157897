import React, { useCallback } from 'react';

import { FiAlertCircle } from 'react-icons/fi';
import Modal from 'react-modal';

import api from '../../services/api';
import { notifyError } from '../../utils/notifications';
import * as Style from './styles';

function ModalChangeFaturaDigitalSms({
  statusOpen,
  closeModal,
  clientInfo,
  numberFormat,
  oldNumberFormat,
  phones,
  handleInvoiceSms,
}) {
  const oldNumberData = phones.find((i) => i.isRecebeFaturaSms === true)
  const newNumberData = phones.find((i) => i.area + i.numero === numberFormat.replace(/[\(\)\-\s]/g, ''))
  const handleConfirm = useCallback(() => {
    api
    .patch('patch_invoice_sms', {
      client_id: clientInfo.clientId,
      celular_id: oldNumberData.id,
      patch_type: 'cancelamento',
      numberActive: oldNumberFormat,
    })
    .then(() => {
      handleInvoiceSms("adesao", clientInfo.clientId, newNumberData.id, numberFormat);
      closeModal();
    })
    .catch((err) => {
      closeModal();
      notifyError('Erro ao cancelar fatura digital pelo celular');
      console.error(
        'Erro ao cancelar fatura digital pelo celular',
        err.message
      );
    });
  }, [clientInfo, numberFormat, oldNumberFormat]);

  return (
    <Modal
      isOpen={statusOpen}
      onRequestClose={closeModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-situacao"
    >
      <Style.WrapperHeaderSituacao>
        <div className="modal-situacao">
          <div className="modal-situacao__alert">
            <FiAlertCircle />
          </div>

          <div className="modal-situacao__text">
            <p>Atenção!</p>
            <span>
              Ao ativar, a sua fatura virá por SMS para o número {numberFormat}.
            </span>

            <div className="btns">
              <button onClick={closeModal} type="button">
                Não, voltar
              </button>
              <button
                className="active-button"
                onClick={() => handleConfirm()}
                type="button"
              >
                Sim, ativar
              </button>
            </div>
          </div>
        </div>
      </Style.WrapperHeaderSituacao>
    </Modal>
  );
}

export default ModalChangeFaturaDigitalSms;
