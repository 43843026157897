function obterNomeDoMes(numeroDoMes) {
  const meses = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];

  /* eslint-disable */
  // Verifica se o número do mês está dentro do intervalo válido (1 a 12)
  if (numeroDoMes >= 1 && numeroDoMes <= 12) {
    // Subtrai 1 porque os meses em JavaScript começam do zero no array
    return meses[numeroDoMes - 1];
  } else {
    return "Mês inválido";
  }
  /* eslint-disable */
}


module.exports = {
  obterNomeDoMes,
};
