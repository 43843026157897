import React from 'react';

import style from './style.module.scss';

function Section({ title = '', children, className='' }) {
  return (
    <div className={style.container}>
      {title && <h1>{title}</h1>}
      <div className={className}>{children}</div>
    </div>
  );
}

export default React.memo(Section);
