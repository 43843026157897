import React, { memo, useCallback, useState } from 'react';

import { Button } from 'antd';

import { usePermissions } from '../../../Context/permissionContext';
import { useAuth } from '../../../hooks/AuthProvider';
import { useClient } from '../../../hooks/ClientProvider';
import { SolicitationService } from '../../../services/solicitationService';
import { notifyError, notifySucces } from '../../../utils/notifications';
import SolicitationModal from '../SolicitationModal';

function CreateSolicitation({ onFinish, solicitation }) {
  const [isLoading, setIsLoading] = useState(false);
  const { client } = useClient();

  const { user } = useAuth();
  const { permissions } = usePermissions();

  const [showNewSolicitation, setShowNewSolicitation] = useState(false);
  const openNewSolicitationForm = useCallback(
    () => setShowNewSolicitation(true),
    []
  );
  const onCancel = useCallback(() => setShowNewSolicitation(false), []);

  const handleSubmit = useCallback(
    async (data) => {
      const request = {
        company_id: user?.company_id,
        ticket_protocol: client?.client?.ticket?.protocol,
        client_product_id: client?.product?.id,
        client_cpf: client?.client?.cpf,
        client_name: client?.client?.name,
        client_birthday: client?.client?.birthday,
        client_id: client?.client?.id,
        client_card_id: client?.information?.idCartao,
      };
      try {
        setIsLoading(true);
        await SolicitationService.create({ ...data, ...request });
        notifySucces('Solicitação criada com sucesso');
        setShowNewSolicitation(false);
        if (onFinish) {
          await onFinish();
        }
      } catch (err) {
        notifyError('Erro ao criar Solicitação', err.message);
        setShowNewSolicitation(false)
      } finally {
        setIsLoading(false);
      }
    },
    [
      client?.client?.birthday,
      client?.client?.cpf,
      client?.client?.id,
      client?.client?.name,
      client?.client?.ticket?.protocol,
      client?.information?.idCartao,
      client?.product?.id,
      onFinish,
      user?.company_id,
    ]
  );

  if (!client || permissions.indexOf('view_solicitation') === -1) {
    return null;
  }

  return (
    <>
      <Button type="button" onClick={openNewSolicitationForm}>
        Nova Solicitação
      </Button>
      {showNewSolicitation && (
        <SolicitationModal
          isLoading={isLoading}
          solicitation={solicitation}
          onCancel={onCancel}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}

export default memo(CreateSolicitation);
