import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";

import {
  ShopOutlined,
  UserOutlined,
  CaretDownOutlined,
  ContactsOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HistoryOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  PrinterOutlined,
  ProfileOutlined,
  PullRequestOutlined,
  SendOutlined,
  SettingOutlined,
  StarOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UploadOutlined,
  RiseOutlined,
  UsergroupDeleteOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip, Switch } from "antd";
import { BsGear } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { IoMenuSharp, IoGitPullRequestOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";

import { usePermissions } from "../../Context/permissionContext";
import { useAuth } from "../../hooks/AuthProvider";
import { useClient } from "../../hooks/ClientProvider";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ModalContentFinalize from "../../pages/home/modals/modalDetailFinalize";
import CreateSolicitation from "../../pages/solicitation/CreateSolicitation";
import ResetPasswordModal from "../../pages/solicitation/ResetPasswordModal";
import PayModal from "../../pages/solicitation/SellandPayModal/index";
import Api from "../../services/api";
import { notifyError } from "../../utils/notifications";
import Can from "../Can";
import LogoutListener  from "../LogoutListener";
import NewMenu from "../NewMenu/Menu";
import { SendTicketModalWrapper } from "./SendTicketModalWrapper/SendTicketModalWrapper";
import * as Style from "./styles";

const { SubMenu } = Menu;

function SideMenu({ children, menuSelected, isHome }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setopen] = useState(false);
  const [clientData, setClientData] = useState({});
  const [modalAlterarSenhaisOpen, setModalAlterarSenhaisOpen] = useState(false);
  const [modalPayisOpen, setModalPayisOpen] = useState(false);
  const [modalSellisOpen, setModalSellisOpen] = useState(false);
  const [obs, setObs] = useState("");
  const identity = localStorage.getItem("@SnxPayment:identity");
  const envLocal = localStorage.getItem("@SnxPayment:env");
  const env = envLocal ? JSON.parse(envLocal) : {};

  const { permissions } = usePermissions();

  const { user, signOut } = useAuth();
  const { client, clearClient } = useClient();
  const [modalIsOpenFinalize, setIsOpenFinalize] = useState(false);
  const [switchState, setSwitchState] = useState(true);
  const { updateClient } = useClient();

  const openModalAlterarSenhaisOpen = useCallback(
    () => setModalAlterarSenhaisOpen(true),
    []
  );

  const closeModalAlterarSenhaisOpen = useCallback(() => {
    setModalAlterarSenhaisOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    if (clientData && clientData.client && clientData.client.ticket) {
      notifyError(
        "Você precisa finalizar o atendimento antes de realizar o logout!"
      );
      return;
    }

    await signOut( user?.login);
    clearClient();
    navigate("/");
  }, [clearClient, clientData, navigate, signOut]);

  const changeRouteClick = useCallback(
    (route) => {
      setopen(false);
      navigate(route);
    },
    [navigate]
  );

  const openModal = () => {
    navigate("/home");
  };

  const finalizeAttendance = useCallback(async () => {
    try {
      await Api.post("attendance_finish", {
        client_id: clientData?.client?.id,
        classification_name: "Sem SubClassificar",
        group_name: "Sem Classificar",
        obs,
      });
      navigate("/home");
      updateClient(false);
    } catch (error) {
      notifyError("Erro ao finalizar atendimento.");
      console.error("Erro ao finalizar atendimento:", error);
    }
  }, [clientData, navigate, obs, updateClient]);

  const openModalFinalize = useCallback(() => {
    if (permissions && permissions.includes("finish_without_class")) {
      finalizeAttendance();
    } else {
      setIsOpenFinalize(true);
    }
  }, [permissions, finalizeAttendance]);

  const closeModalFinalize = useCallback((value) => {
    setObs(value);
    setIsOpenFinalize(false);
  }, []);

  const homeClick = useCallback(
    () => changeRouteClick("/atendimento"),
    [changeRouteClick]
  );

  const solicitationClick = useCallback(
    () => changeRouteClick("/solicitacoes"),
    [changeRouteClick]
  );

  const classificationClick = useCallback(
    () => changeRouteClick("/classificacao"),
    [changeRouteClick]
  );

  const userClick = useCallback(
    () => changeRouteClick("/usuario"),
    [changeRouteClick]
  );

  const groupClick = useCallback(
    () => changeRouteClick("/groups"),
    [changeRouteClick]
  );

  const printClick = useCallback(
    () => changeRouteClick("/printers"),
    [changeRouteClick]
  );

  const accessClick = useCallback(
    () => changeRouteClick("/access"),
    [changeRouteClick]
  );

  const bloqCardInLoteClick = useCallback(
    () => changeRouteClick("/bloqueio-cartao-em-lote"),
    [changeRouteClick]
  );

  const bloqAccountInLoteClick = useCallback(
    () => changeRouteClick("/bloqueio-contas-em-lote"),
    [changeRouteClick]
  );

  const alterInLoteClick = useCallback(
    () => changeRouteClick("/alteracao-limite-em-lote"),
    [changeRouteClick]
  );

  const solicitationTypeClick = useCallback(
    () => changeRouteClick("/tipos_solicitacoes"),
    [changeRouteClick]
  );

  const openModalPayisOpen = useCallback(() => setModalPayisOpen(true), []);

  const paymentsClick = useCallback(() => {
    openModalPayisOpen();
  }, []);

  const closeOpenModalPayisOpen = useCallback(() => {
    setModalPayisOpen(false);
  }, []);

  const closeOpenModalSellisOpen = useCallback(() => {
    setModalSellisOpen(false);
  }, []);

  const openModalSellisOpen = useCallback(() => setModalSellisOpen(true), []);

  const salesClick = useCallback(() => {
    openModalSellisOpen();
  }, []);

  const ScheduleShopkeeperClick = useCallback(
    () => changeRouteClick("/agenda-lojista"),
    [changeRouteClick]
  );

  const ListPayments = useCallback(
    () => changeRouteClick("/listagem-pagamentos"),
    [changeRouteClick]
  );

  const ListSells = useCallback(
    () => changeRouteClick("/listagem-vendas"),
    [changeRouteClick]
  );

  const ListBalanceReserve = useCallback(
    () => changeRouteClick("/listagem-reserva-de-saldo"),
    [changeRouteClick]
  );

  const ListPaymentsCreliq = useCallback(
    () => changeRouteClick("/listagem-pagamentos-creliq"),
    [changeRouteClick]
  );

  const onReportsClick = useCallback(
    () => changeRouteClick("/reports"),
    [changeRouteClick]
  );

  const productListClick = useCallback(
    () => changeRouteClick("/lista-de-produtos"),
    [changeRouteClick]
  );
  const storeListClick = useCallback(
    () => changeRouteClick("/lista-de-lojas"),
    [changeRouteClick]
  );
  const TypesAdjustmentsClick = useCallback(
    () => changeRouteClick("/lista-dos-tipos-de-ajustes"),
    [changeRouteClick]
  );
  const IssuerCycleListClick = useCallback(
    () => changeRouteClick("/lista-de-ciclos"),
    [changeRouteClick]
  );
  const LockTypesListClick = useCallback(
    () => changeRouteClick("/lista-dos-tipos-de-bloqueio-cartao"),
    [changeRouteClick]
  );

  const LockAccountListClick = useCallback(
    () => changeRouteClick("/lista-dos-tipos-de-bloqueio-conta"),
    [changeRouteClick]
  );

  const canceledInsuranceListClick = useCallback(
    () => changeRouteClick("/lista-de-seguros-cancelados"),
    [changeRouteClick]
  );

  const ListDefault = useCallback(
    () => changeRouteClick("/lista-de-inadimplencia"),
    [changeRouteClick]
  );

  const launch_payment = useCallback(
    () => changeRouteClick("/lancar-ajuste"),
    [changeRouteClick]
  );

  const sellListClick = useCallback(() => {
    changeRouteClick("/lancar-vendas");
  }, [changeRouteClick]);

  const launchPaymentClick = useCallback(() => {
    changeRouteClick("/lancar-pagamentos");
  }, [changeRouteClick]);

  const handleSwitchChange = async (checked) => {
    try {
      await Api.post("update_has_beta_active", {
        hasBetaActive: false,
      });
      window.location.reload();
    } catch (error) {
      console.error("erro", error.message);
      notifyError("Erro ao acessar versão oficial");
    }
  };

  const menuAvatar = useMemo(
    () => (
      <Menu>
        {permissions.includes("view_change_password") && (
          <Menu.Item key="0" onClick={openModalAlterarSenhaisOpen}>
            Alterar Senha
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    ),
    [handleLogout]
  );

  const toggleOpen = () => {
    setopen(!open);
  };

  const hasWindow = typeof window !== "undefined";
  const width = hasWindow ? window.innerWidth : null;

  const getReleasePdfs = async (checked) => {
    try {
      const response = await Api.get("release_pdfs");
      const pdfUrl = response.link;
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("erro", error.message);
      notifyError("Erro ao acessar guia de atendimento");
    }
  };

  useEffect(() => {
    setClientData(client);
  }, [client]);

  // useEffect(() => {
  //   if (width >= 1200) {
  //     setTypeMenu('vertical')
  //   } else {
  //     setTypeMenu('inline')
  //   }
  // }, [width]);

  return (
    <Style.WrapperSideMenu>
      <div className={`left-menu ${open ? "active" : ""}`}>
        <div className="sidebar-close">
          <IoMdClose onClick={toggleOpen} />
        </div>

        {modalIsOpenFinalize && (
          <ModalContentFinalize obs={obs} closeModal={closeModalFinalize} />
        )}

        {/* {modalPayisOpen && <PayModal onCancel={closeOpenModalPayisOpen} />} */}

        {/* {modalSellisOpen && ( */}
        {/*   <SellModal */}
        {/*     valueMask={valueMask} */}
        {/*     setValueMask={setValueMask} */}
        {/*     onCancel={closeOpenModalSellisOpen} */}
        {/*   /> */}
        {/* )} */}

        <div className="left-menu__content">
          <div className="left-menu__header">
            <img src="/images/logo-elevaty.svg" alt="Logo Elevaty" />
          </div>
          <div className="left-menu__menu">
            <Menu
              mode={width >= 1200 ? "vertical" : "inline"}
              defaultSelectedKeys={menuSelected}
            >
              {permissions.includes("view_solicitation") && (
                <Menu.Item
                  key="5"
                  icon={<PullRequestOutlined />}
                  onClick={solicitationClick}
                >
                  Solicitações
                </Menu.Item>
              )}

              {clientData && clientData.client && (
                <Menu.Item
                  key="1"
                  icon={<ContactsOutlined />}
                  onClick={homeClick}
                >
                  Atendimento
                </Menu.Item>
              )}

              {permissions &&
                (permissions.includes("view_list_product_issuer") ||
                  permissions.includes("view_types_adjustments") ||
                  permissions.includes("view_cycles_issuer") ||
                  permissions.includes("backoffice_view_list_lock_card") ||
                  permissions.includes(
                    "backoffice_view_list_lock_account"
                  )) && (
                  <SubMenu
                    key="sub7"
                    icon={<SettingOutlined />}
                    title="Parametros"
                    className="menu-snx"
                  >
                    {permissions.includes("view_list_product_issuer") && (
                      <Menu.Item
                        key="7.1"
                        icon={<ContainerOutlined />}
                        onClick={productListClick}
                      >
                        Produtos
                      </Menu.Item>
                    )}
                    {permissions.includes("view_types_adjustments") && (
                      <Menu.Item
                        key="7.2"
                        icon={<SettingOutlined />}
                        onClick={TypesAdjustmentsClick}
                      >
                        Ajustes
                      </Menu.Item>
                    )}
                    {permissions.includes("view_cycles_issuer") && (
                      <Menu.Item
                        key="7.3"
                        icon={<HistoryOutlined />}
                        onClick={IssuerCycleListClick}
                      >
                        Ciclos
                      </Menu.Item>
                    )}
                    {permissions.includes(
                      "backoffice_view_list_lock_account"
                    ) && (
                      <Menu.Item
                        key="7.4"
                        icon={<ProfileOutlined />}
                        onClick={LockAccountListClick}
                      >
                        Bloqueio de conta
                      </Menu.Item>
                    )}
                    {permissions.includes("backoffice_view_list_lock_card") && (
                      <Menu.Item
                        key="7.5"
                        icon={<ProfileOutlined />}
                        onClick={LockTypesListClick}
                      >
                        Bloqueio do cartão
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}

              {permissions &&
                (permissions.includes("backoffice_view_list_stores") ||
                  permissions.includes("manage_printer") ||
                  permissions.includes("permission_schedules_store")) && (
                  <SubMenu
                    key="sub8"
                    icon={<ShopOutlined />}
                    title="Lojas"
                    className="menu-snx"
                  >
                    {permissions.includes("backoffice_view_list_stores") && (
                      <Menu.Item
                        key="8.1"
                        icon={<ContainerOutlined />}
                        onClick={storeListClick}
                      >
                        Lojas credenciadas
                      </Menu.Item>
                    )}
                    {permissions.includes("manage_printer") && (
                      <Menu.Item
                        key="8.2"
                        icon={<PrinterOutlined />}
                        onClick={printClick}
                      >
                        Impressoras
                      </Menu.Item>
                    )}
                    {permissions.includes("permission_schedules_store") && (
                      <Menu.Item
                        key="8.3"
                        icon={<ContainerOutlined />}
                        onClick={ScheduleShopkeeperClick}
                      >
                        Agenda lojista
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}

              {permissions &&
                (permissions.includes("view_launch_adjustment") ||
                  permissions.includes("permission_register_payment") ||
                  permissions.includes("make_sales_manual") ||
                  permissions.includes("list_canceled_insurance") ||
                  permissions.includes("list_pay_launch") ||
                  permissions.includes("list_sale_launch") ||
                  permissions.includes("get_reserve_balance")) && (
                  <SubMenu
                    key="sub9"
                    icon={<UserOutlined />}
                    title="Portador"
                    className="menu-snx"
                  >
                    {permissions.includes("view_launch_adjustment") && (
                      <Menu.Item
                        key="9.1"
                        icon={<ContainerOutlined />}
                        onClick={launch_payment}
                      >
                        Ajustes no portador
                      </Menu.Item>
                    )}
                    {permissions.includes("permission_register_payment") && (
                      <Menu.Item
                        key="9.2"
                        icon={<FileDoneOutlined />}
                        onClick={launchPaymentClick}
                      >
                        Lançar Pagamentos
                      </Menu.Item>
                    )}
                    {permissions.includes("make_sales_manual") && (
                      <Menu.Item
                        key="9.3"
                        icon={<CreditCardOutlined />}
                        onClick={sellListClick}
                      >
                        Lançar Vendas
                      </Menu.Item>
                    )}
                    {permissions.includes("list_pay_launch") && (
                      <Menu.Item
                        key="9.4"
                        icon={<ContainerOutlined />}
                        onClick={ListPayments}
                      >
                        Listar Pagamentos
                      </Menu.Item>
                    )}
                    {permissions.includes("list_sale_launch") && (
                      <Menu.Item
                        key="9.5"
                        icon={<ContainerOutlined />}
                        onClick={ListSells}
                      >
                        Listar Vendas
                      </Menu.Item>
                    )}

                    {permissions.includes("list_canceled_insurance") && (
                      <Menu.Item
                        key="9.6"
                        icon={<ContainerOutlined />}
                        onClick={canceledInsuranceListClick}
                      >
                        Seguros
                      </Menu.Item>
                    )}
                    {permissions.includes("get_reserve_balance") && (
                      <Menu.Item
                        key="9.7"
                        icon={<ContainerOutlined />}
                        onClick={ListBalanceReserve}
                      >
                        Saldo reserva
                      </Menu.Item>
                    )}
                    {permissions.includes("permission_view_inadimplencia") && (
                      <Menu.Item
                        key="9.8"
                        icon={<ContainerOutlined />}
                        onClick={ListDefault}
                      >
                        Inadimplência
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}

              {permissions &&
                (permissions.includes("crud_solicitation_type") ||
                  permissions.includes("crud_classification") ||
                  permissions.includes("crud_user") ||
                  permissions.includes("crud_group_user")) && (
                  <SubMenu
                    key="sub10"
                    icon={<SettingOutlined />}
                    title="Config. Elevaty"
                    className="menu-snx"
                  >
                    {permissions.includes("crud_solicitation_type") && (
                      <Menu.Item
                        key="10.1"
                        icon={<PullRequestOutlined />}
                        onClick={solicitationTypeClick}
                      >
                        Tipos de solicitações
                      </Menu.Item>
                    )}
                    {permissions.includes("crud_classification") && (
                      <Menu.Item
                        key="10.2"
                        icon={<StarOutlined />}
                        onClick={classificationClick}
                      >
                        Tipos de classificações
                      </Menu.Item>
                    )}
                    {permissions.includes("crud_group_user") && (
                      <Menu.Item
                        key="10.3"
                        icon={<UsergroupAddOutlined />}
                        onClick={groupClick}
                      >
                        Grupos
                      </Menu.Item>
                    )}
                    {permissions.includes("crud_user") && (
                      <Menu.Item
                        key="10.4"
                        icon={<UserAddOutlined />}
                        onClick={userClick}
                      >
                        Usuários
                      </Menu.Item>
                    )}
                    {/* {permissions.includes("ip_restriction") && (
                      <Menu.Item
                        key="9.5"
                        icon={<LockOutlined />}
                        onClick={accessClick}
                      >
                        Acesso
                      </Menu.Item>
                    )} */}
                  </SubMenu>
                )}

              {permissions.includes("hiden") && (
                <Menu.Item
                  key="2"
                  icon={<FileTextOutlined />}
                  onClick={onReportsClick}
                >
                  Relatórios
                </Menu.Item>
              )}
            </Menu>
          </div>
        </div>
      </div>

      <NewMenu paymentsClick={paymentsClick} salesClick={salesClick} />

      <div className="content">
        <div className="content__menu">
          <div className="content__menu-top">
            <div className="icon-menu">
              <IoMenuSharp onClick={toggleOpen} />
            </div>

            <div className="logo-menu">
              <img src="/images/logo-elevaty.svg" alt="Logo Elevaty" />
            </div>

            <div className="user-menu-left">
              {client && (
                <Can permission="permission_acess_layout_2">
                  <Switch
                    className="switch-button"
                    checkedChildren={
                      <span style={{ fontSize: "14px" }}>Layout 2.0</span>
                    }
                    unCheckedChildren={
                      <span style={{ fontSize: "14px" }}>Layout 1.0</span>
                    }
                    defaultChecked
                    checked={switchState}
                    onChange={handleSwitchChange}
                  />
                </Can>
              )}
              {(permissions.includes("crud_solicitation_type") || permissions.includes("finish_solicitation") || permissions.includes("view_solicitation")) && location.pathname === "/home" && (
                <div className="btn-atendimento-desk">
                  <Button onClick={getReleasePdfs}>Guia de Solicitações</Button>
                </div>
              )}
            </div>

            <div className="user-menu">
              <div className="user-menu__content">
                {clientData &&
                  location.pathname !== "/home" &&
                  clientData.client &&
                  clientData.client.ticket && (
                    <div className="protocolo-header__topo">
                      <p>Protocolo:</p>
                      <span>{clientData.client.ticket.protocol}</span>
                    </div>
                  )}

                <div className="btns-action__topo">
                  {
                    permissions.includes("permission_send_protocol") &&
                    clientData &&
                    location.pathname !== "/home" &&
                    clientData.client &&
                    clientData.client.ticket && (
                      <SendTicketModalWrapper
                        ticket={clientData.client.ticket?.protocol ?? ""}
                      />
                    )}

                  {clientData &&
                    location.pathname !== "/home" &&
                    clientData.client && (
                      <>
                        <CreateSolicitation />
                        <Button
                          type="button"
                          onClick={openModalFinalize}
                          className="finalize-attendance"
                        >
                          Finalizar
                        </Button>
                      </>
                    )}

                  {location.pathname !== "/home" &&
                    location.pathname !== "/access-denied" &&
                    (!clientData || !clientData.client) && (
                      <Button type="button" onClick={openModal}>
                        Atender
                      </Button>
                    )}
                </div>
                {process.env.REACT_APP_TYPE_ENV === "DEV" && (
                  <>
                    <>
                      {identity === "COND" && (
                        <Tooltip
                          title="Esse acesso está direcionado para API-CARDS homologação"
                          placement="bottom"
                        >
                          <div
                            style={{ marginRight: "5px" }}
                            className={
                              env?.cards === "HML"
                                ? "container-hml"
                                : "container-prod"
                            }
                          >
                            <span>CARDS: HML</span>
                          </div>
                        </Tooltip>
                      )}
                    </>
                    <Tooltip
                      title="Esse acesso está direcionado para API-HORIZON homologação"
                      placement="bottom"
                    >
                      <div
                        className={
                          env?.horizon === "HML"
                            ? "container-hml"
                            : "container-prod"
                        }
                      >
                        <span>HORIZON: HML</span>
                      </div>
                    </Tooltip>
                  </>
                )}

                <Dropdown overlay={menuAvatar} trigger={["click"]}>
                  <div className="ant-dropdown-link">
                    <span>{user && user.username}</span> <FiChevronDown />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="content__menu-bottom">
            {clientData &&
              location.pathname !== "/home" &&
              clientData.client &&
              clientData.client.ticket && (
                <div className="protocolo-header">
                  <p>Protocolo:</p>
                  <span>{clientData.client.ticket.protocol}</span>
                </div>
              )}

            <div className="btns-action">
              { permissions.includes("permission_send_protocol") &&
                clientData &&
                location.pathname !== "/home" &&
                clientData.client &&
                clientData.client.ticket && (
                  <SendTicketModalWrapper
                    ticket={clientData.client.ticket?.protocol ?? ""}
                  />
                )}

              {clientData &&
                location.pathname !== "/home" &&
                clientData.client && (
                  <>
                    <CreateSolicitation />
                    <Button type="button" onClick={openModalFinalize}>
                      Finalizar
                    </Button>
                  </>
                )}

              {location.pathname !== "/home" &&
                location.pathname !== "/access-denied" &&
                (!clientData || !clientData.client) &&
                location.pathname === "/classificacao" && (
                  <Button type="button" onClick={openModal}>
                    Atender
                  </Button>
                )}
            </div>
          </div>
        </div>

        {modalAlterarSenhaisOpen && (
          <ResetPasswordModal onCancel={closeModalAlterarSenhaisOpen} />
        )}

        <div className={`content__page ${isHome ? "home" : ""}`}>
          {children}
        </div>
      </div>

      <LogoutListener />
    </Style.WrapperSideMenu>
  );
}

export default SideMenu;
