import styled from 'styled-components';

export const HeaderRefactor = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 15px;

  & > div:first-of-type {
    height: 31px;
    a {
      color: #000;
    }
  }

  .protocolo-header {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;

    font-size: 1.3rem;
    font-family: 'Lucida Console', 'Courier New', monospace;
    p,
    span {
      font-weight: 800;
      font-size: 1.5rem;
      padding: 0;
      margin: 0;
    }
  }

  .wrapper-select {
    margin-right: 10px;
    min-width: 40px;
    right: 180px;
    width: 30%;
  }
  .wrapper-text {
    margin-right: 10px;
    min-width: 40px;
    right: 180px;
  }
  .text-decorated {
    font-family: 'Lucida Console', 'Courier New', monospace;
    font-size: 14px;
  }

  .ant-btn-button {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div:first-of-type {
      order: 1;
      height: 100%;
      min-width: 120px;
    }

    .wrapper-select {
      width: 42%;
    }

    .ant-btn-button {
      width: auto;
      margin-right: 11px;
    }
  }
  @media (min-width: 1400px) {
    .wrapper-select {
      width: 30%;
    }
  }
`;

export const UserName = styled.div`
  .ant-dropdown-link {
    box-sizing: border-box;
    height: 100%;
  }
`;
