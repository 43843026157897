import React from "react";

import SubMenuItem from "./SubMenuItem";

function SubMenu({
  isBottom,
  subItems,
  title,
  paymentsClick,
  onSubMenuClick,
  salesClick,
}) {
  return (
    <ul className={isBottom && isBottom}>
      <div className="newmenu-item__title">
        <p>{title}</p>
      </div>
      {subItems.map((subItem) => (
        <SubMenuItem
          key={subItem.id}
          title={subItem.title}
          typePermission={subItem.typePermission}
          iconName={subItem.icon}
          route={subItem.route}
          onSubMenuClick={onSubMenuClick}
          paymentsClick={paymentsClick}
          salesClick={salesClick}
        />
      ))}
    </ul>
  );
}

export default SubMenu;
