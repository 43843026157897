import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { notifyError } from '../utils/notifications';
import connection from '../utils/socket';
import { useAuth } from './AuthProvider';

let subscription;

const useSocket = (channel) => {
  const navigate = useNavigate();
  const { user, clearLocalStorage } = useAuth();

  const checkAndLogoutUser = (userId) => {
    if (user && userId === user.id) {
      clearLocalStorage();
      navigate('/');
      notifyError('Atenção! Seu usuário foi desativado!');
    }
  };

  const handleMessageAdd = async (message) => {
    const { type, data } = message;

    switch (type) {
      case 'user:deactivateUser':
        checkAndLogoutUser(data.userId);
        break;
      default:
    }
  };

  useEffect(() => {
    connection.connect();
    subscription = connection.subscribe(channel, handleMessageAdd);
    return () => {
      subscription.close();
    };
  });
};

export default useSocket;
