import styled, { css } from "styled-components";

export const WrapperItemTableRequestHome = styled.div`
  width: 100%;
  padding: 24px;
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: 2rem;
  margin-bottom: 4rem;

  .table-request__loading {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-request__empty {
    width: 100%;
    height: 288px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      svg {
        font-size: 24px;
        color: #ffb32c;
      }
    }

    p {
      font-family: Inter;
      color: #ffb32c;
      font-weight: bold;
    }
  }

  .pagination-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .table-request__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .table-request__header--left {
      width: 100%;
      padding-bottom: 8px;

      p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;

        strong {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }

    .table-request__header--right {
      width: 100%;
      padding-bottom: 8px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;

      .export-files {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .input-search-files {
      width: 100%;

      .input-select-type {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      form {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        .show-filters {
          width: 100%;
          background: #ffb32c;
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;
          margin-bottom: 16px;
          padding: 16px;
          border-radius: 4px;

          .show-filters__title {
            width: 100%;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;

            button{
              width: auto;
              background: #fff;
              border: 1px solid #000;
              padding:0 11px;
            }

            p {
              font-family: Roboto;
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              text-align: left;
              color: #000;
            }
          }

          .show-filters__grid {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 22px;

            .item-filters {
              width: 100%;

              .ant-select {
                width: 100% !important;
                background: #fff;
                border-radius: 4px;
                padding: 7px;
                border: 1px solid #d9d9d9;
                cursor: pointer;

                .ant-select-arrow{
                  color: #000 !important;
                }

                .ant-select-selector{
                  border: 1px solid transparent !important;

                  .ant-select-selection-item{
                    svg{
                      position: relative;
                      bottom: -5px;
                      font-size: 19px;
                    }
                  }
                }
              }

              .item-filters__title {
                width: 100%;
                margin-bottom: 16px;
                p {
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                  text-align: left;
                  color: #000;
                }
              }

              .item-filters__status {
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 16px;
                border: 1px solid #d9d9d9;
                cursor: pointer;

                .justify-between {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  svg {
                    font-size: 18px;
                    position: relative;
                    bottom: -3px;
                  }
                }
              }
            }
          }
        }

        .ant-select {
          width: 100% !important;
          margin: 0 0px 5px 0;

          .ant-select-selector {
            height: 40px !important;
            display: flex !important;
            align-items: center !important;
          }
        }
      }

      .input-search__relative {
        width: 100%;
        position: relative;
        flex: 1;
      }

      input {
        width: 100%;
        height: 40px;
        padding: 8px 12px 8px 12px;
        gap: 0px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        margin-bottom: 8px;

        &.error-id {
          border: 1px solid #ff4d4f;
          position: relative;
        }
      }

      .remove-item {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate3d(0, -37%, 0);
        cursor: pointer;

        svg {
          font-size: 20px;
          color: #ffb32c;
        }
      }

      .error-message {
        position: absolute;
        bottom: -18px;
        right: 5px;

        color: #ff4d4f;
        font-size: 12px;
      }

      .input-checkbox-group {
        margin-top: 5px;
      }

      .input-checkbox-container {
        width: 200px;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
      }

      .button-clear {
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
      }

      .button-clear:hover {
        color: orange;
      }

      button {
        width: 60px;
        height: 40px;
        padding: 11px;
        border: 0;
        gap: 10px;
        border-radius: 5px;
        opacity: 0px;
        background: #ffb32c;
        color: #434343;
        margin-left: 8px;

        &.btn-filter {
          background: white;
          border: 1px solid #bfbfbf;
          padding: 0 11px;
          color: #000000;
          width: 87px;

          svg {
            position: relative;
            bottom: -3px;
            margin-left: 5px;
          }

          &:hover,
          &.active {
            background: #ffb32c;
            color: #434343;
            border: 1px solid #434343;
          }
        }
      }
    }
  }

  @media (min-width: 48rem) {
    .table-request__header {
      .input-search-files {
        form {
          .align-filter-desk {
            width: 679px;
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
          }
          .input-select-type {
            width: calc(100% - 200px);
          }

          .ant-select {
            width: 200px !important;
            margin-right: 15px;
          }

          .show-filters {
            .show-filters__grid {
              grid-template-columns: repeat(4, 1fr);
              grid-column-gap: 22px;
              grid-row-gap: 0px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 75rem) {
    .table-request__header {
      .table-request__header--left {
        width: 40%;
      }
      .table-request__header--right {
        width: 60%;
        .input-search-files {
          form {
            .input-select-type {
              width: calc(100% - 163px);
            }
          }
        }
      }
    }
  }
`;

export const WrapperItemRequest = styled.div`
  width: 100%;
  padding: 0 16px 16px 16px;
  background: #fff;
  padding-top: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
`;

export const DivItemRequest = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .divitem-content__left {
    padding-right: 15px;
    padding-left: 8px;
    border-left: 4px solid #ffb32c;

    &.CANCELLED {
      border-color: #ff4d4f;

      p.divitem-content__infos {
        span.divitem-content__user.status {
          color: #ff4d4f;
        }
      }
    }

    p {
      &.divitem-content__protocol {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;

        strong {
          color: #ffb32c;
        }
      }

      &.divitem-content__title {
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: left;
      }

      &.divitem-content__infos {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;

        span {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;

          display: flex;
          align-items: center;

          &:not(:last-child) {
            border-right: 1px solid #ffb32c;
            padding-right: 12px;
            margin-right: 12px;
          }

          svg {
            margin-right: 3px;
          }

          &.divitem-content__user {
            svg {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .divitem-content__right {
    width: auto;
    margin-left: auto;

    button {
      background: #ffb32c;
      padding: 8px 24px 8px 24px;
      gap: 8px;
      border-radius: 2px 0px 0px 0px;
      opacity: 0px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #434343;
      border: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
