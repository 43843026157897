import styled from "styled-components";

export const OverflowMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 1;
`;

export const WrapperNewMenu = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  padding: 16px 0;
  border-radius: 0px 38px 38px 0px;
  border: 1px solid #e48e01;
  background: var(--brand-color-gold-6, #ffb32c);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  display: none;
  z-index: 2;

  .newmenu-item {
    padding: 16px 24px;
    position: relative;

    ul {
      position: absolute;
      right: -304px;
      top: 0;
      list-style: none;
      min-width: 281px;
      padding: 24px;
      border-radius: 18.978px;
      background: var(--more-color-white, #fff);
      box-shadow: 0px 28.46666px 66.42223px 0px rgba(167, 174, 186, 0.16);
      z-index: 2;

      &.menu-vendas-pagamentos {
        top: auto;
        bottom: -95px;

        &::after {
          top: auto;
          bottom: 97px;
        }
      }

      &.menu-listagens {
        top: auto;
        bottom: -95px;
        right: -374px;
        min-width: 345px;

        &::after {
          top: auto;
          bottom: 97px;
        }
      }

      .newmenu-item__title {
        width: 100%;

        p {
          color: var(--neutral-color-gray-8, #595959);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.56px;
          text-transform: uppercase;
          padding: 0;
          margin: 0;
          margin-bottom: 32px;
        }
      }

      &::after {
        right: 100%;
        top: 34px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        box-shadow: 0px 28.46666px 66.42223px 0px rgba(167, 174, 186, 0.16);
        border-right-color: #ffffff;
        border-width: 15px;
        margin-top: -15px;
      }

      li {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 23.72px;
        }

        .newmenu-item__icon-sub {
          width: 28px;
          margin-right: 19px;

          span {
            transition: all 0.4s ease;
            font-size: 24px;
            color: #ffb32c;
          }
        }

        p {
          color: var(--gray-900, #111b29);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          transition: all 0.4s ease;
        }

        &:hover {
          .newmenu-item__icon-sub {
            span {
              color: var(--gray-900, #111b29);
            }
          }
          p {
            color: #ffb32c;
          }
        }
      }
    }

    .newmenu-item__sub {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      width: 28px;
      height: 28px;

      span {
        font-size: 24px;
        cursor: pointer;
      }

      &:hover {
        span {
          color: #444;
        }
      }
    }

    .newmenu-item__single {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;

      span {
        font-size: 24px;
        cursor: pointer;
      }

      &:hover {
        span {
          color: #444;
        }
      }
    }
  }

  @media (min-width: 75rem) {
    display: inherit;
  }
`;
