import React, { memo } from 'react';

import { Dropdown, Menu } from 'antd';

import iconMenu from '../../assets/bars-solid.svg';
import * as S from './style';

function CreditCardBase({
  menu,
  virtual,
  inTransition,
  leftContent,
  cardHolderName,
  expirationYear = '00',
  expirationMonth = '00',
  statusEmbossing,
  maskedCreditCardNumber,
}) {
  return (
    <S.CreditCardContainer>
      <S.CreditCardTitle>
        {leftContent}
        {menu && (
          <S.CreditCardTitleMoreOption>
            <Dropdown overlay={<Menu>{menu}</Menu>} trigger={['click']}>
              <span className="ant-dropdown-link">
                <div className="solid-bar">
                  <img src={iconMenu} alt="Icone menu" />
                </div>
              </span>
            </Dropdown>
          </S.CreditCardTitleMoreOption>
        )}
      </S.CreditCardTitle>
      <S.CreditCardBody>
        <S.CreditCardRow>
          <span>{maskedCreditCardNumber}</span>
          {!virtual && <span className="credit-card-physical">Físico</span>}
          {virtual && <span className="credit-card-virt">Cartão Virtual</span>}
          {inTransition && (
            <span className="credit-card-transition">Bandeirado</span>
          )}
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>{cardHolderName}</span>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>{`${expirationMonth}/${expirationYear}`}</span>
          {statusEmbossing === 'EMBOSSADO' && (
            <span className="credit-card-embossing">impresso</span>
          )}
        </S.CreditCardRow>
      </S.CreditCardBody>
    </S.CreditCardContainer>
  );
}

export default memo(CreditCardBase);
