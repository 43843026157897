import styled from 'styled-components';

export const ModalClass = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Space = styled.div`
margin-bottom: 25px;
`;

export const TableContainer = styled.div`
  margin-left: 1%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 98%;

  .ant-pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Description = styled.div`
  display: flex;
  padding: 5px;
  width: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  display: inline-block;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.65);

  .modal-encargos {
    width: 100%;
    margin: 0 0 2rem 0;

    .modal-encargos__values {
      width: 100%;

      .item-encargo-value {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        p {
          display: block;
          width: 100%;
          text-align: center;

          font-weight: bold;
          font-size: 1.5rem;
          padding: 0;
          margin: 0;
        }

        span {
          font-weight: bold;
          color: #ffc069;
        }
      }
    }

    ul {
      list-style-type: none;

      li {
        margin-bottom: 1rem;
      }

      p {
        font-weight: bold;
      }
      span {
        font-size: 14px;
      }
    }
  }

  @media (min-width: 768px) {
    .modal-encargos {
      .modal-encargos__values {
        display: flex;
        flex-wrap: wrap;
        .item-encargo-value {
          width: 33.33%;
        }
      }
    }
  }

  .send-mail-container, .send-sms-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const TicketRow = styled.div`
  margin: 5px 0;
  max-width: 100%;
  word-wrap: break-word;
  span {
    margin: 0 5px;
  }

  .credit-card-physical {
    margin-left: 15px;
    background: #e6f7ff;
    color: #1890ff;
    border: 1px solid #1890ff;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-embossing {
    margin-left: 250px;
    background: #f5f5f5;
    color: #6f5f4f;
    border: 0.5px solid #6f5f4f;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-virt {
    margin-left: 15px;
    background: #e6f7ff;
    color: #52c41a;
    border: 1px solid #52c41a;
    padding: 2px;
    font-size: 10px;
  }
`;

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px !important;
  max-height: 500px;
  padding: 10px;
  background: #f5f5f5;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #b2bec3;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 15px;

  b {
    font-weight: 700;
  }
`;

export const InformationAgreementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 12px !important;
  max-height: 500px;
  padding: 10px;
  background: #f5f5f5;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #b2bec3;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 20px;

  b {
    font-weight: 700;
  }
`;

export const ModalFilter = styled.div`
  display: flex;
  padding: 10px 0;

  .ant-input-group-wrapper {
    margin-right: 5px;
  }
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 0.7rem;
  margin-top: 0;
`;

export const BtnRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn-content {
    margin-top: 15px;
  }
`

export const ModalRowFinalizeButton = styled.div`
display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-left: 12rem;
`;

export const ModalNewLine = styled.div`
  border-bottom: 1px solid #ccc;
`;


export const ModalRowFinalize = styled.div`
  margin: 25px 0 0 0;
  font-size: 14px;
  font-weight: 400;

  p {
    display: flex;
    align-items: center;
    margin-block-start: 0px;
    margin-block-end: 0px;

    span {
      color: #ff4d4f;
      margin-right: 4px;
      font-size: 20px;
      line-height: 22px;
    }
  }
`;



export const ModalTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  border-bottom: 1px solid #ccc;

  h1 {
    font-size: 17px;
    line-height: 28px;
    font-weight: bold;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #ccc;
    cursor: pointer;
    margin-bottom: 4px;
  }
`;

export const EnqueteTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  border-bottom: 1px solid #ccc;

  h1 {
    font-size: 16px;
    line-height: 18.75px;
    font-weight: bold;
    margin-left: 1.2rem;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #ccc;
    cursor: pointer;
    align-items: center;
    margin-bottom: 9px;
  }
`;

export const ModalBanner = styled.div`

  img {
    width: 550px;
    height: 270px;
  }
`;

export const ModalSurvey = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  border-bottom: 1px solid #ccc;

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  h1 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
  }
`;

export const ModalTitleAnnuity = styled.div`
  width: 100%;
  height: 24px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.85);

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ModalBody = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 10px 0;
  & > div {
    width: 100%;
  }
`;

export const ModalBodyAnnuity = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  padding: 10px 0;
  & > div {
    width: 100%;
  }
  & > button {
    margin-right: 6px;
    width: 126px;
    height: 40px;
    font-weight: 700;
  }
  & > button:nth-child(2) {
    background: #ffc069;
    width: 116px;
    color: #fff;
  }

  & > button.cancel-modal {
    background: #ff4d4f;
    color: #fff;
    width: auto;
  }
`;

export const RowIcon = styled.span`
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  button {
    margin-left: 402px;
  }
  span {
    margin-right: 10px;
  }
`;

export const WrapperRanger = styled.div`
  display: flex;
  flex: 1;
`;

export const ButtonConcluir = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;

  button {
    margin-left: 330px;
    margin: 0px -135px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    background: var(--primaryColor);
    border: 1px solid #d9d9d9;
    width: 80px;
    height: 40px;
    padding: 5px 0px;
    color: white;
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: bold;
  }

  button[id='goBack'] {
    border: 1px solid #ccc !important;
    background: #ffffff;
    color: black;
  }

  button[id='buttonTicket'] {
    margin-left: 340px;
  }
`;

export const BtnContentConcluir = styled.div`
  button {
    margin-left: 15px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    background: var(--primaryColor);
    border: 1px solid #d9d9d9;
    width: 80px;
    height: 40px;
    padding: 5px 0px;
    color: white;
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: bold;
  }
`;

export const ButtonEnquete = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin: 0px -280px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    background: var(--primaryColor);
    border: 1px solid #d9d9d9;
    width: 200px;
    height: 40px;
    padding: 5px 24px;
    color: black;
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ButtonAtualizar = styled.div`
  margin-top: 15px;
  margin-bottom: 1px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin: 0px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    width: 75px;
    height: 32px;
    padding: 5px 0px;
    box-sizing: border-box;
    margin-top: 10px;
  }
`;

export const ButtonCancelar = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;

  button {
    margin: 0px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    width: 80px;
    height: 40px;
    padding: 5px 0px;
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: bold;
  }

  [id='buttonCancelarClass'] {
    margin-left: 550px;
  }
`;

export const BtnContentCancelar = styled.div`
  button {
    margin: 0px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    width: 80px;
    height: 40px;
    padding: 5px 0px;
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: bold;
  }
`;

export const WrapperSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  span {
    margin-bottom: 5px;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  h3 {
    width: 100%;
    text-align: center;
    background: #fa8c16;
    color: #fff;
    border-radius: 10px;
    padding: 5px 0;
  }
`;

export const WrapperHeaderLockAccount = styled.div`
  .modal-lock-header {
    height: 54px;
    width: 572px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 16px 24px 16px 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    width: 572px;
    height: 54px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    background: #ffffff;

    t {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      height: 22px;
      width: 498px;
      left: 24px;
      top: 16px;
      border-radius: nullpx;

      color: #272d3b;
      flex: none;
      order: 0;
      flex-grow: 1;
    }

    .x {
      height: 10.18750286102295px;
      width: 9.894000053405762px;
      left: 3.046630859375px;
      top: 2.90625px;
      border-radius: 0px;

      position: absolute;
      left: 19.04%;
      right: 19.12%;
      top: 18.16%;
      bottom: 18.16%;

      background: #262626;
    }
  }

  .modal-lock-body {
    height: 216px;
    width: 572px;
    left: 0px;
    top: 54px;
    border-radius: 0px;
    padding: 16px 24px 16px 24px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 16px;

    width: 572px;
    height: 216px;

    background: #ffffff;

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;

    ti {
      t {
        height: 22px;
        width: 7px;
        left: 0px;
        top: 0px;
        border-radius: nullpx;

        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

        width: 7px;
        height: 22px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #ff4d4f;

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      tit {
        height: 22px;
        width: 513px;
        left: 11px;
        top: 0px;
        border-radius: nullpx;

        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

        width: 513px;
        height: 22px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #272d3b;

        flex: none;
        order: 1;
        flex-grow: 1;
      }
    }

    se {
      height: 40px;
      width: 524px;
      left: 0px;
      top: 30px;
      border-radius: 2px;
      padding: 8px 12px 8px 12px;

      border: 1px solid #d9d9d9;
      border-radius: 2px;

      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;

      width: 524px;
      height: 40px;

      background: #ffffff;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    tii {
      height: 22px;
      width: 280px;
      left: 0px;
      top: 0px;
      border-radius: nullpx;

      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;

      width: 280px;
      height: 22px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      color: #272d3b;

      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  .modal-lock {
    width: 100%;
    padding: 24px;
    display: flex;
    background: #ffffff;

    .modal-lock__text {
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      q {
        color: FF4D4F;
      }

      span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
      }
      .btns {
        width: 100%;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          height: 40px;
          background: #ffffff;
          border: 1px solid #bfbfbf;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          border-radius: 2px;
          padding: 8px 24px;

          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: rgba(0, 0, 0, 0.85);

          &.active-button {
            margin-left: 8px;
            background: #faad14;
            border: 1px solid #faad14;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            color: #fff;
          }
        }
      }
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  span {
    margin-left: 10px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 17px;
  margin-bottom: 2px;
  border-top: 1px solid #ccc;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
  button {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    width: 113px;
    height: 40px;
    margin-bottom: 0px;
    margin-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #272d3b;
  }
  .button-confirm {
    border: 1px solid #faad14;
    background: #faad14;
    color: #ffffff;
  }
  .button-confirm:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border: 1px solid #ccc;
    opacity: 0.5;
  }
`;


export const WrapperNewLoading = styled.div`
  width: 100%;
  min-height: 15vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const NewLoadingModals = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const LoadAnnuityModal = styled.div`
    .content--loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      flex-wrap: wrap;

      .content-logo,
      .content-text-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }
    }
`
