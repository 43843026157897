import { tint } from "polished";
import styled, {css} from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PageInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 102px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  margin-left: 2%;
  margin-top: 15px;
`;

export const UserContainer = styled.div`
  margin-top: 1%;
  background-color: white;
  width: 100%;
  height: 70%;
  @media (min-width: 1500px) {
    height: 75%;
  }
  display: flex;
  flex-direction: column;

  .box-form{
    max-width: 400px;
  }

  .flex {
    form {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .gap{
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .select-item {
    width: 350px;
    margin-right: 16px;
    position: relative;

    &.card{
      width: 200px;
    }

    .error{
      font-size: 12px;
      color: red;
      position: absolute;
      top: 0;
      right: 0;
    }

    .error-id {
      .ant-select-selector {
        border: 1px solid red;
      }
    }
  }

  .btn-pay{
    width:100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .select-date {
    & + div {
      margin-left: 16px;
    }
  }

  .select-btn {
    padding-top: 21px;
  }

  .select-table{
    margin-top: 4rem;
  }
`;

export const TableContainer = styled.div`
  margin-left: 1%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 98%;

  .ant-pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .btn-cancel{
    background: #F2363D;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }

  .btn-warning{
    border-color:#d9d9d9;
    color: #000000D9;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  width: 96px;
  height: 34px;
  font-weight: bold;

  ${(props) =>
    props.selected
      ? css`
          background: var(--primaryColor);
          border: 1px solid var(--primaryColor);
          color: white;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px;
  width: 100%;

  hr {
    border-top: 1px solid #ccc;
  }

  div.error-id {
    border: 1px solid #c53030;
  }

  .error {
    width: 100%;
    text-align: right;
    font-size: 1rem;
    color: #c53030;
  }

  input {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 9px;
    width: 100%;
    color: #666360;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &::placeholder {
      color: #928f8f;
      font-weight: 500;
    }

    &:disabled {
      color: #444 !important;
      background: #f5f5f5;
      border: 1px solid #ccc;
    }

    &.error-id {
      border: 1px solid #c53030;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    background: var(--primaryColor);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    border: 1px solid var(--primaryColor);

    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    &:hover {
      background: ${tint(0.2, "#FF9C00")};
      border: 1px solid var(--primaryColor);
    }
  }
`;

export const CpfCnpj = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;
