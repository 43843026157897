import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Input, Modal, Select, Descriptions, Divider } from 'antd';

import { useClient } from "../../../hooks/ClientProvider";
import useDependentCard from "../../../hooks/useDependentCard";
import useHolderCard from "../../../hooks/useHolderCard";
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const { TextArea } = Input;

function CancelCardPlModal({
  isModalVisible,
  closeModal,
  productID,
  clientID,
  card,
  isBandeirado,
  isDependent,
  reloadCards
}) {
  const [cancelType, setCancelType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [observacoes, setObservacoes] = useState('');
  const [blockLypes, setBlockLypes] = useState([]);
  const [bloqTypes, setCopyBlockTypes] = useState([]);
  const [observationError, setObservationError] = useState("");

  const { client } = useClient();

  const {
    bloqCard,
  } = useHolderCard(
    client?.client?.id,
    client?.product?.codigoProduto,
    client?.client?.cpf
  );

  const {
    bloqCardDependent,
  } = useDependentCard(
    client?.client?.adicional,
    client?.product?.codigoProduto
  );

  const handleChangeObservacoes = useCallback((event) => {
    if(event.target.value.length > 0 && event.target.value.length < 3) {
      setObservationError("Pelo menos 3 caracteres");
      return;
    }
    setObservationError("");
    setObservacoes(event.target.value);
  }, []);

  const cancelCard = useCallback(
    async (card, bloqData, dependent) => {
      if (dependent) {
        bloqCardDependent(card, bloqData);
      } else {
        bloqCard(card, bloqData);
      }
    },
    []
  );

  const handleBloq = useCallback(async () => {
    try {
      setLoading(true);
      const identity = localStorage.getItem("@SnxPayment:identity");
      await api.patch('card_cancel', {
        identity,
        card_id: card.id,
        cod_product: productID,
        cancelation_type_id: cancelType,
        observation: observacoes,
        client_id: clientID,
        masked_number: card.numeroMascarado,
      });
      const bloqData = {
        tipoBloqueio: cancelType,
        observacao: observacoes,
      };
      cancelCard(card, bloqData,isDependent);
      notifySucces('Cartão cancelado com sucesso');
      reloadCards(true);
    } catch (error) {
      notifyError(error.message);
    } finally {
      closeModal();
      setLoading(false);
    }
  }, [cancelCard, cancelType, card, clientID, closeModal, observacoes, productID]);

  const getBlockLypes = useCallback(async (bloqTypes) => {
    try {
      const blockTypesCancel = bloqTypes.filter(
        (bloq) => bloq.cancelaCartao === true
      );
      setBlockLypes(blockTypesCancel);
    } catch (error) {
      console.log(error);
    }
  }, [bloqTypes]);

  const getCopyBlockTypes = useCallback(async () => {
    try {
      const response = await api.get("block_types_copyCard", { isBandeirado });
      const { bloqTypes } = response.data;
      if (bloqTypes && bloqTypes.length > 0) {
        getBlockLypes(bloqTypes);
      }
    } catch (error) {
      notifyError(error.message);
    }
  }, [isBandeirado]);

  useEffect(() => {
    if (isBandeirado === true || isBandeirado === false) {
      getCopyBlockTypes();
    }
  }, [isBandeirado, getCopyBlockTypes]);


  return (
    <Modal title="Cancelar" visible={isModalVisible} onCancel={closeModal}>
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <span>Tipo de bloqueio</span>
      <Select
        showSearch
        optionFilterProp="name"
        loading={loading}
        disabled={loading}
        placeholder="Selecione um tipo de bloqueio"
        optionLabelProp="name"
        listHeight={512}
        onChange={(value) => setCancelType(value)}
      >
        {blockLypes.map((block) => (
          <Select.Option value={block.id} key={block.id} name={block.descricao}>
            <Descriptions.Item>{block.descricao} - Cancela</Descriptions.Item>
          </Select.Option>
        ))}
      </Select>
      <S.WrapperSelectBlock>
        <span>Digite uma observação para o cancelamento </span>
        <small style={{
          color: "#ff4d4f",
          fontWeight: "bold"
        }}>{observationError}</small>
        <TextArea rows={4} onChange={handleChangeObservacoes} />
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        {!loading && <Button onClick={handleBloq} disabled={observationError || cancelType === null}>Enviar</Button>}
      </S.WrapperSelectBlock>
    </Modal>
  );
}

export default memo(CancelCardPlModal);
