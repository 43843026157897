import React, { useCallback, useState, useEffect } from 'react';

import { Row} from "antd";

import LoadingElevaty from '../../../../../../../components/LoadingElevaty';
import { usePermissions } from '../../../../../../../Context/permissionContext';
import { useAuth } from '../../../../../../../hooks/AuthProvider';
import { useClient } from '../../../../../../../hooks/ClientProvider';
import api from '../../../../../../../services/api';
import { cardProcessorService } from '../../../../../../../services/cardProcessorService';
import { InvoiceInstallmentService } from '../../../../../../../services/invoiceInstallmentService';
import { notifyError } from '../../../../../../../utils/notifications';
import CalculateInstallment from './CalculateInstallment';
import ClientInfo from './ClientInfo';
import InstallmentOptionsPreview from './InstallmentOptionsPreview';

function StepOneOption({
  setSelectedInstallmentOption,
  selectedInstallmentOption,
  className,
  devedorTotal,
  onCloseClick,
}) {
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [parameters, setParameters] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSimulate, setIsLoadingSimulate] = useState(false);
  const { permissions } = usePermissions();
  const { user } = useAuth();

  const { client } = useClient();

  const onSimulateInstallmentRange = useCallback(async (installment) => {

    await api
      .get('userAttendance', {
        userID: user?.id,
      })
      .then(async (responseUser) => {
        await api
          .get('getInstallment', {
            role_id: responseUser[0].role_id,
          })
          .then(async (responseData) => {
            if (permissions.includes('limit_installment_options')) {
              if (responseData.data.installment_limit === null) {
                await setInstallmentOptions(
                  installment.filter((a) => a.quantidadeParcelas <= 48)
                );
              } else {
                await setInstallmentOptions(
                  installment.filter(
                    (a) =>
                      a.quantidadeParcelas <=
                      responseData.data.installment_limit
                  )
                );
              }
            } else {
              await setInstallmentOptions(
                installment.filter((a) => a.quantidadeParcelas <= 48)
              );
            }
          })
          .catch((err) => {
            notifyError(err.message);
          })
          .finally(() =>{
            setIsLoading(false);
          });
      });
  });

  const onSimulateClickHandler = useCallback(
    async (form) => {
      try {
        setIsLoadingSimulate(true)
        if (!form.tax && !form.taxaEncargoPadraoParcelamentoFatura) {
          throw new Error('Insira uma Taxa de refinanciamento');
        }

        const method = devedorTotal
          ? InvoiceInstallmentService.simulateTotal
          : InvoiceInstallmentService.simulate;

        const response = await method({
          clientId: client.client.id,
          tax: form.tax || form.taxaEncargoPadraoParcelamentoFatura,
        });

        if (devedorTotal && !response?.length) {
          notifyError('Cliente não possui devedor futuro!');
        }
        onSimulateInstallmentRange(response);
      } catch (err) {
        console.error('Error to get simulation', err);
        notifyError(err.message);
        onCloseClick();
      } finally {
        setIsLoadingSimulate(false);
      }
    },
    [devedorTotal, InvoiceInstallmentService]
  );


  const getParameters = async () =>{
      setIsLoading(true)
      try {
        const response = await cardProcessorService.getParameters();
        setParameters(response);
        if(response){
          onSimulateClickHandler(response);
        }
      } catch (err) {
        notifyError(err.message);
        console.error('Error to get the parameters');
        setIsLoading(false);
      }
  }

  useEffect(() => {
    getParameters(parameters);
}, []);

  return (
    <div className={className}>
       {isLoading === true ? (
        <Row justify="center" align="middle" high='60px'>
          <LoadingElevaty />
        </Row>
      ) : (
        <>
          <ClientInfo />
          <CalculateInstallment
            isLoading={isLoadingSimulate}
            installmentOptions={installmentOptions}
            onSimulateClick={onSimulateClickHandler}
            selectedInstallmentOption={selectedInstallmentOption}
          />
          <InstallmentOptionsPreview
            installmentOptions={installmentOptions}
            onInstallmentOptionClick={setSelectedInstallmentOption}
          />
        </>  )}
    </div>
  );
}

export default React.memo(StepOneOption);
