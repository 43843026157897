import React, { useState } from "react";

import { Checkbox, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { MdOutlineSend } from "react-icons/md";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import * as S from "./style";

export function SendSmsForm({
  phones,
  onSubmit,
  hasPermissionToSendToUnregistered = false,
}) {
  const [disabledInputSelectSMS, setDisabledInputSelectSMS] = useState(false);
  const [disabledInputSMS, setDisabledInputSMS] = useState(false);

  const handleChangeSendToUnregisteredSMS = (checked, setFieldValue) => {
    setFieldValue("sendToUnregisteredPhone", checked);
    setDisabledInputSelectSMS(checked);
    setDisabledInputSMS(!checked);
    if (checked) {
      setFieldValue("customPhone", "");
    }
  };

  return (
    <S.FormWrapper>
      <Formik
        initialValues={{
          phone: phones.length === 1 ? phones[0] : "",
          sendToUnregisteredPhone: false,
          customPhone: "",
        }}
        validationSchema={Yup.object({
          phone: Yup.string().nullable(),
          sendToUnregisteredPhone: Yup.boolean(),
          customPhone: Yup.string(),
        }).test({
          name: "has-phone",
          test: (values, ctx) => {
            if (
              (!values.sendToUnregisteredPhone && !values.phone) ||
              (!values.sendToUnregisteredPhone && values.phone === "0")
            ) {
              return ctx.createError({
                path: "phone",
                message: "Insira um número válido",
              });
            }

            if (
              !values.sendToUnregisteredPhone &&
              values.phone &&
              values.phone.length < 14
            ) {
              return ctx.createError({
                path: "phone",
                message: "Número inválido",
              });
            }

            if (values.sendToUnregisteredPhone && !values.customPhone) {
              return ctx.createError({
                path: "customPhone",
                message: "Insira um número válido",
              });
            }

            if (
              values.sendToUnregisteredPhone &&
              values.customPhone &&
              values.customPhone.length < 14
            ) {
              return ctx.createError({
                path: "customPhone",
                message: "Número inválido",
              });
            }
          },
        })}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="item-input">
              <Field name="phone">
                {({ field, meta }) => (
                  <>
                    <p>Selecione o número: </p>
                    <Select
                      value={field?.value || "0"}
                      disabled={disabledInputSelectSMS}
                      className={meta.touched && meta.error ? "error-id" : null}
                      onChange={(value) => {
                        setFieldValue("phone", value);
                      }}
                    >
                      <Select.Option value="0">Selecione</Select.Option>
                      {phones.map((_phone) => (
                        <Select.Option key={_phone} value={_phone}>
                          {_phone}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </div>
            {hasPermissionToSendToUnregistered && (
              <div className="item-input">
                <Field name="sendToUnregisteredPhone">
                  {({ field }) => (
                    <Checkbox
                      checked={field?.value ?? false}
                      onChange={(e) =>
                        handleChangeSendToUnregisteredSMS(
                          e.target.checked,
                          setFieldValue
                        )
                      }
                    >
                      Enviar para outro número
                    </Checkbox>
                  )}
                </Field>
              </div>
            )}
            {hasPermissionToSendToUnregistered &&
              values.sendToUnregisteredPhone && (
                <div className="item-input">
                  <p>Digite o número</p>
                  <Field name="customPhone">
                    {({ field, meta }) => (
                      <ReactInputMask
                        type="text"
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        placeholder="Selecione ou digite um telefone de contato"
                        mask="(99) 99999-9999"
                        maskChar=""
                        disabled={disabledInputSMS}
                        {...field}
                        onChange={(e) => {
                          setDisabledInputSelectSMS(true);
                          field?.onChange(e);
                        }}
                        value={field?.value}
                      />
                    )}
                  </Field>
                </div>
              )}

            <ErrorMessage
              name="phone"
              component="div"
              className="error-message"
            />
            <ErrorMessage
              name="customPhone"
              component="div"
              className="error-message"
            />

            <div className="item-submit">
              <button type="submit">
                <MdOutlineSend /> <p>Enviar</p>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </S.FormWrapper>
  );
}
