import React, { memo, useCallback, useState, useEffect } from 'react';

import { Button, Input, Modal, Select, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import LoadingElevaty from '../../../components/LoadingElevaty';
import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { notifyError } from '../../../utils/notifications';
import * as S from './styles';

const { Option } = Select;

const { TextArea } = Input;

function ModalFinalize({ closeModal, setShowInit }) {
  const { client, updateClient } = useClient();

  const navigate = useNavigate();

  const [classification, setClass] = useState([]);
  const [classIdSelected, setClassIdSelected] = useState('');
  const [classNameSelected, setClassNameSelected] = useState('');
  const [allSubClass, setAllSubClass] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [subClass, setSubClass] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clientData, setClientData] = useState({});
  const [obs, setObs] = useState('');
  const [classificationIdSelected, setClassificationIdSelected] = useState(0);
  const [classificationNameSelected, setClassificationNameSelected] =
    useState('');

  const fetchClass = useCallback(async () => {
    setIsLoading(true);
    try {
      const groupTypes = await api.get('group');
      setClass(groupTypes.data);
    } catch (error) {
      setClass([]);
      console.error('Não foi possível carregar os grupos');
    }
    setIsLoading(false);
  }, []);

  const fetchSubClass = useCallback(async () => {
    setIsLoading(true);
    try {
      const subClassTypes = await api.get('classification');
      setAllSubClass(subClassTypes.data);
      setSubClass(subClassTypes.data);
    } catch (error) {
      setSubClass([]);
      console.error('Nao foi possivel carregar as classificacões');
    }
    setIsLoading(false);
  }, []);

  const handleAttendanceFinish = useCallback(async () => {
    const isAllWithoutGroupSelected = classIdSelected === 'all_no_group';
    const groupIdToSubmit = isAllWithoutGroupSelected ? null : classIdSelected;
    if (client) {
      try {
        await api.post('attendance_finish', {
          client_id: client.client.id,
          classification_id: classificationIdSelected,
          classification_name: classificationNameSelected,
          group_id: groupIdToSubmit,
          group_name: classNameSelected,
          obs,
        });

        navigate('/home');
        updateClient(false);
        return true;
      } catch (error) {
        notifyError('Houve um erro ao finalizar o atendimento.');
        console.error('Erro ao finalizar atendimento:', error);
        return false;
      }
    } else {
      notifyError('Nenhum cliente selecionado.');
      return false;
    }
  }, [
    classificationIdSelected,
    classificationNameSelected,
    classIdSelected,
    classNameSelected,
    client,
    navigate,
    obs,
    updateClient,
  ]);


  useEffect(() => {
    fetchSubClass();
    fetchClass();
  }, [fetchSubClass, fetchClass]);

  useEffect(() => {
    setClientData(client);
  }, [client, fetchSubClass]);

  const handleFinish = useCallback(() => {
    setObs(obs);
    handleAttendanceFinish();
    closeModal();
  }, [handleAttendanceFinish, closeModal, obs]);

  const handleGoBack = useCallback(() => {
    setObs('');
    closeModal(obs);
  }, [closeModal, obs]);

  const onChange = useCallback(
    (evt) => {
      setObs(evt.target.value);
    },
    [setObs]
  );

  const handleClassification = useCallback((value, { value: valueId, children: valueName }) => {
    setClassificationNameSelected(valueName);
    setClassificationIdSelected(valueId);
  }, []);

  const handleClass = useCallback((value, { value: valueId, children: valueName }) => {
    setClassNameSelected(valueName);
    setClassIdSelected(valueId);

    setClassificationIdSelected(null);
    if (!valueId || valueId === 0) {
      setSubClass([]);
      return;
    }

    if (valueId === 'all_no_group') {
      const filteredsubClass = allSubClass.filter(classification => {
        return classification.group_id === 1 || classification.group_id === null;
      });
      setSubClass(filteredsubClass);
      return;
    }

    const filteredsubClass = allSubClass.filter(classification => {
      return Number(classification.group_id) === Number(valueId);
    });

    setSubClass(filteredsubClass);
  }, [allSubClass]);


  return (
    <Modal visible width={600} onCancel={closeModal}>
      {isLoading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.ModalContainer>
        <S.ModalTitle>
          <h1>Finalizar Atendimento</h1>
        </S.ModalTitle>
        <p />
        <S.ModalRowFinalize>
          <p><span>*</span> Selecione a classificação: </p>
        </S.ModalRowFinalize>
        <S.ModalRow>
          <Select
            onChange={handleClass}
            showSearch
            optionFilterProp="children"
            placeholder="Selecione a classificação"
            notFoundContent="Nenhum registro encontrado para esta busca!"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="all_no_group" valueName="Todas sem grupo">
              Todas sem grupo
            </Option>
            {classification.map((group) => (
              <Option
                key={group.id}
                valueId={group.id}
                valueName={group.name}
              >
                {group.name}
              </Option>
            ))}
          </Select>
        </S.ModalRow>

        {classIdSelected && (
          <>
            <S.ModalRowFinalize>
              <p><span>*</span> Selecione a subclassificação:</p>
            </S.ModalRowFinalize>
            <S.ModalRow>
              <Select
                value={classificationIdSelected || undefined}
                onChange={handleClassification}
                showSearch
                optionFilterProp="children"
                placeholder="Selecione uma subclassificação"
                notFoundContent="Nenhum registro encontrado para esta busca!"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {subClass.map((classification) => (
                  <Option
                    key={classification.id}
                    value={classification.id}
                  >
                    {classification.name}
                  </Option>
                ))}
              </Select>
            </S.ModalRow>
          </>
        )}
        <p />
        <p />
        <span>Observação:</span>
        <S.ModalRow>
          <TextArea
            rows={3}
            type="text"
            placeholder="Digite aqui uma observação para finalizar o atendimento"
            value={obs}
            onChange={onChange}
          />
        </S.ModalRow>
        <p />
        <S.ModalNewLine>
          <p />
        </S.ModalNewLine>
        <S.ModalRowFinalizeButton>
          <S.ButtonCancelar>
            <Button onClick={handleGoBack}>Cancelar</Button>
          </S.ButtonCancelar>
          <S.ButtonConcluir>
            {!classIdSelected || !classificationIdSelected ? (
              <Tooltip title="Você deve selecionar uma classificação e uma subclassificação para finalizar!">
                <span>
                  <Button
                    onClick={handleFinish}
                    disabled={!classIdSelected || !classificationIdSelected}
                  >
                    Finalizar
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                onClick={handleFinish}
                disabled={!classIdSelected || !classificationIdSelected}
              >
                Finalizar
              </Button>
            )}
          </S.ButtonConcluir>
        </S.ModalRowFinalizeButton>

      </S.ModalContainer>
    </Modal>
  );
}

export default memo(ModalFinalize);
