import React, { useCallback, useEffect, useState } from "react";

import { Table } from "antd";
import { format } from "date-fns";

import { usePermissions } from "../../../Context/permissionContext";
import * as S from "../style";

function UsersTable({ data, handleOpenModal, lojas }) {
  const { permissions } = usePermissions();

  function formatDate(valueDate) {
    const date = new Date(valueDate);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  const dataWithStatus = data?.content?.reverse().map((record) => ({
    ...record,
    status: record.status,
  }));

  const columns = [
    {
      title: "id Portador",
      dataIndex: "idPortador",
    },

    {
      title: "Forma de Pagamento",
      dataIndex: "formaDePagamento",
    },
    {
      title: "Valor Pago",
      dataIndex: "valorPago",
      render: (valorPago) => (
        <>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valorPago)}
        </>
      ),
    },
    {
      title: "Data Pagamento",
      dataIndex: "dataPagamento",
      render: (dataPagamento) => (
        <>{dataPagamento ? formatDate(dataPagamento) : null}</>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          {status === "N" && <S.StatusActive>Normal</S.StatusActive>}
          {status === "C" && <S.StatusInactive>Cancelado</S.StatusInactive>}
          {status === "P" && <S.StatusActive>Processado</S.StatusActive>}
          {status === "D" && <S.StatusInactive>Desfeito</S.StatusInactive>}
          {status === "E" && <S.StatusInactive>Estornado</S.StatusInactive>}
          {status === "W" && <S.StatusInactive>Pendente</S.StatusInactive>}
        </>
      ),
    },
    {
      title: "Cancelar Pagamento",
      width: 170,
      dataIndex: "numeroAutorizacao",
      align: "center",
      render: (numeroAutorizacao, record) => (
        <>
          {permissions.includes("make_pay_cancel") &&
            (record.status === "N" || record.status === "P") && (
              <button
                type="button"
                className="btn-cancel"
                onClick={() => handleOpenModal(numeroAutorizacao)}
              >
                Cancelar Pagamento
              </button>
            )}
        </>
      ),
    },
  ];

  return (
    <S.TableContainer>
      <Table
        className="tableListsadjustment not-global"
        columns={columns}
        dataSource={data?.content?.reverse()}
        pagination={{ pageSize: 5 }}
      />
    </S.TableContainer>
  );
}

export default React.memo(UsersTable);
