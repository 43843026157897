import React from 'react';

import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';

function AntInput({ ...props }) {
  if (props.mask) {
    return (
      <ReactInputMask {...props}>
        {(inputProps) => (
          <Input
            {...inputProps}
            disabled={props.disabled ? props.disabled : null}
          />
        )}
      </ReactInputMask>
    );
  }
  return <Input {...props} />;
}

export default AntInput;
