import React, { memo, useCallback, useMemo, useState } from 'react';

import { Card, Dropdown, Menu, Modal } from 'antd';

import Loading from '../Loading';
import * as S from './style';

function MenuDropDownComponent({ list }) {
  const listMenu = useMemo(
    () => (
      <Menu>
        {list &&
          list.map((item) => (
            <Menu.Item key={item.name} onClick={item.onClick}>
              <span>{item.name}</span>
            </Menu.Item>
          ))}
      </Menu>
    ),
    [list]
  );
  return (
    <Dropdown overlay={listMenu} trigger={['click']}>
      <span
        className="ant-dropdown-link"
        style={{
          cursor: 'pointer',
          fontSize: '20px',
          fontWeight: 700,
        }}
      >
        ...
      </span>
    </Dropdown>
  );
}

const MenuDropDown = memo(MenuDropDownComponent);

function CardSnx({
  children,
  title,
  list,
  modalContent,
  modalTitle,
  loading,
  hasModal = false,
  extra = null,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <S.CardContainer>
        <Card title={title} extra={list ? <MenuDropDown list={list} /> : extra}>
          {!loading && children}
          {loading && <Loading />}
        </Card>
        {hasModal && (
          <S.ContainerModal onClick={showModal}>
            <span>Ver Mais</span>
          </S.ContainerModal>
        )}
      </S.CardContainer>
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        {modalContent}
      </Modal>
    </>
  );
}

export default memo(CardSnx);
