import React, { useCallback, useState } from "react";


import { HomeFilled } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  ConfigProvider,
  Alert,
  Select
} from "antd";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import Card from "../../components/Card";
import Loading from "../../components/Loading";
import api from "../../services/api";
import { notifyError } from "../../utils/notifications";
import { validaCpf } from "../../utils/validations";
import ModalCancelPayment from "./ModalCancelPayment/index";
import CreliqTable from "./PaymentCreliqTable";
import * as S from "./style";

const { Option } = Select;

function ListPaymentsCreliq() {
  const [data, setData] = useState([]);
  const [dataRaw, setDataRaw] = useState([]);
  const [lastYear, setLastYear] = useState(() => {
    const lastYearDate = new Date();
    lastYearDate.setDate(lastYearDate.getDate() - 10);
    return lastYearDate;
  });
  const [today, setToday] = useState(() => {
    const todayDate = new Date();
    return todayDate;
  });

  const [formSteps, setFormSteps] = useState(0);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [idClient, setIdClient] = useState(0);
  const [nameClient, setNameClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [modalPayStoresOpen, setmodalPayStoresOpen] = useState(false);
  const [listClientAccount, setListClientAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [dateInit, setDateInit] = useState();
  const [dateFim, setDateFim] = useState();

  const [infosModal, setInfosModal] = useState();

  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "cnpj") setCurrentMask("99.999.999/9999-99");
    if (doc === "cartao") setCurrentMask("9999 9999 9999 9999");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);

  const onChangeLastYearRangeDate = useCallback((date, setFieldValue) => {
    setFieldValue("lastYear", moment(date).format("YYYY-MM-DD"));
    setLastYear(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
    setListSelected([]);
  }, []);

  const onChangeTodayRangeDate = useCallback((date, setFieldValue) => {
    setFieldValue("today", moment(date).format("YYYY-MM-DD"));
    setToday(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
    setListSelected([]);
  }, []);

  const handleSubmit = (value) => {
    setLoadingTable(true);
    setSearchData(false);

    setDateInit(value.lastYear);
    setDateFim(value.today);

    return new Promise((resolve, reject) => {
      if (selectedAccount == null || selectedAccount === '0') {
          reject(new Error('Informe a conta do portador'));
      } else {
          resolve();
      }
      })
      .then(() => {
        return api.get("creliq_payment_list", {
          initDate: value.lastYear,
          finalDate: value.today,
          clientId: idClient
        });
      })
      .then((res) => {
        if (res?.data?.inadimplentes.length === 0) {
          notifyError("Portador não possui inadimplente em Creliq");
          setData(null);
          return false;
        }
        setDataRaw(res?.data);
        res?.data?.inadimplentes.sort((a, b) => b.idInadimplente - a.idInadimplente);
        // eslint-disable-next-line array-callback-return
        res?.data?.inadimplentes.map(item => {
          // eslint-disable-next-line array-callback-return
            item.pagamentos.map(item => {
                item.numeroConta = idClient;
            })
            item?.pagamentos.sort((a, b) => new Date(b.dataPagamento) - new Date(a.dataPagamento));
        });
        setData(res?.data?.inadimplentes.flatMap(item => item.pagamentos));
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
      });
  };

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "cnpj") text = "do CNPJ";
    if (currentDoc === "cartao") text = "do Cartão";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const reloadPage = () => {
    setLoadingTable(true);
    setSearchData(false);

    api
      .get("creliq_payment_list", {
        initDate: dateInit,
        finalDate: dateFim,
        clientId: idClient
      })
      .then((res) => {
        if (res?.data?.inadimplentes.length === 0) {
          notifyError("Portador não possui inadimplente em Creliq");
          setData(null);
          return false;
        }
        setDataRaw(res?.data);
        res?.data?.inadimplentes.sort((a, b) => b.idInadimplente - a.idInadimplente);
        // eslint-disable-next-line array-callback-return
        res?.data?.inadimplentes.map(item => {
          // eslint-disable-next-line array-callback-return
          item.pagamentos.map(item => {
            item.numeroConta = idClient;
          })
          item?.pagamentos.sort((a, b) => new Date(b.dataPagamento) - new Date(a.dataPagamento));
        });
        setData(res?.data?.inadimplentes.flatMap(item => item.pagamentos));
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
      });
  };

  const handleOpenModal = useCallback(
    (id) => {
      const inadimplente = dataRaw.inadimplentes.find((inadimplente) => {
        return inadimplente.pagamentos.filter((pagamento) => pagamento.idPagamento === id);
      });

      setmodalPayStoresOpen(true);
      setInfosModal({
        pagamento: inadimplente.pagamentos[0],
        idInadimplente: inadimplente.idInadimplente,
        dataEntradaCobranca: inadimplente.dataEntradaCobranca,
        clientId: idClient
      });
    },
    [data]
  );

  const closeModalPayStoresOpen = useCallback(() => {
    setmodalPayStoresOpen(false);
  }, []);

  const handleStepOne = async (values) => {
    setLoadingBtn(true);
    try{
      const response = await api.get('getAccount', {
        dataDocument: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
        document: currentDoc
      });
      if (!response.data || response.data.length === 0 ) {
        throw new Error("Cliente não encontrado");
      }

      let uniqueAccountSelectList;
      if (Array.isArray(response.data) && response.data.length > 1) {
        uniqueAccountSelectList = response.data.map((item) => ({
          id: item.id,
          accountSelect: item.accountSelect
        }));
      } else {
        await setIdClient(response.data.id);
        await setSelectedAccount(response.data.id);
        uniqueAccountSelectList = [{
          id: response.data.id,
          accountSelect: response.data.accountSelect
        }]
      }
      setListClientAccount(uniqueAccountSelectList);

      setTimeout(() => {
        setLoadingBtn(false);
        setFormSteps(1);
      }, 1000);
    }catch(error){
      setLoadingBtn(false);
      notifyError(error.message)
    }
  };

  const handleAccountSelected = useCallback(async (value) => {
    await setIdClient(value);
    await setSelectedAccount(value);
  }, []);

  return (
    <>
      <S.Container>
        <S.PageInfoContainer>
          <S.PageContent style={{ marginTop: "3%" }}>
            <HomeFilled /> / <b>Listagem de Pagamentos Creliq</b>
          </S.PageContent>
          <S.UserContainer>
            <Card title="Listagem de Pagamentos Creliq" loading={loading}>
              {formSteps === 0 && (
                <Formik
                  initialValues={{ doc: "" }}
                  validationSchema={Yup.object({
                    doc: Yup.string()
                      .required(`${currentDoc.toUpperCase()} obrigatório`)
                      .test(
                        "test_cpf",
                        `Informe um ${currentDoc} válido`,
                        (value) => {
                          if (currentDoc === "client_id") return true;
                          if (currentDoc === "cpf") {
                            if (value === undefined || value === null)
                              return false;

                            const cpfFormated = value
                              .split(".")
                              .join("")
                              .replace("-", "");
                            return validaCpf(cpfFormated);
                          }
                        }
                      ),
                  })}
                  onSubmit={handleStepOne}
                >
                  {() => (
                    <Form>
                      <div className="box-form">
                        <S.FormRow>
                          <div>
                            <b>Tipo:</b>
                          </div>
                          <S.CpfCnpj>
                            <S.DocButton
                              selected={currentDoc === "cpf"}
                              onClick={handleCPF}
                            >
                              CPF
                            </S.DocButton>
                            <S.DocButton
                              selected={currentDoc === "client_id"}
                              onClick={handleClientId}
                            >
                              ID Cliente
                            </S.DocButton>
                          </S.CpfCnpj>

                          <Field name="doc">
                            {({ field, meta }) => (
                              <ReactInputMask
                                placeholder={setPlaceholder()}
                                type="text"
                                mask={currentMask}
                                maskChar={null}
                                autoComplete="off"
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                {...field}
                              />
                            )}
                          </Field>

                          <ErrorMessage
                            name="doc"
                            className="error"
                            component="div"
                          />
                        </S.FormRow>

                        <S.FormRow>
                          <Button
                            loading={loadingBtn}
                            htmlType="submit"
                            type="primary"
                          >
                            Buscar
                          </Button>
                        </S.FormRow>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {formSteps === 1 && (
                <>
                  <div className="flex">
                    {listClientAccount && (
                      <>
                      <p>
                        <strong>* Conta:</strong>
                      </p>
                      <S.FormAccount>
                        <Select
                          placeholder="Selecionar conta"
                          onChange={handleAccountSelected}
                          defaultValue={listClientAccount.length === 1 ? listClientAccount[0].id : "Selecionar"}
                        >
                          {listClientAccount.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.accountSelect}
                            </Option>
                          ))}
                        </Select>
                      </S.FormAccount>
                      </>
                    )}
                    <Formik
                      initialValues={{
                        lastYear: moment(lastYear).format("YYYY-MM-DD"),
                        today: moment(today).format("YYYY-MM-DD"),
                      }}
                      onSubmit={handleSubmit}
                    >
                      {({ setFieldValue }) => (
                        <Form>
                          <ConfigProvider locale={datePickerLocale}>
                            <div className="select-date">
                              <S.InputsLabel>
                                <span style={{ marginRight: "5px" }}>
                                  Data Inicial
                                </span>
                              </S.InputsLabel>

                              <Field name="lastYear">
                                {({ meta }) => (
                                  <DatePicker
                                    defaultValue={moment(lastYear)}
                                    className={`${meta.touched && meta.error ? "error-id" : null
                                      } `}
                                    placeholder="Selecione a data"
                                    format="DD/MM/YYYY"
                                    allowClear={false}
                                    showToday={false}
                                    onChange={(e) =>
                                      onChangeLastYearRangeDate(e, setFieldValue)
                                    }
                                  />
                                )}
                              </Field>
                            </div>

                            <div className="select-date">
                              <S.InputsLabel>
                                <span style={{ marginRight: "5px" }}>
                                  Data Final
                                </span>
                              </S.InputsLabel>

                              <Field name="today">
                                {({ meta }) => (
                                  <DatePicker
                                    defaultValue={moment(today)}
                                    className={`${meta.touched && meta.error ? "error-id" : null
                                      } `}
                                    placeholder="Selecione a data"
                                    format="DD/MM/YYYY"
                                    allowClear={false}
                                    showToday={false}
                                    onChange={(e) =>
                                      onChangeTodayRangeDate(e, setFieldValue)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                          </ConfigProvider>
                          <div className="select-btn">
                            <Button htmlType="submit" type="ghost">
                              Pesquisar
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>

                  <div className="select-table">
                    {loadingTable && <Loading />}
                    {searchData && data && (
                      <CreliqTable
                        data={data}
                        handleOpenModal={handleOpenModal}
                      />
                    )}
                    {searchData && !data && (
                      <div className="alert-table">
                        <Alert
                          message="Não há dados para as datas selecionadas"
                          type="warning"
                          showIcon
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Card>
          </S.UserContainer>
        </S.PageInfoContainer>
      </S.Container>
      {modalPayStoresOpen && (
        <ModalCancelPayment
          onCancel={closeModalPayStoresOpen}
          infosModal={infosModal}
          reloadPage={reloadPage}
        />
      )}
    </>
  );
}
export default React.memo(ListPaymentsCreliq);
