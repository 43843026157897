import styled from "styled-components";

export const WrapperSideMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .left-menu {
    width: 0px;
    height: 100vh;
    background: rgba(75, 85, 99, 0.75);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    .left-menu__content .left-menu__menu ul li {
      margin-bottom: 7px !important;
    }

    transition: width 0.3s ease-in-out;

    .left-menu__content {
      width: 0;
      max-width: 278px;
      /* max-width: 336px; */
      height: 100vh;
      background: #fff;
      transition: width display 0.5s ease-in-out;
      display: none;

      opacity: 0;
      visibility: hidden;
      transform: translate3d(-50px, 0, 0);
      position: absolute;
      top: 0;
      padding: 0 8px;

      .left-menu__header {
        width: 100%;
        padding: 37px 18px;
        border-bottom: 1px solid #d9d9d9;
      }

      .left-menu__menu {
        width: 100%;
        margin-top: 24px;

        ul {
          /* padding-left: 20px; */
          list-style: none;

          &.ant-menu {
            background: transparent;
            border-right: 0;
          }

          li {
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            color: #434343;
            margin-bottom: 44px;

            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 12px;

            svg {
              margin-right: 1rem;
              font-size: 22px;
            }

            &.ant-menu-item {
              margin-bottom: 1rem;
              padding-left: 24px !important;
              font-size: 12px;
            }
          }
        }
      }
    }

    &.active {
      width: 100%;
      transition: width 0.3s ease-in-out;

      .left-menu__content {
        width: 100%;
        display: inherit;

        opacity: 1;
        visibility: visible;
        transition: 0.3s;
        position: relative;
        top: auto;
        transform: translate3d(0px, 0, 0);
      }
    }

    .left-menu__logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      img {
        width: 76px;
      }
    }

    .sidebar-close {
      position: absolute;
      top: 15px;
      right: 10px;

      svg {
        color: #fff;
        font-size: 1.1rem;
      }
    }
  }

  .content {
    width: 100%;
    transition: width 0.3s ease-in-out;

    /* &.active {
      width: calc(100% - 250px);
    } */

    .btn-atendimento-desk {
      display: none;
    }

    .content__menu {
      width: 100%;
      height: 200px;
      background: url("/images/bg.svg") repeat center #ffb32c;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-wrap: wrap;

      .content__menu-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .content__menu-bottom {
        width: 100%;
        margin: -30px auto 0 auto;
        max-width: 310px;

        .protocolo-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            padding: 0;
            margin: 0;
          }

          span {
            margin-left: 4px;
          }
        }

        .btns-action {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
      }

      .icon-menu {
        margin-top: 27px;
        svg {
          cursor: pointer;
          font-size: 20px;
        }
      }

      .logo-menu {
        height: 68px;
        background: #fff;
        padding: 28px 24px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 40px 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .user-menu-left{
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        width: 25%;
        margin-top: 27px;
        gap: 15px;
        height: fit-content;

        .switch-button {
          margin-top:3px;
          margin-left: 28px;
          min-width: 118px;
          height: 28px;
          background: #66bb6a;
          font-weight: bold;

          .ant-switch-handle {
            transform: translatey(-50%);
            top: 50%;
            left: calc(100% - 18px - 10px);
          }
        }
      }


      .user-menu {
        margin-top: 27px;

        text-align: right;
        position: relative;

        .user-menu__content {
          display: inherit;
          column-gap: 10px;
          min-width: 100px;
          position: absolute;
          top: 0;
          right: 0;
        }

        span,
        .protocolo-header__topo,
        .btns-action__topo {
          display: none;
        }

        .container-hml {
          padding: 8px;
          border: 1px solid #f0c04e;
          border-radius: 4px;
          background-color: rgba(255, 245, 226, 0.7);
          color: #f39422;
          font-size: 10px;
          text-align: center;
          font-weight: bold;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }

        .container-prod {
          display: none; /* Esconde o componente para o ambiente de produção */
        }

        .ant-dropdown-link {
          button {
            background: transparent;
            border: 0;
            cursor: pointer;
            p {
              font-size: 16px;
              color: #272d3b;

              span {
                display: none;
              }

              svg {
                font-size: 22px;
                margin-bottom: -6px;
              }
            }
          }
        }
      }
    }

    .content__page {
      width: 100%;
      padding: 0 15px;
      margin-top: -35px;

      &.home {
        margin-top: -100px;
        flex-wrap: wrap;
      }
    }
  }
  @media (min-width: 75rem) {
    .left-menu {
      width: 124px;
      height: auto;
      background: transparent;
      margin-top: 95px;
      display: none;

      &.active {
        width: 200px;
      }

      .sidebar-close {
        display: none;
      }

      .left-menu__content {
        background: transparent;
        width: 100%;
        height: auto;
        display: inherit;
        opacity: 1;
        visibility: visible;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        position: relative;
        top: auto;
        -webkit-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);

        .left-menu__header {
          display: none;
        }

        .left-menu__menu {
          ul {
            padding-left: 0;
            &.ant-menu-root {
              li {
                &.ant-menu-submenu {
                  position: relative;

                  display: flex;
                  align-items: center;
                  justify-content: center;

                  div.ant-menu-submenu-title {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    text-align: center;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .anticon {
                      width: auto;
                    }
                  }
                }
                span.ant-menu-title-content,
                i {
                  display: none;
                }

                &.ant-menu-item {
                  margin-bottom: 1rem;
                  padding-left: 16px !important;
                  font-size: 12px;
                }
              }
            }

            &.ant-menu-sub {
              position: absolute !important;
              top: 0;
              left: 120px;
              min-width: 295px;
              border-radius: 15px;
              overflow: hidden;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

              li {
                span.ant-menu-title-content {
                  display: inherit;
                }
                &:hover {
                  color: #000 !important;
                }
              }
            }

            li {
              justify-content: center;
              height: 58px;
              margin-bottom: 7px;
              &:hover {
                background: #e48e01;
              }
              p {
                display: none;
              }
            }
          }
        }
      }
    }
    .content {
      .btn-atendimento-desk {
        display: inherit;
      }

      .content__menu {
        height: 324px;

        .content__menu-bottom {
          display: none;
        }

        .icon-menu {
          display: none;
        }

        .logo-menu {
          width: 288px;
          height: 79px;
          border-radius: 0px 0px 120px 0px;
          position: relative;
          left: -16px;
          justify-content: flex-start;

          img {
            width: 195px;
          }
        }

        .user-menu {
          width: calc(100% - 288px);
          height: 32px;
          display: flex;
          justify-content: flex-end;

          .protocolo-header__topo {
            width: auto;
            display: flex;
            align-items: center;
            margin-right: 15px;

            p {
              padding: 0;
              margin: 0;
              font-weight: bold;
            }

            span {
              margin-left: 4px;
              font-weight: bold;
            }
          }

          .btns-action__topo {
            display: inherit;
            align-items: center;

            .finalize-attendance {
               width: 160px;
            }
            button {
              border-radius: 3px;
              border: 1px solid var(--neutral-color-gray-5);
              & + button {
                margin-left: 16px;
                font-size: 16px;
              }
            }
          }
          .ant-dropdown {
            left: auto !important;
            right: 20px;
          }

          .ant-dropdown-link {
            display: flex;
            align-items: center;
            justify-content: end;
            min-width: 100px;
          }

          span {
            display: inherit;
          }
        }
      }

      .content__page {
        padding: 0 125px;
        margin-top: -180px;
        display: flex;
        align-items: flex-start;

        &.home {
          margin-top: -180px;
        }
      }
    }
  }

  @media (min-width: 1500px) {
    .content {
      .content__page {
        max-width: 1502px;
        margin: -180px auto 0 auto;
        padding: 0 135px;
      }
    }
  }
`;
