import React, { memo, useEffect, useRef, useState } from "react";

import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

import Card from "../../../components/Card";
import LoadingElevaty from "../../../components/LoadingElevaty";
import { useClient } from "../../../hooks/ClientProvider";
import Api from "../../../services/api";
import { TabsCard } from "../Cards/TabsCard/TabsCard";
import * as S from "../style";

function CustomLabel({ viewBox, value }) {
  const { cx, cy } = viewBox;
  const formattedValue = value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <g>
      <text
        x={cx}
        y={cy - 25}
        className="total"
        textAnchor="middle"
        dominantBaseline="central"
      >
        TOTAL R$
      </text>
      <text
        x={cx}
        y={cy + 5}
        className="amount"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {formattedValue}
      </text>
    </g>
  );
}

function CustomLegend({ payload }) {
  return (
    <ul className="CustomLegendLimits">
      {payload.map((entry) => (
        <li
          key={entry.name}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg height="20" width="20" style={{ marginRight: "10px" }}>
              <circle cx="10" cy="10" r="10" fill={entry.color} />
            </svg>
            <span style={{ fontWeight: "bold" }}>{entry.value}</span>
          </div>
          <p style={{ color: entry.color }}>
            {`R$ ${entry.payload.value.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          </p>
        </li>
      ))}
    </ul>
  );
}

function CardsLimitChart() {
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const { client } = useClient();

  const [informationsClient, setInformationsClient] = useState({});

  useEffect(() => {
    if (client) {
      setLoading(true);
      Api.get("client_information", { client_id: client.client.id })
        .then((response) => {

          setInformationsClient({
            limitePrincipal: response.data.limitePrincipal,
            saldoDisponivel: response.data.saldoDisponivel,
            limiteParcelado: response.data.limiteParcelado,
            saldoDisponivelParcelado: response.data.saldoDisponivelParcelado,
            limiteSaque: response.data.limiteSaque,
            saldoDisponivelSaque: response.data.saldoDisponivelSaque,
          });
        })
        .catch((error) => {
          console.error(
            "Erro ao obter informações do limite do cliente",
            error
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [client]);

  const limiteUtilizadoRotativo =
    informationsClient.limitePrincipal - informationsClient.saldoDisponivel;

  const chartDataRotativo = [
    {
      name: "DISPONÍVEL",
      value: parseFloat(informationsClient.saldoDisponivel) || 0,
    },
    {
      name: "UTILIZADO",
      value: limiteUtilizadoRotativo > 0 ? limiteUtilizadoRotativo : 0,
    },
  ];

  const limiteUtilizadoParcelado =
    informationsClient.limiteParcelado -
    informationsClient.saldoDisponivelParcelado;

  const chartDataParcelado = [
    {
      name: "DISPONÍVEL",
      value: parseFloat(informationsClient.saldoDisponivelParcelado) || 0,
    },
    {
      name: "UTILIZADO",
      value: limiteUtilizadoParcelado > 0 ? limiteUtilizadoParcelado : 0,
    },
  ];

  const limiteUtilizadoSaque =
    informationsClient.limiteSaque - informationsClient.saldoDisponivelSaque;

  const chartDataSaque = [
    {
      name: "DISPONÍVEL",
      value: parseFloat(informationsClient.saldoDisponivelSaque) || 0,
    },
    {
      name: "UTILIZADO",
      value: limiteUtilizadoSaque > 0 ? limiteUtilizadoSaque : 0,
    },
  ];

  const COLORS = ["#272D3B", "#FF8B00"];

  const outerRadii = {
    DISPONÍVEL: 80,
    UTILIZADO: 90,
  };

  return (
    <>
      <S.WrapperCardLimit ref={ref}>
        {loading ? (
          <S.WrapperNewLoading>
            <LoadingElevaty />
          </S.WrapperNewLoading>
        ) : (
          <Card title="Limites do cliente" modalTitle="Limites do cliente">
            <TabsCard
              tabs={[
                {
                  title: "Rotativo",
                  content: (
                    <ResponsiveContainer width="100%" height={216}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PieChart width={218} height={218}>
                          <Pie
                            data={chartDataRotativo}
                            cx={90}
                            cy={90}
                            innerRadius={62}
                            startAngle={90}
                            endAngle={450}
                            paddingAngle={1}
                            dataKey="value"
                            labelLine={false}
                          >
                            {chartDataRotativo.map((entry, index) => (
                              <Cell
                                key={`cell-${entry.name}`}
                                fill={COLORS[index % COLORS.length]}
                                stroke={COLORS[index % COLORS.length]}
                                strokeWidth={entry.name === "UTILIZADO" ? 6 : 1}
                                outerRadius={outerRadii[entry.name]}
                              />
                            ))}
                            <Label
                              content={
                                <CustomLabel
                                  value={informationsClient.limitePrincipal}
                                />
                              }
                              position="center"
                            />
                          </Pie>
                        </PieChart>
                        <CustomLegend
                          payload={chartDataRotativo.map((entry, index) => ({
                            value: entry.name,
                            color: COLORS[index % COLORS.length],
                            payload: entry,
                          }))}
                        />
                      </div>
                    </ResponsiveContainer>
                  ),
                },
                informationsClient.limiteUtilizadoParcelado &&
                informationsClient.saldoDisponivelParcelado
                  ? {
                      title: "Parcelado",
                      content: (
                        <ResponsiveContainer width="100%" height={216}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PieChart width={218} height={218}>
                              <Pie
                                data={chartDataParcelado}
                                cx={90}
                                cy={90}
                                innerRadius={62}
                                startAngle={90}
                                endAngle={450}
                                paddingAngle={1}
                                dataKey="value"
                                labelLine={false}
                              >
                                {chartDataParcelado.map((entry, index) => (
                                  <Cell
                                    key={`cell-${entry.name}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke={COLORS[index % COLORS.length]}
                                    strokeWidth={
                                      entry.name === "UTILIZADO" ? 6 : 1
                                    }
                                    outerRadius={outerRadii[entry.name]}
                                  />
                                ))}
                                <Label
                                  content={
                                    <CustomLabel
                                      value={informationsClient.limiteParcelado}
                                    />
                                  }
                                  position="center"
                                />
                              </Pie>
                            </PieChart>
                            <CustomLegend
                              payload={chartDataParcelado.map(
                                (entry, index) => ({
                                  value: entry.name,
                                  color: COLORS[index % COLORS.length],
                                  payload: entry,
                                })
                              )}
                            />
                          </div>
                        </ResponsiveContainer>
                      ),
                    }
                  : null,
                informationsClient.limiteUtilizadoSaque &&
                informationsClient.saldoDisponivelSaque
                  ? {
                      title: "Saque",
                      content: (
                        <ResponsiveContainer width="100%" height={216}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PieChart width={218} height={218}>
                              <Pie
                                data={chartDataSaque}
                                cx={90}
                                cy={90}
                                innerRadius={62}
                                startAngle={90}
                                endAngle={450}
                                paddingAngle={1}
                                dataKey="value"
                                labelLine={false}
                              >
                                {chartDataSaque.map((entry, index) => (
                                  <Cell
                                    key={`cell-${entry.name}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke={COLORS[index % COLORS.length]}
                                    strokeWidth={
                                      entry.name === "UTILIZADO" ? 6 : 1
                                    }
                                    outerRadius={outerRadii[entry.name]}
                                  />
                                ))}
                                <Label
                                  content={
                                    <CustomLabel
                                      value={informationsClient.limiteSaque}
                                    />
                                  }
                                  position="center"
                                />
                              </Pie>
                            </PieChart>
                            <CustomLegend
                              payload={chartDataSaque.map((entry, index) => ({
                                value: entry.name,
                                color: COLORS[index % COLORS.length],
                                payload: entry,
                              }))}
                            />
                          </div>
                        </ResponsiveContainer>
                      ),
                    }
                  : null,
              ].filter(Boolean)}
            />
          </Card>
        )}
      </S.WrapperCardLimit>
    </>
  );
}

export default memo(CardsLimitChart);
