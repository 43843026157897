import React from 'react';

import * as S from './style';

function PageNotFound() {
  return (
    <S.Wrapper>
      <S.WrapperText>
        <span>404</span>
      </S.WrapperText>
    </S.Wrapper>
  );
}

export default PageNotFound;
