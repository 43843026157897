import React from "react";

import { FaCheckCircle } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";

import * as S from "./style";

export const StatusCard = ({ card }) => {
  const invalidStatuses = [
    "CARTAO_INVALIDO",
    "INATIVO",
    "CANCELADO",
  ]

  const blockedStatuses = [
    "B",
    "BLOQUEADO",
    "BLOQUEADO_PERMANENTE",
  ];


  const cardAlertStatus = ["CARTAO_TROCA",
    "PENDENTE_TROCA",
    "PENDENTE_CONFIRMACAO_TROCA",
    "BLOQUEADO_TEMPORARIO",
    "PENDENTE_TROCA",
    "CARTAO_TROCA",
    "PENDENTE_CONFIRMACAO_TROCA"];

  if (["A","ATIVO"].includes(card.status)) {
    const formattedStatus = card.status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(?:^|\s)\S/g, letter => letter.toUpperCase());

    return (
      <S.statusCardActive>
        <FaCheckCircle />
        <span>{formattedStatus}</span>
      </S.statusCardActive>
    );
  }

  if (cardAlertStatus.includes(card.status)) {
    const formattedStatus = card.status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(?:^|\s)\S/g, letter => letter.toUpperCase());

    return (
      <S.groupStatusCardWarning>
        <S.statusCardWarning>
          <RiErrorWarningFill />
          <span>{formattedStatus}</span>
        </S.statusCardWarning>
        {card.statusEmbossing === "AGUARDANDO_EMBOSSING" &&(
        <S.statusCardWarning>
          <RiErrorWarningFill />
          <span>Não Impresso</span>
        </S.statusCardWarning>
        )}
      </S.groupStatusCardWarning>
    );
  }

  if (blockedStatuses.includes(card.status) || invalidStatuses.includes(card.status)) {
    const formattedStatus = card.status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(?:^|\s)\S/g, letter => letter.toUpperCase());

    return (
      <S.statusCardBlock>
        <MdBlock />
        <span>{formattedStatus}</span>
      </S.statusCardBlock>
    );
  }

  return null;
};
