import React, { memo, useCallback, useState } from 'react';

import { Button, Modal } from 'antd';

import Loading from '../../../../components/Loading';
import api from '../../../../services/api';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import * as S from './style';

function ConfirmAction({
  closeModal,
  isModalVisible,
  getDataFormatedCards,
  clientId,
  reloadCards
}) {
  const [loading, setLoading] = useState(false);

  const handleConfirmGenerateCard = useCallback(async () => {
    try {
      setLoading(true);
      const { idCartao } = await api.post('generate_card_holder', {
        clientId,
      });

      if (idCartao) {
        getDataFormatedCards();
      }
      notifySucces('Cartão gerado com sucesso');
      reloadCards(true);
      setLoading(false);
      closeModal();
    } catch (error) {
      notifyError(error.message);
      setLoading(false);
    }
  }, []);

  return (
    <Modal
      title="Confirmar"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
    >
      <S.WrapperSelectBlock>
        <h2>Deseja realmente gerar um cartão para o titular?</h2>
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        <S.WrapperButtonsReset>
          {!loading && (
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              Cancelar
            </Button>
          )}
          {!loading && (
            <Button onClick={handleConfirmGenerateCard} type="primary">
              Ok
            </Button>
          )}
          {loading && <Loading />}
        </S.WrapperButtonsReset>
      </S.WrapperSelectBlock>
    </Modal>
  );
}

export default memo(ConfirmAction);
