import React, { useCallback, useEffect, useState } from "react";

import { Modal } from "antd";
import { FaCheckCircle, FaEye, FaRegFilePdf } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiAlertLine } from "react-icons/ri";

import ModalLockAccount from "../../pages/home/modals/ModalLocks/ModalLockAccount";
import InvoiceInstallmentsModal from "../../pages/PageAtendimento/InvoiceV2/modals/InstallmentSimulation";
import ChangeLimitCardModal from "../creditCard/modals/ChangeLimitCardModal";
import ModalCharge from "./ModalCharge";
import * as Style from "./style";

function ChargeComponent({
  clientStatus,
  daysLate,
  invoice,
  textList,
  clientBoleto,
  statusDate,
  btnInfos,
  assessoriaValue,
  acordoValue,
  restricaoValue,
  descricaoValue,
  client,
  agreements,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gridStyle, setGridStyle] = useState("");
  const [listInfos, setListInfos] = useState();
  const [modalInstallmentSimulationOpen, setmodalInstallmentSimulationOpen] = useState(false);
  const [modalLockAccountOpen, setModalLockAccountOpen] = useState(false);
  const [modalAlterLimitOpen, setModalAlterLimitOpen] = useState(false);

  const [erroNewLimit, setErroNewLimit] = useState(false);
  const [valueMask, setValueMask] = useState("");
  const [newLimit, setNewLimit] = useState("");
  const [valueAgreements, setValueAgreements] = useState();


  const validateAgreements = (value) => {
    if (value) {
      const filterValue = value?.filter(
        (a) =>
          a.situacao === "ABERTO" ||
          a.situacao === "PARCIAL" ||
          a.situacao === "PENDENTE"
      );


      if (filterValue.length) {
        setValueAgreements(filterValue);
        return true;
      }
      setValueAgreements(null);
      return false;
    }
    setValueAgreements(null);
    return false;
  };


  const openModals = useCallback(async (titleButton) => {
    switch (titleButton) {
      case "Bloquear":
        setModalLockAccountOpen(true);
        break;

      case "Alterar Limite":
        setModalAlterLimitOpen(true);
        break;

      default:
        setmodalInstallmentSimulationOpen(true);
        break;
    }
  });

  const closeModalInstallmentSimulation = useCallback(
    () => setmodalInstallmentSimulationOpen(false),
    []
  );

  const closeModalLockAccount = useCallback(
    () => setModalLockAccountOpen(false),
    []
  );

  const closeModalAlterLimit = useCallback(
    () => setModalAlterLimitOpen(false),
    []
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const finalValue = [];

    if (
      !!assessoriaValue &&
      assessoriaValue.title &&
      assessoriaValue.text.length
    ) {
      finalValue.push(assessoriaValue);
    }

    if (!!acordoValue && acordoValue.title && acordoValue.text.length) {
      finalValue.push(acordoValue);
    }

    if (
      !!restricaoValue &&
      restricaoValue.title &&
      restricaoValue.text.length
    ) {
      finalValue.push(restricaoValue);
    }

    if (
      !!descricaoValue &&
      descricaoValue.title &&
      descricaoValue.text.length
    ) {
      finalValue.push(descricaoValue);
    }

    setListInfos(finalValue);
  }, [textList, assessoriaValue, acordoValue, restricaoValue, descricaoValue]);


  useEffect(() => {
    const listGrid = [clientStatus, daysLate, textList, clientBoleto];

    if (listGrid.filter((item) => item).length === 2) {
      setGridStyle("grid-items-2");
    } else if (listGrid.filter((item) => item).length === 3) {
      setGridStyle("grid-items-3");
    }
  }, [gridStyle]);

  useEffect(() => {
    if (agreements) {
      validateAgreements(agreements);
    }
  }, [agreements]);


  return (
    <>
      <InvoiceInstallmentsModal
        visible={modalInstallmentSimulationOpen}
        key={modalInstallmentSimulationOpen} // Force re-render (clean state) on state transition.
        onCloseClick={closeModalInstallmentSimulation}
        invoices={invoice} 
      />

      <ModalLockAccount
        open={modalLockAccountOpen}
        closeModal={closeModalLockAccount}
        clientID={client.client.id}
      />
      <ChangeLimitCardModal
        isModalVisible={modalAlterLimitOpen}
        closeModal={closeModalAlterLimit}
        clientID={client?.client.id}
        newLimit={newLimit}
        valueMask={valueMask}
        setValueMask={setValueMask}
        setNewLimit={setNewLimit}
        erroNewLimit={erroNewLimit}
        setErroNewLimit={setErroNewLimit}
      />
      <Style.WrapperCharge grid={gridStyle}>
        {clientBoleto && (
          <div className="charge-ticket">
            <div className="charge-ticket__title">
              <p>
                <FaRegFilePdf /> Cliente possui acordos ativos.
              </p>
            </div>
            <div className="charge-ticket__btns">
              <button type="button" onClick={showModal}>
                <FaEye /> Visualizar acordos
              </button>
            </div>
          </div>
        )}


        {clientStatus && (
          <div className={`charge-title ${clientStatus?.status}`}>
            {(clientStatus.status === "alert" ||
              clientStatus.status === "warning") && <RiAlertLine />}
            {clientStatus.status === "success" && <FaCheckCircle />}

            <p className="tagline">{clientStatus.text}</p>
          </div>
        )}


        {daysLate && (
          <div className={`charge-countdown ${!!statusDate && statusDate}`}>
            <span>
              <p>Dias em atraso</p>
              <p>
                <strong>{daysLate} dias</strong>
              </p>
            </span>
          </div>
        )}


        {textList && (
          <div className="charge-list">
            {!!listInfos &&
              listInfos.map((item, index) => (
                <p key={`${item.title}.${index[index]}`}>
                  <strong>{item.title}</strong> {item.text}
                </p>
              ))}
          </div>
        )}


        <div className="charge-last">
          {!!btnInfos && (
            <button
              type="button"
              className={`charge-btn-unlock ${btnInfos?.theme}`}
              disabled={btnInfos?.disabled}
              onClick={() => {
                openModals(btnInfos?.title);
              }}
            >
              <p>
                {btnInfos?.title} <IoIosArrowForward />
              </p>
            </button>
          )}
        </div>
      </Style.WrapperCharge>



      <ModalCharge isModalOpen={isModalOpen} handleCancel={handleCancel}  agreements={valueAgreements || null}/>
    </>
  );
}

export default ChargeComponent;
