import { Button } from 'antd';
import { tint, shade } from 'polished';
import styled from 'styled-components';

export const Table = styled.table`
  td {
    padding-right: 6px;
    padding-bottom: 6px;
  }
`;

export const StrengthMeter = styled.div`
  height: 0.3rem;
  background-color: lightgrey;
  border-radius: 3px;
  margin: 0.5rem 0;

  &::before {
    content: '';
    background-color: ${(props) =>
      `${
        ['red', 'orange', '#03a2cc', '#03a2cc', '#0ce052'][
          props.strengthWidth - 1
        ] || ''
      }`};
    height: 100%;
    width: ${(props) => (props.strengthWidth / 5) * 100}%;
    display: block;
    border-radius: 3px;
    transition: width 0.2s;
  }
`;
export const ContainerCreateEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
export const DescriptionInfo = styled.div`
  word-break: break-all;
`;

export const ContentInfoLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: black;
  font-weight: bold;
`;

export const AttachmentIcon = styled.div`
  margin-right: 6px;
`;

export const AttachmentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  > div {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
`;

export const SubTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const Form = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const ForgotPasswordButton = styled.a`
  background: transparent;
  border: none;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-bottom: -20px;

  &:hover {
    color: #ff9c00;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    background: var(--primaryColor);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    border: 1px solid var(--primaryColor);

    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    &:hover {
      background: ${tint(0.2, '#FF9C00')};
      border: 1px solid var(--primaryColor);
    }
    
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 8px;

  span {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0px 4px;
  }

  p {
    color: #ff4d4f;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    margin: 0px 4px;
  }
`;

export const ForgotPassword = styled.a`
  color: #1890ff;
  font-size: 14px;
  line-height: 32px;
  align-self: flex-end;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${shade(0.2, '#1890FF')};
  }
`;
