import React, { memo, useCallback, useEffect, useState } from "react";

import { Divider, Modal , Select } from "antd";

import * as S from "./style";

const FilterByStoreModal = ({
  isOpen,
  onCancel,
  availableStores,
  applyStoreFilter,
  fetchStoreLoading,
  defaultSelectedStores=[]
}) => {
  const [formattedStoresOptions, setFormmattedStoreOptions] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);

  const formatStoreOptions = useCallback(() => {
    const formmatedAvailableStores = availableStores?.reduce((acc, current) => {
      acc.push({
        label: current?.nomeFantasia,
        value: current?.nomeFantasia
      });
      return acc;
    }, [])

    return formmatedAvailableStores;
  }, [availableStores])

  useEffect(() => {
  setSelectedStores(defaultSelectedStores);
  }, [defaultSelectedStores]);

  useEffect(() => {
    setFormmattedStoreOptions(formatStoreOptions());
  }, [formatStoreOptions]);

  const handleStoreSelection = (selectedStore) => {
    if(selectedStore !== null && selectedStore !== undefined ) {
      setSelectedStores(prevStores => [...prevStores, selectedStore]);
     return;
    }
    setSelectedStores([]);
  }

  const handleStoreDeselection = (deselectedStore) => {
    setSelectedStores(prevStores =>
      prevStores.filter(store => store !== deselectedStore)
    );
  };

  const handleApplyStoreFilter = () => {
    onCancel();
    applyStoreFilter(selectedStores);
  }

  return (
    <Modal
      visible={isOpen}
      title={<span style={{ fontWeight: "bold" }}>Filtrar usuário por loja</span>}
      onCancel={handleApplyStoreFilter}
      className="store-modal"
    >
      <label style={{ fontWeight: "bold" }}>Lojas:</label>
      <Select
        mode="multiple"
        showSearch
        allowClear
        loading={fetchStoreLoading}
        placeholder="Selecione lojas"
        onSelect={handleStoreSelection}
        onDeselect={handleStoreDeselection}
        options={formattedStoresOptions}
        defaultValue={defaultSelectedStores}
      />
      <S.modalBottomSection>
        <button
          onClick={onCancel}
          className="cancel"
          type="button">
          Cancelar
        </button>
        <button
          onClick={handleApplyStoreFilter}
          className="apply"
          type="button"
        >
          Filtrar
        </button>
      </S.modalBottomSection>
    </Modal>
  );
}

export default memo(FilterByStoreModal);
