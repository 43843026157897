import api from './api';

const endpoint = 'solicitation_types';

export class SolicitationTypeService {
  static disable(id) {
    return api.delete(`${endpoint}/${id}`);
  }

  static enable(id) {
    return api.post(`${endpoint}/${id}/enable`);
  }

  static create({ name }) {
    return api.post(`${endpoint}`, {
      name,
    });
  }

  static update(id, { name }) {
    return api.put(`${endpoint}/${id}`, {
      name,
    });
  }

  static list() {
    return api.get(endpoint).then((response) =>
      response.data.map((item) => ({
        key: item.id,
        ...item,
      }))
    );
  }
}
