import React, { createContext, useState, useEffect, useCallback, useContext, useMemo, ReactNode } from 'react';

import { useLocation } from 'react-router-dom';

import api from '../services/api';

export const PERMISSIONS = {
  SET_CARD_PASSWORD: 'set_card_password',
};

interface PermissionContextData {
  permissions: string[];
  fetchPermissions: () => Promise<void>;
  logout: () => Promise<void>;
  isLoading: boolean;
}

const PermissionContext = createContext<PermissionContextData>({} as PermissionContextData);

export const PermissionProvider: React.FC = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const fetchPermissions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('refresh_permission');
      if (response && Array.isArray(response.user_permission)) {
        setPermissions(response.user_permission);
      } else {
        console.error("Resposta da API não é como esperado:", response);
      }
    } catch (error) {
      console.error("Erro ao buscar permissões:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    setPermissions([]);
    setIsLoading(true);
    return setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    const existingToken = localStorage.getItem("@SnxPayment:token")
    if(existingToken) {
      fetchPermissions();
    }
  }, [fetchPermissions]);

  const contextValue = useMemo(() => ({
    permissions,
    fetchPermissions,
    logout,
    isLoading,
  }), [permissions, fetchPermissions, isLoading, isLoggedIn]);

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};

const App = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  return location.pathname !== '/' ? (
    <PermissionProvider>{children}</PermissionProvider>
  ) : (
    <>{children}</>
  );
};

export default App;
