export class PrintPdf {
  constructor(dataPrint) {
    this.dataPrint = dataPrint;
  }

  async MakeDoc() {
    const bodyDoc = this.MakeBodyDoc();
    return this.GenerateDoc(bodyDoc);
  }

  MakeBodyDoc() {
    const Header = [
      {
        text: '',
        bold: true,
        fontSize: 9,
      },
      {},
      {},
    ];

    const lineHeader = [
      {
        text: '__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        alignment: 'center',
        fontSize: 5,
        colSpan: 3,
        margin: [-10, 0, 0, 0],
      },
      {},
    ];
    const Product = this.dataPrint.map((prod) => [
      {
        text: 'PRODUTO',
        bold: true,
        fontSize: 9,
        margin: [80, 4, 0, 0],
      },
      {
        text: prod.cartaoEmissor,
        bold: true,
        fontSize: 9,
        margin: [40, 4, 10, 0],
      },
      {},
    ]);

    const Holder = this.dataPrint.map((prod) => [
      {
        text: 'NOME DO TITULAR',
        bold: true,
        fontSize: 9,
        margin: [80, 4, 10, 0],
      },
      { text: prod.nomeTitular, fontSize: 9, margin: [40, 4, 10, 0] },
      {},
    ]);
    const Card = this.dataPrint.map((prod) => [
      {
        text: 'Nº DO CARTÃO',
        bold: true,
        fontSize: 9,
        margin: [80, 4, 10, 0],
      },
      { text: prod.numeroCartao, fontSize: 14, margin: [40, 4, 10, 0] },
      {},
    ]);
    const PayDate = this.dataPrint.map((prod) => [
      {
        text: 'OPÇÃO DE VENCIMENTO',
        bold: true,
        fontSize: 9,
        margin: [80, 4, 10, 0],
      },
      { text: prod.opcaoVencimento, fontSize: 9, margin: [40, 4, 10, 0] },
      {},
    ]);
    const Limit = this.dataPrint.map((prod) => [
      {
        text: 'LIMITE DE CRÉDITO',
        bold: true,
        fontSize: 9,
        margin: [80, 4, 10, 0],
      },
      { text: prod.LimiteCredito, fontSize: 9, margin: [40, 4, 10, 0] },
      {},
    ]);
    const LimitRot = this.dataPrint.map((prod) => [
      {
        text: 'LIMITE ROTATIVO DISPONÍVEL',
        bold: true,
        fontSize: 9,
        margin: [80, 4, 10, 0],
      },
      {
        text: prod.LimiteRotativoDisponivel,
        fontSize: 9,
        margin: [40, 4, 10, 0],
      },
      {},
    ]);
    const Space = this.dataPrint.map(() => [
      { text: '', fontSize: 8 },
      { text: '', fontSize: 8 },
      {},
    ]);
    const Validade = this.dataPrint.map((prod) => [
      {
        text: prod.Validade_ate,
        bold: true,
        fontSize: 11,
        margin: [80, 4, 10, 0],
      },
      {
        text: prod.Validade,
        bold: true,
        fontSize: 11,
        margin: [40, 4, 10, 0],
      },
      {},
    ]);
    const Signature1 = this.dataPrint.map(() => [
      {
        text: '_______________________________________',
        fontSize: 9,
        margin: [52, 0, 0, 0],
      },
      {
        text: '_______________________________________',
        fontSize: 9,
        margin: [32, 0, 0, 0],
      },
      {},
    ]);
    const Signature2 = this.dataPrint.map(() => [
      {
        text: 'Loja',
        bold: true,
        fontSize: 8,
        margin: [122, 4, 10, 0],
      },
      {
        text: 'Cliente',
        bold: true,
        fontSize: 8,
        margin: [100, 4, 10, 0],
      },
      {},
    ]);

    let content = [Header, lineHeader, ...Product];
    content = [
      ...content,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Holder,
      ...Space,
      ...Card,
      ...Space,
      ...PayDate,
      ...Space,
      ...Limit,
      ...Space,
      ...LimitRot,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Validade,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Space,
      ...Signature1,
      ...Signature2,
    ];
    return content;
  }

  // eslint-disable-next-line class-methods-use-this
  GenerateDoc(bodyDoc) {
    return {
      pageSize: 'A4',
      pageMargins: [1, 53, 14, 48],
      header() {
        return {
          margin: [1, 12, 14, 0],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: `TERMO DE AUTORIZAÇÃO DE COMPRA\n
                  CNPJ  45.827.425/0001-07`,
                  style: 'reportName',
                },
              ],
            ],
          },
        };
      },
      content: [
        {
          margin: [30, 12, 14, 0],
          layout: 'noBorders',
          table: {
            headerRows: 2,
            widths: ['*', 200, 55],

            body: bodyDoc,
          },
        },
      ],
      footer(currentPage, pageCount) {
        return {
          layout: 'noBorders',
          margin: [14, 0, 14, 22],
          table: {
            widths: ['auto'],
            body: [
              [
                {
                  text: '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                  alignment: 'center',
                  fontSize: 5,
                },
              ],
              [
                [
                  {
                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                    fontSize: 7,
                    alignment: 'right',
                    /* horizontal, vertical */
                    margin: [3, 0],
                  },
                  {
                    text: '© Supermercado Lopes',
                    fontSize: 9,
                    alignment: 'center',
                  },
                ],
              ],
            ],
          },
        };
      },
      styles: {
        reportName: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
          margin: [0, 4, 0, 0],
        },
      },
    };
  }
}
