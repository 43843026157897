import api from '../services/api';
import { encodeBase64 } from '../utils/crypto';

/* eslint-disable no-use-before-define */
function inactivityTime() {
  let time;

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  document.addEventListener('scroll', resetTimer, true);

  async function logout() {
    await api.post('logout', {});
    clearLocalStorage();
    clearCacheData();
    window.location.reload();
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, 900000);
  }

  function clearCacheData() {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }


  function clearLocalStorage() {
    localStorage.clear();
  }
}
export default inactivityTime;
