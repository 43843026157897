import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Modal, Select } from "antd";
import { AiFillPrinter } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaCheckCircle, FaRegClock, FaRegFilePdf } from "react-icons/fa";
import { RiAlertFill } from "react-icons/ri";

import api from "../../../services/api";
import { getFormatDateReverse } from "../../../utils/formatDate";
import * as Style from "./style";

const { Option } = Select;
function ModalCharge({ isModalOpen, handleCancel, agreements }) {
  const [productSelect, setProductSelect] = useState(null);
  const [changeAgreements, setChangeAgreements] = useState("0");
  const [filterAgreements, setFilterAgreements] = useState([]);
  const [filterAgreementsParc, setFilterAgreementsParc] = useState([]);
  const [valueAgreements, setValueAgreements] = useState();


  async function handleGoPrintPdf() {
    try {
      const data = await api.get(
        'generatePdfAgreement',
        {
          agreement_id: filterAgreementsParc[productSelect - 1].acordo,
          parc: filterAgreementsParc[productSelect - 1].numeroParcela,
          raw_pdf: 'true',
        },
        { 'Content-Type': 'application/pdf' },
        true
      );
      const fileURL = URL.createObjectURL(await data.blob());
      const pdfWindow = window.open(fileURL);
      setTimeout(() => {
        pdfWindow.document.title = 'Fatura.pdf';
      }, 750);
    } catch (error) {
      console.error('Nao foi possivel gerar pdf da parcela');
    }
    handleCancel();
  }

  const handleChangeAgreement = useCallback(
    (value) => {
      if (value) {
        try {
          const FilterAgreements = valueAgreements?.filter(
            (a) => a.id === value
          );
          setFilterAgreements(FilterAgreements);
          setFilterAgreementsParc(
            FilterAgreements[0].parcelas.filter(
              (a) => a.situacao !== "CANCELADO" && a.situacao !== "CONCLUIDO"
            )
          );
        } catch (error) {
          console.error("Problemas ao listar parcelas de acordo");
        }
      }
    },
    [valueAgreements]
  );

  const options = useMemo(
    () =>
      valueAgreements &&
      valueAgreements?.map((agreementClient) => (
        <Option value={agreementClient.id} key={agreementClient.id}>
          {" Acordo: "}
          {agreementClient.id}
          {"  "}-{"  "}
          {" Status: "}
          {agreementClient.situacao}
        </Option>
      )),
    [valueAgreements]
  );

  useEffect(() => {
    const handleSetAgreements = async () => {
      if (valueAgreements?.length === 1) {
        const selectedAgreement = valueAgreements[0];
        const firstParcel = selectedAgreement?.parcelas.find(
          (parcela) => parcela.numeroParcela === "1"
        );
  
        if (firstParcel) {
          await setChangeAgreements(selectedAgreement.id);
          await setFilterAgreementsParc(selectedAgreement?.parcelas);
  
          const isSingleParcel = selectedAgreement.parcelas.length === 1;
          if (isSingleParcel) {
            await setFilterAgreementsParc((prevFilter) =>
              prevFilter.map((agree) => ({ ...agree, checked: true }))
            );
          }
        }
      }
    };
  
    handleSetAgreements();
  }, [valueAgreements, setChangeAgreements, setFilterAgreementsParc]);
  
  useEffect(() => {
    if (agreements && agreements.length > 0) {
      setValueAgreements(agreements);
    }
  }, [agreements, setValueAgreements]);
  

  return (
    <Modal
      title="Parcelamento de cobrança"
      visible={isModalOpen}
      onCancel={handleCancel}
      width={450}
    >
      <Style.ModalCharge>
        <div className="modal-charge__item">
          <p>Selecione o acordo</p>

          <Select
            defaultValue={changeAgreements}
            onChange={handleChangeAgreement}
          >
            <Option value="0">Selecione o acordo</Option>
            {options}
          </Select>
        </div>
        <div className="modal-charge__item">
          <p>Quantidade de parcelas</p>

          <input
            type="text"
            value={`${filterAgreementsParc.length} parcelas`}
            disabled
          />
        </div>

        <div className="modal-charge__division" />

        <div className="modal-charge__item">
          <p>Produtos:</p>

          <div>
            {filterAgreementsParc &&
              filterAgreementsParc.map((parcel, index) => (
                <div
                  className={`modal-charge-select__item ${
                    productSelect === index + 1 || parcel?.checked === true
                      ? "active"
                      : ""
                  } ${parcel.situacao === "LIQUIDADO" ? "disabled" : ""}`}
                  onClick={() => {
                    setProductSelect(index + 1);
                  }}
                >
                  <div className="icon-check">
                    {productSelect === index + 1 && (
                      <BsCheckCircleFill color="#FFC53D" />
                    )}
                  </div>

                  <div className="modal-charge-select__item-left">
                    <p>
                      <strong>
                        <FaRegFilePdf /> {index + 1}º - R$ {parcel.valorTotal}
                      </strong>
                    </p>

                    <p>
                      Vencimento: {getFormatDateReverse(parcel.dataVencimento)}
                    </p>
                  </div>
                  <div className="modal-charge-select__item-right">
                    <span
                      className={
                        parcel.situacao === "LIQUIDADO"
                          ? "active"
                          : parcel.situacao === "PARCIAL"
                          ? "blocked"
                          : "waiting"
                      }
                    >
                      {parcel.situacao === "LIQUIDADO" ? (
                        <FaCheckCircle />
                      ) : parcel.situacao === "PARCIAL" ? (
                        <RiAlertFill />
                      ) : (
                        <FaRegClock />
                      )}{" "}
                      {parcel.situacao === "LIQUIDADO"
                        ? "Pago"
                        : parcel.situacao === "PARCIAL"
                        ? "Parcial"
                        : "Aguardando"}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="footer-modal">
          <button
            type="button"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancelar
          </button>
          <button type="submit" className="submit"  onClick={() => handleGoPrintPdf()}>
            <div className="button-content">
              <AiFillPrinter/> Emitir boleto
            </div>
          </button>
        </div>
      </Style.ModalCharge>
    </Modal>
  );
}

export default ModalCharge;
