import { shade } from 'polished';
import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding: 9px;
  width: 100%;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErroed &&
    css`
      border: 1px solid #c53030;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      background: #f5f5f5;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--primaryColor);
      border: 1px solid var(--primaryColor);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--primaryColor);
    `}

  input {
    color: black;
    flex: 1;
    border: 0;
    background: transparent;

    &::placeholder {
      color: #928f8f;
      font-weight: 500;
    }

    &:disabled {
      color: #444 !important;
    }
  }

  svg {
    width: 17px;
    height: 17px;
    margin-right: 9px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const PasswordEye = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    width: 2.1rem;
    height: 2.1rem;

    &:hover {
      fill: ${shade(0.2, '#F18670')};
    }
  }
`;

export const ErrorDown = styled.span`
  color: red;
`;
