import React, { useState } from "react";

import { Checkbox, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { MdOutlineSend } from "react-icons/md";
import * as Yup from "yup";

import * as S from "./style";

export function SendMailForm({
  onSubmit,
  clientMail,
  hasPermissionToSendToUnregistered = false,
}) {
  const [disabledInputSelectMail, setDisabledInputSelectMail] = useState(false);
  const [disabledInputMail, setDisabledInputMail] = useState(false);

  const handleChangeSendToUnregisteredMail = (checked, setFieldValue) => {
    setFieldValue("sendToUnregisteredEmail", checked);
    setDisabledInputSelectMail(checked);
    setDisabledInputMail(!checked);
    if (checked) {
      setFieldValue("customEmail", "");
    }
  };

  return (
    <S.FormWrapper>
      <Formik
        initialValues={{
          email: clientMail ?? "0",
          sendToUnregisteredEmail: false,
          customEmail: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().email("Deve ser um e-mail válido").nullable(),
          customEmail: Yup.string().email("Deve ser um e-mail válido"),
          sendToUnregisteredEmail: Yup.boolean(),
        }).test({
          name: "has-email",
          test: (value, ctx) => {
            if (value.sendToUnregisteredEmail && !value.customEmail) {
              return ctx.createError({
                path: "customEmail",
                message: "Campo Obrigatório",
              });
            }

            if (
              (!value.sendToUnregisteredEmail && !value.email) ||
              (!value.sendToUnregisteredEmail && value.email === "0")
            ) {
              return ctx.createError({
                path: "email",
                message: "Campo Obrigatório",
              });
            }
          },
        })}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="item-input">
              <Field name="email">
                {({ field, meta }) => (
                  <>
                    <p>Selecione o email: </p>
                    <Select
                      value={field?.value || "0"}
                      disabled={disabledInputSelectMail}
                      className={meta.touched && meta.error ? "error-id" : null}
                      onChange={(value) => {
                        setFieldValue("email", value);
                      }}
                    >
                      <Select.Option value="0">Selecione</Select.Option>
                      {clientMail && (
                        <Select.Option value={clientMail}>
                          {clientMail}
                        </Select.Option>
                      )}
                    </Select>
                  </>
                )}
              </Field>
            </div>
            {hasPermissionToSendToUnregistered && (
              <div className="item-input">
                <Field name="sendToUnregisteredEmail">
                  {({ field }) => (
                    <Checkbox
                      checked={field?.value ?? false}
                      onChange={(e) =>
                        handleChangeSendToUnregisteredMail(
                          e.target.checked,
                          setFieldValue
                        )
                      }
                    >
                      Enviar para outro email
                    </Checkbox>
                  )}
                </Field>
              </div>
            )}
            {hasPermissionToSendToUnregistered &&
              values.sendToUnregisteredEmail && (
                <div className="item-input">
                  <p>Digite o Email</p>
                  <Field name="customEmail">
                    {({ field, meta }) => (
                      <input
                        type="email"
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        placeholder="Digite o e-mail que será enviado"
                        {...field}
                        disabled={disabledInputMail}
                        onChange={(e) => {
                          setDisabledInputSelectMail(true);
                          field?.onChange(e);
                        }}
                      />
                    )}
                  </Field>
                </div>
              )}
            <ErrorMessage
              name="email"
              component="div"
              className="error-message"
            />
            <ErrorMessage
              name="customEmail"
              component="div"
              className="error-message"
            />

            <div className="item-submit">
              <button type="submit">
                <MdOutlineSend /> <p>Enviar</p>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </S.FormWrapper>
  );
}
