import api from './api';

export class cardProcessorService {
  static getClientId(clientId) {
    return api
      .get('clientId', { client_id: clientId })
      .then((response) => response.data);
  }

  static getParameters() {
    return api.get('parameters').then((response) => response.data);
  }
}
