import styled from 'styled-components';

export const FormActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  button + button {
    margin-left: 10px;
  }
  button {
    width: 90%;
  }
`;
