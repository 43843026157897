import styled from "styled-components";

export const WrapperNewLogin = styled.div`
  width: 100%;
  min-height: 100vh;
  /* height: 100vh; */
  background: #ffb32c;
  padding: 20px 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  .content-login {
    width: 100%;
    background: #fff;
    border-radius: 32px;
    overflow: hidden;

    .content-login__left {
      padding: 25px;

      .content-login__btn-recovery {
        width: 100%;

        display: flex;
        justify-content: flex-end;

        button {
          height: 50px;
          padding: 8px 24px;
          color: var(--neutral-color-gray-9, #434343);
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          &.back-btn {
            background: #fff;
            border: 1px solid #434343;
            border-radius: 2px;
          }

          &.adjust-submit {
            width: auto !important;
            margin-left: 15px;
          }
        }
      }

      .content-login__logo {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        .content-login__logo-left {
          width: 60%;
        }

        .content-login__logo-right {
          width: 40%;

          p {
            padding: 0;
            margin: 0;
            margin-left: 16px;
            border-left: 1px solid #e5e7eb;
            padding-left: 16px;

            color: var(--neutral-color-gray-8, #595959);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        img {
          max-width: 218px;
          width: 100%;
          height: auto;
        }
      }

      .content-login__title {
        width: 100%;
        margin-bottom: 48px;

        p {
          padding: 0;
          margin: 0;

          color: var(--neutral-color-gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &.title {
            color: var(--neutral-color-gray-9, #434343);
            font-family: Roboto;
            font-size: 20px;
            /* font-size: 30px; */
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 16px;
          }
        }
      }

      .content-login__form {
        width: 100%;

        button {
          &[type="submit"] {
            width: 100%;
            height: 50px;
            padding: 8px 24px;
            border-radius: 2px;
            border: 1px solid var(--brand-color-gold-6, #ffb32c);
            background: var(--brand-color-gold-6, #ffb32c);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            color: var(--neutral-color-gray-9, #434343);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            transition: all 0.3s ease;

            &:disabled {
              opacity: 0.5;
              background: #f5f5f5;
              border-color: #ccc;
              cursor: not-allowed;
            }

            &:hover {
              opacity: 0.6;
            }
          }
        }

        .content-login__form-recovery {
          width: 100%;
          margin-bottom: 24px;

          display: flex;
          justify-content: flex-end;

          button {
            background: transparent;
            border: 0;
            color: var(--neutral-color-gray-7, #8c8c8c);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            display: flex;
            align-items: center;
            transition: all 0.5s ease;

            svg {
              margin-left: 8px;
            }

            &:hover {
              color: #ffb32c;
            }
          }
        }

        .content-login__form-item {
          width: 100%;
          position: relative;
          margin-bottom: 16px;

          .error-message {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            color: #ff4d4f;
          }

          .icone-input {
            position: absolute;
            bottom: 5px;
            right: 7px;

            &.check {
              cursor: pointer;
            }

            svg {
              font-size: 18px;
              color: #ffb32c;
            }
          }

          p {
            padding: 0;
            margin: 0;
            color: var(--neutral-color-dark-10, #272d3b);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          input {
            width: 100%;
            padding: 8px 27px 8px 12px;
            border-radius: 2px;
            border: 1px solid var(--neutral-5, #d9d9d9);
            background: var(--neutral-color-gray-1, #fff);

            &::placeholder {
              color: var(--neutral-color-gray-6, #bfbfbf);
            }

            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            &.error-id {
              border-color: #ff4d4f;
            }
          }
        }
      }

      .content-login__benefits {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          color: var(--neutral-color-gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          display: flex;
          align-items: center;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    padding: 50px 100px;
    overflow: hidden;
    .content-login {
      display: flex;
      flex-wrap: wrap;
      max-width: 1440px;
      margin: 0 auto;

      .content-login__left {
        padding: 80px 100px 32px 100px;
        width: 593px;
        height: 766px;

        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
      }

      .content-login__right {
        width: calc(100% - 593px);
        background: url("/images/new-bg-login.png") no-repeat center center;
        background-size: cover;
      }
    }
  }
`;
