import { useCallback, useEffect, useState } from "react";

import moment from "moment";

import api from "../services/api";
import { notifyError } from "../utils/notifications";
import { obterNomeDoMes } from "../utils/obterNomeDoMes";
import useCheckPermission from "../utils/useCheckPermission";

const useInvoicev2 = (clientId, startDate, enDate) => {
  const [invoices, setInvoices] = useState([]);
  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const hasPermission = useCheckPermission("invoice_search");



  const getData = useCallback(async () => {
     /* eslint-disable */
    try {
      await setLoadingInvoice(true);
      const {
        data: { faturasResponse },
      } = await api.get("client_invoice_period", {
        client_id: clientId,
        start_date: startDate,
        end_date: enDate,
      });
      const sortedInvoices = faturasResponse.sort(
        (a, b) =>
          moment.parseZone(b.dataVencimento) -
          moment.parseZone(a.dataVencimento)
      );
      let formatedDateInvoices = sortedInvoices.map((invoice) => {
        const formatedDate = moment.parseZone(invoice.dataVencimento);
        const splitDate = formatedDate.format("DD/MM/YYYY").split("/");

        invoice.dataVencimento = formatedDate.format("DD/MM/YYYY");
        invoice.valorMinimo = `R$ ${Number(invoice.valorMinimo).toFixed(2)}`;
        invoice.devedor = `R$ ${Number(invoice.devedor).toFixed(2)}`;
        invoice.valorPagamentosEfetuados = `R$ ${Number(
          invoice.valorPagamentosEfetuados
        ).toFixed(2)}`;
        invoice.valorTotal = `R$ ${Number(invoice.valorTotal).toFixed(2)}`;
        invoice.anoVencimento = splitDate[2].slice(-2);
        invoice.MesVencimento = obterNomeDoMes(splitDate[1]);
        invoice.DataVencimentoOnly = splitDate[0];

        return invoice;
      });

      if (formatedDateInvoices.length > 10) {
        // removendo os 4 ultimos indices para ficar mais bonito na tela
        formatedDateInvoices.pop();
        formatedDateInvoices.pop();
        formatedDateInvoices.pop();
        formatedDateInvoices.pop();
      }

      formatedDateInvoices = formatedDateInvoices.filter(
        (_i) => _i.idFatura !== "[Futura]"
      );

      const reversedArray = [...formatedDateInvoices].reverse();

      await setInvoices(reversedArray);
    } catch (error) {
      console.error(error.message);
    } finally {
      await setLoadingInvoice(false);
    }
     /* eslint-disable */
  }, [enDate, startDate]);

  useEffect(() => {
    if (clientId && hasPermission) {
      getData();
    }
  }, [clientId, getData, hasPermission]);

  return {
    invoices,
    loadingInvoice,
    getData,
  };
};

export default useInvoicev2;
