import React, { useEffect, useRef, useState } from "react";

import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Descriptions, Input, Modal, Select, Tooltip } from "antd";
import SignatureCanvas from "react-signature-canvas";

import InsuranceIcon from "../../../../assets/icons/security.png";
import userCheck from "../../../../assets/icons/user-check.png";
import userPlus from "../../../../assets/icons/user-plus.png";
import LoadingElevaty from "../../../../components/LoadingElevaty";
import { usePermissions } from "../../../../Context/permissionContext";
import { useAuth } from "../../../../hooks/AuthProvider";
import useInsurance from "../../../../hooks/useInsurance";
import api from "../../../../services/api";
import { formatClientName } from "../../../../utils/formatClientName";
import { formatCpf } from "../../../../utils/formatCpf";
import { notifyError, notifySucces } from "../../../../utils/notifications";
import { validaCpf } from "../../../../utils/validations";
import * as S from "./style";

const { Option } = Select;

const ModalAcessInsurances = ({
  client,
  open,
  closeModal,
  setDependents,
  clientId,
  clientInsurances,
  lojas,
  reloadClientInsurances,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [listDependents, setListDependents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectLoja, setSelectLoja] = useState(null);
  const [insuranceSelected, setInsuranceSelected] = useState({});
  const [loadingInsuranceAdd, setLoadingInsuranceAdd] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [currentBeneficiary, setCurrentBeneficiary] = useState("");
  const [filteredInsurances, setFilteredInsurances] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [fieldsSaved, setFieldsSaved] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [errorMessages, setErrorMessages] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCpfValid, setIsCpfValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { permissions } = usePermissions();
  const [nomeSocial, setNomeSocial] = useState(null);
  const [signatureBase64, setSignatureBase64] = useState(null);
  const [previewSignatureBase64, setPreviewSignatureBase64] = useState(null);
  const [optionNomeSocial, setOptionNomeSocial] = useState(true);
  const sigCanvas = useRef({});
  const [setSavedSignature] = useState(null);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [isSignatureCleared, setIsSignatureCleared] = useState(false);
  const [canvasSize, setCanvasSize] = useState({ width: 544, height: 113 });

  const { user } = useAuth();

  const hasNomeSocial = sessionStorage.getItem('@SnxPayment:hasSocialName');

  const parsedNomeSocial = hasNomeSocial !== null && hasNomeSocial !== undefined
    ? JSON.parse(hasNomeSocial)
    : false;

  const handleSelection = async (person, typePerson) => {
    setSelectedBeneficiary(person);
    if (typePerson === "dependente") {
      const allowedForDependents = insurances.filter(
        (insurance) => insurance.permiteDependente
      );
      if (allowedForDependents.length === 0) {
        notifyError("Nenhum seguro disponível permite dependente");
      } else {
        setFilteredInsurances(allowedForDependents);
      }
      setCurrentBeneficiary("dependente");
    } else {
      const activeInsurancesDescriptions = clientInsurances.map(
        (insurance) => insurance.descricaoSeguro
      );
      const availableInsurances = insurances.filter(
        (insurance) =>
          !activeInsurancesDescriptions.includes(insurance.descricao)
      );
      setFilteredInsurances(availableInsurances);
      setCurrentBeneficiary("titular");
    }
  };

  const handleCancel = () => {
    setSelectedBeneficiary(null);
    setListDependents([]);
    setLoading(false);
    setCurrentStep(0);
    setSelectLoja(null);
    setInsuranceSelected({});
    setSignatureBase64(null);
    closeModal();
    setNomeSocial(null);
    setOptionNomeSocial(true); 
  };

  const advance = () => {
    if (selectedBeneficiary) {
      setCurrentStep(1);
    } else {
      notifyError(
        "Por favor, selecione um titular ou dependente para continuar."
      );
    }
  };

  const goBack = () => {
    setCurrentStep(0);
    setInsuranceSelected({});
    setSelectLoja(null);
  };

  const handleSignature = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const canvas = sigCanvas.current.getCanvas();
      const previewBase64 = canvas.toDataURL("image/jpeg", 0.7);
      const resizedCanvas = document.createElement("canvas");
      const ctx = resizedCanvas.getContext("2d");
      const scaleFactor = 0.5;

      resizedCanvas.width = canvas.width * scaleFactor;
      resizedCanvas.height = canvas.height * scaleFactor;

      ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

      const base64 = resizedCanvas.toDataURL("image/jpeg", 0.7);
      setSignatureBase64(base64);
      setPreviewSignatureBase64(previewBase64)
      setShowSaveSuccess(true);
      notifySucces("Assinatura salva com sucesso!");
      setTimeout(() => {
        setShowSaveSuccess(false);
      }, 3000);
      setIsSignatureCleared(false);
    } else {
      notifyError("Por favor, assine antes de salvar!");
    }
  };

  const clearSignature = () => {
    setPreviewSignatureBase64(null);
    setSignatureBase64(null);
    sigCanvas.current.clear();
    setSavedSignature(null);
    setIsSignatureCleared(true);
  };

  useEffect(() => {
    const updateCanvasSize = () => {
      if (window.innerWidth < 768) {
        setCanvasSize({ width: 365, height: 113 });
      } else {
        setCanvasSize({ width: 544, height: 113 });
      }
    };

    window.addEventListener("resize", updateCanvasSize);
    updateCanvasSize();

    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const loadDependents = async () => {
      if (!open) {
        return;
      }
      setLoading(true);
      try {
        const dependentData = await setDependents(clientId);
        if (isMounted) {
          setListDependents(dependentData || []);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
          setListDependents([]);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    if (clientId && open) {
      loadDependents();
    }
    return () => {
      isMounted = false;
    };
  }, [clientId, setDependents, open]);

  useEffect(async () => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get('insurances');
        const segurosData = response.data.filter(seguro => !seguro.exclusivoPdv);
        setInsurances(segurosData);
        setFilteredInsurances(segurosData);
      } catch (error) {
        console.error('Falha ao carregar seguros', error);
        notifyError('Falha ao listar seguros');
      } finally {
        setLoading(false);
      }
    };

    if (clientId) fetchData();
  }, [clientId]);

  const createSelectFields = async (arr) => {
    await Promise.all(
      arr.map(async (field, i) => {
        if (field.conteudoPadrao) {
          const auxArr = field.conteudoPadrao.split(",");
          field.selectFormatted = [];
          await Promise.all(
            auxArr.map(async (a) => {
              const aux = a.split("|");
              field.selectFormatted.push({
                val1: aux[0],
                val2: aux[1],
                text: aux[2],
              });
            })
          );
          arr[i] = field;
        }
      })
    );
    return arr;
  };

  const openModalFields = () => {
    const resetInputValues = missingFields.reduce((acc, field) => {
      acc[field.nome] = '';
      return acc;
    }, {});
    setInputValues(resetInputValues);
    setInputKey(Date.now());
    setModalVisible(true);
  };

  const openErrorModal = () => {
    setShowErrorModal(true);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    goBack();
  };

  const contractForHolder = () => {
    goBack();
    closeErrorModal();
  };

  const handleInsurance = async (value) => {
    let auxInsuranceSelect = null;
    if (value !== 'none') {
      auxInsuranceSelect = insurances.find(
        (insurance) => Number(insurance.id) === Number(value)
      );

      if (currentBeneficiary === 'dependente') {
        const titularHasInsurance = clientInsurances.some(insurance => insurance.codigoSeguro === auxInsuranceSelect.id);

        if (!titularHasInsurance) {
          openErrorModal();
          return;
        }
      }

      setInsuranceSelected(auxInsuranceSelect);
    } else {
      setInsuranceSelected({});
    }


    if (auxInsuranceSelect) {
      setLoading(true);
      let beneficiary;
      let idDependente;
      if (currentBeneficiary === 'dependente') {
        beneficiary = selectedBeneficiary;
        idDependente = selectedBeneficiary.id;
      } else {
        beneficiary = { id: clientId, tipo: 'titular' };
      }

      try {
        const response = await api.get('insurance_fields', {
          codigoOperacao: auxInsuranceSelect.idOperacao,
          codigoProduto: auxInsuranceSelect.idProduto,
          codigoSeguro: auxInsuranceSelect.id,
          idCliente: beneficiary.tipo === 'dependente' ? beneficiary.idClienteTitularAssociado : clientId,
          idDependente,
        });

        if ((response.data.campos && response.data.campos.length > 0) || (parsedNomeSocial === true && beneficiary.tipo === 'titular')) {
          const fields = await createSelectFields(response.data.campos);
          if (beneficiary.tipo === 'dependente') {
            fields.dependent_name = beneficiary.nome;
          }
          setMissingFields(fields);
          setOptionNomeSocial(false); 
          openModalFields();
        } else {
          const fields = await createSelectFields([]);
          setMissingFields(fields);
        }
      } catch (error) {
        console.error("Erro na chamada da API para campos de seguro:", error);
        notifyError("Erro ao obter campos de seguro para dependente.");
      }
      setLoading(false);
    }
  };

  const handleFieldChangeSelect = (index, value) => {
    const newInputValues = { ...inputValues, [missingFields[index].nome]: value };
    setInputValues(newInputValues);
  };

  const handleFieldChange = (index, value) => {
    const field = missingFields[index];

    if (field.nome === 'numerodocumento') {
      value = value.replace(/\D/g, '');
    }

    if (field.nome === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailRegex.test(value));
    }

    if (field.nome === 'num_cpf') {
      const formattedValue = value.replace(/\D/g, '');
      const isValid = validaCpf(formattedValue);
      setIsCpfValid(isValid);
    }

    const newInputValues = { ...inputValues, [field.nome]: value };
    setInputValues(newInputValues);
  };

  const handleHireInsurance = async () => {
    setLoading(true);
    try {
      const camposAdesao = missingFields.map(field => ({
        nome: field.nome,
        valor: inputValues[field.nome] || '',
      }));

      if( currentBeneficiary === 'titular' && nomeSocial !== null){
        const fieldNomeSocial = {
          "nome": "contratante.dadosPessoais.nomeSocial",
          "valor": nomeSocial
        };
        camposAdesao.push(fieldNomeSocial);
      }
      
      if (signatureBase64) {
        const base64WithoutPrefix = signatureBase64.substring(
          signatureBase64.indexOf(",") + 1
        );
        camposAdesao.push({
          nome: "contratante.dadosPessoais.assinatura",
          valor: base64WithoutPrefix,
        });
      }

      let postData = {
        client_id: clientId,
        codigoPortador: clientId,
        codigoLoja: selectLoja,
        codigoOperacao: insuranceSelected.idOperacao,
        codigoProduto: insuranceSelected.idProduto,
        codigoPromotor: user.id,
        codigoSeguro: insuranceSelected.id,
        cpfTitular: client.client.cpf,
        meioAdesao: "SNXPAYMENT",
        nomePromotor: user.username,
        camposAdesao,
        descricaoSeguro: insuranceSelected.descricao,
      };

      if (currentBeneficiary === 'dependente') {
        postData = {
          ...postData,
          nomeDependente: selectedBeneficiary.nome,
          codigoDependente: selectedBeneficiary.id,
          codigoPortadorTitular: clientId,
        };
      }

      const endpoint = currentBeneficiary === 'dependente' ? 'dependant_insurances' : 'client_insurances';

      if (!loadingInsuranceAdd) {
        setLoadingInsuranceAdd(true);
        const response = await api.post(endpoint, postData);

        if (response.data && response.data.campos && response.data.campos.length > 0) {
          setMissingFields(response.data.campos);
          setCurrentStep(2);
        } else {
          notifySucces('Seguro contratado com sucesso.');
          reloadClientInsurances();
          handleCancel();
        }
      }
    } catch (error) {
      const errorMessage = error.message.split(',')[0];
      notifyError(errorMessage || 'Falha ao contratar seguro.');
    } finally {
      setLoading(false);
      setLoadingInsuranceAdd(false);
    }
  };

  const handleContractInsurance = async () => {
    const hasErrors = await Promise.all(
      missingFields.map(async (field) => {
        if (
          field.obrigatorio === true &&
          (inputValues[field.nome] === "" ||
            inputValues[field.nome] === null ||
            inputValues[field.nome] === undefined)
        ) {
          notifyError(`Preencha o campo ${field.nome}`);
          return true;
        }
        return false;
      })
    );
  
    if (hasErrors.some((error) => error)) {
      setModalVisible(true);
    } else {
      await handleHireInsurance();
    }
  };

  const isButtonDisabled = !selectedBeneficiary;

  const closeModalFields = () => {
    setModalVisible(false);
  };
  const handleSaveFields = () => {
    const requiredFields = missingFields.filter((field) => field.obrigatorio);
    let hasCpfField = false;
    let isValidCpf = true;
    let hasEmailField = false;

    const newInputValues = { ...inputValues };

    requiredFields.forEach((field) => {
      if (field.nome === 'num_cpf') {
        hasCpfField = true;
        isValidCpf = validaCpf(newInputValues[field.nome]);
        if (!isValidCpf) {
          notifyError('CPF inválido. Por favor, insira um CPF válido.');
          return;
        }
      }

      if (field.nome === 'email') {
        hasEmailField = true;
        if (!isEmailValid) {
          notifyError('E-mail inválido. Por favor, insira um e-mail no formato nome@dominio.com');
          // eslint-disable-next-line no-useless-return
          return;
        }
      }
    });

    if ((hasCpfField && !isValidCpf) || (hasEmailField && !isEmailValid)) {
      return;
    }

    const newErrorMessages = {};
    const emptyFields = requiredFields.filter(
      (field) => !newInputValues[field.nome]
    );
    emptyFields.forEach((field) => {
      newErrorMessages[field.nome] = `${field.descricao} é obrigatório.`;
    });

    setErrorMessages(newErrorMessages);

    if (emptyFields.length > 0) {
      notifyError('Por favor, preencha todos os campos obrigatórios antes de Salvar.');
      return;
    }

    setFieldsSaved(true);
    closeModalFields();
    notifySucces('Campos adicionais salvos com sucesso');
  };

  const handleCancelFields = () => {
    closeModalFields();
  };

  useEffect(() => {
    // Verifica se existe apenas uma loja no array `lojas` e atualiza o estado `selectLoja`
    if (lojas && lojas.length === 1) {
      setSelectLoja(lojas[0].id);
    }
  }, [lojas]);


  return (
    <S.ModalInsurance
      visible={open}
      title={<S.ModalTitle>Contratar seguro</S.ModalTitle>}
      width={currentStep === 1 ? 1013 : 572}
      onCancel={handleCancel}
      footer={null}
    >
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.ContentContainer>
        {currentStep === 0 && (
          <>
            <S.SelectPerson>
              <h2>Para:</h2>
              {[client, ...listDependents].map((person, index) => (
                <S.PersonContainer
                  key={person.id || index}
                  isSelected={selectedBeneficiary === person}
                  onClick={() => handleSelection(person, person === client ? 'titular' : 'dependente')}
                >
                  <div className='personContainer__title'>
                    <h3>
                      <img src={person === client ? userCheck : userPlus} alt={person === client ? 'user check icon' : 'user Plus icon'} />
                      {person === client ? 'Titular' : 'Dependente'}
                    </h3>
                  </div>
                  <p><strong>Nome: </strong>{formatClientName(person === client ? person?.client?.name : person?.nome)}</p>
                  <p><strong>CPF: </strong>{person === client ? person?.client?.cpf ? formatCpf(person.client.cpf) : 'CPF não disponível' : person?.cpf ? formatCpf(person.cpf) : 'CPF não disponível'}</p>
                  <div className="checkmark">
                    <CheckOutlined />
                  </div>
                </S.PersonContainer>
              ))}
            </S.SelectPerson>
            <S.DividerLine />
            <S.ButtonContainer>
              {isButtonDisabled ? (
                <Tooltip title="Por favor, selecione um titular ou dependente para continuar.">
                  <Button
                    type="primary"
                    onClick={advance}
                    disabled={isButtonDisabled}
                  >
                    <CheckOutlined />Avançar
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  type="primary"
                  onClick={advance}
                  disabled={isButtonDisabled}
                >
                  <CheckOutlined />Avançar
                </Button>
              )}
            </S.ButtonContainer>
          </>
        )}
      </S.ContentContainer>
      {currentStep === 1 && selectedBeneficiary && (
        <>
          <S.SelectInsurance>
            <S.SelectedPerson>
              <h2>Para:</h2>
              {selectedBeneficiary && (
                <S.PersonContainer isSelected >
                  <div className='personContainer__title'>
                    <h3>
                      <img src={selectedBeneficiary === client ? userCheck : userPlus} alt="Selected Beneficiary" />
                      {selectedBeneficiary === client ? 'Titular' : 'Dependente'}
                    </h3>
                  </div>
                  <p><strong>Nome: </strong>{selectedBeneficiary === client ? formatClientName(client?.client?.name) : formatClientName(selectedBeneficiary?.nome)}</p>
                  <p><strong>CPF: </strong>{selectedBeneficiary === client ? (client?.client?.cpf ? formatCpf(client.client.cpf) : 'CPF não disponível') : (selectedBeneficiary?.cpf ? formatCpf(selectedBeneficiary.cpf) : 'CPF não disponível')}</p>
                  <div className="checkmark">
                    <CheckOutlined />
                  </div>
                </S.PersonContainer>
              )}
              {clientInsurances.length > 0 && (
                <h2>Seguros Contratados:</h2>
              )}
              <S.ContractedInsurances>
                {!clientInsurances[0] ? (
                  <h3>Titular não possui nenhum seguro.</h3>
                ) : (
                  <>
                    {clientInsurances.map((tag, index, array) => (
                      <div key={tag.codigoSeguro}>
                        <S.ContractedInsurance>
                          <p>{tag?.codigoAdesao}</p>
                          <p>Valor:</p>
                        </S.ContractedInsurance>
                        <S.ContractedInsurance>
                          <p>{tag?.descricaoSeguro}</p>
                          <p className='contractedInsurance__value'>{tag?.valorParcela}</p>
                        </S.ContractedInsurance>
                        {index < array.length - 1 && <S.DividerLineTwo />}
                      </div>
                    ))}
                  </>
                )}
              </S.ContractedInsurances>
            </S.SelectedPerson>
            <S.VerticalDivider />
            <S.InsuranceInfo>
              <S.InsuranceContainer>
                {lojas && lojas.length > 0 ? (
                  <>
                    <h2>Escolha a loja:</h2>
                    <S.StyledSelect
                      defaultValue={selectLoja}
                      showSearch
                      placeholder="Selecione uma loja"
                      optionFilterProp="name"
                      optionLabelProp="name"
                      listHeight={300}
                      onChange={async (id_loja) => {
                        await setSelectLoja(id_loja);
                      }}
                      required
                    >
                      {lojas && lojas.map((loja) => (
                        <Option
                          value={loja.id}
                          key={loja.id}
                          name={`${loja.nomeFantasia ?? ''} ${loja.razaoSocial}`}
                        >
                          <Descriptions>
                            {loja.nomeFantasia && (
                              <Descriptions
                                label="Nome Fantasia"
                                span={8}
                                labelStyle={{ fontWeight: 700 }}
                              >
                                {loja.nomeFantasia}
                              </Descriptions>
                            )}
                            <Descriptions
                              label="Razão Social"
                              span={8}
                              labelStyle={{ fontWeight: 700 }}
                            >
                              {loja.razaoSocial}
                            </Descriptions>
                          </Descriptions>
                        </Option>
                      ))}
                    </S.StyledSelect></>
                ) : null}
                <h2>Escolha o seguro:</h2>
                <Tooltip
                  title={!selectLoja && lojas.length > 0 ? "Por favor, selecione a loja antes de selecionar o seguro" : ""}
                >
                  <S.StyledSelect
                    onChange={(e) => handleInsurance(e)}
                    id="insurances_list"
                    key={insuranceSelected.id}
                    placeholder="Selecione um seguro"
                    defaultValue={
                      insuranceSelected.id ? insuranceSelected.id : 'Selecione um seguro'
                    }
                    disabled={lojas.length > 0 && !selectLoja}
                  >
                    {filteredInsurances.map((insurance) => (
                      <Option value={insurance.id} key={insurance.id}>
                        {insurance.descricao}
                      </Option>
                    ))}
                  </S.StyledSelect>
                </Tooltip>
              </S.InsuranceContainer>
              {permissions.includes("mandatory_signature") && currentBeneficiary === "titular" && (
                <S.SignatureContainer>
                  <h2>Assinatura:</h2>
                  <S.SignatureCanvas>
                    {
                      signatureBase64 === null ? (
                        <SignatureCanvas
                          penColor="black"
                          backgroundColor="white"
                          canvasProps={canvasSize}
                          ref={sigCanvas}
                        />
                      ) : (
                        <img src={previewSignatureBase64} alt="Preview da assinatura" />
                      )
                    }
                  </S.SignatureCanvas>
                  <S.SignatureButton>
                    <Button type="button" onClick={handleSignature}>
                      Salvar assinatura
                    </Button>
                    <Button type="button" onClick={clearSignature}>
                      Limpar assinatura
                    </Button>
                  </S.SignatureButton>
                </S.SignatureContainer>
              )}
              <S.SelectedInsuranceInfo>
                {insuranceSelected && insuranceSelected.id && (
                  <S.SelectedInsuranceInfo>
                    <>
                      <h2>Valor do seguro:</h2>
                      <S.DisplayInsuranceInfo>
                        <p>
                          <img src={InsuranceIcon} alt="icone de seguros" />
                          {insuranceSelected?.totalParcelas} Parcelas
                        </p>
                        <p><strong>R$ {insuranceSelected.valorParcela} </strong></p>
                      </S.DisplayInsuranceInfo>
                    </>
                  </S.SelectedInsuranceInfo>
                )}

              </S.SelectedInsuranceInfo>
            </S.InsuranceInfo>
          </S.SelectInsurance>
          <S.DividerLine />
          <S.ActionButtonsContainer>
            <Button onClick={goBack}><LeftOutlined />Voltar</Button>
            {insuranceSelected && insuranceSelected.id ? (
              <Tooltip
                title={
                  permissions.includes("mandatory_signature") &&
                  !signatureBase64 && currentBeneficiary === "titular"
                    ? "Salve a assinatura antes de contratar"
                    : ""
                }
              >
                <Button
                  type="primary"
                  onClick={() => handleContractInsurance()}
                  disabled={
                    permissions.includes("mandatory_signature") &&
                    !signatureBase64 && currentBeneficiary === "titular"
                  }
                >
                  <CheckOutlined />
                  {isMobile ? "Contratar" : "Contratar seguro"}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Por favor, selecione um seguro para continuar.">
                <Button
                  type="primary"
                  disabled
                >
                  <CheckOutlined />
                  {isMobile ? 'Contratar' : 'Contratar seguro'}
                </Button>
              </Tooltip>
            )}
          </S.ActionButtonsContainer>
        </>
      )}
      <Modal
        title="Campos Adicionais"
        visible={modalVisible}
        onCancel={handleCancelFields}
      >
        <p>Existem informações incompletas para o beneficiario selecionado, preencha e salve para continuar com a contratação.</p>
        {missingFields.map((field, index) => (
          <S.FieldsContainer key={field.nome}>
            <S.SubTitle>{field.descricao} <span className='mandatory'>*</span></S.SubTitle>
            {field.conteudoPadrao ? (
              <Select
                key={`select-field-${inputKey}`}
                id={`field_list_${index}`}
                defaultValue="none"
                onChange={(value) => handleFieldChangeSelect(index, value)}
              >
                <Option value="none" key="none">
                  Selecione o {field.descricao}
                </Option>
                {field.selectFormatted.map((sf) => (
                  <Option value={sf.val2} key={sf.val2}>
                    {sf.text}
                  </Option>
                ))}
              </Select>
            ) : (
              <>
                <Input
                  key={`input-field-${inputKey}`}
                  type={field.tipo === 'number' ? 'number' : 'text'}
                  value={inputValues[missingFields[index].nome] || ''}
                  placeholder={field.nome === 'email' ? 'nome@dominio.com' : (field.nome === 'num_cpf' || field.nome === 'numeroDocumento' ? 'Digite apenas números' : field.descricao)}
                  onChange={(e) => handleFieldChange(index, e.target.value)}
                  maxLength={field.nome === 'num_cpf' ? 11 : undefined}
                  style={{ borderColor: field.nome === 'num_cpf' && !isCpfValid ? 'red' : undefined }}
                />
                {errorMessages[field.nome] && <p>* {errorMessages[field.nome]}</p>}
                {field.nome === 'num_cpf' && !isCpfValid && <p style={{ color: 'red' }}>CPF inválido</p>}
              </>
            )}
          </S.FieldsContainer>
        ))}
        {currentBeneficiary === 'titular' && parsedNomeSocial === true && 
          <S.FieldsContainer key="nomeSocial">
            <S.SubTitle>Nome Social <span className='optional'>(opcional)</span></S.SubTitle>
            <Input
              key="input-field-nomeSocial"
              type="text"
              onChange={(e) => setNomeSocial(e.target.value)}
            />
          </S.FieldsContainer>
        }
        <S.FieldsButtons>
          {parsedNomeSocial === true && missingFields.length === 0 &&
            <Button key="submit" onClick={handleCancelFields}>Continuar</Button>
          }
          <Button key="submit" type="primary" onClick={handleSaveFields}>Salvar</Button>
        </S.FieldsButtons>
      </Modal>
      <S.StyledErrorModal
        title="Atenção"
        visible={showErrorModal}
        onCancel={closeErrorModal}
        maskClosable={false}
        closable={false}
      >
        <p>O Titular precisa ter o seguro antes de contratar para o dependente!</p>
        <S.ErrorModalButtons>
          <Button key="cancel" onClick={closeErrorModal}>
            Cancelar
          </Button>
          <Button key="contract" type="primary" onClick={contractForHolder}>
            Contratar para o Titular
          </Button>
        </S.ErrorModalButtons>
      </S.StyledErrorModal>

    </S.ModalInsurance>
  );
};

export default ModalAcessInsurances;
