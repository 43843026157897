import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import IconMenuElevaty from "../../assets/iconMenuElevaty";
import { usePermissions } from "../../Context/permissionContext";
import { getIconFromName } from "../../utils/getIconFromName";
import SubMenu from "./SubMenu";

function MenuItem({
  title,
  id,
  isBottom,
  iconName,
  typePermission,
  subItems,
  isOpen,
  onSubMenuClick,
  route,
  paymentsClick,
  salesClick,
}) {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const icon =
    iconName === "ElevatyIcon" ? (
      <IconMenuElevaty />
    ) : (
      getIconFromName(iconName)
    );

  useEffect(() => {
    if (!typePermission) {
      setShouldRenderComponent(true);
      return;
    }
    if (typePermission && permissions) {
      const typePermissionValue = typePermission.map(item => item.id);
      setShouldRenderComponent(
        typePermissionValue.some(permission => permissions.includes(permission))
      );
    }
  }, [typePermission, permissions]);

  const changeRouteClick = (route) => {
    navigate(route);
    onSubMenuClick(false);
  };

  if (!shouldRenderComponent) {
    return null;
  }

  return (
    <div className="newmenu-item">
      {subItems ? (
        <>
          <div
            onClick={() => onSubMenuClick(id)}
            className="newmenu-item__sub"
          >
            {icon}
          </div>
          {isOpen && (
            <SubMenu
              isBottom={isBottom}
              subItems={subItems}
              title={title}
              onSubMenuClick={onSubMenuClick}
              paymentsClick={paymentsClick}
              salesClick={salesClick}
            />
          )}
        </>
      ) : (
        <div
          onClick={() => changeRouteClick(route)}
          className="newmenu-item__single"
        >
          {icon}
        </div>
      )}
    </div>
  );
}

export default MenuItem;
