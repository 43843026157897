import React, { Suspense } from "react";

import { Route, Routes } from "react-router-dom";

import SearchUser from "../components/SearchUser";
import SideMenu from "../components/SideMenu";
import Skeleton from "../components/Skeleton";
import { useVersion } from "../Context/hasBetaActiveContext";
import ListBalanceReserve from "../pages/BalanceReserve";
import LaunchAdjustment from "../pages/LaunchAdjustment";
import ListDefault from "../pages/ListDefault";
import ListPaymentsCreliq from "../pages/ListPaymentsCreliq";
import ListSells from "../pages/ListSells";
import ListBlockAccount from "../pages/ListsInLote/blockAccount";
import ListBlockCard from "../pages/ListsInLote/blockCard";
import ListAlterLimits from "../pages/ListsInLote/changeLimit";
import LockAccountList from "../pages/LockAccountList";
import NewGroups from "../pages/NewGroups";
import NewListPayments from "../pages/NewListPayments";
import NewRecovery from "../pages/NewRecovery";
import NewUsers from "../pages/NewUsers";
import NotFound from "../pages/NotFound";
import PageAtendimento from "../pages/PageAtendimento";
import { PermissionRouter, PrivateRouter } from "./Route";

const NewLogin = React.lazy(() => import("../pages/NewLogin"));
const Home = React.lazy(() => import("../pages/home"));
const ControlUser = React.lazy(() => import("../pages/controlUser"));
const User = React.lazy(() => import("../pages/user"));
const ModalTest = React.lazy(() => import("../pages/modal"));
const Classification = React.lazy(() => import("../pages/classification"));
const Reports = React.lazy(() => import("../pages/reports"));
const Printers = React.lazy(() => import("../pages/printers"));
const ManageAcess = React.lazy(() => import("../pages/manageAcess"));
const ProductList = React.lazy(() => import("../pages/productList"));
const StoreList = React.lazy(() => import("../pages/storeList"));
const PostSale = React.lazy(() => import("../pages/PostSale"));
const PostPayment = React.lazy(() => import("../pages/PostPayment"));
const CanceledListInsurance = React.lazy(() =>
  import("../pages/CanceledListInsurance")
);
const IssuerCycleList = React.lazy(() => import("../pages/IssuerCycles"));
const LockTypesList = React.lazy(() => import("../pages/LockTypesList"));
const TypesAdjustments = React.lazy(() => import("../pages/TypesAdjustments"));
const ScheduleShopkeeper = React.lazy(() =>
  import("../pages/ScheduleShopkeeper")
);
const Occurrences = React.lazy(() => import("../pages/occurrences"));

const Group = React.lazy(() => import("../pages/groups"));
const Pdf = React.lazy(() => import("../pages/pdf"));
const ControlClassification = React.lazy(() =>
  import("../pages/classification/Modals/modalControlSubClass")
);
const ControlGroup = React.lazy(() =>
  import("../pages/classification/Modals/modalControlClass")
);
const Solicitation = React.lazy(() => import("../pages/solicitation"));
const SolicitationType = React.lazy(() => import("../pages/solicitationType"));


const AccessDenied = React.lazy(() => import("../pages/AccessDenied"));

function RoutesPage() {

  const { hasBetaActive } = useVersion();

  return (
    <Suspense fallback={<h1> Carregando... </h1>}>
        <Routes>
          <Route path="/" exact element={<NewLogin />} />
          {/* <Route
            path="/recuperacao-senha"
            exact
            element={<PasswordRecovery />}
          /> */}
          <Route path="/recuperacao-senha" exact element={<NewRecovery />} />

          <Route
            path="/groups"
            exact={false}
            element={
              <PrivateRouter permission="crud_group_user">
                <SideMenu>
                  <NewGroups />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/lista-de-produtos"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected={["1"]}>
                  <ProductList />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/lista-dos-tipos-de-ajustes"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected={["2"]}>
                  <TypesAdjustments />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/agenda-lojista"
            exact={false}
            element={
              <PrivateRouter>
                <Skeleton menuSelected={["5"]}>
                  <ScheduleShopkeeper />
                </Skeleton>
              </PrivateRouter>
            }
          />

          <Route
            path="/listagem-pagamentos"
            exact
            element={
              <PermissionRouter permission="list_pay_launch">
                <SideMenu>
                  <NewListPayments />
                </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/listagem-pagamentos-creliq"
            exact={false}
            element={
              <PrivateRouter>
                <Skeleton menuSelected={["6"]}>
                  <ListPaymentsCreliq />
                </Skeleton>
              </PrivateRouter>
            }
          />

          <Route
            path="/listagem-vendas"
            exact={false}
            element={
              <PermissionRouter permission="list_sale_launch">
                <SideMenu>
                  <ListSells />
                </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/lancar-vendas"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu>
                  <PostSale />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/lancar-pagamentos"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu>
                  <PostPayment/>
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/listagem-reserva-de-saldo"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu>
                  <ListBalanceReserve />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/lista-de-ciclos"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected={["3"]}>
                  <IssuerCycleList />
                </SideMenu>
              </PrivateRouter>
            }
          />
          <Route
            path="/lista-dos-tipos-de-bloqueio-cartao"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected={["4"]}>
                  <LockTypesList />
                </SideMenu>
              </PrivateRouter>
            }
          />
          <Route
            path="/lista-dos-tipos-de-bloqueio-conta"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected={["5"]}>
                  <LockAccountList />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/lista-de-lojas"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected="6">
                  <StoreList />
                </SideMenu>
              </PrivateRouter>
            }
          />
          <Route
            path="/lista-de-seguros-cancelados"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu menuSelected={["7"]}>
                  <CanceledListInsurance />
                </SideMenu>
              </PrivateRouter>
            }
          />
          <Route
            path="/bloqueio-cartao-em-lote"
            exact={false}
            element={
              <PrivateRouter permission="view_bloqcard_in_batch">
                <Skeleton menuSelected={["11"]}>
                  <ListBlockCard />
                </Skeleton>
              </PrivateRouter>
            }
          />
          <Route
            path="/bloqueio-contas-em-lote"
            exact={false}
            element={
              <PrivateRouter permission="view_bloqaccount_in_batch">
                <Skeleton menuSelected={["12"]}>
                  <ListBlockAccount />
                </Skeleton>
              </PrivateRouter>
            }
          />
          <Route
            path="/alteracao-limite-em-lote"
            exact={false}
            element={
              <PrivateRouter permission="view_alter_limit_batch">
                <Skeleton menuSelected={["13"]}>
                  <ListAlterLimits />
                </Skeleton>
              </PrivateRouter>
            }
          />
          <Route
            path="/ocorrencias"
            exact={false}
            element={
              <PrivateRouter>
                <Skeleton menuSelected={[""]}>
                  <Occurrences />
                </Skeleton>
              </PrivateRouter>
            }
          />

          <Route
            path="/classificacao"
            exact
            element={
              <PermissionRouter permission="crud_classification">
                <SideMenu>
                  <Classification />
                </SideMenu>
              </PermissionRouter>
            }
          />
          <Route
            path="/solicitacoes"
            exact
            element={
              <PermissionRouter permission="view_solicitation">
                <SideMenu >
                  <Solicitation />
                </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/reports"
            exact
            element={
              <PermissionRouter permission="request_report">
                <Skeleton menuSelected={["6"]}>
                  <Reports />
                </Skeleton>
              </PermissionRouter>
            }
          />

          <Route
            path="/tipos_solicitacoes"
            exact
            element={
              <PermissionRouter permission="view_solicitation">
                <SideMenu menuSelected={["6"]}>
                    <SolicitationType />
                </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/atendimento"
            exact={false}
            element={
              <PrivateRouter>
                {hasBetaActive === true ? (
                  <SideMenu >
                    <PageAtendimento />
                  </SideMenu>
                ) : (
                  <Home />
                )}
              </PrivateRouter>
            }
          />
          <Route
            path="/new-atendimento"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu>
                  <PageAtendimento />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/home"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu isHome>
                  <SearchUser />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/controleUsuario"
            exact
            element={
              <PrivateRouter>
                <SideMenu>
                  <ControlUser />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/usuario"
            exact
            element={
              <PermissionRouter permission="crud_user">
                <SideMenu>
                  <NewUsers />
                </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/printers"
            exact
            element={
              <PermissionRouter permission="manage_printer">
                  <SideMenu>
                     <Printers />
                  </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/access"
            exact
            element={
              <PermissionRouter permission="ip_restriction">
                  <SideMenu>
                     <ManageAcess/>
                  </SideMenu>
              </PermissionRouter>
            }
          />

          <Route
            path="/classificacao/nova"
            exact
            element={
              <PermissionRouter permission="crud_classification">
                <Skeleton menuSelected={["2"]}>
                  <ControlClassification />
                </Skeleton>
              </PermissionRouter>
            }
          />
          <Route
            path="/grupo/novo"
            exact
            element={
              <PermissionRouter permission="crud_classification">
                <Skeleton menuSelected={["2"]}>
                  <ControlGroup />
                </Skeleton>
              </PermissionRouter>
            }
          />

          <Route
            path="/lista-de-inadimplencia"
            exact={false}
            element={
              <PrivateRouter>
                <Skeleton menuSelected={["8"]}>
                  {" "}
                  {/*  a que se refere essa prorpiedade menu select?  */}
                  <ListDefault />
                </Skeleton>
              </PrivateRouter>
            }
          />

          <Route
            path="/lancar-ajuste"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu>
                  <LaunchAdjustment />
                </SideMenu>
              </PrivateRouter>
            }
          />

          <Route
            path="/access-denied"
            exact={false}
            element={
              <PrivateRouter>
                <SideMenu>
                  <AccessDenied />
                </SideMenu>
              </PrivateRouter>
            }
          />


          <Route path="/pdf" element={<Pdf />} />
          <Route element={<NotFound />} />
        </Routes>
    </Suspense>
  );
}
export default RoutesPage;
