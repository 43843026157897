import React, { useCallback, useState, useEffect } from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { IoIosArrowForward } from "react-icons/io";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import api from "../../services/api";
import { notifyError, notifySucces } from "../../utils/notifications";
import * as S from "./style";

function NewRecovery() {
  const navigate = useNavigate();
  const [hidePass, setHidePass] = useState(false);

  const [values, setValues] = useState({
    new_pwd: "",
    confirm_pwd: "",
  });
  const [meter, setMeter] = React.useState(false);
  const [isWeakPwd, setIsWeakPwd] = useState(true);

  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{6,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: values.new_pwd.match(atLeastOneUppercase),
    lowercase: values.new_pwd.match(atLeastOneLowercase),
    number: values.new_pwd.match(atLeastOneNumeric),
    specialChar: values.new_pwd.match(atLeastOneSpecialChar),
    eightCharsOrGreater: values.new_pwd.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const [urlParams] = useState(new URLSearchParams(window.location.search));

  useEffect(() => {
    if (!urlParams.get("recoveryToken")) {
      navigate("/");
    }
  }, [navigate, urlParams]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await api.post("confirm_recovery_password", {
          user_token: urlParams.get("recoveryToken"),
          new_pwd: data.new_pwd,
          confirm_pwd: data.confirm_pwd,
        });

        notifySucces("Senha alterada com sucesso!");

        setTimeout(() => {
          navigate("/");
        }, 1000);
      } catch (err) {
        notifyError(err.message);
      }
    },
    [navigate, urlParams]
  );

  const handleChange = useCallback(
    (event, setFieldValue) => {
      const { target } = event;
      const { name, value } = target;
      event.persist();
      setFieldValue(name, value);
      setValues({ ...values, [name]: value });
      if (passwordStrength === 5) {
        setIsWeakPwd(false);
      } else {
        setIsWeakPwd(true);
      }
    },
    [values]
  );

  return (
    <S.WrapperNewLogin>
      <div className="content-login">
        <div className="content-login__left">
          <div style={{ width: "100%" }}>
            <div className="content-login__logo">
              <div className="content-login__logo-left">
                <img src="/images/logo-login-new.png" alt="Elevaty" />
              </div>
              <div className="content-login__logo-right">
                {/* <p>atendimento</p> */}
              </div>
            </div>

            <div className="content-login__title">
              <p className="title">Recuperação de Senha</p>
            </div>
            <Formik
              initialValues={{
                new_pwd: "",
                confirm_pwd: "",
              }}
              validationSchema={Yup.object({
                new_pwd: Yup.string()
                  .required("Informe a nova senha")
                  .min(6, "A senha deve ter no mínimo 6 caracteres"),
                confirm_pwd: Yup.string()
                  .required("Informe a senha de confirmação")
                  .oneOf([Yup.ref("new_pwd"), null], "Senhas divergentes"),
              })}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="content-login__form">
                    <div className="content-login__form-item">
                      <Field name="new_pwd">
                        {({ field, meta }) => (
                          <>
                            <p>Nova Senha</p>

                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type={hidePass ? "text" : "password"}
                              placeholder="Nova Senha"
                              onFocus={() => setMeter(true)}
                              value={values.new_pwd}
                              {...field}
                              name="new_pwd"
                              onChange={(e) => handleChange(e, setFieldValue)}
                            />
                            <div
                              className="icone-input check"
                              onClick={() => setHidePass(!hidePass)}
                            >
                              {hidePass ? (
                                <IoEyeOffOutline />
                              ) : (
                                <IoEyeOutline />
                              )}
                            </div>
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="new_pwd"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {meter && (
                      <div style={{ marginBottom: "1rem" }}>
                        <div className="password-strength-meter" />
                        <div>
                          {passwordStrength < 5 && "Deve conter "}
                          {!passwordTracker.uppercase && "maiúscula, "}
                          {!passwordTracker.lowercase && "minúscula, "}
                          {!passwordTracker.specialChar &&
                            "caractere especial, "}
                          {!passwordTracker.number && "número, "}
                          {!passwordTracker.eightCharsOrGreater &&
                            "seis caracteres ou mais"}
                        </div>
                      </div>
                    )}

                    <div className="content-login__form-item">
                      <Field name="confirm_pwd">
                        {({ field, meta }) => (
                          <>
                            <p>Nova Senha</p>

                            <input
                              className={
                                meta.touched && meta.error ? "error-id" : null
                              }
                              type={hidePass ? "text" : "password"}
                              placeholder="Confirmação da Senha"
                              onFocus={() => setMeter(true)}
                              value={values.confirm_pwd}
                              {...field}
                              name="confirm_pwd"
                              onChange={(e) => handleChange(e, setFieldValue)}
                            />
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="confirm_pwd"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <button type="submit" disabled={isWeakPwd}>
                      Confirmar
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="content-login__benefits">
            <a href="http://www.elevaty.com.br">
              Conheça as vantagens da Elevatypayments <IoIosArrowForward />
            </a>
          </div>
        </div>

        <div className="content-login__right" />
      </div>
      <style jsx>
        {`
          .password-strength-meter {
            height: 0.3rem;
            background-color: lightgrey;
            border-radius: 3px;
            margin: 0.5rem 0;
          }

          .password-strength-meter::before {
            content: "";
            background-color: ${[
              "red",
              "orange",
              "#03a2cc",
              "#03a2cc",
              "#0ce052",
            ][passwordStrength - 1] || ""};
            height: 100%;
            width: ${(passwordStrength / 5) * 100}%;
            display: block;
            border-radius: 3px;
            transition: width 0.2s;
          }
        `}
      </style>
    </S.WrapperNewLogin>
  );
}

export default NewRecovery;
