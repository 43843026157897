import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 0.8rem;
`
export const WrapperCardMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 98%;
  gap: 4px;
  margin: 0 auto;

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    /* BUTTON RESET */
  }
`;

export const MenuItem = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  line-height: 22px;
  font-size: 15px;
  position: relative;
  cursor: pointer;
  padding: 6px 0;
  flex-basis: 24%;
  flex-grow: 0;

  .icon {
    font-size: 16px;
  }
  &:not(:first-child)::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
    border-left: 1px solid #E5E7EB;
  }

  ${props =>
      props.disabled && css`
        color: #434343;
        opacity: 0.5;
        cursor: not-allowed;
      `
  }
`;

export const OtherActionsButton = styled.button`
  width: fit-content;
  padding: 4px 24px;
  max-width: 250px;
  border: 1px solid var(--neutral-color-gray-6, #bfbfbf);
  background: var(--neutral-color-gray-1, #fff);
  color: var(--neutral-color-dark-10, #272d3b);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 0.8rem;
`
