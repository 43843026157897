import { React, useCallback, useEffect, useState } from 'react';

import { Modal, Button, Input, Select } from 'antd';

import Loading from '../../../../components/Loading';
import LoadingElevaty from '../../../../components/LoadingElevaty';
import { useClient } from '../../../../hooks/ClientProvider';
import api from '../../../../services/api';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import * as S from './styles';

const { TextArea } = Input;
const { Option } = Select;

function ModalLockAccount({ open, closeModal, clientID }) {
  const { client, updateClient } = useClient();

  const [observation, setObservation] = useState('');
  const [loading, setLoading] = useState(false);

  const [loadingTypes, setLoadingTypes] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [typesBlock, setTypesBlock] = useState([]);

  const getData = useCallback(async () => {
    try {
      setLoadingTypes(true);
      const { data } = await api.get('list_lock_account');
      await setTypesBlock(data);
    } catch (error) {
      console.error('Falha ao listar tipos de bloqueio ao cliente');
    } finally {
      setLoadingTypes(false);
    }
  }, []);
  useEffect(async () => {
    getData();
  }, [getData]);

  const handleChangeObservation = useCallback(async (event) => {
    const { value } = event.target;
    await setObservation(value);
  }, []);

  const handleEraserObservation = useCallback(async () => {
    document.getElementsByClassName('txta')[0].value = '';
    closeModal();
  }, []);

  const handleSendFormObservation = useCallback(async () => {
    setLoading(true);
    await api
      .post('client_lock_account', {
        idTipoBloqueio: selectedType,
        client_id: clientID,
        observacao: observation,
      })
      .then(() => {
        const lastBloqDetail = typesBlock.filter(
          (aux) => aux.id === selectedType
        );

        updateClient({
          ...client,
          client: {
            ...client.client,
            statusCliente: 'B',
            ultimoMotivoBloqueioCliente: lastBloqDetail[0].descricao,
          },
        });
        handleEraserObservation();
        setLoading(false);
        notifySucces('O cliente foi bloqueado com sucesso');
      })
      .catch((err) => {
        setLoading(false);
        handleEraserObservation();
        notifyError('Erro ao bloquear conta');
      });
  }, [selectedType, observation]);

  return (
    <Modal
      title="Bloquear Cliente"
      width={572}
      visible={open}
      onCancel={handleEraserObservation}
    >
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.WrapperSelectBlock>
        {!loadingTypes && (
          <>
            <span>
              <red>* </red> Motivo de bloqueio
            </span>
            <Select
              className="text"
              defaultValue={selectedType}
              placeholder="Selecionar"
              onChange={setSelectedType}
            >
              <Option value="0">Selecionar</Option>
              {typesBlock.map((BloqType) => (
                <Option value={BloqType.id} key={BloqType.id}>
                  {BloqType.resumo[0] +
                    BloqType.resumo.slice(1).toLowerCase()}{' '}
                  - {BloqType.cancelaCliente === "S" ? 'CANCELA' : 'NÃO CANCELA'}
                </Option>
              ))}
            </Select>
          </>
        )}
        {loadingTypes && <Loading />}
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        <span>Observação</span>
        <TextArea
          className="txta"
          rows={3}
          placeholder="Ex.: O cliente teve o seu cartão fraudado e solicitou o bloqueio"
          onChange={handleChangeObservation}
        />
      </S.WrapperSelectBlock>
      {!loading && (
        <>
          <S.ButtonContainer>
            <Button onClick={handleEraserObservation}>Cancelar</Button>
            <Button
              className="button-confirm"
              disabled={observation.length === 0 && selectedType === null}
              onClick={handleSendFormObservation}
            >
              Bloquear
            </Button>
          </S.ButtonContainer>
        </>
      )}
    </Modal>
  );
}

export default ModalLockAccount;
