import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Divider, Modal, Select } from "antd";

import api from "../../../services/api";
import { notifySucces, notifyError } from "../../../utils/notifications";
import * as S from "../style"

const { Option } = Select;

const ChangeClientCardModal = ({
  isVisible,
  onCancel,
  productId,
  client_id,
  cardNumber,
  cardId
}) => {
  const [selectedBlockTypeId, setSelectedBlockTypeId] = useState(null)
  const [isLoadingNewSolicitation, setIsLoadingNewSolicitation] = useState(false);
  const handleBlockTypeSelection = (blockTypeId) => {
    setSelectedBlockTypeId(blockTypeId);
  }

  const handleSolicitation = async () => {
    try {
      const identity = localStorage.getItem("@SnxPayment:identity");
      setIsLoadingNewSolicitation(true);
      const { data } = await api.patch('card_exchange', {
        identity,
        card_id: cardId,
        client_id,
        card_number: cardNumber,
        product_id: productId
      });
      notifySucces('Solicitado com sucesso');
    } catch (error) {
      notifyError(error.message);
    } finally {
      setSelectedBlockTypeId(null);
      setIsLoadingNewSolicitation(false);
      onCancel();
    }
  }

  return (
    <Modal
      visible={isVisible}
      title={<span style={{ fontWeight: "bold" }}>Realizar troca de cartão</span>}
      onCancel={onCancel}
    >
        <S.WrapperSelectBlock>
          <button className="solicitate"
            type="button"
            onClick={handleSolicitation}
            disabled={isLoadingNewSolicitation}
            style={{
              cursor: (isLoadingNewSolicitation) ? "not-allowed" : "pointer",
              opacity: (isLoadingNewSolicitation) ? "0.8" : "1"
            }}>Solicitar</button>
        </S.WrapperSelectBlock>
    </Modal>
  );
}

export default ChangeClientCardModal;
