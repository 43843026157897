import React, { memo, useCallback, useEffect, useState } from "react";

import { Button, Modal, Select, Skeleton } from "antd";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import api from "../../../services/api";
import { notifyError, notifySucces } from "../../../utils/notifications";
import * as S from "./style";

const { Option } = Select;

function ModalPayStores({ onCancel, totalPayment, listSelected, reloadPage }) {
  const [loadingBtn, setLoadingBtn] = useState(false);

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }

  const mascaraMoeda = (event, setFieldValue) => {
    const onlyDigits = event.target.value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");

    const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
    setFieldValue("valor", digitsFloat);
  };

  const handleSubmit = (value) => {

    const d = new Date();
    d.setDate(d.getDate() - 1);
    setLoadingBtn(true);

    if (listSelected && listSelected.length === 1) {
      api
        .post(`post_schedules_pay`, {
          idLoja: listSelected[0].idAgenda,
          valor: value.valor,
          meioPagamento: String(value.meioPagamento).toUpperCase(),
          dataHoraRecebimento: d.toISOString(),
        })
        .then(() => {
          reloadPage();
          notifySucces("Pagamento realizado com sucesso!");
        })
        .catch((error) => {
          notifyError(error.message);
        })
        .finally(() => {
          setLoadingBtn(false);
          onCancel();
        });
    } else {
      listSelected.map(async (item) => {
        api
          .post(`post_schedules_pay`, {
            idLoja: item.idAgenda,
            valor: item.valorDevedor,
            meioPagamento: String(value.meioPagamento).toUpperCase(),
            dataHoraRecebimento: d.toISOString(),
          })
          .then(() => {
            reloadPage();
            notifySucces("Pagamento realizado com sucesso!");
          })
          .catch((error) => {
            notifyError(error.message);
          })
          .finally(() => {
            setLoadingBtn(false);
            onCancel();
          });
      });
    }
  };

  const onlyOneItem = Yup.object({
    valor: Yup.string().required(`Campo obrigatório`),
    meioPagamento: Yup.string().required(`Campo obrigatório`),
  });

  const moreItems = Yup.object({
    meioPagamento: Yup.string().required(`Campo obrigatório`),
  });

  return (
    <Modal title="Realizar Pagamento" visible onCancel={onCancel}>
      <S.ContainerCreateEdit>
        <Formik
          initialValues={{ valor: "", meioPagamento: "" }}
          validationSchema={
            listSelected && listSelected.length === 1 ? onlyOneItem : moreItems
          }
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {listSelected && listSelected.length === 1 && (
                <S.FormRow>
                  <div>
                    <b>Valor:</b>
                  </div>

                  <Field name="valor">
                    {({ field, meta }) => (
                      <ReactInputMask
                        placeholder="Valor da transação"
                        type="text"
                        autoComplete="off"
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        {...field}
                        value={maskCurrency(values.valor)}
                        onChange={(e) => mascaraMoeda(e, setFieldValue)}
                      />
                    )}
                  </Field>

                  <ErrorMessage
                    name="valor"
                    className="error"
                    component="div"
                  />
                </S.FormRow>
              )}
              <S.FormRow>
                <div>
                  <b>Meio de pagamento:</b>
                </div>

                <Field name="meioPagamento">
                  {({ field, meta }) => (
                    <ReactInputMask
                      placeholder="Meio de Pagamento"
                      type="text"
                      autoComplete="off"
                      className={meta.touched && meta.error ? "error-id" : null}
                      {...field}
                    />
                  )}
                </Field>

                <ErrorMessage
                  name="meioPagamento"
                  className="error"
                  component="div"
                />
              </S.FormRow>

              <S.FormRow>
                <div>
                  <b>Total:</b>
                </div>
                <div>
                  <b>{totalPayment}</b>
                </div>
              </S.FormRow>

              <S.FormRow>
                <Button loading={loadingBtn} htmlType="submit" type="primary">
                  Realizar Pagamento
                </Button>
              </S.FormRow>
            </Form>
          )}
        </Formik>
      </S.ContainerCreateEdit>
    </Modal>
  );
}

export default memo(ModalPayStores);
