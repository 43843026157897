import React from 'react';

import { Button, Switch } from 'antd';

import * as S from '../../style';

function Functionality({
  functionality,
  permissions,
  activeFunctionalities,
  handlePermission,
  setEditModalOpen,
  setOpenModalinstallment,
}) {
  return (
    <S.ModalRow>
      <S.FuncionalityWrapper>
        <S.FuncionalityTitle>{functionality}</S.FuncionalityTitle>
        <S.ModalRow>
          {permissions.map((permission) => (
            <>
              <S.FuncionalitySwitch key={permission.id}>
                <Switch
                  onColor="#FF9C00"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={activeFunctionalities.includes(permission.id)}
                  onChange={(value) => {
                    handlePermission(value, permission.id);
                  }}
                />
                <span>{permission.name}</span>
              </S.FuncionalitySwitch>
              {permission.slug === 'group_classification' &&
                activeFunctionalities.includes(permission.id) && (
                  <S.FuncionalitySwitchLink>
                    <Button type="link" onClick={() => setEditModalOpen(true)}>
                      Editar classificações relacionadas
                    </Button>
                  </S.FuncionalitySwitchLink>
                )}
              {permission.slug === 'limit_installment_options' &&
                activeFunctionalities.includes(permission.id) && (
                  <S.FuncionalitySwitchLink>
                    <Button
                      type="link"
                      onClick={() => setOpenModalinstallment(true)}
                    >
                      Editar opções de parcelamento
                    </Button>
                  </S.FuncionalitySwitchLink>
                )}
            </>
          ))}
        </S.ModalRow>
      </S.FuncionalityWrapper>
    </S.ModalRow>
  );
}

export default React.memo(Functionality);
