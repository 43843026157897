import React, { memo, useCallback, useMemo, useState } from 'react';

import { Button, Modal } from 'antd';

import Loading from '../../../components/Loading';
import LoadingElevaty from '../../../components/LoadingElevaty';
import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import * as S from './styles';

function ModalAnnuityAdhered({
  closeModal,
  open,
  reloadPremiumAnnuity,
  activeIdAnnuity,
  cancelIdAnnuity,
}) {
  const [loading, setLoading] = useState(false);
  const { client } = useClient();

  const handleJoinAnnuity = useCallback(async () => {
    try {
      setLoading(true);
      await api.patch(`client/${client.client.id}/annuity`, {
        id_anuidade: activeIdAnnuity,
      });
      notifySucces('Anuidade ativada com sucesso');
      reloadPremiumAnnuity();
    } catch (err) {
      notifyError('Falha ao ativar anuidade');
    } finally {
      setLoading(false);
      closeModal();
    }
  });

  const handleCancelAnnuity = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`client/${client?.client?.id}/annuity`, {
        id_anuidade: cancelIdAnnuity,
      });
      notifySucces('Anuidade cancelada com sucesso');

      api.post('timeline', {
        description: `Anuidade de id ${cancelIdAnnuity} foi cancelada com sucesso.`,
        client_id: client.client.id,
      });
      reloadPremiumAnnuity();
    } catch (err) {
      notifyError('Falha ao cancelar anuidade');
    } finally {
      setLoading(false);
      closeModal();
    }
  });

  const contentActiveAnnuity = useMemo(() => {
    return (
      <Modal visible={open} onCancel={closeModal}>
        <S.ModalTitleAnnuity>Confirmar adesão</S.ModalTitleAnnuity>
        <S.ModalContainer>
          Tem certeza que deseja confirmar adesão?
        </S.ModalContainer>
        <S.ModalBodyAnnuity style={{ display: 'flex', justifyContent: 'end' }}>
          {!loading && (
            <Button onClick={() => closeModal()}>Não, voltar</Button>
          )}
          {!loading && (
            <Button onClick={() => handleJoinAnnuity()}>
              Sim, tenho certeza
            </Button>
          )}
          {loading && <Loading />}
        </S.ModalBodyAnnuity>
      </Modal>
    );
  });
  const contentCancelAnnuity = useMemo(() => {
    return (
      <Modal visible={open} onCancel={closeModal}>
        {loading && (
          <S.NewLoadingModals>
            <LoadingElevaty width="40px" height="40px" duration="durFast" />
          </S.NewLoadingModals>
        )}
        <S.ModalTitleAnnuity>Cancelar anuidade bonificada</S.ModalTitleAnnuity>
        <S.ModalContainer>
          Tem certeza que deseja cancelar a anuidade bonificada
        </S.ModalContainer>
        <S.ModalBodyAnnuity style={{ display: 'flex', justifyContent: 'end' }}>
          {!loading && (
            <Button onClick={() => closeModal()}>Não, voltar</Button>
          )}
          {!loading && (
            <Button
              className="cancel-modal"
              onClick={() => handleCancelAnnuity()}
            >
              Sim, tenho certeza
            </Button>
          )}
        </S.ModalBodyAnnuity>
      </Modal>
    );
  });

  return (
    <>
      {activeIdAnnuity && contentActiveAnnuity}
      {cancelIdAnnuity && contentCancelAnnuity}
    </>
  );
}

export default memo(ModalAnnuityAdhered);
