import styled, { css } from "styled-components";

export const InactiveStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF4D4F0D;
  border: 1px solid #FF4D4F;
  color: #FF4D4F;
  font-weight: 400;
  font-family: "Roboto";
  padding: 1px 8px;
  border-radius: 16px;
`;
