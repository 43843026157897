import React from 'react';

import { RiAlertLine } from 'react-icons/ri';

import * as Style from './styles';

function ContentModalLogs({ closeModal, handleSituacao }) {
  return (
    <Style.WrapperHeaderSituacao>
      <div className="modal-situacao">
        <div className="modal-situacao__alert">
          <RiAlertLine />
        </div>

        <div className="modal-situacao__text">
          <p>Cancelar fatura digital deste e-mail?</p>
          <span>Tem certeza?</span>

          <div className="btns">
            <button onClick={closeModal} type="button">
              Não, voltar
            </button>
            <button
              className="active-button"
              onClick={handleSituacao}
              type="button"
            >
              Sim, tenho certeza
            </button>
          </div>
        </div>
      </div>
    </Style.WrapperHeaderSituacao>
  );
}

export default ContentModalLogs;
