import React, { useCallback, useState } from 'react';

import { Modal } from 'antd';
import classnames from 'classnames';

import InstallmentInformation from '../components/InstallmentInformation';
import Footer from './Footer';
import StepOneList from './steps/StepOneList';
import styles from './style.module.scss';

function InvoiceInstallmentsModal({ visible, onCloseClick }) {
  const [selectedInstallmentOption, setSelectedInstallmentOption] = useState();
  const [step, setStep] = useState(0);

  const onInstallmentOptionClickHandler = useCallback((option) => {
    setSelectedInstallmentOption(option);
    setStep((prevState) => prevState + 1);
  }, []);

  const goBack = useCallback(() => {
    setSelectedInstallmentOption(null);
    setStep((prevState) => prevState - 1);
  }, []);

  return (
    <Modal
      centered
      width="80%"
      visible={visible}
      onCancel={onCloseClick}
      title="Parcelamento de fatura / saldo devedor"
    >
      <StepOneList
        setSelectedInstallmentOption={onInstallmentOptionClickHandler}
        className={classnames({
          [styles.hidden]: step !== 0,
        })}
      />

      <InstallmentInformation
        selectedInstallmentOption={selectedInstallmentOption}
        className={classnames({
          [styles.hidden]: step !== 1,
        })}
      />

      <Footer step={step} goBack={goBack} onCloseClick={onCloseClick} />
    </Modal>
  );
}

export default React.memo(InvoiceInstallmentsModal);
