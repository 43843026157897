import React, { memo } from 'react';

import { Modal } from 'antd';

import Card from '../../../components/Card';
import * as S from './styles';

function ModalAnnuityInformation({
  closeModal,
  open,
  selectedAnnuityInformation,
}) {
  return (
    <Modal visible={open} title="Informações" onCancel={closeModal} width="30%">
      <S.ModalContainer>
        <Card title="Detalhes da Anuidade">
          <p>
            <span>Faturamento Mínimo: </span>
            <b>{`R$ ${Number(selectedAnnuityInformation?.faturamentoMinimo)
              .toFixed(2)
              .replace('.', ',')}`}</b>
          </p>
          <p>
            <span>Parcelas Cancelamento: </span>
            <b>{selectedAnnuityInformation?.numParcelasCancelamento}</b>
          </p>
          <p>
            <span>Bonificada: </span>
            <b>{selectedAnnuityInformation?.isBonificada ? 'Sim' : 'Não'}</b>
          </p>
        </Card>
        <br />
      </S.ModalContainer>
    </Modal>
  );
}

export default memo(ModalAnnuityInformation);
