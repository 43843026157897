import React, { memo, useCallback } from 'react';

import { Menu } from 'antd';

import Can from '../../../../../../components/Can';
import { useClient } from '../../../../../../hooks/ClientProvider';
import useToggle from '../../../../../../hooks/useToggle';
import { vipagemService } from '../../../../../../services/vipagemService';
import {
  notifyError,
  notifySucces,
} from '../../../../../../utils/notifications';

const { Item } = Menu;

function ReissueCard({ card, key, fetchCardsInTransition }) {
  const [isLoading, toggleLoading] = useToggle();
  const { client } = useClient();

  const onReissueCardClick = useCallback(async () => {
    try {
      toggleLoading();

      await vipagemService.reemitirCartaoTransicao(
        client?.client?.id,
        card.id,
        card.numeroMascarado
      );

      notifySucces('Solicitação de reemissão enviada');

      await fetchCardsInTransition();
    } catch (e) {
      notifyError('Erro ao reemitir cartão');
    } finally {
      toggleLoading();
    }
  }, [card, client]);

  return (
    <Can permission="vipagem_reemitir_cartao">
      <Item key={key} onClick={onReissueCardClick} disabled={isLoading}>
        <span>Reemetir cartão</span>
      </Item>
    </Can>
  );
}

export default memo(ReissueCard);
