import React from 'react';

import Modal from 'react-modal';

import ContentModalActiveFaturaDigital from './ContentModalActiveFaturaDigital';

function ModalActiveFaturaDigital({ statusOpen, closeModal }) {
  return (
    <Modal
      isOpen={statusOpen}
      onRequestClose={closeModal}
      overlayClassName="react-modal-overlay-situacao"
      className="react-modal-situacao"
    >
      <ContentModalActiveFaturaDigital closeModal={closeModal} />
    </Modal>
  );
}

export default ModalActiveFaturaDigital;
