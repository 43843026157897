import React  from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import AntInput from '../../../components/AntInput';
import Loading from "../../../components/Loading";
import { getFormatDateAndTimeNoTz, getFormatDateReverse } from '../../../utils/formatDate';
import * as S from './style';

function ModalAntecipatePartial({
  open,
  handleAntecipatePartial,
  parcelNumber,
  handleChangeParcel,
  hasData,
  loading,
  dataAntecipate,
  paramsAntecipatePartial,
  closeModal
}) {

  return (
    <Modal width={500} title="Antecipação Parcial da Venda" visible={open} onCancel={closeModal}>

    { !dataAntecipate && (
      <><span>Quantidade de Parcelas: {paramsAntecipatePartial[1]}</span>
      <br /><br />
      <span>Digite o número da parcela para antecipação:</span>
        <S.ResultAntecipateRow>
          <AntInput
            value={parcelNumber}
            mask='999'
            style={{ marginTop: 8, marginBottom: 8 }}
            onChange={(e) => handleChangeParcel(e.target.value)}
            placeholder="Digite o número de parcelas" />
          <Button onClick={() => handleAntecipatePartial(parcelNumber, paramsAntecipatePartial[0])}> Antecipar</Button>
        </S.ResultAntecipateRow></>
    )}
    {loading && <Loading />}

    {hasData && dataAntecipate && (
       <><S.HeaderResultContainer>
          <CheckCircleOutlined className="anticon-success" />
            Antecipação Realizada com Sucesso!
         </S.HeaderResultContainer>
         <S.AntecipateModalContainer>
          <S.ResultAntecipateRow>
            <span>Id do Portador:</span>
            <span>{dataAntecipate?.idPortador}</span>
          </S.ResultAntecipateRow>
          <S.ResultAntecipateRow>
            <span>Data de Faturamento:</span>
            <span>{getFormatDateAndTimeNoTz(dataAntecipate?.dataFaturamento)}</span>
          </S.ResultAntecipateRow>
          <S.ResultAntecipateRow>
            <span>Id da Venda:</span>
            <span>{dataAntecipate?.idVenda}</span>
          </S.ResultAntecipateRow>
          <S.ResultAntecipateRow>
            <span>Número da Parcela:</span>
            <span>{dataAntecipate?.numeroParcela}</span>
          </S.ResultAntecipateRow>
          <S.ResultAntecipateRow>
            <span>Valor de Antecipação:</span>
            <span>R$ {dataAntecipate?.valorAntecipacao}</span>
          </S.ResultAntecipateRow>
          <S.ResultAntecipateRow>
            <span>Desconto de Antecipação:</span>
            <span>R$ {dataAntecipate?.descontoAntecipacao}</span>
          </S.ResultAntecipateRow>
          <S.ResultAntecipateRow>
            <span>Valor Final:</span>
            <span>R$ {dataAntecipate?.valorFinal}</span>
          </S.ResultAntecipateRow>
        </S.AntecipateModalContainer></>
    )}
    </Modal>
  );
}

export default (ModalAntecipatePartial);
