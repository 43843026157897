import React, { useEffect, useState } from "react";

import { Modal } from "antd";
import { format, parse } from "date-fns";
import { FaUsers, FaPlus, FaRegEye } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import { usePermissions } from "../../../Context/permissionContext";
import ModalAddDependent from "../../../pages/home/modals/ModalAddDependent";
import api from "../../../services/api";
import { capitalize } from "../../../utils/capitalize";
import { customerStatus } from "../../../utils/customerStatus";
import { notifyError } from "../../../utils/notifications";
import useCheckPermission from "../../../utils/useCheckPermission";
import LoadingElevaty from "../../LoadingElevaty";
import * as Style from "../style";

function TabDependente({ client, grauParentesco }) {
  const { permissions } = usePermissions();

  const [dependents, setDependents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);

  const [isOpenAddDependant, setOpenAddDependant] = useState(false);
  const hasPermissionView = useCheckPermission("view_personal_data");
  const addDependentPermission = useCheckPermission("permission_add_dependents");

  function handleModalAddDependent() {
    setOpenAddDependant(!isOpenAddDependant);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get("client_dependants", {
        client_id: client.client.id,
        filter: "all",
        identity: "COND",
      })
      .then((response) => {
        setDependents(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        notifyError("Erro ao listar dependentes", err.message);
      });
  }, [client]);

  return (
    <>
      {loading ? (
        <Style.WrapperNewLoading>
          <LoadingElevaty width="50px" height="50px" />
        </Style.WrapperNewLoading>
      ) : (
        <div className={`content-info ${client && dependents.length > 0 ? "userGrid" : ""}`}>
          {dependents && dependents.length > 0 ? (
            <>
              <div className="content-info-grid-users">
                {dependents?.slice(0, 2).map((dependent) => {
                  const formatedDate = format(
                    parse(dependent.dataNascimento, "yyyy-MM-dd", new Date()),
                    "dd/MM/yyyy"
                  );
                  return (
                    <div className="grid-user__item" key={dependent.id}>
                      {hasPermissionView && (
                        <div className="client-id">
                          <p>
                            #{dependent.id}{" "}
                            {dependent.idGrauParentesco &&
                              `- ${grauParentesco[dependent.idGrauParentesco]}`}
                          </p>
                        </div>
                      )}
                      <div className="client-name">
                        {dependent.nome && <p>{capitalize(dependent.nome)}</p>}

                        <span
                          className={customerStatus(
                            dependent.status
                          ).toLocaleLowerCase()}
                        >
                          {customerStatus(dependent.status)}
                        </span>
                      </div>
                      {hasPermissionView && (
                        <div
                          className={`client-more-infos ${showMoreItems ? "active" : ""
                        }`}
                        >
                          {dependent.cpf && (
                            <p>
                              <span>CPF:</span>{" "}
                              {dependent.cpf.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                "$1.$2.$3-$4"
                              )}
                            </p>
                          )}
                          {formatedDate && (
                            <p>
                              <span>Nasc.:</span> {formatedDate}
                            </p>
                          )}
                          {dependent.areaTelefone && dependent.numeroTelefone && (
                            <p>
                              <span>Tel.:</span>{" "}
                              {`(${dependent.areaTelefone}) ${dependent.numeroTelefone}`}{" "}
                            </p>
                          )}
                          {dependent.nomeMae && (
                            <p>
                              <span>Mãe:</span> {capitalize(dependent.nomeMae)}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                className="tab-right__item-right"
                style={{ marginTop: "20px" }}
              >
                <div
                  className={`client-edit-options  ${showMoreItems ? "active" : ""
                    }`}
                >
                  {dependents && dependents.length > 2 && (
                    <button
                      type="button"
                      aria-label="btn-email"
                      className="edit"
                      onClick={showModal}
                    >
                      <span>
                        <FaRegEye /> Ver todos ({dependents.length})
                      </span>
                    </button>
                  )}

                  {addDependentPermission && (
                    <button
                      type="button"
                      aria-label="btn-add-new"
                      onClick={handleModalAddDependent}
                    >
                      <span>
                        <FaPlus /> Adicionar
                      </span>
                    </button>
                  )}
                </div>
              </div>

              <div className="btn-show-more">
                <button
                  type="button"
                  aria-label="btn-show-more"
                  onClick={() => setShowMoreItems(!showMoreItems)}
                >
                  <p>
                    <MdOutlineKeyboardArrowDown
                      className={showMoreItems && "active"}
                    />{" "}
                    {showMoreItems ? "Mostrar menos" : "Mostrar mais"}
                  </p>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="no-users">
                <p>
                  <FaUsers /> Nenhum dependente cadastrado{" "}
                </p>

                {addDependentPermission && (
                  <button
                    type="button"
                    aria-label="btn-add-new"
                    onClick={handleModalAddDependent}
                  >
                    <span>
                      <FaPlus /> Adicionar dependente
                    </span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
      <ModalAddDependent
        open={isOpenAddDependant}
        closeModal={() => {
          setOpenAddDependant(false);
        }}
        client={client}
      />

      {dependents && dependents.length > 2 && (
        <Modal
          title="Dependentes"
          visible={isModalOpen}
          onCancel={handleCancel}
          width={1000}
        >
          <Style.ModalUsers>
            <div className="modal-scroll-user">
              {dependents?.map((dependent) => {
                const formatedDate = format(
                  parse(dependent.dataNascimento, "yyyy-MM-dd", new Date()),
                  "dd/MM/yyyy"
                );
                return (
                  <div className="content-user" key={dependent.id}>
                    {hasPermissionView && (
                      <div className="content-user__id">
                        <p>
                          #{dependent.id}{" "}
                          {dependent.idGrauParentesco &&
                            `- ${grauParentesco[dependent.idGrauParentesco]}`}
                        </p>
                      </div>
                    )}

                    <div className="content-user__name">
                      {dependent.nome && <p>{capitalize(dependent.nome)}</p>}

                      <span
                        className={customerStatus(
                          dependent.status
                        ).toLocaleLowerCase()}
                      >
                        {customerStatus(dependent.status)}
                      </span>
                    </div>
                    {hasPermissionView && (
                      <div className="content-user__infos">
                        {dependent.cpf && (
                          <p>
                            <span>CPF:</span>{" "}
                            {dependent.cpf.replace(
                              /(\d{3})(\d{3})(\d{3})(\d{2})/,
                              "$1.$2.$3-$4"
                            )}
                          </p>
                        )}
                        {formatedDate && (
                          <p>
                            <span>Nasc.:</span> {formatedDate}
                          </p>
                        )}
                        {dependent.areaTelefone && dependent.numeroTelefone && (
                          <p>
                            <span>Tel.:</span>{" "}
                            {`(${dependent.areaTelefone}) ${dependent.numeroTelefone}`}{" "}
                          </p>
                        )}
                        {dependent.nomeMae && (
                          <p>
                            <span>Mãe:</span> {capitalize(dependent.nomeMae)}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Style.ModalUsers>
        </Modal>
      )}
    </>
  );
}

export default TabDependente;
