import React, { useCallback, useState } from "react";

import { HomeFilled } from "@ant-design/icons";
import {
  Button,
  Alert,
  Table,
} from "antd";

import AntInput from "../../components/AntInput";
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import { usePermissions } from "../../Context/permissionContext";
import api from "../../services/api";
import { notifyError } from "../../utils/notifications";
import ModalSendArqLote from "./ModalSendTxt"
import * as S from "./style";

function ListAlterLimits() {
  const { permissions } = usePermissions();
  const [isVisibleModalSendTxt, setVisibleModalSendTxt] =
  useState(false);

  const [formSteps, setFormSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [numberLote, setNumberLote] = useState('');


  const handleModalSendTxt = useCallback(() => {
    setVisibleModalSendTxt(true);
  }, []);

  const closeVisibleModalSendTxt = useCallback(() => {
    setVisibleModalSendTxt(false);
  }, []);

  const handleChangeSubmit = useCallback(async (value) => {
    await setNumberLote(value);
  }, [numberLote]);

  const handleSearchBatch = useCallback(async () => {
    try {
      setLoading(true);
      if(!numberLote || numberLote <= 0) throw new Error('Insira um ID de lote válido.')
      const {data} = await api.get('view_alter_limit_batch',{
        codigoLote: numberLote,
      });
      await setSearchData(data.inconsistencias);
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  }, [numberLote]);

  const columns = [
    {
      title: "identificador do Portador",
      dataIndex: "idPortador",
    },
    {
      title: "Limite Aplicado",
      dataIndex: "limiteAplicado",
    },
    {
      title: "Limite de proximo período",
      dataIndex: "limiteProxPeriodo",
    },
    {
      title: "Limite de Parcelamento",
      dataIndex: "limiteParcelamento",
    },
    {
      title: "Observação",
      dataIndex: "mensagem",
    },
  ];


  return (
    <>
      <S.Container>
        <S.PageInfoContainer>
          <S.PageContent style={{ marginTop: "3%" }}>
            <HomeFilled /> / <b>Listagem de processamento do lote de alteração de limite</b>
          </S.PageContent>
          <S.UserContainer>
            <div className="select-btn" >
              <Button
               style={{ marginLeft: 22, marginBottom:15 }}
               disabled={!permissions.includes('post_alter_limit_lote')}
               onClick={() => handleModalSendTxt()} type="ghost">
                Criar alteração de limite em lote
              </Button>
            </div>

            <Card title="Listagem de processamento do lote de alteração de limite" loading={loading}>
              {formSteps === 0 && (
                <div className="flex-container">
                  <AntInput
                    className="input-number"
                    type="number"
                    value={numberLote}
                    placeholder="556"
                    onChange={async (e) => handleChangeSubmit(e.target.value)}
                  />
                    <Button onClick={() => handleSearchBatch()} type="ghost">
                      Pesquisar
                    </Button>
                </div>
              )}

              <div className="select-table">
                {loadingTable && <Loading />}
                {searchData && searchData.length > 0 && (
                <S.TableContainer>
                 <Table
                  columns={columns}
                  dataSource={searchData}
                  pagination={{ pageSize: 5 }} />
                </S.TableContainer>
                )}
                {searchData === undefined || searchData.length === 0 && (
                  <div className="alert-table">
                    <Alert
                      message="Não há dados para as datas selecionadas"
                      type="warning"
                      showIcon
                    />
                  </div>
                )}
              </div>
            </Card>
          </S.UserContainer>
        </S.PageInfoContainer>
      </S.Container>
    <ModalSendArqLote
      isModalVisible={isVisibleModalSendTxt}
      closeModal={closeVisibleModalSendTxt}
      titleModal="alterar limite em lote"
      endpoint="post_alter_limit_batch"
    />
    </>
  );
}
export default React.memo(ListAlterLimits);
