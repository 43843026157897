import React, {  useCallback, useState } from "react";

import { Table, Button, Popover } from "antd";
import { format } from "date-fns";

import { usePermissions } from "../../../Context/permissionContext";
import api from "../../../services/api";
import { notifyError, notifySucces } from "../../../utils/notifications";
import  ModalAntecipatePartial from "../../home/ModalSaleAntecipate/ModalAntecipatePartial";
import ModalAntecipateTotal from "../../home/ModalSaleAntecipate/ModalAntecipateTotal";
import * as S from "../style";

function UsersTable({ data, lojas, clientId, typeCard, handleOpenModal }) {
  const { permissions } = usePermissions();
  const [isModalAntecipatePartial, setIsModalAntecipatePartial] = useState(false);
  const [isModalAntecipateTotal, setIsModalAntecipateTotal] = useState(false);
  const [paramsAntecipateTotal, setParamsAntecipateTotal] = useState();

  const [paramsAntecipatePartial, setParamsAntecipatePartial] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [parcelNumber, setParcelNumber] = useState('');
  const [dataAntecipate, setDataAntecipate] = useState(null);

  const handleChangeParcel = useCallback(
    async (value) => {
    await setParcelNumber(value.replace(/\D/g, ''));
   }, [] );

  const handleAntecipatePartial = useCallback(
    async (numeroParcela, idVenda) => {
      setLoading(true);
      const numberReplace = numeroParcela.replace(/\D/g, '');
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(numberReplace))) {
        throw new Error('Insira um número de parcelas válido.');
      }
      api.post('sales_antecipate_partial', {
        idPortador: Number(clientId),
        idVenda: Number(idVenda),
        numeroParcela: Number(numberReplace),
      }).
      then(async res => {
        await setDataAntecipate(res.data);
        await notifySucces('Antecipação parcial de venda realizada com sucesso');
        await setLoading(false);
        await setHasData(true);

        }).
      catch(error => {
        setLoading(false);
        notifyError(error.message);
        setIsModalAntecipatePartial(false);
      });
    }, [] );


  const antecipateSalePartial = useCallback(
    async (idVenda, quantidadeParcelas) => {
      try {
        await setLoading(false);
        await setHasData(false);
        await setDataAntecipate(null);
        await setParcelNumber('');
        await setParamsAntecipatePartial([idVenda, quantidadeParcelas]);
        await setIsModalAntecipatePartial(true);
      } catch (error) {
        console.error(error.message);
      }
    },
    []
  );

  const antecipateSaleTotal = useCallback(
    (idCompra) => {
      setLoading(true);
      api.post('sales_antecipate_total', {
      idPortador: Number(clientId),
      idVenda: Number(idCompra)
      })
    .then(async res => {
      await setParamsAntecipateTotal(res.data);
      notifySucces('Antecipação Realizada com Sucesso!');
      await setIsModalAntecipateTotal(true);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
      notifyError(error.message);
      });
    }, []);

  function formatDate(valueDate) {
    const date = new Date(valueDate);
    date.setDate(date.getDate());

    return date;
  }

  const columns = [
    {
      title: "id Portador",
      dataIndex: "idPortador",
    },
    {
      title: "Nome do Portador",
      dataIndex: "nomePortador",
    },
    {
      title: "Cartão",
      dataIndex: "cartaoTruncado",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          {status === 'A' && <S.StatusActive>Ativa</S.StatusActive>}
          {status === 'N' && <S.StatusActive>Normal</S.StatusActive>}
          {status === 'P' && <S.StatusActive>Processado</S.StatusActive>}
          {status === 'C' && <S.StatusInactive>Cancelado</S.StatusInactive>}
          {status === 'W' && <S.StatusInactive>Pendente</S.StatusInactive>}
          {status === 'D' && <S.StatusInactive>Desfeito</S.StatusInactive>}
          {status === 'I' && <S.StatusInactive>Invertido/Revertido</S.StatusInactive>}
          {status === 'O' && <S.StatusInactive>Outros</S.StatusInactive>}
        </>
      ),
    },
    {
      title: "NSU",
      dataIndex: "nsuCadastro",
      render: (nsuCadastro) => (
        <>
          {nsuCadastro}
        </>
      ),
    },
    {
      title: "Nº Autorização",
      dataIndex: "numeroAutorizacao",
      render: (numeroAutorizacao) => (
        <>
          {numeroAutorizacao}
        </>
      ),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      render: (valor) => (
        <>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valor)}
        </>
      ),
    },
    {
      title: "Data Transação",
      dataIndex: "dataTransacao",
      render: (dataTransacao) => (
        <>{dataTransacao && format(formatDate(dataTransacao), "dd/MM/yyyy")}</>
      ),
    },
    {
      title: "Data Venda",
      dataIndex: "dataVenda",
      render: (dataVenda) => (
        <>{dataVenda && format(formatDate(dataVenda), "dd/MM/yyyy")}</>
      ),
    },

    {
      title: "Cancelar Venda",
      dataIndex: "numeroAutorizacao",
      align: "center",
      render: (numeroAutorizacao) => (
        <>
          {permissions.includes("make_sales_cancel") && (
            <button
              type="button"
              className="btn-cancel"
              onClick={() => handleOpenModal(numeroAutorizacao)}
            >
              Cancelar Venda
            </button>
          )}
        </>
      ),
    },
    {
      title: "Antecipar Venda",
      dataIndex: "idCompra",
      align: "center",
      render: (idCompra, items) => (
        <>
            <Popover
              content={
                <div>
                  <>
                    <Button
                      style={{ marginRight: "5px" }}
                      onClick={() => antecipateSalePartial(idCompra, items.quantidadeParcelas)}
                    >
                      PARCIAL
                    </Button>

                    <Button onClick={() => antecipateSaleTotal(idCompra)}>
                      TOTAL
                    </Button>
                  </>
                </div>
              }
              title="Antecipacao de Venda"
              trigger="click"
              placement="topRight"
            >
                <button type="button" className="btn-warning" title="Antecipacao de Venda" disabled={!permissions.includes("sales_antecipation")} >
                  Antecipar Venda
                </button>
            </Popover>
        </>
      ),
    },
  ];

  const columnsBandeirado = [
    {
      title: "id Portador",
      dataIndex: "idPortador",
    },
    {
      title: "Nome do Portador",
      dataIndex: "nomePortador",
    },
    {
      title: "Cartão",
      dataIndex: "cartaoTruncado",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
           <>
          {status === 'A' && <S.StatusActive>Ativa</S.StatusActive>}
          {status === 'N' && <S.StatusActive>Normal</S.StatusActive>}
          {status === 'P' && <S.StatusActive>Processado</S.StatusActive>}
          {status === 'C' && <S.StatusInactive>Cancelado</S.StatusInactive>}
          {status === 'W' && <S.StatusInactive>Pendente</S.StatusInactive>}
          {status === 'D' && <S.StatusInactive>Desfeito</S.StatusInactive>}
          {status === 'I' && <S.StatusInactive>Invertido/Revertido</S.StatusInactive>}
          {status === 'O' && <S.StatusInactive>Outros</S.StatusInactive>}
        </>
        </>
      ),
    },
    {
      title: "NSU",
      dataIndex: "nsuCadastro",
      render: (nsuCadastro) => (
        <>
          {nsuCadastro}
        </>
      ),
    },
    {
      title: "numeroAutorizacao",
      dataIndex: "numeroAutorizacao",
      render: (numeroAutorizacao) => (
        <>
          {numeroAutorizacao}
        </>
      ),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      render: (valor) => (
        <>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valor)}
        </>
      ),
    },
    {
      title: "Data Transação",
      dataIndex: "dataTransacao",
      render: (dataTransacao) => (
        <>{dataTransacao && format(formatDate(dataTransacao), "dd/MM/yyyy")}</>
      ),
    },
    {
      title: "Data Venda",
      dataIndex: "dataVenda",
      render: (dataVenda) => (
        <>{dataVenda && format(formatDate(dataVenda), "dd/MM/yyyy")}</>
      ),
    },
    {
      title: "Cancelar Venda",
      dataIndex: "numeroAutorizacao",
      align: "center",
      render: (numeroAutorizacao) => (
        <>
          {permissions.includes("make_sales_cancel") && (
            <button
              type="button"
              className="btn-cancel"
              onClick={() => handleOpenModal(numeroAutorizacao)}
            >
              Cancelar Venda
            </button>
          )}
        </>
      ),
    },
    {
      title: "Antecipar Venda",
      dataIndex: "idCompra",
      align: "center",
      render: (idCompra, items) => (
        <>
            <Popover
              content={
                <div>
                  <>
                    <Button
                      style={{ marginRight: "5px" }}
                      onClick={() => antecipateSalePartial(idCompra, items.quantidadeParcelas)}
                    >
                      PARCIAL
                    </Button>

                    <Button onClick={() => antecipateSaleTotal(idCompra)}>
                      TOTAL
                    </Button>
                  </>
                </div>
              }
              title="Antecipacao de Venda"
              trigger="click"
              placement="topRight"
            >
                <button type="button" className="btn-warning" title="Antecipacao de Venda" disabled={!permissions.includes("sales_antecipation")} >
                  Antecipar Venda
                </button>
            </Popover>
        </>
      ),
    },

  ];

  let updatedColumns;
  if (typeCard.value === 'nao-bandeirado' ) {
      updatedColumns = permissions.includes("sales_antecipation") ? columns : columns.filter(col => col.title !== "Antecipar Venda");
  } else {
      updatedColumns = permissions.includes("sales_antecipation") ? columnsBandeirado : columnsBandeirado.filter(col => col.title !== "Antecipar Venda");
  }

  return (
    <>
    <S.TableContainer>
      <Table
        className="tableListsadjustment not-global"
        columns={updatedColumns}
        dataSource={data?.content}
        pagination={{ pageSize: 5 }} />
    </S.TableContainer>


    <ModalAntecipatePartial
      open={isModalAntecipatePartial}
      handleAntecipatePartial={handleAntecipatePartial}
      parcelNumber={parcelNumber}
      paramsAntecipatePartial={paramsAntecipatePartial}
      handleChangeParcel={handleChangeParcel}
      hasData={hasData}
      loading={loading}
      dataAntecipate={dataAntecipate}
      closeModal={() => {
        setIsModalAntecipatePartial(false);
      }}
    />
    <ModalAntecipateTotal
      open={isModalAntecipateTotal}
      paramsAntecipateTotal={paramsAntecipateTotal}
      closeModal={() => {
        setIsModalAntecipateTotal(false);
      }}
    />
    </>
  );
}

export default React.memo(UsersTable);
