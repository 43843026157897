import styled, { css } from "styled-components";

export const Content = styled.div`
  width: 100%;

  border-radius: 4px;
  border: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);
  background: var(--Neutral-Color-Gray-1, #fff);

  .content-users__title {
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-users__title--left {

      h2 {
        padding: 0;
        margin: 0;
        color: var(--Neutral-Color-Dark-10, #272d3b);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .content-users__title--right {
      display:flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 16px;
    }
  }

  @media(max-width:689px) {
    .content-users__title {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 8px;
    }

    .content-users__title--right {
      flex-wrap: wrap;
      button {
        margin-block: 5px;
        width: auto;
      }
    }

    .btns-acoes {
      width: auto !important;
    }

    .rwd-table tr td[data-th="Ações"] {
      display: flex;
      align-items: center;
      justify-content: start;
      }

    .rwd-table th:first-child {
      margin:top: 6px;
      padding-left: 0px;
    }

      .rwd-table td:first-child {
        background: #f5f5f5;
        border-radius: 2.5px;
        padding-left: 0;
      }
  }

  .content-users__content {
    width: 100%;
    padding: 16px;

    .rwd-table {
      width: 100%;
      background: #fff;
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      border-collapse: separate !important;
      border-spacing: 0;
      table-layout: fixed;

      border-radius: 4px;
      border: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);

      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

       .action-header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btns-acoes {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;

        .btn-div {
          padding: 8px;
          border-radius: 2px;
          border: 1px solid var(--Neutral-5, #d9d9d9);
          background: var(--Neutral-1, #fff);
          /* drop-shadow/button-secondary */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
        }
      }

      p {
        &.status {
          border-radius: 16px;
          border: 1px solid var(--Status-Color-Success, #52c41a);
          background: rgba(82, 196, 26, 0.05);
          padding: 1px 8px;
          color: var(--Status-Color-Success, #52c41a);
          /* Footnote 12/description */
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: inline-flex;
          margin: 0;

          &.off {
            border: 1px solid var(--Status-Color-Error, #ff4d4f);
            background: rgba(255, 77, 79, 0.05);
            color: var(--Status-Color-Error, #ff4d4f);
          }
        }
      }

      & td {
        /* border-left: 1px solid #ccc; */
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        text-align: left;
      }

      & tr {
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;

        &:first-of-type {
          background: #f5f5f5;
        }

        &:last-of-type {
          & td {
            /* border-left: 1px solid #ccc; */
            border-bottom: 1px solid transparent !important;
            padding: 10px;
            text-align: left;
          }
        }
      }
      & th {
        display: none;
      }

      & td {
        display: block;

        &:first-child {
          padding-top: 0.5em;
        }

        &:last-child {
          padding-bottom: 0.5em;
        }

        &:before {
          content: attr(data-th) ": ";
          font-weight: bold;
          width: 6.5em;
          display: inline-block;
        }
      }

      & th,
      & td {
        text-align: left;
        margin: 0.5em 1em;
        word-wrap: break-word;
      }

      & th,
      & td:before {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      }
    }

    @media (min-width: 690px) {
      .rwd-table td:before {
        display: none;
      }

      .rwd-table th,
      .rwd-table td {
        display: table-cell;
        padding: 1em !important;
      }
      .rwd-table th:first-child,
      .rwd-table td:first-child {
        padding-left: 0;
      }
      .rwd-table th:last-child,
      .rwd-table td:last-child {
        padding-right: 0;
      }
    }

    .content-users__content--loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 16px;
      flex-wrap: wrap;

      .content-logo,
      .content-text-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }
    }
  }

  .pagination-users {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: end;
    gap: 8px;

    span {
      display: flex;
      align-items: center;
    }

    button {
      width: 32px;
      height: 32px;
      padding: 10px;
      border-radius: 2px;
      border: 1px solid var(--Neutral-Color-Gray-5, #d9d9d9);
      background: var(--Neutral-Color-Gray-1, #fff);
      color: var(--Neutral-Color-Dark-10, #272d3b);
      font-size: 14px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        color: #d9d9d9;
        cursor: not-allowed;
      }

      &.active {
        border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
        color: #ffb32c;
      }
    }
  }

  @media (max-width: 768px) {
      .content-users__title--right {
        justify-content: center !important;
    }

  @media (min-width: 768px) {
    .content-users__title {
      display: flex;
      align-items: center;
      .content-users__title--left {
        width: calc(100% - 454px);
        margin-bottom: 0;
      }
      .content-users__title--right {
        width: 454px;
        margin-bottom: 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        input {
          margin-bottom: 0;
          width: 271px;
          margin-right: 16px;
        }

        button {
          display: flex;
          width: 167px;
        }
      }
    }
  }
`;

export const addDataButton = styled.button`
  border-radius: 2px;
  border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
  background: var(--Brand-Color-Gold-6, #ffb32c);
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  color: var(--Neutral-Color-Gray-9, #434343);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: ease 0.5s linear;

  &:hover {
    box-shadow: 0px 2px 8px #ffb32c;
  }
}`

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
`;

export const BreadCrumbPage = styled.div`
  width: 100%;
  margin-bottom: 16px;

  p {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    span {
      font-weight: bold;
    }
  }
`;
