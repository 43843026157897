import React, { memo, useCallback, useEffect, useState } from "react";

import { format } from 'date-fns';
import {
  MdOutlineSecurity,
  MdAttachMoney,
  MdMessage,
  MdKeyboardArrowRight,
} from "react-icons/md";

import DataTable from "../../../components/DataTable";
import QuickAccessCardComponent from "../../../components/QuickAccessCard";
import { useAuth } from "../../../hooks/AuthProvider";
import { useClient } from "../../../hooks/ClientProvider";
import useInsurance from "../../../hooks/useInsurance";
import usePremiumAnnuity from "../../../hooks/usePremiumAnnuity";
import useSMS from "../../../hooks/useSMS";
import api from "../../../services/api";
import { getPlaces } from "../../../utils/getStores";
import { notifyError } from "../../../utils/notifications";
import useCheckPermission from "../../../utils/useCheckPermission";
import ModalListAnnuities from "../../home/modals/ModalListAnnuities";
import * as Style from "../style";
import ModalAcessAnnuity from "./ModalAcessAnnuity";
import ModalAcessInsurances from "./ModalAcessInsurances";
import ModalViewInsurances from "./ModalAcessInsurances/ModalViewInsurances";
import ModalAcessSMS from "./ModalAcessSMS";

function QuickAcessCardsComponent() {
  const { client } = useClient();
  const hasPermissionViewSMSPackage = useCheckPermission("view_sms_package");
  const {
    ref: refSMS,
    pacoteSMS,
    reload: reloadPacoteSMS,
    columnPacoteSMS,
    listAllSMS,
    listContractedSMS,
    openSMSModal,
    setOpenSMSModal,
    activePacote,
    openContractModal,
    isLoading: isLoadingSMS,
    handleCloseContractModal,
    openCancelContractModal,
    handleCloseCancelContractModal,
  } = useSMS(client.client.id);

  const {
    ref,
    isLoadingAnnuity,
    premiumAnnuity,
    allBonnusAnnuity,
    reload: reloadPremiumAnnuity,
    openModalAnnuityInformation,
    setOpenModalAnnuityInformation,
    selectedAnnuityInformation,
    handleOpenModalAnnuityInformation,
  } = usePremiumAnnuity();

  const {
    refInsurance,
    loadingInsurance,
    insurances,
    reload: reloadInsurances,
  } = useInsurance(client?.client?.id);

  const [openModal, setOpenModal] = useState(false);
  const [openModalInsurances, setOpenModalInsurances] = useState(false);
  const [modalViewInsurancesIsOpen, setModalViewInsurancesIsOpen] = useState(false);
  const [openModalAnnuity, setOpenModalAnnuity] = useState(false);
  const [openModalSms, setOpenModalSms] = useState(false);
  const [openModalSMSList, setOpenModalSMSList] = useState(false);
  const [listAnnuities, setListAnnuities] = useState([]);
  const [storeAux, setStoreAux] = useState([]);
  const hasPermission = useCheckPermission('view_contracted_insurances');
  const { user } = useAuth();

  const handleInsuranceClick = () => {
    if (hasPermission) {
      setModalViewInsurancesIsOpen(true);
    } else {
      notifyError("Você não tem permissão para visualizar seguros contratados.");
    }
  };

  const handleDependents = useCallback(async () => {
    try {
      const rawDependents = await api.get('client_dependants', {
        client_id: client?.client?.id,
        filter: 'null' || 'ativos',
      });
      const dependentsFormatted = rawDependents.data.map((dependent) => {
        dependent.dataNascimento = format(
          new Date(dependent.dataNascimento),
          'dd/MM/yyyy'
        );
        return dependent;
      });
      if (dependentsFormatted.length === 0) {
        return [];
      }
      return dependentsFormatted.filter(item => item.status !== "C");

    } catch (error) {
      return [];
    }
  }, [api, client?.client?.id]);

  const handleOpenModal = useCallback(async () => {
    setOpenModal(true);
    setListAnnuities(allBonnusAnnuity);
  });

  const handleOpenModalInsurances = useCallback(() => {
    setOpenModalInsurances(true);
  }, []);

  const openNewInsuranceModal = () => {
    setOpenModalInsurances(true);
    setModalViewInsurancesIsOpen(false);
  };

  const handleModalViewInsurances = () => {
    setModalViewInsurancesIsOpen((state) => !state);
  }

  useEffect(() => {
    async function fetchPlaces() {
      const places = await getPlaces(user);
      setStoreAux(places);
    }

    fetchPlaces();
  }, [user]);


  return (
    <Style.QuickAccessCard>
      <div ref={refInsurance}>
        <QuickAccessCardComponent
          loading={loadingInsurance}
          icon={<MdOutlineSecurity />}
          title="Seguros"
          contentLeft={
            <>
              {insurances && insurances.length > 0 ? (
                <>
                  <p>Contratados:</p>
                  <p>
                    <strong onClick={handleInsuranceClick}>
                      {insurances.length} Seguro{insurances.length > 1 ? "s" : ""}
                    </strong>
                  </p>
                </>
              ) : (
                <p>Nenhum seguro contratado</p>
              )}
            </>
          }
          permissionButton="insurance_Contract"
          onClickButton={handleOpenModalInsurances}
        />
      </div>

      <div ref={ref}>
        <QuickAccessCardComponent
          loading={isLoadingAnnuity}
          icon={<MdAttachMoney />}
          title="Anuidade"
          contentLeft={
            <>
              {premiumAnnuity && premiumAnnuity.length > 0 ? (
                <>
                  <p>Contratados:</p>
                  <p>
                    <strong onClick={() => setOpenModalAnnuity(true)}>
                      {premiumAnnuity ? premiumAnnuity.length : 0} Serviço
                      {premiumAnnuity && premiumAnnuity.length > 1 ? "s" : ""}
                      <MdKeyboardArrowRight />
                    </strong>
                  </p>
                </>
              ) : (
                <p>Nenhuma anuidade contratada</p>
              )}
            </>
          }
          permissionButton="join_premium_annuity"
          onClickButton={handleOpenModal}
        />
      </div>

      {hasPermissionViewSMSPackage ? (
        <div ref={refSMS}>
          <QuickAccessCardComponent
            loading={isLoadingSMS}
            icon={<MdMessage />}
            title="Pacote de SMS"
            contentLeft={
              <>
                {listContractedSMS && listContractedSMS.length > 0 ? (
                  <>
                    <p>Contratados:</p>
                    <p>
                      <strong onClick={() => setOpenModalSMSList(true)}>
                        {listContractedSMS ? listContractedSMS.length : 0}{" "}
                        Serviço
                        {listContractedSMS && listContractedSMS.length > 1
                          ? "s"
                          : ""}
                        <MdKeyboardArrowRight />
                      </strong>
                    </p>
                  </>
                ) : (
                  <p>Nenhum Pacote de SMS contratado</p>
                )}
              </>
            }
            permissionButton="join_premium_annuity"
            onClickButton={() => setOpenModalSms(true)}
            reloadPacoteSMS={reloadPacoteSMS}
          />
        </div>
      ) : null}

      <ModalAcessInsurances
        open={openModalInsurances}
        closeModal={() => setOpenModalInsurances(false)}
        clientId={client?.client?.id}
        clientInsurances={insurances}
        client={client}
        setDependents={handleDependents}
        lojas={storeAux}
        reloadClientInsurances={reloadInsurances}
      />
      <ModalViewInsurances
        open={modalViewInsurancesIsOpen}
        closeModal={handleModalViewInsurances}
        insurance={insurances}
        clientId={client?.client?.id}
        clientInsurances={insurances}
        reloadClientInsurances={reloadInsurances}
        client={client}
        setDependents={handleDependents}
        lojas={storeAux}
        openNewInsuranceModal={openNewInsuranceModal}
      />
      <ModalAcessAnnuity
        openModal={openModalAnnuity}
        titleModal="Anuidade contratada"
        onClose={() => setOpenModalAnnuity(false)}
        contentData={premiumAnnuity}
        handleOpenModalAnnuityInformation={handleOpenModalAnnuityInformation}
        openModalAnnuityInformation={openModalAnnuityInformation}
        setOpenModalAnnuityInformation={setOpenModalAnnuityInformation}
        reloadPremiumAnnuity={reloadPremiumAnnuity}
        selectedAnnuityInformation={selectedAnnuityInformation}
      />

      {/* Contratar Novo Pacote SMS */}
      <ModalAcessSMS
        openModal={openModalSms}
        openDetailsModal={openSMSModal}
        setDetailsModalOpen={setOpenSMSModal}
        titleModal="Pacote de SMS"
        onClose={() => setOpenModalSms(false)}
        pacoteSMS={listAllSMS}
        reloadPacoteSMS={reloadPacoteSMS}
      />

      {/* Pacotes SMS Contratados */}
      <ModalAcessSMS
        openModal={openModalSMSList}
        openDetailsModal={openSMSModal}
        setDetailsModalOpen={setOpenSMSModal}
        titleModal="Pacote de SMS"
        onClose={() => setOpenModalSMSList(false)}
        pacoteSMS={listContractedSMS}
        reloadPacoteSMS={reloadPacoteSMS}
      />

      {/* Modal de Anuidades */}
      <ModalListAnnuities
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        reloadPremiumAnnuity={reloadPremiumAnnuity}
        listAnnuities={listAnnuities}
      />
    </Style.QuickAccessCard>
  );
}

export default memo(QuickAcessCardsComponent);
