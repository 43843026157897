import React, { useCallback, useMemo } from 'react';

import { Button } from 'antd';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { utils, writeFile } from 'xlsx';

import { TRANSLATE_PRIORITY } from '../../../components/Priority';
import { TRANSLATE_STATUS } from '../../../components/Status';
import {
  formatCPF,
  formatDate,
  formatDateTime,
} from '../../../utils/formatter';

const headers = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Protocolo',
    key: 'ticket_protocol',
  },
  {
    label: 'Usuário solicitante',
    key: 'user',
  },
  {
    label: 'Usuário atendente',
    key: 'attendant',
  },
  {
    label: 'CPF do cliente',
    key: 'client_cpf',
  },
  {
    label: 'Nome do cliente',
    key: 'client_name',
  },
  {
    label: 'Aniversário do cliente',
    key: 'client_birthday',
  },
  {
    label: 'Data/Hora de criação',
    key: 'created_at',
  },
  {
    label: 'Prioridade',
    key: 'priority',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Tipo',
    key: 'type.name',
  },
  {
    label: 'Obs_pendente',
    key: 'obs_pendente',
  },
  {
    label: 'Obs_finalizado',
    key: 'obs_finalizado',
  },
  {
    label: 'Obs_cancelamento',
    key: 'obs_cancelamento',
  },
];

function ExportToFileButton({ data }) {
  const dataNormExcel = useMemo(
    () =>
      data?.map((row) => ({
        ID: row.id,
        Protocolo: row.ticket_protocol,
        'Usuário solicitante': row.user,
        'Usuário atendente': row.attendant,
        'CPF do cliente': formatCPF(row.client_cpf),
        'Nome do cliente': row.client_name,
        'Aniversário do cliente': formatDate(row.client_birthday),
        'Data/Hora de criação': formatDateTime(row?.statuses[0]?.created_at),
        Prioridade: TRANSLATE_PRIORITY[row.priority].label,
        Status: TRANSLATE_STATUS[row.status].label,
        Tipo: row.type.name,
        'Obs_pendente': row?.statuses?.filter(row => row.status === "PENDING").map(status => status.description)[0],
        'Obs_finalizado': row?.statuses?.filter(row => row.status === "RESOLVED").map(status => status.description)[0],
        'Obs_cancelamento': row?.statuses?.filter(row => row.status === "CANCELLED").map(status => status.description)[0],
        
      })),
    [data]
  );

  const dataNormCsv = useMemo(
    () =>
      data?.map((row) => ({
        ...row,
        client_birthday: formatDate(row.client_birthday),
        created_at: formatDateTime(row?.statuses[0]?.created_at),
        priority: TRANSLATE_PRIORITY[row.priority].label,
        status: TRANSLATE_STATUS[row.status].label,
        client_cpf: formatCPF(row.client_cpf),
        obs_pendente: row?.statuses?.filter(row => row.status === "PENDING").map(status => status.description),
        obs_finalizado: row?.statuses?.filter(row => row.status === "RESOLVED").map(status => status.description),
        obs_cancelamento: row?.statuses?.filter(row => row.status === "CANCELLED").map(status => status.description),
      })),
    [data]
  );

  const downloadExcel = useCallback(() => {
    const ws = utils.json_to_sheet(dataNormExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'sheet_1');
    const columnsSize = Object.keys(dataNormExcel[0]);
    ws['!cols'] = columnsSize.map(() => ({ wch: 25 }));

    writeFile(wb, `solicitações-${moment().format('DD-MM-YYYY-HH-mm')}.xlsx`);
  }, [dataNormExcel]);

  if (!data) {
    return null;
  } 
  
  return (
    <div>
      <Button onClick={downloadExcel}>Exportar para Excel</Button>
      <Button style={{ marginLeft: '15px' }}>
        <CSVLink
          filename={`solicitações-${moment().format('DD-MM-YYYY-HH-mm')}`}
          data={dataNormCsv}
          headers={headers}
        >
          Exportar para CSV
        </CSVLink>
      </Button>
    </div>
  );
}

export default React.memo(ExportToFileButton);
