import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Modal,
  Select,
  DatePicker,
  Alert,
  ConfigProvider
} from "antd";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import BreadCrumbNavigation from "../../components/DataTable/TopSection/BreadCrumbNavigation";
import LoadingElevaty from '../../components/LoadingElevaty';
import { useAuth } from "../../hooks/AuthProvider";
import api from "../../services/api";
import { getPlaces } from '../../utils/getStores';
import { notifyError, notifySucces } from "../../utils/notifications";
import { validaCpf } from "../../utils/validations";
import PayCancelModal from "../solicitation/SellandPayModal/payCancelModal";
import * as S from "./style";
import UsersTable from "./UsersTable";

const { Option } = Select;

function NewTable() {

  const [data, setData] = useState([]);
  const [lojas, setLojas] = useState([]);
  const [lastYear, setLastYear] = useState(() => {
    const lastYearDate = new Date();
    lastYearDate.setDate(lastYearDate.getDate() - 10);
    return lastYearDate;
  });
  const [today, setToday] = useState(() => {
    const todayDate = new Date();
    return todayDate;
  });

  const [formSteps, setFormSteps] = useState(0);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [idClient, setIdClient] = useState(0);
  const [nameClient, setNameClient] = useState("");
  const [clientProducts, setClientProducts] = useState([]);

  const [listClientAccount, setListClientAccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [nameStore, setNameStore] = useState("");
  const [listSelected, setListSelected] = useState([]);
  const [modalPayStoresOpen, setmodalPayStoresOpen] = useState(false);

  const [idStore, setIdStore] = useState();
  const [dateInit, setDateInit] = useState();
  const [dateFim, setDateFim] = useState();

  const [infosModal, setInfosModal] = useState();

  const { user } = useAuth();

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);


  const onChangeLastYearRangeDate = useCallback((date, setFieldValue) => {
    setFieldValue("lastYear", moment(date).format("YYYY-MM-DD"));
    setLastYear(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
    setListSelected([]);
  }, []);

  const onChangeTodayRangeDate = useCallback((date, setFieldValue) => {
    setFieldValue("today", moment(date).format("YYYY-MM-DD"));
    setToday(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
    setListSelected([]);
  }, []);


  const handleAccountSelected = useCallback(async (value) => {
    await setIdClient(value);
    const selectedClient = await listClientAccount.filter(client => client.id === value)[0];

    if (selectedClient) {
      await setClientProducts([selectedClient]);
      await setNameClient(selectedClient.name);
    } else {
      console.error(`Cliente com ID ${value} não encontrado.`);
    }
  }, [clientProducts, nameClient]);




  const handleStepOne = async (values) => {
    try {
      setLoadingBtn(true);
      const response = await api.get('getAccount', {
        dataDocument: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
        document: currentDoc
      })

      if (!Array.isArray(response.data)) {
        response.data = response.data ? [response.data] : [];
      }

      if (!response.data || (Array.isArray(response.data) && response.data.length === 0)) {
        throw new Error("Cliente não encontrado");
      }
      const accountOptionsSelectList = response.data.map(account => (
        {
          id: account.id,
          accountDisplayMessage: account.accountSelect,
          name: account.name
        }
      ));
      await setListClientAccount(accountOptionsSelectList);
      await setClientProducts(response.data);
      if (accountOptionsSelectList.length === 1) await setIdClient(accountOptionsSelectList[0]?.id);



      setTimeout(() => {
        setLoadingBtn(false);
        setFormSteps(1);
      }, 1000);
    } catch (error) {
      setLoadingBtn(false);
      notifyError(error.message);
    }
  };

  const handleSubmit = (value) => {
    setLoadingTable(true);
    setSearchData(false);

    setDateInit(value.lastYear);
    setDateFim(value.today);

    api
      .get("get_pay_launch", {
        initDate: value.lastYear,
        finalDate: value.today,
        client_id: idClient
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
      });
  };

  const handleOpenModal = useCallback(
    (numeroAutorizacao) => {
      setmodalPayStoresOpen(true);
      setInfosModal(
        data?.content?.filter(
          (item) => item.numeroAutorizacao === numeroAutorizacao
        )
      );
    },
    [data]
  );

  const closeModalPayStoresOpen = useCallback(() => {
    setmodalPayStoresOpen(false);
  }, []);


  const getLojas = useCallback(async () => {
    try {
      await setLoading(true);
      const places = await getPlaces(user);

      const editList = places
        .map((item) => {
          return {
            value: item.id,
            label: item.nomeFantasia,
            cnpj: item.cnpj,
          };
        });

      setLojas(editList);
    } catch (error) {
      console.error("Falha ao listar lojas");
    } finally {
      await setLoading(false);
    }
  }, []);

  const handleResetSearch = async () => {
    await setFormSteps(0);
    await setNameClient(null);
    await setIdClient(null);
    await setClientProducts(null);
    await setSearchData(false);
  }

  const reloadPage = () => {
    setLoadingTable(true);
    setSearchData(false);

    api
      .get("get_pay_launch", {
        initDate: dateInit,
        finalDate: dateFim,
        client_id: idClient
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
      });
  };

  useEffect(async () => {
    await getLojas();
  }, [getLojas]);

  return (
    <S.Container>
      <BreadCrumbNavigation tableName="Pagamentos" preTableName="Listagem" />
      {formSteps === 0 && (
        <S.SearchUserWrapper>
          <Formik
            initialValues={{ doc: "" }}
            validationSchema={Yup.object({
              doc: Yup.string()
                .required(`${currentDoc.toUpperCase()} obrigatório`)
                .test(
                  "test_cpf",
                  `Informe um ${currentDoc} válido`,
                  (value) => {
                    if (currentDoc === "client_id") return true;
                    if (currentDoc === "cpf") {
                      if (value === undefined || value === null)
                        return false;

                      const cpfFormated = value
                        .split(".")
                        .join("")
                        .replace("-", "");
                      return validaCpf(cpfFormated);
                    }

                  }
                ),
            })}
            onSubmit={handleStepOne}
          >
            {() => (
              <Form>
                <div className="box-form">
                  <S.FormRow>
                    <div>
                      <b>Tipo:</b>
                    </div>
                    <S.CpfCnpj>
                      <S.DocButton
                        selected={currentDoc === "cpf"}
                        onClick={handleCPF}
                      >
                        CPF
                      </S.DocButton>
                      <S.DocButton
                        selected={currentDoc === "client_id"}
                        onClick={handleClientId}
                      >
                        ID Cliente
                      </S.DocButton>
                    </S.CpfCnpj>

                    <Field name="doc">
                      {({ field, meta }) => (
                        <ReactInputMask
                          placeholder={setPlaceholder()}
                          type="text"
                          mask={currentMask}
                          maskChar={null}
                          autoComplete="off"
                          className={
                            meta.touched && meta.error ? "error-id" : null
                          }
                          {...field}
                        />
                      )}
                    </Field>

                    <ErrorMessage
                      name="doc"
                      className="error"
                      component="div"
                    />
                  </S.FormRow>

                  <S.FormRow>
                    <Button
                      loading={loadingBtn}
                      htmlType="submit"
                      type="primary"
                    >
                      Buscar
                    </Button>
                  </S.FormRow>
                </div>
              </Form>
            )}
          </Formik>
        </S.SearchUserWrapper>
      )}
      {formSteps > 0 && (
        <div className="select-btn" >
          <Button
            style={{ marginBottom: 15 }}
            onClick={() => handleResetSearch()} type="ghost">
            Nova Pesquisa
          </Button>
        </div>
      )}
      {formSteps === 1 && (
        <S.SearchDataWrapper>
          <span className="title">
            * Conta:
          </span>
          <S.FormAccount>
            {listClientAccount.length > 1 ? (
              <Select
                placeholder="Selecionar conta"
                onChange={handleAccountSelected}
              >
                {listClientAccount.map(account => (
                  <Option value={account.id} key={account.id}>
                    {account.accountDisplayMessage}
                  </Option>
                ))}
              </Select>
            ) :
              (<Select disabled value={listClientAccount[0].name} />)
            }
            <Formik
              initialValues={{
                lastYear: moment(lastYear).format("YYYY-MM-DD"),
                today: moment(today).format("YYYY-MM-DD"),
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form className="formWrapper">
                  <ConfigProvider locale={datePickerLocale}>
                    <div className="date-selection-container">
                      <div className="select-date">
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>
                            Data Inicial
                          </span>
                        </S.InputsLabel>
                        <Field name="lastYear">
                          {({ meta }) => (
                            <DatePicker
                              defaultValue={moment(lastYear)}
                              className={`${meta.touched && meta.error ? "error-id" : ""}`}
                              placeholder="Selecione a data"
                              format="DD/MM/YYYY"
                              allowClear={false}
                              showToday={false}
                              onChange={(e) => onChangeLastYearRangeDate(e, setFieldValue)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="select-date">
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>
                            Data Final
                          </span>
                        </S.InputsLabel>
                        <Field name="today">
                          {({ meta }) => (
                            <DatePicker
                              defaultValue={moment(today)}
                              className={`${meta.touched && meta.error ? "error-id" : ""}`}
                              placeholder="Selecione a data"
                              format="DD/MM/YYYY"
                              allowClear={false}
                              showToday={false}
                              onChange={(e) => onChangeTodayRangeDate(e, setFieldValue)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="select-btn">
                        <Button htmlType="submit" type="ghost">
                          Pesquisar
                        </Button>
                      </div>
                    </div>
                  </ConfigProvider>
                </Form>
              )}
            </Formik>
          </S.FormAccount>
          <S.TableWrapper>
            {loadingTable === true && (
              <S.LoadingWrapper><LoadingElevaty /></S.LoadingWrapper>
            )}
            {searchData && data?.content && (
              <UsersTable
                data={data}
                handleOpenModal={handleOpenModal}
                lojas={lojas}
              />
            )}
            {searchData && !data?.content && (
              <div className="alert-table">
                <Alert
                  message="Não há dados para as datas selecionadas"
                  type="warning"
                  showIcon
                />
              </div>
            )}
          </S.TableWrapper>
        </S.SearchDataWrapper>
      )}

      {modalPayStoresOpen && (
        <PayCancelModal
          onCancel={closeModalPayStoresOpen}
          infosModal={infosModal}
          reloadPage={reloadPage}
        />
      )}
    </S.Container>
  )
}

export default NewTable;
