import React, { useCallback, useState } from 'react';

import * as AntIcon from '@ant-design/icons';
import { Tooltip } from 'antd';

import api from '../../../../../services/api';
import { notifyError, notifySucces } from '../../../../../utils/notifications';
import ActionModal from './ActionModal';

function ButtonActionTable({ solicitationType, setClassifications }) {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState();
  const entityIsDisabled = solicitationType?.deleted_at;

  const handleToggle = useCallback(
    () => setVisible((prevState) => !prevState),
    []
  );

  const onFinishHandler = useCallback(() => {
    handleToggle();
  }, [handleToggle]);

  const onClickHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      await api.delete(`remove_role_classification`, {
        classification_id: solicitationType.id,
        role_id: solicitationType.role_id,
      });

      const response = await api.get('role_classification', {
        role_id: solicitationType.role_id,
      });
      setClassifications(response.data);

      notifySucces(`Relacionamento de classificação removida com sucesso`);

      onFinishHandler();
    } catch (err) {
      notifyError(
        `Erro ao remover relacionamento de classificação`,
        err.message
      );
    } finally {
      setIsLoading(false);
    }
  }, [entityIsDisabled, onFinishHandler, solicitationType.id]);

  if (!solicitationType) {
    return null;
  }

  return (
    <>
      {!solicitationType?.deleted_at && (
        <Tooltip title="Remover">
          <AntIcon.StopOutlined
            onClick={handleToggle}
            style={{ marginLeft: '10px' }}
          />
        </Tooltip>
      )}
      <ActionModal
        title="remover"
        action="remover"
        visible={visible}
        onOkClick={onClickHandler}
        isLoading={isLoading}
        onCancelClick={handleToggle}
        solicitationType={solicitationType}
      />
    </>
  );
}

export default React.memo(ButtonActionTable);
