/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactText, memo, useEffect, useState } from "react";

import { Button, Table } from "antd";

import { formatDate } from "../../../../../../../utils/formatter";
import Parcela from "../model/Parcela";
import Parcelamento from "../model/Parcelamento";
import { Simulation } from "../model/simulation";
import classes from "./InstallmentAnticipationDetails.module.scss";
import { SelectedInstalmentInterface } from "./SelectedInstalment.interface";

interface InstallmentAnticipationDetailsProps {
  parcelamento: Parcelamento;
  selectedInstallments: SelectedInstalmentInterface;
  isPartialButtonDisabled: boolean;
  isTotalButtonDisabled: boolean;
  isLoading: boolean;
  simulation: Simulation | undefined;
  onChange: (selectedRowKeys: React.Key[], selectedRows: Parcela[]) => void;
  onSelectedInstalmentClick: () => void;
  onTotalInstalmentClick: () => void;
}
function InstallmentAnticipationDetails({
  parcelamento,
  simulation,
  isLoading,
  selectedInstallments,
  onSelectedInstalmentClick,
  isPartialButtonDisabled,
  onChange,
  onTotalInstalmentClick,
  isTotalButtonDisabled,
}: InstallmentAnticipationDetailsProps) {

  const [numberTeste, setNumberTeste] = useState<{ valor: number; principal: number; juros: number; iof: number; economiaTotal?: number; qtdParcelas: number; }[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentChecked, setCurrentChecked] = useState<ReactText[]>([]);
  const [checkedParcelas, setCheckedParcelas] = useState<Record<number, boolean>>({});

  function rounding(value: number) {
    return parseFloat(value.toFixed(2));
  }

  function onSimulateInstalment(parcelamento: Parcelamento, selectedInstallments: SelectedInstalmentInterface) {

    setNumberTeste([]);
    let installments: number[] = [];

     installments = Array.from(
      { length: selectedInstallments.firstInstallment - selectedInstallments.lastInstallment + 1 },
      (_, i) => i + selectedInstallments.lastInstallment
    );

    const installmentsAntecipaveis = parcelamento.parcelas.filter(
      (parcela) => installments.some((installment) => installment === parcela.posicao)
    );

      const totalParcelasAntecipaveisRaw = installmentsAntecipaveis?.reduce((acc, parcela) => {
        acc.valor += parcela.valor;
        acc.principal += parcela.principal;
        acc.juros += parcela.juros;
        acc.iof += parcela.iof;
        acc.economiaTotal += parcela.juros + parcela.iof;
        return acc;
      }, { valor: 0, principal: 0,juros: 0, iof: 0, qtdParcelas:  installments.length,
        economiaTotal: 0
      });

      const totalParcelasAntecipaveis = {
        valor: rounding(totalParcelasAntecipaveisRaw.valor),
        principal: rounding(totalParcelasAntecipaveisRaw.principal),
        juros: rounding(totalParcelasAntecipaveisRaw.juros),
        iof: rounding(totalParcelasAntecipaveisRaw.iof),
        qtdParcelas: rounding(totalParcelasAntecipaveisRaw.qtdParcelas),
        economiaTotal: rounding(totalParcelasAntecipaveisRaw.economiaTotal)
      }
      setNumberTeste(prevState => [...prevState, totalParcelasAntecipaveis]);
    }

    useEffect(() => {
      onSimulateInstalment(parcelamento, selectedInstallments);
    }, [parcelamento, selectedInstallments]);

  return (
    <>
      <Table
        title={() => "Dados Do Parcelamento"}
        columns={[
          {
            title: "Valor Financiado (R$)",
            dataIndex: "valorFinanciado",
            key: "valorFinanciado",
          },
          {
            title: "Valor Total (R$)",
            dataIndex: "valorTotal",
            key: "valorTotal",
          },
          {
            title: "Taxa de Juros (%)",
            dataIndex: "taxaJuros",
            key: "taxaJuros",
          },
          {
            title: "Juros Total (R$)",
            dataIndex: "valorJuros",
            key: "valorJuros",
          },
          {
            title: "CET (%)",
            dataIndex: "cet",
            key: "cet",
          },
        ]}
        dataSource={[parcelamento]}
        pagination={false}
        scroll={{ y: 240 }}
        rowKey="idPagamentoAtivacao"
      />

      <Table
        title={() => "Antecipação de Parcelamento Total"}
        loading={isLoading}
        dataSource={ simulation ? [simulation.resumoAntecipacao] : undefined}
        columns={[
          {
            title: "Qtd. Parcelas Restantes",
            key: "parcelasRestantes",
            dataIndex: "parcelasRestantes",
          },
          {
            title: "Total Parcelado Restante (R$)",
            key: "totalRestante",
            dataIndex: "totalRestante",
          },
          {
            title: "Valor em caso de antecipação (R$)",
            dataIndex: "totalAntecipacao",
            key: "totalAntecipacao",
          },
          {
            title: "Economia de Juros (R$)",
            dataIndex: "economiaJuros",
            key: "economiaJuros",
          },
          {
            title: "Economia de IOF",
            dataIndex: "economiaIOF",
            key: "economiaIOF",
          },
          {
            title: "Economia Total (R$)",
            dataIndex: "economiaAntecipacao",
            key: "economiaAntecipacao",
          },
        ]}
        pagination={false}
        scroll={{ y: 240 }}
      />
      <div className={classes.InstallmentAnticipationDetails__actionButtons}>
        <Button
          onClick={onTotalInstalmentClick}
          disabled={!simulation || isTotalButtonDisabled}
          loading={isLoading}
          type="primary"
        >
          Antecipar Parcelamento Completo
        </Button>
      </div>

      <Table
        title={() => "Simulação de Parcelamentos"}
        loading={isLoading}
        rowSelection={
          simulation
            ? {
                type: "checkbox",
                onChange: (selectedRowKeys: ReactText[], selectedRows: Parcela[]) => {
                  const newCheckedParcelas = { ...checkedParcelas };
                  selectedRows.forEach((parcela) => {
                    newCheckedParcelas[parcela.posicao] = true;
                  });
                  setCheckedParcelas(newCheckedParcelas);

                  onChange(selectedRowKeys, selectedRows);
                },
                getCheckboxProps: (parcela: Parcela) => {
                  const { posicao } = parcela;
                  const { firstInstallment, lastInstallment } = selectedInstallments;

                  let lastItem = simulation?.parcelasAntecipaveis?.reduce(
                    (acc, item) => {
                      return acc > item.parcela ? acc : item.parcela;
                    },
                    0
                  );

                  lastItem =
                    lastInstallment <= lastItem
                      ? lastItem - (firstInstallment - lastInstallment + 1)
                      : lastItem;

                  const antecipavel = simulation.parcelasAntecipaveis
                    .map(({ parcela }) => parcela)
                    .includes(posicao);

                  const isChecked = currentChecked && currentChecked.includes(posicao);

                  return {
                    disabled: !antecipavel || lastItem - 1 >= posicao,
                    checked: !!isChecked,
                  };
                },
              }
            : undefined
        }
        columns={[
          {
            title: "Parcela",
            key: "posicao",
            dataIndex: "posicao",
            sortOrder: "descend",
            sorter: (a, b) => a.posicao - b.posicao,
          },
          {
            title: "Faturamento",
            key: "dataFaturamento",
            render: (row: any) => (
              <span>{formatDate(row.dataFaturamento)}</span>
            ),
          },
          {
            title: "Parcela (R$)",
            dataIndex: "valor",
            key: "valor",
          },
          {
            title: "Total Principal (R$)",
            dataIndex: "principal",
            key: "principal",
          },
          {
            title: "Juros Parcela",
            dataIndex: "juros",
            key: "juros",
          },
          {
            title: "IOF Aplicado (R$)",
            dataIndex: "iof",
            key: "iof",
          },
        ]}
        dataSource={parcelamento.parcelas}
        pagination={false}
        scroll={{ y: 240 }}
        rowKey="posicao"
      />

    {numberTeste.every(item => item.qtdParcelas !== 0) && (
      <Table
        title={() => "Simulação de Parcelamento Parcial"}
        loading={isLoading}
        dataSource={numberTeste}
        columns={[
          {
            title: "Qtd. Parcelas Restantes",
            key: "qtdParcelas",
            dataIndex: "qtdParcelas",
          },
          {
            title: "Total Parcelado Restante (R$)",
            key: "valor",
            dataIndex: "valor",
          },
          {
            title: "Valor em caso de antecipação (R$)",
            dataIndex: "principal",
            key: "principal",
          },
          {
            title: "Economia de Juros (R$)",
            dataIndex: "juros",
            key: "juros",
          },
          {
            title: "Economia de IOF",
            dataIndex: "iof",
            key: "iof",
          },
          {
            title: "Economia Total (R$)",
            dataIndex: "economiaTotal",
            key: "economiaTotal",
          },
        ]}
        pagination={false}
        scroll={{ y: 240 }}
      />
    )}

      <div className={classes.InstallmentAnticipationDetails__actionButtons}>
        <Button
          loading={isLoading}
          onClick={onSelectedInstalmentClick}
          disabled={!simulation || isPartialButtonDisabled || numberTeste.every(item => item.qtdParcelas === 0)}
          type="primary"
        >
          Antecipar Parcelas Selecionadas
        </Button>
      </div>
    </>
  );
}

export default memo(InstallmentAnticipationDetails);
