import React, { useEffect } from 'react';

import { hotjar } from 'react-hotjar';
import { ToastContainer } from 'react-toastify';

import AppProvide from './hooks/AppProvider';
import Routes from './routes';

import 'antd/dist/antd.variable.css';
import './styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  useEffect(() => {
    hotjar.initialize(3271870, 6);
  }, []);

  return (
    <AppProvide>
      <ToastContainer />
      <Routes />
    </AppProvide>
  );
}

export default App;
