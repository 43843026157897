import { memo } from 'react';

import { usePermissions } from '../../Context/permissionContext';

function Can({ permission, children }) {
  const { permissions } = usePermissions();
  if (permissions && permissions.includes(permission)) {
    return children;
  }

  return null;
}

export default memo(Can);
