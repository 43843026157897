import { notifyError } from '../utils/notifications';
import api from './api';

const endpoint = 'vipagem';

const productMap = {
  1: 19,
  2: 20,
  3: 24,
  4: 22,
};

export class vipagemService {
  static bandeirarConta(clientId, productId, cardNumber) {
    const correctProductId = productMap[productId];

    if (!correctProductId) {
      notifyError('Produto não possui código bandeira correspondente');
    }

    const url = `${endpoint}/bandeirar_conta`;
    return api
      .post(url, {
        client_id: clientId,
        product_id: correctProductId,
        card_number: cardNumber,
      })
      .then((response) => response.data);
  }

  static cartoesBandeiradosTransicao(clientId) {
    return api
      .get(`${endpoint}/cartoes_bandeirados_transicao`, { client_id: clientId })
      .then((response) => response.data);
  }

  static reemitirCartaoTransicao(clientId, cardId, cardNumber) {
    const url = `${endpoint}/reemitir_cartao_transicao`;
    return api
      .post(url, {
        client_id: clientId,
        card_id: cardId,
        card_number: cardNumber,
      })
      .then((response) => response.data);
  }

  static alterarProdutoPortador(clientId, productId, cardNumber) {
    const url = `${endpoint}/alterar_produto_portador`;
    return api
      .post(url, {
        client_id: clientId,
        product_id: productMap[productId],
        card_number: cardNumber,
      })
      .then((response) => response.data);
  }
}
