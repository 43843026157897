import Ws from '@adonisjs/websocket-client';

export class SocketConnection {
  connect() {
    this.ws = Ws(process.env.REACT_APP_WS_HOST).connect();
    return this;
  }

  subscribe(channel, handler) {
    if (!this.ws) {
      return this.subscribe(channel);
    }

    const result = this.ws.subscribe(channel);

    result.on('message', (message) => {
      handler(message);
    });

    // result.on('error', (error) => {
    //   console.error(error);
    // });

    return result;
  }
}

export default new SocketConnection();
