import styled from "styled-components";

export const WrapperPopupService = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .align-popup {
    width: 100%;
    max-width: 600px;
    text-align: center;
    padding: 15px;

    .align-popup__close{
      width: 100%;
      display: flex;
      justify-content: flex-end;

      svg{
        font-size: 36px;
        cursor: pointer;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1200px) {
    .align-popup {
      max-width: 500px;
    }
  }

  @media (max-width: 992px) {
    .align-popup {
      max-width: 400px;
    }
  }

  @media (max-width: 768px) {
    .align-popup {
      max-width: 300px;
    }
  }

  @media (max-width: 576px) {
    .align-popup {
      max-width: 100%;
      padding: 10px;
    }

    .align-popup__close svg {
      font-size: 30px;
    }
  }
`;
