import { Modal,Col } from 'antd';
import styled from 'styled-components';

export const ModalContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 16px;
  gap: 16px;
  width: 552px;
  height: 108px;
  background: #F5F5F5;
  border: 1px dashed #BFBFBF;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  
  h3 {
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
    padding-top: 45px;
  }
  h4 {
    color: #8C8C8C;
    white-space: nowrap;
    margin: 0;
    padding-top: 4px;

  }

  .file-upload-label {
    visibility: hidden;
  }
`;



export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;


  .cancel-btn {
    margin-right: 8px;
  }
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
  }
`;
export const ThirdCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;