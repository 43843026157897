import { React, useCallback, useState } from 'react';

import { Button, Input, Modal } from 'antd';

import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from './styles';

const { TextArea } = Input;

function ModalUnlockAccount({ open, closeModal, clientID, setIsBlockedUser}) {
  const { client, updateClient } = useClient();

  const [observation, setObservation] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeObservation = useCallback(async (event) => {
    const { value } = event.target;
    await setObservation(value);
  }, []);

  const handleSendFormObservation = useCallback(async () => {
    setLoading(true);

    await api
      .post('client_unlock_account', {
        client_id: clientID,
        observation,
      })
      .then(() => {
        updateClient({
          ...client,
          client: {
            ...client.client,
            statusCliente: 'A',
            ultimoMotivoBloqueioCliente: null,
          },
        });
        notifySucces('O cliente foi desbloqueado com sucesso');
        setLoading(false);
        setIsBlockedUser(true);
        closeModal();
      })
      .catch((error) => {
        notifyError('Erro ao desbloquear conta');
        setLoading(false);
        setIsBlockedUser(false);
        closeModal();
      });
  }, [observation, clientID]);

  return (
    <Modal
      title="Desbloquear conta"
      visible={open}
      width={572}
      onCancel={closeModal}
    >
      {loading ? (
        <S.NewLoadingModals>
          <LoadingElevaty width="35px" height="35px" duration="durFast" />
        </S.NewLoadingModals>
      ) : (
        <>
          <>
            <S.WrapperSelectBlock>
              <span>Observação</span>
              <TextArea
                rows={3}
                placeholder="Ex.: O cliente solicitou o desbloqueio"
                onChange={handleChangeObservation}
              />
            </S.WrapperSelectBlock>
          </>
          <>
            <S.ButtonContainer>
              <Button onClick={closeModal}>Cancelar</Button>
              <Button
                className="button-confirm"
                disabled={observation.length === 0}
                onClick={handleSendFormObservation}
              >
                Desbloquear
              </Button>
            </S.ButtonContainer>
          </>
        </>
      )}
    </Modal>
  );
}

export default ModalUnlockAccount;
