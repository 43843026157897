import React, { useState } from "react";

import {
  InfoCircleOutlined,
  ScheduleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { GrAlert } from "react-icons/gr";

import { usePermissions } from "../../../../Context/permissionContext";
import { useClient } from "../../../../hooks/ClientProvider";
import ModalCancelSMS from "../../../home/modals/modalCancelSMS";
import ModalContractSMS from "../../../home/modals/modalContractSMS";
import ModalDetailsSMS from "../../../home/modals/modalDetailSMS";
import * as S from "./style";

function ModalAcessSMS({
  openModal,
  openDetailsModal,
  setDetailsModalOpen,
  titleModal,
  onClose,
  pacoteSMS,
  reloadPacoteSMS,
}) {
  const [activePacote, setActivePacote] = useState(null);
  const [openCancelContractModalSMS, setOpenCancelContractModalSMS] =
    useState(false);
  const [openContractModalSMS, setOpenContractModalSMS] = useState(false);

  const { permissions } = usePermissions();
  const { client } = useClient();

  return (
    <>
      <S.WrapperModalAcessSms
        visible={openModal}
        title={titleModal}
        onCancel={onClose}
      >
        {pacoteSMS?.length > 0 ? (
          <>
            <table className="rwd-table">
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Tarifa</th>
                <th>Ações</th>
              </tr>

              {pacoteSMS?.map((data) => (
                <tr key={data?.id}>
                  <td data-th="ID">{data?.id}</td>
                  <td data-th="Nome">{data?.nome}</td>
                  <td data-th="Descrição">{data?.descricao}</td>
                  <td data-th="Valor">
                    R$ {String(data?.valorTarifa).replace(".", ",")}
                  </td>
                  <td data-th="Ações">
                    <>
                      <InfoCircleOutlined
                        title="Detalhes"
                        onClick={async () => {
                          setActivePacote(data);
                          setDetailsModalOpen(true);
                        }}
                      />
                      {permissions &&
                        permissions.includes("cancel_sms_package") &&
                        data.contratado && (
                          <StopOutlined
                            title="Cancelar"
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActivePacote(data);
                              setOpenCancelContractModalSMS(true);
                            }}
                          />
                        )}
                      {permissions &&
                        permissions.includes("contract_sms_package") &&
                        !data.contratado && (
                          <ScheduleOutlined
                            title="Contratar"
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActivePacote(data);
                              setOpenContractModalSMS(true);
                            }}
                          />
                        )}
                    </>
                  </td>
                </tr>
              ))}
            </table>
          </>
        ) : (
          <div className="no-find-sms">
            <GrAlert />
            <p>Nenhum pacote SMS encontrado</p>
          </div>
        )}

        {/* Modal de Detalhes do Pacote SMS */}
        <ModalDetailsSMS
          open={openDetailsModal}
          closeModal={() => {
            setDetailsModalOpen(false);
          }}
          activePacote={activePacote}
        />

        {/* Modal de Cancelamento de Pacote SMS */}
        <ModalCancelSMS
          open={openCancelContractModalSMS}
          closeModal={() => setOpenCancelContractModalSMS(false)}
          reloadPacoteSMS={reloadPacoteSMS}
          activePacote={activePacote}
          client={client}
        />

        {/* Modal de Contratação de Pacote SMS */}
        <ModalContractSMS
          open={openContractModalSMS}
          closeModal={() => setOpenContractModalSMS(false)}
          reloadPacoteSMS={reloadPacoteSMS}
          activePacote={activePacote}
          client={client}
        />
      </S.WrapperModalAcessSms>
    </>
  );
}

export default ModalAcessSMS;
