import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';

import { getFormatDateAndTime, getFormatDateAndTimeNoTz, getFormatDateReverse } from '../../../utils/formatDate';
import * as S from './style';

function ModalAntecipateTotal({ open, paramsAntecipateTotal, closeModal }) {

  return (
    <S.StyledModal title={
        <span>
          <CheckCircleOutlined className="anticon-success" /> Antecipação Realizada com Sucesso!
        </span>
      } width={500} visible={open} onCancel={closeModal}>
    {paramsAntecipateTotal && (
    <S.AntecipateModalContainer>
      <S.ResultAntecipateRow>
        <span>Id do Portador:</span>
        <span>{paramsAntecipateTotal?.idPortador}</span>
        </S.ResultAntecipateRow>
      <S.ResultAntecipateRow>
        <span>Id da Venda:</span>
        <span>{paramsAntecipateTotal?.idVenda}</span>
      </S.ResultAntecipateRow>
      <S.ResultAntecipateRow>
        <span>Desconto Total:</span>
        <span>R$ {paramsAntecipateTotal?.descontoAntecipacaoTotal}</span>
      </S.ResultAntecipateRow>
      <S.ResultAntecipateRow>
        <span>Valor Final Total:</span>
        <span>R$ {paramsAntecipateTotal?.valorFinalTotal}</span>
      </S.ResultAntecipateRow>

    {paramsAntecipateTotal?.parcelas && paramsAntecipateTotal.parcelas.length > 0 && paramsAntecipateTotal.parcelas.map((parcela) => (
        <>
        <S.ResultAntecipateRow className="parcela-unica">
          <span>Detalhes de Parcelas de número:</span>
          <span>{parcela.numeroParcela}</span>
        </S.ResultAntecipateRow>
        <S.ResultAntecipateRow>
          <span>Data Faturamento:</span>
          <span>{getFormatDateAndTimeNoTz(parcela.dataFaturamento)}</span>
        </S.ResultAntecipateRow>
        <S.ResultAntecipateRow>
          <span>Data Antecipação:</span>
          <span>{getFormatDateAndTimeNoTz(parcela.dataAntecipacao)}</span>
        </S.ResultAntecipateRow>
        <S.ResultAntecipateRow >
          <span>Data Vencimento:</span>
          <span>{getFormatDateReverse(parcela.dataVencimento)}</span>
        </S.ResultAntecipateRow>
        <S.ResultAntecipateRow >
          <span>Valor Antecipado:</span>
          <span>R$ {parcela.valorAntecipacao}</span>
        </S.ResultAntecipateRow>
        <S.ResultAntecipateRow >
          <span>Desconto de Antecipação:</span>
          <span>R$ {parcela.descontoAntecipacao}</span>
        </S.ResultAntecipateRow>
        <S.ResultAntecipateRow >
          <span>Valor Final:</span>
          <span>R$ {parcela.valorFinal}</span>
        </S.ResultAntecipateRow>
      </>
    ))}
    </S.AntecipateModalContainer>
    )}
  </S.StyledModal>
);
}

export default (ModalAntecipateTotal);
