import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";

import { usePermissions } from "../../Context/permissionContext";
import { useClient } from "../../hooks/ClientProvider"
import useDependentCard from "../../hooks/useDependentCard";
import useHolderCard from '../../hooks/useHolderCard';
import Api from "../../services/api";
import { formatPrice } from "../../utils/currency";
import { notifyError, notifySucces } from "../../utils/notifications";
import Can  from "../Can";
import ModalBlockCard from "../creditCard/modals/BlockModal"
import ModalUnBlockCard from "../creditCard/modals/UnBlockModal";
import * as S from "./style";


export const CreditCardV2 = ({ card, reloadCards, isDependent, isHistoryCard }) => {
  const [isModalBlockCardVisible, setIsModalBlockCardVisible] = useState(false);
  const [blockTypes, setBlockTypes] = useState([]);
  const [newDetailBlockCard, setNewDetailBlockCard] = useState(null);
  const [isUnBlockModalVisible,setIsUnBlockModalVisible] = useState(false);
  const [limit, setLimit] = useState('');

  const { client } = useClient();
  const { permissions } = usePermissions();

  const productID = client?.product?.id;
  const clientID = client?.client?.id;
  const codProduct = client?.product?.codigoProduto
  const isProdutoBandeirado = client?.client.isProdutoBandeirado;
  const validityDate = card && card.dataValidade ? card.dataValidade.split("-") : [];

  const {
    bloqCard,
    unBloqCard
  } = useHolderCard(
    client?.client?.id,
    client?.product?.codigoProduto,
    client?.client?.cpf
  );


  const {
    unBloqCardDependent,
    bloqCardDependent
  } = useDependentCard(
    client?.client?.adicional,
    client?.product?.codigoProduto
  );


  const blockedStatuses = [
    "B",
    "BLOQUEADO",
    "BLOQUEADO_TEMPORARIO",
    "BLOQUEADO_PERMANENTE",
    "PENDENTE_TROCA",
    "CARTAO_TROCA",
    "PENDENTE_CONFIRMACAO_TROCA"
  ];

  const cardExchangeStatuses = ["CARTAO_TROCA","PENDENTE_TROCA","PENDENTE_CONFIRMACAO_TROCA"];

  const unlockableStatuses = ["BLOQUEADO", "B", "BLOQUEADO_TEMPORARIO"];

  const formattedCardNumber = useMemo(() => {
    if(!card.numeroMascarado) {
      return;
    }


    if (typeof card.numeroMascarado !== "string") {
      return card.numeroMascarado;
    }

    const cardNumber = card.numeroMascarado;
    return cardNumber
      .match(/.{1,4}/g)
      .join(" ")
      .replace(/X/g, "*");
  }, [card.numeroMascarado]);

  const getBlockTypes = useCallback(async () => {
    try {
      const resp = await Api.get("card_block_type", { isProdutoBandeirado });
      const { data } = resp;
      await setBlockTypes(data);
    } catch (error) {
      notifyError(error.message);
    }
  }, []);

  const confirmExchange = useCallback(async () => {
    try {
      await Api.post("card_confirm_exchange", {
        card_id: card.id,
        product_id: productID,
      });
      notifySucces("Troca realizada com sucesso.");
    } catch (error) {
      notifyError(error.message);
    } finally {
      reloadCards();
    }
  }, [card, client]);

  async function getLimit() {
    await Api
      .get('client_information', { client_id: client.client.id })
      .then(async (response) => {
        await setLimit(response.data.limitePrincipal);
      })
      .catch((erro) => {
        console.error('Falha', erro);
      });
  }

  const toggleModalBlockCard = useCallback(() => {
    if (permissions.includes("change_password") === true){
      return  setIsModalBlockCardVisible(prevState => !prevState);
    }
    return undefined;
  }, [permissions]);

  const toggleModalUnBlock = useCallback(() => {
    return  setIsUnBlockModalVisible(prevState => !prevState);
  }, []);

  useEffect(() => {
    getBlockTypes();
    getLimit();
  }, [])


  return (
    <>
     <ModalBlockCard
        isModalVisible={isModalBlockCardVisible}
        // eslint-disable-next-line react/jsx-curly-brace-presence
        blockValue={"0"}
        blockTypes={blockTypes}
        closeModal={toggleModalBlockCard}
        clientID={clientID}
        productID={client?.product?.codigoProduto}
        card={card}
        bloqCard={isDependent === true?  bloqCardDependent : bloqCard}
        setNewDetailBlockCard={setNewDetailBlockCard}
        codProduct={codProduct}
        reloadCards={reloadCards}
      />

    <ModalUnBlockCard
      isModalVisible={isUnBlockModalVisible}
      closeModal={toggleModalUnBlock}
      card={card}
      unBloqCard={isDependent === true?  unBloqCardDependent : unBloqCard}
      clientID={clientID}
      productID={client?.product?.codigoProduto}
      setDetailBlockCard={null}
      reloadCards={reloadCards}
    />

    <S.CreditCardContainer>
      <S.CreditCardTop>
        <span>{formatPrice(limit)}</span>
      </S.CreditCardTop>
      <S.CreditCardLogo
        src="/images/logo-pequeno-elevaty.png"
        alt="Logo Elevaty"
      />
      <S.CreditCardInfo>
        <div>
          <span className="credit-card-number">{formattedCardNumber}</span>
          <span>{card.nome}</span>
        </div>
        {!!validityDate.length &&
          <div className="validity-container">
            <span>Validade</span>
            <span className="credit-card-validity">
              {validityDate[1]}/{validityDate[0]}
            </span>
          </div>
        }
      </S.CreditCardInfo>
      <S.CreditCardBottom>
        {card.statusEmbossing === "EMBOSSADO" && <span>Impresso</span>}
        <span>{card.virtual ? "Virtual" : "Físico"}</span>
        {cardExchangeStatuses.includes(card.status) ? (
          <S.CreditCardButton type="button" onClick={confirmExchange}>
            Confirmar Troca
          </S.CreditCardButton>
        ) : !blockedStatuses.includes(card.status) && !isHistoryCard ?  (
          <Can permission="block">
            <S.CreditCardButton type="button" onClick={toggleModalBlockCard}>
              Bloquear
            </S.CreditCardButton>
          </Can>
        ) : unlockableStatuses.includes(card.status) && !isHistoryCard ?  (
          <Can permission="unblock">
            <S.CreditCardButton type="button" onClick={toggleModalUnBlock}>
              Desbloquear
            </S.CreditCardButton>
          </Can>
        ) : ["C","CANCELADO"].includes(card.status) ? (
          <Can permission="reactivate_canceled_card">
            <S.CreditCardButton type="button" onClick={toggleModalBlockCard}>
              Reativar
            </S.CreditCardButton>
          </Can>
        ): null}
      </S.CreditCardBottom>
    </S.CreditCardContainer>
    </>
  );
};
