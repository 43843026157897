import React, { memo, useCallback, useState, useRef } from "react";

import { UploadOutlined, RightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Col } from "antd";

import api from "../../../services/api";
import { notifyError, notifySucces } from "../../../utils/notifications";
import * as S from "./style";


function ModalSendArqLote({ closeModal, isModalVisible, titleModal,endpoint }) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [numLote, setNumLote] = useState('');
  const inputFileRef = useRef(null);

  const handleFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFileName(selectedFile.name);
  }, []);

  const handleClickModalContent = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const closeAndClearModal = useCallback(() => {
    // setFile(null);
    // setSelectedFileName(null);
    closeModal();
  }, []);

  const closeAuxModal = useCallback(() => {
    setOpenModalSuccess(false);
  })

  const handleSendBatch = useCallback(async () => {
    try {
      setLoadingBtn(true);
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await api.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      notifySucces('Lote criado com sucesso.');
      setNumLote(data.codigoLote);
      setOpenModalSuccess(true);
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoadingBtn(false);
      closeAndClearModal();
    }
  }, [file]);

  return (
    <>
    <Modal
      title={titleModal[0].toUpperCase() + titleModal.toLowerCase().substr(1)}
      width={600}
      visible={isModalVisible}
      onCancel={closeAndClearModal}
      footer={null}
    >
      <S.ModalContent onClick={handleClickModalContent}>
        <Row gutter={16} align="middle">
          <Col span={7}>
            <UploadOutlined
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "48px",
                color: "#BFBFBF",
              }}
            />
          </Col>
          <Col span={17}>
            <h3>Selecione o arquivo para {titleModal}.</h3>
            <h4>Tipos de arquivos suportados: .txt</h4>
            {selectedFileName && (
              <h4>Arquivo selecionado: {selectedFileName}</h4>
            )}
            <label htmlFor="file-upload" className="file-upload-label">
              <input
                type="file"
                accept=".txt"
                id="file-upload"
                ref={inputFileRef}
                onChange={handleFileChange}
              />
              Escolher Arquivo
            </label>
          </Col>
        </Row>
        <RightOutlined
          style={{ fontSize: "13px", color: "#272D3B", marginLeft: 130 }}
        />
      </S.ModalContent>

      <S.ModalFooter>
        <Button className="cancel-btn" onClick={closeAndClearModal}>
          Cancelar
        </Button>
        <Button
         loading={loadingBtn}
         onClick={() => handleSendBatch()}
         disabled={file === null}
         type="primary">
          Criar o lote
        </Button>
      </S.ModalFooter>
    </Modal>
    { numLote && openModalSuccess &&  (
      <Modal
      title={titleModal[0].toUpperCase() + titleModal.toLowerCase().substr(1)}
      width={600}
      visible={openModalSuccess}
      onCancel={closeAuxModal}
      footer={null}
      >
      <S.ModalContent>
      <CheckCircleOutlined style={{ fontSize: "30px", color: "#008000", marginLeft: 30}} />
      <b style={{ fontSize: "20px", marginLeft: 10}}>
        O lote foi criado com sucesso: código {numLote} .</b>
      </S.ModalContent>
    </Modal>
   )}
   </>
  );
}

export default memo(ModalSendArqLote);
