import styled from 'styled-components';

export const WrapperHeaderSituacao = styled.div`
  .modal-active-fatura {
    width: 100%;
    padding: 24px;

    display: flex;

    &__left {
      width: 90%;
      display: flex;
    }

    &__right {
      width: 10%;
      text-align: center;

      svg {
        font-size: 22px;
        color: #bfbfbf;
      }
    }

    .modal-active-fatura__alert {
      width: 22px;
      font-size: 22px;
      margin-right: 16px;
      color: #52c41a;
    }

    .modal-active-fatura__text {
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
`;
