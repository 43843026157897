import React from "react"

import { HomeFilled } from "@ant-design/icons"

import * as S from "../style"

const BreadCrumbNavigation = ({tableName, preTableName="Configurações"}) => {
  return (
       <S.BreadCrumbPage>
        <p>
          <HomeFilled /> / {preTableName} / <span>{tableName}</span>
        </p>
      </S.BreadCrumbPage>
  )
}

export default BreadCrumbNavigation;
