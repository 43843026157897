import styled from 'styled-components';

export const ModalTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  border-bottom: 1px solid #ccc;

  h1 {
    font-size: 17px;
    line-height: 28px;
    font-weight: bold;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #ccc;
    cursor: pointer;
    margin-bottom: 4px;
  }
`;

export const CreditCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  max-height: 500px;
  padding: 10px;
  background: #f5f5f5;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #b2bec3;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 15px;
`;

export const CreditCardTitle = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 10px;

  span {
    margin-right: 10px;
  }
  & > span:nth-child(1) {
    color: #52c41a;
    border: 1px solid #52c41a;
    padding: 5px;
    display: flex;
    justify-items: center;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  & > button:nth-child(2) {
    background: #fa8c16;
    color: #fff;
    border: 1px solid #fa8c16;
    padding: 5px;
    display: flex;
    justify-items: center;
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  & > button:nth-child(3) {
    background: #1890ff;
    color: #fff;
    border: 1px solid #1890ff;
    padding: 5px;
    display: flex;
    justify-items: center;
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
`;
export const CreditCardTitleMoreOption = styled.div`
  display: flex;
  width: 20%;
  flex: 1;
  justify-content: flex-end;
  align-content: center;
  justify-items: center;

  span {
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
  }
`;

export const CreditCardBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CreditCardRow = styled.div`
  margin: 5px 0;
  max-width: 100%;
  word-wrap: break-word;

  span {
    margin: 0 5px;
  }

  .credit-card-physical {
    margin-left: 15px;
    background: #e6f7ff;
    color: #1890ff;
    border: 1px solid #1890ff;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-embossing {
    margin-left: 250px;
    background: #f5f5f5;
    color: #6f5f4f;
    border: 0.5px solid #6f5f4f;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-virt {
    margin-left: 15px;
    background: #e6f7ff;
    color: #52c41a;
    border: 1px solid #52c41a;
    padding: 2px;
    font-size: 10px;
  }

  .credit-card-transition {
    margin-left: 4px;
    background: #fff;
    color: #c4801a;
    border: 1px solid #c4801a;
    padding: 2px;
    font-size: 10px;
  }
`;

export const WrapperSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  span {
    margin-bottom: 5px;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  h3 {
    width: 100%;
    text-align: center;
    background: #fa8c16;
    color: #fff;
    border-radius: 10px;
    padding: 5px 0;
  }
`;

export const ModalRow = styled.div`
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  margin-top: 10px;

  .btns-send {
    width: 100%;

    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px 24px;

      background: #faad14;
      color: #fff;
      border: 1px solid #bfbfbf;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      &:first-of-type {
        margin-right: 5px;
        background: #ffffff;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
`;
