const customerStatus = (status) => {
  // Possíveis status:
  // -  LISTA_NEGRA = "L";
  // -  CLIENTE_ATIVO = "A";
  // -  CLIENTE_INATIVO = "I";
  // -  CLIENTE_CANCELADO = "C";
  // -  CLIENTE_BLOQUEADO = "B";
  switch (status) {
    case 'L':
      return 'Lista de bloqueio';
    case 'A':
      return 'Ativo';
    case 'I':
      return 'Inativo';
    case 'C':
      return 'Cancelado';
    case 'B':
      return 'Bloqueado';
    default:
      return '-';
  }
};

module.exports = {
  customerStatus,
};
