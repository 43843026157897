import React from "react";

import * as S from "./style";

function ActiveStatus({ status }) {
  return (
    <S.ActiveStatus>{status}</S.ActiveStatus>
  );
}

export default ActiveStatus;
