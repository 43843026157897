import React, { memo, useCallback, useRef, useState } from 'react';

import * as AntIcon from '@ant-design/icons';
import { Form } from '@unform/web';
import { Button, Select } from 'antd';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaRegCreditCard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { usePermissions } from '../../Context/permissionContext';
import { useAuth } from '../../hooks/AuthProvider';
import { useClient } from '../../hooks/ClientProvider';
import ModalMissingData from '../../pages/home/modals/modalMissingData';
import api from '../../services/api';
import { capitalize } from '../../utils/capitalize';
import { customerStatus } from '../../utils/customerStatus';
import getValidationErrors from '../../utils/getValidationErros';
import { notifyError } from '../../utils/notifications';
import { validaCpf, validarCNPJ } from '../../utils/validations';
import Can from '../Can';
import Input from '../Input';
import * as S from './style';

const { Option } = Select;

function ModalAttend({ attendanceStartedSetter, ...props }) {
  const { updateClient } = useClient();
  const { user } = useAuth();
  const { permissions } = usePermissions();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentDoc, setCurrentDoc] = useState('cpf');
  const [currentMask, setCurrentMask] = useState('999.999.999-99');
  const [currentClient, setCurrentClient] = useState('titular');
  const [showProducts, setShowProducts] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [showInit, setShowInit] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [clientProducts, setClientProducts] = useState([]);
  const [clientFound, setClientFound] = useState({});
  const [clientInformation, setClientInformation] = useState({});

  const [dataQuestions, setDataQuestions] = useState(null);
  const [q1, setQ1] = useState('');
  const [q2, setQ2] = useState('');
  const [product, setProduct] = useState({});
  const [isMissingDataModalVisible, setIsMissingDataModalVisible] =
    useState(false);
  const [idClient, setIdClient] = useState(0);
  const [cardSelected, setCardSelected] = useState();

  const handleQ1 = useCallback((event) => {
    const { value } = event.target;
    setQ1(value);
  }, []);

  const handleQ2 = useCallback((event) => {
    const { value } = event.target;
    setQ2(value);
  }, []);

  const handleCli = useCallback((cli) => {
    setCurrentClient(cli);
    setCurrentDoc(cli);
  }, []);

  const handleTab = useCallback((doc) => {
    setShowClient(false);
    setShowQuestions(false);
    if (doc === 'cpf') setCurrentMask('999.999.999-99');
    if (doc === 'cnpj') setCurrentMask('99.999.999/9999-99');
    if (doc === 'cartao') setCurrentMask('9999 9999 9999 9999 999');
    if (doc === 'client_id') setCurrentMask('99999999999999999');
    setCurrentDoc(doc);
  }, []);

  const handleGoBack = useCallback(() => {
    if (showProducts) setShowProducts(false);
    if (showClient) setShowClient(false);
    if (showQuestions) setShowQuestions(false);
    if (disabledInput) setDisabledInput(false);
    attendanceStartedSetter(false);
  }, [
    disabledInput,
    attendanceStartedSetter,
    showClient,
    showProducts,
    showQuestions,
  ]);

  const handleMissingData = useCallback(() => {
    handleGoBack();
    if (showInit) setShowInit(false);
    setIsMissingDataModalVisible(true);
  }, [handleGoBack, showInit]);

  const handleProduct = useCallback(
    async (value) => {
      if (value !== 'none') {
        setCardSelected(value);
        const productSelectedArr = clientProducts.filter(
          (cp) => cp.id === Number(value)
        );
        const productSelected = productSelectedArr[0];

        setProduct(productSelected);
        setShowClient(true);
        setShowQuestions(true);

        const clientId = productSelected?.idTitular ?? productSelected?.id;

        try {
          const response = await api.get('client_id', {
            client_id: clientId,
            product_id: productSelected.codigoProduto,
          });
          if (productSelected.idTitular) {
            response.data.adicional?.map((aditional) => {
              if (aditional.id === productSelected.id) {
                setDataQuestions(aditional.nascimento);
              }
              return true;
            });
          }

          await setClientInformation(response.data.information);

          delete response.data.information;
          await setClientFound(response.data);
        } catch (err) {
          notifyError('Erro ao selecionar produto', err.message);
        }
      }
    },
    [clientProducts]
  );

  const handleAttend = useCallback(async () => {
    try {
      setLoading(true);
      const birthdayArr = dataQuestions
        ? dataQuestions.split('-')
        : clientFound.birthday.split('-');
      // const monthArr = clientFound.mesNascimento;

      if (permissions.includes('view_birth_date')) {
        if (typeof Number(q1) === 'number' && !Number.isNaN(Number(q1))) {
          if (Number(q1) === 10) {
            if (Number(birthdayArr[1]) !== Number(q1))
              throw new Error('Pergunta 1 não confere');
          } else if (Number(birthdayArr[1].replace('0', '')) !== Number(q1))
            throw new Error('Pergunta 1 não confere');
        } // else if (!monthArr.includes(q1.toLowerCase().trim()))
        // throw new Error("Pergunta 1 não confere");
        if (birthdayArr[0] !== q2) throw new Error('Pergunta 2 não confere');
      }

      if (clientFound.endereco_alternativo) {
        clientFound.endereco_alternativo.bairro = capitalize(
          clientFound.endereco_alternativo.bairro
            ? clientFound.endereco_alternativo.bairro
            : ''
        );
        clientFound.endereco_alternativo.localidade = capitalize(
          clientFound.endereco_alternativo.localidade
            ? clientFound.endereco_alternativo.localidade
            : ''
        );
        clientFound.endereco_alternativo.cidade = capitalize(
          clientFound.endereco_alternativo.cidade
            ? clientFound.endereco_alternativo.cidade
            : ''
        );
        clientFound.endereco_alternativo.logradouro = capitalize(
          clientFound.endereco_alternativo.logradouro
            ? clientFound.endereco_alternativo.logradouro
            : ''
        );
        clientFound.endereco_alternativo.complemento = capitalize(
          clientFound.endereco_alternativo.complemento
            ? clientFound.endereco_alternativo.complemento
            : ''
        );
      }

      if (clientFound.endereco_comercial) {
        clientFound.endereco_comercial.empresa = capitalize(
          clientFound.endereco_comercial.empresa
            ? clientFound.endereco_comercial.empresa
            : ''
        );
        clientFound.endereco_comercial.departamento = capitalize(
          clientFound.endereco_comercial.departamento
            ? clientFound.endereco_comercial.departamento
            : ''
        );
        clientFound.endereco_comercial.bairro = capitalize(
          clientFound.endereco_comercial.bairro
            ? clientFound.endereco_comercial.bairro
            : ''
        );
        clientFound.endereco_comercial.localidade = capitalize(
          clientFound.endereco_comercial.localidade
            ? clientFound.endereco_comercial.localidade
            : ''
        );
        clientFound.endereco_comercial.cidade = capitalize(
          clientFound.endereco_comercial.cidade
            ? clientFound.endereco_comercial.cidade
            : ''
        );
        clientFound.endereco_comercial.logradouro = capitalize(
          clientFound.endereco_comercial.logradouro
            ? clientFound.endereco_comercial.logradouro
            : ''
        );
        clientFound.endereco_comercial.complemento = capitalize(
          clientFound.endereco_comercial.complemento
            ? clientFound.endereco_comercial.complemento
            : ''
        );
      }
    if (clientFound.endereco_residencial) {
      clientFound.endereco_residencial.bairro = capitalize(
        clientFound.endereco_residencial.bairro
          ? clientFound.endereco_residencial.bairro
          : ''
      );
      clientFound.endereco_residencial.localidade = capitalize(
        clientFound.endereco_residencial.localidade
          ? clientFound.endereco_residencial.localidade
          : ''
      );
      clientFound.endereco_residencial.cidade = capitalize(
        clientFound.endereco_residencial.cidade
          ? clientFound.endereco_residencial.cidade
          : ''
      );
      clientFound.endereco_residencial.logradouro = capitalize(
        clientFound.endereco_residencial.logradouro
          ? clientFound.endereco_residencial.logradouro
          : ''
      );
      clientFound.endereco_residencial.complemento = capitalize(
        clientFound.endereco_residencial.complemento
          ? clientFound.endereco_residencial.complemento
          : ''
      );
    }
      clientFound.name = capitalize(clientFound.name);

      updateClient({
        client: clientFound,
        product,
        information: clientInformation,
      });
      setTimeout(() => {
        navigate('/atendimento');
        props.close(false);
        attendanceStartedSetter(false);
      }, 1000);
    } catch (err) {
      setLoading(false);
      notifyError('Responda as perguntas corretamente', err.message);
    }
  }, [
    attendanceStartedSetter,
    clientFound,
    navigate,
    product,
    props,
    q1,
    q2,
    updateClient,
  ]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          doc: Yup.string()
            .required(`${currentDoc.toUpperCase()} obrigatório`)
            .test('test_cpf', `Informe um ${currentDoc} válido`, (value) => {
              if (currentDoc === 'client_id') return true;
              if (currentDoc === 'cpf') {
                const cpfFormated = value.split('.').join('').replace('-', '');
                return validaCpf(cpfFormated);
              }
              if (currentDoc === 'cartao') {
                const cardFormated = value
                  .replace(/[./-]/g, '')
                  .replace(/\s/g, '');
                 let cardSize = 16;
                if( cardFormated.length === 19) cardSize = 19;
                return cardFormated.length === cardSize;
                      // return validaCartao(cardFormated);
              }
              const cnpjFormated = value
                .split('.')
                .join('')
                .replace('-', '')
                .replace('/', '');
              return validarCNPJ(cnpjFormated);
            }),
        });

        await schema.validate(data, { abortEarly: false });
        setLoading(true);
        let response = '';
        if (currentClient === 'adicional') {
          if (currentDoc === 'cpf') {
            response = await api.get(`client_${currentDoc}_dependants`, {
              [`${currentDoc}`]: data.doc
                .replace(/[./-]/g, '')
                .replace(/\s/g, ''),
            });
          }
          if (currentDoc === 'cartao') {
            response = await api.get(`client_${currentDoc}`, {
              [`${currentDoc}`]: data.doc
                .replace(/[./-]/g, '')
                .replace(/\s/g, ''),
            });

            response = await api.get(`client_cpf_dependants`, {
              cpf: response.data[0].cpf,
            });
          }
        } else {
          if (currentDoc === 'client_id') {
            response = await api.get(`get_client_by_id`, {
              [`${currentDoc}`]: data.doc,
            });
          } else {
            response = await api.get(`client_${currentDoc}`, {
              [`${currentDoc}`]: data.doc
                .replace(/[./-]/g, '')
                .replace(/\s/g, ''),
            });
          }

          if (currentDoc === 'cartao') {
            response = await api.get(`client_cpf`, {
              cpf: response.data[0].cpf,
            });
          }
        }

        if (!response.data[0]) throw new Error('Cliente não encontrado');
        setIdClient(response.data[0].id);
        setClientProducts(response.data);
        attendanceStartedSetter(true);

        setTimeout(() => {
          setLoading(false);
          setShowProducts(true);
          setDisabledInput(true);
        }, 1000);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        notifyError(err.message);
      }
    },
    [attendanceStartedSetter, currentDoc]
  );

  const handleTypeClient = useCallback(
    (cli) => {
      handleCli(cli);
    },
    [handleCli]
  );

  const handleCPF = useCallback(() => {
    handleTab('cpf');
  }, [handleTab]);

  const handleCNPJ = useCallback(() => {
    handleTab('cnpj');
  }, [handleTab]);

  const handleCard = useCallback(() => {
    handleTab('cartao');
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab('client_id');
  }, [handleTab]);

  const onCloseModal = useCallback(() => {
    setIsMissingDataModalVisible(false);
  }, []);

  function setPlaceholder() {
    const text = 'Digite o CPF, CNPJ ou RG';
    if (currentDoc === 'client_id') {
      return 'Digite o número do ID do Cliente';
    } if (user?.allowFetchByCard) {
      return 'Digite o CPF, CNPJ, RG ou Nº do Cartão';
    }
    return text;
  }

  return (
    <>
      {showInit && (
        <S.ModalContainer>
          <S.ModalTitle>
            <h1>Encontrar cliente</h1>
            <div>
              <AntIcon.CloseOutlined onClick={props.close} />
            </div>
          </S.ModalTitle>
          <S.ModalContent>
            {!showProducts && showInit && (
              <S.ModalFieldSelect>
                <div>
                  <b>Cliente:</b>
                </div>
                <S.CpfCnpj>
                  <S.DocButton
                    selected={currentClient === 'titular'}
                    onClick={() => handleTypeClient('titular')}
                  >
                    Titular
                  </S.DocButton>
                  <S.DocButton
                    selected={currentClient === 'adicional'}
                    onClick={() => handleTypeClient('adicional')}
                  >
                    Adicional
                  </S.DocButton>
                </S.CpfCnpj>
                <div>
                  <b>Tipo: </b>
                </div>
                <S.CpfCnpj>
                  <S.DocButton
                    selected={currentDoc === 'cpf'}
                    onClick={handleCPF}
                  >
                    CPF
                  </S.DocButton>
                  {currentClient === 'titular' && (
                    <S.DocButton
                      selected={currentDoc === 'cnpj'}
                      onClick={handleCNPJ}
                    >
                      CNPJ
                    </S.DocButton>
                  )}
                  {user?.allowFetchByCard && (
                    <S.DocButton
                      selected={currentDoc === 'cartao'}
                      onClick={handleCard}
                    >
                      Cartão
                    </S.DocButton>
                  )}
                  {currentClient === 'titular' && (
                    <Can permission="personal_data">
                      <S.DocButton
                        selected={currentDoc === 'client_id'}
                        onClick={handleClientId}
                      >
                        ID Cliente
                      </S.DocButton>
                    </Can>
                  )}
                </S.CpfCnpj>
              </S.ModalFieldSelect>
            )}

            <S.SearchContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <S.InputContainer>
                  <div>
                    <b>
                      {currentDoc === 'client_id' ? 'ID Cliente' : 'Número'}
                    </b>
                  </div>
                  <Input
                    placeholder={setPlaceholder()}
                    mask={currentMask}
                    disabled={disabledInput}
                    name="doc"
                    errorDown
                  />
                </S.InputContainer>

                {showProducts && (
                  <S.InputContainer>
                    <div>
                      <b>Produto</b>
                    </div>

                    {clientProducts && clientProducts.length <= 2 ? (
                      <>
                        {clientProducts.map((cp) => (
                          <S.CardItem
                            value={cp.id}
                            id={cp.id}
                            key={cp.id}
                            onClick={() => handleProduct(cp.id)}
                            className={`${
                              cp.id === cardSelected ? 'active-item' : ''
                            }`}
                          >
                            <div className="icon-check">
                              <BsCheckCircleFill color="#FFC53D" />
                            </div>
                            <p className="title">
                              <FaRegCreditCard />
                              {cp.desc_prod}
                            </p>
                            <p>
                              {cp.cartoes.map((cartao) => (
                                <>
                                  <span>
                                    {cartao.numeroMascarado} (
                                    {cartao.virtual ? 'Virtual' : 'Físico'}) -{' '}
                                    {cartao.status.replace(/_/g, ' ')}
                                  </span>
                                  <br />
                                </>
                              ))}
                            </p>
                          </S.CardItem>
                        ))}
                      </>
                    ) : (
                      <Select id="products_list" onChange={handleProduct}>
                        <Option value="none" key="none">
                          Selecione o produto
                        </Option>
                        {clientProducts.map((cp) => (
                          <Option value={cp.id} id={cp.id} key={cp.id}>
                            <b>{cp.desc_prod}</b>
                            <div>
                              {cp.cartoes.map((cartao) => (
                                <>
                                  <span>
                                    {cartao.numeroMascarado} (
                                    {cartao.virtual ? 'Virtual' : 'Físico'}) -{' '}
                                    {cartao.status.replace(/_/g, ' ')}
                                  </span>
                                  <br />
                                </>
                              ))}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    )}
                  </S.InputContainer>
                )}

                {showClient && (
                  <S.ClientContainer>
                    <S.ClientTitle>
                      <S.Title>Cliente Encontrado</S.Title>
                    </S.ClientTitle>
                    <S.ClientDataContainer>
                      <S.ClientInputs>
                        <div>
                          <span>Nome</span>
                          <Input
                            placeholder="Nome"
                            name="name"
                            disabled
                            value={clientFound.name ? clientFound.name : ''}
                          />
                        </div>
                        <div>
                          <span>CPF</span>
                          <Input
                            placeholder="CPF"
                            name="cpf"
                            disabled
                            value={clientFound.cpf ? clientFound.cpf : ''}
                          />
                        </div>
                      </S.ClientInputs>
                      <S.ClientInputs>
                        <div>
                          <span>Email</span>
                          <Input
                            placeholder="E-mail"
                            name="email"
                            disabled
                            value={clientFound.email ? clientFound.email : ''}
                          />
                        </div>
                      </S.ClientInputs>
                      <S.ClientInputs>
                        <div>
                          <span>Status do Cliente</span>
                          <Input
                            placeholder="Status do Cliente"
                            name="clientStatus"
                            disabled
                            value={
                              clientFound.statusCliente
                                ? customerStatus(clientFound.statusCliente)
                                : ''
                            }
                          />
                        </div>
                      </S.ClientInputs>
                      <S.ClientInputs>
                        {clientFound.statusCliente === 'B' &&
                          clientFound.ultimoMotivoBloqueioCliente !== null && (
                            <div>
                              <span>Motivo do Bloqueio</span>
                              <Input
                                placeholder="Motivo de bloqueio"
                                name="BlockStatus"
                                disabled
                                value={clientFound?.ultimoMotivoBloqueioCliente}
                              />
                            </div>
                          )}
                      </S.ClientInputs>
                      {!!clientFound?.diasAtrasoTotal && (
                        <S.ClientInputs>
                          <div>
                            <span>Dias de atraso total</span>
                            <Input
                              disabled
                              name="diasAtrasoTotal"
                              value={clientFound?.diasAtrasoTotal}
                            />
                          </div>
                        </S.ClientInputs>
                      )}
                    </S.ClientDataContainer>

                    {showQuestions &&
                      permissions.includes('view_birth_date') && (
                        <S.ClientDataContainer>
                          <S.ClientInputs>
                            <div>
                              <span>Mês de nascimento do {currentClient}:</span>
                              <Input
                                name="q1"
                                onChange={handleQ1}
                                placeholder="Ex.: 01"
                              />
                            </div>
                            <div>
                              <span>Ano de nascimento do {currentClient}:</span>
                              <Input
                                mask="9999"
                                name="q2"
                                onChange={handleQ2}
                                placeholder="Ex.: 2022"
                              />
                            </div>
                          </S.ClientInputs>
                        </S.ClientDataContainer>
                      )}
                  </S.ClientContainer>
                )}

                <S.ButtonContainer>
                  <div className="btns-first">
                    {showProducts && (
                      <Button id="goBack" type="button" onClick={handleGoBack}>
                        Voltar
                      </Button>
                    )}
                    {showProducts && showClient && showQuestions ? (
                      <Button
                        htmlType="button"
                        loading={loading}
                        type="primary"
                        onClick={handleAttend}
                        disabled={!clientFound.name}
                      >
                        Atender
                      </Button>
                    ) : (
                      <Button
                        htmlType="submit"
                        loading={loading}
                        type="primary"
                      >
                        Buscar
                      </Button>
                    )}
                  </div>
                  {showProducts && (
                    <div className="div-full">
                      <Button
                        id="missingData"
                        onClick={handleMissingData}
                        type="link"
                      >
                        Finalizar por falta de informações
                      </Button>
                    </div>
                  )}
                </S.ButtonContainer>
              </Form>
            </S.SearchContainer>
          </S.ModalContent>
        </S.ModalContainer>
      )}
      <ModalMissingData
        open={isMissingDataModalVisible}
        idClient={idClient}
        setShowInit={setShowInit}
        closeModal={onCloseModal}
      />
    </>
  );
}

export default memo(ModalAttend);
