const ATIVO = 'ATIVO';
const INATIVO = 'INATIVO';
const BLOQUEADO = 'BLOQUEADO';
const BLOQUEADO_DEFINITIVO = 'BLOQUEADO_DEFINITIVO';
const CANCELADO = 'CANCELADO';
const PENDENTE_TROCA = 'PENDENTE_TROCA';
const PENDENTE_CONFIRMACAO_TROCA = 'PENDENTE_CONFIRMACAO_TROCA';
const ERRO = 'ERRO';
const CARTAO_VALIDO = 'CARTAO_VALIDO';
const CARTAO_INVALIDO = 'CARTAO_INVALIDO';
const CARTAO_INVALIDO_ERP = 'CARTAO_INVALIDO_ERP';
const CARTAO_BLOQUEADO = 'CARTAO_BLOQUEADO';
const CARTAO_MANUAL = 'CARTAO_MANUAL';
const CARTAO_GERADO = 'CARTAO_GERADO';
const CARTAO_TROCA = 'CARTAO_TROCA';

const allCardStatus = [
  ATIVO,
  INATIVO,
  BLOQUEADO,
  BLOQUEADO_DEFINITIVO,
  CANCELADO,
  PENDENTE_TROCA,
  PENDENTE_CONFIRMACAO_TROCA,
  ERRO,
  CARTAO_VALIDO,
  CARTAO_INVALIDO,
  CARTAO_INVALIDO_ERP,
  CARTAO_BLOQUEADO,
  CARTAO_MANUAL,
  CARTAO_GERADO,
  CARTAO_TROCA,
];

const invalidCardStatus = [INATIVO, CANCELADO, CARTAO_INVALIDO];

module.exports = {
  allCardStatus,
  invalidCardStatus,
};
