import React, { memo, useEffect, useRef, useState } from 'react';

import Card from '../../../../components/Card';
import LoadingElevaty from '../../../../components/LoadingElevaty';
import PurchaseTimeline from '../../../../components/PurchasesTimeline';
import { useClient } from '../../../../hooks/ClientProvider';
import useFutureInvoices from '../../../../hooks/useFutureInvoices';
import * as S from '../../style';

function PurchaseTimelineCard() {
  const { client } = useClient();

  const { futureInvoices, loading } = useFutureInvoices(client?.client?.id);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref?.current?.clientHeight]);

  return (
    <S.WrapperPurchases ref={ref}>
      {loading ? (
        <S.WrapperNewLoading >
          <LoadingElevaty />
        </S.WrapperNewLoading>
      ) : (
        <Card title="Lançamentos Futuros" modalTitle="Compras" loading={loading}>
          <PurchaseTimeline
            invoices={futureInvoices}
            height={futureInvoices && futureInvoices.length > 1 ? 240 : 173}
            loading={loading}
          />
        </Card>
      )}
    </S.WrapperPurchases>
  );
}

export default memo(PurchaseTimelineCard);
