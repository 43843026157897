import React, { useRef, useEffect } from 'react';

import { Button, Space, Checkbox } from 'antd';

import * as S from "./style";

const UniqueDataDropdown = ({
  isVisible,
  onClose,
  currentFilter,
  handleFilteredData,
  setSearchClientText,
  searchClientText,
  options
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleFilteredData(searchClientText);
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isVisible, onClose]);

    const handleClearFilter = () => {
      setSearchClientText(prevObj => ({
        ...prevObj,
        [currentFilter]: []
      }))
    };

    const handleCheckboxChange = (checkedValues) => {
        setSearchClientText(prevObj => ({
          ...prevObj,
          [currentFilter]: checkedValues
        }));
    };

    const handleSearch = () => {
        onClose();
        handleFilteredData(searchClientText);
    }

    const handleEnterPress = (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    }

  const filterActiveKeys = (options) => {
    return Object.keys(Object.fromEntries(
      Object.entries(options)
        .filter(([key, value]) => value > 0)
    ));
  };


  return (
   <S.FilterContainer ref={dropdownRef}>
    <div className='inner-container'>
         <Checkbox.Group
          className='antd-checkbox-group'
          onKeyDown={handleEnterPress}
          onChange={handleCheckboxChange}
          options={Object.entries(options).map(option => option[0])}
          defaultValue={filterActiveKeys(options)}
          value={searchClientText[currentFilter]}
          />
        <div className="delimiter" />
        <Space className="line-space">
          <Button
            className="reset-button"
            size="small"
            onClick={handleClearFilter}
            disabled={!searchClientText[currentFilter].length}
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={handleSearch}
          >
            Ok
          </Button>
        </Space>
    </div>
   </S.FilterContainer>
  );
}

export default UniqueDataDropdown;
