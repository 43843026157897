import styled, { css } from "styled-components";

export const ModalFaturaSend = styled.div`
  width: 100%;

  .modal-fatura__loading {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-select__options {
    width: 100%;
    display: flex;
  }

  .item-select__user {
    width: 100%;
    &:not(:first-child) {
      margin-top: 1rem;
    }

    p.title {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  width: 100%;

  height: 48px;
  border-radius: 2px;
  font-weight: bold;

  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }

  ${(props) =>
    props.selected
      ? css`
          background: #ffb32c;
          border: 1px solid #ffb32c;
          color: #434343;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}
`;
export const FormWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .error-message {
      width: 100%;
      background: #f8d7da;
      border: 1px solid #f1aeb5;
      color: #58151c;
      margin-bottom: 5px;
      border-radius: 5px;
      padding: 15px;
    }

    .item-input {
      position: relative;
      input {
        width: 100%;
        padding: 8px 12px;
        margin-bottom: 24px;
        border-radius: 2px;
        border: 1px solid var(--neutral-5, #d9d9d9);
        background: var(--neutral-color-gray-1, #fff);

        font-size: 16px;
        outline: 0;
        caret-color: #ffb32c;
        color: #434343;

        &:disabled {
          background: #ccc !important;
          opacity: 0.4;
          cursor: no-drop;

          &::placeholder {
            color: #000;
          }
        }

        &.error-id {
          border: 1px solid #ff4d4f;
        }

        &::placeholder {
          color: #bfbfbf;
          font-weight: 400;
        }
      }

      .ant-checkbox-wrapper {
        width: 100%;
        margin-bottom: 16px;
      }
      .ant-select {
        &.ant-select-disabled {
          .ant-select-selector {
            background: #ccc !important;
            opacity: 0.4;
          }
        }

        &.error-id {
          .ant-select-selector {
            border: 1px solid #ff4d4f;
          }
        }

        .ant-select-arrow {
          top: 35% !important;
        }
        .ant-select-selector {
          width: 100%;
          /* height: 24px; */
          height: 47px;
          padding: 8px 12px;
          margin-bottom: 24px;
          border-radius: 2px;
          border: 1px solid var(--neutral-5, #d9d9d9);
          background: var(--neutral-color-gray-1, #fff);

          font-size: 16px;
          outline: 0;
          caret-color: #ffb32c;
          color: #434343;

          &.error {
            border-color: #ff4d4f;
          }

          &::placeholder {
            color: #bfbfbf;
            font-weight: 400;
          }
        }
      }

      .error-msg {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 0.875rem;
        color: #ff4d4f;
      }
    }

    .item-submit {
      width: 100%;
      margin-top: 16px;
      border-top: 1px solid #d9d9d9;
      padding-top: 16px;

      display: flex;
      justify-content: flex-end;

      button {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;

        border-radius: 2px;
        border: 1px solid var(--brand-color-gold-6, #ffb32c);
        background: var(--brand-color-gold-6, #ffb32c);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

        svg {
          margin-right: 8px;
          font-size: 19px;
        }

        p {
          padding: 0;
          margin: 0;
          color: var(--neutral-color-gray-9, #434343);
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
`;
