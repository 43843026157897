import React, { useCallback, useState } from "react";

import { Modal } from "antd";
import moment from "moment";
import { FaUsers, FaPlus, FaRegEye } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import { capitalize } from "../../../utils/capitalize";
import { customerStatus } from "../../../utils/customerStatus";
import useCheckPermission from "../../../utils/useCheckPermission";
import AddCardModal from "../../creditCard/modals/addCard";
import * as Style from "../style";

function TabAdicional({ client, grauParentesco }) {
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const hasPermissionView = useCheckPermission("view_personal_data");
  const addAdditionalPermission = useCheckPermission("permission_add_additionals");


  const toggleAddModal = useCallback(() => {
    setIsAddModalVisible((state) => !state);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={`content-info ${
          client && client.client.adicional.length > 0 ? "userGrid" : ""
        }`}
      >
        {client && client.client.adicional.length > 0 ? (
          <>
            <div className="content-info-grid-users">
              {client.client.adicional.slice(0, 2).map((userAditional) => {
                const formatedDate = moment
                  .parseZone(userAditional.nascimento)
                  .format("DD/MM/YYYY");
                return (
                  <div className="grid-user__item" key={userAditional.id}>
                    <div className="client-id">
                      <p>
                        #{userAditional.id}{" "}
                        {userAditional.grauParentesco &&
                          `- ${grauParentesco[userAditional.grauParentesco]}`}
                      </p>
                    </div>
                    <div className="client-name">
                      {userAditional.nome && (
                        <p>{capitalize(userAditional.nome)}</p>
                      )}

                      <span
                        className={customerStatus(
                          userAditional.statusCartao
                        ).toLocaleLowerCase()}
                      >
                        {customerStatus(userAditional.statusCartao)}
                      </span>
                    </div>
                    {hasPermissionView && (
                      <div
                        className={`client-more-infos ${
                          showMoreItems ? "active" : ""
                        }`}
                        >
                        <p>
                          <span>CPF:</span>{" "}
                          {userAditional.cpf.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          )}
                        </p>
                        <p>
                          <span>Nasc.:</span> {formatedDate}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className="tab-right__item-right"
              style={{ marginTop: "20px" }}
            >
              <div
                className={`client-edit-options  ${
                  showMoreItems ? "active" : ""
                }`}
              >
                {client.client?.adicional.length > 2 && (
                  <button
                    type="button"
                    aria-label="btn-email"
                    className="edit"
                    onClick={showModal}
                  >
                    <span>
                      <FaRegEye /> Ver todos ({client.client?.adicional.length})
                    </span>
                  </button>
                )}
                { addAdditionalPermission && (
                  <button
                    type="button"
                    aria-label="btn-email"
                    onClick={toggleAddModal}
                  >
                    <span>
                      <FaPlus /> Adicionar
                    </span>
                  </button>
                )}
              </div>
            </div>

            <div className="btn-show-more">
              <button
                type="button"
                aria-label="btn-show-more"
                onClick={() => setShowMoreItems(!showMoreItems)}
              >
                <p>
                  <MdOutlineKeyboardArrowDown
                    className={showMoreItems && "active"}
                  />{" "}
                  {showMoreItems ? "Mostrar menos" : "Mostrar mais"}
                </p>
              </button>
            </div>
          </>
        ) : (
          <div className="no-users">
            <p>
              <FaUsers /> Nenhum adicional cadastrado{" "}
            </p>
            { addAdditionalPermission && (
              <button
                type="button"
                aria-label="btn-add-new"
                onClick={toggleAddModal}
              >
                <span>
                  <FaPlus /> Adicionar adicional
                </span>
              </button> )
            }
          </div>
        )}
      </div>

      {isAddModalVisible && <AddCardModal closeModal={toggleAddModal} />}

      <Modal
        title="Adicional"
        visible={isModalOpen}
        onCancel={handleCancel}
        width={1000}
      >
        <Style.ModalUsers>
          <div className="modal-scroll-user">
            {client.client.adicional.map((userAditional) => {
              const formatedDate = moment
                .parseZone(userAditional.nascimento)
                .format("DD/MM/YYYY");
              return (
                <div className="content-user" key={userAditional.id}>
                  <div className="content-user__id">
                    <p>
                      #{userAditional.id}{" "}
                      {userAditional.grauParentesco &&
                        `- ${grauParentesco[userAditional.grauParentesco]}`}
                    </p>
                  </div>

                  <div className="content-user__name">
                    {userAditional.nome && (
                      <p>{capitalize(userAditional.nome)}</p>
                    )}

                    <span
                      className={customerStatus(
                        userAditional.statusCartao
                      ).toLocaleLowerCase()}
                    >
                      {customerStatus(userAditional.statusCartao)}
                    </span>
                  </div>
                  {hasPermissionView && (
                    <div className="content-user__infos">
                      <p>
                        <span>CPF:</span>{" "}
                        {userAditional.cpf.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                          "$1.$2.$3-$4"
                        )}
                      </p>
                      <p>
                        <span>Nasc.:</span> {formatedDate}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Style.ModalUsers>
      </Modal>
    </>
  );
}

export default TabAdicional;
