import moment from "moment";
import * as Yup from "yup";

import { validaRg } from "../utils/validations";
import { ufs } from "./ufs";

const ERROR = {
    REQUIRED: "Campo Obrigatório",
    POSITIVE: "O valor tem que ser um número positivo",
    EMAIL: "Deve ser um e-mail válido",
    CNPJ: "CNPJ Inválido",
    CPF: "CPF Inválido",
    RG: "RG Inválido",
    TEL: "Telefone Inválido",
    CEL: "Celular Inválido",
    CEP: "CEP Inválido",
    UF: "UF Inválido",
    NIVER: "Data de Nascimento Inválido",
  };

export const schemaResidencial = Yup.object().shape({
    numero: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complemento: Yup.string().required("Informe o complemento"),
    bairro: Yup.string().required("Bairro obrigatório"),
    cep: Yup.string().required("CEP obrigatório"),
    localidade: Yup.string().required("Cidade obrigatório"),
    uf: Yup.string().required("UF obrigatório"),
    logradouro: Yup.string().required("Logradouro obrigatório")
  });

  export const schemaResidencialAlternativo = Yup.object().shape({
    numero: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complemento: Yup.string().required("Informe o complemento"),
    bairro: Yup.string().required("Bairro obrigatório"),
    cep: Yup.string().required("CEP obrigatório"),
    localidade: Yup.string().required("Cidade obrigatório"),
    uf: Yup.string().required("UF obrigatório"),
    logradouro: Yup.string().required("Logradouro obrigatório"),
    numerotitular_adicional: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complementotitular_adicional: Yup.string().required("Informe o complemento"),
    bairrotitular_adicional: Yup.string().required("Bairro obrigatório"),
    ceptitular_adicional: Yup.string().required("CEP obrigatório"),
    localidadetitular_adicional: Yup.string().required("Cidade obrigatório"),
    uftitular_adicional: Yup.string().required("UF obrigatório"),
    logradourotitular_adicional: Yup.string().required("Logradouro obrigatório")
  });

  export const schemaResidencialComercial = Yup.object().shape({
    numero: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complemento: Yup.string().required("Informe o complemento"),
    bairro: Yup.string().required("Bairro obrigatório"),
    cep: Yup.string().required("CEP obrigatório"),
    localidade: Yup.string().required("Cidade obrigatório"),
    uf: Yup.string().required("UF obrigatório"),
    logradouro: Yup.string().required("Logradouro obrigatório"),
    numerotitular_comercial: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complementotitular_comercial: Yup.string().required("Informe o complemento"),
    bairrotitular_comercial: Yup.string().required("Bairro obrigatório"),
    ceptitular_comercial: Yup.string().required("CEP obrigatório"),
    localidadetitular_comercial: Yup.string().required("Cidade obrigatório"),
    uftitular_comercial: Yup.string().required("UF obrigatório"),
    logradourotitular_comercial: Yup.string().required("Logradouro obrigatório"),
    departamentotitular_comercial: Yup.string().required("Departamento obrigatório"),
    empresatitular_comercial: Yup.string().required("Empresa obrigatória"),
  });

  export const schemaResidencialComercialAlternativo = Yup.object().shape({
    numero: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complemento: Yup.string().required("Informe o complemento"),
    bairro: Yup.string().required("Bairro obrigatório"),
    cep: Yup.string().required("CEP obrigatório"),
    localidade: Yup.string().required("Cidade obrigatório"),
    uf: Yup.string().required("UF obrigatório"),
    logradouro: Yup.string().required("Logradouro obrigatório"),
    numerotitular_comercial: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complementotitular_comercial: Yup.string().required("Informe o complemento"),
    bairrotitular_comercial: Yup.string().required("Bairro obrigatório"),
    ceptitular_comercial: Yup.string().required("CEP obrigatório"),
    localidadetitular_comercial: Yup.string().required("Cidade obrigatório"),
    uftitular_comercial: Yup.string().required("UF obrigatório"),
    logradourotitular_comercial: Yup.string().required("Logradouro obrigatório"),
    departamentotitular_comercial: Yup.string().required("Departamento obrigatório"),
    empresatitular_comercial: Yup.string().required("Empresa obrigatória"),
    numerotitular_adicional: Yup.number()
    .typeError("Informe o número corretamente")
    .required("Informe o número")
    .integer("Informe o número corretamente"),
    complementotitular_adicional: Yup.string().required("Informe o complemento"),
    bairrotitular_adicional: Yup.string().required("Bairro obrigatório"),
    ceptitular_adicional: Yup.string().required("CEP obrigatório"),
    localidadetitular_adicional: Yup.string().required("Cidade obrigatório"),
    uftitular_adicional: Yup.string().required("UF obrigatório"),
    logradourotitular_adicional: Yup.string().required("Logradouro obrigatório")
  });