import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';

import Priority from '../../../components/Priority';
import Status, { STATUSES } from '../../../components/Status';
import { useClient } from '../../../hooks/ClientProvider';
import { SolicitationService } from '../../../services/solicitationService';
import {
  formatCPF,
  formatCreditCard,
  formatDate,
  formatDateTime,
} from '../../../utils/formatter';
import ChangeSolicitationForm from './ChangeSolicitationForm';
import CreateSolicitationForm from './CreateSolicitationForm';
import * as S from './style';

function SolicitationModal({
  onCancel,
  handleSubmit,
  solicitation,
  isLoading,
  nextStatus,
}) {
  const { client } = useClient();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const title = useMemo(() => {
    if (nextStatus === STATUSES.CANCELLED) {
      return 'Cancelar Solicitação';
    }
    if (nextStatus === STATUSES.RESOLVED) {
      return 'Finalizar Solicitação';
    }

    if (nextStatus) {
      return 'Visualizar';
    }

    return 'Cadastro de Solicitação';
  }, [nextStatus]);

  const onSubmit = useCallback(
    (form) => {
      handleSubmit({ ...form, files });
    },
    [handleSubmit, files]
  );

  useEffect(() => {
    if (solicitation?.id) {
      SolicitationService.attachments(solicitation.id).then((response) => {
        setAttachments(response.data);
      });
    }
  }, [solicitation]);

  // eslint-disable-next-line max-len
  const shouldShowUpdateForm = useMemo(
    () =>
      solicitation &&
      (nextStatus === STATUSES.CANCELLED || nextStatus === STATUSES.RESOLVED),
    [nextStatus, solicitation]
  );
  const shouldShowCreateForm = useMemo(() => !nextStatus, [nextStatus]);

  return (
    <Modal title={title} visible onCancel={onCancel}>
      <S.ContainerCreateEdit>
        <S.ContentInfo>
          <S.Row>
            <div>
              <S.ContentInfoLabel>Nome do Cliente</S.ContentInfoLabel>
              <div>{solicitation?.client_name || client?.client?.name}</div>
            </div>

            <div>
              <S.ContentInfoLabel>CPF</S.ContentInfoLabel>
              <div>
                {formatCPF(solicitation?.client_cpf || client?.client?.cpf)}
              </div>
            </div>
          </S.Row>

          <S.Row>
            {client && (
              <div>
                <S.ContentInfoLabel>Cartão</S.ContentInfoLabel>
                <div>
                  {formatCreditCard(client?.information?.numeroCartaoTruncado)}
                </div>
              </div>
            )}
            <div>
              <S.ContentInfoLabel>Protocolo</S.ContentInfoLabel>
              <div>
                {solicitation?.ticket_protocol ||
                  client?.client?.ticket?.protocol}
              </div>
            </div>
          </S.Row>
          <S.Row>
            <div>
              <S.ContentInfoLabel>Data Nascimento</S.ContentInfoLabel>
              <div>
                {formatDate(
                  solicitation?.client_birthday || client?.client?.birthday
                )}
              </div>
            </div>
          </S.Row>
          {solicitation && (
            <>
              <S.Row>
                <div>
                  <S.ContentInfoLabel>Status</S.ContentInfoLabel>
                  <Status status={solicitation.status} />
                </div>
                <div>
                  <S.ContentInfoLabel>Prioridade</S.ContentInfoLabel>
                  <Priority priority={solicitation.priority} />
                </div>
              </S.Row>
              <div>
                <S.ContentInfoLabel>Eventos</S.ContentInfoLabel>
                <S.Table>
                  {solicitation?.statuses.map(
                    ({ status, created_at, description }) => (
                      <tr>
                        <td>
                          <Status status={status} />
                        </td>
                        <td>
                          <Tooltip
                            placement="top"
                            title={formatDateTime(created_at)}
                          >
                            <S.DescriptionInfo>{description}</S.DescriptionInfo>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  )}
                </S.Table>
              </div>
              {attachments.length > 0 && (
                <div>
                  <S.ContentInfoLabel>Arquivos</S.ContentInfoLabel>
                  <div>
                    {attachments.map(({ file_name, url, user }) => (
                      <S.AttachmentRow>
                        <S.AttachmentIcon>
                          <LinkOutlined />
                        </S.AttachmentIcon>
                        <Tooltip
                          placement="top"
                          title={`Usuário: ${user.username}`}
                        >
                          <a href={url} target="_blank">
                            {file_name}
                          </a>
                        </Tooltip>
                      </S.AttachmentRow>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </S.ContentInfo>

        {shouldShowUpdateForm && (
          <ChangeSolicitationForm
            nextStatus={nextStatus}
            onFinish={onSubmit}
            isLoading={isLoading}
            setFiles={setFiles}
            onCancel={onCancel}
          />
        )}
        {shouldShowCreateForm && (
          <CreateSolicitationForm
            onFinish={onSubmit}
            isLoading={isLoading}
            setFiles={setFiles}
            onCancel={onCancel}
          />
        )}
      </S.ContainerCreateEdit>
    </Modal>
  );
}

export default memo(SolicitationModal);
