import React, { forwardRef, Ref, useCallback, useImperativeHandle, useState, memo, useEffect } from 'react';

import { List, Modal } from 'antd';

import { useClient } from '../../../hooks/ClientProvider';
import Api from '../../../services/api';
import { formatDateTime } from '../../../utils/formatter';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const HistoryBlockModal = forwardRef(({ isModalVisible, closeModal, card }, ref) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const isPch = JSON.parse(
    localStorage.getItem("@SnxPayment:isPch")
  );

  const { client } = useClient();

  const codigoProduto = client?.product?.codigoProduto;
  const cardId = card?.id || card?.card?.card?.id;
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await Api.get('card_block_history', {
        card_id: cardId,
        cod_product: codigoProduto,
      });
      const ajusteArray = data
        .sort((a, b) => new Date(b.dataBloqueio) - new Date(a.dataBloqueio))
        .splice(0, 5);
      await setItems(ajusteArray);
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  }, [cardId, codigoProduto]);

  useImperativeHandle(ref, () => ({
    getData,
  }))

  useEffect(() => {
      if(!isPch) getData();
  }, [isModalVisible,isPch]);

  return (
    <Modal
      title="Histórico de Bloqueio"
      visible={isModalVisible}
      onCancel={closeModal}
    >
      <S.WrapperSelectBlock>
        <h2>Histórico de Bloqueio</h2>
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
      {loading && (
        <S.WrapperNewLoading>
          <LoadingElevaty width="30px" height="30px" duration="durFast" />
        </S.WrapperNewLoading>
      )}
        {!loading && <List
          bordered
          dataSource={items}
          renderItem={(item) => (
            <List.Item>
              <S.ListDate>
                <span>Data de bloqueio:</span>
                {item.dataBloqueio && (
                  <b>{formatDateTime(new Date(item.dataBloqueio))}</b>
                )}
              </S.ListDate>
              <S.ListDescription>
                <span>Observação:</span>
                <b>{item.observacao}</b>
              </S.ListDescription>
              <S.ListDate>
                <span>Data de desbloqueio:</span>
                {item.dataDesbloqueio && (
                  <b>{formatDateTime(new Date(item.dataDesbloqueio))}</b>
                )}
              </S.ListDate>
              <S.ListDescription>
                <span>Observação Desbloqueio:</span>
                <b>{item.observacaoDesbloqueio}</b>
              </S.ListDescription>
              <S.TypeBlock>
                <span>Tipo de bloqueio:</span>
                <b>{item.tipoBloqueioCartao.descricao}</b>
              </S.TypeBlock>
            </List.Item>
          )}
        />}
      </S.WrapperSelectBlock>
    </Modal>
  );
});
export default memo(HistoryBlockModal);
