import React from 'react';

import { Table } from 'antd';

import { formatDate } from '../../../../../../../../utils/formatter';
import Section from '../../../../components/Section';

function InstallmentOptionsPreview({
  installmentOptions,
  onInstallmentOptionClick,
}) {
  const columns = [
    {
      title: 'Entrada (R$)',
      key: 'valorEntrada',
      render: (row) => row.valorEntrada.toFixed(2),
    },
    {
      title: 'Parcelas (R$)',
      key: 'quantidadeParcelas',
      render: (row) =>
        `(${row && row.quantidadeParcelas - 1}x) ${row?.valorParcela.toFixed(
          2
        )}`,
    },
    {
      title: 'Total parcelado (R$)',
      key: 'valorTotal',
      render: (row) => row.valorTotal.toFixed(2),
    },
    {
      title: 'IOF aplicado (R$)',
      key: 'valorIof',
      render: (row) => row.valorIof.toFixed(2),
    },
    {
      title: 'Taxa de Juros (%a.m.)',
      key: 'taxaJuros',
      render: (row) => row.taxaJuros.toFixed(2),
    },
    {
      title: 'CET (%a.a.)',
      key: 'cet',
      render: (row) => row.cet.toFixed(2),
    },
    {
      title: 'Data recomendada de ativação',
      key: 'dataRecomendavelAtivacao',
      render: (row) => formatDate(row.dataRecomendavelAtivacao),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) =>
      onInstallmentOptionClick(selectedRows),
  };

  if (!installmentOptions?.length) {
    return null;
  }

  return (
    <Section title="Simulação de parcelamento">
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={columns}
        pagination={false}
        scroll={{ y: 240 }}
        rowKey="quantidadeParcelas"
        dataSource={installmentOptions}
      />
    </Section>
  );
}

export default React.memo(InstallmentOptionsPreview);
