import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PageInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 102px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  margin-left: 2%;
  margin-top: 15px;
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 20px;
`;

export const SearchContainer = styled.div`
  margin-top: 1%;
  background-color: white;
  width: 98%;
  height: 80px;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 13px;
  }
`;

export const SearchContentInputs = styled.div`
  margin-left: 1%;
  display: flex;
  align-items: center;
  justify-content: center;

  .email_input {
    width: 500px;
  }

  span {
    margin: 0px 10px;
  }
`;

export const SearchContentButtons = styled.div`
  width: 23%;
  @media (min-width: 1500px) {
    width: 16%;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1%;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
  }

  span {
    color: var(--primaryColor);
  }
`;

export const CleanButton = styled.button`
  background: #ffffff;
  padding: 5px 16px;
  width: 136px;
  height: 32px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin: 0px 8px;
`;

export const UserContainer = styled.div`
  margin-top: 1%;
  background-color: white;
  width: 100%;
  height: 70%;
  @media (min-width: 1500px) {
    height: 75%;
  }
  display: flex;
  flex-direction: column;
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 100%;
  max-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableTitle = styled.div`
  margin-right: 1%;
  display: flex;
  flex-direction: row;
`;

export const TableButtons = styled.div`
  margin-right: 1%;
  display: flex;
  flex-direction: row;

  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 12px;
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
  }
`;

export const TableContainer = styled.div`
  margin-left: 1%;
  align-items: center;
  justify-content: center;
  width: 98%;
  overflow:visible;

  .ant-pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  button + button {
    margin-left: 10px;
  }

  button {
    width: 35%;
    border-radius: 4px;
    margin-block: 10px;
  }

  .confirm, .cancel {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 0;
    }
  }

  .confirm {
    background: #ff9c00;
  }
`;

export const UserDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const LabelField = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
  margin: 0px 4px;
  width: 150px;

  p {
    color: #ff4d4f;
    margin: 3px 4px;
    font-size: 20px;
    line-height: 22px;
    text-align: right;
  }
`;

export const UserContainerDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span {
    color: black;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

export const StatusActive = styled.div`
  display: flex;
  padding: 5px;
  width: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid #27ae60;
  color: #27ae60;
`;

export const StatusInactive = styled.div`
  display: flex;
  padding: 5px;
  width: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid tomato;
  color: tomato;
`;
