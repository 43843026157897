import React, { useMemo, useRef , useEffect, useState } from "react";

import { Button, Select } from "antd";

import api from "../../../../../services/api";
import { notifySucces } from "../../../../../utils/notifications";

const { Option } = Select;

function ClassificationProcess({ relateSubClassificationFn }) {
  const subClassificationsCache = useRef({});
  const [issuerClassificationsOptions, setIssuerClassificationsOptions] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState(null);

  async function fetchSubClassificationsFromClassification(classificationId) {
    const subClassifications = await api.get(`subclassifications/classifications/${classificationId}`);
    return subClassifications.data;
  }

  async function onClassificationSelection(classificationId) {
    const cachedSubclassifications = subClassificationsCache.current;
    if (cachedSubclassifications[classificationId] === undefined) {
      const newSubclassifications = await fetchSubClassificationsFromClassification(classificationId)
      cachedSubclassifications[classificationId] = newSubclassifications;
    }
    setSelectedClassification(classificationId);
  }

  async function fetchAllIssuerClassifications() {
    const issuerClassifications = await api.get("group");
    return issuerClassifications.data;
  }

  function extractActiveIssuerClassifications(classifications) {
    return classifications.filter(cls => cls.deleted_at == null);
  }

  async function handleMultipleSubClassificationRelations() {
    const selectedClassificationName = issuerClassificationsOptions.filter(cls => cls.id === Number(selectedClassification))[0]?.name;
    const subClassificationsToRelate = subClassificationsCache.current[selectedClassification];

    const registrationResults = await Promise.all(subClassificationsToRelate.map(subCls => relateSubClassificationFn(subCls)));
    const registeredSubClassificationsCounter = registrationResults.filter(didRegistred => didRegistred).length;
    notifySucces(`${registeredSubClassificationsCounter} de ${subClassificationsToRelate.length} Sub-Classificações cadastradas da classificação "${selectedClassificationName}"`);
  }

  useEffect(() => {
    const setupClassifications = async () => {
      const classifications = await fetchAllIssuerClassifications();
      const activeClassifications = extractActiveIssuerClassifications(classifications);
      setIssuerClassificationsOptions(activeClassifications);
    };

    setupClassifications()
  }, []);

  return (
    <>
      <Select
        onChange={onClassificationSelection}
        showSearch
        optionFilterProp="children"
        placeholder="Selecione uma classificação"
        notFoundContent="Nenhum registro encontrado para esta busca!"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {issuerClassificationsOptions.map(classification => (
          <Option
            key={classification.id}
            valueId={classification.id}
            valueName={classification.name}
          >
            {classification.name}
          </Option>
        ))
        }
      </Select>
      <Button onClick={handleMultipleSubClassificationRelations} type="primary">
        Relacionar
      </Button>
    </>
  )
}

export default React.memo(ClassificationProcess);