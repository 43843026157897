import React from 'react';

import { MdOutlineCheckCircleOutline, MdClose } from 'react-icons/md';

import * as Style from './styles';

function ContentModalActiveFaturaDigitalSms({ closeModal }) {
  return (
    <Style.WrapperHeaderSituacao>
      <div className="modal-active-fatura">
        <div className="modal-active-fatura__left">
          <div className="modal-active-fatura__alert">
            <MdOutlineCheckCircleOutline />
          </div>

          <div className="modal-active-fatura__text">
            <p>Fatura digital por SMS ativada</p>
            <span>
              A fatura por SMS foi ativada com sucesso para este celular
            </span>
          </div>
        </div>
        <div className="modal-active-fatura__right">
          <MdClose onClick={() => closeModal()} />
        </div>
      </div>
    </Style.WrapperHeaderSituacao>
  );
}

export default ContentModalActiveFaturaDigitalSms;
