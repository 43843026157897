import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "@unform/web";
import { Modal, Select } from "antd";

import { ufs } from "../../../helpers/ufs";
import Input from "../../Input";
import Loading from "../../Loading";
import { Option } from "../../Select";
import * as Style from "../style";

function ModalEndAlternativo({
  formRef,
  modalAlternativoAddress,
  closeModalAlternativoAddress,
  handleSubmitAlternativoAddress,
  newAdressAlternative,
  handleNewAdress,
  handleCepEndereçoAlternativo,
  loadingEnderecoAlternativo,
  infosEnderecoAlternativo,
  setInfosEnderecoAlternativo,
  loading,
}) {
  return (
    <Modal
      title="Adicionar Endereço Alternativo"
      visible={modalAlternativoAddress}
      onCancel={closeModalAlternativoAddress}
    >
      <Form ref={formRef} onSubmit={(e) => handleSubmitAlternativoAddress(e)}>
        <Style.InputsContainer>
          <Style.Inputs>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>Tipo</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Select
                  defaultValue={newAdressAlternative}
                  onChange={(value) => handleNewAdress(value)}
                >
                  <Option value="R">Residencial</Option>
                  <Option value="C">Comercial</Option>
                </Select>
              </Style.InputsRow>
            </Style.InputsContent>
          </Style.Inputs>

          <Style.Inputs>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>CEP</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Input
                  name="cep"
                  mask="99999-999"
                  onChange={(e) => handleCepEndereçoAlternativo(e)}
                />
                {loadingEnderecoAlternativo && <LoadingOutlined />}
              </Style.InputsRow>
            </Style.InputsContent>
          </Style.Inputs>

          <Style.Inputs>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>Endereço</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Input
                  name="address"
                  defaultValue={infosEnderecoAlternativo.logradouro}
                  onChange={(e) =>
                    setInfosEnderecoAlternativo({
                      ...infosEnderecoAlternativo,
                      logradouro: e.target.value,
                    })
                  }
                />
              </Style.InputsRow>
            </Style.InputsContent>
          </Style.Inputs>

          <Style.Inputs>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>Número</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Input name="number" />
              </Style.InputsRow>
            </Style.InputsContent>

            <Style.InputsContent>
              <Style.InputsLabel>
                <span>Complemento</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Input name="complement" />
              </Style.InputsRow>
            </Style.InputsContent>
          </Style.Inputs>

          <Style.Inputs>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>Bairro</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Input
                  name="neighborhood"
                  defaultValue={infosEnderecoAlternativo.bairro}
                  onChange={(e) =>
                    setInfosEnderecoAlternativo({
                      ...infosEnderecoAlternativo,
                      bairro: e.target.value,
                    })
                  }
                />
              </Style.InputsRow>
            </Style.InputsContent>
          </Style.Inputs>

          <Style.Inputs>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>Cidade</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Input
                  name="city"
                  defaultValue={infosEnderecoAlternativo.localidade}
                  onChange={(e) =>
                    setInfosEnderecoAlternativo({
                      ...infosEnderecoAlternativo,
                      localidade: e.target.value,
                    })
                  }
                />
              </Style.InputsRow>
            </Style.InputsContent>
            <Style.InputsContent>
              <Style.InputsLabel>
                <span>UF</span>
              </Style.InputsLabel>
              <Style.InputsRow>
                <Select
                  className="select-uf-alternativo"
                  defaultValue={infosEnderecoAlternativo.uf}
                  onChange={(value) => setInfosEnderecoAlternativo({
                    ...infosEnderecoAlternativo,
                    uf: value,
                  })}
                  showSearch
                  placeholder="UF"
                >
                    {ufs.map((value) => (
                      <Option key={`end-alternativo-uf-${value}`} value={value}>{value}</Option>
                    ))}
                </Select>
              </Style.InputsRow>
            </Style.InputsContent>
          </Style.Inputs>
          <Style.ModalButtonContainer>
            {loading ? <Loading /> : <button type="submit">Cadastrar</button>}
          </Style.ModalButtonContainer>
        </Style.InputsContainer>
      </Form>
    </Modal>
  );
}

export default ModalEndAlternativo;
