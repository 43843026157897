import React, { useEffect, useRef } from 'react';


import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';

import * as S from "./style";

function InputDropdown({
  labelName,
  isVisible,
  onClose,
  currentFilter,
  handleFilteredData,
  setSearchClientText,
  searchClientText,
  tableName,
  mask
}) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleFilteredData(searchClientText);
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isVisible, onClose]);

    const handleClearFilter = () => {
    setSearchClientText(prevObj => ({
      ...prevObj,
      [currentFilter]: ""
    }))
   };

    const handleSearch = (e) => {
    setSearchClientText(prevObj => ({
      ...prevObj,
      [currentFilter]: mask ? mask(e.target.value) : e.target.value
    }))
  };

    const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      onClose();
      handleFilteredData(searchClientText);
    }
  }

    return (
    <S.FilterContainer ref={dropdownRef}>
      <div className='inner-container'>
        <Input
          className="antd-input"
          placeholder={`Procure pelo ${labelName}`}
          value={mask ? mask(searchClientText[currentFilter]) : searchClientText[currentFilter]}
          onKeyDown={handleEnterPress}
          onChange={handleSearch}
          key={labelName + tableName}
        />
        <Space>
          <Button
            type="primary"
            className='antd-button'
            icon={<SearchOutlined />}
            size="small"
            onClick={() => {
              onClose();
              handleFilteredData(searchClientText);
            }}
          >
            Procurar
          </Button>
          <Button
            size="small"
            className='antd-button'
            onClick={handleClearFilter}
          >
            Apagar
          </Button>
        </Space>
      </div>
    </S.FilterContainer>
  );
}

export default React.memo(InputDropdown);
