import React from 'react';

import Modal from 'react-modal';

import ContentModalSituacao from './ContentModalSituacao';

function ModalSituacao({ statusOpen, closeModal, handleSituacao }) {
  return (
    <Modal
      isOpen={statusOpen}
      onRequestClose={closeModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-situacao"
    >
      <ContentModalSituacao
        closeModal={closeModal}
        handleSituacao={handleSituacao}
      />
    </Modal>
  );
}

export default ModalSituacao;
