import React, { memo, useCallback, useEffect, useState } from "react";

import { Menu, Modal, Tooltip} from "antd";

import { useClient } from "../../../../../hooks/ClientProvider";
import { InvoiceInstallmentService } from "../../../../../services/invoiceInstallmentService";
import { notifyError } from "../../../../../utils/notifications";
import InstallmentAnticipationList from "./InstallmentAnticipationList";
import Parcelamento from "./InstallmentAnticipationList/model/Parcelamento";

const { Item } = Menu;

function InvoiceInstallmentAnticipationModal() {
  const [isOpen, setOpen] = useState(false);
  const { client } = useClient() as any;
  const clientId = client.client.id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Parcelamento[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activePayment, setActivePayment]= useState(true);

  const onCloseClick = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpenClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await InvoiceInstallmentService.active({
        clientId,
      });
      setData(response.data.parcelamentos);
      setOpen(true);
    } catch (err) {
      notifyError(
        "Não há nenhum parcelamento de fatura ativo e não-faturado para este portador."
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const verifyActivePayment = async () => {
    try {
      const response = await InvoiceInstallmentService.active({
        clientId,
      });
      setData(response.data.parcelamentos);
      if(response?.data?.parcelamentos){
        setActivePayment(false);
      }
    }catch (err) {
      setActivePayment(true);
    }
  }

  useEffect(() => {
    verifyActivePayment();
  }, [activePayment]);

  return (
    <>
      <Item disabled={activePayment} key="4" onClick={onOpenClick}>
        <Tooltip
            title={activePayment && 'Cliente não possui Parcelamento Ativo'}
          >
            <span>
              {isLoading ? "Carregando..." : "Visualizar Parcelamento Ativo"}
            </span>
        </Tooltip>
      </Item>
      {isOpen && (
        <Modal
          centered
          width="80%"
          visible
          onCancel={onCloseClick}
          title="Visualizar Parcelamento Ativo"
        >
          <InstallmentAnticipationList
            onCloseClick={onCloseClick}
            parcelamentos={data}
          />
        </Modal>
      )}
    </>
  );
}

export default memo(InvoiceInstallmentAnticipationModal);
