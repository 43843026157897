import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/* eslint-disable radix */

function getFormatDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

function getFormatDateAndTime(date) {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const zonedDate = zonedTimeToUtc(date, browserTimezone);

  return format(zonedDate, 'dd/MM/yyyy HH:mm', { timeZone: browserTimezone });
}

function getFormatDateAndTimeNoTz(date) {
  date = date.replace('Z','');
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

function getFormatCalendar(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

function getFormatDateTime(dateString) {
  const regex = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2})$/;
  return dateString.replace(regex, '$3/$2/$1 ($4:$5)');
}

function getFormatData(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

function getFormatFilterTimeline(date, time) {
  if (time === 'start') {
    let start = format(new Date(date), 'yyyy-MM-dd');
    start += ' 00:00:00.000 -0300';
    return start;
  }
  let end = format(new Date(date), 'yyyy-MM-dd');
  end += ' 23:59:59.000 -0300';
  return end;
}

function getFormatTime(date) {
  return format(new Date(date), 'HH:mm');
}

function getFormatDateReverse(date) {
  return date?.split('-').reverse().join('/');
}

export {
  getFormatDate,
  getFormatDateAndTimeNoTz,
  getFormatDateAndTime,
  getFormatCalendar,
  getFormatData,
  getFormatTime,
  getFormatDateTime,
  getFormatDateReverse,
  getFormatFilterTimeline,
};
