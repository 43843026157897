function formatClientName(name) {
  const lowerCaseWords = ['de', 'da', 'do', 'dos', 'das', 'e'];

  return name.toLowerCase().split(' ').map((word, index) => {
    if (index !== 0 && lowerCaseWords.includes(word)) return word;
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}

module.exports = {
  formatClientName
};
