import React, { useCallback } from 'react';

import { Button, Modal } from 'antd';

import Loading from '../../../components/Loading';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import * as S from '../style';

function DeleteUser({
  modalUserPhone,
  setModalUserPhone,
  auxUser,
  loading,
  setLoading,
  fetchUser,
}) {
  const deleteUser = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete('user', { user_id: auxUser.id });
      setModalUserPhone(false);
      notifySucces('Usuário desativado');
      fetchUser();
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
      notifyError('Erro ao desativar usuário', err.message);
      return false;
    }
  }, [auxUser.id, fetchUser, setLoading, setModalUserPhone]);

  return (
    <Modal
      title="Desativar usuário"
      visible={modalUserPhone}
      onCancel={() => setModalUserPhone(false)}
    >
      <S.UserContainerDelete>
        <span>Tem certeza que deseja desativar o usuário?</span>
        <S.UserDelete>{auxUser.username}</S.UserDelete>
        <S.ModalButtonContainer>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Button type="button" className='cancel' onClick={() => setModalUserPhone(false)}>
                Cancelar
              </Button>
              <Button type="button" className='confirm' onClick={deleteUser}>
                Confirmar
              </Button>
            </>
          )}
        </S.ModalButtonContainer>
      </S.UserContainerDelete>
    </Modal>
  );
}

export default React.memo(DeleteUser);
