import React, { useEffect, useState } from 'react';

import { useClient } from '../../../../../../../hooks/ClientProvider';
import { InvoiceInstallmentService } from '../../../../../../../services/invoiceInstallmentService';
import ClientInfo from './ClientInfo';
import InstallmentOptionsPreview from './InstallmentOptionsPreview';

function StepOneOption({ className, setSelectedInstallmentOption }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { client } = useClient();

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response = await InvoiceInstallmentService.list({
          clientId: client.client.id,
        });
        setData(response);
      } catch (err) {
        console.error('Error to list installments', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [client]);

  return (
    <div className={className}>
      <ClientInfo />
      <InstallmentOptionsPreview
        isLoading={isLoading}
        data={data}
        setSelectedInstallmentOption={setSelectedInstallmentOption}
      />
    </div>
  );
}

export default React.memo(StepOneOption);
