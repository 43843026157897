import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';

import AntInput from '../../../../components/AntInput';
import api from '../../../../services/api';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import * as S from '../style';

function ModalInstallmentLimit({
  openModalinstallment,
  setOpenModalinstallment,
  selectedGroupId,
}) {
  const [installmentLimit, setInstallmentLimit] = useState([]);
  const [newLimit, setNewLimit] = useState('');

  useEffect(async () => {
    if (openModalinstallment) {
      const response = await api.get('getInstallment', {
        role_id: selectedGroupId,
      });
      await setInstallmentLimit(response.data.installment_limit);
    }
  }, [openModalinstallment]);

  async function handleApplyLimit(value) {
    await setNewLimit(value);
  }

  async function handleApplyClickLimit() {
    try {
      await api.put('update_installment', {
        role_id: selectedGroupId,
        installment: Number(newLimit),
      });

      const response = await api.get('getInstallment', {
        role_id: selectedGroupId,
      });

      setInstallmentLimit(response.data.installment_limit);
      notifySucces('Limite de parcelamento aplicado com sucesso');
      setOpenModalinstallment(false);
    } catch (error) {
      notifyError(error.message);
      setOpenModalinstallment(false);
    }
  }

  return (
    <Modal
      visible={openModalinstallment}
      title="Opções de parcelamento"
      width={600}
      height={600}
      closeModal={() => setOpenModalinstallment(false)}
      onCancel={() => setOpenModalinstallment(false)}
    >
      <S.ModalContainer>
        <S.ModalRow>
          <S.ContainerSelect>
            <AntInput
              type="number"
              min="0"
              max="48"
              placeholder={
                installmentLimit === null
                  ? 'Nenhum limite de parcelamento aplicado'
                  : `Parcelamento atual de ${installmentLimit}`
              }
              onChange={(e) => handleApplyLimit(e.target.value)}
            />
            <Button onClick={handleApplyClickLimit} type="primary">
              Aplicar
            </Button>
          </S.ContainerSelect>
        </S.ModalRow>
      </S.ModalContainer>
    </Modal>
  );
}

export default ModalInstallmentLimit;
