import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ModalRow = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  .confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff9c00;

    span {
      margin: 0;
    }
  }

  .confirm:hover {
    color: #000000d9;
  }
`;

export const FuncionalityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px 0;
`;

export const FuncionalityTitle = styled.div`
  display: flex;
  flex: 1;
  font-weight: 700;
  border-bottom: 1px solid #f5f5f5;
  font-size: 20px;
`;

export const FuncionalitySwitch = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 20px;
  width: 100px;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin-bottom: 8px;
  }
`;

export const StatusActive = styled.div`
  display: flex;
  padding: 5px;
  width: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid #27ae60;
  color: #27ae60;
`;

export const StatusInactive = styled.div`
  display: flex;
  padding: 5px;
  width: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid tomato;
  color: tomato;
`;

export const PageInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 800px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const ContainerSelect = styled.div`
  display: flex;
  width: -webkit-fill-available;
  padding: 0px 0px 8px;
`;

export const FuncionalitySwitchLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 20px;
  width: auto;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin-bottom: 8px;
  }
`;
