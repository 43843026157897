import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Modal, Select, Skeleton } from 'antd';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import * as Yup from 'yup';

import { useAuth } from '../../../hooks/AuthProvider';
import api from '../../../services/api';
import { getPlaces } from '../../../utils/getStores';
import { notifyError, notifySucces } from '../../../utils/notifications';
import { validaCpf } from '../../../utils/validations';
import * as S from './style';

const { Option } = Select;

function PayModal({ onCancel }) {

  const { user } = useAuth();

  const [lojas, setLojas] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState(false);

  const [dataUser, setDataUser] = useState([]);
  const [currentDoc, setCurrentDoc] = useState('cpf');
  const [listClientAccount, setListClientAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [currentMask, setCurrentMask] = useState('999.999.999-99');
  const [idClient, setIdClient] = useState(0);
  const [clientProducts, setClientProducts] = useState([]);
  const [accountProducts, setAccountProducts] = useState([]);
  const [formSteps, setFormSteps] = useState(0);
  const [product, setProduct] = useState({});
  const [hasClickButton,setHasClickButton] = useState(false);
  const [nameClient, setNameClient] = useState('');
  const [filteredStores, setFilteredStores] = useState();
  const [loadingStores, setLoadingStores] = useState(false);
  const handleTab = useCallback((doc) => {
    if (doc === 'cpf') setCurrentMask('999.999.999-99');
    if (doc === 'cnpj') setCurrentMask('99.999.999/9999-99');
    if (doc === 'cartao') setCurrentMask('9999 9999 9999 9999 999');
    if (doc === 'client_id') setCurrentMask('99999999999999999');
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab('cpf');
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab('client_id');
  }, [handleTab]);

  const getLojas = useCallback(async () => {
    try {
      const places = await getPlaces(user);
      const editList = places
        .filter((a) => a.id >= 1)
        .map((item) => {
          return {
            value: item.id,
            label: `${item.nomeFantasia || item.razaoSocial}${item.idExterno ? ` - ID Externo: ${item.idExterno}` : ''}`,
            cnpj: item.cnpj,
          };
        });

      setLojas(editList);
    } catch (error) {
      console.error('Falha ao listar lojas');
    }
  }, []);

  function setPlaceholder() {
    let text = '';
    if (currentDoc === 'cpf') text = 'do CPF';
    if (currentDoc === 'cnpj') text = 'do CNPJ';
    if (currentDoc === 'cartao') text = 'do Cartão';
    if (currentDoc === 'client_id') text = 'do ID do Cliente';

    return `Digite o número ${text}`;
  }

  const fetchUser = useCallback(async () => {
    try {
      const response = await api.get('getUser');
      const aux = response.data.map((r) => {
        r.key = r.id;
        r.status = r.deleted_at ? 'Inativo' : 'Ativo';
        return r;
      });
      setDataUser(aux);
    } catch (err) {
      notifyError('Erro ao listar usuários', err.message);
    }
  }, []);

  const handleTransaction = async (values) => {
    let dataHoraTerminal = moment().format('YYYY-MM-DDTHH:mm:ss');
    dataHoraTerminal += 'Z';

    let cnpjPlace = lojas.filter(
      (pl) => Number(pl.value) === Number(values.loja)
    );
    cnpjPlace = cnpjPlace[0].cnpj;
    await setHasClickButton(true);
    try {
      if (selectedAccount == null || selectedAccount === '0') return notifyError('Informe a conta do portador');
      await api.post('make_payment_manual', {
        client_id: idClient,
        card_id: values?.cartao,
        redeOrigemOperacaoPDV: cnpjPlace,
        dataHoraTerminal,
        valorTransacao: values.transacao,
      });
      notifySucces('Lançamento de pagamento efetuado com sucesso!');
    } catch (error) {
      notifyError('Erro no lançamento de pagamento.');
    }
    finally {
      onCancel();
      setHasClickButton(false);
    }
  };

  const handleAccountSelected = useCallback((value, products) => {
    setIdClient(value);
    setSelectedAccount(value);

    const selectedClient = products && Array.isArray(products) ? products.filter((client) => client.id === value)[0]  : clientProducts.filter((client) => client.id === value)[0];

    if (selectedClient) {
      setAccountProducts([selectedClient]);
      setNameClient(selectedClient.name);
    } else {
      console.error(`Cliente com ID ${value} não encontrado.`);
    }

  }, [clientProducts,nameClient]);

  const handleStepOne = async (values) => {

    try {
      setLoadingBtn(true);

      const response = await api.get('getAccount', {
        dataDocument: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
        document: currentDoc
      });

      if (!Array.isArray(response.data)) {
        response.data = response.data ? [response.data] : [];
      }

      if (!response.data || (Array.isArray(response.data) && response.data.length === 0)) {
        throw new Error("Cliente não encontrado");
      }

    const uniqueAccountSelectList = response.data.map((item) => ({
      id: item.id,
      accountSelect: item.accountSelect,
      name: item.name
    }));

    setClientProducts(response.data);
    setListClientAccount(uniqueAccountSelectList);

    if (uniqueAccountSelectList.length === 1) {
      handleAccountSelected(uniqueAccountSelectList.at(0).id, response.data);
    }

    setTimeout(() => {
      setLoadingBtn(false);
      setFormSteps(1);
    }, 1000);
    } catch (error) {
      setLoadingBtn(false);
      notifyError(error.message);
    }
  };

  const handleProduct = useCallback(
    async (value) => {
      if (value !== 'none') {
        const productSelectedArr = clientProducts.filter(
          (cp) => cp.id === Number(value)
        );
        const productSelected = productSelectedArr[0];

        setLoadingStores(true);

        const filterUserStores = dataUser.filter((item) => item.id === user?.id);

        const editList = productSelected.cartoes
          .filter((a) => a.id >= 1)
          .map((item) => {
            return {
              value: item.id,
              label: `${item.numeroMascarado}  (${item.status}) `,
            };
          });

        if (filterUserStores[0]?.store && filterUserStores[0]?.store.length > 0) {
          const editIDstoFilter = filterUserStores[0].store
            .split(',')
            .map(Number);

          const filterStoresForUser = lojas.filter((store) =>
            editIDstoFilter.includes(store.value)
          );

          setProduct(editList);
          setFilteredStores(filterStoresForUser);

          setTimeout(() => {
            setLoadingStores(false);
          }, 500);
        } else {
          setProduct(editList);
          setFilteredStores(lojas);

          setTimeout(() => {
            setLoadingStores(false);
          }, 500);
        }
      }
    },
    [clientProducts]
  );

  function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(valor);
  }

  const mascaraMoeda = (event, setFieldValue) => {
    const onlyDigits = event.target.value
      .split('')
      .filter((s) => /\d/.test(s))
      .join('')
      .padStart(3, '0');

    const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
    setFieldValue('transacao', maskCurrency(digitsFloat));
  };

  useEffect(() => {
    getLojas();
    fetchUser();
  }, []);



  return (
    <Modal title="Cadastrar Pagamento" visible onCancel={onCancel}>
      <S.ContainerCreateEdit>
        {formSteps === 0 && (
          <Formik
            initialValues={{ doc: '' }}
            validationSchema={Yup.object({
              doc: Yup.string()
                .required(`${currentDoc.toUpperCase()} obrigatório`)
                .test(
                  'test_cpf',
                  `Informe um ${currentDoc} válido`,
                  (value) => {
                    if (currentDoc === 'client_id') return true;
                    if (currentDoc === 'cpf') {
                      if (value === undefined || value === null) return false;

                      const cpfFormated = value
                        .split('.')
                        .join('')
                        .replace('-', '');
                      return validaCpf(cpfFormated);
                    }
                    if (currentDoc === 'cartao') {
                      const cardFormated = value ? value
                        .replace(/[./-]/g, '')
                        .replace(/\s/g, '') : '';
                      let cardSize = 16;
                      if( cardFormated.length === 19) cardSize = 19;

                      return cardFormated.length === cardSize;
                    }
                  }
                ),
            })}
            onSubmit={handleStepOne}
          >
            {() => (
              <Form>
                <S.FormRow>
                  <div>
                    <b>Tipo:</b>
                  </div>
                  <S.CpfCnpj>
                    <S.DocButton
                      selected={currentDoc === 'cpf'}
                      onClick={handleCPF}
                    >
                      CPF
                    </S.DocButton>
                    <S.DocButton
                      selected={currentDoc === 'client_id'}
                      onClick={handleClientId}
                    >
                      ID Cliente
                    </S.DocButton>
                  </S.CpfCnpj>

                  <Field name="doc">
                    {({ field, meta }) => (
                      <ReactInputMask
                        placeholder={setPlaceholder()}
                        type="text"
                        mask={currentMask}
                        maskChar={null}
                        autoComplete="off"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        {...field}
                      />
                    )}
                  </Field>

                  <ErrorMessage name="doc" className="error" component="div" />
                </S.FormRow>

                <S.FormRow>
                  <Button loading={loadingBtn} htmlType="submit" type="primary">
                    Buscar
                  </Button>
                </S.FormRow>
              </Form>
            )}
          </Formik>
        )}

        {formSteps === 1 && (
          <>
            <S.FormRow>
              <div>
                <b>Conta:</b>
              </div>
              <Select
                placeholder="Selecionar conta"
                onChange={handleAccountSelected}
                value={selectedAccount}
              >
                {listClientAccount && listClientAccount.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.accountSelect}
                  </Option>
                ))}
              </Select>
            </S.FormRow>
            {selectedAccount && (
              <>
            <S.FormRow>
              <div>
                <b>Nome Cliente:</b>
              </div>

              <ReactInputMask
                type="text"
                value={nameClient}
                autoComplete="off"
                disabled
                />
            </S.FormRow>
            <S.FormRow>
              <div>
                <b>Produto</b>
              </div>
              <Select id="products_list" onChange={handleProduct}>
                <Option value="none" key="none">
                  Selecione o produto
                </Option>
                {accountProducts && accountProducts.map((cp) => (
                  <Option value={cp.id} id={cp.id} key={cp.id}>
                    <b>{cp?.desc_prod}</b>
                    <div>
                      {cp.cartoes.map((cartao) => (
                        <>
                          <span>
                            {cartao.numeroMascarado} (
                            {cartao.virtual ? 'Virtual' : 'Físico'}) -{' '}
                            {cartao.status.replace(/_/g, ' ')}
                          </span>
                          <br />
                        </>
                      ))}
                    </div>
                  </Option>
                ))}
              </Select>
            </S.FormRow>
            </>)}

            {filteredStores && (
              <>
                <hr />
                {loadingStores ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div style={{ marginTop: 16 }}>
                      <Formik
                        initialValues={{ loja: '', transacao: '', cartao: '' }}
                        validationSchema={Yup.object({
                          loja: Yup.string()
                            .required('Campo obrigatório')
                            .nullable(),
                          transacao: Yup.string()
                            .required('Campo obrigatório')
                            .nullable(),
                          cartao: Yup.string()
                            .required('Campo obrigatório')
                            .nullable(),
                        })}
                        onSubmit={handleTransaction}
                      >
                        {({ setFieldValue, values }) => (
                          <Form>
                            <S.FormRow>
                              <S.FormLabel>
                                <b>Selecionar Cartão</b>
                              </S.FormLabel>

                              <Field name="cartao">
                                {({ field, meta }) => (
                                  <Select
                                    className={`${meta.touched && meta.error
                                        ? 'error-id'
                                        : null
                                      } `}
                                    {...field}
                                    placeholder="Selecione o cartão"
                                    style={{ width: 120 }}
                                    loading={!filteredStores}
                                    options={product}
                                    onChange={(e) => {
                                      setFieldValue('cartao', e);
                                    }}
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="cartao"
                                className="error"
                                component="div"
                              />
                            </S.FormRow>
                            <S.FormRow>
                              <S.FormLabel>
                                <b>Selecionar loja</b>
                              </S.FormLabel>

                              <Field name="loja">
                                {({ field, meta }) => (
                                  <Select
                                    className={`${meta.touched && meta.error
                                        ? 'error-id'
                                        : null
                                      } `}
                                    {...field}
                                    placeholder="Selecione a loja"
                                    style={{ width: 120 }}
                                    loading={!filteredStores}
                                    options={filteredStores}
                                    onChange={(e) => {
                                      setFieldValue('loja', e);
                                    }}
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="loja"
                                className="error"
                                component="div"
                              />
                            </S.FormRow>

                            <S.FormRow>
                              <div>
                                <b>Valor da Transação:</b>
                              </div>

                              <Field name="transacao">
                                {({ field, meta }) => (
                                  <ReactInputMask
                                    placeholder="R$ 99,99"
                                    type="text"
                                    autoComplete="off"
                                    maskChar={false}
                                    className={
                                      meta.touched && meta.error
                                        ? 'error-id'
                                        : null
                                    }
                                    {...field}
                                    value={values.transacao}
                                    onChange={(e) =>
                                      mascaraMoeda(e, setFieldValue)
                                    }
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="transacao"
                                className="error"
                                component="div"
                              />
                            </S.FormRow>

                            <S.FormRow>
                              <S.ButtonContainer>
                                <Button htmlType="submit" disabled={hasClickButton} loading={false}>
                                  Confirmar pagamento
                                </Button>
                              </S.ButtonContainer>
                            </S.FormRow>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </S.ContainerCreateEdit>
    </Modal>
  );
}

export default memo(PayModal);
