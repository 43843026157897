import { WarningOutlined } from '@ant-design/icons';
import { Modal, Menu } from 'antd';
import styled from 'styled-components';

export const ModalViewInsurance = styled(Modal)`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  top: 35px;


  .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.5715;
  }

  .modalIsurance__button {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 10px;
    margin: 0 26px 15px 26px;

      button {
      margin: 0;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      background: var(--brand-color-gold-6);
      border: 1px solid #d9d9d9;
      width: 130px;
      height: 40px;
      padding: 8px 18px 8px 4px;
      color: var(--neutral-color-gray-9);
      font-weight: bold;
      font-size: 16px;
      box-sizing: border-box;
      margin-top: 10px;
    }

    button:hover {
      background: var(--orange-1);
    }

  }
`;

export const ModalTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--neutral-color-dark-10);
`;

export const NewSelectPerson = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  overflow-y: auto;
  max-height: 70vh;

  &::-webkit-scrollbar {
    width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--brand-color-gold-6);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--orange-1);
    }

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 26px 8px 26px;
  }
`;


export const PersonContainer = styled.div`
  position: relative;
  max-height: 115px;
  max-width: 524px;
  height: 100%;
  padding: 16px;
  border-radius: 6px;
  border: 2px solid ${props => props.isSelected ? 'var(--brand-color-gold-6)' : 'var(--neutral-color-gray-5)'};
  margin: 0 26px 15px 26px;
  cursor: pointer;
  transition: border-color 0.3s;
  font-family: Roboto;

  &:hover {
    border-color: var(--brand-color-gold-6);
  }

  .personContainer__title {
    h3 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      color: var(--neutral-color-gray-9);

    img {
      width: 20px;
      margin-right: 8px;
    }
  }
}

  p {
    margin: 4px 0;
    color: var(--neutral-color-gray-10);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    strong {
      font-weight: 600;
    }
  }

  .checkmark {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    background-color: var(--brand-color-gold-6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--neutral-color-gray-1);
    visibility: ${props => props.isSelected ? 'visible' : 'hidden'};
  }
`;

export const NewSelectInsurance = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  max-height: 447px;

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: 100vh;

  }
`;

export const SelectedPerson = styled.div`
  display: flex;
  flex-direction: column;
  overflow: none;
  width: 100%;
  flex: 0 0 40%;

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 26px 8px 26px;
  }

  @media (max-width: 768px) {
    max-height: 200px;
  }
`;

export const NewSelectedPerson = styled.div`
  overflow-y: auto;
  padding: 6px 0 0 0;

    &::-webkit-scrollbar {
    width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--brand-color-gold-6);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--orange-1);
    }

`;

export const NewContractedInsurances = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 260px;
  overflow-y: auto;
  padding: 6px 0 0 0;


  &::-webkit-scrollbar {
    width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--brand-color-gold-6);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--orange-1);
    }

    @media (max-width: 768px) {
      max-height: 130px;

    }
`;

export const InsuranceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 26px 16px 26px;
  padding: 10px 0;
`;

export const NoInsuranceRow = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;

  h3 {
    text-align: center;
    font-size: 15px;
    color: var(--neutral-color-gray-10);
    font-weight: bold;
    margin-top: 6px;
  }

  @media (max-width: 768px) {
    margin: 14px;

    h3 {
      font-size: 14px;
    }
}
`;

export const  LargeWarningIcon = styled(WarningOutlined)`
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const NewInsuranceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    margin: 0;
    color: var(--neutral-color-gray-11);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .contractedInsurance__value {
    color: #434343;
  }
`;

export const InsuranceValueActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const InsuranceActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  .ant-dropdown-menu {
    padding: 0;
  }

  .custom-icon {
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}
`;

export const VerticalDivider = styled.div`
  height: 450px;
  width: 1px;
  background-color: #f0f0f0;
  margin: 0px;

  @media (max-width: 768px) {
    height: 2px;
    width: 100%;
    margin: 20px 0;
  }
`;

export const InsuranceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: none;
  width: 100%;
  margin: 0;
  padding: 0;

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 8px 0;
  }
`;

export const NewInsuranceContainer = styled.div`
  flex: 0 0 60%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0 26px 0 26px;
  }
`;

export const TotalInsuranceInfo = styled.div`
  text-align: right;
  margin-top: 20px;
  padding-right: 20px;

  p {
    margin: 10px 26px 0 0;
    color: var(--neutral-color-gray-11);
    font-size: 14px;
    font-weight: 400;
  }

  h2 {
    margin-top: 5px;
    font-size: 24px;
    font-weight: 800;
    color: var(--neutral-color-gray-9);
  }
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    justify-content: end;
    padding: 0px;
    align-items: center;
    margin: 10px 26px 14px 26px;

    button {
      padding: 8px 28px 8px 24px;
      font-weight: bold;
      font-size: 16px;
      height: 40px;
    }

      button:last-child {
      margin: 0;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      background: var(--brand-color-gold-6);
      border: 1px solid #d9d9d9;
      color: var(--neutral-color-gray-9);
      box-sizing: border-box;
    }

    button:last-child:hover {
      background: var(--orange-1);
    }
`;

export const StyledMenu = styled(Menu)`
    width: 232px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    top: 5px;

    .ant-dropdown-menu {
    padding: 0 !important;
  }

    ul {
      padding: 0;
    }

    li {
      align-items: center;
    }

    li:hover {
      background-color: var(--brand-color-gold-6);
      cursor: pointer;
    }

`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  padding: 1.7rem 0 1.5rem 1.5rem;

  .ant-dropdown-menu {
    padding: 0 !important;
    margin: 0;
  }


  span {
    display: flex;
  }

  svg {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    padding: 1rem 0 1rem 1rem;
  }
`;

export const CancelaInsuranceModal = styled(Modal)`
  width: 100%;

  p {
    text-align: center;
  }
`;

export const ActionCancelButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;

  button {
    border-radius: 4px;
  }

  button:last-child {
    font-weight: bold;
    color: var(--neutral-color-gray-9);
    background: var(--brand-color-gold-6);
  }

  button:last-child:hover {
      background: var(--orange-1);
    }

`;

export const NewLoadingModals = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;


