import React, { memo } from 'react';

import { Tag } from 'antd';

export const TRANSLATE_PRIORITY = {
  LOW: {
    label: 'Baixa',
    color: 'success',
  },
  MEDIUM: {
    label: 'Media',
    color: 'warning',
  },
  HIGH: {
    label: 'Alta',
    color: 'error',
  },
};

function Priority({ priority }) {
  const config = TRANSLATE_PRIORITY[priority] || {};
  return <Tag color={config.color}>{config.label}</Tag>;
}

export default memo(Priority);
