import React, { memo } from 'react';

import { Button, Modal } from 'antd';

import * as S from './style';

function ActionModal({
  title,
  action,
  visible,
  onOkClick,
  isLoading,
  onCancelClick,
  solicitationType,
}) {
  return (
    <Modal title={title} visible={visible} onCancel={onCancelClick}>
      <S.ContainerDelete>
        <span>
          Tem certeza que deseja {action} a classificação{' '}
          <b>{solicitationType?.name}</b>?
        </span>
        <S.ModalButtonContainer>
          <Button type="button" onClick={onCancelClick} loading={isLoading}>
            Cancelar
          </Button>
          <Button type="button" onClick={onOkClick} loading={isLoading}>
            Confirmar
          </Button>
        </S.ModalButtonContainer>
      </S.ContainerDelete>
    </Modal>
  );
}

export default memo(ActionModal);
