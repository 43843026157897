import React, { memo } from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';

import RenderItemMonth from './RenderItemMonth';
import * as S from './style';

function PurchaseTimeline({ invoices, loading, height }) {
  const itemRender = (page, type, originalElement) => {
    if (type === 'page') {
      return invoices?.length
        ? moment(invoices[page - 1]?.dataVencimento)
            .format('MMM')
            .toUpperCase()
        : '';
    }

    return originalElement;
  };

  return (
    <S.PurchasesTimelineContainer>
      <S.StyledList
        itemLayout="vertical"
        loading={loading}
        size="large"
        locale={{
          emptyText: 'Sem lançamentos',
        }}
        pagination={{
          position: 'top',
          pageSize: 1,
          itemRender,
          showLessItems: true,
        }}
        dataSource={invoices}
        renderItem={(month) => (
          <RenderItemMonth month={month} height={height} />
        )}
      />
    </S.PurchasesTimelineContainer>
  );
}

export default memo(PurchaseTimeline);
