import styled from 'styled-components';

export const ModalButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  button + button {
    margin-left: 10px;
  }

  button {
    width: 90%;
    height: 30px;
  }
`;

export const ContainerDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span {
    color: black;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;
