import React from 'react';

import { Input } from 'antd';

import { useClient } from '../../../../../../../../hooks/ClientProvider';
import Section from '../../../../components/Section';
import style from './style.module.scss';

function ClientInfo() {
  const { client } = useClient();

  return (
    <Section title="Dados do cliente" className={style.container}>
      <Input addonBefore="Cliente" value={client.client.name} disabled />
      <Input
        addonBefore="Cartão"
        value={client.information.numeroCartaoTruncado}
        disabled
      />
    </Section>
  );
}

export default React.memo(ClientInfo);
