const formatPhone = (area, phone) => {
  const phone_number = phone.replace(/\D/g, '');
  return phone_number.length === 9
  ? `(${area}) ${phone_number.slice(0, 5)}-${phone_number.slice(5)}`
  : `(${area}) ${phone_number.slice(0, 4)}-${phone_number.slice(4)}`;

};

module.exports = {
  formatPhone,
};
