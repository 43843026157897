import { Tabs } from "antd";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);

  .section-2 {
    width: 100%;
    display: block;
    margin-top: 24px;
  }

  @media (min-width: 768px) {
    .section-2 {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .section-2__left {
      display: flex;
      flex-direction: column;
      width: 409px;
    }

    .section-2__right {
      width: calc(100% - 409px);
      justify-content: center;
    }
  }

  }
`;

export const BreadCrumbPage = styled.div`
  width: 100%;
  margin-bottom: 16px;

  p {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const CreditCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;



  .plus-rounded {
      background-color: var(--brand-color-gold-6);
      border: none;
      color: black;
      cursor: pointer;
      padding: 5px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  .adicional__card {
      width: 83%;


  .adicional__card--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 0 6px;


    h2 {
      font-weight: 800;
      font-size: 16px;
      margin-right: auto;
    }

    button {
      margin: 0 0 1rem 0;
    }
  }

    .adicional__card--bar {
      height: 1px;
      background-color: #D9D9D9;
      width: 100%;
    }

    .adicional__card--container {
      width: 100%;

      .adicional__card--content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;

        img {
          width: 131px;
          height: 108px;
        }

        p {
          color: var(--neutral-color-dark-10);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0;
          margin: 0;

          svg {
            font-size: 18px;
            margin-right: 8px;
          }
        }

        button {
          margin-top: 20px;
          margin-bottom: 24px;
          border-radius: 2px;
          border: 1px solid var(--brand-color-gold-6);
          background: var(--brand-color-gold-6);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
          display: flex;
          padding: 8px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          span {
            display: flex;
            align-items: center;
            color: var(--neutral-color-gray-9);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            svg {
              margin-right: 8px;
              font-size: 16px;
            }
          }
        }
      }
    }
    }

    .thumbnails-menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    h2 {
      font-weight: bold;
      color: black;
      font-size: 16px;
      margin-right: auto;
    }

    button {
      margin: 0 0 .7rem .5rem;
      height: 24px;
      width: 24px;
    }

    .thumbnail-navigate{
      color: var(--brand-color-gold-6);
      border: none;
      cursor: pointer;
    }

    .thumbnails-container {
      display: flex;
      margin-left: auto;
      max-width: 140px;
      overflow: hidden;
      transform: translateY(3.5px);
    }

    .thumbnail {
      height: 35px;
      cursor: pointer;
      margin: 0 10px;
      position: relative;
    }

    .thumbnail.active {
      position: relative;
      padding-bottom: .5rem;
    }

    .thumbnail.active::after {
      content: '';
      position: absolute;
      left: 4px;
      right: 0;
      top: 30px;
      bottom: 0;
      height: 4px;
      width: 30px;
      border-radius: 8px;
      background-color: orange;
    }
  }

  .card-slides {
    width: 320px;
    overflow: hidden !important;
  }

  .slick-slide {
    padding-right: 6px;
  }

  .slick-list {
    padding: 0 10px;
  }
  .underline-bar {
    height: 1px;
    background-color: #D9D9D9;
    width: 83%;
    margin: 0 8px 10px 0;
  }

@media (min-width: 768px) {
  .card-slides {
    width: 385px;
  }
}
`;

export const WrapperCards = styled.div`
  /* grid-row: 1/8;
  grid-column: 2/2; */
  width: 100%;
  margin-top: 32px;

  .ant-card-body {
    padding: 0;
  }

  @media (min-width: 768px) {
    width: 400px;

    .ant-card-body {
      padding: 0;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: blue;
        border-radius: 20px;
        border: 3px solid orange;
      }
    }
  }
`;

export const NoCardPlaceholder = styled(Tabs)`

    .no-users {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;

        img {
          width: 131px;
          height: 108px;
        }

        p {
          color: var(--neutral-color-dark-10);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0;
          margin: 0;

          svg {
            font-size: 18px;
            margin-right: 8px;
          }
        }

        button {
          margin-top: 20px;
          margin-bottom: 24px;

          border-radius: 2px;
          border: 1px solid var(--brand-color-gold-6);
          background: var(--brand-color-gold-6);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
          display: flex;
          padding: 8px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          span {
            display: flex;
            align-items: center;

            color: var(--neutral-color-gray-9);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            svg {
              margin-right: 8px;
              font-size: 16px;
            }
          }
        }
      }
`;

export const StyledTabs = styled(Tabs)`

  .ant-tabs-nav-wrap {
    margin: 0 !important;
  }
  .ant-tabs-nav {
    display: flex;
    .ant-tabs-tab {
      flex-grow: 1;
    }
  }
  .ant-tabs-nav {
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    height: 50px;
    overflow: hidden;
    width: 100%;


    .ant-tabs-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-grow: 1;
      padding: 16px 1px;
      color: var(--character-title-85);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      background: var(--neutral-color-gray-4);
      border-bottom: 2px solid transparent;
      cursor: pointer;
      margin: 0;
      text-align: center;


      &.ant-tabs-tab-active {
        background: var(--neutral-color-gray-1);
        color: var(--brand-color-gold-6);
        border-color: var(--brand-color-gold-6);
        font-style: normal;
        font-weight: 600;
      }
    }

    .ant-tabs-tab:nth-child(n+3) {
      border-top-right-radius: 14px;
    }

    .ant-tabs-tab:first-child {
      border-top-left-radius: 14px;
    }
  }

  .ant-tabs-content-holder {
      overflow: hidden;
      padding-bottom: 16px;
  }

  .centralizeStatus{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;


export const WrapperCardLimit = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 12px;

  .ant-card {
    height: 100% !important;
  }

  .ant-card-body {
    padding: 0;
  }

  .total {
      font-size: 12px;
      font-weight: 400;
      fill: #8C8C8C;
    }

    .amount {
      font-size: 22px;
      font-weight: 900;
      fill: #272D3B;
    }

    .CustomLegendLimits {
      list-style: none;
      list-style-type: none;
      margin: 0px 0 0 0;
      padding: 0;
      display: flex;
      flex-direction: column ;
      align-items: flex-start;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #8C8C8C;
      }

      p {
        font-size: 12px;
        font-weight: 900;
        margin: 2px 0 0 26px;
      }
    }


  @media (min-width: 768px) {
    width: 400px;


    .ant-card-body {
       /* height: 235px; */


      &::-webkit-scrollbar-thumb {
        background-color: blue;
        border-radius: 20px;
        border: 3px solid orange;
      }
    }

    .total {
      font-size: 12px;
      font-weight: 400;
      fill: #8C8C8C;
    }

    .amount {
      font-size: 22px;
      font-weight: 900;
      fill: #272D3B;
    }

    .CustomLegendLimits {
      list-style: none;
      list-style-type: none;
      margin: 10px 0 0 0;
      padding: 0;
      display: flex;
      flex-direction: column ;
      align-items: flex-start;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #8C8C8C;
      }

      p {
        font-size: 18px;
        font-weight: 900;
        margin: 2px 0 0 26px;
      }
    }

  }
`;


export const MenuCards = styled.div`
  display: flex;
  width: 20%;
  cursor: pointer;
`;

export const RowIcon = styled.span`
  position: relative;
  cursor: pointer;
  display: flex;
  color: #444;
  span {
    margin-right: 10px;
  }
`;

export const RowStats = styled.p`
  width: 100%;

  color: var(--neutral-color-gray-7, #8c8c8c);

  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  border-radius: 16px;
  border: 1px solid var(--neutral-color-gray-7, #8c8c8c);
  background: var(--neutral-color-gray-2, #fafafa);
  padding: 1px 8px;
  text-align: center;

  ${(props) =>
    props.active === "ATUAL" || props.active === "FECHADO" &&
    css`
      border: 1px solid var(--status-color-success);
      background: rgba(82, 196, 26, 0.05);
      color: var(--status-color-success);
    `}
`;

export const WrapperFaturas = styled.div`
  /* grid-row: 8/15;
  grid-column: 2/4; */
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 32px;

  .ant-card-body {
    padding: 0;
    padding-top: 15px;
    height: 100%;
  }
  .ant-popover {
    position: relative;
    left: 20px;
  }

  .ant-card {
    height: 100% !important;
  }

  @media (min-width: 768px) {
    width: calc(100% - 400px);
    padding-left: 10px;

    .ant-card-body {
      height: 562px;
    }
  }
`;
export const FaturaTitleMoreOption = styled.div`
  display: flex;
  width: 20%;
  flex: 1;
  justify-content: flex-end;
  align-content: center;
  justify-items: center;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
  }
`;
export const InputsLabel = styled.div`
  span {
    font-weight: bold;
  }
  .margem {
    align-items: center;
  }
`;

export const WrapperSeguros = styled.div`
  width: 260px;
  position: relative;
  overflow-y: auto;

  &.mt-3{
    margin-top: 38px;
  }


  .ant-card-body {
    padding: 0;
    padding-top: 15px;
    height: 100%;
  }
  .ant-popover {
    position: relative;
    left: 20px;
  }

  .ant-card {
    height: 100% !important;
  }

  @media(min-width: 400px){
    width: 100%;
  }
`;

export const MenuInsegurance = styled.div`
  cursor: pointer;
`;

export const WrapperPurchases = styled.div`
  /* grid-row: 1/8;
  grid-column: 3/3;
  padding: 10px; */
  width: 100%;
  height: 100%;
  margin-top: 38px;

  .ant-card{
    height: 100%;
  }

  .ant-list-item {
    padding: 16px 0 0;
  }

  .ant-list-split.ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item:last-child {
    border: none;
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const WrapperTimeline = styled.div`
  /* grid-row: auto / auto;
  grid-column: 1/4; */
  width: 100%;
  height: 100%;
  margin-top: 24px;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const WrapperNewLoading = styled.div`
  width: 100%;
  min-height: 40vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QuickAccessCard = styled.div`
  width: 100%;
  margin-top: 24px;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr;

  @media(min-width: 768px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media(min-width: 992px){
    grid-template-columns: repeat(3, 1fr);
  }
`;
