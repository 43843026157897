import React, { memo } from 'react';

import { Tag } from 'antd';

export const STATUSES = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
  CANCELLED: 'CANCELLED',
};

export const TRANSLATE_STATUS = {
  [STATUSES.PENDING]: {
    label: 'Pendente',
    color: 'warning',
  },
  [STATUSES.ACTIVE]: {
    label: 'Ativo',
    color: 'processing',
  },
  [STATUSES.RESOLVED]: {
    label: 'Resolvido',
    color: 'success',
  },
  [STATUSES.CANCELLED]: {
    label: 'Cancelado',
    color: 'error',
  },
};

function Status({ status }) {
  const config = TRANSLATE_STATUS[status] || {};
  return <Tag color={config.color}>{config.label}</Tag>;
}

export default memo(Status);
