import React from "react";

import { IoEllipsisHorizontalSharp } from "react-icons/io5";

const Pagination = ({
  currentPage,
  totalPageCount,
  setCurrentPage
}) => {
  const renderPages = [];
  const addPageButton = (pageNumber) => {
    renderPages.push(
      <button
        type="button"
        key={pageNumber}
        onClick={() => setCurrentPage(pageNumber)}
        className={currentPage === pageNumber ? "active" : ""}
      >
        {pageNumber}
      </button>
    );
  };

  const addEllipsis = (key) => {
    renderPages.push(<span key={key}><IoEllipsisHorizontalSharp /></span>);
  };

  if (totalPageCount <= 5) {
    for (let i = 1; i <= totalPageCount; i++) {
      addPageButton(i);
    }
  } else {
    if (currentPage <= 3) {
      for (let i = 1; i <= 5; i++) {
        addPageButton(i);
      }
      addEllipsis('ellipsis1');
      addPageButton(totalPageCount);
    } else if (currentPage >= totalPageCount - 2) {
      addPageButton(1);
      addEllipsis('ellipsis2');
      for (let i = totalPageCount - 4; i <= totalPageCount; i++) {
        addPageButton(i);
      }
    } else {
      addPageButton(1);
      addEllipsis('ellipsis3');
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        addPageButton(i);
      }
      addEllipsis('ellipsis4');
      addPageButton(totalPageCount);
    }
  }

  return <>{renderPages}</>;
}

export default Pagination;
