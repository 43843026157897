import React, { useCallback, useEffect, useState } from "react";

import { HomeFilled } from "@ant-design/icons";

import api from "../../services/api";
import { notifyError } from "../../utils/notifications";
import EditModal from "./modal";
import * as Style from "./style";
import DataTableGroups from "./UserTableV2";

function NewGroups() {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);

  async function newGroup() {
    setOpenModal(true);
    setSelectedGroup(null);
  }

  async function addRow(group) {
    setGroups((_p) => [..._p, group]);
    setSelectedGroup(group);
  }

  async function editRow(row) {
    setOpenModal(true);
    setSelectedGroup(row);
  }

  async function getData() {
    try {
      setLoading(true);
      const { data } = await api.get("role");
      const permision = await api.get("permission", {
        module_id: 1,
        companie: data[0].company_id,
      });

      setPermissions(permision.data);
      setGroups(data);
    } catch (error) {
      notifyError("Falha ao listar permissões");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <EditModal
        open={openModal}
        permissions={permissions}
        closeModal={() => {
          setOpenModal(false);
          setSelectedGroup(null);
        }}
        selectedGroup={selectedGroup}
        addRow={addRow}
      />

      <Style.Container>
        {groups && (
          <DataTableGroups
            data={groups}
            loading={loading}
            modalAction={newGroup}
            editRow={editRow}
          />
        )}
      </Style.Container>
    </>
  );
}

export default NewGroups;
