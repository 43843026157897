import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const List = styled.span`
  font-size: 40px;
  font-weight: 600;
  cursor: pointer;
`;

export const ContainerModal = styled.div`
  background: #f5f5f5;
  color: #b2bec3;
  position: absolute;
  width: 100%;
  left: 0;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
