import React, { useEffect, useState } from 'react';

import { useClient } from '../../hooks/ClientProvider';
import { cardProcessorService } from '../../services/cardProcessorService';
import LoadingElevaty from '../LoadingElevaty';
import * as Style from './style';
import TabAdicional from './TabAdicional';
import TabDependente from './TabDependente';
import TabTitular from './TabTitular';



function InfosClient() {
  const { client } = useClient();

  const [activeTab, setActiveTab] = useState(1);
  const [rendaValue, setRendaValue] = useState();
  const { loading } = useState();

  const grauParentesco = {
    1: 'Pai',
    2: 'Mãe',
    3: 'Filho',
    4: 'Irmão',
    5: 'Conjuge',
    6: 'Outro',
  };

  const getInfoRenda = async () => {
    const response = await cardProcessorService.getClientId(
      client?.client.id
    );
    setRendaValue(response.rendas[0]);
  };

  useEffect(() => {
    getInfoRenda();
  }, [])

  return (
    <>
      <Style.WrapperInfosClient>
        {loading ? (
          <LoadingElevaty />
        ) : (
          <div className="infos-client__header">
            <ul>
              <li
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => setActiveTab(1)}
              >
                Titular
              </li>
              <li
                className={activeTab === 2 ? 'active' : ''}
                onClick={() => setActiveTab(2)}
              >
                Dependentes
              </li>
              <li
                className={activeTab === 3 ? 'active' : ''}
                onClick={() => setActiveTab(3)}
              >
                Adicional
              </li>
            </ul>
          </div>
        )}
        <div className="infos-client__content">
          {activeTab === 1 && (
            <TabTitular client={client && client} rendaValue={rendaValue} />
          )}
          {activeTab === 2 && (
            <TabDependente client={client && client} grauParentesco={grauParentesco} />
          )}
          {activeTab === 3 && (
            <TabAdicional client={client && client} grauParentesco={grauParentesco} />
          )}
        </div>
      </Style.WrapperInfosClient>
    </>
  );
}

export default InfosClient;
