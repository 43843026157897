import React, { memo, useCallback, useEffect, useState } from "react";

import { Button, Modal, Select, Skeleton } from "antd";
import { format } from "date-fns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import LoadingElevaty from '../../../components/LoadingElevaty';
import { useAuth } from "../../../hooks/AuthProvider";
import api from "../../../services/api";
import { getPlaces } from '../../../utils/getStores';
import { notifyError, notifySucces } from "../../../utils/notifications";
import { validaCpf } from "../../../utils/validations";
import * as S from "./style";

const { Option } = Select;

function SellCancelModal({ onCancel, infosModal, reloadPage }) {
  const { user } = useAuth();

  function formatDate(valueDate) {
    const date = new Date(valueDate);
    date.setDate(date.getDate());

    return date;
  }

  const [lojas, setLojas] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);

  const [dataUser, setDataUser] = useState([]);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [idClient, setIdClient] = useState(0);
  const [nameClient, setNameClient] = useState("");
  const [clientProducts, setClientProducts] = useState([]);
  const [formSteps, setFormSteps] = useState(0);
  const [product, setProduct] = useState({});
  const [filteredStores, setFilteredStores] = useState();
  const [loadingStores, setLoadingStores] = useState(false);
  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "cnpj") setCurrentMask("99.999.999/9999-99");
    if (doc === "cartao") setCurrentMask("9999 9999 9999 9999 999");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);

  const getLojas = useCallback(async () => {
    try {
      const places = await getPlaces(user);
      const editList = places
        .map((item) => {
          return {
            value: item.id,
            label: item.nomeFantasia,
            cnpj: item.cnpj,
          };
        });

      setLojas(editList);
    } catch (error) {
      if (error.message) return  notifyError(error.message)
      notifyError("Falha ao listar lojas.");
    }
  }, []);

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "cnpj") text = "do CNPJ";
    if (currentDoc === "cartao") text = "do Cartão";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const handleTransaction = async (values) => {
    let dataHoraTerminal = moment().format("YYYY-MM-DDTHH:mm:ss");
    dataHoraTerminal += "Z";
    const dataTransacao = infosModal[0]?.dataTransacao.replace(/(\.\d+)([+-]\d{2})(\d{2})$/, "Z");
    try {
      await api.post("make_sale_cancel_manual", {
        client_id: idClient,
        dataHoraTerminal,
        dataHoraTransacaoOriginal: dataTransacao,
        nsuOrigemTransacaoOriginal: infosModal[0].nsuCadastro.toString(),
        redeOrigemOperacaoPDV: infosModal[0].redeOrigem.toString()
      });
      notifySucces("Venda cancelada com sucesso!");
      reloadPage();
      onCancel();
    } catch (error) {
      if (error.message){
        notifyError(error.message);
        reloadPage();
        return onCancel();
      }
      notifyError("Erro no cancelamento da venda.");
      reloadPage();
      onCancel();
    }
  };

  const handleStepOne = async (values) => {
    setLoadingBtn(true);
    let response = "";
    if (currentDoc === "client_id") {
      response = await api.get(`get_client_by_id`, {
        [`${currentDoc}`]: values.doc,
      });
    } else {
      response = await api.get(`client_${currentDoc}`, {
        [`${currentDoc}`]: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
      });
    }

    if (!response.data[0]) throw new Error("Cliente não encontrado");
    const resp = await api.get("clientId", { client_id: response.data[0].id });
    setNameClient(resp.data.nome);
    setIdClient(response.data[0].id);
    setClientProducts(response.data);

    setTimeout(() => {
      setLoadingBtn(false);
      setFormSteps(1);
    }, 1000);
  };

  const handleProduct = useCallback(
    async (value) => {
      if (value !== "none") {
        const productSelectedArr = clientProducts.filter(
          (cp) => cp.id === Number(value)
        );
        const productSelected = productSelectedArr[0];

        setLoadingStores(true);

        const filterUserStores = dataUser.filter((item) => item.id === user?.id);

        if (
          filterUserStores[0]?.store &&
          filterUserStores[0]?.store.length > 0
        ) {
          const editIDstoFilter = filterUserStores[0].store
            .split(",")
            .map(Number);

          const filterStoresForUser = lojas.filter((store) =>
            editIDstoFilter.includes(store.value)
          );

          setProduct(productSelected);
          setFilteredStores(filterStoresForUser);

          setTimeout(() => {
            setLoadingStores(false);
          }, 500);
        } else {
          setProduct(productSelected);
          setFilteredStores(lojas);

          setTimeout(() => {
            setLoadingStores(false);
          }, 500);
        }
      }
    },
    [clientProducts]
  );

  useEffect(() => {
    getLojas();

    if (infosModal[0].idPortador) {
      setLoadingSearchUser(true);

      const fetchData = async () => {
        let set = true;
        let response = "";

        response = await api.get(`get_client_by_id`, {
          [`client_id`]: infosModal[0].idPortador,
        });

        response.data.map((item) => {
          if (item.cartoes[0]) {
            set = false;
          }
          return true;
        });

        // if (set) {
        //   setLoadingBtn(false);
        //   notifyError("Cliente não possui cartão para cancelamento");
        //   throw new Error("Cliente não possui cartão ");
        // }
        const resp = await api.get("clientId", {
          client_id: response.data[0].id,
        });


        setNameClient(resp.data.nome);
        setIdClient(response.data[0].id);
        setClientProducts(response.data);

        setTimeout(() => {
          setLoadingSearchUser(false);
          setFormSteps(1);
        }, 1000);
      };

      fetchData();
    }
  }, []);

  return (
    <Modal title="Cancelar Venda" visible onCancel={onCancel}>
      <S.ContainerCreateEdit>
        {loadingSearchUser ? (
          <div className="loading-user">
            <div className="loading-user__text">
              <p>Carregando informações para o cancelamento!</p>
            </div>
            <LoadingElevaty />
          </div>
         ) : (
          <>
            {formSteps === 0 && (
              <Formik
                initialValues={{ doc: "" }}
                validationSchema={Yup.object({
                  doc: Yup.string()
                    .required(`${currentDoc.toUpperCase()} obrigatório`)
                    .test(
                      "test_cpf",
                      `Informe um ${currentDoc} válido`,
                      (value) => {
                        if (currentDoc === "client_id") return true;
                        if (currentDoc === "cpf") {
                          if (value === undefined || value === null)
                            return false;

                          const cpfFormated = value
                            .split(".")
                            .join("")
                            .replace("-", "");
                          return validaCpf(cpfFormated);
                        }
                        if (currentDoc === 'cartao') {
                          const cardFormated = value ? value
                            .replace(/[./-]/g, '')
                            .replace(/\s/g, '') : '';
                          let cardSize = 16;
                          if( cardFormated.length === 19) cardSize = 19;

                          return cardFormated.length === cardSize;
                        }
                      }
                    ),
                })}
                onSubmit={handleStepOne}
              >
                {() => (
                  <Form>
                    <S.FormRow>
                      <div>
                        <b>Tipo:</b>
                      </div>
                      <S.CpfCnpj>
                        <S.DocButton
                          selected={currentDoc === "cpf"}
                          onClick={handleCPF}
                        >
                          CPF
                        </S.DocButton>
                        <S.DocButton
                          selected={currentDoc === "client_id"}
                          onClick={handleClientId}
                        >
                          ID Cliente
                        </S.DocButton>
                      </S.CpfCnpj>

                      <Field name="doc">
                        {({ field, meta }) => (
                          <ReactInputMask
                            placeholder={setPlaceholder()}
                            type="text"
                            mask={currentMask}
                            maskChar={null}
                            autoComplete="off"
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            {...field}
                          />
                        )}
                      </Field>

                      <ErrorMessage
                        name="doc"
                        className="error"
                        component="div"
                      />
                    </S.FormRow>

                    <S.FormRow>
                      <Button
                        loading={loadingBtn}
                        htmlType="submit"
                        type="primary"
                      >
                        Buscar
                      </Button>
                    </S.FormRow>
                  </Form>
                )}
              </Formik>
            )}

            {formSteps === 1 && (
              <>
                <S.FormRow>
                  <div>
                    <b>ID Cliente:</b>
                  </div>

                  <ReactInputMask
                    type="text"
                    value={idClient}
                    autoComplete="off"
                    disabled
                  />
                </S.FormRow>
                <S.FormRow>
                  <div>
                    <b>Nome Cliente:</b>
                  </div>

                  <ReactInputMask
                    type="text"
                    value={nameClient}
                    autoComplete="off"
                    disabled
                  />
                </S.FormRow>
                <S.FormRow>
                  <div>
                    <b>Produto</b>
                  </div>
                  <Select id="products_list" onChange={handleProduct}>
                    <Option value="none" key="none">
                      Selecione o produto
                    </Option>
                    {clientProducts.map((cp) => (
                      <Option value={cp.id} id={cp.id} key={cp.id}>
                        <b>{cp.desc_prod}</b>
                        <div>
                          {cp.cartoes.map((cartao) => (
                            <>
                              <span>
                                {cartao.numeroMascarado} (
                                {cartao.virtual ? "Virtual" : "Físico"}) -{" "}
                                {cartao.status.replace(/_/g, " ")}
                              </span>
                              <br />
                            </>
                          ))}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </S.FormRow>
                {filteredStores && (
                  <>
                    <hr />
                    {loadingStores ? (
                      <Skeleton active />
                    ) : (
                      <>
                        <div style={{ marginTop: 16 }}>
                          <Formik
                            initialValues={{ data: "", datahora: "" }}
                            onSubmit={handleTransaction}
                          >
                            <Form>
                              <S.FormRow>
                                <div>
                                  <b>Data da Transação:</b>
                                </div>

                                <div>
                                  <b>
                                    {infosModal[0] &&
                                      format(
                                        formatDate(infosModal[0].dataTransacao),
                                        "dd/MM/yyyy"
                                      )}
                                  </b>
                                </div>
                              </S.FormRow>
                              <S.FormRow>
                                <div>
                                  <b>Horário da Transação:</b>
                                </div>

                                <div>
                                  <b>
                                    {infosModal[0].dataTransacao &&
                                      infosModal[0].dataTransacao.match(
                                        /\d\d:\d\d/
                                      )}
                                  </b>
                                </div>
                              </S.FormRow>

                              {infosModal[0].dataTransacao && (
                                <S.FormRow>
                                  <div>
                                    <b>NSU Origem da Transação:</b>
                                  </div>
                                  <div>
                                    <b>
                                      {infosModal[0].nsuCadastro &&
                                        infosModal[0].nsuCadastro}
                                    </b>
                                  </div>
                                </S.FormRow>
                              )}

                              <S.FormRow>
                                <S.ButtonContainer>
                                  <Button htmlType="submit" loading={false}>
                                    Cancelar Venda
                                  </Button>
                                </S.ButtonContainer>
                              </S.FormRow>
                            </Form>
                          </Formik>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )} 
      </S.ContainerCreateEdit>
    </Modal>
  );
}

export default memo(SellCancelModal);
