import React, { ReactNode } from 'react';

import { useLocation } from 'react-router-dom';

import { CompanyParamsProvider } from '../Context/companyContext'
import { VersionProvider } from '../Context/hasBetaActiveContext';
import { PoupProvider } from '../Context/hasPopupActiveContext';
import { PermissionProvider } from '../Context/permissionContext';
import { AuthProvider } from './AuthProvider';
import { ClientProvider } from './ClientProvider';
import inactivityTime from './useInactivityTime';

interface AppProvideHookInterface {
  children: ReactNode;
}

function AppProvide({ children }: AppProvideHookInterface) {
  const location = useLocation();
  inactivityTime();

  const isLoginPage = location.pathname === '/';

  return (
    <AuthProvider>
      {!isLoginPage ? (
        <ClientProvider>
          <PermissionProvider>
            <PoupProvider>
              <VersionProvider>
                <CompanyParamsProvider>
                  {children}
                </CompanyParamsProvider>
              </VersionProvider>
            </PoupProvider>
          </PermissionProvider>
        </ClientProvider>
      ) : (
        <>{children}</>
      )}
    </AuthProvider>
  );
}

export default AppProvide;
