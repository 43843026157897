/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";

const endpoint = "client_invoice/installment";
export class InvoiceInstallmentService {
  static simulate({ clientId, tax }) {
    const url = `${endpoint}/${clientId}/${tax}/simulate`;
    return api.get(url).then((response) => response.data?.parcelamentos);
  }

  static simulateTotal({ clientId, tax }) {
    const url = `${endpoint}/${clientId}/${tax}/simulate/total`;
    return api.get(url).then((response) => response.data?.parcelamentos);
  }

  static activate({ clientId, devedorTotal, params }) {
    const url = `${endpoint}/${clientId}/activate`;
    return api
      .post(url, {
        devedorTotal,
        taxaJuros: params.taxaJuros,
        quantidadeParcelas: params.quantidadeParcelas,
        idFatura: params.idFatura,
      })
      .then((response) => response);
  }

  static list({ clientId }) {
    const url = `${endpoint}/${clientId}`;
    return api.get(url).then((response) => response.data?.parcelamentos);
  }

  static async active({ clientId }) {
    const url = `${endpoint}/${clientId}/active`;
    return api.get(url);
  }

  static async simulateAnticipation({ installmentId, clientId }) {
    const url = `${endpoint}/${clientId}/anticipation/${installmentId}`;
    return api.get(url);
  }

  static async anticipateTotal({ installmentId, clientId, infoAntecipation }) {
    const url = `${endpoint}/${clientId}/anticipation/${installmentId}/total`;
    return api.post(url);
  }

  static async anticipatePartial({
    installmentId,
    clientId,
    firstInstallment,
    lastInstallment,
  }) {
    const url = `${endpoint}/${clientId}/anticipation/${installmentId}/partial`;
    return api.post(url, {
      firstInstallment,
      lastInstallment,
    });
  }
}
