import React, { useCallback, useEffect, useState } from "react";

import { HomeFilled, WalletOutlined } from "@ant-design/icons";
import { Button, Select, DatePicker, ConfigProvider, Alert } from "antd";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { format } from "date-fns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import Card from "../../components/Card";
import BreadCrumbNavigation from "../../components/DataTable/TopSection/BreadCrumbNavigation";
import Loading from "../../components/Loading";
import api from "../../services/api";
import { notifyError, notifySucces } from "../../utils/notifications";
import { validaCpf } from "../../utils/validations";
import ReserveCancelModal from "../solicitation/SellandPayModal/sellCancelModal";
import * as S from "./style";
import UsersTable from "./UsersTable";

const { Option } = Select;

function ListBalanceReserve() {
  const [data, setData] = useState([]);
  const [lastYear, setLastYear] = useState(() => {
    const lastYearDate = new Date();
    lastYearDate.setDate(lastYearDate.getDate() - 10);
    return lastYearDate;
  });
  const [today, setToday] = useState(() => {
    const todayDate = new Date();
    return todayDate;
  });

  const [formSteps, setFormSteps] = useState(0);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [idClient, setIdClient] = useState(0);
  const [nameClient, setNameClient] = useState("");
  const [listClientAccount, setListClientAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [modalCancelReserveOpen, setmodalCancelReserveOpen] = useState(false);

  const [dateInit, setDateInit] = useState();
  const [dateFim, setDateFim] = useState();
  const [dateInitConfirmation, setDateInitConfirmation] = useState();
  const [dateFimConfirmation, setDateFimConfirmacao] = useState();

  const [infosModal, setInfosModal] = useState();

  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "cnpj") setCurrentMask("99.999.999/9999-99");
    if (doc === "cartao") setCurrentMask("9999 9999 9999 9999");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);

  const onChangeLastYearRangeDate = useCallback((date, setFieldValue) => {
    setFieldValue("lastYear", moment(date).format("YYYY-MM-DD"));
    setLastYear(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
  }, []);

  const onChangeTodayRangeDate = useCallback((date, setFieldValue) => {
    setFieldValue("today", moment(date).format("YYYY-MM-DD"));
    setToday(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
  }, []);

  const onChangeLastYearRangeDateConfirm = useCallback(
    (date, setFieldValue) => {
      setFieldValue("lastYearConfirm", moment(date).format("YYYY-MM-DD"));
      setLastYear(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
      setSearchData(false);
    },
    []
  );

  const onChangeTodayRangeDateConfirm = useCallback((date, setFieldValue) => {
    setFieldValue("todayConfirm", moment(date).format("YYYY-MM-DD"));
    setToday(moment(date.utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setSearchData(false);
  }, []);

  const handleSubmit = (value) => {
    setLoadingTable(true);
    setSearchData(false);
    setData(null);

    setDateInit(value.lastYear);
    setDateFim(value.today);

    setDateInitConfirmation(value.lastYearConfirm);
    setDateFimConfirmacao(value.todayConfirm);

    return new Promise((resolve, reject) => {
      if (selectedAccount == null || selectedAccount === "0") {
        reject(new Error("Informe a conta do portador"));
      } else {
        resolve();
      }
    })
      .then(() => {
        return api.get("get_reserve_balance", {
          client_id: idClient,
          dataCriacaoInicial: value.lastYear,
          dataCriacaoFinal: value.today,
          dataUtilizacaoFinal: value.todayConfirm,
          dataUtilizacaoInicial: value.lastYearConfirm,
        });
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
      });
  };

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "cnpj") text = "do CNPJ";
    if (currentDoc === "cartao") text = "do Cartão";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const reloadPage = () => {
    setLoadingTable(true);
    setSearchData(false);
    setData(null);

    api
      .get("get_reserve_balance", {
        client_id: idClient,
        dataCriacaoInicial: dateInit,
        dataCriacaoFinal: dateFim,
        dataUtilizacaoFinal: dateFimConfirmation,
        dataUtilizacaoInicial: dateInitConfirmation,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        notifyError(error.message);
        setData(null);
      })
      .finally(() => {
        setSearchData(true);
        setLoadingTable(false);
      });
  };

  const handleOpenModal = useCallback(
    async (nsuOrigem) => {
      // setmodalCancelReserveOpen(true);

      api
        .put("cancel_reserve_balance", {
          client_id: idClient,
          dataHoraTerminal: moment().format("YYYY-MM-DDTHH:mm:ss"),
          infoData: data?.filter((item) => item.nsuOrigem === nsuOrigem),
        })
        .then((res) => {
          setData(res.data);
          notifySucces(res.data.message);
        })
        .catch((error) => {
          notifyError(error.message);
          setData(null);
        })
        .finally(() => {
          setSearchData(true);
          setLoadingTable(false);
        });
    },
    [data]
  );

  const closeModalCancelReserve = useCallback(() => {
    setmodalCancelReserveOpen(false);
  }, []);

  const handleResetSearch = async () => {
    await setFormSteps(0);
    await setNameClient(null);
    await setIdClient(null);
    await setSearchData(false);
    await setSelectedAccount(null);
  };

  const handleStepOne = async (values) => {
    try {
      setLoadingBtn(true);

      const response = await api.get("getAccount", {
        dataDocument: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
        document: currentDoc,
      });

      if (!response.data || response.data.length === 0) {
        throw new Error("Cliente não encontrado");
      }

      let uniqueAccountSelectList;
      if (Array.isArray(response.data) && response.data.length > 1) {
        uniqueAccountSelectList = response.data.map((item) => ({
          id: item.id,
          accountSelect: item.accountSelect,
        }));
      } else {
        await setIdClient(response.data.id);
        await setSelectedAccount(response.data.id);
        uniqueAccountSelectList = [
          {
            id: response.data.id,
            accountSelect: response.data.accountSelect,
          },
        ];
      }

      setListClientAccount(uniqueAccountSelectList);

      setTimeout(() => {
        setLoadingBtn(false);
        setFormSteps(1);
      }, 1000);
    } catch (error) {
      setLoadingBtn(false);
      notifyError(error.message);
    }
  };

  const handleAccountSelected = useCallback(async (value) => {
    await setIdClient(value);
    await setSelectedAccount(value);
  }, []);

  return (
    <>
      <S.Container>
        <BreadCrumbNavigation tableName="Listagem de Reserva de Saldo" />

        {formSteps > 0 && (
          <div className="select-btn">
            <Button
              style={{ marginLeft: 22, marginBottom: 15 }}
              onClick={() => handleResetSearch()}
              type="ghost"
            >
              Nova Pesquisa
            </Button>
          </div>
        )}
        <Card title="Listagem de Reserva de Saldo" loading={loading}>
          {formSteps === 0 && (
            <Formik
              initialValues={{ doc: "" }}
              validationSchema={Yup.object({
                doc: Yup.string()
                  .required(`${currentDoc.toUpperCase()} obrigatório`)
                  .test(
                    "test_cpf",
                    `Informe um ${currentDoc} válido`,
                    (value) => {
                      if (currentDoc === "client_id") return true;
                      if (currentDoc === "cpf") {
                        if (value === undefined || value === null) return false;

                        const cpfFormated = value
                          .split(".")
                          .join("")
                          .replace("-", "");
                        return validaCpf(cpfFormated);
                      }
                    }
                  ),
              })}
              onSubmit={handleStepOne}
            >
              {() => (
                <Form style={{ maxWidth: '600px' }}>
                  <div className="box-form">
                    <S.FormRow>
                      <div>
                        <b>Tipo:</b>
                      </div>
                      <S.CpfCnpj>
                        <S.DocButton
                          selected={currentDoc === "cpf"}
                          onClick={handleCPF}
                        >
                          CPF
                        </S.DocButton>
                        <S.DocButton
                          selected={currentDoc === "client_id"}
                          onClick={handleClientId}
                        >
                          ID Cliente
                        </S.DocButton>
                      </S.CpfCnpj>

                      <Field name="doc">
                        {({ field, meta }) => (
                          <ReactInputMask
                            placeholder={setPlaceholder()}
                            type="text"
                            mask={currentMask}
                            maskChar={null}
                            autoComplete="off"
                            className={
                              meta.touched && meta.error ? "error-id" : null
                            }
                            {...field}
                          />
                        )}
                      </Field>

                      <ErrorMessage
                        name="doc"
                        className="error"
                        component="div"
                      />
                    </S.FormRow>

                    <S.FormRow>
                      <Button
                        loading={loadingBtn}
                        htmlType="submit"
                        type="primary"
                      >
                        Buscar
                      </Button>
                    </S.FormRow>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {formSteps !== 0 && (
            <div className="flex">
              {!nameClient && (
                <>
                  <p>
                    <strong>* Conta:</strong>
                  </p>
                  <S.FormAccount>
                    <Select
                      placeholder="Selecionar conta"
                      onChange={handleAccountSelected}
                      defaultValue={
                        listClientAccount.length === 1
                          ? listClientAccount[0].id
                          : "Selecionar"
                      }
                    >
                      {listClientAccount.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.accountSelect}
                        </Option>
                      ))}
                    </Select>
                  </S.FormAccount>
                </>
              )}
              <Formik
                initialValues={{
                  lastYear: null,
                  today: null,
                  lastYearConfirm: null,
                  todayConfirm: null,
                }}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form style={{ display: "flex", alignItems: "flex-end" }}>
                    <ConfigProvider locale={datePickerLocale}>
                      <div className="select-date">
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>
                            Data de Solicitação:
                          </span>
                        </S.InputsLabel>

                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>
                            Data Inicial
                          </span>
                        </S.InputsLabel>

                        <Field name="lastYear">
                          {({ meta }) => (
                            <DatePicker
                              defaultValue={null}
                              className={`${meta.touched && meta.error ? "error-id" : null
                                } `}
                              placeholder="Selecione a data"
                              format="DD/MM/YYYY"
                              allowClear={false}
                              showToday={false}
                              onChange={(e) =>
                                onChangeLastYearRangeDate(e, setFieldValue)
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div
                        className="select-date"
                        style={{ marginLeft: "4px" }}
                      >
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }} />
                        </S.InputsLabel>
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>Data Final</span>
                        </S.InputsLabel>

                        <Field name="today">
                          {({ meta }) => (
                            <DatePicker
                              defaultValue={null}
                              className={`${meta.touched && meta.error ? "error-id" : null
                                } `}
                              placeholder="Selecione a data"
                              format="DD/MM/YYYY"
                              allowClear={false}
                              showToday={false}
                              onChange={(e) =>
                                onChangeTodayRangeDate(e, setFieldValue)
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div
                        className="select-date"
                        style={{ marginLeft: "40px" }}
                      >
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>
                            Data de Confirmação:
                          </span>
                        </S.InputsLabel>
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>
                            Data Inicial
                          </span>
                        </S.InputsLabel>

                        <Field name="lastYearConfirm">
                          {({ meta }) => (
                            <DatePicker
                              defaultValue={null}
                              className={`${meta.touched && meta.error ? "error-id" : null
                                } `}
                              placeholder="Selecione a data"
                              format="DD/MM/YYYY"
                              allowClear={false}
                              showToday={false}
                              onChange={(e) =>
                                onChangeLastYearRangeDateConfirm(
                                  e,
                                  setFieldValue
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>

                      <div className="select-date">
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }} />
                        </S.InputsLabel>
                        <S.InputsLabel>
                          <span style={{ marginRight: "5px" }}>Data Final</span>
                        </S.InputsLabel>

                        <Field name="todayConfirm">
                          {({ meta }) => (
                            <DatePicker
                              defaultValue={null}
                              className={`${meta.touched && meta.error ? "error-id" : null
                                } `}
                              placeholder="Selecione a data"
                              format="DD/MM/YYYY"
                              allowClear={false}
                              showToday={false}
                              onChange={(e) =>
                                onChangeTodayRangeDateConfirm(e, setFieldValue)
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </ConfigProvider>
                    <div className="select-btn" style={{ marginLeft: "40px" }}>
                      <S.InputsLabel>
                        <span style={{ marginRight: "5px" }} />
                      </S.InputsLabel>
                      <Button htmlType="submit" type="ghost">
                        Pesquisar
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}

          <div className="select-table">
            {loadingTable && <Loading />}
            {searchData && data?.length > 0 && (
              <UsersTable
                data={data}
                clientId={idClient}
                handleOpenModal={handleOpenModal}
              />
            )}
            {searchData && (!data || data?.length === 0) && (
              <div className="alert-table">
                <Alert
                  message="Não há dados para as datas selecionadas"
                  type="warning"
                  showIcon
                />
              </div>
            )}
          </div>
        </Card>
      </S.Container>
      {modalCancelReserveOpen && (
        <ReserveCancelModal
          onCancel={closeModalCancelReserve}
          infosModal={infosModal}
          reloadPage={reloadPage}
        />
      )}
    </>
  );
}
export default React.memo(ListBalanceReserve);
