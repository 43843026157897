import api from "../services/api";

async function setPlaces() {
  const {
    data: { places },
  } = await api.get("card_places");
  localStorage.setItem(
    "@SnxPayment:stores",
    JSON.stringify(places?.filter((place) => place.id >= 1))
  );
}

async function getPlaces(user, allPlaces) {
  const lojas = JSON.parse(localStorage.getItem("@SnxPayment:stores"));
  let userPlaces = user?.store?.split(",")?.map(Number);
  if(Array.isArray(userPlaces) && userPlaces[0]===0) {
    userPlaces = undefined;
  }

  let filteredPlaces;

  if (lojas && lojas.length && userPlaces && userPlaces.length > 0 && !allPlaces) {
    return lojas.filter(
      (place) => userPlaces.includes(place.id) && place.id >= 1
    );
  }

  if (!userPlaces && lojas && !allPlaces) {
    return lojas;
  }

  if (!lojas || allPlaces) {
    try {
      const res = await api.get("card_places");
      const { data } = res;

      if (userPlaces && !allPlaces) {
        filteredPlaces = data?.places.filter(
          (place) => userPlaces.includes(place.id) && place.id >= 1
        );
      } else {
        filteredPlaces = data?.places.filter((place) => place.id >= 1);
      }

      localStorage.setItem(
        "@SnxPayment:stores",
        JSON.stringify(data?.places.filter((place) => place.id >= 1))
      );

      return filteredPlaces;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return false;
}

export { getPlaces, setPlaces };
