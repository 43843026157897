import React from "react";

import { Table } from "antd";

import { usePermissions } from "../../../Context/permissionContext";
import { getFormatDateAndTimeNoTz } from "../../../utils/formatDate";
import * as S from "../style";

function UsersTable({ data, clientId, handleOpenModal }) {
  const { permissions } = usePermissions();

  const columns = [
    {
      title: "NSU Origem",
      dataIndex: "nsuOrigem",
    },
    {
      title: "Data de Criação",
      dataIndex: "dataCriacao",
      render: (valor) => {
        return getFormatDateAndTimeNoTz(valor);
      },
    },
    {
      title: "Data de Expiração",
      dataIndex: "dataExpiracao",
      render: (valor) => {
        return getFormatDateAndTimeNoTz(valor);
      },
    },

    {
      title: "Data de Utilizacao",
      dataIndex: "dataUtilizacao",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      render: (valor) => {
          return valor.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Cancelar Reserva",
      dataIndex: "nsuOrigem",
      align: "center",
      render: (nsuOrigem) => (
        <>
          {permissions.includes("cancel_reserve_balance") && (
            <button
              type="button"
              className="btn-cancel"
              onClick={() => handleOpenModal(nsuOrigem)}
            >
              Cancelar Reserva
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
    <S.TableContainer>
      <Table
        className="tableListsadjustment not-global"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }} />
    </S.TableContainer>

    </>
  );
}

export default React.memo(UsersTable);
