import { useCallback, useEffect, useState } from 'react';

import Api from '../services/api';
import { invalidCardStatus } from '../utils/cardStatus';
import { useClient } from './ClientProvider';

const useHolderCard = (clientId, productId, clientCpf) => {
  const [loadingHolderCard, setLoadingHolderCard] = useState(false);
  const [holderCards, setHolderCards] = useState([]);
  const [invalidCardsHistory, setInvalidCardsHistory] = useState([]);
  const {
    client: {
      client: { isProdutoBandeirado },
      product: { cartoes },
    },
  } = useClient();

  const getDataFormatedCards = useCallback(async (isCanceled = false) => {
    try {
      await setLoadingHolderCard(true);
      let clientCardsResponse;
      try {
        clientCardsResponse = await Api.get('client_cards', {
          client_id: clientId,
        });
      } catch (error) {
        console.error(error);
        if(isCanceled){
          return setHolderCards([]);
        }
      }

      setHolderCards(clientCardsResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHolderCard(false);
    }
  }, [clientId, productId]);

  useEffect(() => {
    if (clientId) {
      getDataFormatedCards();
    }
  }, [clientId, getDataFormatedCards]);

  const getDataHistory = useCallback(async () => {
    try {
      let data;

      if (isProdutoBandeirado) {
        const response = await Api.get('co_branded_cards', {
          client_id: clientId,
          product: cartoes?.[0]?.funcaoCartao
            ? cartoes?.[0]?.funcaoCartao
            : 'CREDITO',
        });

        const ArrayCartaoTitularEdit = Array.isArray(
          response.data.cartaoTitular
        )
          ? new Array(...response.data.cartaoTitular)
          : new Array(response.data.cartaoTitular);
        const ArrayCartaoVirtualEdit = Array.isArray(
          response.data.cartaoVirtual
        )
          ? new Array(...response.data.cartaoVirtual)
          : new Array(response.data.cartaoVirtual);
        const ArrayCartoesAdicionaisEdit = Array.isArray(
          response.data.cartoesAdicionais
        )
          ? new Array(...response.data.cartoesAdicionais)
          : new Array(response.data.cartoesAdicionais);
        const ArrayCartoesAnterioresEdit = Array.isArray(
          response.data.cartoesAnteriores
        )
          ? new Array(...response.data.cartoesAnteriores)
          : new Array(response.data.cartoesAnteriores);
        const ArrayCartoesFuturosEdit = Array.isArray(
          response.data.cartoesFuturos
        )
          ? new Array(...response.data.cartoesFuturos)
          : new Array(response.data.cartoesFuturos);

        data = [
          ...ArrayCartaoTitularEdit,
          ...ArrayCartaoVirtualEdit,
          ...ArrayCartoesAdicionaisEdit,
          ...ArrayCartoesAnterioresEdit,
          ...ArrayCartoesFuturosEdit,
        ];
      } else {
        const response = await Api.get('client_cards_history', {
          client_cpf: clientCpf,
        });
        data = response.data;
      }

      const filterCards = data.filter((n) => n);

      // TODO: Passar esse filtro para o backend
      let formatedCards = filterCards.map((card) => ({
        ...card,
        nome: card.nomePortadorCartao,
        status: card.statusCartao,
        dataValidade: card.dataExpiracao,
        numeroMascarado: card.panMascarado,
        dataEmissao: card.dataEmissao,
        permiteReativar: card?.cancelamentoCartao?.tipoCancelamento?.id === 29
      }));

      // TODO: Passar esse filtro para o backend
      formatedCards = formatedCards.filter(
        (c) =>
          invalidCardStatus.includes(c?.statusCartao) &&
          (c.idPortadorCredito === clientId || c.idPortadorDebito === clientId)
      );
      await setInvalidCardsHistory(formatedCards);
    } catch (error) {
      console.error('Falha ao buscar o histórico de cartões do cliente');
    }
  }, [clientCpf, clientId, cartoes, isProdutoBandeirado]);

  useEffect(() => {
    if (clientCpf) {
      getDataHistory();
    }
  }, [clientCpf, getDataHistory]);

  const unBloqCard = useCallback(async (card) => {
    await setHolderCards((previousCards) =>
      previousCards.map((previousCard) => {
        if (previousCard.id === card.id) {
          previousCard.status = 'ATIVO';
          previousCard.bloqueioCartao = null;
        }
        return previousCard;
      })
    );
  }, []);

  const bloqCard = useCallback(async (card, bloqData) => {
    await setHolderCards((previousCards) =>
      previousCards.map((previousCard) => {
        if (previousCard.id === card.id) {
          previousCard.status = 'BLOQUEADO';
          previousCard.bloqueioCartao = bloqData;
        }
        return previousCard;
      })
    );
  }, []);

  const cancelCard = useCallback(async (card) => {
    await setHolderCards((previousCards) =>
      previousCards.filter((previousCard) => previousCard.id !== card.id)
    );
  }, []);

  const copyCardHolder = useCallback(async (previousId, newId) => {
    await setHolderCards((cards) =>
      cards.map((card) => {
        if (Number(card.id) === Number(previousId)) {
          card.id = newId;
          card.permit_print = true;
        }
        return card;
      })
    );
  }, []);

  return {
    bloqCard,
    cancelCard,
    copyCardHolder,
    getDataFormatedCards,
    holderCards,
    invalidCardsHistory,
    loadingHolderCard,
    unBloqCard,
    getDataHistory
  };
};

export default useHolderCard;
