const fullyMessages = [
  {from: "Access forbidden. You are not allowed to this resource.", to: "Você não tem permissão para este recurso."},
  {from: "Erro de comunicacao com o Osiris.", to: "Falha de comunicação.Por favor, tente novamente."},
]

const partialMessages = [
  {from: "ARGUMENTO_INVALIDO", to: "Erro de validação. Por favor, verifique se há algum campo inválido e tente novamente."},
  {from: "Connection refused", to: "Falha de comunicação. Por favor, tente novamente."}
]

const isParsedError = (errorMessage) => {
  if (!errorMessage || typeof errorMessage !== "string") {
    return;
  }

  const hasFullMessage = fullyMessages.find(message => errorMessage === message.from);

  if (hasFullMessage) {
    return hasFullMessage.to;
  }

  const hasPartialMessage = partialMessages.find(message => errorMessage.includes(message.from));

  if (hasPartialMessage) {
    return hasPartialMessage.to;
  }
};

module.exports = {
  isParsedError,
};
