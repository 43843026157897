import React from "react";

import { FaPlus } from "react-icons/fa";

import DataTable from "../../../components/DataTable";
import * as TableStyle from "../../../components/DataTable/style";
import BreadCrumbNavigation from "../../../components/DataTable/TopSection/BreadCrumbNavigation";
import HeaderWithAction from "../../../components/DataTable/TopSection/HeaderWithAction";
import { useAuth } from "../../../hooks/AuthProvider";
import * as S from "../style";

const DataTableGroups = ({ loading, modalAction, data, editRow }) => {
  const { user } = useAuth();

  const tableHeadersAndFilters = [
    { id: "ID", filterType: 'text' },
    { name: "Nome", filterType: 'text' },
    // eslint-disable-next-line eqeqeq
    (user?.type_user !== 'Merchant' && user?.type_user != 1) && { type_role: "Origem", filterType: 'text' },
    { actions: "Ações", filterType: null, allowedActions: ["edit"] },
  ].filter(Boolean);


  return (
      <>
        <BreadCrumbNavigation tableName="Grupos" />
        <TableStyle.Content>
          <HeaderWithAction
            tableName="Grupos"
            createItemButton={
              <S.NewUserButton onClick={() => modalAction()}>
                <FaPlus /> Novo Grupo
              </S.NewUserButton>
            } />
          <DataTable
            data={data}
            loading={loading}
            tableHeadersAndFilters={tableHeadersAndFilters}
            tableName="Grupos"
            onUpdateItem={{action: editRow}}
          />
        </TableStyle.Content>
    </>
  );
};

export default DataTableGroups;
