/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import { React, useCallback, useEffect, useState } from 'react';

import { Button, Modal, Select } from 'antd';

import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import * as S from './styles';

const { Option } = Select;

function ModalChangeInvoiceDueDate({
  open,
  closeModal,
  cicloValue,
  dateCiclos,
  value,
}) {
  const [dateCiclo, setDateCiclo] = useState(cicloValue);
  const [newdate, setNewDate] = useState('');

  const [erro, setErro] = useState('');

  const { client } = useClient();

  useEffect(() => {
    if (erro !== '') {
      notifyError(`Não foi possível realizar alteração: ${erro}`);
      setErro('');
    }
  }, [erro]);
  const clientId = client.client.id;
  const ifChangeCiclo = useCallback(() => {
    try {
      return api.get('clientId', { client_id: clientId });
    } catch (error) {
      console.error('Falha ao consultar ciclo do cliente');
      return undefined;
    }
  }, [clientId]);

  async function handleChangeDay() {
    const data = await ifChangeCiclo();
    let novoCic = await dateCiclos.filter((d) => d.id === newdate);
    novoCic = novoCic[0].diaVencimento;

    if (data.data.faturaFutura === null) {
      setErro('Cliente possui valores de fatura em aberto');
      closeModal();
    } else {
      try {
        await api.patch('change_invoice_due_date', {
          client_id: client.client.id,
          idciclovencimento: newdate,
          cicloAnterior: client.information.diaVencimento,
          novoCiclo: novoCic,
        });
        notifySucces('Data de vencimento alterada com sucesso');
        closeModal();
      } catch (error) {
        notifyError(
          'Erro! O cliente teve seu ciclo alterado nos últimos 30 dias ou possui alguma fatura em aberto.'
        );
      }
    }
  }
  async function handleGoBack() {
    closeModal();
  }

  return (
    <Modal visible={open} width={600} onCancel={closeModal}>
      <S.ModalContainer>
        <S.ModalTitle>
          <h1>Alterar data de vencimento</h1>
        </S.ModalTitle>

        <p />
        <p />
        <span>Digite aqui o dia desejado</span>
        <Select defaultValue={dateCiclo} onChange={setNewDate}>
          <Option value="0">Selecione</Option>
          {dateCiclos.map((dateCiclo) => (
            <Option value={dateCiclo.id} key={dateCiclo.id}>
              {dateCiclo.diaVencimento}
            </Option>
          ))}
        </Select>

        <S.BtnRow>
          <div className='btn-content'>
            <S.BtnContentCancelar>
              <Button onClick={() => handleGoBack()}>Cancelar</Button>
            </S.BtnContentCancelar>
           </div>
          <div className='btn-content'>
            <S.BtnContentConcluir>
              <Button onClick={() => handleChangeDay()}>Concluir</Button>
            </S.BtnContentConcluir>
           </div>
        </S.BtnRow>
      </S.ModalContainer>
    </Modal>
  );
}
export default ModalChangeInvoiceDueDate;
