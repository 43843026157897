import React, { memo } from "react";

import * as AntIcon from "@ant-design/icons";

import Can from "../../../components/Can";
import * as S from "../style";

function ActionsSection({
  row,
  onUpdateUser,
  onRestoreUser,
  onDeleteUser
}) {
  return (
    <S.ActionsSection>
      <div onClick={ () => onUpdateUser(row, true)}>
        <AntIcon.EyeOutlined className="action-icon"/>
      </div>
      <div onClick={ () => onUpdateUser(row)}>
        <AntIcon.EditOutlined className="action-icon"/>
      </div>
      <div
        onClick={ () => row.deleted_at ? onRestoreUser(row) : onDeleteUser(row) }>
        { row.deleted_at ?
          (
            <Can permission="reactivate_users">
              <AntIcon.ReloadOutlined className="action-icon"/>
            </Can>
          ) :
          (
            <Can permission="delete_user">
              <AntIcon.StopOutlined className="action-icon"/>
            </Can>
          )
        }
      </div>
    </S.ActionsSection>
  )
}

export default ActionsSection;
