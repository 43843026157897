import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import { Button, Modal, Select, Skeleton } from "antd";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import Card from "../../../components/Card";
import BreadCrumbNavigation from "../../../components/DataTable/TopSection/BreadCrumbNavigation";
import SwitchInput from "../../../components/SwitchInput";
import { useAuth } from "../../../hooks/AuthProvider";
import api from "../../../services/api";
import { getPlaces } from '../../../utils/getStores';
import { notifyError, notifySucces } from "../../../utils/notifications";
import { validaCpf } from "../../../utils/validations";
import * as S from "./style";

const { Option } = Select;

function SellModal({ onCancel }) {
  const { user } = useAuth();

  const [lojas, setLojas] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState(false);

  const [selectedProductValue, setSelectedProductValue] = useState("");
  const [dataUser, setDataUser] = useState([]);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [listClientAccount, setListClientAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [idClient, setClientId] = useState(0)
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [nameClient, setNameClient] = useState("");
  const [clientProducts, setClientProducts] = useState([]);
  const [accountProducts, setAccountProducts] = useState([]);
  const [formSteps, setFormSteps] = useState(0);
  const [product, setProduct] = useState({});
  const [hasClickButton,setHasClickButton] = useState(false);
  const [filteredStores, setFilteredStores] = useState();
  const [loadingStores, setLoadingStores] = useState(false);

  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "cnpj") setCurrentMask("99.999.999/9999-99");
    if (doc === "cartao") setCurrentMask("9999 9999 9999 9999 999");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);

  const getLojas = useCallback(async () => {
    try {
      const places = await getPlaces(user);
      const editList = places
        .filter((a) => a.id >= 1)
        .map((item) => {
          return {
            value: item.id,
            label: item.nomeFantasia,
            cnpj: item.cnpj,
          };
        });

      setLojas(editList);
    } catch (error) {
      console.error("Falha ao listar lojas");
    }
  }, []);

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "cnpj") text = "do CNPJ";
    if (currentDoc === "cartao") text = "do Cartão";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const fetchUser = useCallback(async () => {
    try {
      const response = await api.get("getUser");
      const aux = response.data.map((r) => {
        r.key = r.id;
        r.status = r.deleted_at ? "Inativo" : "Ativo";
        return r;
      });
      setDataUser(aux);
    } catch (err) {
      notifyError("Erro ao listar usuários", err.message);
    }
  }, []);


  useEffect(() => {
    getLojas();
    fetchUser();
  }, []);

  const resetSelections = async () => {
    setSelectedProductValue("");
    await setFilteredStores(null);
    await setAccountProducts(null);
  };

  const handleAccountSelected = useCallback(async (value, data=[]) => {
    await resetSelections();
    await setClientId(value);
    await setSelectedAccount(value);

    let selectedClient;
    if(data.length === 0) {
      selectedClient = clientProducts.find(client => client.id === value);
    } else {
      selectedClient = await data.find(client => client.id === value);
    }

    if (selectedClient) {
      await setAccountProducts([selectedClient]);
      await setNameClient(selectedClient.name);
    } else {
      console.error(`Cliente com ID ${value} não encontrado.`);
    }

  }, [clientProducts,nameClient]);

  const handleTransaction = async (values) => {
    let dataHoraTerminal = moment().format("YYYY-MM-DDTHH:mm:ss");
    dataHoraTerminal += "Z";

    let cnpjPlace = lojas.filter(
      (pl) => Number(pl.value) === Number(values.redeOrigemOperacaoPDV)
    );
    cnpjPlace = cnpjPlace[0].cnpj;
    await setHasClickButton(true);
    try {
      if (selectedAccount == null || selectedAccount === '0') return notifyError('Informe a conta do portador');

      await api.post("make_sale_manual", {
        client_id: idClient,
        dataHoraTerminal,
        numeroParcelas: values.parcelas,
        valorTransacao: values.transacao,
        redeOrigemOperacaoPDV: cnpjPlace,
        parceladoAdmin: values.parceladoAdmin ? "S" : "N",
      });
      notifySucces("Lançamento de venda efetuado com sucesso");
    } catch (error) {
      notifyError("Erro no lançamento de venda.");
    } finally {
      setHasClickButton(false);
      onCancel();
    }

  };

  const handleStepOne = async (values) => {
    try {
      setLoadingBtn(true);

      const response = await api.get('getAccount', {
        dataDocument: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
        document: currentDoc
      });

      if (!Array.isArray(response.data)) {
        response.data = response.data ? [response.data] : [];
      }

      if (!response.data || (Array.isArray(response.data) && response.data.length === 0)) {
        throw new Error("Cliente não encontrado");
      }

    const uniqueAccountSelectList = response.data.map((item) => ({
      id: item.id,
      accountSelect: item.accountSelect,
      name: item.name
    }));

    await setClientProducts(response.data);
    await setListClientAccount(uniqueAccountSelectList);
    if(uniqueAccountSelectList.length === 1) {
      handleAccountSelected(uniqueAccountSelectList[0].id, response.data);
    }

    setTimeout(() => {
      setLoadingBtn(false);
      setFormSteps(1);
    }, 1000);
    } catch (error) {
      setLoadingBtn(false);
      notifyError(error.message);
    }

  };

  const extractCardInfo = (card) => {
    const virtualOrPhysicCard = card?.virtual ? "Virtual" : "Físico";
    const cardStatusFormmated = card?.status.replace(/_/g, " ");
    const formmatedCardInfo = `${card?.numeroMascarado} (${virtualOrPhysicCard}) - ${cardStatusFormmated}`;
    return formmatedCardInfo;
  }

  const handleProduct = useCallback(
    async (value) => {
      if (value !== "none") {
        const productSelectedArr = clientProducts.filter(
          (cp) => cp.id === Number(value)
        );
        const productSelected = productSelectedArr[0];
        if(productSelected?.desc_prod) {
          setSelectedProductValue(productSelected.desc_prod);
        } else {
          const cardOfProduct = productSelected?.cartoes[0];
          const cardInfoFormmated = extractCardInfo(cardOfProduct);
          setSelectedProductValue(cardInfoFormmated);
        }

        setLoadingStores(true);

        const filterUserStores = dataUser.filter((item) => item.id === user?.id);

        if (filterUserStores[0]?.store && filterUserStores[0]?.store.length > 0) {
          const editIDstoFilter = filterUserStores[0].store
            .split(",")
            .map(Number);

          const filterStoresForUser = lojas.filter((store) =>
            editIDstoFilter.includes(store.value)
          );

          setProduct(productSelected);
          setFilteredStores(filterStoresForUser);

          setTimeout(() => {
            setLoadingStores(false);
          }, 500);
        } else {
          setProduct(productSelected);
          setFilteredStores(lojas);

          setTimeout(() => {
            setLoadingStores(false);
          }, 500);
        }
      }
    },
    [clientProducts, selectedAccount]
  );

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }

  const mascaraMoeda = (event, setFieldValue) => {
    const onlyDigits = event.target.value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");

    const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
    setFieldValue("transacao", maskCurrency(digitsFloat));
  };

  const handleResetSearch = async () => {
      await setFormSteps(0);
      await setNameClient(null);
      await setClientId(null);
      await setClientProducts(null);
      await setAccountProducts(null);
      await setSelectedAccount(null);
      await setFilteredStores(null);
      await setProduct({});
  }

  return (
    <S.Container>
      <BreadCrumbNavigation tableName="Lançar venda" preTableName="Vendas e Pagamentos"   />
        {formSteps > 0 && (
          <div className="select-btn">
            <Button
              style={{ marginLeft: 22, marginBottom: 15 }}
              onClick={handleResetSearch}
              type="ghost"
            >
              Nova Pesquisa
            </Button>
          </div>
        )}
      <Card title="Lançamento de vendas">
        <S.ContainerCreateEdit>
        {formSteps === 0 && (
          <Formik
            initialValues={{ doc: "" }}
            validationSchema={Yup.object({
              doc: Yup.string()
                .required(`${currentDoc.toUpperCase()} obrigatório`)
                .test(
                  "test_cpf",
                  `Informe um ${currentDoc} válido`,
                  (value) => {
                    if (currentDoc === "client_id") return true;
                    if (currentDoc === "cpf") {
                      if (value === undefined || value === null) return false;

                      const cpfFormated = value
                        .split(".")
                        .join("")
                        .replace("-", "");
                      return validaCpf(cpfFormated);
                    }
                    if (currentDoc === 'cartao') {
                      const cardFormated = value ? value
                        .replace(/[./-]/g, '')
                        .replace(/\s/g, '') : '';
                      let cardSize = 16;
                      if( cardFormated.length === 19) cardSize = 19;

                      return cardFormated.length === cardSize;
                    }
                  }
                ),
            })}
            onSubmit={handleStepOne}
          >
            {() => (
              <Form>
                <S.FormRow>
                  <div>
                    <b>Tipo:</b>
                  </div>
                  <S.CpfCnpj>
                    <S.DocButton
                      selected={currentDoc === "cpf"}
                      onClick={handleCPF}
                    >
                      CPF
                    </S.DocButton>
                    <S.DocButton
                      selected={currentDoc === "client_id"}
                      onClick={handleClientId}
                    >
                      ID Cliente
                    </S.DocButton>
                  </S.CpfCnpj>

                  <Field name="doc">
                    {({ field, meta }) => (
                      <ReactInputMask
                        placeholder={setPlaceholder()}
                        type="text"
                        mask={currentMask}
                        maskChar={null}
                        autoComplete="off"
                        className={
                          meta.touched && meta.error ? "error-id" : null
                        }
                        {...field}
                      />
                    )}
                  </Field>

                  <ErrorMessage name="doc" className="error" component="div" />
                </S.FormRow>

                <S.FormRow>
                  <Button loading={loadingBtn} htmlType="submit" type="primary">
                    Buscar
                  </Button>
                </S.FormRow>
              </Form>
            )}
          </Formik>
        )}

        {formSteps === 1 && (
          <>
            <S.FormRow>
              <div>
                <b>Conta:</b>
              </div>
              { listClientAccount.length > 1 ?
                  (
                    <Select
                      placeholder="Selecionar conta"
                      /* onChange={handleAccountSelected} */
                      onChange={(value) => handleAccountSelected(value, [])}
                    >
                      {listClientAccount && listClientAccount.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.accountSelect}
                        </Option>
                      ))}
                    </Select>
                  ) :
                  (
                    <Select disabled value={listClientAccount[0].accountSelect}/>
                  )
              }
            </S.FormRow>

            {selectedAccount  && (
            <><S.FormRow>
                <div>
                  <b>Nome Cliente:</b>
                </div>

                <ReactInputMask
                  type="text"
                  value={nameClient}
                  autoComplete="off"
                  disabled />
              </S.FormRow><S.FormRow>
                  <div>
                    <b>Produto</b>
                  </div>
                  <Select id="products_list" onChange={handleProduct} value={selectedProductValue}>
                    <Option value="none" key="none">
                      Selecione o produto
                    </Option>
                    {accountProducts && accountProducts.map((cp) => (
                      <Option value={cp.id} id={cp.id} key={cp.id}>
                        <b>{cp?.desc_prod}</b>
                        <div>
                          {cp.cartoes.map((cartao) => (
                            <>
                              <span>
                                {cartao.numeroMascarado} (
                                {cartao.virtual ? "Virtual" : "Físico"}) -{" "}
                                {cartao.status.replace(/_/g, " ")}
                              </span>
                              <br />
                            </>
                          ))}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </S.FormRow></>
            )}
            {filteredStores && (
              <>
                <hr />
                {loadingStores ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div style={{ marginTop: 16 }}>
                      <Formik
                        initialValues={{
                          transacao: "",
                          parcelas: "",
                          redeOrigemOperacaoPDV: "",
                          parceladoAdmin: false,
                        }}
                        validationSchema={Yup.object({
                          transacao: Yup.string()
                            .required("Campo obrigatório")
                            .nullable(),
                          parcelas: Yup.string()
                            .required("Campo obrigatório")
                            .nullable(),
                          redeOrigemOperacaoPDV: Yup.string()
                            .required("Campo obrigatório")
                            .nullable(),
                        })}
                        onSubmit={handleTransaction}
                      >
                        {({ setFieldValue, values }) => (
                          <Form>
                            <S.FormRow>
                              <S.FormLabel>
                                <b>Selecionar loja</b>
                              </S.FormLabel>

                              <Field name="redeOrigemOperacaoPDV">
                                {({ field, meta }) => (
                                  <Select
                                    className={`${
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    } `}
                                    {...field}
                                    placeholder="Selecione a loja"
                                    style={{ width: 120 }}
                                    loading={!filteredStores}
                                    options={filteredStores}
                                    onChange={(e) => {
                                      setFieldValue("redeOrigemOperacaoPDV", e);
                                    }}
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="redeOrigemOperacaoPDV"
                                className="error"
                                component="div"
                              />
                            </S.FormRow>
                            <S.FormRow>
                              <div>
                                <b>Valor da transação:</b>
                              </div>

                              <Field name="transacao">
                                {({ field, meta }) => (
                                  <ReactInputMask
                                    placeholder="Valor da transação"
                                    type="text"
                                    autoComplete="off"
                                    className={
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    }
                                    {...field}
                                    value={values.transacao}
                                    onChange={(e) =>
                                      mascaraMoeda(e, setFieldValue)
                                    }
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="transacao"
                                className="error"
                                component="div"
                              />
                            </S.FormRow>
                            <S.FormRow>
                              <div>
                                <b>Parcelas:</b>
                              </div>

                              <Field name="parcelas">
                                {({ field, meta }) => (
                                  <ReactInputMask
                                    placeholder="Numero de parcelas"
                                    type="text"
                                    autoComplete="off"
                                    mask="99"
                                    maskChar={false}
                                    className={
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    }
                                    {...field}
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="parcelas"
                                className="error"
                                component="div"
                              />
                            </S.FormRow>
                            <S.FormRow>
                              <div>
                                <b>Parcela Administradora:</b>
                              </div>

                              <Field name="parceladoAdmin">
                                {({ field }) => (
                                  <SwitchInput
                                    {...field}
                                    id="SMS"
                                    isOn={values.parceladoAdmin}
                                    onColor="#FAAD14"
                                    handleToggle={() =>
                                      setFieldValue(
                                        "parceladoAdmin",
                                        !values.parceladoAdmin
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </S.FormRow>

                            <S.FormRow>
                              <S.ButtonContainer>
                                <Button htmlType="submit" disabled={hasClickButton} loading={false}>
                                  Confirmar venda
                                </Button>
                              </S.ButtonContainer>
                            </S.FormRow>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        </S.ContainerCreateEdit>
       </Card>
    </S.Container>
  );
}

export default memo(SellModal);
