import React from 'react';

import { Button } from 'antd';
import classnames from 'classnames';

import styles from '../style.module.scss';

function Footer({
  step,
  goNext,
  goBack,
  isLoading,
  onCloseClick,
  isActivationFinished,
  selectedInstallmentOption,
}) {
  return (
    <div className={styles.modal__footer}>
      <span>
        <Button
          onClick={goBack}
          disabled={isActivationFinished || isLoading}
          className={classnames({
            [styles.hidden]: step < 1,
          })}
        >
          Voltar
        </Button>
      </span>

      <div>
        <Button
          type="ghost"
          onClick={onCloseClick}
          disabled={isActivationFinished || isLoading}
        >
          Cancelar
        </Button>

        <Button
          type="primary"
          onClick={goNext}
          className={styles.modal__footer__button_next}
          disabled={
            isActivationFinished || isLoading || !selectedInstallmentOption
          }
        >
          {step === 0 ? 'Avançar' : 'Confirmar'}
        </Button>
      </div>
    </div>
  );
}

export default React.memo(Footer);
