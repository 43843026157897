import styled, { css } from "styled-components";

export const WrapperModalRequestHome = styled.div`
  p {
    padding: 0;
    margin: 0;
  }

  .modal-request-home__header {
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    padding: 16px 0;
    margin-bottom: 16px;
    display: flex;

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 18.75px;
      text-align: left;
    }

    span {
      height: 24px;
      padding: 0px 12px 0px 12px;
      gap: 10px;
      border-radius: 50px;
      opacity: 0px;
      margin-left: 10px;
      background: #faad14;

      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;

      &.Cancelado {
        background: #ff4d4f;
        color: white;
      }

      &.Ativo,
      &.Resolvido {
        background: #52c41a;
        color: white;
      }
    }
  }

  .modal-request-home__left,
  .modal-request-home__right {
    width: 100%;
  }

  .user-info__search {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 16px;

    .user-info__search-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border-left: 4px solid #ffb32c;
      padding-top: 14px;
      padding-bottom: 12px;
      padding-left: 16px;

      .user-info__search-infos {
        width: 100%;

        .item-user-info {
          width: 100%;
          margin-top: 2rem;

          display: flex;

          align-items: center;
          justify-content: space-between;

          button {
            width: 62px;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            opacity: 0px;
            background: #ffb32c;
            border: 0;

            svg {
              font-size: 24px;
            }
          }

          p {
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #4b5563;
            margin-bottom: 8px;

            span {
              color: #000;
            }

            &.email {
              font-size: 12px;

              span {
                font-size: 14px;
              }
            }
          }
        }
      }

      .user-info__search-item__header {
        width: 100%;
        p {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          font-family: "Inter", sans-serif;
          margin-bottom: 8px;
        }

        .tags {
          width: 100%;
          margin-top: 9px;
          display: flex;
          align-items: center;

          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            padding: 0;
            margin: 0;
            margin-right: 12px;
          }

          svg {
            margin-left: 8px;
            font-size: 18px;
            color: #ff4d4f;
          }

          span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1px 8px;
            gap: 10px;

            width: auto;
            height: 22px;

            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;

            &.ativo {
              color: #52c41a;
              background: rgba(82, 196, 26, 0.05);
              border: 1px solid #52c41a;
            }

            &.bloqueado {
              color: #ff4d4f;
              background: rgba(255, 84, 84, 0.05);
              border: 1px solid #ff4d4f;
            }

            &.inativo {
              color: #212733;
              background: rgba(0, 0, 0, 0.05);
              border: 1px solid #212733;
            }

            &.cancelado {
              color: #ef7d00;
              border: 1px solid var(--orange-2, #ef7d00);
              border: 1px solid #ef7d00;
            }
          }
        }
      }
    }
  }

  .modal-request-home__title {
    width: 100%;

    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 24px;
    margin-bottom: 16px;

    .modal-request-home__title--left {
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin-top: 16px;

        li {
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
    }

    .modal-request-home__title--right {
      display: flex;
      margin-left: auto;
      span {
        border-left: 1px solid #ccc;
        padding-left: 8px;

        p {
          color: red;
          height: 33px;
          padding: 4px 8px 4px 8px;
          gap: 4px;
          border-radius: 2px;
          border: 1px solid red;
          opacity: 0px;
        }
      }
    }
  }

  .modal-request-home__events {
    width: 100%;

    p {
      padding: 0;
      margin: 0;
    }

    .modal-request-home__events--title {
      width: 100%;
      margin-bottom: 24px;

      p {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }
    }

    .modal-request-home__events--content {
      width: 100%;

      .loading-events{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
      }

      .list-events {
        width: 100%;
        max-height: 250px;
        overflow-y: scroll;
        padding-right: 10px;

        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-track {
          background-color: #eaeaea;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #b0b0b0;
          border-radius: 6px;
          cursor: pointer;
        }
        ::-webkit-scrollbar-thumb:hover {
          background-color: #ffb32c;
        }
      }

      .button-comments {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;

        button {
          padding: 8px 16px;
          border-radius: 4px;
          border: 1px solid #1890ff;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #1890ff;
          background: transparent;
          cursor: pointer;
          margin-left: auto;
          transition: all 0.3s ease-in-out;

          &:hover {
            background: #1890ff;
            color: #fff;
          }
        }
      }

      .comments-area {
        width: 100%;
        margin-top: 2rem;

        .relative-comment{
          width: 100%;
          position: relative;
        }

        .comment-error{
          position: absolute;
          bottom: 15px;
          right: 15px;
          color: red;
        }

        .header-comment {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 14px;
          }
        }

        .btns-comment {
          width: 100%;

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;

          button {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #1890ff;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #1890ff;
            background: transparent;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
              background: #1890ff;
              color: #fff;
            }

            &[type="button"] {
              border-color: red;
              color: red;

              &:hover {
                background: red;
                color: #fff;
              }
            }
          }
        }

        textarea {
          width: 100%;
          height: 80px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          padding: 8px;
          font-size: 14px;
          line-height: 22px;
          resize: none;

          &.error-id {
            border: 1px solid red !important;
          }
        }
      }

      .modal-request-home__events--content__item {
        width: 100%;
        display: flex;

        padding-bottom: 2rem;

        .event-icon {
          width: 24px;
          margin-top: 16px;

          svg {
            font-size: 20px;
            color: #1890ff;
          }

          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background: transparent;
            border-left: 1px dashed #ccc;
            margin-left: 5px;
          }
        }

        .event-content {
          width: calc(100% - 24px);

          display: flex;
          justify-content: space-between;
          padding-left: 8px;

          .event-content__left {
            p {
              font-family: Roboto;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
              color: #8c8c8c;

              strong {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;
                text-align: center;
                color: #434343;
              }
            }
          }

          .event-content__right {
            padding-left: 8px;
            p {
              text-align: right;

              font-family: Roboto;
              font-size: 12px;
              font-weight: 400;
              line-height: 22px;
              text-align: right;
              color: #8c8c8c;

              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .modal-request-home__footer {
    width: 100%;
    margin-top: 16px;
    border-top: 1px solid #eaeaea;
    padding-top: 16px;
  }

  @media (min-width: 62rem) {
    display: flex;
    flex-wrap: wrap;

    .modal-request-home__left {
      width: calc(100% - 377px);
    }

    .modal-request-home__right {
      width: 329px;
      padding-left: 24px;
      margin-left: 24px;
      border-left: 1px solid #e5e7eb;
    }

    .user-info__search {
      border-bottom: 0px solid transparent;
      .user-info__search-item {
        .user-info__search-item__header {
          width: 50%;
        }

        .user-info__search-infos {
          width: 50%;
          border-left: 1px solid #e5e7eb;
          margin-left: auto;
          padding-left: 15px;
        }
      }
    }
  }
`;

export const ContentInfoLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: black;
  font-weight: bold;
`;

export const AttachmentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const AttachmentIcon = styled.div`
  margin-right: 6px;
`;
