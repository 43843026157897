import { useCallback, useEffect, useMemo, useState } from 'react';

import { orderBy } from 'lodash';
import moment from 'moment';

import api from '../services/api';
import { notifyError } from '../utils/notifications';
import useCheckPermission from '../utils/useCheckPermission';
import useToggle from './useToggle';

const useFutureInvoices = (clientID) => {
  const [futureInvoices, setFutureInvoices] = useState([]);
  const [loading, toggleLoading] = useToggle();

  const hasPermission = useCheckPermission('view_future_invoices');
  const getData = useCallback(async () => {
    try {
      toggleLoading();
      const {
        data: { faturasFuturas },
      } = await api.get('client_all_future_invoice', { client_id: clientID });

      const sortedArray = orderBy(
        faturasFuturas,
        (item) => moment(item.dataVencimento),
        ['asc']
      );
      const flagInvoice = faturasFuturas?.faturasFuturas?.length;
      if(flagInvoice) {
        setFutureInvoices(sortedArray[0]);
      } else
      if(faturasFuturas?.length > 1){
        setFutureInvoices(sortedArray);
      } else {
        setFutureInvoices([sortedArray[0]]);
        }

    } catch (e) {
      const message = e?.message.split(',')[0] ? e?.message.split(',')[0] : null;
      if (message === 'Informação não encontrada.') {
        notifyError('Informação de Lancamentos Futuros não encontrada');
      } else {
        console.error('Erro ao listar lançamentos futuros');
      }
    } finally {
      toggleLoading();
    }
  }, [clientID]);

  useEffect(() => {
    if (hasPermission) {
      if (clientID) {
        getData();
      }
    }
  }, [getData, hasPermission]);

  return useMemo(
    () => ({
      futureInvoices,
      loading,
    }),
    [futureInvoices, loading]
  );
};

export default useFutureInvoices;
