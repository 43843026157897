import styled, { css } from "styled-components"

export const modalBottomSection = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;

  button {
    width: 70px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 18px;
  }

  button.cancel {
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
  }

  button.apply {
    color: #434343;
    background: #FFB32C;
    border: none;
  }
`
