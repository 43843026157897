import React, { createContext, useState, useEffect, useCallback, useContext, useMemo } from 'react';

import api from '../services/api';

interface VersionContextData {
  hasBetaActive: boolean;
  fetchHasBetaActive: () => Promise<void>;
}

const VersionContext = createContext<VersionContextData>({} as VersionContextData);

export const VersionProvider: React.FC = ({ children }) => {
  const [hasBetaActive, setBetaActive] = useState<boolean>(false);

  const fetchHasBetaActive = useCallback(async () => {
    try {
      const response = await api.get('has_beta_active');
      setBetaActive(response.hasBetaActive);
    } catch (error) {
      console.error("Erro ao buscar versão:", error);
    }
  }, []);

  useEffect(() => {
    const existingToken = localStorage.getItem("@SnxPayment:token")
    if(existingToken)  {
      fetchHasBetaActive();
    }
  }, [fetchHasBetaActive]);

  const contextValue = useMemo(() => ({
    hasBetaActive,
    fetchHasBetaActive,
  }), [hasBetaActive, fetchHasBetaActive]);

  return (
    <VersionContext.Provider value={contextValue}>
      {children}
    </VersionContext.Provider>
  );
};

export const useVersion = () => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};

export default VersionContext;
