import styled from "styled-components";

export const WrapperInfosClient = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--neutral-color-gray-5);
  background: var(--white);
  position: relative;

  .infos-client__header {
    width: 100%;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;

      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      li {
        padding: 16px 41px;
        width: auto;
        color: var(--character-title-85);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        background: var(--neutral-color-gray-4);
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
          background: var(--neutral-color-gray-1);
          color: var(--brand-color-gold-6);
          border-color: var(--brand-color-gold-6);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }
  }

  .infos-client__content {
    width: 100%;
    padding: 24px;

    .content-info {
      width: 100%;

      .content-info-grid-users {
        width: 100%;

        .grid-user__item {
          width: 100%;

          &:not(:first-of-type) {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid var(--neutral-color-gray-5);
          }
        }
      }

      .no-users {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;

        p {
          color: var(--neutral-color-dark-10);
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0;
          margin: 0;

          svg {
            font-size: 18px;
            margin-right: 8px;
          }
        }

        button {
          margin-top: 20px;
          margin-bottom: 24px;

          border-radius: 2px;
          border: 1px solid var(--brand-color-gold-6);
          background: var(--brand-color-gold-6);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
          display: flex;
          padding: 8px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          span {
            display: flex;
            align-items: center;

            color: var(--neutral-color-gray-9);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            svg {
              margin-right: 8px;
              font-size: 16px;
            }
          }
        }
      }

      .client-id {
        width: 100%;

        p {
          color: var(--gray-600);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          padding: 0;
          margin: 0;
        }
      }

      .client-name {
        width: 100%;
        display: flex;
        align-items: center;

        p {
          color: var(--gray-900);
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 0;
          margin: 0 12px 0 0;
          max-width: 275px;
          word-break: break-all;
        }

        svg {
          margin-left: 8px;
          font-size: 18px;
          color: #ff4d4f;
        }

        span {
          .switch {
            display: inline-block;
            position: relative;
            width: 44px;
            height: 24px;
            margin-right: 4px;
            cursor: pointer;

            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
          }

          .slider {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            background-color: #ff6b6b;
            border-radius: 34px;
            transition: background-color 0.3s ease;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 19px;
            width: 19px;
            left: 4px;
            bottom: 4px;
            background-color: #ffffff;
            border-radius: 50%;
            transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
          }

          .switch input:checked + .slider {
            background-color: #66bb6a;
          }

          .switch input:checked + .slider:before {
            transform: translateX(17px);
          }

          .slider:before {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }

          .knob {
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            border-radius: 50%;
            transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);

            svg {
              margin-left: -5px;
              margin-top: 2px;
              font-size: 15px;
            }
          }

          .switch input:checked ~ .knob {
            transform: translateX(17px) translateY(-50%);
          }
          &.ativo {
            border: 1px solid var(--status-color-success);
            color: var(--status-color-success);
            background: rgba(82, 196, 26, 0.05);
          }

          &.bloqueado {
            color: #ff4d4f;
            background: rgba(255, 84, 84, 0.05);
            border: 1px solid #ff4d4f;
          }

          &.inativo {
            color: #212733;
            background: rgba(0, 0, 0, 0.05);
            border: 1px solid #212733;
          }

          &.cancelado {
            color: #ef7d00;
            border: 1px solid var(--orange-2, #ef7d00);
            border: 1px solid #ef7d00;
          }

          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          padding: 0 8px;
          border-radius: 2px;

          &.status-new {
            height: 32px;
            border-radius: 16px;
            padding: 4px;
            display: flex;
            align-items: center;
          }
        }
      }

      .client-more-infos {
        width: 100%;
        margin-top: 12px;
        display: none;

        &.active {
          display: inherit;
        }

        &:not(:first-of-type) {
          p {
            margin-top: 4px;
          }
        }

        p {
          padding: 0;
          margin: 0;

          span {
            color: #111827;
          }
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #4b5563;
        }
      }

      .product-info-section {
        font-weight: 500;
        font-family: Inter;
        font-size: 16px;

        h3 {
          margin: 0;
          color: #4b5563;
          font-size: 15px;
        }

        span {
          color: #111827;
        }

        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin-top: 14px;
        align-items: flex-start;

        .product {
          display: flex;
          column-gap: 10px;
          align-items: center;
        }
      }

      .days-late-section {
        width: 100%;
        margin-top: 14px;
        border-radius: 7px;
        font-weight: 500;
        font-family: Inter;
        font-size: 18px;
        .prefix {
          display: inline-block;
        }

        h3 {
          display: inline-block;
        }

        @media(min-width: 480px) {
          width: 80%;
        }

        @media (min-width: 670px) and (max-width: 991px) {
          width: 445px;
        }

        @media(min-width: 992px) {
          width: 65%;
        }

      }

      .client-separator {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        display: none;

        &.show {
          display: inherit;
        }

        hr {
          border-top: 1px solid var(--neutral-color-gray-5);
          border-bottom: 0;
        }
      }
      .client-phone {
        width: 100%;

        .principal-phone {
          width: 100%;
          p {
            display: flex;
            align-items: center;
            svg {
              font-size: 24px;
              margin-right: 4px;
            }
            color: var(--neutral-color-gray-9);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 0;
            margin: 0;
          }
        }

        .list-icons-phone {
          width: 100%;
          margin-top: 8px;
          padding-top: 8px;
          margin-bottom: 8px;
          padding-bottom: 8px;

          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .list-icons-phone__icon {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            &:not(:last-child) {
              border-right: 1px solid var(--neutral-color-gray-4);
            }

            button {

              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              background: transparent;

              svg {
                font-size: 20px;
                color: var(--neutral-color-gray-6);
              }
            }
          }
        }
      }

      .client-info-email {
        width: 100%;
        display: none;

        &.active {
          display: flex;
          align-items: center;
        }

        .client-info-email__address {
          width: calc(100% - 40px);

          display: flex;
          flex-wrap: wrap;
          align-items: center;
          word-wrap: break-word;

          span {
            display: flex;
            align-items: center;

            &.business {
              svg {
                font-size: 20px;
              }
            }
          }

          svg {
            font-size: 24px;
            margin-right: 4px;
          }

          p {
            padding: 0;
            margin: 0;
            word-wrap: break-word;

            color: var(--character-primary-65);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .client-info-email__btn {
          width: 40px;
          height: 40px;
          display: flex;

          button {
            width: 100%;
            height: 100%;
            border-radius: 2px;
            border: 1px solid var(--brand-color-gold-6);
            background: var(--brand-color-gold-6);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
              background: #ccc;
            }

            &.a,
            &.A {
              border: 1px solid var(--status-color-success, #52c41a);
              background: rgba(82, 196, 26, 0.05);
              color: var(--status-color-success, #52c41a);
            }

            svg {
              font-size: 18px;
            }
          }
        }
      }

      .tab-right__item-right {
        width: 100%;
      }

      .info-address-client {
        width: 100%;
        max-width: 416px;
        border-radius: 2px;
        border: 1px solid var(--brand-color-gold-2, #fff1b8);
        background: var(--conditional-pop-over, #fff);
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
          0px 6px 16px 0px rgba(0, 0, 0, 0.08),
          0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        position: absolute;
        right: 0;
        padding: 24px;
        z-index: 2;

        visibility: hidden;
        transform: translate3d(0, -10px, 0);
        position: absolute;
        bottom: 0;

        &.active {
          opacity: 1;
          visibility: visible;
          bottom: 107px;
          transition: 0.3s;
          transform: translate3d(0px, 0, 0);
        }

        p {
          padding: 0;
          margin: 0;
        }

        .infos-address-client__title {
          width: 100%;
          margin-bottom: 12px;
          svg {
            font-size: 20px;
            margin-right: 12px;
          }

          p {
            display: flex;
            align-items: center;
            color: var(--character-title-85);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }

        .infos-address-client__cep {
          width: 100%;

          p {
            color: var(--character-primary-65, rgba(0, 0, 0, 0.65));
            /* Subheading 16/regular */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding-bottom: 12px;
            border-bottom: 1px solid #d9d9d9;
          }
        }

        .infos-address-client__rua {
          width: 100%;
          padding-top: 8px;

          p {
            color: var(--character-primary-65, rgba(0, 0, 0, 0.65));
            /* Subheading 16/regular */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .client-edit-options {
        width: 100%;
        margin-bottom: 8px;
        display: none;

        &.active {
          display: inherit;
        }

        button {
          width: 100%;
          height: 40px;
          border-radius: 2px;
          border: 1px solid var(--neutral-color-gray-6);
          background: var(--neutral-1);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
          margin-bottom: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          &.edit {
            border-radius: 2px;
            border: 1px solid var(--brand-color-gold-6);
            background: var(--brand-color-gold-6);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
          }

          span {
            display: flex;
            align-items: center;
            color: var(--character-title-85);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            svg {
              font-size: 16px;
              margin-right: 8px;
            }
          }
        }
      }

      .btn-show-more {
        width: 100%;

        button {
          width: 100%;
          display: flex;
          padding: 8px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 2px;
          border: 1px solid var(--neutral-color-gray-6);
          background: var(--neutral-1);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

          p {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0;

            color: var(--character-title-85);
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            svg {
              font-size: 16px;

              transform: rotate(0deg);
              transition: transform 0.4s linear;

              &.active {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 490px) {
    .infos-client__header {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        li {
          width: 100%;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .infos-client__content {
      .content-info {
        .info-address-client {
          transform: translate3d(0, 0, 0);
          bottom: -168px;
          &.active {
            bottom: -168px;
            right: 28px;
          }
        }
        .btn-show-more {
          display: none;
        }

        .content-info-tab1__right {
          display: grid;
          grid-template-columns: 1fr 192px;
          align-items: center;

          .tab-right__item-left {
            border-right: 1px solid #e5e7eb;
            padding-right: 32px;
          }

          .client-separator {
            margin-top: 8px;
            margin-bottom: 8px;

            &.none {
              display: none;
            }
          }
        }

        .content-info-grid-users {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;

          .grid-user__item {
            border-right: 1px solid #e5e7eb;
            padding-right: 15px;
            &:not(:first-of-type) {
              margin-top: 0;
              padding-top: 0;
              border-top: none;
              border-right: none;
              padding-right: 15px;
            }
          }
        }

        .client-name {
          p {
            font-size: 24px;
          }
        }

        .client-more-infos {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          max-width: 400px;

          &.active {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-width: 400px;
          }

          p {
            &:nth-child(odd) {
              border-right: 1px solid #e5e7eb;
              margin-right: 15px;
            }
          }
        }

        .client-phone {
          display: flex;
          flex-wrap: wrap;

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;

          .list-icons-phone {
            padding-top: 0;
            margin-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        .client-info-email {
          display: flex;
        }

        .client-edit-options {
          display: inherit;
          padding-left: 32px;
          button {
            width: 100%;
            margin: 0 auto;

            &:not(:first-of-type) {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .infos-client__content {
      .content-info {
        &.tab1 {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
        }

        &.userGrid {
          display: grid;
          grid-template-columns: 1fr 192px;
        }

        .content-info-tab1__left {
          .client-separator {
            &.none {
              display: none;
            }
          }
        }

        .content-info-tab1__right {
          margin-top: 16px;
        }
      }
    }
  }
`;

export const ModalTitular = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-wrap: wrap;

    .footer-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      border-top: 1px solid #d9d9d9;
      gap: 16px;

      button {
        display: flex;
        width: 239px;
        padding: 8px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: 1px solid var(--neutral-color-gray-6);
        background: var(--neutral-1);
        /* drop-shadow/button-secondary */
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        &:disabled {
          background: #ccc;
        }

        &.submit {
          border-radius: 2px;
          border: 1px solid var(--brand-color-gold-6, #ffb32c);
          background: var(--brand-color-gold-6, #ffb32c);
          /* drop-shadow/button-primary */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

          color: var(--neutral-color-gray-9, #434343);
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          &:disabled {
            background: #ccc;
            border-color: #ccc;
            color: #434343;
          }
        }
      }
    }
  }

  .modal-titular__left {
    width: 100%;
  }

  .modal-titular__title {
    p {
      color: var(--neutral-color-dark-10, #272d3b);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      display: flex;
      align-items: center;

      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid #d9d9d9;

      svg {
        margin-right: 8px;
        font-size: 18px;
      }
    }
  }

  .item-input {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
    position: relative;

    .loading {
      position: absolute;
      right: 11px;
      top: 25px;
      visibility: hidden;
      transform: translate3d(0, -10px, 0);

      &.active {
        visibility: inherit;
        transition: 0.3s;
        transform: translate3d(0px, 0, 0);
      }

      svg {
        animation: spinner 1s infinite linear;
      }
    }

    span.error {
      position: absolute;
      bottom: -18px;
      right: 7px;
      font-size: 11px;
      color: #ff4d4f;
    }

    label {
      width: 100%;

      color: var(--neutral-color-dark-10, #272d3b);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: #ff4d4f;
      }
    }

    .ant-select {
      &.error-id {
        border: 1px solid #ff4d4f;
      }
    }

    input {
      width: 100%;
      padding: 5px 12px;
      border-radius: 2px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-color-gray-1, #fff);

      &.error-id {
        border: 1px solid #ff4d4f;
      }

      &:disabled,
      &:read-only {
        background: #d9d9d9;
      }
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (min-width: 768px) {
    .grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    .grid-3 {
      display: grid;
      grid-template-columns: 1fr 122px 122px;
      gap: 16px;
    }
  }
`;

export const ModalUsers = styled.div`
  width: 100%;

  .modal-scroll-user {
    width: 100%;
    max-height: 320px;
    overflow-y: scroll;
  }

  .content-user {
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 16px;
      border-top: 1px solid var(--gray-200);
      padding-top: 16px;
    }

    .content-user__id {
      width: 100%;
      p {
        color: var(--gray-600);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 0;
        margin: 0;
      }
    }

    .content-user__name {
      width: 100%;
      display: flex;
      align-items: center;

      p {
        color: var(--gray-900);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0;
        margin: 0 12px 0 0;
      }

      span {
        &.ativo {
          border: 1px solid var(--status-color-success);
          color: var(--status-color-success);
          background: rgba(82, 196, 26, 0.05);
        }

        &.bloqueado {
          color: #ff4d4f;
          background: rgba(255, 84, 84, 0.05);
          border: 1px solid #ff4d4f;
        }

        &.inativo {
          color: #212733;
          background: rgba(0, 0, 0, 0.05);
          border: 1px solid #212733;
        }

        &.cancelado {
          color: #ef7d00;
          border: 1px solid var(--orange-2, #ef7d00);
          border: 1px solid #ef7d00;
        }

        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        padding: 0 8px;
        border-radius: 2px;
      }
    }

    .content-user__infos {
      width: 100%;
      margin-top: 12px;

      &:not(:first-of-type) {
        p {
          margin-top: 8px;
        }
      }

      p {
        padding: 0;
        margin: 0;

        span {
          color: #111827;
        }
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #4b5563;
      }
    }
  }

  @media (min-width: 768px) {
    .modal-scroll-user {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2px;
      height: 400px;
    }

    .content-user {
      &:nth-child(2) {
        margin-top: 0;
        border-top: none;
        padding-top: 0;
      }

      &:not(:first-of-type) {
        border-top: none;
      }

      &:nth-child(even) {
        border-left: 1px solid var(--gray-200);
        padding-left: 25px;
      }

      .content-user__name {
        p {
          font-size: 24px;
        }
      }

      .content-user__infos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 400px;

        p {
          &:nth-child(odd) {
            border-right: 1px solid #e5e7eb;
            margin-right: 15px;
          }
        }
      }
    }
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-top: 4%;
`;

export const BtnPhone = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  position: relative;

  .bg-btn {
    width: 100%;
    height: 1px;
    background: #f0f0f0;
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

export const BtnAddress = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  position: relative;

  .bg-btn {
    width: 100%;
    height: 1px;
    background: #f0f0f0;
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

export const InputsContent = styled.div`
  width: 100%;

  & + div {
    margin-left: 4%;
  }
`;

export const InputsLabel = styled.div`
  margin-bottom: 8px;
  span {
    color: var(--neutral-color-dark-10, #272d3b);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg {
    margin-left: 5px;
  }
`;

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  input {
    width: 100%;
    height: 32px;
    padding: 12px;
  }

  input:disabled {
    color: #444;
  }

  span.anticon-loading {
    position: absolute;
    top: 12px;
    right: 15px;

    svg {
      color: #fa8c16;
    }
  }

  .select-uf-alternativo .ant-select-selector {
    height: 52px;
    padding: 9px;

    .ant-select-selection-search .ant-select-selection-search-input {
      height: 52px;
    }
  }
`;

export const TelephoneTypeContent = styled.div`
  width: 50%;
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  div + div {
    margin-left: 10px;
  }
`;

export const TelephoneTrash = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  svg {
    cursor: pointer;
    width: 19px;
    height: 19px;
    margin-left: 7px;
    margin-top: 5px;
  }

  .on {
    color: #52c41a;
    fill: #52c41a;
  }
  .off {
    color: #ccc;
    fill: #ccc;
  }
`;

export const AddButton = styled.button`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 241px;
  height: 32px;
  background: #ffffff;
  border: 1px solid var(--neutral-color-gray-6, #bfbfbf);
  box-sizing: border-box;
  box-shadow: 0px 0px 8px 15px #fff;
  border-radius: 2px;

  color: var(--neutral-color-dark-10, #272d3b);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const TelephoneContainerDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span {
    color: black;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

export const TelephoneDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const ModalButtonContainerTitular = styled.div`
  display: flex;

  button + button {
    margin-left: 10px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 24px;

    border-radius: 2px;
    border: 1px solid var(--brand-color-gold-6);
    background: var(--brand-color-gold-6);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      display: flex;
      align-items: center;

      color: var(--neutral-color-gray-9);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      svg {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
`;

export const ModalButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;

  button + button {
    margin-left: 10px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 24px;

    border-radius: 2px;
    border: 1px solid var(--brand-color-gold-6);
    background: var(--brand-color-gold-6);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      display: flex;
      align-items: center;

      color: var(--neutral-color-gray-9);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      svg {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WrapperNewLoading = styled.div`
  width: 100%;
  min-height: 20vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
