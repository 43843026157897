import styled, { css } from "styled-components";

export const WrapperSearchUserMeet = styled.div`
  width: 100%;
  max-width: 481px;
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  .user-meet__header {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #d9d9d9;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 0;
      margin: 0;
    }
  }
  .user-meet__content {
    padding: 24px 16px;

    .item-select__user {
      width: 100%;
      &:not(:first-child) {
        margin-top: 1rem;
      }

      p.title {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .input-search {
        width: 100%;

        form {
          width: 100%;
          display: flex;
          flex-direction: column;

          .item-input {
            position: relative;
            input {
              width: 100%;
              height: 60px;
              padding: 16px 19px;
              margin-bottom: 24px;
              background: #fff;
              border: 1px solid #ffb32c;
              font-size: 16px;
              border-radius: 40px;
              outline: 0;
              caret-color: #ffb32c;
              color: #434343;

              &.error {
                border-color: #ff4d4f;
              }

              &::placeholder {
                color: #bfbfbf;
                font-weight: 400;
              }
            }

            .error-msg {
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: 0.875rem;
              color: #ff4d4f;
            }
          }

          .user-login__btn {
            width: 100%;
            text-align: center;

            button {
              width: 62px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              cursor: pointer;

              background: #ffb32c;
              border-radius: 50%;
              border: 0;

              svg {
                font-size: 24px;
                color: #272d3b;
              }

              &:hover {
                box-shadow: 0px 2px 8px #ffc53d;
              }
              &:disabled {
                background: #a0a0a0;
                &:hover {
                  box-shadow: 0px 2px 8px #686868;
                }
              }
            }

            .item-loading {
              width: 24px;
              height: 24px;
              border: 3px solid #272d3b;
              border-block-start-color: transparent;
              border-radius: 50%;
              animation: loading 500ms linear infinite;
            }
          }
        }
      }
    }

    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(1turn);
      }
    }

    .item-select__options {
      width: 100%;
      display: flex;

      &.scroll {
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
  }
  @media (min-width: 75rem) {
    margin: ${props => props.viewSolicitation ? "0 auto" : "0"};

    .user-meet__content {
      .item-select__user {
        .item-select__options {
          &.scroll {
            overflow: inherit;
          }
        }

        .input-search {
          form {
            flex-direction: row;

            .item-input {
              width: 100%;

              input {
                margin-bottom: 0;
              }

              .error-msg {
                bottom: -16px;
              }
            }
            .user-login__btn {
              width: 60px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`;

export const WrapperInfosUser = styled.div`
  width: 100%;
  /* background: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); */
  margin: 56px auto 2rem auto;
  max-width: 481px;

  .user-card-select__loading {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-card-select__empty {
    width: 100%;
    height: 288px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      svg {
        font-size: 24px;
        color: #ffb32c;
      }
    }

    p {
      font-family: Inter;
      color: #ffb32c;
      font-weight: bold;
    }
  }

  .infos-search__header {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 0;
      margin: 0;
    }

    button {
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #ffb32c;
      }

      svg {
        margin-left: 5px;
      }
    }
  }

  .user-card-select__clientname {
    width: 100%;

    &:not(:nth-child(1)) {
      margin-top: 21px;
      border-top: 1px solid #d9d9d9;
      padding-top: 21px;
    }

    .client-name__name {
      width: 100%;
      display: flex;
      margin-bottom: 21px;

      svg {
        font-size: 18px;
        margin-right: 12px;
        color: #434343;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #434343;
      }
    }

    .client-name__document {
      width: 100%;
      margin-bottom: 21px;
      margin-left: 40px;

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #4b5563;

        span {
          color: #434343;
        }
      }
    }

    .client-name__btn {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        padding: 5px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffb32c;
        border: 0;
        width: 100%;
        max-width: 137px;
        height: 32px;
      }
    }
  }

  @media (min-width: 75rem) {
    margin-top: 0;
    margin-left: 2rem;
    max-width: calc(100% - 504px);

    .user-card-select__clientname {
      display: flex;
      align-items: center;

      .client-name__name,
      .client-name__document,
      .client-name__btn {
        width: 33.33%;
        margin-bottom: 0;
      }

      .client-name__document {
        text-align: center;
      }

      .client-name__btn {
        justify-content: flex-end;
      }
    }
  }
`;

export const WrapperCardsUserSelect = styled.div`
  width: 100%;
  padding: 0 16px 16px 16px;
  background: #fff;
  padding-top: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);

  .user-card-select__header {
    width: 100%;

    p {
      font-weight: 400;
      font-size: 14px;
      color: #000;
      margin-bottom: 8px;
    }
  }

  .user-card-select__item {
    width: 100%;
    border: 2px solid #d9d9d9;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;

    .icon-check {
      position: absolute;
      right: -2px;
      background: white;
      width: 25px;
      height: 25px;

      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 50px, 0);
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
      }
    }

    &.active {
      border-color: #ffc53d;

      .icon-check {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
        top: -9px;
        right: -9px;
        transform: translate3d(0px, 0, 0);
      }
    }

    .user-card-select__item-header {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      svg {
        margin-right: 10px;
        color: #434343;
        font-size: 20px;
      }

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #434343;
        padding: 0;
        margin: 0;
      }
    }

    .user-card-select__item-content {
      width: 100%;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-weight: 400;
          line-height: 22px;
          color: #595959;
          display: flex;
          align-items: center;
          gap: 2rem;
          margin-block: 0.5rem;

          .status {
            border-radius: 4px;
            padding: 3px;
            text-align: center;
            white-space: nowrap;
            margin-bottom: 0px !important;
            font-size: 12px;
          }

          p.active-card {
            color: #52c41a;
            background: rgba(82, 196, 26, 0.05);
            border: 1px solid #52c41a;
            font-weight: bold;
          }

          p.blocked-card {
            color: #ff4d4f;
            background: rgba(255, 84, 84, 0.05);
            border: 1px solid #ff4d4f;
          }

          @media (max-width: 480px) {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .user-info__loading {
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    .item-loading {
      width: 30px;
      height: 30px;
      border: 3px solid #ffb32c;
      border-block-start-color: transparent;
      border-radius: 50%;
      animation: loading 500ms linear infinite;
    }
  }

  .user-info__search {
    width: 100%;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding-top: 16px;
    padding-bottom: 16px;

    .user-info__search-item {
      width: 100%;
      border-left: 4px solid #ffb32c;
      padding-top: 14px;
      padding-bottom: 12px;
      padding-left: 16px;

      .user-info__search-infos {
        display: none;
        border-left: 1px solid #e5e7eb;
        margin-left: auto;
        padding-left: 15px;

        .item-user-info {
          width: 100%;

          p {
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #4b5563;
            margin-bottom: 8px;

            span {
              color: #000;
            }

            &.email {
              font-size: 12px;

              span {
                font-size: 14px;
              }
            }
          }
        }
      }

      .user-info__search-item__header {
        p {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          font-family: "Inter", sans-serif;
          margin-bottom: 8px;
        }

        .tags {
          width: 100%;
          margin-top: 9px;
          display: flex;
          align-items: center;

          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            padding: 0;
            margin: 0;
            margin-right: 12px;
          }

          svg {
            margin-left: 8px;
            font-size: 18px;
            color: #ff4d4f;
          }

          span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1px 8px;
            gap: 10px;

            width: auto;
            height: 22px;

            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;

            &.ativo {
              color: #52c41a;
              background: rgba(82, 196, 26, 0.05);
              border: 1px solid #52c41a;
            }

            &.bloqueado {
              color: #ff4d4f;
              background: rgba(255, 84, 84, 0.05);
              border: 1px solid #ff4d4f;
            }

            &.inativo {
              color: #212733;
              background: rgba(0, 0, 0, 0.05);
              border: 1px solid #212733;
            }

            &.cancelado {
              color: #ef7d00;
              border: 1px solid var(--orange-2, #ef7d00);
              border: 1px solid #ef7d00;
            }
          }
        }
      }
    }
  }

  .user-info__form {
    width: 100%;
    margin-top: 16px;

    .user-info__form-input {
      width: 100%;
      margin-bottom: 16px;
      position: relative;

      .error-msg {
        position: absolute;
        bottom: 2px;
        right: 5px;
        font-size: 0.875rem;
        color: #ff4d4f;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      input {
        width: 100%;
        height: 40px;
        padding: 8px 12px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: 0;

        &.error {
          border-color: #ff4d4f;
        }

        &:focus {
          box-shadow: 0px 0px 0px 2px rgba(250, 173, 20, 0.2);
          border-color: #ffb32c;
        }
      }
    }

    .user-info__btn {
      width: 100%;
      border-top: 1px solid #d9d9d9;
      padding-top: 1rem;

      button {
        width: 100%;
        height: 60px;
        background: #ffb32c;
        border-radius: 40px;
        border: 0;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 24px;
        }
      }
    }
  }

  @media (min-width: 75rem) {
    .scroll-names-desk {
      width: 100%;
      height: 390px;
      overflow-y: scroll;
      padding-right: 5px;

      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background-color: rgba(243, 243, 243, 0.8);
      }
      ::-webkit-scrollbar-thumb {
        background-color: #ffb32c;
        border-radius: 6px;
        cursor: pointer;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #94bf0b;
      }
    }
    .user-card-select__grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    .user-card-select__item {
      .user-card-select__item-header {
        p {
          font-size: 16px;
        }
      }

      .user-card-select__item-content {
        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }

    .user-info__search {
      .user-info__search-item {
        display: flex;
        align-items: center;

        .user-info__search-item__header {
          max-width: 245px;
        }

        .user-info__search-infos {
          display: flex;
          flex-direction: column;
          min-width: 217px;
        }
      }
    }

    .user-info__form {
      .user-info__form-align {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
      }

      .user-info__form-left {
        width: 100%;

        display: flex;
        justify-content: space-around;

        .user-info__form-input {
          max-width: 185px;
        }
      }
      .user-info__form-right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .user-info__btn {
          border-top: 0;
          padding-top: 0;
          display: flex;
          justify-content: flex-end;

          button {
            max-width: 217px;
          }
        }
      }
    }
  }
  @media (min-width: 1350px) {
    .user-info__form {
      .user-info__form-left {
        width: calc(100% - 249px);

        display: flex;
        justify-content: space-around;

        .user-info__form-input {
          max-width: 185px;
        }
      }
      .user-info__form-right {
        width: 217px;
        border-left: 1px solid #e5e7eb;
        padding-left: 16px;
        margin-left: 16px;

        .user-info__btn {
          border-top: 0;
          padding-top: 0;
          display: flex;
          justify-content: flex-end;

          button {
            max-width: 217px;
          }
        }
      }
    }
  }
`;

export const CenterDivPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3px;
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;

  height: 48px;
  border-radius: 2px;
  font-weight: bold;

  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }

  ${(props) =>
    props.scroll
      ? css`
          width: 100%;
          min-width: 124px;
        `
      : css`
          width: 100%;
        `}

  ${(props) =>
    props.selected
      ? css`
          background: #ffb32c;
          border: 1px solid #ffb32c;
          color: #434343;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}

  @media (min-width: 75rem) {
    ${(props) =>
      props.scroll
        ? css`
            width: 100%;
            min-width: auto;
          `
        : css`
            width: 100%;
          `}
  }
`;

export const WrapperItemRequest = styled.div`
  width: 100%;
  padding: 0 16px 16px 16px;
  background: #fff;
  padding-top: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
`;

export const DivItemRequest = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .divitem-content__left {
    padding-right: 15px;
    padding-left: 8px;
    border-left: 4px solid #ffb32c;

    &.CANCELLED {
      border-color: #ff4d4f;

      p.divitem-content__infos {
        span.divitem-content__user.status {
          color: #ff4d4f;
        }
      }
    }

    p {
      &.divitem-content__protocol {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;

        strong {
          color: #ffb32c;
        }
      }

      &.divitem-content__title {
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: left;
      }

      &.divitem-content__infos {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;

        span {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;

          display: flex;
          align-items: center;

          &:not(:last-child) {
            border-right: 1px solid #ffb32c;
            padding-right: 12px;
            margin-right: 12px;
          }

          svg {
            margin-right: 3px;
          }

          &.divitem-content__user {
            svg {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .divitem-content__right {
    width: auto;
    margin-left: auto;

    button {
      background: #ffb32c;
      padding: 8px 24px 8px 24px;
      gap: 8px;
      border-radius: 2px 0px 0px 0px;
      opacity: 0px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #434343;
      border: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
