import React, { createContext, useState, useEffect, useCallback, useContext, useMemo } from 'react';

import api from '../services/api';

interface CompanyParams {
  name: string;
  has_cobransaas: boolean;
  card_password_length: number;
  cnpj: string,
  has_change_password: boolean;
  crypt_key:boolean;
  has_security: boolean;
  has_annuity:boolean;
}

interface CompanyParamsContextType {
  companyParams: CompanyParams | null;
  isLoading: boolean;
  error: string | null;
  refreshCompanyParams: () => void;
}

export const CompanyParamsContext = createContext<CompanyParamsContextType | undefined>(undefined);

export const CompanyParamsProvider: React.FC = ({ children }) => {
  const [companyParams, setCompanyParams] = useState<CompanyParams | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCompanyParams = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.get('get_company_params');
      setCompanyParams(response);
    } catch (err) {
      setError('Erro ao carregar os parâmetros da empresa');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompanyParams();
  }, [fetchCompanyParams]);

  const refreshCompanyParams = useCallback(() => {
    fetchCompanyParams();
  }, [fetchCompanyParams]);

  const value = useMemo(() => ({
    companyParams,
    isLoading,
    error,
    refreshCompanyParams,
  }), [companyParams, isLoading, error, refreshCompanyParams]);

  return (
    <CompanyParamsContext.Provider value={value}>
      {children}
    </CompanyParamsContext.Provider>
  );
};

export const CompanyParams = (): CompanyParamsContextType => {
  const context = useContext(CompanyParamsContext);
  if (context === undefined) {
    throw new Error('useCompanyParams must be used within a CompanyParamsProvider');
  }
  return context;
};