import { Modal, Select } from 'antd';
import styled from 'styled-components';


export const ModalInsurance = styled(Modal)`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  top: 15px;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.5715;
  }

  .modalIsurance__button {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 10px;
    margin: 0 26px 15px 26px;

      button {
      margin: 0;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      background: var(--brand-color-gold-6);
      border: 1px solid #d9d9d9;
      width: 130px;
      height: 40px;
      padding: 8px 18px 8px 4px;
      color: var(--neutral-color-gray-9);
      font-weight: bold;
      font-size: 16px;
      box-sizing: border-box;
      margin-top: 10px;
    }

    button:hover {
      background: var(--orange-1);
    }

  }
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
  margin: 0 26px 13px 26px;

    button {
      margin: 0;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      background: var(--brand-color-gold-6);
      border: 1px solid #d9d9d9;
      width: 130px;
      height: 40px;
      padding: 8px 18px 8px 4px;
      color: var(--neutral-color-gray-9);
      font-weight: bold;
      font-size: 16px;
      box-sizing: border-box;
      margin-top: 10px;
    }

    button:hover {
      background: var(--orange-1);
      color: var(--neutral-color-gray-1);
    }
`;

export const ModalTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--neutral-color-dark-10);
`;


export const SelectPerson = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 70vh;

  &::-webkit-scrollbar {
    width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--brand-color-gold-6);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--orange-1);
    }

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 26px 8px 26px;
  }


`;

export const PersonContainer = styled.div`
  position: relative;
  max-height: 115px;
  max-width: 524px;
  height: 100%;
  padding: 16px;
  border-radius: 6px;
  border: 2px solid ${props => props.isSelected ? 'var(--brand-color-gold-6)' : 'var(--neutral-color-gray-5)'};
  margin: 0 26px 15px 26px;
  cursor: pointer;
  transition: border-color 0.3s;
  font-family: Roboto;

  &:hover {
    border-color: var(--brand-color-gold-6);
  }

  .personContainer__title {
    h3 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      color: var(--neutral-color-gray-9);

    img {
      width: 20px;
      margin-right: 8px;
    }
  }
}

  p {
    margin: 4px 0;
    color: var(--neutral-color-gray-10);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    strong {
      font-weight: 600;
    }
  }

  .checkmark {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    background-color: var(--brand-color-gold-6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--neutral-color-gray-1);
    visibility: ${props => props.isSelected ? 'visible' : 'hidden'};
  }
`;

export const DividerLine = styled.div`
  border-bottom: 1px solid #f0f0f0;
  margin: 8px 0;
`;

export const DividerLineTwo = styled.div`
  border-bottom: 1px solid #f0f0f0;
  margin: 1px 26px 16px 26px;
`;

export const SelectInsurance = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 26px 8px 26px;
  }

  @media (max-width: 975px) {
    flex-direction: column;
  }
`;

export const SelectedPerson = styled.div`
  display: flex;
  flex-direction: column;
  overflow: none;
  width: 100%;
  flex: 0 0 40%;


`;

export const ContractedInsurances = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  max-height: 250px;
  overflow-y: auto;


    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--brand-color-gold-7);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--orange-1);
    }

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
  }

  @media (max-width: 768px) {
    max-height: 90px;
  }
`;

export const ContractedInsurance = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 26px 2px 26px;
  margin-top: 2px;


  p {
    color: var(--neutral-color-gray-11);
    font-size: 14px;
    font-weight: 400;
    line-height: 8px;
  }

  .contractedInsurance__value {
    color: #434343;
  }
`;

export const VerticalDivider = styled.div`
  // height: 450px;
  width: 1px;
  background-color: #f0f0f0;
  margin: 0px;

  @media (max-width: 768px) {
    height: 2px;
    width: 100%;
    margin: 20px 0;
  }
`;

export const InsuranceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: none;
  // overflow-y: scroll;
  width: 100%;
  margin: 0;
  padding: 0;

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 8px 0;
  }
`;

export const InsuranceContainer = styled.div`
  flex: 0 0 10%;
  margin: 0 26px 15px 26px;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  margin: 0 0 15px 0;
  .ant-select-selection-item {
    width: 100px;
  }
`;


export const SelectedInsuranceInfo = styled.div`
  margin: 0 26px 0 26px;
`;

export const DisplayInsuranceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  background-color: var(--neutral-color-gray-2);
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px #00000013;
  border: 1px solid var(--neutral-color-gray-5);

  p {
    display: flex;
    align-items: center;
    margin: 0;
    color: #434343;
    font-size: 16px;
    font-weight: 400;

    img {
      width: 24px;
      margin: 0 12px 0 10px;
    }
  }

  p strong {
    font-size: 24px;
    font-weight: 800;
    margin: 0 10px 0 0;
  }

  .value {
    color: green;
    font-size: 1.2em;
  }
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px;
    align-items: center;
    margin: 10px 26px 14px 26px;

    button {
      padding: 8px 28px 8px 24px;
      font-weight: bold;
      font-size: 16px;
      height: 40px;
    }

    button:nth-child(n+2) {
      margin: 0;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      background: var(--brand-color-gold-6);
      border: 1px solid #d9d9d9;
      color: var(--neutral-color-gray-9);
      box-sizing: border-box;
    }

    button:last-child:hover {
      background-color: var(--orange-1);
    }

`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  span {
    font-size: 15px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    color: red;
  }
`;

export const SubTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 3px;

  .mandatory{
    color: #ff0000;
  }
  p {
    margin: 0 0 5px 0;
  }

  .optional{
    font-size: 9px;
  }
`;

export const FieldsButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  gap: 140px;

  button {
    border-radius: 4px;
    width: 100%;
  }

  button:last-child {
    font-weight: bold;
    color: var(--neutral-color-gray-9);
    background: var(--brand-color-gold-6);
  }

  button:last-child:hover {
      background: var(--orange-1);
    }
`;

export const ErrorModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;

  button {
    border-radius: 4px;
  }

  button:last-child {
    font-weight: bold;
    color: var(--neutral-color-gray-9);
    background: var(--brand-color-gold-6);
  }

  button:last-child:hover {
      background: var(--orange-1);
    }
`;

export const StyledErrorModal = styled(Modal)`
  p {
    text-align: center;
  }
`;


export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

    p {
      color: green;
      margin-top: 1.5rem;
    }

    h2 {
      text-align: start;
      margin: 0 480px 10px 0;
      font-size: 14px;
      font-weight: 400;
    }

    @media(max-width: 615px) {
      h2 {
        margin: 10px;
      }
    }

    button {
      margin: 1rem;
    }
`;


export const SignatureCanvas = styled.div`
  width: 300px;
  height: 120px;
  display: flex;
  justify-content: center;

  canvas {
    border: 0.5px solid #bfbfbf;
    padding: 0.5rem;
  }

  @media (max-width: 600px) {
    width: 200px;
    height: 100px;
  }
`;


export const SignatureButton = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: 244px;

    button {
      width: 145px;
      margin-right: 1rem;
    }

  @media (max-width: 768px) {
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
`;

export const NewLoadingModals = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;


