import React, { useCallback, useEffect, useState } from "react";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Tooltip, Input } from "antd";
import { FaRegComment } from "react-icons/fa";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

import { STATUSES } from "../../../components/Status";
import { usePermissions } from "../../../Context/permissionContext";
import { SolicitationService } from "../../../services/solicitationService";
import { notifyError, notifySucces } from "../../../utils/notifications";
import SolicitationModal from "../SolicitationModal";
import SolicitationFileInput from "../SolicitationModal/SolicitationFileInput";
import * as S from "./style";

const VIEW_STATUS = "VIEW";

const { TextArea } = Input;

function FinishSolicitationPermission({ children }) {
  const { permissions } = usePermissions();

  if (permissions.includes("finish_solicitation")) {
    return children;
  }

  return null;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} é obrigatório",
};
/* eslint-enable no-template-curly-in-string */

function SolicitationActionButtonV2({
  viewOnly = false,
  solicitation,
  onFinish,
  closeModal,
} = {}) {
  // eslint-disable-next-line camelcase
  const { status, id } = solicitation;
  const [isLoading, setIsLoading] = useState(false);
  const [nextStatus, setNextStatus] = useState();
  const [files, setFiles] = useState([]);

  const cancelClick = useCallback(() => {
    setNextStatus(STATUSES.CANCELLED);
  }, []);

  const finishClick = useCallback(() => {
    setNextStatus(STATUSES.RESOLVED);
  }, []);
  const viewClick = useCallback(() => {
    setNextStatus(VIEW_STATUS);
  }, []);

  const handleSubmit = useCallback(
    async ({ description, files, formStatus }) => {
      try {
        setIsLoading(true);
        let response;

        switch (formStatus) {
          case STATUSES.CANCELLED: {
            response = await SolicitationService.cancel(id, {
              description,
              files,
            });
            closeModal();
            break;
          }
          case STATUSES.RESOLVED: {
            response = await SolicitationService.finish(id, {
              description,
              files,
            });
            closeModal();
            break;
          }
          default: {
            setNextStatus(STATUSES.ACTIVE);
            response = await SolicitationService.initiate(id);
          }
        }
        const { data } = response;
        notifySucces(data);
        if (onFinish) {
          await onFinish();
        }
        // solicitation.status = nextStatus;
        // setNextStatus();
      } catch (e) {
        notifyError("Erro", e.message);
      } finally {
        setIsLoading(false);
      }
    },
    [id, nextStatus, onFinish, solicitation]
  );

  const onCancel = useCallback(() => setNextStatus(undefined), []);

  const onSubmit = useCallback(
    async (form, formStatus) => {
      handleSubmit({ ...form, files, formStatus });
    },
    [handleSubmit, nextStatus, files]
  );

  useEffect(() => {
    setNextStatus(status);
  }, [solicitation]);

  return (
    <>
      {nextStatus === STATUSES.PENDING ? (
        <S.WrapperBtnsAction>
          <FinishSolicitationPermission>
            <button type="button" onClick={handleSubmit}>
              <IoIosCheckmark />
              Iniciar Atendimento
            </button>
          </FinishSolicitationPermission>
        </S.WrapperBtnsAction>
      ) : null}

      {nextStatus === STATUSES.ACTIVE ? (
        <S.WrapperBtnsAction>
          <FinishSolicitationPermission>
            <button
              type="button"
              onClick={() => setNextStatus("FORM-DONTACCEPT")}
            >
              <IoIosClose />
              Recusar e finalizar solicitação
            </button>
            <button type="button" onClick={() => setNextStatus("FORM-ACCEPT")}>
              <IoIosCheckmark />
              Aceitar e finalizar solicitação
            </button>
          </FinishSolicitationPermission>
        </S.WrapperBtnsAction>
      ) : null}

      {nextStatus === "FORM-ACCEPT" ? (
        <FinishSolicitationPermission>
          <S.SolicitationFormAction>
            <div className="solicitation-form__left">
              <p>
                <strong>
                  <IoIosCheckmark />
                  Aceitar e finalizar solicitação
                </strong>
              </p>
              <div className="align-sub-text">
                <span>
                  Deixe uma comentário para conseguir finalizar a solicitação.
                </span>
              </div>
            </div>

            <div className="solicitation-form__right">
              <Form
                layout="vertical"
                onFinish={(e) => onSubmit(e, STATUSES.RESOLVED)}
                validateMessages={validateMessages}
              >
                <SolicitationFileInput setFiles={setFiles} />
                <Form.Item
                  name="description"
                  label="Observações"
                  rules={[{ required: true }]}
                >
                  <TextArea rows={3} maxLength={512} showCount />
                </Form.Item>

                <div className="solicitation-form__right">
                  <button type="submit" className="comment">
                    <FaRegComment />
                    Comentar
                  </button>
                  <button
                    type="button"
                    onClick={() => setNextStatus(STATUSES.ACTIVE)}
                  >
                    Cancelar
                  </button>
                </div>
              </Form>
            </div>
          </S.SolicitationFormAction>
        </FinishSolicitationPermission>
      ) : null}

      {nextStatus === "FORM-DONTACCEPT" ? (
        <FinishSolicitationPermission>
          <S.SolicitationFormAction>
            <div className="solicitation-form__left reject">
              <p>
                <strong>
                  <IoIosClose />
                  Recusar e finalizar solicitação
                </strong>
              </p>
              <div className="align-sub-text">
                <span>
                  Deixe uma comentário para conseguir finalizar a solicitação.
                </span>
              </div>
            </div>

            <div className="solicitation-form__right">
              <Form
                layout="vertical"
                onFinish={(e) => onSubmit(e, STATUSES.CANCELLED)}
                validateMessages={validateMessages}
              >
                <SolicitationFileInput setFiles={setFiles} />
                <Form.Item
                  name="description"
                  label="Observações"
                  rules={[{ required: true }]}
                >
                  <TextArea rows={3} maxLength={512} showCount />
                </Form.Item>

                <div className="solicitation-form__right">
                  <button type="submit" className="comment">
                    <FaRegComment />
                    Comentar
                  </button>
                  <button
                    type="button"
                    onClick={() => setNextStatus(STATUSES.ACTIVE)}
                  >
                    Cancelar
                  </button>
                </div>
              </Form>
            </div>
          </S.SolicitationFormAction>
        </FinishSolicitationPermission>
      ) : null}
    </>
  );
}

export default SolicitationActionButtonV2;
