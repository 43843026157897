import React, { memo, useState } from "react";

import { Button, Modal } from "antd";

import api from "../../../services/api";
import { notifyError, notifySucces } from "../../../utils/notifications";
import * as S from "./style";

function ModalCancelPayment({ onCancel, infosModal }) {
  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleSubmit = () => {
    setLoadingBtn(true);
      api
          .patch("creliq_payment_cancellation", {
            clientId: infosModal?.clientId,
            paymentId: infosModal?.pagamento?.idPagamento,
            accountId: infosModal?.pagamento?.numeroConta,
            defaultingId: infosModal?.idInadimplente,
            nuAuthorization: infosModal?.pagamento?.nuAutorizacao,
          })
            .then((response) => {

                notifySucces("Cancelamento realizado com sucesso");
                setLoadingBtn(false);
            })
            .catch((error) => {
                console.error(error);
                notifyError(error.message);
                setLoadingBtn(false);
                onCancel();
            })
  };

  return (
    <Modal title="Cancelar Pagamento" visible onCancel={onCancel}>
      <S.ContainerCreateEdit>
              <S.FormRow>
                <div>
                  <b>
                    Deseja realmente cancelar esse pagamento? <br />
                  </b>
                </div>
              </S.FormRow>

              <S.FormRow>
                <Button
                  loading={loadingBtn}
                  onClick={handleSubmit}
                  type="primary"
                  style={{ background: "#F2363D", border: "1px solid #F2363D" }}
                >
                  Cancelar Pagamento
                </Button>
              </S.FormRow>
      </S.ContainerCreateEdit>
    </Modal>
  );
}

export default memo(ModalCancelPayment);
