import React, { useEffect, useState } from 'react';

import { StopOutlined } from '@ant-design/icons';
import { Card, Modal, Popover, Button } from 'antd';

import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import { usePermissions } from '../../../Context/permissionContext';
import { useAuth } from '../../../hooks/AuthProvider';
import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import * as S from './styles';

function ModalDetailInsurance({ open, closeModal, insurance }) {
  const { client } = useClient();
  const { user } = useAuth();
  const { permissions } = usePermissions();
  const [currentInsurance, setCurrentInsurance] = useState([]);
  const [currentDependantes, setCurrentDependantes] = useState([]);
  const [loading, setLoading] = useState(false);
  const clientId = client?.client?.id;

  useEffect(() => {
    async function getInsuranceDetail() {
      await setLoading(true);
      if (insurance && open) {
        const { data } = await api.get('insurance_detail', {
          client_id: clientId,
          codigo_adesao: insurance.codigoAdesao,
        });
        const responseDependents = await api.get('client_dependants', {
          client_id: clientId,
          filter: 'null' || 'ativos',
        });
        if (data.insurance.length) await setCurrentInsurance(data.insurance);
        if (data.dependentes.length && responseDependents.data.length) {
          await setCurrentDependantes(
            data.dependentes.map((d) => {
              const dependentData = responseDependents.data.filter(
                (dps) => dps.id.toString() === d.codigoDependente
              )[0];
              return { ...d, nomeDependente: dependentData.nome || '' };
            })
          );
        }
      }
      await setLoading(false);
    }
    getInsuranceDetail();
  }, [clientId, insurance, open]);

  const cancelInsurance = async (row) => {
    try {
      await api.delete('dependant_insurances', {
        client_id: clientId,
        codigoAdesaoDependente: row.codigoAdesaoDependente,
        meioCancelamento: 'SNXPAYMENT',
        usuarioCancelamento: user?.username,
        lojaCancelamento: 1,
        descricaoSeguro: row.descricaoSeguro,
      });
      notifySucces(
        `Seguro ${row.descricaoSeguro} de ${row.nomeDependente} cancelado com sucesso`
      );

      await setCurrentDependantes(
        currentDependantes.filter(
          (i) => i.codigoAdesaoDependente !== row.codigoAdesaoDependente
        )
      );
    } catch (error) {
      notifyError(`Erro ${error.message}`);
    }
  };

  const [columnsInsurance, setColumnsInsurance] = useState([]);

  useEffect(() => {
    setColumnsInsurance([
      {
        title: 'Nome',
        dataIndex: 'nomeDependente',
        key: 'nomeDependente',
      },
      {
        title: 'Cód adesão',
        dataIndex: 'codigoAdesaoDependente',
        key: 'codigoAdesaoDependente',
      },
      {
        title: 'Data de adesão',
        dataIndex: 'dataAdesao',
        key: 'dataAdesao',
      },
      {
        title: 'Seguro',
        dataIndex: 'descricaoSeguro',
        key: 'descricaoSeguro',
      },
      {
        title: 'Valor da parcela',
        dataIndex: 'valorParcela',
        key: 'valorParcela',
      },
      {
        title: 'Ações',
        dataIndex: '',
        key: '',
        render: (row) => (
          // eslint-disable-next-line react/jsx-filename-extension
          <div>
            {permissions && permissions.includes('cancel_insurance') && (
              <Popover
                content={
                  <div>
                    <Button
                      style={{ marginRight: '5px' }}
                      onClick={() => cancelInsurance(row)}
                    >
                      Confirmar cancelamento
                    </Button>
                  </div>
                }
                title="Deseja cancelar o seguro?"
                trigger="click"
                placement="top"
              >
                <StopOutlined
                  style={{ marginRight: '10px' }}
                  title="Cancelar"
                />
              </Popover>
            )}
          </div>
        ),
      },
    ]);
  }, [currentDependantes]);

  const renderDependantsTable = () => {
    if (loading) {
      return (
        <div style={{ padding: 15 }}>
          <Loading />
        </div>
      );
    }

    if (!currentDependantes.length) {
      return (
        <p style={{ padding: 15 }}>Não foi encontrado dependentes no seguro</p>
      );
    }

    return (
      <Table
        columns={columnsInsurance}
        rows={currentDependantes}
        page={5}
        showPaginationNav
      />
    );
  };

  return (
    <Modal visible={open} title="Informações" onCancel={closeModal} width="90%">
      <S.ModalContainer>
        <Card title="Detalhes do seguro">
          {!loading &&
            currentInsurance.map((insu) => (
              <>
                <p>
                  <span>Cód Adesão: </span>
                  <b>{insu.codigoAdesao}</b>
                </p>
                <p>
                  <span>Descrição: </span>
                  <b>{insu.descricaoSeguro}</b>
                </p>
                <p>
                  <span>Código: </span>
                  <b>{insu.codigoSeguro}</b>
                </p>
                <p>
                  <span>Número da sorte: </span>
                  <b>{insu.numeroDaSorte}</b>
                </p>
                <p>
                  <span>Valor da parcela: </span>
                  <b>R$ {insu.valorParcela}</b>
                </p>
                <p>
                  <span>Data de adesão: </span>
                  <b>{insu.dataAdesao}</b>
                </p>
              </>
            ))}
          {loading && <Loading />}
        </Card>
        <br />
        <Card
          title="Dependentes contratados no seguro"
          bodyStyle={{ padding: 0 }}
        >
          {renderDependantsTable()}
        </Card>
      </S.ModalContainer>
    </Modal>
  );
}
export default ModalDetailInsurance;
