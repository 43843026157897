import React, { memo, useCallback } from 'react';

import { Button, Tooltip } from 'antd';

import Can from '../../../../../../components/Can';
import { useClient } from '../../../../../../hooks/ClientProvider';
import useToggle from '../../../../../../hooks/useToggle';
import { vipagemService } from '../../../../../../services/vipagemService';
import {
  notifyError,
  notifySucces,
} from '../../../../../../utils/notifications';

function ConfirmTransition({ card, reloadCards }) {
  const [isLoading, toggleLoading] = useToggle();
  const { client, refreshClient } = useClient();

  const confirmTransition = useCallback(async () => {
    try {
      toggleLoading();

      await vipagemService.alterarProdutoPortador(
        client?.client?.id,
        client.product.codigoProduto,
        card.numeroMascarado
      );

      notifySucces('Cartão confirmado com sucesso!');

      await refreshClient();
      await reloadCards();
    } catch (e) {
      notifyError('Erro ao confirmar transição');
    } finally {
      toggleLoading();
    }
  }, [card, client]);

  return (
    card.status !== 'ATIVO' && (
      <Can permission="vipagem_confirmar_transicao">
        <Tooltip title="Confirmação de recebimento pelo cliente">
          <Button
            type="button"
            onClick={confirmTransition}
            disabled={isLoading}
            loading={isLoading}
          >
            Confirmar transição
          </Button>
        </Tooltip>
      </Can>
    )
  );
}

export default memo(ConfirmTransition);
