import React, { useCallback, useState } from 'react';

import { usePermissions } from '../../../../../../../Context/permissionContext';
import { useAuth } from '../../../../../../../hooks/AuthProvider';
import { useClient } from '../../../../../../../hooks/ClientProvider';
import api from '../../../../../../../services/api';
import { InvoiceInstallmentService } from '../../../../../../../services/invoiceInstallmentService';
import { notifyError } from '../../../../../../../utils/notifications';
import CalculateInstallment from './CalculateInstallment';
import ClientInfo from './ClientInfo';
import InstallmentOptionsPreview from './InstallmentOptionsPreview';

function StepOneOption({
  setSelectedInstallmentOption,
  selectedInstallmentOption,
  className,
  devedorTotal,
  onCloseClick,
}) {
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { permissions } = usePermissions();
  const { user } = useAuth();

  const { client } = useClient();

  const onSimulateInstallmentRange = useCallback(async (installment) => {
    const id = user?.id;

    await api
      .get('userAttendance', {
        userID: id,
      })
      .then(async (responseUser) => {
        await api
          .get('getInstallment', {
            role_id: responseUser[0].role_id,
          })
          .then(async (responseData) => {
            if (permissions.includes('limit_installment_options')) {
              if (responseData.data.installment_limit === null) {
                await setInstallmentOptions(
                  installment.filter((a) => a.quantidadeParcelas <= 48)
                );
              } else {
                await setInstallmentOptions(
                  installment.filter(
                    (a) =>
                      a.quantidadeParcelas <=
                      responseData.data.installment_limit
                  )
                );
              }
            } else {
              await setInstallmentOptions(
                installment.filter((a) => a.quantidadeParcelas <= 48)
              );
            }
          })
          .catch((erro) => {
            console.error('Falha', erro);
          });
      });
  });

  const onSimulateClickHandler = useCallback(
    async (form) => {
      try {
        setIsLoading(true);

        const method = devedorTotal
          ? InvoiceInstallmentService.simulateTotal
          : InvoiceInstallmentService.simulate;

        const response = await method({
          clientId: client.client.id,
          tax: form.tax,
        });

        if (devedorTotal && !response?.length) {
          notifyError('Cliente não possui devedor futuro!');
        }
        onSimulateInstallmentRange(response);
      } catch (err) {
        notifyError(err.message);
        onCloseClick();
        console.error('Error to get simulation', err);
      } finally {
        setIsLoading(false);
      }
    },
    [client]
  );

  return (
    <div className={className}>
      <ClientInfo />
      <CalculateInstallment
        isLoading={isLoading}
        installmentOptions={installmentOptions}
        onSimulateClick={onSimulateClickHandler}
        selectedInstallmentOption={selectedInstallmentOption}
      />
      <InstallmentOptionsPreview
        installmentOptions={installmentOptions}
        onInstallmentOptionClick={setSelectedInstallmentOption}
      />
    </div>
  );
}

export default React.memo(StepOneOption);
