import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { usePermissions } from "../../Context/permissionContext";
import { getIconFromName } from "../../utils/getIconFromName";

function SubMenuItem({
  title,
  typePermission,
  iconName,
  route,
  paymentsClick,
  onSubMenuClick,
  salesClick,
}) {
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);

  const icon = getIconFromName(iconName);

  const routeActions = {
    'modal-paymentsClick': paymentsClick,
    'modal-salesClick': salesClick,
  };

  const changeRouteClick = useCallback(
    (route) => {
      const action = routeActions[route];
      if (action) {
        action();
      } else {
        navigate(route);
      }
      onSubMenuClick(false);
    },
    [navigate, onSubMenuClick]
  );

  useEffect(() => {
    if (typePermission) {
      const typePermissionValue = typePermission.map((item) => item.id);

      setShouldRenderComponent(
        typePermissionValue.some((permission) =>
          permissions.includes(permission)
        )
      );
    }
  }, []);

  return (
    <>
      {permissions && shouldRenderComponent && (
        <li onClick={() => changeRouteClick(route)}>
          <div className="newmenu-item__icon-sub">{icon}</div>
          <p>{title}</p>
        </li>
      )}
    </>
  );
}
export default SubMenuItem;
