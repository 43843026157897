import React, { memo, useCallback } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';

function SolicitationFileInput({ setFiles }) {
  const onRemove = useCallback(
    (file) => {
      setFiles((state) => {
        const index = state.indexOf(file);
        const newFileList = state.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    [setFiles]
  );

  const beforeUpload = useCallback(
    (file) => {
      setFiles((files) => [...files, file]);
      return false;
    },
    [setFiles]
  );

  return (
    <Upload onRemove={onRemove} beforeUpload={beforeUpload}>
      <Button icon={<UploadOutlined />}>Seleciona o Arquivo</Button>
    </Upload>
  );
}

export default memo(SolicitationFileInput);
