import React from 'react';

import { Table } from 'antd';

import * as S from './styles';

function TableSNX({
  columns,
  rows = [],
  page = 1,
  showPaginationNav = false,
  rowSelection,
  rowKey = 'key',
}) {
  return (
    <S.WrapperDiv>
      <Table
        rowKey={rowKey}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={rows}
        pagination={{
          pageSize: page,
          total: showPaginationNav ? rows.length || 0 : 0,
          showTotal: (total, range) =>
            `${range[0]} - ${range[1]} de ${total} resultados`,
          position: ['none', showPaginationNav ? 'bottomCenter' : 'none'],
        }}
      >
        Teste
      </Table>
    </S.WrapperDiv>
  );
}

export default TableSNX;
