import React, { memo, useCallback, useState } from 'react';

import { Button, Input, Modal } from 'antd';

import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const { TextArea } = Input;

function UnBlockCardModal({
  isModalVisible,
  closeModal,
  card,
  unBloqCard,
  productID,
  clientID,
  setDetailBlockCard,
  reloadCards
}) {
  const [loading, setLoading] = useState(false);
  const [obs, setObs] = useState('');

  const handleUnblock = useCallback(async () => {
    try {
      await setLoading(true);
      if (obs === '' || obs === null) {
        throw new Error('Digite uma Observação de Desbloqueio');
      }
      await api.patch('card_unblock', {
        card_id: card.id,
        cod_product: productID,
        observation: obs,
        client_id: clientID,
        masked_number: card.numeroMascarado,
        action: 'Desbloquear',
      });
      await unBloqCard(card);
      if (typeof setDetailBlockCard === 'function') {
        setDetailBlockCard('');
    }
      closeModal();
      notifySucces('Desbloqueado com sucesso');
      reloadCards();
    } catch (error) {
      const stringError = error.message;

      if (stringError.includes("detail")) {
        const splitArray = stringError.split("detail:");
        const detailText = splitArray[1].split(",")[0].trim();
        return detailText ? notifyError(detailText) : notifyError(stringError);
      }

      notifyError(stringError);
    } finally {
      await setLoading(false);
    }
  }, [
    card,
    clientID,
    closeModal,
    productID,
    setDetailBlockCard,
    unBloqCard,
    obs,
  ]);

  const onChange = useCallback(
    (evt) => {
      setObs(evt.target.value);
    },
    [setObs]
  );

  return (
    <Modal width={550} visible={isModalVisible} onCancel={closeModal}>
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.WrapperSelectBlock>
        <S.ModalTitle>
          <h1>Desbloqueio Cartão</h1>
        </S.ModalTitle>
        <p />
        <p />
        <span>Mensagem:</span>
        <S.WrapperSelectBlock>
          <TextArea
            rows={3}
            type="text"
            placeholder="Digite uma observação para o desbloqueio do cartão"
            value={obs}
            onChange={onChange}
          />
        </S.WrapperSelectBlock>
        <S.WrapperSelectBlock>
          <S.WrapperSelectBlock>
            {!loading && <Button onClick={handleUnblock}>Confirmar </Button>}
          </S.WrapperSelectBlock>
        </S.WrapperSelectBlock>
      </S.WrapperSelectBlock>
    </Modal>
  );
}

export default memo(UnBlockCardModal);
