import styled from 'styled-components';

export const FormItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 32px;

  input:disabled {
    color: #000;
  }
`;

const Container = styled.div`
  button {
    width: 150px;
    position: relative;
    margin-top: 17px;
    font-weight: bold;
    font-size: 17;
  }
`;
Container.FormItems = FormItemsContainer;

export default Container;