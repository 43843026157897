import styled, { css } from "styled-components";

export const WrapperBtnsAction = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    height: 54px;
    padding: 16px 24px 16px 24px;
    gap: 8px;
    opacity: 0px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-align: left;
    background: transparent;
    border: 0;
    color: #272d3b;

    display: flex;
    align-items: center;

    svg {
      font-size: 26px;
      color: #ff4d4f;
    }

    &:is(:last-child) {
      border-top: 1px solid #e5e7eb;

      svg {
        color: #52c41a;
      }
    }
  }

  @media (min-width: 62rem) {
    flex-direction: row;
    justify-content: flex-end;

    button {
      &:is(:last-child) {
        border-top: 0px solid transparent;
        border-left: 1px solid #e5e7eb;
      }
    }
  }
`;

export const SolicitationFormAction = styled.div`
  width: 100%;

  .solicitation-form__left {
    width: 100%;
    background: #f0f0f0;
    border-bottom: 2px solid #52c41a;
    padding: 26px;
    margin-bottom: 2rem;

    &.reject {
      border-color: #ff4d4f;

      svg {
        color: #ff4d4f;
      }

      span {
        color: #ff4d4f;
      }
    }

    .align-sub-text {
      padding-left: 24px;
    }

    svg {
      font-size: 26px;
      color: #52c41a;
    }

    p {
      padding: 0;
      margin: 0;
      strong {
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-size: 19px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        color: #272d3b;
      }
    }

    span {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #52c41a;
    }
  }

  .solicitation-form__right {
    width: 100%;

    form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    input,
    .ant-row {
      width: 100%;
      height: 122px;
      border-radius: 2px;
      flex-direction: row !important;

      .ant-form-item-label{
        width: 100%;
      }
    }

    .solicitation-form__right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;

      button {
        background: transparent;
        border: 0;
        height: 40px;
        padding: 8px 24px 8px 24px;
        border-radius: 2px;
        color: #434343;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 5px;
        }

        &.comment {
          background: #ffb32c;
        }
      }
    }
  }

  @media (min-width: 62rem) {
    display: flex;
    flex-wrap: wrap;

    .solicitation-form__left {
      width: 358px;
      margin-bottom: 0;
      border-bottom: 0;
      border-right: 2px solid #52c41a;
    }

    .solicitation-form__right {
      width: calc(100% - 358px);
      padding-left: 32px;

      display: flex;
      flex-wrap: wrap;

      input,
      .ant-row {
        width: calc(100% - 162px);
      }

      .solicitation-form__right {
        width: 162px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        margin: 0;

        button {
          &.comment {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
`;
