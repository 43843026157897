import styled from 'styled-components';

export const WrapperDiv = styled.div`
  thead th:last-of-type {
    padding-left: 30px;
  }

  tr,
  td,
  thead th {
    font-weight: 700;
  }
`;
