import { Modal } from "antd";
import styled from "styled-components";

export const WrapperModalAcessCards = styled(Modal)`
  width: 90% !important;
  max-width: 800px;

  .ant-modal-body {
    padding: 5px 15px !important;
  }

  .icon-btn {
    background: transparent;
    border: 1px solid transparent;

    svg{
      font-size: 20px;
    }
  }

  .rwd-table {
    width: 100%;
    background: #fff;
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    border-collapse: separate !important;
    border-spacing: 0;

    border-radius: 4px;
    border: 1px solid var(--Neutral-Color-Gray-4, #f0f0f0);

    .btns-acoes {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .btn-div {
        padding: 8px;
        border-radius: 2px;
        border: 1px solid var(--Neutral-5, #d9d9d9);
        background: var(--Neutral-1, #fff);
        /* drop-shadow/button-secondary */
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
      }
    }

    p {
      &.status {
        border-radius: 16px;
        border: 1px solid var(--Status-Color-Success, #52c41a);
        background: rgba(82, 196, 26, 0.05);
        padding: 1px 8px;
        color: var(--Status-Color-Success, #52c41a);
        /* Footnote 12/description */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: inline-flex;
        margin: 0;

        &.off {
          border: 1px solid var(--Status-Color-Error, #ff4d4f);
          background: rgba(255, 77, 79, 0.05);
          color: var(--Status-Color-Error, #ff4d4f);
        }
      }
    }

    & td {
      /* border-left: 1px solid #ccc; */
      border-bottom: 1px solid #f0f0f0;
      padding: 10px;
      text-align: left;
    }

    & tr {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;

      &:first-of-type {
        background: #f5f5f5;
      }

      &:last-of-type {
        & td {
          /* border-left: 1px solid #ccc; */
          border-bottom: 1px solid transparent !important;
          padding: 10px;
          text-align: left;
        }
      }
    }
    & th {
      display: none;
    }

    & td {
      display: block;

      &:first-child {
        padding-top: 0.5em;
      }

      &:last-child {
        padding-bottom: 0.5em;
      }

      &:before {
        content: attr(data-th) ": ";
        font-weight: bold;
        width: 6.5em;
        display: inline-block;
      }
    }

    & th,
    & td {
      text-align: left;
      margin: 0.5em 1em;
    }

    & th,
    & td:before {
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    }
  }

  @media (min-width: 480px) {
    .rwd-table td:before {
      display: none;
    }

    .rwd-table th,
    .rwd-table td {
      display: table-cell;
      padding: 1em !important;
    }
    .rwd-table th:first-child,
    .rwd-table td:first-child {
      padding-left: 0;
    }
    .rwd-table th:last-child,
    .rwd-table td:last-child {
      padding-right: 0;
    }
  }
`;
