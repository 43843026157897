import styled, { keyframes, css } from 'styled-components';

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }
  50% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;


export const InsuranceContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: auto;

  button {
    margin: 0px 8px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    background: var(--primaryColor);
    border: 1px solid #d9d9d9;
    width: 40%;
    height: 40px;
    padding: 5px 0px;
    color: white;
    font-weight: bold;
    font-size: 17px;
    box-sizing: border-box;
    margin-top: 10px;
  }

  button[id='goBack'] {
    border: 1px solid #ccc !important;
    background: #ffffff;
    color: black;
  }
`;

export const InsuranceButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  border-top: 1px solid #ccc;

  display: flex;
  align-items: center;
  justify-content: ${({ step3 }) => (step3 ? css`center` : css`flex-end`)};

  button {
    width: 20%;
  }

  button + button {
    margin-left: 10px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  span {
    font-size: 15px;
    font-weight: bold;
  }
`;

export const Title = styled.h1`
  font-size: 25px;
  font-weight: bold;
  color: var(--primaryColor);
`;

export const SubTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const BeneficiaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BeneficiaryCardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  div[id='dependent'] {
    margin-left: 20px;
  }
`;

export const BeneficiaryCard = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #ccc;
  border-radius: 10px;

  div + div {
    margin-top: 13px;
  }

  &:hover {
    border-color: var(--primaryColor);
  }
`;

export const BeneficiaryCardIcon = styled.div`
  background-color: var(--primaryColor);
  border-radius: 50%;
  padding: 10px;

  svg {
    width: 45px;
    height: 44px;
    color: white;
  }
`;

export const BeneficiaryTitle = styled.div`
  h2 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
  }
`;

export const BeneficiaryDescription = styled.div`
  width: 75%;
  line-break: anywhere;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #898a8a;
  }
`;

export const BeneficiaryButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HolderContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;

export const HolderDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 7px;
`;

export const HolderInsurancesContainer = styled.div``;

export const InsurancesTagsContainer = styled.div`
  width: 100%;
  height: 100px;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;

  div {
    margin-left: 7px;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border: 1px solid var(--primaryColor);
  border-radius: 50px;
  min-width: 170px;
  font-size: 15px;
  background: white;
`;

export const SelectInsuranceContainer = styled.div`
  margin-top: 10px;
`;

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;

    h2 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    p {
      color: green;
      margin-top: 1.5rem;
    }

    button {
      margin-top: 2.5rem;
    }
`;


export const SignatureCanvas = styled.div`
  width: 300px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem;

  canvas {
    border: .5px solid black;
    padding: .5rem;
  }
`;

export const SignatureButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

    button {
      width: 200px;
    }
`;

export const ErrorMsg = styled.span`
  color: red;
  font-size: 17px;
  font-weight: bold;
  margin-top: 5px;
`;

export const DependentContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;

export const SelectInsuranceDependentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  button {
    width: 150px;
    position: relative;
    margin-top: 17px;
  }
`;

export const DependentsList = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  margin-top: 10px;
`;

export const DependentsData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: 10px;
  padding: 10px;
  max-height: 500px;
  overflow: auto;

  span {
    margin-left: 7px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  input {
    cursor: pointer;
  }

  span {
    font-weight: 500;
    letter-spacing: 1px;
  }
`;

export const QuestionContainer = styled.div`
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
