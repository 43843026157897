import React from "react";

import { MdOutlineInfo } from "react-icons/md";

import ModalAnnuityInformation from "../../../home/modals/ModalAnnuityInformation";
import * as S from "./style";

function ModalAcessAnnuity({
  openModal,
  titleModal,
  onClose,
  contentData,
  openModalAnnuityInformation,
  setOpenModalAnnuityInformation,
  reloadPremiumAnnuity,
  selectedAnnuityInformation,
  handleOpenModalAnnuityInformation,
}) {
  return (
    <>
      <S.WrapperModalAcessCards
        visible={openModal}
        title={titleModal}
        onCancel={onClose}
      >
        {contentData?.length > 0 ? (
          <>
            <table className="rwd-table">
              <tr>
                <th>ID</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Ações</th>
              </tr>

              {contentData?.map((data) => (
                <tr key={data.id}>
                  <td data-th="ID">{data.id}</td>
                  <td data-th="Descrição">{data.descricao}</td>
                  <td data-th="Valor">
                    R$ {String(data.valor).replace(".", ",")}
                  </td>
                  <td data-th="Ações">
                    <button
                      type="button"
                      className="icon-btn"
                      onClick={() => handleOpenModalAnnuityInformation(data)}
                    >
                      <MdOutlineInfo />
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          </>
        ) : null}
      </S.WrapperModalAcessCards>
      <ModalAnnuityInformation
        open={openModalAnnuityInformation}
        closeModal={() => {
          setOpenModalAnnuityInformation(false);
        }}
        reloadPremiumAnnuity={reloadPremiumAnnuity}
        selectedAnnuityInformation={selectedAnnuityInformation}
      />
    </>
  );
}

export default ModalAcessAnnuity;
