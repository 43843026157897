const capitalize = (str) => {
  const arr = str.split(' ');
  const c = arr.map((a) => {
    const cap = a.charAt(0).toUpperCase() + a.toLowerCase().substr(1);
    return cap;
  });
  return c.join(' ');
};

module.exports = {
  capitalize,
};
