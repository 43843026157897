import React from 'react';

import { TbFileInvoice } from 'react-icons/tb';

import * as S from "./style";

export function InvoiceInfo({text, dataVencimento, valorTotal, possuiPagamentoMinimo, valorMinimo}) {
  return (
    <S.WrapperLastOrder>
        <p>{text}</p>

        <div className="infos-boleto">
          <div className="infos-boleto__left">
            <TbFileInvoice color="#FF4D4F" />
            {dataVencimento && (
              <p>
                {
                  dataVencimento.split(
                    "-"
                  )[2]
                }
                /
                {
                  dataVencimento.split(
                    "-"
                  )[1]
                }
                /
                {
                  dataVencimento.split(
                    "-"
                  )[0]
                }
              </p>
            )}
          </div>

          <div className="infos-boleto__right">
            <p>
              <strong>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(valorTotal)}
              </strong>
            </p>

            {possuiPagamentoMinimo && (
              <p>
                Min:{" "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(
                  valorMinimo
                )}
              </p>
            )}
          </div>
        </div>
  </S.WrapperLastOrder>
  )
}
