import React, {
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  CaretDownOutlined,
  ContactsOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HistoryOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  PrinterOutlined,
  ProfileOutlined,
  PullRequestOutlined,
  SendOutlined,
  SettingOutlined,
  StarOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UploadOutlined,
  RiseOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Popover, Layout, Switch} from "antd";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "../../Context/permissionContext";
import { useAuth } from "../../hooks/AuthProvider";
import { useClient } from "../../hooks/ClientProvider";
import ModalContentFinalize from "../../pages/home/modals/modalDetailFinalize";
import CreateSolicitation from "../../pages/solicitation/CreateSolicitation";
import ResetPasswordModal from "../../pages/solicitation/ResetPasswordModal";
import PayModal from "../../pages/solicitation/SellandPayModal/index";
import PayCancelModal from "../../pages/solicitation/SellandPayModal/payCancelModal";
import SellCancelModal from "../../pages/solicitation/SellandPayModal/sellCancelModal";
import SellModal from "../../pages/solicitation/SellandPayModal/sellModal";
import Api from "../../services/api";
import { notifyError } from "../../utils/notifications";
import Can from "../Can";
import ModalAttend from "../ModalAttendWrapper";
import * as S from "./styles";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

function Skeleton({ children, menuSelected }) {
  const { client, clearClient } = useClient();
  const navigate = useNavigate();

  const [isModalSendSmsVisible, setIsModalSendSmsVisible] = useState(false);
  const [isModalSendMailVisible, setIsModalSendMailVisible] = useState(false);
  const { user, signOut } = useAuth();
  const { permissions } = usePermissions();
  const [collapsed, setCollapsed] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalAlterarSenhaisOpen, setModalAlterarSenhaisOpen] = useState(false);
  const [modalSellisOpen, setModalSellisOpen] = useState(false);
  const [modalPayisOpen, setModalPayisOpen] = useState(false);
  const [modalCancelSellisOpen, setModalCancelSellisOpen] = useState(false);
  const [modalCancelPayisOpen, setModalCancelPayisOpen] = useState(false);
  const [valueMask, setValueMask] = useState("");
  const [loadingMail, setLoadingMail] = useState(false);
  const [switchState, setSwitchState] = useState(false);

  const [modalIsOpenFinalize, setIsOpenFinalize] = useState(false);
  const [clientData, setClientData] = useState({});
  const [phoneValue] = useState("0");

  const [obs, setObs] = useState("");

  const openModal = () => {
    navigate("/home");
  };
  const openModalFinalize = useCallback(() => setIsOpenFinalize(true), []);
  const openModalAlterarSenhaisOpen = useCallback(
    () => setModalAlterarSenhaisOpen(true),
    []
  );
  const openModalSellisOpen = useCallback(() => setModalSellisOpen(true), []);
  const openModalPayisOpen = useCallback(() => setModalPayisOpen(true), []);

  const openModalCancelSellisOpen = useCallback(
    () => setModalCancelSellisOpen(true),
    []
  );
  const openModalCancelPayisOpen = useCallback(
    () => setModalCancelPayisOpen(true),
    []
  );

  const closeModalFinalize = useCallback((value) => {
    setObs(value);
    setIsOpenFinalize(false);
  }, []);

  const closeModalAlterarSenhaisOpen = useCallback(() => {
    setModalAlterarSenhaisOpen(false);
  }, []);
  const closeOpenModalSellisOpen = useCallback(() => {
    setModalSellisOpen(false);
  }, []);

  const closeOpenModalPayisOpen = useCallback(() => {
    setModalPayisOpen(false);
  }, []);
  const closeOpenModalCancelSellisOpen = useCallback(() => {
    setModalCancelSellisOpen(false);
  }, []);

  const closeOpenModalCancelPayisOpen = useCallback(() => {
    setModalCancelPayisOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setCollapsed((previous) => !previous);
  }, []);

  const handleLogout = useCallback(async () => {
    if (clientData && clientData.client && clientData.client.ticket) {
      notifyError(
        "Você precisa finalizar o atendimento antes de realizar o logout!"
      );
      return;
    }

    await signOut( user?.login);
    clearClient();
    navigate("/");
  }, [clearClient, clientData, navigate, signOut]);

  const menuAvatar = useMemo(
    () => (
      <Menu>
        {permissions.includes("view_change_password") && (
          <Menu.Item key="0" onClick={openModalAlterarSenhaisOpen}>
            Alterar Senha
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    ),
    [handleLogout]
  );

  useEffect(() => {
    setClientData(client);
  }, [client]);

  const changeRouteClick = useCallback((route) => navigate(route), [navigate]);

  const homeClick = useCallback(
    () => changeRouteClick("/atendimento"),
    [changeRouteClick]
  );
  const solicitationTypeClick = useCallback(
    () => changeRouteClick("/tipos_solicitacoes"),
    [changeRouteClick]
  );

  const classificationClick = useCallback(
    () => changeRouteClick("/classificacao"),
    [changeRouteClick]
  );

  const userClick = useCallback(
    () => changeRouteClick("/usuario"),
    [changeRouteClick]
  );
  const groupClick = useCallback(
    () => changeRouteClick("/groups"),
    [changeRouteClick]
  );

  const printClick = useCallback(
    () => changeRouteClick("/printers"),
    [changeRouteClick]
  );

  const solicitationClick = useCallback(
    () => changeRouteClick("/solicitacoes"),
    [changeRouteClick]
  );

  const bloqCardInLoteClick = useCallback(
    () => changeRouteClick("/bloqueio-cartao-em-lote"),
    [changeRouteClick]
  );

  const alterInLoteClick = useCallback(
    () => changeRouteClick("/alteracao-limite-em-lote"),
    [changeRouteClick]
  );

  const bloqAccountInLoteClick = useCallback(
    () => changeRouteClick("/bloqueio-contas-em-lote"),
    [changeRouteClick]
  );

  const launch_payment = useCallback(
    () => changeRouteClick("/lancar-ajuste"),
    [changeRouteClick]
  );

  const salesClick = useCallback(() => {
    openModalSellisOpen();
  }, []);

  const paymentsClick = useCallback(() => {
    openModalPayisOpen();
  }, []);

  const cancelSalesClick = useCallback(() => {
    openModalCancelSellisOpen();
  }, []);

  const cancelPaymentsClick = useCallback(() => {
    openModalCancelPayisOpen();
  }, []);

  const onReportsClick = useCallback(
    () => changeRouteClick("/reports"),
    [changeRouteClick]
  );

  const productListClick = useCallback(
    () => changeRouteClick("/lista-de-produtos"),
    [changeRouteClick]
  );

  const sellListClick = useCallback(
    () => changeRouteClick("/lancar-vendas"),
    [changeRouteClick]
  );

  const launchPaymentClick = useCallback(() => {
    changeRouteClick("/lancar-pagamentos");
  }, [changeRouteClick]);

  const storeListClick = useCallback(
    () => changeRouteClick("/lista-de-lojas"),
    [changeRouteClick]
  );

  const canceledInsuranceListClick = useCallback(
    () => changeRouteClick("/lista-de-seguros-cancelados"),
    [changeRouteClick]
  );

  const IssuerCycleListClick = useCallback(
    () => changeRouteClick("/lista-de-ciclos"),
    [changeRouteClick]
  );

  const TypesAdjustmentsClick = useCallback(
    () => changeRouteClick("/lista-dos-tipos-de-ajustes"),
    [changeRouteClick]
  );

  const ScheduleShopkeeperClick = useCallback(
    () => changeRouteClick("/agenda-lojista"),
    [changeRouteClick]
  );

  const ListPayments = useCallback(
    () => changeRouteClick("/listagem-pagamentos"),
    [changeRouteClick]
  );

  const ListPaymentsCreliq = useCallback(
    () => changeRouteClick("/listagem-pagamentos-creliq"),
    [changeRouteClick]
  );

  const ListSells = useCallback(
    () => changeRouteClick("/listagem-vendas"),
    [changeRouteClick]
  );

  const ListBalanceReserve = useCallback(
    () => changeRouteClick("/listagem-reserva-de-saldo"),
    [changeRouteClick]
  );

  const LockTypesListClick = useCallback(
    () => changeRouteClick("/lista-dos-tipos-de-bloqueio-cartao"),
    [changeRouteClick]
  );

  const LockAccountListClick = useCallback(
    () => changeRouteClick("/lista-dos-tipos-de-bloqueio-conta"),
    [changeRouteClick]
  );

  const ListDefault= useCallback(
    () => changeRouteClick("/lista-de-inadimplencia"),
    [changeRouteClick]
  );



  const handleSwitchChange = async (checked) => {
    setSwitchState(checked);
    try {
      await Api.post('update_has_beta_active', {
        hasBetaActive: true,
      });
      window.location.reload()
    } catch (error) {
      console.error('erro', error.message);
      notifyError('Erro ao acessar versão oficial');
    }
  };


  const sendMail = useCallback(async () => {
    await setLoadingMail(true);
    await setIsModalSendMailVisible(true);
    await setLoadingMail(false);
  }, []);

  const sendSMS = useCallback(async () => {
    try {
      setIsModalSendSmsVisible(true);
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  const renderProtocolSendingModals = () => {
    if (!permissions) {
      return null;
    }

    const ModalSendMail = lazy(() =>
      import("../../pages/home/modals/modalSendMail")
    );
    const ModalSendSms = lazy(() =>
      import("../../pages/home/modals/modalSendSms")
    );

    return (
      <>
        <ModalSendMail
          open={isModalSendMailVisible}
          ticket={
            clientData?.client?.ticket?.protocol
              ? clientData.client.ticket.protocol
              : null
          }
          closeModal={() => {
            setIsModalSendMailVisible(false);
          }}
        />

        <ModalSendSms
          open={isModalSendSmsVisible}
          ticket={
            clientData?.client?.ticket?.protocol
              ? clientData.client.ticket.protocol
              : null
          }
          closeModal={() => {
            setIsModalSendSmsVisible(false);
          }}
          phoneValue={phoneValue}
        />
      </>
    );
  };

  return (
    <>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            {collapsed ? (
              <img
                className="logo-collapse"
                alt="logo"
                src="https://i.ibb.co/5Wr465V/elevatylogo.png"
              />
            ) : (
              <img alt="logo" src="https://i.ibb.co/mqG1k7h/elevatyv2.png" />
            )}
          </div>
          <Menu mode="inline" defaultSelectedKeys={menuSelected}>
            {clientData && clientData.client && (
              <Menu.Item
                key="1"
                icon={<ContactsOutlined />}
                onClick={homeClick}
              >
                Atendimento
              </Menu.Item>
            )}

            {permissions &&
              (permissions.includes("crud_classification") ||
                permissions.includes("crud_user") ||
                permissions.includes("crud_group_user")) && (
                <SubMenu
                  key="sub1"
                  icon={<SettingOutlined />}
                  title="Configurações"
                  className="menu-snx"
                >
                  {permissions.includes("crud_solicitation_type") && (
                    <Menu.Item
                      key="6"
                      icon={<PullRequestOutlined />}
                      onClick={solicitationTypeClick}
                    >
                      Tipos Solicitações
                    </Menu.Item>
                  )}
                  {permissions.includes("crud_classification") && (
                    <Menu.Item
                      key="2"
                      icon={<StarOutlined />}
                      onClick={classificationClick}
                    >
                      Classificação
                    </Menu.Item>
                  )}
                  {permissions.includes("crud_user") && (
                    <Menu.Item
                      key="3"
                      icon={<UserAddOutlined />}
                      onClick={userClick}
                    >
                      Usuários
                    </Menu.Item>
                  )}
                  {permissions.includes("crud_group_user") && (
                    <Menu.Item
                      key="4"
                      icon={<UsergroupAddOutlined />}
                      onClick={groupClick}
                    >
                      Grupos
                    </Menu.Item>
                  )}
                  {permissions.includes("manage_printer") && (
                    <Menu.Item
                      key="printers"
                      icon={<PrinterOutlined />}
                      onClick={printClick}
                    >
                      Impressoras
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
            {permissions.includes("view_solicitation") && (
              <Menu.Item
                key="5"
                icon={<PullRequestOutlined />}
                onClick={solicitationClick}
              >
                Solicitações
              </Menu.Item>
            )}

            {permissions &&
              (permissions.includes("view_bloqcard_in_lote") ||
                permissions.includes("view_bloqaccount_in_lote") ||
                permissions.includes("view_alter_limit_lote")) && (
                <SubMenu
                  key="sub3"
                  icon={<UploadOutlined />}
                  title="Operações em Lote"
                  className="menu-snx"
                >
                  <Menu.Item
                    key="11"
                    icon={<CreditCardOutlined />}
                    onClick={bloqCardInLoteClick}
                  >
                    Bloqueio de cartão em lote
                  </Menu.Item>

                  <Menu.Item
                    key="12"
                    icon={<UsergroupDeleteOutlined />}
                    onClick={bloqAccountInLoteClick}
                  >
                    Bloqueio de conta em lote
                  </Menu.Item>

                  <Menu.Item
                    key="13"
                    icon={<RiseOutlined />}
                    onClick={alterInLoteClick}
                  >
                    Alterar limite em lote
                  </Menu.Item>
                </SubMenu>
              )}

              {(
                permissions.includes("permission_register_payment") ||
                permissions.includes("make_sales_manual") ||
                permissions.includes("list_pay_launch") ||
                permissions.includes("permission_schedules_store") ||
                permissions.includes("list_sale_launch") ||
                permissions.includes("get_reserve_balance") ||
                permissions.includes("view_launch_adjustment")
              ) && (
              <SubMenu
                key="sub2"
                icon={<DollarOutlined />}
                title="Vendas e Pagamentos"
                className="menu-snx"
              >
                {permissions.includes("permission_register_payment") && (
                  <Menu.Item
                    key="1"
                    icon={<FileDoneOutlined />}
                    onClick={launchPaymentClick}
                  >
                    Lançar Pagamento
                  </Menu.Item>
                )}

                {permissions.includes("make_sales_manual") && (
                  <Menu.Item
                    key="2"
                    icon={<CreditCardOutlined />}
                    onClick={sellListClick}
                  >
                    Lançar Venda
                  </Menu.Item>
                )}
                {permissions.includes("permission_schedules_store") && (
                  <Menu.Item
                    key="3"
                    icon={<ContainerOutlined />}
                    onClick={ScheduleShopkeeperClick}
                  >
                    Agenda lojista
                  </Menu.Item>
                )}
                {permissions.includes("list_pay_launch") && (
                  <Menu.Item
                    key="4"
                    icon={<ContainerOutlined />}
                    onClick={ListPayments}
                  >
                    Listagem de Pagamentos
                  </Menu.Item>
                )}
                {permissions.includes("list_sale_launch") && (
                  <Menu.Item
                    key="5"
                    icon={<ContainerOutlined />}
                    onClick={ListSells}
                  >
                    Listagem de Vendas
                  </Menu.Item>
                )}
                {permissions.includes("view_creliq_payment") && (
                  <Menu.Item
                    key="6"
                    icon={<ContainerOutlined />}
                    onClick={ListPaymentsCreliq}
                  >
                    Listagem de Pagamentos Creliq
                  </Menu.Item>
                )}
                {permissions.includes("get_reserve_balance") && (
                  <Menu.Item
                    key="7"
                    icon={<ContainerOutlined />}
                    onClick={ListBalanceReserve}
                  >
                    Listagem de Reserva de Saldo
                  </Menu.Item>
                )}
                {permissions.includes("view_launch_adjustment") && (
                  <Menu.Item
                    key="8"
                    icon={<FileDoneOutlined />}
                    onClick={launch_payment}
                  >
                    Lançar Ajuste
                  </Menu.Item>
                  )}
              </SubMenu>
            )}

            {permissions.includes("hiden") && (
              <Menu.Item
                key="report"
                icon={<FileTextOutlined />}
                onClick={onReportsClick}
              >
                Relatórios
              </Menu.Item>
            )}
            {(permissions.includes("list_canceled_insurance") ||
              permissions.includes("view_list_product_issuer") ||
              permissions.includes("view_types_adjustments") ||
              permissions.includes("view_cycles_issuer") ||
              permissions.includes("backoffice_view_list_lock_card") ||
              permissions.includes("backoffice_view_list_stores") ||
              permissions.includes("backoffice_view_list_lock_account")) && (
              <SubMenu
                key="list1"
                icon={<OrderedListOutlined />}
                title="Listagens"
                className="menu-listagens"
              >
                {permissions.includes("view_list_product_issuer") && (
                  <Menu.Item
                    key="1"
                    icon={<ContainerOutlined />}
                    onClick={productListClick}
                  >
                    Lista de produtos
                  </Menu.Item>
                )}
                {permissions.includes("view_types_adjustments") && (
                  <Menu.Item
                    key="2"
                    icon={<SettingOutlined />}
                    onClick={TypesAdjustmentsClick}
                  >
                    Lista dos tipos de ajustes
                  </Menu.Item>
                )}
                {permissions.includes("view_cycles_issuer") && (
                  <Menu.Item
                    key="3"
                    icon={<HistoryOutlined />}
                    onClick={IssuerCycleListClick}
                  >
                    Lista de Ciclos
                  </Menu.Item>
                )}
                {permissions.includes("backoffice_view_list_lock_card") && (
                  <Menu.Item
                    key="4"
                    icon={<ProfileOutlined />}
                    onClick={LockTypesListClick}
                  >
                    Lista dos tipos de bloqueio cartão
                  </Menu.Item>
                )}
                {permissions.includes("backoffice_view_list_lock_account") && (
                  <Menu.Item
                    key="5"
                    icon={<ProfileOutlined />}
                    onClick={LockAccountListClick}
                  >
                    Lista dos tipos de bloqueio conta
                  </Menu.Item>
                )}
                {permissions.includes("backoffice_view_list_stores") && (
                  <Menu.Item
                    key="6"
                    icon={<ContainerOutlined />}
                    onClick={storeListClick}
                  >
                    Lista de lojas
                  </Menu.Item>
                )}
                {permissions.includes("list_canceled_insurance") && (
                  <Menu.Item
                    key="7"
                    icon={<ContainerOutlined />}
                    onClick={canceledInsuranceListClick}
                  >
                    Listar Seguros Cancelados
                  </Menu.Item>
                )}
                {permissions.includes("permission_view_inadimplencia") && (
                  <Menu.Item
                    key="8"
                    icon={<ContainerOutlined />}
                    onClick={ListDefault}
                  >
                    Lista de Inadimplência
                  </Menu.Item>
                )}
              </SubMenu>
            )}
          </Menu>

        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {collapsed ? (
              <MenuUnfoldOutlined className="trigger" onClick={toggle} />
            ) : (
              <MenuFoldOutlined className="trigger" onClick={toggle} />
            )}
            <Can permission="permission_acess_layout_2">
              <Switch
                style={{ width: '80px' }}
                checkedChildren={<span style={{ fontSize: '14px' }}>Layout 2.0</span>}
                unCheckedChildren={<span style={{ fontSize: '14px' }}>Layout 1.0</span>}
                defaultChecked
                checked={switchState}
                onChange={handleSwitchChange}
              />
            </Can>
            <S.HeaderRefactor>
              <S.UserName>
                <Dropdown overlay={menuAvatar} trigger={["click"]}>
                  <div className="ant-dropdown-link">
                    {user && user.username} <CaretDownOutlined />
                  </div>
                </Dropdown>
              </S.UserName>

              {clientData && clientData.client && clientData.client.ticket && (
                <div className="protocolo-header">
                  <p>Protocolo:</p>
                  <span>{clientData.client.ticket.protocol}</span>
                </div>
              )}
              {
                permissions.includes("permission_send_protocol") &&
                clientData &&
                clientData.client &&
                clientData.client.ticket &&
                (
                <Popover
                  content={
                    <div>
                      <>
                        <Button onClick={() => sendSMS()}>SMS</Button>
                        <Can permission="send_ticket_by_email">
                          <Button
                            style={{ marginLeft: "5px" }}
                            onClick={() => sendMail()}
                          >
                            Email
                          </Button>
                        </Can>
                      </>
                    </div>
                  }
                  title="Enviar Protocolo"
                  trigger="click"
                  placement="topRight"
                >
                  <Button type="button" style={{ margin: "24px 16px" }}>
                    <SendOutlined />
                  </Button>
                </Popover>
              )}

              {clientData && clientData.client ? (
                <>
                  <CreateSolicitation />

                  <Button type="button" onClick={openModalFinalize}>
                    Finalizar
                  </Button>
                </>
              ) : (
                <Button type="button" onClick={openModal}>
                  Atender
                </Button>
              )}
            </S.HeaderRefactor>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      {modalIsOpenFinalize && (
        <ModalContentFinalize obs={obs} closeModal={closeModalFinalize} />
      )}

      {modalIsOpen && (
        <ModalAttend modalIsOpen={modalIsOpen} modalIsOpenSetter={setIsOpen} />
      )}

      {modalAlterarSenhaisOpen && (
        <ResetPasswordModal onCancel={closeModalAlterarSenhaisOpen} />
      )}

      {/* {modalSellisOpen && ( */}
      {/*   <SellModal */}
      {/*     valueMask={valueMask} */}
      {/*     setValueMask={setValueMask} */}
      {/*     onCancel={closeOpenModalSellisOpen} */}
      {/*   /> */}
      {/* )} */}

      {/* {modalPayisOpen && <PayModal onCancel={closeOpenModalPayisOpen} />} */}
      {modalCancelSellisOpen && (
        <SellCancelModal onCancel={closeOpenModalCancelSellisOpen} />
      )}
      {modalCancelPayisOpen && (
        <PayCancelModal onCancel={closeOpenModalCancelPayisOpen} />
      )}

      {renderProtocolSendingModals()}
    </>
  );
}

export default memo(Skeleton);
