/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
import { eventEmitter } from '../utils/eventEmitter';

// const baseUrl = 'https://mighty-forest-18459.herokuapp.com';
const baseUrl = process.env.REACT_APP_HOST_BACKEND;
// const baseUrl = 'http://127.0.0.1:3333/api/v1';

class Api {
  constructor() {
    this.jwt = '';
  }

  setJwt(jwt) {
    this.jwt = jwt;
    localStorage.setItem('jwt', jwt);
  }

  async get(url, params = {}, requestHeaders = {}, rawResponse = false) {
    let _p = '';
    if (params) {
      _p = this.serialize(params);
    }

    const headers = this.headers(requestHeaders);
    const response = await fetch(`${baseUrl}/${url}?${_p}`, {
      headers,
    }).then(async (res) => {
      if (
        res.status.toString()[0] === '4' ||
        res.status.toString()[0] === '5'
      ) {
        if (res.status.toString() === '420') {
          let tokenTry =
            localStorage.getItem('@SnxPayment:refreshTokenRetry') || 0;
          if (tokenTry > 3) {
            localStorage.removeItem('@SnxPayment:refreshTokenRetry');
            throw new Error('Ocorreu um problema ao renovar o token');
          }

          await this.refreshToken();
          tokenTry += 1;
          localStorage.setItem('@SnxPayment:refreshTokenRetry', tokenTry);
          return this.get(url, params);
        } else if (res.status.toString() === '555') {
          this.logout();
          throw new Error('Sessão Expirada');
        } else if (res.status.toString() === '406') {
            this.logout();
            throw new Error('IP inválido');
        } else if (res.status.toString() === '405') {
          this.logout(true);
          throw new Error('Sessão inválida');
        } else {
          const message = await res.json();
          throw new Error(message.message);
        }
      }

      return rawResponse ? res : res.json();
    });
    return response;
  }

  async post(url, params, isFormData, responseType = 'json') {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    if (isFormData) delete headers['Content-Type'];
    return fetch(`${baseUrl}/${url}`, {
      method: 'POST',
      headers,
      body: isFormData ? body : JSON.stringify(body),
    }).then(async (res) => {
      if (
        res.status.toString()[0] === '4' ||
        res.status.toString()[0] === '5'
      ) {
        if (res.status.toString() === '403')
          throw new Error('Operação proibida para esse usuário');
        if (res.status.toString() === '420') {
          await this.refreshToken();
          return this.post(url, params);
        } else if (res.status.toString() === '555') {
          this.logout();
          throw new Error('Sessão Expirada');
        } else if (res.status.toString() === '405') {
          this.logout(true);
          throw new Error('Sessão inválida');;
        } else if (res.status.toString() === '401') {
          if(url !== "login")
            this.logout();
          throw new Error('IP inválido');
        } else {
          const message = await res.json();
          throw new Error(message.message);
        }
      }
      if (responseType === 'blob') {
            return res.blob();
      } else {
          return res.json();
      }
    });
  }

  async patch(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${baseUrl}/${url}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body),
    }).then(async (res) => {
      if (
        res.status.toString()[0] === '4' ||
        res.status.toString()[0] === '5'
      ) {
        if (res.status.toString() === '403')
          throw new Error('Operação proibida para esse usuário');
        if (res.status.toString() === '420') {
          await this.refreshToken();
          return this.patch(url, params);
        } else if (res.status.toString() === '555') {
          this.logout();
          throw new Error('Sessão Expirada');
        } else if (res.status.toString() === '406') {
          this.logout();
          throw new Error('IP inválido');
        } else if (res.status.toString() === '405') {
          this.logout(true);
          throw new Error('Sessão inválida');
        } else {
          const message = await res.json();
          throw new Error(message.message);
        }
      }
      return res.json();
    });
  }

  async put(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${baseUrl}/${url}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    }).then(async (res) => {
      if (
        res.status.toString()[0] === '4' ||
        res.status.toString()[0] === '5'
      ) {
        if (res.status.toString() === '403')
          throw new Error('Operação proibida para esse usuário');
        if (res.status.toString() === '420') {
          await this.refreshToken();
          return this.put(url, params);
        } else if (res.status.toString() === '555') {
          this.logout();
          throw new Error('Sessão Expirada');
        } else if (res.status.toString() === '406') {
          this.logout();
          throw new Error('IP inválido');
        } else if (res.status.toString() === '405') {
          this.logout(true);
          throw new Error('Sessão inválida');
        } else {
          const message = await res.json();
          throw new Error(message.message);
        }
      }
      return res.json();
    });
  }

  async delete(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${baseUrl}/${url}`, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    }).then(async (res) => {
      if (
        res.status.toString()[0] === '4' ||
        res.status.toString()[0] === '5'
      ) {
        if (res.status.toString() === '403')
          throw new Error('Operação proibida para esse usuário');
        if (res.status.toString() === '420') {
          await this.refreshToken();
          return this.delete(url, params);
        } else if (res.status.toString() === '555') {
          this.logout();
          throw new Error('Sessão Expirada');
        } else if (res.status.toString() === '406') {
          this.logout();
          throw new Error('IP inválido');
        } else if (res.status.toString() === '405') {
          this.logout(true);
          throw new Error('Sessão inválida');
        } else {
          const message = await res.json();
          throw new Error(message.message);
        }
      }
      return res.json();
    });
  }

  serialize(obj) {
    const str = [];
    Object.keys(obj).forEach((key) =>
      obj[key] === undefined || obj[key] === null ? delete obj[key] : {}
    );
    for (const p in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
    return str.join('&');
  }

  headers(headers) {
    const jwt = localStorage.getItem('@SnxPayment:token');
    const currentSessionId = localStorage.getItem('@SnxPayment:sessionId');

    headers = {
      ...headers,
      'Content-Type': headers['Content-Type'] || 'application/json',
      Authorization: `bearer ${jwt}`,
      'X-Proccessor-Identity': localStorage.getItem('@SnxPayment:identity'),
      'X-Session-ID': currentSessionId,
    };
    return headers;
  }

  async refreshToken() {
    const refreshToken = localStorage.getItem('@SnxPayment:refreshToken');
    const body = { refreshToken };
    const res = await fetch(`${baseUrl}/reload_token`, {
      method: 'POST',
      headers: this.headers({}),
      body: JSON.stringify(body),
    });
    const resJson = await res.json();
    const { token } = resJson;
    const newToken = token?.token;
    const newRefreshToken = token?.refreshToken;
    if (newToken && newRefreshToken) {
      localStorage.setItem('@SnxPayment:token', newToken);
      localStorage.setItem('@SnxPayment:refreshToken', newRefreshToken);
    } else {
      console.log('falha ao renovar token', resJson);
    }
  }

  async logout(isSessionExpired = false) {
    if(isSessionExpired === true ) return  eventEmitter.emit('logout');

    localStorage.clear();

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    const rootURL = window.location.origin;
    window.location.replace(rootURL);
  }
}

export default new Api();
