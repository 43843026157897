import React, { memo, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Modal,
  Row,
  Select,
} from 'antd';
import { startCase, snakeCase } from 'lodash';

import { useAuth } from "../../../hooks/AuthProvider";
import useToggle from '../../../hooks/useToggle';
import Api from '../../../services/api';
import { printerService } from '../../../services/printerService';
import { getPlaces } from '../../../utils/getStores';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from "../style"

function ModalPrint({ isModalVisible, closeModal, card, client_id }) {
  const [storeLoading, toggleStoreLoading] = useToggle();
  const [printerLoading, togglePrinterLoading] = useToggle();
  const [loadingSend, setLoadingSend] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(null)

  const [storesActivePrinters, setStoresActivePrinters] = useState([]);

  const [printers, setPrinters] = useState([]);
  const [form] = Form.useForm();

  const { user } = useAuth();

  const getStores = async () => {
    toggleStoreLoading();
    try {
      const places = await getPlaces(user);
      const filteredStores = places.filter(loja => loja.id >= 1);
      setStoresActivePrinters(filteredStores);
    } catch (error) {
      notifyError(error.message);
    }
    finally {
      toggleStoreLoading();
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      getStores();
    }
    return () => {
      form.resetFields();
    }
  }, [isModalVisible]);


  const handleChangePlace = async (storeId) => {
    form.setFieldsValue({ print: undefined });
    setPrinters([]);
    try {
      togglePrinterLoading();
      const printers = await printerService.list(storeId, "true");
      if(printers?.length === 0) {
        notifyError("Não há impressoras para a loja selecionada");
      }
      setPrinters(printers);
    } catch (error) {
      notifyError(error.message);
    } finally {
      togglePrinterLoading();
    }
  };

  const handleSendPrint = useCallback(async () => {
    try {
      const formData = await form.validateFields();
      setLoadingSend(true);
      await Api.post('print_card', {
        id_cartao: card.id,
        id_impressora: formData.print,
        client_id,
        masked_number: card.numeroMascarado,
      });
      closeModal();
      notifySucces('O cartão foi impresso com sucesso');
    } catch (error) {
      if (!error.values) {
        console.error('Erro ao imprimir', error);
      }
    } finally {
      setLoadingSend(false);
    }
  }, [card, client_id, closeModal, form]);

  const titleCase = useCallback((string) => startCase(snakeCase(string)), []);


  return (
    <Modal
      title="Imprimir Cartão"
      visible={isModalVisible}
      onCancel={closeModal}
      width="50%"
    >
      {storeLoading ?
      (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      ) :
      (

      <Form layout="vertical" form={form}>
        <Form.Item
          name="loja"
          label="Loja"
          required
          rules={[{ required: true, message: 'Por favor escolha a loja' }]}
        >
          <Select
            showSearch
            optionFilterProp="name"
            loading={storeLoading}
            disabled={storeLoading}
            placeholder="Selecione uma loja"
            optionLabelProp="name"
            onChange={handleChangePlace}
            placement="bottomLeft"
            dropdownStyle={{ maxHeight:256 }}
          >
            {storesActivePrinters.map((loja) => (
              <Select.Option
                value={loja.id}
                key={loja.id}
                name={loja.nomeFantasia}
              >
                <Descriptions>
                  <Descriptions.Item
                    label="Nome Fantasia"
                    span={3}
                    labelStyle={{ fontWeight: 700 }}
                  >
                    {loja.nomeFantasia}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Endereço"
                    span={1}
                    labelStyle={{ fontWeight: 700 }}
                  >
                    {titleCase(loja.logradouro)}, {loja.numero},{' '}
                    {titleCase(loja.bairro)}, {titleCase(loja.cidade)} -{' '}
                    {loja.estado} - {loja.cep}
                  </Descriptions.Item>
                </Descriptions>
                <Divider style={{ margin: 0 }} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {form.getFieldValue('loja') && (
          <Form.Item
            name="print"
            label="Impressora"
            required
            rules={[{ required: true, message: 'Por favor escolha a impressora' }]}
          >
            <Select
              showSearch
              placeholder="Selecione uma impressora"
              optionFilterProp="name"
              loading={printerLoading}
              disabled={printerLoading || !form.getFieldValue('loja') || printers?.length === 0}
              optionLabelProp="name"
            >
              {printers.map((print) => (
                <Select.Option
                  value={print.codigoImpressora}
                  key={print.codigoImpressora}
                  name={print.nome}
                >
                  <Descriptions>
                    <Descriptions.Item
                      label="Nome"
                      span={3}
                      labelStyle={{ fontWeight: 700 }}
                    >
                      {print.nome}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Token"
                      span={3}
                      labelStyle={{ fontWeight: 700 }}
                    >
                      {print.token}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Código"
                      span={1}
                      labelStyle={{ fontWeight: 700 }}
                    >
                      {print.codigoImpressora}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Status"
                      span={1}
                      labelStyle={{ fontWeight: 700 }}
                    >
                      {print.status}
                    </Descriptions.Item>
                  </Descriptions>
                  <Divider style={{ margin: 0 }} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Row justify="end" align="bottom">
          <Col>
            <Button
              onClick={handleSendPrint}
              loading={loadingSend}
              disabled={selectedPrinter}
            >
              {loadingSend ? 'Imprimindo...' : 'Imprimir'}
            </Button>
          </Col>
        </Row>
       </Form>
      )}
    </Modal>
  );
}

export default memo(ModalPrint);
