import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ModalTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  border-bottom: 1px solid #ccc;

  h1 {
    font-size: 17px;
    line-height: 28px;
    font-weight: bold;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #ccc;
    cursor: pointer;
    margin-bottom: 4px;
  }
`;

export const ModalContent = styled.div``;

export const ModalFieldSelect = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const CpfCnpj = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  width: 96px;
  height: 34px;
  font-weight: bold;

  ${(props) =>
    props.selected
      ? css`
          background: var(--primaryColor);
          border: 1px solid var(--primaryColor);
          color: white;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}
`;

export const SearchContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const CardItem = styled.div`
  width: 100%;
  margin-top: 12px;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  transition: 0.5s ease-in;
  position: relative;

  .icon-check {
    position: absolute;
    right: -2px;
    background: white;
    width: 25px;
    height: 25px;

    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 50px, 0);
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 20px;
    }
  }

  &.active-item {
    border-color: #ffc53d;

    .icon-check {
      opacity: 1;
      visibility: visible;
      transition: 0.3s;
      top: -9px;
      transform: translate3d(0px, 0, 0);
    }
  }

  &:hover {
    border-color: #ffc53d;
  }

  p {
    margin-bottom: 4px;
    &.title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #434343;
      margin-bottom: 8px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 36px;
  padding: 4px 12px;
  background: #ffffff;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;

  color: rgba(0, 0, 0, 0.25);
`;

export const ClientContainer = styled.div`
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const ClientTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
`;

export const ClientDataContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    margin-top: 10px;
  }
`;

export const ClientInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div .myCustomPicker .ant-picker-panel-container {
    display: none !important;
  }

  div {
    width: 100%;
  }

  div + div {
    margin-left: 10px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  /* margin-bottom: 15px; */
  border-top: 1px solid #ccc;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  .div-full {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btns-first {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;

    button {
      width: 50%;
      height: 40px;
    }
  }
`;
