import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  background: #ff9c00;
`;

export const WrapperText = styled.div`
  color: #fff;
  font-size: 100px;
  font-weight: 700;
`;
