import React, { useCallback, useEffect, useState } from 'react';

import {
  FilePdfOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SendOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { format } from 'date-fns';
import useInView from 'react-cool-inview';

import { CompanyParams } from "../Context/companyContext"
import { usePermissions } from '../Context/permissionContext';
import api from '../services/api';
import { notifyError, notifySucces } from '../utils/notifications';
import { useAuth } from './AuthProvider';

function useInsurance(clientId, handleOpenModalSendMailTermInsurance = null) {
  const [insurances, setInsurances] = useState([]);
  const [dependents, setDependents] = useState([]);
  const [detailInsurance, setDetailInsurance] = useState(null);
  const [detailInsuranceModalOpen, setDetailInsuranceModalOpen] =
    useState(false);
  const [loadingPdfBilhet, setLoadingPdfBilhet] = useState(false);
  const [loadingPdfTerm] = useState(false);
  const [handleClickRow, setHandleClickRow] = useState({});

  const { permissions } = usePermissions();
  const { user } = useAuth();
  const { companyParams } = CompanyParams();

  const printTicketInsurance = useCallback(async (row) => {
    try {
      setLoadingPdfBilhet(true);
      const resp = await api.get(
        'insurance_ticket_contracts',
        { client_id: clientId, contract_id: row.codigoAdesao },
        { 'Content-Type': 'application/pdf' },
        true
      );
      const fileURL = URL.createObjectURL(await resp.blob());
      const pdfWindow = window.open(fileURL);
      setTimeout(() => {
        pdfWindow.document.title = 'Bilhete_Contrato.pdf';
      }, 750);
      setLoadingPdfBilhet(false);
    } catch (error) {
      setLoadingPdfBilhet(false);
      notifyError('Seguro não possui bilhete para emitir');
    }
  }, []);

  const printTermInsurance = useCallback((row) => {
    window.open(
      `/pdf?clientId=${clientId}&contractId=${row.codigoAdesao}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, []);

  async function handleClickSendEmail(row) {
    if (handleOpenModalSendMailTermInsurance) {
      setHandleClickRow(row);
      handleOpenModalSendMailTermInsurance(true);
    }
  }

  async function cancelInsurance(row) {
    try {
      await api.delete('client_insurances', {
        client_id: clientId,
        codigoAdesao: row.codigoAdesao,
        meioCancelamento: 'SNXPAYMENT',
        usuarioCancelamento: user?.username,
        lojaCancelamento: 1,
        descricaoSeguro: row.descricaoSeguro,
      });
      notifySucces(`Seguro ${row.descricaoSeguro} cancelado com sucesso`);
      setInsurances(
        insurances.filter((i) => i.codigoAdesao !== row.codigoAdesao)
      );
    } catch (error) {
      notifyError(error.message);
    }
  }

  function openModalDetailInsurance(row) {
    setDetailInsurance(row);
    setDetailInsuranceModalOpen(true);
  }

  function closeModalDetailInsurance() {
    setDetailInsurance(null);
    setDetailInsuranceModalOpen(false);
  }

  const [columnsInsurance, setColumnsInsurance] = useState([]);

  useEffect(() => {
    setColumnsInsurance([
      {
        title: 'Cód adesão',
        dataIndex: 'codigoAdesao',
        key: 'codigoAdesao',
      },
      {
        title: 'Data de adesão',
        dataIndex: 'dataAdesao',
        key: 'dataAdesao',
      },
      {
        title: 'Seguro',
        dataIndex: 'descricaoSeguro',
        key: 'descricaoSeguro',
      },
      {
        title: 'Valor da parcela',
        dataIndex: 'valorParcela',
        key: 'valorParcela',
      },
      {
        title: 'Ações',
        dataIndex: '',
        key: '',
        render: (row) => (
          // eslint-disable-next-line react/jsx-filename-extension
          <div style={{ paddingLeft: '10px' }}>
            {row.possuiBilhete &&
              (loadingPdfBilhet ? (
                <LoadingOutlined style={{ marginRight: '10px' }} />
              ) : (
                <FilePdfOutlined
                  style={{ marginRight: '10px' }}
                  title="Emitir bilhete"
                  onClick={() => printTicketInsurance(row)}
                />
              ))}

            {row.possuiTermoAdesao &&
              (loadingPdfTerm ? (
                <LoadingOutlined style={{ marginRight: '10px' }} />
              ) : (
                <FilePdfOutlined
                  style={{ marginRight: '10px' }}
                  title="Emitir termo adesão"
                  onClick={() => printTermInsurance(row)}
                />
              ))}

            {permissions && permissions.includes('cancel_insurance') && (
              <Popover
                content={
                  <Button onClick={() => cancelInsurance(row)}>
                    Confirmar cancelamento
                  </Button>
                }
                title="Deseja cancelar o seguro?"
                trigger="click"
                placement="top"
              >
                <StopOutlined
                  style={{ marginRight: '10px' }}
                  title="Cancelar"
                />
              </Popover>
            )}

            {row.possuiTermoAdesao &&
              (loadingPdfTerm ? (
                <LoadingOutlined style={{ marginRight: '10px' }} />
              ) : (
                <SendOutlined
                  style={{ marginRight: '10px' }}
                  onClick={() => handleClickSendEmail(row)}
                />
              ))}

            <InfoCircleOutlined onClick={() => openModalDetailInsurance(row)} />
          </div>
        ),
      },
    ]);
  }, [insurances, loadingPdfTerm, loadingPdfBilhet]);

  const [loadingInsurance, setLoadingInsurance] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoadingInsurance(true);
      if(companyParams?.has_security) {
        const response = await api.get('client_insurances', {
          client_id: clientId,
        });
        const insurancesFormatted = await response.data.map((insurance) => {
          insurance.valorParcela = `R$ ${insurance.valorParcela}`;
          return insurance;
        });
        await setInsurances(insurancesFormatted);
      }
      else {
        await setInsurances([]);
      }
      const responseDependents = await api.get('client_dependants', {
        client_id: clientId,
        filter: 'null' || 'ativos',
      });
      const dependentsFormatted = responseDependents.data.map((dependent) => {
        dependent.dataNascimento = format(
          new Date(dependent.dataNascimento),
          'dd/MM/yyyy'
        );
        return dependent;
      });
      await setDependents(dependentsFormatted);
    } catch (err) {
      console.error(err);
    } finally {
      await setLoadingInsurance(false);
    }
  }, [clientId]);

  const { observe } = useInView({
    threshold: 0.1,
    onEnter: ({ unobserve }) => {
      if (clientId) {
        getData().then(unobserve);
      }
    },
  });

  return {
    refInsurance: observe,
    insurances,
    dependents,
    columnsInsurance,
    loadingInsurance,
    reload: getData,
    detailInsuranceModalOpen,
    closeModalDetailInsurance,
    detailInsurance,
    handleClickRow,
  };
}

export default useInsurance;
