import styled from "styled-components";

export const statusCardActive = styled.div`
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 125px;
  border: 1px solid #52c41a;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #52c41a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
export const groupStatusCardWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const statusCardWarning = styled.div`
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  /* width: 125px; */
  border: 1px solid #ff912b;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #ff912b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 8px;
`;

export const statusCardBlock = styled.div`
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 125px;
  border: 1px solid #dc2626;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #dc2626;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
