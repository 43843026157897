import React from 'react';

import { Modal, Button } from 'antd';

import * as Style from "./style";

const ModalIframe = ({ visible, onClose, url, title }) => {
  const printIframeContent = () => {
    const iframe = document.getElementById('iframe-content');
    if (iframe) {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      width={800}
      style={{ top: 20 }}
    >
      <div style={{ width: '100%', height: '400px', overflow: 'hidden' }}>
        <iframe
          id="iframe-content"
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          title={title}
          style={{ border: 'none', display: 'block' }}
        />
      </div>
      <Style.WrapperFooter>
          <Button type="button" onClick={printIframeContent}>Imprimir</Button>
      </Style.WrapperFooter>
    </Modal>
  );
};

export default ModalIframe;