/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { usePermissions } from "../../../../../../../Context/permissionContext";
import { useClient } from "../../../../../../../hooks/ClientProvider";
import { InvoiceInstallmentService } from "../../../../../../../services/invoiceInstallmentService";
import {
  notifyError,
  notifySucces,
} from "../../../../../../../utils/notifications";
import Parcela from "../model/Parcela";
import Parcelamento from "../model/Parcelamento";
import { Simulation } from "../model/simulation";
import Element from "./InstallmentAnticipationDetails";
import { SelectedInstalmentInterface } from "./SelectedInstalment.interface";

interface InstallmentAnticipationDetailsProps {
  parcelamento: Parcelamento;
  onCloseClick: () => void;
}
function InstallmentAnticipationDetails({
  parcelamento,
  onCloseClick,
}: InstallmentAnticipationDetailsProps) {
  const { idParcelamento } = parcelamento;
  const { client } = useClient() as any;
  const clientId = client.client.id;
  const [simulation, setSimulation] = useState<Simulation>();
  const [selectedRows, setSelectedRow] = useState<Parcela[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { permissions } = usePermissions();
  const partialEnabled = permissions?.includes("active_installment_partial");
  const totalEnabled = permissions?.includes("active_installment_total");
  const selectedInstallments = useMemo<SelectedInstalmentInterface>(() => {
    const positions = selectedRows.map((item) => item.posicao);
    return {
      firstInstallment: Math.max(...positions),
      lastInstallment: Math.min(...positions),
    };
  }, [selectedRows]);

  useEffect(() => {
    setIsLoading(true);
    InvoiceInstallmentService.simulateAnticipation({
      installmentId: idParcelamento,
      clientId,
    })
      .then(({ data }) => {
        setSimulation(data);
      })
      .finally(() => setIsLoading(false));
  }, [clientId, idParcelamento]);

  const onSelectedInstalmentClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await InvoiceInstallmentService.anticipatePartial({
        installmentId: idParcelamento,
        clientId,
        ...selectedInstallments,
      });
      notifySucces("Antecipação de parcelas realizada com sucesso.");
      onCloseClick();
    } catch (err: any) {
      notifyError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedInstallments, idParcelamento, clientId]);

  const onTotalInstalmentClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await InvoiceInstallmentService.anticipateTotal({
        installmentId: idParcelamento,
        clientId,
        infoAntecipation: simulation?.resumoAntecipacao,

      });
      notifySucces("Antecipação de todas as parcelas realizado com sucesso.");
      onCloseClick();
    } catch (err: any) {
      notifyError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [clientId, idParcelamento]);

  const onChange = useCallback(
    (selectedRowKeys: React.Key[], rows: Parcela[]) => {
      setSelectedRow(rows);
    },
    []
  );

  return (
    <Element
      isTotalButtonDisabled={!totalEnabled}
      isLoading={isLoading}
      selectedInstallments={selectedInstallments}
      isPartialButtonDisabled={!partialEnabled}
      simulation={simulation}
      onChange={onChange}
      parcelamento={parcelamento}
      onSelectedInstalmentClick={onSelectedInstalmentClick}
      onTotalInstalmentClick={onTotalInstalmentClick}
    />
  );
}

export default memo(InstallmentAnticipationDetails);
