import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    background-color: #f2f2f2;
    border-bottom: none;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    padding-top: 20px;
    padding-bottom: 0;
  }

  .ant-modal-title {
    align-items: center;
    justify-content: center;
  }

  .anticon-success {
    color: green; /* Cor do ícone de sucesso */
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
  }

  .ant-modal-close-x {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    &:hover {
      color: #666666;
    }
  }

  .ant-modal-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ResultAntecipateRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &.parcela-unica span {
    font-weight: bold;
    font-size: 14px;
    color: #4f4f4f;
    margin-top: 30px;
  }

  &.data-faturamento span {
    color: #666666;
  }

  &.data-antecipacao span {
    color: #666666;
  }

  &.data-vencimento span {
    color: #666666;
  }
`;

export const HeaderResultContainer = styled.div`
  .anticon-success {
    color: green; 
    margin-right: 8px; 
  }

  .ant-modal-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  `;

export const AntecipateModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 700px;
  padding: 20px;
  background: #f5f5f5;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  b {
    font-weight: 700;
  }
`;