import React, { memo, useCallback, useState } from 'react';

import { Button, Input, Modal, Select } from 'antd';

import api from '../../../services/api';
import { formatDateTime } from '../../../utils/formatter';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const { TextArea } = Input;
const { Option } = Select;
const isPch = JSON.parse(
  localStorage.getItem("@SnxPayment:isPch")
);

function ModalBlockCard({
  isModalVisible,
  blockValue,
  blockTypes,
  closeModal,
  productID,
  bloqCard,
  clientID,
  card,
  setNewDetailBlockCard,
  codProduct,
  reloadCards
}) {
  const [blockType, setBlockType] = useState(blockValue);
  const [loading, setLoading] = useState(false);
  const [observacoes, setObservacoes] = useState('');

  const handleChangeObservacoes = useCallback(async (event) => {
    const { value } = event.target;
    await setObservacoes(value);
  }, []);

  const handleChangeType = useCallback((value) => {
    setBlockType(value);
  }, []);

  async function handleNewDetailBLock() {
    const { data } = await api.get('card_block_history', {
      card_id: card.id,
      cod_product: codProduct,
    });

    const getInfo = data.sort(
      (a, b) => new Date(b.dataBloqueio) - new Date(a.dataBloqueio)
    )[0];

    await setNewDetailBlockCard({
      tipoBloqueio: getInfo.tipoBloqueioCartao?.descricao
        ? getInfo.tipoBloqueioCartao?.descricao
        : null,
      observacao: getInfo.observacao ? getInfo?.observacao : null,
      dataBloqueio: getInfo.dataBloqueio
        ? formatDateTime(getInfo.dataBloqueio)
        : null,
    });
  }

  const handleBloq = useCallback(async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line eqeqeq
      if (blockType == 0) {
        throw Error('Selecione um Tipo de Bloqueio');
      }
      await api.patch('card_block', {
        card_id: card.id || card?.card?.card?.id,
        cod_product: productID,
        block_type_id: blockType,
        observation: observacoes,
        client_id: clientID,
        masked_number: card.numeroMascarado || card?.card?.card?.numeroMascarado,
      });
      const bloqData = {
        tipoBloqueio: {
          descricao: blockTypes.filter((types) => types.id === blockType)[0],
        },
        observacao: observacoes,
      };
      await bloqCard(card, bloqData);
      if(!isPch) await handleNewDetailBLock();
      closeModal();
      notifySucces('Cartão bloqueado com sucesso');
      reloadCards();
    } catch (error) {
      const msgError = error.message || 'Falha ao bloquear';
      notifyError(`Erro! ${msgError}`);
    } finally {
      setLoading(false);
    }
  }, [
    blockType,
    blockTypes,
    bloqCard,
    card,
    clientID,
    closeModal,
    observacoes,
    productID,
  ]);

  return (
    <Modal title="Bloquear" visible={isModalVisible} onCancel={closeModal}>
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.WrapperSelectBlock>
        <span>Tipo de bloqueio</span>
        <Select defaultValue={blockType} onChange={handleChangeType}>
          <Option value="0">Selecione</Option>
          {blockTypes
            .filter(({ cancelaCartao }) => !cancelaCartao)
            .map((type) => (
              <Option value={type.id} key={type.id}>
                {type.descricao[0].toUpperCase() +
                  type.descricao.slice(1).toLowerCase()}{' '}
                - Não Cancela
              </Option>
            ))}
        </Select>
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        <span>Mensagem</span>
        <TextArea rows={4} onChange={handleChangeObservacoes} />
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        {!loading && <Button onClick={handleBloq}>Enviar</Button>}
      </S.WrapperSelectBlock>
    </Modal>
  );
}

export default memo(ModalBlockCard);
