import React, { useCallback, useState } from "react";

import { InfoCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import useInView from "react-cool-inview";

import Can from "../components/Can";
import { usePermissions } from "../Context/permissionContext";
import api from "../services/api";
import { useAuth } from "./AuthProvider";
import { useClient } from "./ClientProvider";

function usePremiumAnnuity() {
  const [isLoadingAnnuity, setIsLoadingAnnuity] = useState(false);
  const [premiumAnnuity, setPremiumAnnuity] = useState([]);
  const [allBonnusAnnuity, setAllBonnusAnnuity] = useState([]);
  const [openAnnuityModal, setOpenAnnuityModal] = useState(false);
  const [activeIdAnnuity, setActiveIdAnnuity] = useState(null);
  const [cancelIdAnnuity, setCancelIdAnnuity] = useState(null);
  const [openModalAnnuityInformation, setOpenModalAnnuityInformation] =
    useState(false);
  const [selectedAnnuityInformation, setSelectedAnnuityInformation] =
    useState(null);
    const { permissions } = usePermissions();
    const { client } = useClient();

  async function handleOpenCancelAnnuityModal(id) {
    setOpenAnnuityModal(true);
    setCancelIdAnnuity(id);
    setActiveIdAnnuity(false);
  }

  async function handleOpenModalAnnuityInformation(annuity) {
    setOpenModalAnnuityInformation(true);
    setSelectedAnnuityInformation(annuity);
  }

  const columnPremiumAnnuity = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Descricao",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) => <span>R$ {String(valor).replace(".", ",")}</span>,
    },
    {
      title: "Ações",
      dataIndex: "",
      key: "",
      render: (annuity) => (
        <div style={{ paddingLeft: "10px" }}>
          <InfoCircleOutlined
            title="Detalhes"
            onClick={() => handleOpenModalAnnuityInformation(annuity)}
          />
          {permissions && annuity.isAdhered && annuity.isBonificada && (
            <Can permission="cancel_premium_annuity">
              <LogoutOutlined
                title="Cancelar anuidade bonificada"
                style={{
                  width: "10px",
                  marginLeft: "10px",
                  cursor: "pointer",
                  color: "#FF4D4F",
                }}
                onClick={() => {
                  handleOpenCancelAnnuityModal(annuity.id);
                }}
              />
            </Can>
          )}
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingAnnuity(true);
      if (client) {
        const { data: listAnnuities } = await api.get(
          `annuity/product/${client?.product?.codigoProduto}`
        );

        const { data: annuitiesAdhered } = await api.get(
          `client/${client?.client?.id}/annuity`
        );

        // Verifica se annuitiesAdhered é um array ou um objeto
        if (Array.isArray(annuitiesAdhered)) {
          // Se for um array, verifica cada anuidade em annuitiesAdhered
          annuitiesAdhered.forEach((adheredAnnuity) => {
            if (
              !listAnnuities.some((annuity) => annuity.id === adheredAnnuity.id)
            ) {
              listAnnuities.push(adheredAnnuity);
            }
          });
        } else {
          // Se não for um array, verifica se o objeto não está em listAnnuities e o adiciona
          if (
            !listAnnuities.some((annuity) => annuity.id === annuitiesAdhered.id)
          ) {
            listAnnuities.push(annuitiesAdhered);
          }
        }

        if (listAnnuities && listAnnuities.length > 0) {
          let hasAnnuityAdhered = listAnnuities.find(
            (annuity) => annuity.id === annuitiesAdhered?.id
          );

          if (!hasAnnuityAdhered) listAnnuities.push(annuitiesAdhered);

          const annuities = listAnnuities.map((annuity) => {
            annuity.isAdhered = annuitiesAdhered?.id === annuity.id;
            return annuity;
          });

          let bonnusAnnuity = annuities.filter(
            (annuity) => annuity.isBonificada === true && annuity.isAdhered
          );
          if (!Array.isArray(hasAnnuityAdhered)) {
            // Se hasAnnuityAdhered não for um array, transforme-o em um array com um único elemento.
            hasAnnuityAdhered = [hasAnnuityAdhered];
          }

          if (bonnusAnnuity.length === 0) {
            bonnusAnnuity = annuities.filter(
              (annuity) =>
                annuity?.isBonificada === false &&
                hasAnnuityAdhered.some((item) => item.id === annuity?.id)
            );
          }

          const getAllBonnusAnnuity = annuities.filter(
            (annuity) => annuity.id !== annuitiesAdhered.id
          );

          setAllBonnusAnnuity([...getAllBonnusAnnuity]);
          setPremiumAnnuity([...bonnusAnnuity]);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingAnnuity(false);
    }
  });

  const { observe } = useInView({
    threshold: 0.1,
    onEnter: ({ unobserve }) => {
      if (client?.product?.codigoProduto) {
        fetchData().then(unobserve);
      }
    },
  });

  return {
    ref: observe,
    isLoadingAnnuity,
    premiumAnnuity,
    allBonnusAnnuity,
    columnPremiumAnnuity,
    permissions,
    reload: fetchData,
    openAnnuityModal,
    setOpenAnnuityModal,
    activeIdAnnuity,
    cancelIdAnnuity,
    openModalAnnuityInformation,
    setOpenModalAnnuityInformation,
    selectedAnnuityInformation,
    handleOpenModalAnnuityInformation
  };
}

export default usePremiumAnnuity;
