import React, { memo, useCallback, useState } from 'react';

import CryptoJS from 'crypto-js';

import Api from '../../../../services/api';
import { encrypt } from '../../../../utils/crypto';
import { notifyError, notifySucces } from '../../../../utils/notifications';
import Element from './setPassword';

interface SetPasswordModalProps {
  closeModal: () => void;
  visible: boolean;
  cardId: number;
  clientId: number;
  productId: number;
}
function SetPassword({
  closeModal,
  visible,
  clientId,
  productId,
  cardId,
}: SetPasswordModalProps) {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
    const [inputValueConfirm, setInputValueConfirm] = useState('');


  const encryptCardPassword = useCallback(async (pass) => {
    try {
      const encryptedData = await encrypt(pass);
      
      return encryptedData;
    } catch (error) {
      console.error('Erro ao criptografar senha!');
      await setInputValue('');
    }
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);

        const passCrypt = await encryptCardPassword(inputValue);

        await Api.patch('card_set_password', {
          card_id: cardId,
          cod_product: productId,
          client_id: clientId,
          new_password: passCrypt,
        });
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        notifySucces('Senha alterada com sucesso');
        closeModal();
      } catch (err: any) {
        setLoading(false);
        notifyError(`Falha ao alterar senha!\n ${err.message}`);
      }
    },
    [cardId, clientId, closeModal, productId, inputValue]
  );

  const onChange = useCallback((e) => {
    const { value } = e.target;
    setInputValue((state) => {
      const newValue = value.replace(/\D/g, '');
      return newValue === state ? state : newValue;
    });
  }, []);

  const onChangeConfirm = useCallback((e) => {
    const { value } = e.target;
    setInputValueConfirm((state) => {
      const newValue = value.replace(/\D/g, '');
      return newValue === state ? state : newValue;
    });
  }, []);

  if (!visible) return null;

  return (
    <Element
      onSubmit={onSubmit}
      valuePassword={inputValue}
      valueConfirm={inputValueConfirm}
      onChange={onChange}
      onChangeConfirm={onChangeConfirm}
      closeModal={closeModal}
      loading={loading}
    />
  );
}

export default memo(SetPassword);