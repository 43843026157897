import React from 'react';

import { formatDate } from '../../../../../../../utils/formatter';
import Section from '../../Section';
import styles from './style.module.scss';

function UpperSection({ selectedInstallmentOption, invoices }) {
  return (
    <Section title="Dados do parcelamento">
      <div className={styles.info}>
        <div className={styles.info__col}>
          <p>
            Serviço financiado: <b>FATURA</b>
          </p>
          <p>
            Valor Financiado (R$):{' '}
            <b>{selectedInstallmentOption?.valorFinanciado.toFixed(2)}</b>
          </p>
          <p>
            Vencimento: <b>{invoices?.[0]?.dataVencimento}</b>
          </p>
          <p>
            Valor do Juros (R$):{' '}
            <b>{selectedInstallmentOption?.valorJuros.toFixed(2)}</b>
          </p>
        </div>

        <div className={styles.info__col}>
          <p>
            Quant. de parcelas:{' '}
            <b>{selectedInstallmentOption?.quantidadeParcelas}</b>
          </p>
          <p>
            Valor total (R$):{' '}
            <b>{selectedInstallmentOption?.valorTotal?.toFixed(2)}</b>
          </p>
          <p>
            Taxa de Juros (%a.m.):{' '}
            <b>{selectedInstallmentOption?.taxaJuros?.toFixed(2)}</b>
          </p>
          <p>
            Valor do Desconto (R$):{' '}
            <b>{selectedInstallmentOption?.valorDesconto?.toFixed(2)}</b>
          </p>
        </div>

        <div className={styles.info__col}>
          <p>
            Data de Ativação:{' '}
            <b>{formatDate(selectedInstallmentOption?.dataAtivacao)}</b>
          </p>
          <p>
            Data recomendada de Ativação:{' '}
            <b>
              {formatDate(selectedInstallmentOption?.dataRecomendavelAtivacao)}
            </b>
          </p>
          <p>
            IOF Total aplicado (R$):{' '}
            <b>{selectedInstallmentOption?.valorIof?.toFixed(2)}</b>
          </p>
          <p>
            Taxa IOF Adicional (%a.m.):{' '}
            <b>{selectedInstallmentOption?.taxaIofAdicional?.toFixed(2)}</b>
          </p>
          {/* <p> */}
          {/*  Taxa IOF vigente (%a.d.): */}
          {/*  {' '} */}
          {/*  <b>???</b> */}
          {/* </p> */}
          <p>
            CET (%a.a.): <b>{selectedInstallmentOption?.cet}</b>
          </p>
        </div>
      </div>
    </Section>
  );
}

export default React.memo(UpperSection);
