import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { usePermissions } from "../../Context/permissionContext";
import { useClient } from "../../hooks/ClientProvider";
import MenuItem from "./MenuItem";
import * as S from "./styles";

const menuData = [
  {
    id: 2,
    title: "Solicitações",
    icon: "PullRequestOutlined",
    typePermission: [{ id: "view_solicitation" }],
    route: "/solicitacoes",
  },
  {
    id: 6,
    title: "Parametros",
    icon: "SettingOutlined",
    typePermission: [
      { id: "view_list_product_issuer" },
      { id: "view_types_adjustments" },
      { id: "view_cycles_issuer" },
      { id: "backoffice_view_list_lock_card" },
      { id: "backoffice_view_list_lock_account" },
    ],
    subItems: [
      {
        id: 6.1,
        title: "Produtos",
        icon: "ContainerOutlined",
        typePermission: [{ id: "view_list_product_issuer" }],
        route: "/lista-de-produtos",
      },
      {
        id: 6.2,
        title: "Ajustes",
        icon: "SettingOutlined",
        typePermission: [{ id: "view_types_adjustments" }],
        route: "/lista-dos-tipos-de-ajustes",
      },
      {
        id: 6.3,
        title: "Ciclos",
        icon: "HistoryOutlined",
        typePermission: [{ id: "view_cycles_issuer" }],
        route: "/lista-de-ciclos",
      },
      {
        id: 6.4,
        title: "Bloqueio de conta",
        icon: "ProfileOutlined",
        typePermission: [{ id: "backoffice_view_list_lock_account" }],
        route: "/lista-dos-tipos-de-bloqueio-conta",
      },
      {
        id: 6.5,
        title: "Bloqueio do cartão",
        icon: "ProfileOutlined",
        typePermission: [{ id: "backoffice_view_list_lock_card" }],
        route: "/lista-dos-tipos-de-bloqueio-cartao",
      },
    ],
  },
  {
    id: 7,
    title: "Lojas",
    icon: "ShopOutlined",
    typePermission: [
      { id: "backoffice_view_list_stores" },
      { id: "manage_printer" },
      { id: "permission_schedules_store" },
    ],
    subItems: [
      {
        id: 7.1,
        title: "Lojas credenciadas",
        icon: "ContainerOutlined",
        typePermission: [{ id: "backoffice_view_list_stores" }],
        route: "/lista-de-lojas",
      },
      {
        id: 7.2,
        title: "Impressoras",
        icon: "PrinterOutlined",
        typePermission: [{ id: "manage_printer" }],
        route: "/printers",
      },
      {
        id: 7.3,
        title: "Agenda lojista",
        icon: "ContainerOutlined",
        typePermission: [{ id: "permission_schedules_store" }],
        route: "/agenda-lojista",
      },
    ],
  },
  {
    id: 8,
    title: "Portador",
    icon: "UserOutlined",
    isBottom: "menu-vendas-pagamentos",
    typePermission: [
      { id: "view_launch_adjustment" },
      { id: "permission_register_payment" },
      { id: "make_sales_manual" },
      { id: "list_canceled_insurance" },
      { id: "get_reserve_balance" },
      { id: "list_pay_launch" },
      { id: "list_sale_launch" },
      { id: "permission_view_inadimplencia" },
    ],
    subItems: [
      {
        id: 8.1,
        title: "Ajustes no portador",
        icon: "FileDoneOutlined",
        typePermission: [{ id: "view_launch_adjustment" }],
        route: "/lancar-ajuste",
      },
      {
        id: 8.2,
        title: "Lançar Pagamentos",
        icon: "FileDoneOutlined",
        typePermission: [{ id: "permission_register_payment" }],
        route: "/lancar-pagamentos",
      },
      {
        id: 8.3,
        title: "Lançar Vendas",
        icon: "CreditCardOutlined",
        typePermission: [{ id: "make_sales_manual" }],
        route: "/lancar-vendas",
      },
      {
        id: 8.4,
        title: "Listar Pagamentos",
        icon: "ContainerOutlined",
        typePermission: [{ id: "list_pay_launch" }],
        route: "/listagem-pagamentos",
      },
      {
        id: 8.5,
        title: "Listar Vendas",
        icon: "ContainerOutlined",
        typePermission: [{ id: "list_sale_launch" }],
        route: "/listagem-vendas",
      },
      {
        id: 8.6,
        title: "Seguros",
        icon: "ContainerOutlined",
        typePermission: [{ id: "list_canceled_insurance" }],
        route: "/lista-de-seguros-cancelados",
      },
      {
        id: 8.7,
        title: "Saldo reserva",
        icon: "ContainerOutlined",
        typePermission: [{ id: "get_reserve_balance" }],
        route: "/listagem-reserva-de-saldo",
      },
      {
        id: 8.8,
        title: "Inadimplência",
        icon: "ContainerOutlined",
        typePermission: [{ id: "permission_view_inadimplencia" }],
        route: "/lista-de-inadimplencia",
      },
    ],
  },
  {
    id: 1,
    title: "Config. Elevaty",
    icon: "ElevatyIcon",
    isBottom: "menu-listagens",
    typePermission: [
      { id: "crud_solicitation_type" },
      { id: "crud_classification" },
      { id: "crud_user" },
      { id: "crud_group_user" },
    ],
    subItems: [
      {
        id: 1.1,
        title: "Tipos de solicitações",
        icon: "PullRequestOutlined",
        typePermission: [{ id: "crud_solicitation_type" }],
        route: "/tipos_solicitacoes",
      },
      {
        id: 1.2,
        title: "Tipos de classificações",
        icon: "StarOutlined",
        typePermission: [{ id: "crud_classification" }],
        route: "/classificacao",
      },
      {
        id: 1.4,
        title: "Grupos",
        icon: "UsergroupAddOutlined",
        typePermission: [{ id: "crud_group_user" }],
        route: "/groups",
      },
      {
        id: 1.3,
        title: "Usuários",
        icon: "UserAddOutlined",
        typePermission: [{ id: "crud_user" }],
        route: "/usuario",
      },
       {
         id: 1.6,
         title: "Acesso",
         icon: "LockOutlined",
         typePermission: [{ id: "ip_restriction" }],
         route: "/access",
       },
    ],
  },
  {
    id: 5,
    title: "Relatórios",
    icon: "FileTextOutlined",
    typePermission: [{ id: "hiden" }],
    route: "/reports",
  },
];

function Menu({ paymentsClick, salesClick }) {
  const location = useLocation();
  const { permissions } = usePermissions();
  const { client } = useClient();
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [filteredMenuData, setFilteredMenuData] = useState([]);

  const handleSubMenuClick = (subMenuId) => {
    setOpenSubMenu(openSubMenu === subMenuId ? null : subMenuId);
  };

  useEffect(() => {
    const newFilteredMenuData = menuData.filter((menuItem) => {
      if (menuItem.typePermission) {
        return menuItem.typePermission.some((permission) =>
          permissions.includes(permission.id)
        );
      }
      return true;
    });
    setFilteredMenuData(newFilteredMenuData);
  }, [permissions]);

  return (
    <>
      {openSubMenu && <S.OverflowMenu onClick={() => setOpenSubMenu(false)} />}
      <S.WrapperNewMenu>
        <div className="Menu">
          {client && client?.client && location.pathname !== "/atendimento" && (
            <MenuItem
              iconName="ContactsOutlined"
              id={9}
              route="/atendimento"
            />
          )}
          {filteredMenuData.map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              id={menuItem.id}
              isBottom={menuItem.isBottom}
              title={menuItem.title}
              iconName={menuItem.icon}
              subItems={menuItem.subItems}
              isOpen={menuItem.id === openSubMenu}
              typePermission={menuItem.typePermission}
              onSubMenuClick={handleSubMenuClick}
              setOpenSubMenu={setOpenSubMenu}
              route={menuItem.route}
              paymentsClick={paymentsClick}
              salesClick={salesClick}
            />
          ))}
        </div>
      </S.WrapperNewMenu>
    </>
  );
}

export default Menu;
