import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Dropdown, Menu } from 'antd';
import { format } from 'date-fns';

import iconMenu from '../../../assets/bars-solid.svg';
import Card from '../../../components/Card';
import ModalInsurance from '../../../components/ModalInsurance';
import Table from '../../../components/Table';
import { usePermissions } from '../../../Context/permissionContext';
import { useAuth } from '../../../hooks/AuthProvider';
import { useClient } from '../../../hooks/ClientProvider';
import useInsurance from '../../../hooks/useInsurance';
import api from '../../../services/api';
import { getPlaces } from '../../../utils/getStores';
import { notifyError } from '../../../utils/notifications';
import useCheckPermission from '../../../utils/useCheckPermission';
import ModalDetailInsurance from '../../home/modals/modalDetailInsurance';
import ModalSendMail from '../../home/modals/modalSendMail';
import * as S from '../style';

const { Item } = Menu;

function InsuranceComponentV2() {
  const hasPermission = useCheckPermission('view_contracted_insurances');
  const { user } = useAuth();
  const { permissions } = usePermissions();
  const { client } = useClient();
  const [modalInsuranceIsOpen, setInsuranceIsOpen] = useState(false);
  const [storeAux, setStoreAux] = useState([]);
  const [isModalSendMailVisible, setIsModalSendMailVisible] = useState(false);

  const handleDependents = useCallback(async () => {
    try{
      const rawDependents = await api.get('client_dependants', {
      client_id: client?.client?.id,
      filter: 'null' || 'ativos',
      });
      const dependentsFormatted = rawDependents.data.map((dependent) => {
        dependent.dataNascimento = format(
          new Date(dependent.dataNascimento),
          'dd/MM/yyyy'
        );
        return dependent;
      });
      if (dependentsFormatted === null || dependentsFormatted === undefined || dependentsFormatted.length === 0)   throw new Error('Não existem dependentes ativos');

      return (dependentsFormatted.filter(item => item.status !== "C"));

  }catch(error){
    notifyError(error.message);
  }
  }, []);

  const {
    ref,
    insurances,
    reload: reloadInsurances,
    loadingInsurance,
    columnsInsurance,
    detailInsuranceModalOpen,
    closeModalDetailInsurance,
    detailInsurance,
    handleClickRow,
  } = useInsurance(client?.client?.id, setIsModalSendMailVisible);


  const handleModalInsurance = () => {
    setInsuranceIsOpen((state) => !state);
  }

  useEffect(() => {
    async function fetchPlaces() {
      const places = await getPlaces(user);
      setStoreAux(places);
    }

    fetchPlaces();
  }, [user]);


  const menuInsurance = useMemo(
    () => (
      <Menu>
        {permissions && permissions.includes('insurance_Contract') && (
          <Item key="0" onClick={handleModalInsurance}>
            <span>Contratar seguro</span>
          </Item>
        )}
      </Menu>
    ),
    [handleModalInsurance, permissions]
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <ModalInsurance
        open={modalInsuranceIsOpen}
        closeModal={handleModalInsurance}
        clientId={client?.client?.id}
        clientInsurances={insurances}
        reloadClientInsurances={reloadInsurances}
        client={client}
        setDependents={handleDependents}
        lojas={storeAux}
      />
      <ModalDetailInsurance
        open={detailInsuranceModalOpen}
        closeModal={closeModalDetailInsurance}
        insurance={detailInsurance}
      />
      <ModalSendMail
        open={isModalSendMailVisible}
        closeModal={() => setIsModalSendMailVisible(false)}
        insurance={handleClickRow}
      />
      <S.WrapperSeguros ref={ref}>
        <Card
          title="Seguros Contratados"
          modalTitle="Seguros Contratados"
          loading={loadingInsurance}
          extra={[
            <S.MenuInsegurance>
              <Dropdown
                overlay={menuInsurance}
                trigger={['click']}
                placement="bottomRight"
              >
                <div className="solid-bar">
                  <img src={iconMenu} alt="Icone menu" />
                </div>
              </Dropdown>
            </S.MenuInsegurance>,
          ]}
        >
          <Table rows={insurances} columns={columnsInsurance} page={10} />
        </Card>
      </S.WrapperSeguros>
    </>
  );
}

export const InsuranceV2 = memo(InsuranceComponentV2);
