import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Form, Input, Select } from 'antd';

import { SolicitationTypeService } from '../../../../services/solicitationTypeService';
import SolicitationFileInput from '../SolicitationFileInput';
import * as S from './style';

const priorities = [
  {
    label: 'Baixa',
    value: 'LOW',
  },
  {
    label: 'Media',
    value: 'MEDIUM',
  },
  {
    label: 'Alta',
    value: 'HIGH',
  },
];
const { Option } = Select;
const { TextArea } = Input;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} é obrigatório',
};
/* eslint-enable no-template-curly-in-string */

function CreateSolicitationForm({ onFinish, isLoading, onCancel, setFiles }) {
  const [solicitationTypes, setSolicitationTypes] = useState([]);

  useEffect(() => {
    SolicitationTypeService.list().then((respose) => {
      setSolicitationTypes(respose?.filter((st) => !st.deleted_at));
    });
  }, []);

  const filterOption = useCallback((input, option) => {
    const optionName = option.props.children.toLowerCase();
    const inputName = input.toLowerCase();
    return optionName.indexOf(inputName) > -1;
  }, []);

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item name="type_id" label="Tipo" rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={filterOption}
          placeholder="Selecione um tipo"
        >
          {solicitationTypes.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="priority"
        label="Prioridade"
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          filterOption={filterOption}
          placeholder="Selecione uma Prioridade"
        >
          {priorities.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <SolicitationFileInput setFiles={setFiles} />

      <Form.Item
        name="description"
        label="Observações"
        rules={[{ required: true }]}
      >
        <TextArea rows={3} maxLength={512} showCount />
      </Form.Item>

      <S.FormActionButtons>
        <Button htmlType="button" onClick={onCancel} disabled={isLoading}>
          Cancelar
        </Button>
        <Button htmlType="submit" loading={isLoading} type="primary">
          Salvar
        </Button>
      </S.FormActionButtons>
    </Form>
  );
}

export default memo(CreateSolicitationForm);
