import React from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';

import LoadingElevaty from '../../../../../../../../components/LoadingElevaty';
import { formatDateTime } from '../../../../../../../../utils/formatter';
import Section from '../../../../components/Section';

function InstallmentOptionsPreview({
  isLoading,
  data,
  setSelectedInstallmentOption,
}) {
  const columns = [
    {
      title: 'Data e horário de simulação',
      key: 'dataSimulacao',
      render: (row) => <span>{formatDateTime(row.dataSimulacao)}</span>,
    },
    // {
    //   title: 'Data e horário de pagamento',
    //   key: 'dataFaturamento',
    //   render: () => <span>???</span>, // TODO: Get the correct info with Lucas
    // },
    {
      title: 'Valor financiado (R$)',
      dataIndex: 'valorFinanciado',
      key: 'valorFinanciado',
    },
    {
      title: 'Tipo',
      render: (row) => (row?.devedorTotal === 'N' ? 'Fatura' : 'Saldo Devedor'),
    },
    {
      title: 'Quant. de Parcelas',
      dataIndex: 'quantidadeParcelas',
      key: 'quantidadeParcelas',
    },
    {
      title: 'Valor total (R$)',
      dataIndex: 'valorTotal',
      key: 'valorTotal',
    },
    {
      width: 100,
      title: 'Ações',
      render: (row) => (
        <Tooltip title="Visualizar detalhes do parcelamento">
          <Button onClick={() => setSelectedInstallmentOption(row)}>
            <EyeOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const WrapperNewLoading = {
    width: "100%",
    minHeight: "20vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Section>
      {isLoading ? (
        <div style={WrapperNewLoading}>
          <LoadingElevaty width="40px" height="40px" duration="durFast"/>
          </div>

      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 240 }}
          rowKey="quantidadeParcelas"
          loading={isLoading}
        />
      )}
    </Section>
  );
}

export default React.memo(InstallmentOptionsPreview);
