import { tint, shade } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
`;

export const Table = styled.table`
  td {
    padding-right: 6px;
    padding-bottom: 6px;
  }
`;

export const CpfCnpj = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  width: 96px;
  height: 34px;
  font-weight: bold;

  ${(props) =>
    props.selected
      ? css`
          background: var(--primaryColor);
          border: 1px solid var(--primaryColor);
          color: white;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}
`;

export const StrengthMeter = styled.div`
  height: 0.3rem;
  background-color: lightgrey;
  border-radius: 3px;
  margin: 0.5rem 0;

  &::before {
    content: "";
    background-color: ${(props) =>
      `${
        ["red", "orange", "#03a2cc", "#03a2cc", "#0ce052"][
          props.strengthWidth - 1
        ] || ""
      }`};
    height: 100%;
    width: ${(props) => (props.strengthWidth / 5) * 100}%;
    display: block;
    border-radius: 3px;
    transition: width 0.2s;
  }
`;
export const ContainerCreateEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;

  .loading-user {
    width: 78%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading-user__text {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
`;
export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
export const DescriptionInfo = styled.div`
  word-break: break-all;
`;

export const ContentInfoLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: black;
  font-weight: bold;
`;

export const AttachmentIcon = styled.div`
  margin-right: 6px;
`;

export const AttachmentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  > div {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
`;

export const SubTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const Form = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px;
  width: 78%;

  hr {
    border-top: 1px solid #ccc;
  }

  div.error-id {
    border: 1px solid #c53030;
  }

  .error {
    width: 100%;
    text-align: right;
    font-size: 1rem;
    color: #c53030;
  }

  input {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 9px;
    width: 100%;
    color: #666360;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &::placeholder {
      color: #928f8f;
      font-weight: 500;
    }

    &:disabled {
      color: #444 !important;
      background: #f5f5f5;
      border: 1px solid #ccc;
    }

    &.error-id {
      border: 1px solid #c53030;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    background: var(--primaryColor);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    border: 1px solid var(--primaryColor);

    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    &:hover {
      background: ${tint(0.2, "#FF9C00")};
      border: 1px solid var(--primaryColor);
    }
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 8px;

  span {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0px 4px;
  }

  p {
    color: #ff4d4f;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    margin: 0px 4px;
  }
`;

export const ForgotPassword = styled.a`
  color: #1890ff;
  font-size: 14px;
  line-height: 32px;
  align-self: flex-end;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${shade(0.2, "#1890FF")};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  /* margin-bottom: 15px; */
  /* border-top: 1px solid #ccc; */

  display: flex;
  align-items: center;
  justify-content: flex-start;

  button + button {
    margin-left: 10px;
  }
  button {
    margin-top: 6px;
  }

  button:hover {
        background: var(--brand-color-gold-6);
        color: #ffffff;
        border: 1px solid var(--primaryColor);
      }


  .back-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
  }
`;
