/* eslint-disable max-len */
import React, { useEffect, useRef, useState, useCallback } from 'react';

import { useField } from '@unform/core';
import CurrencyInput from 'react-currency-masked-input';
import * as AiIcons from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';
import InputMask from 'react-input-mask';

import { Container, Error, PasswordEye, ErrorDown } from './style';

function Input({
  name,
  icon: Icon,
  mask,
  currency = false,
  errorDown,
  ...rest
}) {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(<AiIcons.AiFillEye />);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handlePasswordWatch = useCallback((inputName) => {
    const inputValue = document.getElementsByName(inputName);
    if (inputValue[0].type === 'password') {
      inputValue[0].type = 'text';
      setEyeIcon(<AiIcons.AiFillEyeInvisible />);
    } else {
      inputValue[0].type = 'password';
      setEyeIcon(<AiIcons.AiFillEye />);
    }
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
    setIsFilled(!!rest.defaultValue);
  }, [fieldName, registerField, rest.defaultValue]);

  return (
    <>
      <Container
        isErroed={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isDisabled={!!(rest && rest.disabled)}
      >
        {Icon && <Icon size={20} />}
        {!mask ? (
          currency ? (
            <CurrencyInput
              name={name}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={defaultValue}
              ref={inputRef}
              autocomplete="off"
              {...rest}
            />
          ) : (
            <input
              name={name}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={defaultValue}
              ref={inputRef}
              autoComplete="off"
              {...rest}
            />
          )
        ) : (
          <InputMask
            maskChar=""
            mask={mask}
            name={name}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            ref={inputRef}
            autocomplete="off"
            {...rest}
          />
        )}
        {name.match('password') && !error && (
          <PasswordEye onClick={() => handlePasswordWatch(name)}>
            {eyeIcon}
          </PasswordEye>
        )}
        {error && !errorDown && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>

      {error && errorDown && <ErrorDown>{error}</ErrorDown>}
    </>
  );
}

export default Input;
