import { getFormatFilterTimeline } from '../utils/formatDate';
import api from './api';

const endpoint = 'timeline';

export class SolicitationService {
  static list({
    desc,
    page,
    limit,
    protocol,
    processorClientId,
    startDate,
    endDate,
  } = {}) {
    const date_1 = getFormatFilterTimeline(startDate, 'end');
    const date_2 = getFormatFilterTimeline(endDate, 'start');

    return api
      .get(endpoint, {
        desc,
        page,
        limit,
        protocol,
        sorter: 'created_at,descend',
        processor_client_id: processorClientId,
        startDate: date_1,
        endDate: date_2,
      })
      .then((response) => ({
        ...response.data,
        data: response?.data.data.map((item) => ({
          key: item.id,
          ...item,
        })),
      }));
  }
}
