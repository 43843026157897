import { toast } from 'react-toastify';

import { isParsedError } from './parsedErrors';

function notifySucces(message, description = '') {
  const texto = `${message}\n${description}`;

  toast.success(texto, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
}

function notifyError(message = '', description = '', shouldSplit = true) {
  const isFormattedError = isParsedError(message);
  if(isFormattedError === "Você não tem permissão para este recurso.") {
    console.error(isFormattedError);
    return;
  }

  if (isFormattedError) {
    message = isFormattedError;
  }

  const texto = shouldSplit ? message.split("*")[0] : message;

  toast.error(texto, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
}

function notifyInfo(message, description = '') {
  const texto = `${message}\n${description}`;

  toast.info(texto, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
}

export { notifySucces, notifyError, notifyInfo };
