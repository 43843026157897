import { tint, shade } from "polished";
import styled, {css} from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);

`;

export const PageInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 102px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  margin-left: 2%;
  margin-top: 15px;
`;

export const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 20px;
`;

export const SearchContainer = styled.div`
  margin-top: 1%;
  background-color: white;
  width: 98%;
  height: 80px;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 13px;
  }
`;

export const SearchContentInputs = styled.div`
  margin-left: 1%;
  display: flex;
  align-items: center;
  justify-content: center;

  .email_input {
    width: 500px;
  }

  span {
    margin: 0px 10px;
  }
`;

export const SearchContentButtons = styled.div`
  width: 23%;
  @media (min-width: 1500px) {
    width: 16%;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1%;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
  }

  span {
    color: var(--primaryColor);
  }
`;

export const CleanButton = styled.button`
  background: #ffffff;
  padding: 5px 16px;
  width: 136px;
  height: 32px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin: 0px 8px;
`;

export const UserContainer = styled.div`
  margin-top: 1%;
  background-color: white;
  width: 100%;
  height: 70%;
  @media (min-width: 1500px) {
    height: 75%;
  }
  display: flex;
  flex-direction: column;

  .box-form{
    max-width: 400px;
  }

  .flex {
    form {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .select-item {
    width: 350px;
    margin-right: 16px;
    position: relative;

    &.card{
      width: 200px;
    }

    .error{
      font-size: 12px;
      color: red;
      position: absolute;
      top: 0;
      right: 0;
    }

    .error-id {
      .ant-select-selector {
        border: 1px solid red;
      }
    }
  }

  .btn-pay{
    width:100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .select-date {
    & + div {
      margin-left: 16px;
    }
  }

  .select-btn {
    padding-top: 21px;
  }

  .select-table{
    margin-top: 4rem;
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 100%;
  max-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableTitle = styled.div`
  margin-right: 1%;
  display: flex;
  flex-direction: row;
`;

export const TableButtons = styled.div`
  margin-right: 1%;
  display: flex;
  flex-direction: row;

  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 12px;
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
  }
`;

export const TableContainer = styled.div`
  margin-left: 1%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 98%;

  .ant-pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .btn-cancel{
    background: #F2363D;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }

  .btn-warning{
    border-color:#d9d9d9;
    color: #000000D9;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  button + button {
    margin-left: 10px;
  }

  button {
    width: 90%;
    height: 30px;
  }
`;

export const LabelField = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
  margin: 0px 4px;
  width: 150px;

  p {
    color: #ff4d4f;
    margin: 3px 4px;
    font-size: 20px;
    line-height: 22px;
    text-align: right;
  }
`;

export const StatusActive = styled.div`
  display: flex;
  padding: 5px;
  width: 86px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid #27ae60;
  color: #27ae60;
  display: inline-block;
`;

export const StatusInactive = styled.div`
  display: flex;
  padding: 5px;
  width: 86px;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid tomato;
  color: tomato;
  display: inline-block;
`;

export const InputsLabel = styled.div`
  span {
    font-weight: bold;
  }
  .margem {
    align-items: center;
  }
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  width: 96px;
  height: 34px;
  font-weight: bold;

  ${(props) =>
    props.selected
      ? css`
          background: var(--primaryColor);
          border: 1px solid var(--primaryColor);
          color: white;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px;
  width: 100%;

  hr {
    border-top: 1px solid #ccc;
  }

  div.error-id {
    border: 1px solid #c53030;
  }

  .error {
    width: 100%;
    text-align: right;
    font-size: 1rem;
    color: #c53030;
  }

  input {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 9px;
    width: 100%;
    color: #666360;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &::placeholder {
      color: #928f8f;
      font-weight: 500;
    }

    &:disabled {
      color: #444 !important;
      background: #f5f5f5;
      border: 1px solid #ccc;
    }

    &.error-id {
      border: 1px solid #c53030;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    background: var(--primaryColor);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 4px;
    border: 1px solid var(--primaryColor);

    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    &:hover {
      background: ${tint(0.2, "#FF9C00")};
      border: 1px solid var(--primaryColor);
    }
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 8px;

  span {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0px 4px;
  }

  p {
    color: #ff4d4f;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    margin: 0px 4px;
  }
`;

export const FormAccount = styled.div`
    margin: 10px 0;
    width: 72.5%;
`;

export const CpfCnpj = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const SearchUserWrapper = styled.div`
  width: 400px;
`;

export const SearchDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  
  .title{
    font-weight: bold;
  }

  .formWrapper{
    margin-top: 15px;
  }
  .date-selection-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .select-btn {
    margin-top: 15px; 
    align-self: flex-end; 
  }
`;
;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const SearchDateWrapper = styled.div`
 display: flex;
  justify-content: space-between; 
  align-items: flex-start;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
