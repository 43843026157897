import React, { useCallback } from 'react';

import { Button, Modal } from 'antd';

import Loading from '../../../components/Loading';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import * as S from '../style';

function ReactivateUser({
  auxUser,
  loading,
  setModalUserRestore,
  modalUserRestore,
  setLoading,
  fetchUser,
}) {
  const restoreUser = useCallback(async () => {
    try {
      setLoading(true);
      await api.patch('user_restore', { user_id: auxUser.id });
      setModalUserRestore(false);
      notifySucces('Usuário reativado');
      fetchUser();
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
      notifyError('Erro ao ativar usuário', err.message);
      return false;
    }
  }, [auxUser.id, fetchUser, setLoading, setModalUserRestore]);

  return (
    <Modal
      title="Ativar usuário"
      visible={modalUserRestore}
      onCancel={() => setModalUserRestore(false)}
    >
      <S.UserContainerDelete>
        <span>Tem certeza que deseja ativar o usuário?</span>
        <S.UserDelete>{auxUser.username}</S.UserDelete>
        <S.ModalButtonContainer>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Button type="button" onClick={() => setModalUserRestore(false)}>
                Cancelar
              </Button>
              <Button type="button" onClick={restoreUser}>
                Confirmar
              </Button>
            </>
          )}
        </S.ModalButtonContainer>
      </S.UserContainerDelete>
    </Modal>
  );
}

export default React.memo(ReactivateUser);
