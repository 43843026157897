import React from 'react';

import styled, { keyframes } from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Spinner = styled.div`
  border: 8px solid #ffffff;
  border-top-color: #fa8c16;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  animation: ${rotate} 1s linear infinite;
  box-shadow: inset 0px 0px 0px 4px #ffffff;
  position: relative;

  &:after {
    content: "";
    display: block;
    border: 8px solid #fa8c16;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${rotate} 0.8s cubic-bezier(0.6, 0.2, 0.0, 0.8) infinite;
    box-sizing: border-box;
  }

  &:before {
    content: "";
    display: block;
    border: 8px solid #fa8c16;
    border-radius: 50%;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
    animation: ${rotate} 1.2s cubic-bezier(0.6, 0.2, 0.0, 0.8) infinite;
    box-sizing: border-box;
  }
`;

const Message = styled.p`
  color: #fa8c16;
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
`;

function LoadingBiometric({ message }) {
  return (
    <Container>
      <ContentWrapper>
        <Spinner />
        <Message>{message}</Message>
      </ContentWrapper>
    </Container>
  );
}

export default LoadingBiometric;
