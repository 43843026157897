import React, { createContext, useState, useEffect, useCallback, useContext, useMemo } from 'react';

import api from '../services/api';

interface PopupContextData {
  hasPopupActive: boolean;
  fetchHasPopupActive: () => Promise<void>;
  handleClosePopup: () => void;
}

const PopupContext = createContext<PopupContextData>({} as PopupContextData);

export const PoupProvider: React.FC = ({ children }) => {
  const [hasPopupActive, setPopupActive] = useState<boolean>(false);

  const fetchHasPopupActive = useCallback(async () => {
    try {
      const response = await api.get('release_popup');
      setPopupActive(response.data);
    } catch (error) {
      console.error("Erro ao buscar versão:", error);
    }
  }, []);

  const handleClosePopup = () => {
    setPopupActive(false);
  };


  useEffect(() => {
    const existingToken = localStorage.getItem("@SnxPayment:token")
    if(existingToken) {
      fetchHasPopupActive();
    }
  }, [fetchHasPopupActive,]);

  const contextValue = useMemo(() => ({
    hasPopupActive,
    fetchHasPopupActive,
    handleClosePopup,
  }), [hasPopupActive, fetchHasPopupActive, handleClosePopup]);

  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a hasPopupActiveContext');
  }
  return context;
};

export default PopupContext;
