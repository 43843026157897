import React from 'react';

import ReactInputMask from 'react-input-mask';

import { InputCardItem, InputDiv } from './style';

function InputCard({ ...props }) {
  if (props.mask) {
    return (
      <InputDiv>
        <p>
          {props.requiredInfo && <span>*</span>}
          {props.addonBefore}
        </p>
        <ReactInputMask {...props}>
          {(inputProps) => (
            <InputCardItem
              {...inputProps}
              disabled={props.disabled ? props.disabled : null}
            />
          )}
        </ReactInputMask>
      </InputDiv>
    );
  }
  return (
    <InputDiv>
      <p>
        {props.requiredInfo && <span>*</span>}
        {props.addonBefore}
      </p>
      <InputCardItem {...props} />
    </InputDiv>
  );
}

export default InputCard;
