import React from "react";

import LoadingElevaty from ".";
import * as S from "./style";

export function PageLoadingElevaty() {
  return (
    <S.LoadingContainer>
      <LoadingElevaty />
    </S.LoadingContainer>
  );
}
