/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unreachable */
/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */

import React, { useEffect, useRef, useState } from 'react';

import * as AntIcon from '@ant-design/icons';
import { Descriptions, Divider, Input, Modal, Popover, Select } from 'antd';
import SignatureCanvas from 'react-signature-canvas'

import { usePermissions } from '../../Context/permissionContext';
import { useAuth } from '../../hooks/AuthProvider';
import api from '../../services/api';
import { notifyError, notifySucces } from '../../utils/notifications';
import Button from '../Button';
import Loading from '../Loading';
import SmallLoading from '../SmallLoading';
import * as S from './style';


const { Option } = Select;

function ModalInsurances({
  open,
  closeModal,
  clientId,
  clientInsurances,
  reloadClientInsurances,
  client,
  setDependents,
  lojas,
}) {
  const [modalWidth, setModalWidth] = useState(700);
  const [step, setStep] = useState(0);
  const [insurances, setInsurances] = useState([]);
  const [filteredInsurances, setFilteredInsurances] = useState([]);
  const [insurancesThatAllowDependent, setInsurancesThatAllowDependent] =
    useState([]);
  const [dependentsSelected, setDependentsSelected] = useState([]);
  const [insuranceSelected, setInsuranceSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [detailsInsurance, setDetailsInsurance] = useState([]);
  const [currentBeneficiary, setCurrentBeneficiary] = useState('');
  const [usedCheckAll, setUsedCheckAll] = useState(false);
  const [loadingInsuranceAdd, setLoadingInsuranceAdd] = useState(false);
  const [willUseInsuranceToDependent, setWillUseInsuranceToDependent] =
    useState(false);
  const [listDependents, setListDependents] = useState([]);
  const [dependentIndex, setDependentIndex] = useState(0);
  const [selectLoja, setSelectLoja] = useState(null);
  const { user } = useAuth();
  const { permissions } = usePermissions();
  const goForward = async(beneficiarySelected) => {

    if (beneficiarySelected === 'dependent' && step === 0) {
      const dependents = await setDependents();
      if (dependents !== null && dependents !== undefined) {
        setListDependents(dependents);
        setStep(step + 1);
      }
    } else if(beneficiarySelected === 'holder' || step >= 1) {
      setStep(step + 1);
    }else{
      setStep(step + 1);
    }
  };

  const goBack = () => setStep(step - 1);

  function filterObjByKeyValue(obj, key, filterArr) {
    return obj.filter(object => !filterArr.includes(object[key]));
  }

  const initialState = () => {
    setModalWidth(700);
    setInsuranceSelected({});
    setStep(0);
    setDependentsSelected([]);
    setLoading(false);
    setWillUseInsuranceToDependent(false);
    setMissingFields([]);
    setDetailsInsurance([]);
    setUsedCheckAll(false);
    setLoadingInsuranceAdd(false);
  };

  const createSelectFields = async (arr) => {
    for (const [i, field] of arr.entries()) {
      if (field.conteudoPadrao) {
        const auxArr = field.conteudoPadrao.split(',');
        field.selectFormatted = [];

        await auxArr.map((a) => {
          const aux = a.split('|');
          field.selectFormatted.push({
            val1: aux[0],
            val2: aux[1],
            text: aux[2],
          });
        });
        arr[i] = field;
      }
    }
    return arr;
  };

  const getInsurancesThatAllowDependent = () => {
    const allowDependent = insurances.filter(
      (i) => i.permiteDependente === true
    );
    if (!allowDependent[0]) {
      notifyError('Nenhum seguro disponível permite dependente');
      return false;
    }
    setInsurancesThatAllowDependent(allowDependent);
  };

  const handleStep0Holder = (beneficiarySelected) => {
    const activenIsurances = Object.values(clientInsurances).map(obj => obj.descricaoSeguro);
    const res = filterObjByKeyValue(insurances,'descricao',activenIsurances)
    setFilteredInsurances(res);

    if (beneficiarySelected === 'dependent') getInsurancesThatAllowDependent();
    setCurrentBeneficiary(beneficiarySelected);
    setModalWidth(1000);
  };

  const uncheckAll = async () => {
    setUsedCheckAll(false);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (const checkbox of checkboxes) {
      checkbox.checked = false;
    }
    await setDependentsSelected([]);
  };

  const handleInsurance =  async (value) => {
    setInsuranceSelected({});
    let auxInsuranceSelect;
    if (value !== 'none') {
       auxInsuranceSelect = insurances.filter(
        (i) => Number(i.id) === Number(value)
      );
      setInsuranceSelected(auxInsuranceSelect[0]);
    }
    if (currentBeneficiary === 'dependent') {
      try {
        setLoading(true);
        await uncheckAll();
        if(value === 'none'){
          setLoading(false);
          return false;
        }
        const { dependentes } = await api.get('insurance_dependent', {
          client_id: clientId,
          codigo_insurance: auxInsuranceSelect[0].idProduto,
        });
        if (dependentes.length > 0) {
          const filteredDependents = listDependents.filter(dependent => {
            return !dependentes.some(d => String(d.codigoDependente) === String(dependent.id));
          });
          await setListDependents(filteredDependents);
        }
        setLoading(false);
      } catch (error) {
        notifyError(error.message);
        console.error(error.message);
        setLoading(false);
      }
    }
  };



  const handleDependentSelect = async (e) => {
    const { value } = e.target;
    const hasDependent = dependentsSelected.find(
      (d) => Number(d.id) === Number(value)
    );
    if (hasDependent) {
      const removedDependent = dependentsSelected.filter((dependent) => {
        if (Number(dependent.id) !== Number(value)) {
          return dependent;
        }
      });
      await setDependentsSelected(removedDependent);
    } else {
      const dependent = listDependents.find((d) => Number(d.id) === Number(value));
      dependentsSelected.push(dependent);
      await setDependentsSelected(dependentsSelected);
    }
  };

  const checkAll = async () => {
    setUsedCheckAll(true);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (const checkbox of checkboxes) {
      checkbox.checked = true;
    }
    listDependents.map((dependent) => {
      dependentsSelected.push(dependent);
    });
    await setDependentsSelected(dependentsSelected);
  };



  const handleFieldChange = (index, event) => {
    const values = [...missingFields];
    if (currentBeneficiary === 'dependent') {
      values[dependentIndex].campos[index].value = event.target.value;
      setMissingFields(values);
    } else {
      values[index].value = event.target.value;
      setMissingFields(values);
    }
  };

  const handleFieldChangeSelect = (index, event) => {
    if (event !== 'none') {
      const values = [...missingFields];
      if (currentBeneficiary === 'dependent') {
        values[dependentIndex].campos[index].value = event;
        setMissingFields(values);
      } else {
        values[index].value = event;
        setMissingFields(values);
      }
    }
  };

  const handleStep1 = async () => {
    if(lojas.length === 1){
      await setSelectLoja(lojas[0].id);
    }

    if (lojas.length > 1 && selectLoja == null) {
      notifyError('Selecione uma Loja');
      return false;
    }

    const checkHasInsurance = await clientInsurances.filter(
      (i) => i.codigoSeguro === insuranceSelected.id
    );

    if (checkHasInsurance[0]) {
      notifyError('Titular já possui este seguro');
      return false;
    }

    setLoading(true);
    await api
      .get('insurance_fields', {
        codigoOperacao: insuranceSelected.idOperacao,
        codigoProduto: insuranceSelected.idProduto,
        codigoSeguro: insuranceSelected.id,
        idCliente: clientId,
      })
      .then(async (response) => {
        const fieldsFormatted = await createSelectFields(response.data.campos);

        setMissingFields(fieldsFormatted);
        setDetailsInsurance([response.data.totalParcelas, response.data.valorParcela])
        setLoading(false);
        goForward();
      })
      .catch(() => {
        setLoading(false);
        notifyError('Falha ao pegar campos do seguro');
        return false;
      });
    return true;
  };

  const [signatureBase64, setSignatureBase64] = useState(null);
  const sigCanvas = useRef({});
  const [setSavedSignature] = useState(null);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [isSignatureCleared, setIsSignatureCleared] = useState(false);


  const handleSignature = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const canvas = sigCanvas.current.getCanvas();

      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      const scaleFactor = 0.5;

      resizedCanvas.width = canvas.width * scaleFactor;
      resizedCanvas.height = canvas.height * scaleFactor;

      ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

      const base64 = resizedCanvas.toDataURL("image/jpeg", 0.7);
      setSignatureBase64(base64);
      setShowSaveSuccess(true);
      setTimeout(() => {
        setShowSaveSuccess(false);
      }, 3000);
      setIsSignatureCleared(false);
    } else {
      notifyError('Por favor, assine antes de salvar!');
    }
  };



  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignatureBase64(null);
      setSavedSignature(null);
      setIsSignatureCleared(true);
    }
  };

  const handleStep2 = async () => {
    try {
      setLoading(true);

      let missingFieldsFormatted = [];

      if (missingFields.length) {
        missingFieldsFormatted = await missingFields.map((item) => {
          if (!item.value)
            throw new Error(`Preencha o campo ${item.descricao}`);
          if (item.value.length > item.tamanho) {
            throw new Error(
              `O tamanho do campo ${item.descricao} é maior do que o permitido. Preencha apenas com ${item.tamanho} caracteres`
            );
          }
          return {
            nome: item.nome,
            valor: item.value,
          };
        });
      }

      if (permissions.includes("mandatory_signature")) {
        await handleSignature();

        if (signatureBase64) {
          const base64WithoutPrefix = signatureBase64.substring(signatureBase64.indexOf(',') + 1);
          missingFieldsFormatted.push({
            nome: "contratante.dadosPessoais.assinatura",
            valor: base64WithoutPrefix,
          });
        }
      }

      if (loadingInsuranceAdd === false) {
        await setLoadingInsuranceAdd(true);
        await api
          .post('client_insurances', {
            client_id: clientId,
            codigoLoja: selectLoja,
            codigoOperacao: insuranceSelected.idOperacao,
            codigoPortador: clientId,
            codigoProduto: insuranceSelected.idProduto,
            codigoPromotor: user?.id,
            codigoSeguro: insuranceSelected.id,
            cpfTitular: client.client.cpf,
            meioAdesao: 'SNXPAYMENT',
            nomePromotor: user?.username,
            camposAdesao: missingFieldsFormatted,
            descricaoSeguro: insuranceSelected.descricao,
          })
          .then(() => {
            if (insuranceSelected.permiteDependente) {
              setWillUseInsuranceToDependent(true);
              goForward();
              setLoading(false);
            } else {
              notifySucces('Seguro contratado');
              closeModal();
              initialState();
            }
            setLoadingInsuranceAdd(false);

            reloadClientInsurances();
          })
          .catch((err) => {
            setLoading(false);
            setLoadingInsuranceAdd(false);
            const errorMessage = err?.message
              ? err.message.split(',')[0]
              : 'Falha ao contratar seguro';
              throw new Error(errorMessage);
          });
      }
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
      return false;
    }
  };


  const handleStep3 = () => {
    getInsurancesThatAllowDependent();
    setCurrentBeneficiary('dependent');
    setStep(1);
    setLoading(false);
  };

  const handleStep1Dependent = async () => {
    try {
      if (!insuranceSelected.id) throw new Error('Selecione um seguro');
      if(lojas.length === 1) await setSelectLoja(lojas[0].id);
      if (lojas.length > 1 && selectLoja == null) throw new Error('Selecione uma Loja');
      if (!dependentsSelected[0])
        throw new Error('Selecione pelo menos um dependente');
      if (!clientInsurances[0] && !willUseInsuranceToDependent) {
        throw new Error('O titular não possui nenhum seguro');
      } else if (clientInsurances[0] && !willUseInsuranceToDependent) {
        let aux = '';
        await clientInsurances.map((ci) => {
          aux = `${aux + ci.codigoSeguro},`;
        });
        if (!aux.includes(insuranceSelected.id)) {
          throw new Error('O titular não possui este seguro');
        }
      }
      setLoading(true);

      for (const [, dependent] of dependentsSelected.entries()) {
        const response = await api
          .get('insurance_fields', {
            codigoOperacao: insuranceSelected.idOperacao,
            codigoProduto: insuranceSelected.idProduto,
            codigoSeguro: insuranceSelected.id,
            idCliente: clientId,
            idDependente: dependent.id,
          })
          .catch(() => {
            throw new Error(
              `Falha ao listar campos do dependente ${dependent.nome}`
            );
          });

        response.data.campos.dependent_name = dependent.nome;
        const fields = await createSelectFields(response.data.campos);
        setDetailsInsurance([response.data.totalParcelas, response.data.valorParcela])
        missingFields.push({
          campos: fields,
          dependent,
        });
      }

      setDependentsSelected(dependentsSelected);
      setMissingFields(missingFields);
      setLoading(false);
      setUsedCheckAll(false);
      goForward();
      return true;
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
      return false;
    }
  };

  const handleStep2Dependent = async () => {
    try {
      setLoading(true);

      let missingFieldsFormatted = [];

      if (missingFields[dependentIndex]) {
        missingFieldsFormatted = await missingFields[0].campos.map((item) => {
          if (!item.value)
            throw new Error(`Preencha o campo ${item.descricao}`);
          if (item.value.length > item.tamanho) {
            throw new Error(
              `O tamanho do campo ${item.descricao} é maior do que o permitido. Preencha apenas com ${item.tamanho} caracteres`
            );
          }
          item.formatted = {
            nome: item.nome,
            valor: item.value,
          };
          return item;
        });
      }
      missingFields[dependentIndex].campos = missingFieldsFormatted;

      // setMissingFields(missingFields[dependentIndex]);
      if (missingFields.length > dependentIndex + 1) {
        const aux = dependentIndex + 1;
        setDependentIndex(aux);
        return true;
      }

      for (const missingField of missingFields.entries()) {
        const dependentName = missingField[1].dependent.nome;
        const dependentId = missingField[1].dependent.id;

        const aux = [];

        await missingField[1].campos.map((campo) => {
          aux.push(campo.formatted);
          return true;
        });
        if (loadingInsuranceAdd === false) {
          await setLoadingInsuranceAdd(true);
          try {
            await api.post('dependant_insurances', {
              client_id: clientId,
              codigoLoja: selectLoja,
              codigoOperacao: insuranceSelected.idOperacao,
              codigoPortadorTitular: clientId,
              codigoProduto: insuranceSelected.idProduto,
              codigoPromotor: user?.id,
              codigoSeguro: insuranceSelected.id,
              cpfTitular: client.client.cpf,
              meioAdesao: 'SNXPAYMENT',
              nomeDependente: dependentName,
              codigoDependente: dependentId,
              nomePromotor: user?.username,
              camposAdesao: aux,
              descricaoSeguro: insuranceSelected.descricao,
            });
            notifySucces('Seguro(s) contratado');
            closeModal();
            initialState();
            return true;
          } catch (err) {
            setLoadingInsuranceAdd(false);
              const errorMessage = err?.message
                ? err.message.split(',')[0]
                : 'Falha ao contratar seguro';
                throw new Error(errorMessage);
            };
        }
      }
    } catch (err) {
      setLoading(false);
      notifyError(err.message);
      return false;
    }
  };

  useEffect(() => {
    initialState();
    async function getData() {
      await api
        .get('insurances')
        .then((response) => {
          setInsurances(response.data.filter((a) => a.exclusivoPdv === false));
        })
        .catch(() => {
          notifyError('Falha ao listar seguros');
        });
    }
    if (clientId) getData();
  }, [clientId]);

  return (
    <Modal
      width={modalWidth}
      visible={open}
      title="Contratar seguros"
      onCancel={() => {
        initialState();
        closeModal();
        setIsSignatureCleared(true);
      }}
    >
      <S.InsuranceContainer>
        {step === 0 && (
          <S.BeneficiaryContainer>
            <div>
              <S.Title>Contratar seguro para:</S.Title>
            </div>
            <S.BeneficiaryCardContainer>
              <S.BeneficiaryCard id="holder">
                <S.BeneficiaryCardIcon>
                  <AntIcon.UserOutlined />
                </S.BeneficiaryCardIcon>
                <S.BeneficiaryTitle>
                  <h2>Titular</h2>
                </S.BeneficiaryTitle>
                <S.BeneficiaryDescription>
                  <span>Contrate seguro para o titular</span>
                </S.BeneficiaryDescription>
                <S.BeneficiaryButtonContainer>
                  <Button
                    type="button"
                    onClick={() => {
                      goForward('holder');
                      handleStep0Holder('holder');
                    }}
                  >
                    Contratar
                  </Button>
                </S.BeneficiaryButtonContainer>
              </S.BeneficiaryCard>
              <S.BeneficiaryCard id="dependent">
                <S.BeneficiaryCardIcon>
                  <AntIcon.UsergroupAddOutlined />
                </S.BeneficiaryCardIcon>
                <S.BeneficiaryTitle>
                  <h2>Dependente</h2>
                </S.BeneficiaryTitle>
                <S.BeneficiaryDescription>
                  <span>Contrate seguro para o dependente</span>
                </S.BeneficiaryDescription>
                <S.BeneficiaryButtonContainer>
                  <Button
                    type="button"
                    onClick={() => {
                      goForward('dependent');
                      handleStep0Holder('dependent');
                    }}
                  >
                    Contratar
                  </Button>
                </S.BeneficiaryButtonContainer>
              </S.BeneficiaryCard>
            </S.BeneficiaryCardContainer>
          </S.BeneficiaryContainer>
        )}

        {step === 1 &&
          (currentBeneficiary === 'holder' ? (
            <S.HolderContainer>
              <S.HolderDataContainer>
                <S.SubTitle>
                  Titular:
                  <p>{client.client.name}</p>
                </S.SubTitle>
                <S.SubTitle>
                  CPF:
                  <p>{client.client.cpf}</p>
                </S.SubTitle>
              </S.HolderDataContainer>

              <S.HolderInsurancesContainer>
                <div>
                  <S.SubTitle>Seguro(s) que o titular já possui:</S.SubTitle>
                </div>
                <S.InsurancesTagsContainer>
                  {!clientInsurances[0] ? (
                    <div>Titular não possui nehum seguro</div>
                  ) : (
                    <>
                      {clientInsurances.map((tag) => (
                        <S.Tag key={tag.codigoSeguro}>
                          {tag.descricaoSeguro}
                        </S.Tag>
                      ))}
                    </>
                  )}
                </S.InsurancesTagsContainer>
              </S.HolderInsurancesContainer>
              <S.SelectInsuranceContainer>
              {lojas.length > 1 && (
                <><S.SubTitle>Escolha a Loja</S.SubTitle><Select
                  defaultValue={lojas.length === 1 ? lojas[0].id : null}
                  showSearch
                  placeholder="Selecione uma loja"
                  optionFilterProp="name"
                  optionLabelProp="name"
                  listHeight={300}
                  disabled={lojas.length === 1}
                  onChange={async (id_loja) => {
                    await setSelectLoja(id_loja);
                  } }
                  required
                >
                  {lojas && lojas.map((loja, index) => (
                    <Option
                      value={loja.id}
                      key={loja.id}
                      name={`${loja.nomeFantasia ?? ''} ${loja.razaoSocial}`}
                    >
                      <Descriptions>
                        {loja.nomeFantasia && (
                          <Descriptions
                            label="Nome Fantasia"
                            span={8}
                            labelStyle={{ fontWeight: 700 }}
                          >
                            {loja.nomeFantasia}
                          </Descriptions>
                        )}
                        <Descriptions
                          label="Razão Social"
                          span={8}
                          labelStyle={{ fontWeight: 700 }}
                        >
                          {loja.razaoSocial}
                        </Descriptions>
                      </Descriptions>
                      <Divider style={{ margin: 0 }} />
                    </Option>
                  ))}
                </Select></>
                )}
              </S.SelectInsuranceContainer>
              <S.SelectInsuranceContainer>
                <S.SubTitle>Escolha o seguro que deseja contratar:</S.SubTitle>
                <Select
                  onChange={(e) => handleInsurance(e)}
                  id="insurances_list"
                  key={insuranceSelected.id}
                  defaultValue={
                    insuranceSelected.id ? insuranceSelected.id : 'none'
                  }
                >
                  <Option value="none" key="none">
                    Selecione o seguro
                  </Option>
                  {filteredInsurances.map((insurance) => (
                    <Option value={insurance.id} key={insurance.id}>
                      {insurance.descricao}
                    </Option>
                  ))}
                </Select>
                <p />
                <p />
                <S.InsuranceButtonContainer>
                  <Button
                    type="button"
                    id="goBack"
                    onClick={() => {
                      goBack();
                      initialState();
                    }}
                  >
                    Voltar
                  </Button>
                  <Button type="button" onClick={() => handleStep1()}>
                    {loading ? <SmallLoading /> : 'Próximo'}
                  </Button>
                </S.InsuranceButtonContainer>
              </S.SelectInsuranceContainer>
            </S.HolderContainer>
          ) : (
            <S.DependentContainer>
              <S.SelectInsuranceDependentContainer>
                <div>
                  <S.SubTitle>
                    Escolha o seguro que deseja contratar:
                  </S.SubTitle>
                  {willUseInsuranceToDependent ? (
                    <Select
                      onChange={(e) => handleInsurance(e)}
                      id="insurances_list"
                      defaultValue={insuranceSelected.id}
                      key={insuranceSelected.id}
                      disabled
                    >
                      <Option value="none" key="none">
                        Selecione o seguro
                      </Option>
                      {insurancesThatAllowDependent.map((insurance) => (
                        <Option value={insurance.id} key={insurance.id}>
                          {insurance.descricao}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      onChange={(e) => handleInsurance(e)}
                      id="insurances_list"
                      key={insuranceSelected.id}
                      defaultValue={
                        insuranceSelected.id ? insuranceSelected.id : 'none'
                      }
                    >
                      <Option value="none" key="none">
                        Selecione o seguro
                      </Option>
                      {insurancesThatAllowDependent.map((insurance) => (
                        <Option value={insurance.id} key={insurance.id}>
                          {insurance.descricao}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
                <div>
                  {usedCheckAll ? (
                    <Button type="button" onClick={() => uncheckAll()}>
                      Desmarcar todos
                    </Button>
                  ) : (
                    <Button type="button" onClick={() => checkAll()}>
                      Marcar todos
                    </Button>
                  )}
                </div>
              </S.SelectInsuranceDependentContainer>
              <p />
              <p />
              <p />

              {lojas.length > 1 && (
                <><S.SubTitle>Escolha a Loja</S.SubTitle><Select
                  defaultValue={lojas.length === 1 ? lojas[0].id : null}
                  showSearch
                  placeholder="Selecione uma loja"
                  optionFilterProp="name"
                  optionLabelProp="name"
                  listHeight={300}
                  disabled={lojas.length === 1}
                  onChange={async (id_loja) => {
                    await setSelectLoja(id_loja);
                  } }
                  required
                >
                  {lojas && lojas.map((loja, index) => (
                    <Option
                      value={loja.id}
                      key={loja.id}
                      name={`${loja.nomeFantasia ?? ''} ${loja.razaoSocial}`}
                    >
                      <Descriptions>
                        {loja.nomeFantasia && (
                          <Descriptions
                            label="Nome Fantasia"
                            span={8}
                            labelStyle={{ fontWeight: 700 }}
                          >
                            {loja.nomeFantasia}
                          </Descriptions>
                        )}
                        <Descriptions
                          label="Razão Social"
                          span={8}
                          labelStyle={{ fontWeight: 700 }}
                        >
                          {loja.razaoSocial}
                        </Descriptions>
                      </Descriptions>
                      <Divider style={{ margin: 0 }} />
                    </Option>
                  ))}
                </Select></>
                )}
              <S.DependentsList>
                <S.SubTitle>
                  Escolha os dependentes para contratar o seguro:
                </S.SubTitle>
                {loading ? (
                  <div style={{ marginTop: 10, marginBottom: 40 }} >
                  <Loading/>
                  </div>
                ) : (
                listDependents.map((dependent) => (
                  <S.DependentsData key={dependent.id}>
                    <div>
                      <S.SubTitle>Nome:</S.SubTitle>
                      <span>{dependent.nome}</span>
                    </div>
                    <S.CheckboxContainer>
                      <input
                        type="checkbox"
                        id={dependent.id}
                        value={dependent.id}
                        onClick={(e) => handleDependentSelect(e)}
                      />
                      <span htmlFor="scales">Selecionar</span>
                    </S.CheckboxContainer>
                  </S.DependentsData>
                ))) }
              </S.DependentsList>

              <S.InsuranceButtonContainer>
                <Button
                  type="button"
                  id="goBack"
                  onClick={() => {
                    goBack();
                    initialState();
                  }}
                >
                  Voltar
                </Button>
                <Button type="button" onClick={() => handleStep1Dependent()}>
                  {loading ? <SmallLoading /> : 'Próximo'}
                </Button>
              </S.InsuranceButtonContainer>
            </S.DependentContainer>
          ))}

        {step === 2 &&
          (!missingFields[0] ? (
            <>
              <S.SelectInsuranceContainer>
                <S.SubTitle>
                  <p><b>Valor das Parcelas:</b> R$ {detailsInsurance[1]}</p>
                  <p><b>Total de Parcelas:</b>  {detailsInsurance[0]}</p>
                </S.SubTitle>
              </S.SelectInsuranceContainer>
              <div>Sem campos para preencher</div>
              {permissions.includes("mandatory_signature") && (
                <S.SignatureContainer>
                  <h2>Assine no quadrado abaixo para prosseguir:</h2>
                  <S.SignatureCanvas>
                    <SignatureCanvas
                      penColor='black'
                      backgroundColor='white'
                      canvasProps={{
                        width: 500,
                        height: 200,
                      }}
                      ref={sigCanvas}
                    />
                  </S.SignatureCanvas>
                  {showSaveSuccess && <p>Assinatura salva com sucesso!</p>}
                  <S.SignatureButton>
                    <Button type="button" onClick={handleSignature} >Salvar assinatura</Button>
                    <Button type="button" onClick={clearSignature}>Limpar assinatura</Button>
                  </S.SignatureButton>
                </S.SignatureContainer>
              )}
              {currentBeneficiary === 'holder' ? (
                <S.InsuranceButtonContainer>
                  {loading ? (
                    <Button type="button" onClick={() => handleStep2()}>
                      <SmallLoading />
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="button"
                        id="goBack"
                        onClick={() => {
                          goBack();
                          setIsSignatureCleared(true);
                        }}
                      >
                        Voltar
                      </Button>
                      <Popover
                        content={
                          permissions.includes("mandatory_signature") && (!signatureBase64 || isSignatureCleared)
                            ? "Salve a assinatura antes de contratar"
                            : null
                        }
                        placement="top"
                        visible={permissions.includes("mandatory_signature") && (!signatureBase64 || isSignatureCleared)}
                      >
                        <Button
                          type="button"
                          onClick={() => handleStep2()}
                          disabled={permissions.includes("mandatory_signature") && (!signatureBase64 || isSignatureCleared)}
                        >
                          Contratar Seguro
                        </Button>
                      </Popover>

                    </>
                  )}
                </S.InsuranceButtonContainer>
              ) : (
                <S.InsuranceButtonContainer>
                  <Button
                    type="button"
                    id="goBack"
                    onClick={
                      dependentIndex === 0
                        ? () => {
                          goBack();
                          setDependentsSelected([]);
                        }
                        : () => setDependentIndex(dependentIndex - 1)
                    }
                  >
                    Voltar
                  </Button>
                  <Button type="button" onClick={() => handleStep2Dependent()}>
                    {loading ? (
                      <SmallLoading />
                    ) : dependentIndex + 1 === missingFields.length ? (
                      'Contratar Seguro'
                    ) : (
                      'Próximo'
                    )}
                  </Button>
                </S.InsuranceButtonContainer>
              )}
            </>
          ) : currentBeneficiary === 'holder' ? (
            <>
              <S.SelectInsuranceContainer>
                <S.SubTitle>
                  <p><b>Valor das Parcelas:</b> R$ {detailsInsurance[1]}</p>
                  <p><b>Total de Parcelas:</b>  {detailsInsurance[0]}</p>
                </S.SubTitle>
              </S.SelectInsuranceContainer>

              {missingFields.map((field, index) =>
                field.conteudoPadrao ? (
                  <S.FieldsContainer>
                    <S.SubTitle>{field.descricao}</S.SubTitle>
                    <Select
                      id="field_list"
                      defaultValue="none"
                      key={field.descricao}
                      onChange={(e) => handleFieldChangeSelect(index, e)}
                    >
                      <Option value="none" key="none">
                        Selecione o {field.descricao}
                      </Option>
                      {field.selectFormatted.map((sf) => (
                        <Option value={sf.val2} id={sf.val1} key={sf.val2}>
                          {sf.text}
                        </Option>
                      ))}
                    </Select>
                  </S.FieldsContainer>
                ) : (
                  <S.FieldsContainer>
                    <S.SubTitle>{field.descricao}</S.SubTitle>
                    <Input
                      type={field.tipo === 'number' ? 'number' : 'text'}
                      placeholder={field.descricao}
                      onChange={(e) => handleFieldChange(index, e)}
                    />
                  </S.FieldsContainer>
                )
              )}
              <S.InsuranceButtonContainer>
                {loading ? (
                  <Button type="button" onClick={() => handleStep2()}>
                    <SmallLoading />
                  </Button>
                ) : (
                  <>
                    <Button type="button" id="goBack" onClick={() => goBack()}>
                      Voltar
                    </Button>
                    <Button type="button" onClick={() => handleStep2()}>
                      Contratar Seguro
                    </Button>
                  </>
                )}
              </S.InsuranceButtonContainer>
            </>
          ) : currentBeneficiary === 'holder' ? (
            <>
              {missingFields.map((field, index) =>
                field.conteudoPadrao ? (
                  <S.FieldsContainer>
                    <S.SubTitle>{field.descricao}</S.SubTitle>
                    <Select
                      id="field_list"
                      defaultValue="none"
                      key={field.descricao}
                      onChange={(e) => handleFieldChangeSelect(index, e)}
                    >
                      <Option value="none" key="none">
                        Selecione o {field.descricao}
                      </Option>
                      {field.selectFormatted.map((sf) => (
                        <Option value={sf.val2} id={sf.val1} key={sf.val2}>
                          {sf.text}
                        </Option>
                      ))}
                    </Select>
                  </S.FieldsContainer>
                ) : (
                  <S.FieldsContainer>
                    <S.SubTitle>{field.descricao}</S.SubTitle>
                    <Input
                      type={field.tipo === 'number' ? 'number' : 'text'}
                      placeholder={field.descricao}
                      onChange={(e) => handleFieldChange(index, e)}
                    />
                  </S.FieldsContainer>
                )
              )}
              <S.InsuranceButtonContainer>
                {loading ? (
                  <Button type="button" onClick={() => handleStep2()}>
                    <SmallLoading />
                  </Button>
                ) : (
                  <>
                    <Button type="button" id="goBack" onClick={() => goBack()}>
                      Voltar
                    </Button>
                    <Button type="button" onClick={() => handleStep2()}>
                      Contratar Seguro
                    </Button>
                  </>
                )}
              </S.InsuranceButtonContainer>
            </>
          ) : (
            <>
              <S.SelectInsuranceContainer>
                  <S.SubTitle>
                    <p><b>Valor das Parcelas:</b> R$ {detailsInsurance[1]}</p>
                    <p><b>Total de Parcelas:</b>  {detailsInsurance[0]}</p>
                    <p />
                  </S.SubTitle>
              </S.SelectInsuranceContainer>

              {missingFields[dependentIndex].campos.map((field, index) =>
                field.conteudoPadrao ? (
                  <><S.FieldsContainer>
                      <S.SubTitle>{field.descricao}</S.SubTitle>
                      <Select
                        id="field_list"
                        defaultValue="none"
                        key={field.descricao}
                        onChange={(e) => handleFieldChangeSelect(index, e)}
                      >
                        <Option value="none" key="none">
                          Selecione o {field.descricao}
                        </Option>
                        {field.selectFormatted.map((sf) => (
                          <Option value={sf.val2} id={sf.val1} key={sf.val2}>
                            {sf.text}
                          </Option>
                        ))}
                      </Select>
                    </S.FieldsContainer></>
                ) : (
                  <>
                    <S.FieldsContainer>
                      <S.SubTitle>{field.descricao}</S.SubTitle>
                      <Input
                        type={field.tipo === 'number' ? 'number' : 'text'}
                        placeholder={field.descricao}
                        onChange={(e) => handleFieldChange(index, e)} />
                        <p />
                    </S.FieldsContainer></>
                )
              )}
              <S.InsuranceButtonContainer>
                {!loading && (
                  <Button
                    type="button"
                    id="goBack"
                    onClick={
                      dependentIndex === 0
                        ? () => {
                          goBack();
                          setDependentsSelected([]);
                        }
                        : () => setDependentIndex(dependentIndex - 1)
                    }
                  >
                    Voltar
                  </Button>
                )}
              </S.InsuranceButtonContainer>
              <Button type="button" onClick={() => handleStep2Dependent()}>
                {loading ? (
                  <SmallLoading />
                ) : dependentIndex + 1 === missingFields.length ? (
                  'Contratar Seguro'
                ) : (
                  'Próximo'
                )}
              </Button>
            </>
          ))}

        {step === 3 && (
          <div>
            <S.QuestionContainer>
              Deseja contratar este seguro para os dependentes?
            </S.QuestionContainer>
            <S.InsuranceButtonContainer step3>
              <Button
                type="button"
                id="goBack"
                onClick={() => {
                  initialState();
                  closeModal();
                }}
              >
                Não
              </Button>
              <Button type="button" onClick={() => handleStep3()}>
                Sim
              </Button>
            </S.InsuranceButtonContainer>
          </div>
        )}
      </S.InsuranceContainer>
    </Modal >
  );
}

export default ModalInsurances;
