import styled from 'styled-components';

export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  p {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #272d3b;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ff4d4f;
      margin-right: 5px;
    }
  }
`;

export const InputCardItem = styled.input`
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &.error-id {
    border: 1px solid #ff4d4f;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
`;
