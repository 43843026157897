import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Form } from '@unform/web';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import { useAuth } from '../../../hooks/AuthProvider';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErros';
import { notifyError, notifySucces } from '../../../utils/notifications';
import classes from "../../signin/index.module.scss"
import * as S from './style';

function ResetPasswordModal({ onCancel }) {
  const { user } = useAuth();

  const formRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [meter, setMeter] = React.useState(false);
  const [values, setValues] = useState({
    old_pwd: '',
    new_pwd: '',
    confirm_pwd: '',
  });
  const [isWeakPwd, setIsWeakPwd] = useState(true);
  const [title, setTitle] = useState("Resetar Senha");
  const [forgetModal, setForgetModal] = useState(false);

  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{6,}/g; // eight characters or more

  const formForgetRef = useRef(null);

  const passwordTracker = {
    uppercase: values.new_pwd.match(atLeastOneUppercase),
    lowercase: values.new_pwd.match(atLeastOneLowercase),
    number: values.new_pwd.match(atLeastOneNumeric),
    specialChar: values.new_pwd.match(atLeastOneSpecialChar),
    eightCharsOrGreater: values.new_pwd.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const isPasswordExpired = JSON.parse(localStorage.getItem('@SnxPayment:passwordExpired'));


  const handleRefresh = () => {
    return window.location.reload();
  }

  const updatePermission = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const { user_permission } = await api.get('refresh_permission');

        setTimeout(() => {
          setLoading(false);
          localStorage.setItem('@SnxPayment:passwordExpired', JSON.stringify(false));
          handleRefresh();
          onCancel();
        }, 1000);
      } catch (err) {
        setLoading(false);
        toast.error(err.message, {
          hideProgressBar: true,
        });
      }
    },
    []
  );

  const forgetModalClick = useCallback(() => {
    setForgetModal(true);
  }, []);

  const forgetModalClickBack = useCallback(() => {
    setForgetModal(false);
  }, []);

  const sendRecoveryEmail = useCallback(
    async () => {
      try {
        await api.post('send_recovery_password', {
          recovery_user: user?.login,
        });

        setTimeout(() => {
          setLoading(false);
          setForgetModal(false);
          notifySucces('E-mail de recuperação enviado');
        }, 1000);
      } catch (err) {
        setLoading(false);
        setForgetModal(false);
        notifyError('Erro ao tentar recuperar a senha');
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_pwd: Yup.string().required('Informe a senha atual'),
          new_pwd: Yup.string()
            .required('Informe a nova senha')
            .min(6, 'A senha deve ter no mínimo 6 caracteres'),
          confirm_pwd: Yup.string()
            .required('Informe a senha de confirmação')
            .oneOf([Yup.ref('new_pwd'), null], 'Senhas divergentes!'),
        });

        await schema.validate(data, { abortEarly: false });
        setLoading(true);

        await api.post('confirm_recovery_password_dashboard', {
          old_pwd: data.old_pwd,
          new_pwd: data.new_pwd,
          confirm_pwd: data.confirm_pwd,
        });

        if ( isPasswordExpired.isPwdExpired === true) {
          updatePermission();
        }

        setTimeout(() => {
          setLoading(false);
          toast.success('Senha alterada com sucesso!', {
            hideProgressBar: true,
          });
          onCancel();
        }, 1000);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error(err.message, {
          hideProgressBar: true,
        });
      }
    },
    [navigate]
  );

  const handleChange = useCallback(
    (event) => {
      const { target } = event;
      const { name, value } = target;
      event.persist();
      setValues({ ...values, [name]: value });
      if(passwordStrength === 5){
        setIsWeakPwd(false);
      }else{
        setIsWeakPwd(true);
      }
    },
    [values]
  );

  useEffect(() => {
    if (isPasswordExpired.isPwdExpired === true) setTitle("Atualizar Senha");
    if (values.confirm_pwd.length > 0) {
      formRef.current?.setErrors({});
      if (values.new_pwd !== values.confirm_pwd) {
        formRef.current?.setErrors({
          confirm_pwd: 'Senhas divergentes!',
        });
      }
    }
  }, [values,isPasswordExpired]);

  return (
    <Modal title={title} visible onCancel={onCancel}>
      <S.ContainerCreateEdit>
      {!forgetModal ? (

        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.FormRow>
            <S.FormLabel>
              <p>*</p>
              <span>Senha Atual</span>
            </S.FormLabel>
            <Input
              icon={LockOutlined}
              name="old_pwd"
              placeholder="Senha Atual"
              type="password"
              value={values.old_pwd}
              onChange={handleChange}
            />
          </S.FormRow>
          <S.FormRow>
            <S.FormLabel>
              <p>*</p>
              <span>Nova Senha</span>
            </S.FormLabel>
            <Input
              onFocus={() => setMeter(true)}
              icon={LockOutlined}
              name="new_pwd"
              placeholder="Nova Senha"
              type="password"
              value={values.new_pwd}
              onChange={handleChange}
            />
            {meter && (
              <div>
                <S.StrengthMeter strengthWidth={passwordStrength} />
                <div>
                  {passwordStrength < 5 && 'Deve conter '}
                  {!passwordTracker.uppercase && 'maiúscula, '}
                  {!passwordTracker.lowercase && 'minúscula, '}
                  {!passwordTracker.specialChar && 'caractere especial, '}
                  {!passwordTracker.number && 'número, '}
                  {!passwordTracker.eightCharsOrGreater &&
                    'seis caracteres ou mais'}
                </div>
              </div>
            )}
          </S.FormRow>
          <S.FormRow>
            <S.FormLabel>
              <p>*</p>
              <span>Confirmação da senha</span>
            </S.FormLabel>
            <Input
              icon={LockOutlined}
              name="confirm_pwd"
              placeholder="Confirmação da Senha"
              type="password"
              value={values.confirm_pwd}
              onChange={handleChange}
            />
          </S.FormRow>

          <S.FormRow>
            <Button loading={loading} htmlType="submit" type="primary" disabled={isWeakPwd}>
              Confirmar
            </Button>
            <p />
            <p />
            <S.ForgotPasswordButton onClick={forgetModalClick} type="link">
               Esqueci minha senha
            </S.ForgotPasswordButton>
          </S.FormRow>
        </Form>
        ) : (
          <S.FormRow>
            <b
             style={{  display: "flex",
              justifyContent: "center" }}
            >
              Deseja solicitar o envio de um e-mail de recuperação de senha?</b>
            <p />
            <Button loading={loading} onClick={sendRecoveryEmail} type="primary" >
              Confirmar
            </Button>
            <p />
            <p />
            <S.ForgotPasswordButton style={{marginBottom: -30, marginTop: -10}} onClick={forgetModalClickBack} type="link">
              voltar
            </S.ForgotPasswordButton>
        </S.FormRow>
        )}
      </S.ContainerCreateEdit>
    </Modal>
  );
}

export default memo(ResetPasswordModal);
