import React from 'react';

function PhoneLinkPlus({ ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 0.666687H8C8.73333 0.666687 9.33333 1.26669 9.33333 2.00002V14C9.33333 14.7334 8.73333 15.3334 8 15.3334H1.33333C0.6 15.3334 0 14.7334 0 14V2.00002C0 1.26669 0.6 0.666687 1.33333 0.666687ZM1.33333 13.3334H8V2.66669H1.33333V13.3334Z"
      />
      <path d="M14 5H13V7H11V8H13V10H14V8H16V7H14V5Z" />
    </svg>
  );
}

export default PhoneLinkPlus;
