import styled from 'styled-components';

export const Container = styled.div`
  &.lds-ring {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 8px;
    border: 2px solid white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: white transparent transparent transparent;
  }

  &.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  &.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  &.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
