import React, { memo, useState } from 'react';

import { Button, Modal } from 'antd';

import Loading from '../../../components/Loading';
import Api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';

function ModalCancelSMS({
  open,
  closeModal,
  reloadPacoteSMS,
  activePacote,
  client,
}) {
  const [loading, setLoading] = useState(false);

  const handleCancelPackage = async () => {
    try {
      setLoading(true);
      await Api.delete('client/contrated_sms_pack', {
        sms_pack_id: activePacote.id,
        client_id: client.client.id,
      });
      notifySucces('O serviço de SMS foi cancelado!');
      reloadPacoteSMS();
    } catch (error) {
      console.error('erro', error.message);
      notifyError('Houve um erro ao cancelar o serviço de SMS');
    } finally {
      closeModal();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <Modal visible={open} title="Cancelar Pacote" onCancel={closeModal}>
      <h1
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          fontWeight: 700,
          fontSize: '15px',
        }}
      >
        Deseja cancelar o pacote?
      </h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'space-between',
        }}
      >
        {!loading && (
          <>
            <Button onClick={closeModal}>Cancelar</Button>
            <Button type="primary" onClick={handleCancelPackage}>
              Confirmar
            </Button>
          </>
        )}
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {' '}
            <Loading />{' '}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default memo(ModalCancelSMS);
