function offserInhours() {
  const data = new Date();
  const offsetInMinutes = data.getTimezoneOffset();
  const offsetInHours = -offsetInMinutes / 60;
  return {
    operador: offsetInHours >= 0 ? "+" : "-",
    number: Math.abs(parseInt(offsetInHours, 10)),
  };
}

function convertFuseHours(dateString) {
  const { operador, number } = offserInhours();
  const offsetInHours = operador + number;

  // Extrai a data e hora da string
  const parts = dateString.split(" ");
  const datePart = parts[0];
  const timePart = parts[1];

  // Separa a data em ano, mês e dia
  const [year, month, day] = datePart.split("-");

  // Separa a hora em horas, minutos e segundos
  const [hours, minutes, seconds] = timePart.split(":");

  // pega as horas e utiliza o operador e o numero para converter a data para o fuso horário correto
  const value = 3 + parseInt(offsetInHours, 10);

  const difference = value === 0 ? 3 : value;

  // verifica se o difference é um numero negativo
  const newHours =
    difference < 0
      ? parseInt(hours, 10) - Math.abs(difference) + 3
      : parseInt(hours, 10) + difference;
  const newHoursZ = newHours < 10 ? `0${newHours}` : newHours;

  return `${year}-${month}-${day} ${newHoursZ}:${minutes}:${seconds}`;
}

function convertOnlyDate(dateString) {
  const { operador, number } = offserInhours();
  const offsetInHours = operador + number;

  // Extrai a data e hora da string
  const parts = dateString.split(" ");
  const datePart = parts[0];
  const timePart = parts[1];

  // Separa a data em ano, mês e dia
  const [year, month, day] = datePart.split("-");

  // Separa a hora em horas, minutos e segundos
  const [hours, minutes, seconds] = timePart.split(":");

  // pega as horas e utiliza o operador e o numero para converter a data para o fuso horário correto
  const value = 3 + parseInt(offsetInHours, 10);

  const difference = value === 0 ? 3 : value;

  // verifica se o difference é um numero negativo
  const newHours =
    difference < 0
      ? parseInt(hours, 10) - Math.abs(difference) + 3
      : parseInt(hours, 10) + difference;
  const newHoursZ = newHours < 10 ? `0${newHours}` : newHours;

  return {
    year,
    month,
    day,
    hours: newHoursZ,
    minutes,
    seconds,
  };
}

function convertOnlyHours(dateString) {
  const { operador, number } = offserInhours();
  const offsetInHours = operador + number;

  // Extrai a data e hora da string
  const parts = dateString.split(" ");
  const timePart = parts[1];

  // Separa a hora em horas, minutos e segundos
  const [hours, minutes] = timePart.split(":");

  // pega as horas e utiliza o operador e o numero para converter a data para o fuso horário correto
  const value = 3 + parseInt(offsetInHours, 10);

  const difference = value === 0 ? 3 : value;

  // verifica se o difference é um numero negativo
  const newHours =
    difference < 0
      ? parseInt(hours, 10) - Math.abs(difference) + 3
      : parseInt(hours, 10) + difference;
  const newHoursZ = newHours < 10 ? `0${newHours}` : newHours;

  return `${newHoursZ}:${minutes}`;
}

export { convertFuseHours, convertOnlyHours, convertOnlyDate};
