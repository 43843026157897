import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as AntIcon from "@ant-design/icons";
import { Loading3QuartersOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Spin, Table, Tooltip } from 'antd';
import { FaFileUpload, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Can from "../../components/Can";
import DataTable from "../../components/DataTable";
import * as TableStyle from "../../components/DataTable/style";
import BreadCrumbNavigation from "../../components/DataTable/TopSection/BreadCrumbNavigation";
import { useAuth } from "../../hooks/AuthProvider";
import api from "../../services/api";
import { getPlaces } from "../../utils/getStores";
import { notifyError } from "../../utils/notifications";
import DeleteUser from "../user/modals/DeleteUser";
import ReactivateUser from "../user/modals/ReactivateUser";
import ActionsSection from "./actionsSection";
import FilterByStoreModal from "./FilterByStoreModal";
import RegisterUserLotModal from "./RegisterUserLotModal";
import * as S from "./style";
import ActiveStatus from "./UserStatusComponent/activeStatus";
import InactiveStatus from "./UserStatusComponent/inactiveStatus";

function NewUsers() {
  const [fetchUserLoading, setFetchUserLoading] = useState(false);
  const [fetchStoreLoading, setFetchStoreLoading] = useState(false);
  const [isRegisterUserLotOpen, setIsRegisterUserLotOpen] = useState(false);
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [filterStoreTooltip, setFilterStoreTooltip] = useState("Carregando lojas...");
  const [availableStoresToFilter, setAvailableStoresToFilter] = useState([]);
  const [isFilterByStoreModalOpen, setIsFilterByStoreModalOpen] = useState(false);
  const [filteredStores, setFilteredStores] = useState([]);
  const [auxiliaryUserData, setAuxiliaryUserData] = useState({});
  const [defaultSelectedStores, setDefaultSelectedStores] = useState([]);
  const [modalUserPhone, setModalUserPhone] = useState(false);
  const [modalUserRestore, setModalUserRestore] = useState(false);
  const newUserStores = JSON.parse(localStorage.getItem("@SnxPayment:stores"));

  // checagem de permissão temporária e improvisada
  const canCreateUserLot = user?.type_user === "Elevaty" || user?.type_user === 3;
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState(
    {
      pagination: {},
      filters: {
        "cpf": null,
        "login": null,
        "email": null,
        "storeNames": null,
        "role_name": null,
        "type_user": null,
        "status": null,
        "username": null
        },
      sorter: {},
      stores: null
    }
  );

  const getColumnSearchProps = useCallback(
    (dataIndex, labelName) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Procure pelo ${labelName}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Procurar
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Apagar
            </Button>
          </Space>
        </div>
      )},
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#ff9c00' : undefined }} />
      ),
    }),
    [searchState]
  );

  const sortOptions = {
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
    };

    const filterDataViaFilterObject = useCallback((filters) => {
      const filteredData = data.filter(user => {
        return Object.keys(filters).every(key => {
          const filterValue = filters[key];

          if (filterValue === null) {
            return true;
          }

          if (Array.isArray(filterValue)) {
            return filterValue.some(filterTerm => {
              if (filterTerm === null) {
                return true;
              }

              if (!Array.isArray(user[key])) {
                if(key === "status")
                  return user[key].toLowerCase() === filterTerm.toLowerCase();

                return user[key] && user[key].toLowerCase().includes(filterTerm?.toLowerCase());
              }
              return user[key]?.some(item => item.toLowerCase().includes(filterTerm?.toLowerCase()));
            });
          }

          return true;
        });
      });
      return filteredData;
    }, [data, availableStoresToFilter]);

  const orderBySorterProperties = (dataToSort, sorterProperties) => {
      if(sorterProperties?.column?.dataIndex) {
        const ordenationKey = sorterProperties.column.dataIndex;
        const ordenationDirection = sorterProperties.order === "ascend" ? 1 : -1;
        dataToSort.sort((a, b) => {
        const valorB = b[ordenationKey];
        const valorA = a[ordenationKey];

        if (valorA < valorB) {
          return -1 * ordenationDirection;
        }
        if (valorA > valorB) {
          return 1 * ordenationDirection;
        }
        return 0;
      });
    }
  }

  const getURLParams = (URLParams) => {
    const filters = {}
    const possibleFilters = Object.keys(searchState.filters);
    possibleFilters.forEach(filter => {
        filters[filter] = [];
        let currentFilterValue = URLParams.get(filter);
        if(currentFilterValue?.includes(",")) {
          currentFilterValue = currentFilterValue.split(",");
        }
        if(currentFilterValue !== null) {
          filters[filter] = Array.isArray(currentFilterValue) ? currentFilterValue : [currentFilterValue];
        } else {
          filters[filter] = null;
        }
    });
    return filters;
  }

  const setURLParams = (filters) => {
    const URLParams = new URLSearchParams();
    const filterKeys = Object.keys(filters);
    for(let i=0; i<filterKeys.length; ++i) {
      const filteredValues = filters[filterKeys[i]];
      if(filteredValues !== null) URLParams.set(filterKeys[i], filteredValues);
    }

    const newUrl = `${window.location.pathname}?${URLParams.toString()}`;
    window.history.replaceState({}, '', newUrl)
    return newUrl;
  }

  const manageDataFiltering = async(
      pagination = {},
      filters = {},
      sorter = {},
      stores = null ) =>
    {
      filters.storeNames = stores;
      setSearchState({ pagination, filters, sorter, stores });
      setURLParams(filters);

      const filteredData = filterDataViaFilterObject(filters);
      orderBySorterProperties(filteredData, sorter);
      setFilteredData(filteredData);
  }

  const applyStoreFilter = (filteredStores) => {
    manageDataFiltering(
      searchState.pagination,
      searchState.filters,
      searchState.sorter,
      filteredStores.length > 0 ? filteredStores : null
    );
    /* setDefaultSelectedStores([]); */
    setFilteredStores(filteredStores);
  };

  function cpfInputMask(e) {
      let cpf = e.replace(/\D/g, '');
      cpf = cpf.slice(0, 11);
      cpf = cpf.replace(/^(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
      cpf = cpf.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
      return cpf
  }

  const fetchStores = useCallback(async () => {
    try {
      setFetchStoreLoading(true);
      const stores = await getPlaces(user, true);
      setAvailableStoresToFilter(stores || [])
      setFilterStoreTooltip("Filtrar por lojas")
    } catch(error) {
      const errorMessage = error?.message ? error.message : "Erro ao buscar lojas";
      setFilterStoreTooltip("Lojas não disponíveis")
      notifyError(errorMessage);
    } finally {
      setTimeout(() => {
        setFetchStoreLoading(false);
      }, 500);
    }
  }, [])

  const fromStringOfIdsToArrayOfNumericIds = (storeString) => {
    return (storeString === null || storeString === "" ?
            [] : storeString.replace(",", " ").split(" ").map(Number));
  }

  const getStoreNamesFromStoreIds = (storeIds) => {
    const storesNames = [];
    try {
      for(let i=0; i <= storeIds.length; ++i) {
        const currentId = storeIds[i];
        const filteredStoreById = availableStoresToFilter.find(store => store.id === currentId);
        if(filteredStoreById?.nomeFantasia) {
          const storeName = filteredStoreById.nomeFantasia;
          storesNames.push(storeName);
        }
      }
    } catch(error) {
      throw new Error(error?.message);
    }

    return storesNames;
  }

  const getUserStatus = (user) => {
    return user.deleted_at ? "Inativo" : "Ativo";
  }

  const formatUserRequestResponse = (userData) => {
    const formmatedUserResponse = userData.map( user => {
      user.key = user.id;
      user.status = getUserStatus(user);
      user.storeIds = fromStringOfIdsToArrayOfNumericIds(user.store);
      user.storeNames = getStoreNamesFromStoreIds(user.storeIds);
      return user;
    });
    return formmatedUserResponse;
  }

  const fetchUser = useCallback(async () => {
    setFetchUserLoading(true);
    try {
      const userData = await api.get("user");
      const auxiliaryUserData = formatUserRequestResponse(userData.data);
      setData(auxiliaryUserData);
      if(filteredData.length > 0) {
        return;
      }
      setFilteredData(auxiliaryUserData)
    } catch (err) {
      notifyError("Erro ao listar usuários", err.message);
    } finally {
      setTimeout(() => {
        setFetchUserLoading(false);
      }, 500);
    }
  }, [availableStoresToFilter, filterDataViaFilterObject]);

  const handleUpdateUser = (row, readOnly = false) => {
    let title;
    let redirectURL;
    if(readOnly) {
      title = "Visualizar Usuário"
    } else {
      title = "Editar Usuário"
    }


    const currentFiltersOnURL = new URLSearchParams(window.location.search);
    const filters = getURLParams(currentFiltersOnURL);
    if(filters?.storeNames?.length > 0) {
      redirectURL = setURLParams({ storeNames: filters.storeNames });
    } else {
      redirectURL = "/usuario";
    }

    navigate("/controleUsuario", {
      state: { ...row, readOnly, title, redirectURL },
    });
  };

  const handleDeleteUser = (row) => {
    setAuxiliaryUserData(row);
    setModalUserPhone(true);
  };

  const handleRestoreUser = (row) => {
    setAuxiliaryUserData(row);
    setModalUserRestore(true);
  };

  const handleNewUser = () => {
    navigate("/controleUsuario", {
      state: { newUserStores, title: "Criar Usuário", typeOfUser: user.type_user },
    });
  };

  const columns = useMemo(
    () => [
      {
        title: 'Nome',
        dataIndex: 'username',
        width: 180,
        key: 'username',
        ...sortOptions,
        ...getColumnSearchProps('username', 'Nome'),
      },
      {
        title: 'CPF',
        dataIndex: 'cpf',
        ...sortOptions,
        ...getColumnSearchProps('cpf', 'CPF'),
      },
      {
        title: 'Login',
        dataIndex: 'login',
        ...sortOptions,
        ...getColumnSearchProps('login', 'Login'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...sortOptions,
        ...getColumnSearchProps('email', 'Email'),
      },
      filteredStores.length > 0 ?
      {
        title: "Lojas",
        width: 180,
        dataIndex: "storeNames",
        align: "center",
        render: row => fetchStoreLoading ?
          <Spin size="small" indicator={<Loading3QuartersOutlined spin/>} /> :
          row.join(", ")
      } : null,
      {
        title: 'Grupo',
        dataIndex: 'role_name',
        ...sortOptions,
        ...getColumnSearchProps('role_name', 'Grupo'),
      },
      // eslint-disable-next-line eqeqeq
      ( user?.type_user !== 'Merchant' && user?.type_user != 1 ) ?
      {
        title: 'Tipo usuário',
        dataIndex: 'type_user',
        ...sortOptions,
        ...getColumnSearchProps('type_user', 'Tipo usuário'),
      } : null,

      {
        title: 'Status',
        dataIndex: 'status',
        ...sortOptions,
        filters: [
          {
            text: "Ativo",
            value: "Ativo"
          },
          {
            text: "Inativo",
            value: "Inativo"
          }
        ],
        render: (row) => row === "Ativo" ?
          <ActiveStatus status={row} /> : <InactiveStatus status={row}  />
      },
      {
        title: 'Ações',
        width: 120,
        align: "center",
        dataIndex: '',
        render: (row) => (
          <ActionsSection
            row={row}
            onUpdateUser={handleUpdateUser}
            onRestoreUser={handleRestoreUser}
            onDeleteUser={handleDeleteUser}
          />
        ),
      },
    ].filter(Boolean),
    [fetchUser, getColumnSearchProps, fetchStores, fetchStoreLoading, filteredStores]
  );

  useEffect(async () => {
    fetchStores();
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, [availableStoresToFilter])

  useEffect(() => {
    const currentFiltersOnURL = new URLSearchParams(window.location.search);
    const filters = getURLParams(currentFiltersOnURL);
    if(filters.storeNames) {
      setFilteredStores(filters.storeNames)
      setDefaultSelectedStores(filters.storeNames);
    }
    manageDataFiltering({}, filters, {}, filters.storeNames);
  }, [data, availableStoresToFilter]);

  const getFilteredStoresLength = useCallback(() => {
    return filteredStores.length > 0 && (
        <div className="selected-store-length">
            <p>{filteredStores.length}</p>
        </div>
      );
  }, [defaultSelectedStores, filteredStores]);

  const handleToggleUserStatus = (row) => {
    return row.deleted_at ? (
      <Can permission="reactivate_users">
        <div className="btn-div">
          <AntIcon.SyncOutlined
            title="Ativar"
            onClick={() => handleRestoreUser(row)}
            />
        </div>
      </Can>
    ) : (
      <Can permission="delete_user">
        <div className="btn-div">
          <AntIcon.StopOutlined
            title="Desativar"
            onClick={() => handleDeleteUser(row)}
          />
        </div>
      </Can>
    )
  }

  const handleRegisterUserLot = () => {
    setIsRegisterUserLotOpen(prev => !prev);
  }

  return (
    <S.Container>
      <BreadCrumbNavigation tableName="Usuários"    />
      <TableStyle.Content>
        <div className="content-users__title">
          <div className="content-users__title--left">
            <h2>Usuários</h2>
          </div>
          <div className="content-users__title--right">
            <Tooltip title={filterStoreTooltip}
            >
              <S.FilterByStoreButton
                fetchStoreLoading={fetchStoreLoading}
                availableStoresToFilterLength={availableStoresToFilter.length}
                disabled={fetchStoreLoading || !availableStoresToFilter.length}
                onClick={() => setIsFilterByStoreModalOpen(prevVisibility => !prevVisibility)}>
                {
                  fetchStoreLoading ?
                    (
                      <S.LoadingStoresButton>
                        <p>Carregando lojas </p>
                        <Spin size="small" indicator={<Loading3QuartersOutlined spin/>} />
                      </S.LoadingStoresButton>
                    ) : availableStoresToFilter.length > 0 ?
                    (
                      <>
                        <AntIcon.FilterOutlined style={{ fontSize: "14px"}}/>
                        <p>Filtro por loja</p>
                      </>
                    ) :
                    (
                      <>
                        <AntIcon.FilterOutlined style={{ fontSize: "14px"}}/>
                        <p>Sem lojas disponíveis</p>
                      </>
                    )
                }
                { getFilteredStoresLength() }
              </S.FilterByStoreButton>
            </Tooltip>
            {canCreateUserLot && (
              <S.NewUserButton onClick={handleRegisterUserLot}>
                <FaFileUpload /> Cadastrar em lote
              </S.NewUserButton>
            )}
            <S.NewUserButton onClick={handleNewUser}>
              <FaPlus /> Novo usuário
            </S.NewUserButton>
          </div>
        </div>
        <Table
          loading={fetchUserLoading}
          onChange={(pagination, filters, sorter) => manageDataFiltering(pagination, filters, sorter, filteredStores?.length > 0 ? filteredStores : null )}
          columns={columns}
          dataSource={filteredData}
          scroll={{ x: 768 }}
          rowKey="key"
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [5, 10, 15, 25, 50, 100, 200, 500],
            showSizeChanger: true,
            position: ["bottomRight"],
            style: { marginRight: "20px" },

          }}
        />
      </TableStyle.Content>
      <DeleteUser
        auxUser={auxiliaryUserData}
        loading={fetchUserLoading}
        fetchUser={fetchUser}
        setLoading={setFetchUserLoading}
        modalUserPhone={modalUserPhone}
        setModalUserPhone={setModalUserPhone}
      />

      <ReactivateUser
        auxUser={auxiliaryUserData}
        loading={fetchUserLoading}
        fetchUser={fetchUser}
        setLoading={setFetchUserLoading}
        modalUserRestore={modalUserRestore}
        setModalUserRestore={setModalUserRestore}
      />

      <FilterByStoreModal
        isOpen={isFilterByStoreModalOpen}
        onCancel={ () => setIsFilterByStoreModalOpen(false) }
        availableStores={availableStoresToFilter}
        applyStoreFilter={applyStoreFilter}
        fetchStoreLoading={fetchStoreLoading}
        defaultSelectedStores={defaultSelectedStores}
      />
      <RegisterUserLotModal
        isOpen={isRegisterUserLotOpen}
        onCancel={ () => setIsRegisterUserLotOpen(false) }
      />

    </S.Container>
  );
}

export default NewUsers;
