import React from 'react';

import { Table } from 'antd';

import { formatDate } from '../../../../../../../utils/formatter';
import Section from '../../Section';

function LowerSection({ selectedInstallmentOption }) {
  const columns = [
    {
      title: 'Parcela',
      key: 'posicao',
      render: (row) => (row.posicao ? row.posicao : ''),
    },
    {
      title: 'Faturamento',
      key: 'dataFaturamento',
      render: (row) => (row.dataFaturamento ? formatDate(row?.dataFaturamento) : ''),
    },
    {
      title: 'Principal',
      key: 'principal',
      render: (row) => (row.principal ? `R$ ${row.principal?.toFixed(2)}` : ''),
    },
    {
      title: 'Juros',
      key: 'juros',
      render: (row) => (row.juros ? `R$ ${row.juros?.toFixed(2)}` : ''),
    },
    {
      title: 'IOF',
      key: 'iof',
      render: (row) => (row.iof ? `R$ ${row.iof?.toFixed(2)}` : ''),
    },
    {
      title: 'Valor total',
      key: 'principal',
      render: (row) => (row.valor ? `R$ ${row.valor?.toFixed(2)}` : ''),
    },
  ];

  return (
    <Section title="Resultado da simulação">
      <Table
        columns={columns}
        dataSource={selectedInstallmentOption?.parcelas}
        pagination={false}
        scroll={{ y: 240 }}
        rowKey="quantidadeParcelas"
      />
    </Section>
  );
}

export default React.memo(LowerSection);
