import api from './api';

const endpoint = 'card_printer';

export class printerService {
  static list(storeId, filter) {
    return api
      .get(endpoint, {
        id_loja: storeId,
        filterActive: filter,
      })
      .then((response) => response.data.print);
  }

  static activate(printerId) {
    const url = `${endpoint}/${printerId}/activate`;
    return api.post(url).then((response) => response.data.print);
  }

  static deactivate(printerId) {
    const url = `${endpoint}/${printerId}/deactivate`;
    return api.post(url).then((response) => response.data.print);
  }

  static create(body) {
    const url = `${endpoint}`;
    return api.post(url, body).then((response) => response);
  }
}
