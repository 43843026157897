import React from 'react';

import { WrapperSwitchInput } from './style';
// import "./Switch.css";

function SwitchInput({ isOn, handleToggle, onColor, id }) {
  return (
    <WrapperSwitchInput>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new-${id}`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={`react-switch-new-${id}`}
      >
        <span className="react-switch-button" />
      </label>
    </WrapperSwitchInput>
  );
}

export default SwitchInput;
