import React from 'react';

const FileIcon = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 13C14.464 13 12 15.464 12 18.5C12 21.536 14.464 24 17.5 24C20.536 24 23 21.536 23 18.5C23 15.464 20.536 13 17.5 13ZM17.5 22.9C15.0745 22.9 13.1 20.9255 13.1 18.5C13.1 16.0745 15.0745 14.1 17.5 14.1C19.9255 14.1 21.9 16.0745 21.9 18.5C21.9 20.9255 19.9255 22.9 17.5 22.9ZM16.4 19.6935L20.0245 16.069L20.8 16.85L16.4 21.25L14.2 19.05L14.9755 18.2745L16.4 19.6935Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H13.2572C12.4718 21.0491 12 19.8296 12 18.5C12 18.3315 12.0076 18.1647 12.0224 18H8V16H12.5997C13.0086 15.2002 13.6075 14.5137 14.3369 14H8V12H16V13.207C16.4768 13.0722 16.98 13 17.5 13C18.4002 13 19.2499 13.2163 20 13.5997V8L14 2ZM13 9V3.5L18.5 9H13Z"
      fill={color}
    />
  </svg>
);

export default FileIcon;
