import * as Yup from 'yup';

import { validaCpf, validaRg } from "../utils/validations";
import { ufs } from './ufs';

const ERROR = {
  REQUIRED: 'Campo Obrigatório',
  POSITIVE: 'O valor tem que ser um número positivo',
  EMAIL: 'Deve ser um e-mail válido',
  CNPJ: 'CNPJ Inválido',
  CPF: 'CPF Inválido',
  RG: 'RG Inválido',
  TEL: 'Telefone Inválido',
  CEL: 'Celular Inválido',
  CEP: 'CEP Inválido',
  UF: 'UF Inválido',
  NIVER: 'Data de Nascimento Inválido',
};

export const schemaPrestador = Yup.object().shape({
  nome: Yup.string()
    .required(ERROR.REQUIRED)
    .test("lastName", "Insira pelo menos um sobrenome", name => {
      if (!name) {
        return false;
      }
      const formattedName = name.trim().split(" ");
      return formattedName?.length > 1;
    }),
  dataNascimento: Yup.string()
    .min(10, ERROR.NIVER)
    .required(ERROR.REQUIRED)
    .nullable()
    .test('dataNascimento', 'A data de nascimento é inválida', (value) => {
      if (!value) return false;
      const dateFormated = value.replace(/_/g, "").replace(/\//g, "-");
      if (dateFormated.length < 10) return false;
      const dateParts = dateFormated.split('-');
      // eslint-disable-next-line radix
      if( parseInt(dateParts[1]) > 12 || parseInt(dateParts[0]) > 31) return false
      const formattedDateStr = dateParts.reverse().join('-');
      const birthDate = new Date(formattedDateStr);
      const currentDate = new Date();
      if (birthDate >= currentDate) {
        return false;
      }
      return true;
    }),
  cpf: Yup.string()
    .min(14, ERROR.CPF)
    .required(ERROR.REQUIRED)
    .nullable()
    .test('cpf', 'O CPF é inválido', (value) => {
      if (!value) return false;
      const cpfFormated = value
      .split(".")
      .join("")
      .replace("-", "");
    return validaCpf(cpfFormated);
    }),
  idGrauParentesco: Yup.string()
    .required(ERROR.REQUIRED),
  rg: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable()
    .matches(/^[0-9]+$/, "O campo deve conter apenas números")
    .max(15, "Permitido até 15 caracteres.")
    .min(7, "Mínimo de 7 caracteres.")
    .test('rg', 'O Rg é inválido', (value) => {
      if (!value) return false;
      const rgFormated = value
      .split(".")
      .join("")
      .replace("-", "");
    return validaRg(rgFormated);
    }),
  nomeCartao: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable(),
  nomePersonalizado: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable(),
  tipo: Yup.string()
    .required(ERROR.REQUIRED),
  nomeMae:Yup.string()
    .required(ERROR.REQUIRED)
    .nullable(),
  cep: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable()
    .test('cpf', 'O CEP é inválido', (value) => {
      if (!value) return false;
      if(value.length === 9) return true
    }),
  uf: Yup.string()
    .required(ERROR.REQUIRED)
    .matches(/^[a-zA-Z]+$/, 'A UF deve conter apenas letras')
    .nullable()
    .max(2, 'A UF deve conter 2 dígitos')
    .test('uf', 'A UF é inválida', (value) => {
      if (!value) {
        return false;
      }
      return ufs.includes(value.toUpperCase())
    }),
  localidade: Yup.string()
    .required(ERROR.REQUIRED)
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/,'A Cidade deve conter apenas letras')
    .max(60, 'Máximo de caracteres permitidos')
    .nullable(),
  bairro: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable(),
  numero:Yup.string()
    .required(ERROR.REQUIRED)
    .nullable(),
  logradouro: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable(),
  area: Yup.string()
    .required(ERROR.REQUIRED)
    .nullable()
    .test('area', 'O DDD é inválido', (value) => {
      if (!value) {
        return false;
      }
      const area = value.replace(/\D/g, "");
      let isValid = false
      if (!area) isValid = false;
      if(area.length === 2) {
        isValid = true;
      }
      return isValid;
    }),
  telefone:Yup.string()
  .required(ERROR.REQUIRED)
  .nullable()
  .test('telefone', 'O telefone é inválido', (value) => {
    if (!value) {
      return false;
    }
    const telefone = value.replace(/\D/g, "");
    let isValid = false
    if (!telefone) isValid = false;
    if(telefone.length >= 8) {
      isValid = true;
    }
    return isValid
  }),
  sexo:Yup.string()
  .required(ERROR.REQUIRED)
  .nullable()
});
