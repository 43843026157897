import React, { useEffect, useState } from "react";

import { Modal } from "antd";

import LoadingElevaty from "../../../../components/LoadingElevaty";
import { usePermissions } from "../../../../Context/permissionContext";
import { SendMailForm } from "./SendMailForm";
import { SendSmsForm } from "./SendSmsForm";
import * as Style from "./style";

const SMS_TYPE = "sms";
const MAIL_TYPE = "mail";

const getDefaultTypeFature = ({ isSmsEnabled, isMailEnabled }) => {
  if (isSmsEnabled && isMailEnabled) {
    return MAIL_TYPE;
  }

  if (isSmsEnabled) {
    return SMS_TYPE;
  }

  if (isMailEnabled) {
    return MAIL_TYPE;
  }

  return "";
};

export function ModalSendMailOrSms({
  isSmsEnabled = true,
  isMailEnabled = true,
  title = "Enviar",
  isOpen,
  onClose,
  isLoading = false,
  phones = [],
  clientMail = "",
  onSubmitSms,
  onSubmitMail,
}) {
  const [typeFature, setTypeFature] = useState(() =>
    getDefaultTypeFature({ isMailEnabled, isSmsEnabled })
  );
  if (!isSmsEnabled && !isMailEnabled) {
    return null;
  }

  const { permissions } = usePermissions();

  const hasPermissionToSendToUnregistered =
    permissions && permissions.includes("sending_unregistered_contacts");

  const userCanChoose = isSmsEnabled && isMailEnabled;

  const handleClose = () => {
    const defaultTypeFature = getDefaultTypeFature({
      isMailEnabled,
      isSmsEnabled,
    });

    setTypeFature(defaultTypeFature);

    onClose();
  };

  const handleSubmitSms = (values) => {
    const formattedPhone = values.sendToUnregisteredPhone
      ? values.customPhone
      : values.phone;
    onSubmitSms?.(formattedPhone);
  };

  const handleSubmitMail = (values) => {
    const formattedMail = values.sendToUnregisteredEmail
      ? values.customEmail
      : values.email;
    onSubmitMail?.(formattedMail);
  };

  useEffect(() => {
    const defaultTypeFature = getDefaultTypeFature({
      isMailEnabled,
      isSmsEnabled,
    });
    setTypeFature(defaultTypeFature);
  }, [isSmsEnabled, isMailEnabled]);

  return (
    <Modal title={title} visible={isOpen} onCancel={handleClose} width={572}>
      <Style.ModalFaturaSend>
        {isLoading ? (
          <div className="modal-fatura__loading">
            <LoadingElevaty />
          </div>
        ) : (
          <>
            {userCanChoose && (
              <div className="item-select__user">
                <p className="title">Cliente:</p>

                <div className="item-select__options">
                  <Style.DocButton
                    selected={typeFature === MAIL_TYPE}
                    onClick={() => {
                      setTypeFature(MAIL_TYPE);
                    }}
                  >
                    <p>E-mail</p>
                  </Style.DocButton>
                  <Style.DocButton
                    selected={typeFature === SMS_TYPE}
                    onClick={() => {
                      setTypeFature(SMS_TYPE);
                    }}
                  >
                    <p>SMS</p>
                  </Style.DocButton>
                </div>
              </div>
            )}
            {isMailEnabled && typeFature === MAIL_TYPE && (
              <SendMailForm
                clientMail={clientMail}
                onSubmit={handleSubmitMail}
                hasPermissionToSendToUnregistered={
                  hasPermissionToSendToUnregistered
                }
              />
            )}
            {isSmsEnabled && typeFature === SMS_TYPE && (
              <SendSmsForm
                phones={phones}
                onSubmit={handleSubmitSms}
                hasPermissionToSendToUnregistered={
                  hasPermissionToSendToUnregistered
                }
              />
            )}
          </>
        )}
      </Style.ModalFaturaSend>
    </Modal>
  );
}
