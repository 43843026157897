import React, { useCallback, useEffect, useState } from "react";

import { Table } from "antd";
import moment from "moment";

import { useAuth } from "../../../hooks/AuthProvider";
import { getFormatDateAndTimeNoTz } from "../../../utils/formatDate";
import * as S from "../style";

function UsersTable( data) {
  const formatDate = (dateString) => {
    const formattedDate = moment(dateString).format('DD/MM/YYYY');
    if (formattedDate === 'Invalid date') {
      return 'Data inválida';
    }
    return formattedDate;
  };

  const statusTable = (status) => {
    // Possíveis status:
    // -  AJUSTE="A";
    // -  FATURA="F";
    // -  VENDA="V";
    // -  VENDABANDEIRA="B";
    // -  SAQUEBANDEIRA="Q";
    // -  RENEGOCIACAO="R";
    // -  JUROSSOBREPARCELA="E";
    // -  JUROSSOBREPARCELABANDEIRA="D";
    // -  JUROSIOFSOBREPARCELABANDEIRA="I";
    // -  IOFSOBREPARCELABANDEIRA="K";
    // -  JUROSPARCELAREFINANCIAMENTOFATURA="Z";

    switch (status) {
      case 'A':
        return 'Ajuste';
      case 'F':
        return 'Fatura';
      case 'V':
        return 'Venda';
      case 'B':
        return 'Venda Bandeirada';
      case 'Q':
        return 'Saque Bandeira';
      case 'R':
        return 'Renegociação';
      case 'E':
        return 'Juros Sobre Parcela';
      case 'D':
        return 'Juros Sobre Parcela Bandeira';
      case 'I':
        return 'Juros IOF Sobre Parcela Bandeira';
      case 'K':
        return 'IOF Sobre Parcela Bandeira';
      case 'Z':
        return 'Juros Parcela e Financiamento Fatura';
      default:
        return '';
    }
  };

  const columns = [
    {
      title: "Data Lançamento",
      dataIndex: "dataLancamento",
      render: (valor) => {
        return formatDate(valor);
      },
    },
    {
      title: "Data Faturamento",
      dataIndex: "dataFaturamento",
      render: (valor) => {
        return formatDate(valor);
      },
    },
    {
      title: "Tipo lançamento",
      dataIndex: "tipo",
      render: (status) => {
        return statusTable(status);
      }
    },
    {
      title: "Valor",
      dataIndex: "valor",
      render: (valor) => (
        <>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valor)}
        </>
      ),
    },
  ];

  return (
    <S.TableContainer>
      <Table
        className="tableListsadjustment not-global"
        columns={columns}
        // eslint-disable-next-line react/destructuring-assignment
        dataSource={data?.data?.reverse()}
        pagination={{ pageSize: 5 }}
      />
    </S.TableContainer>
  );
}

export default React.memo(UsersTable);
