import React, { memo, useCallback } from 'react';

import { List } from 'antd';
import moment from 'moment';
import { FixedSizeList } from 'react-window';

import * as S from '../style';

function RenderItemMonth({ month, height }) {
  const formatDate = useCallback((date) => moment(date).format('DD/MM'), []);

  const Row = useCallback(({ index, style, data }) => {
    return (
      <div style={style}>
        <S.PurchasesTimelineItem>
          <S.PurchasesTimelineRow>
            <span className="date">
              {moment(data[index].dataLancamento).format('DD/MM/YYYY HH:mm:ss')}
            </span>
          </S.PurchasesTimelineRow>
          <S.PurchasesTimelineRow>
            <span className="place">{data[index].descricaoLancamento}</span>
            <span className="value">R$ {data[index].valor}</span>
          </S.PurchasesTimelineRow>
        </S.PurchasesTimelineItem>
      </div>
    );
  }, []);

  return (
    <List.Item>
      <S.ListItemMeta
        title={`Valor: R$ ${month.valor} | Vencimento: ${formatDate(
          month.dataVencimento
        )}`}
        description={`Corte: ${formatDate(month.dataCorte)}`}
      />
      <S.StyledH1>Lançamentos:</S.StyledH1>
      <FixedSizeList
        height={height}
        itemCount={month.lancamentos?.length}
        itemSize={80}
        itemData={month.lancamentos}
      >
        {Row}
      </FixedSizeList>
    </List.Item>
  );
}

export default memo(RenderItemMonth);
