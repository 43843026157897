import React, { useEffect, useState } from "react";

import { LinkOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaUser } from "react-icons/fa";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";
import { MdKeyboardArrowRight, MdOutlineFlag } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { useClient } from "../../hooks/ClientProvider";
import SolicitationActionButtonV2 from "../../pages/solicitation/SolicitationActionButtonV2";
import { SolicitationService } from "../../services/solicitationService";
import { formatCPF, formatDate, formatTime } from "../../utils/formatter";
import LoadingElevaty from "../LoadingElevaty";
import Priority from "../Priority";
import { STATUSES, TRANSLATE_STATUS } from "../Status";
import * as S from "./style";

function ModalRequestHome({
  dataModalRequest,
  onClose,
  handleSubmitFormModal,
  fetchSolicitations,
}) {
  const { client } = useClient();
  const [attachments, setAttachments] = useState([]);

  const [infosSolicitationModal, setInfosSolicitationModal] =
    useState(dataModalRequest);

  const [showComment, setShowComment] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);

  const [charCount, setCharCount] = useState(0);

  const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .max(90, "O comentário deve ter no máximo 90 caracteres")
      .required("Comentário é obrigatório"),
  });

  const formatLastChange = (statuses) => {
    const priority = {
      RESOLVED: 1,
      PENDING: 2,
      ACTIVE: 3,
    };

    statuses.sort((a, b) => priority[a.status] - priority[b.status]);
    return formatDate(statuses[0].created_at);
  };

  const getLastSolicitationUpdate = (solicitation) => {
    return solicitation?.statuses
      ? formatLastChange(solicitation.statuses)
      : "-";
  };

  const handleRefreshModal = async () => {
    setLoadingComment(true);
    setCharCount(0);
    setShowComment(false);
    try {
      const response = await SolicitationService.refresh(
        infosSolicitationModal.id
      );

      setInfosSolicitationModal(response.data[0]);
      toast.success("Comentário adicionado com sucesso");
    } catch (error) {
      toast.error("Ocorreu um erro ao atualizar os comentários");
    } finally {
      setLoadingComment(false);
    }
  };

  const handleCommentEvent = async (value) => {
    const { comment } = value;

    try {
      await SolicitationService.addComment(infosSolicitationModal.id, {
        description: comment,
      });

      handleRefreshModal();
    } catch (error) {
      toast.error("Ocorreu um erro ao adicionar o comentário");
    }
  };

  useEffect(() => {
    if (infosSolicitationModal?.id) {
      SolicitationService.attachments(infosSolicitationModal.id).then(
        (response) => {
          setAttachments(response.data);
        }
      );
    }
  }, [infosSolicitationModal]);

  const reversedStatuses = infosSolicitationModal?.statuses.slice().reverse();

  return (
    <Modal
      visible
      width="100%"
      style={{
        maxWidth: "1093px",
      }}
      onCancel={onClose}
    >
      <S.WrapperModalRequestHome>
        <div className="modal-request-home__header">
          <p>
            {infosSolicitationModal?.id
              ? `Solicitação #${infosSolicitationModal?.id} |`
              : ""}{" "}
            {infosSolicitationModal?.ticket_protocol
              ? `Prot. #${infosSolicitationModal?.ticket_protocol} `
              : `Prot. #${client?.client?.ticket?.protocol} `}
          </p>
          {infosSolicitationModal?.status ? (
            <span
              className={TRANSLATE_STATUS[infosSolicitationModal?.status].label}
            >
              {TRANSLATE_STATUS[infosSolicitationModal?.status].label}
            </span>
          ) : null}
        </div>

        <div className="modal-request-home__left">
          <div className="modal-request-home__title">
            <div className="modal-request-home__title--left">
              {infosSolicitationModal?.user ? (
                <>
                  <p>Usuário Solicitante:</p>
                  <p style={{ marginBottom: "8px" }}>
                    <strong>{infosSolicitationModal?.user}</strong>
                  </p>
                </>
              ) : null}

              {infosSolicitationModal?.attendant ? (
                <>
                  <p>Usuário Atendimento:</p>
                  <p style={{ marginBottom: "8px" }}>
                    <strong>{infosSolicitationModal?.attendant}</strong>
                  </p>
                </>
              ) : null}

              <p>Motivo da solicitação:</p>
              <p>
                <strong>{infosSolicitationModal?.type?.name}</strong>
              </p>

              <ul>
                <li>
                  <p>
                    Última atualização:{" "}
                    {getLastSolicitationUpdate(infosSolicitationModal)}
                  </p>
                </li>
              </ul>

              {attachments.length > 0 && (
                <div>
                  <S.ContentInfoLabel>Arquivos</S.ContentInfoLabel>
                  <div>
                    {attachments.map(({ file_name, url, user }) => (
                      <S.AttachmentRow>
                        <S.AttachmentIcon>
                          <LinkOutlined />
                        </S.AttachmentIcon>
                        <Tooltip
                          placement="top"
                          title={`Usuário: ${user.username}`}
                        >
                          <a href={url} target="_blank">
                            {file_name}
                          </a>
                        </Tooltip>
                      </S.AttachmentRow>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="modal-request-home__title--right">
              <span>
                <Priority priority={infosSolicitationModal?.priority} />
              </span>
            </div>
          </div>

          <div className="user-info__search">
            <div className="user-info__search-item">
              <div className="user-info__search-item__header">
                <FaUser />
                <p>
                  {infosSolicitationModal?.client_name || client?.client?.name}
                </p>

                <div className="tags">
                  <p>#{infosSolicitationModal?.client_product_id}</p>
                </div>
              </div>

              <div className="user-info__search-infos">
                <div className="item-user-info">
                  <p>
                    <span>CPF</span>:{" "}
                    {formatCPF(
                      infosSolicitationModal?.client_cpf || client?.client?.cpf
                    )}
                  </p>

                  <button
                    type="button"
                    onClick={() =>
                      handleSubmitFormModal(infosSolicitationModal?.client_cpf)
                    }
                  >
                    <MdKeyboardArrowRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-request-home__right">
          <div className="modal-request-home__events">
            <div className="modal-request-home__events--title">
              <p>Eventos:</p>
            </div>

            <div className="modal-request-home__events--content">
              {loadingComment ? (
                <div className="loading-events">
                  <LoadingElevaty />
                </div>
              ) : (
                <>
                  <div className="list-events">
                    {reversedStatuses?.map((event) => (
                      <div
                        className="modal-request-home__events--content__item"
                        key={event.id}
                      >
                        <div className="event-icon">
                          <MdOutlineFlag />
                        </div>

                        <div className="event-content">
                          <div className="event-content__left">
                            <p>
                              <strong>{event.description}</strong>
                            </p>
                            {/* <p>{event.comment}</p> */}
                          </div>

                          <div className="event-content__right">
                            <p>
                              <span>{formatDate(event.created_at)}</span>
                              <span>{formatTime(event.created_at)}</span>
                              {event.username && <span>{event.username}</span>}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {showComment ? (
                    <div className="comments-area">
                      <Formik
                        initialValues={{ comment: "" }}
                        validationSchema={validationSchema}
                        onSubmit={handleCommentEvent}
                      >
                        {({ handleChange, resetForm }) => (
                          <Form>
                            <div className="relative-comment">
                              <div className="header-comment">
                                <label htmlFor="comment">Comentário:</label>
                                <span>{charCount}/90 caracteres</span>
                              </div>
                              <Field name="comment">
                                {({ field, meta }) => (
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    maxLength="90"
                                    className={
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    }
                                    {...field}
                                    onChange={(e) => {
                                      setCharCount(e.target.value.length);
                                      handleChange(e);
                                    }}
                                  />
                                )}
                              </Field>

                              <ErrorMessage
                                name="comment"
                                component="div"
                                className="comment-error"
                              />
                            </div>
                            <div className="btns-comment">
                              <button
                                type="button"
                                onClick={() => {
                                  resetForm();
                                  setCharCount(0);
                                  setShowComment(false);
                                }}
                              >
                                Cancelar
                              </button>
                              <button type="submit">Enviar</button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    <div className="button-comments">
                      <button
                        type="button"
                        onClick={() => setShowComment(true)}
                      >
                        Adicionar Comentário
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="modal-request-home__footer">
          <SolicitationActionButtonV2
            // viewOnly
            solicitation={infosSolicitationModal}
            onFinish={fetchSolicitations}
            closeModal={onClose}
          />
          {/* {dataModalRequest?.status === STATUSES.PENDING ? (
              <button type="button">
                <IoIosCheckmark />
                Iniciar Atendimento
              </button>
            ) : null} */}
        </div>
      </S.WrapperModalRequestHome>
    </Modal>
  );
}

export default ModalRequestHome;
