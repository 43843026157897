import React from 'react';

import { Container } from './style';

function Loading() {
  return (
    <Container className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
}

export default Loading;
