/* eslint-disable no-useless-concat */
import {
  convertFuseHours,
  convertOnlyHours,
  convertOnlyDate
} from "./convertFusoHours";
import { getFormatDateTime } from "./formatDate";
import { obterNomeDoMes } from "./obterNomeDoMes";

function getDate(date) {
  return new Date(date).toLocaleDateString("pt-BR");
}

function getUser(item) {
  return item.user ? `${item.user.username}` : "-";
}

function getObs(item) {
  return item?.obs;
}

function getChangeIn(item) {
  return item?.change_in;
}

function getChangeTo(item) {
  return item?.change_to;
}

function getDesc(item) {
  return item?.desc;
}

function getTimelineUserDescription(item) {
  const date = convertFuseHours(item?.created_at);
  return `[ ${getUser(item)} ] ${getFormatDateTime(date)}  `;
}
function getNewTimeLineV2Hours(item) {
  const date = convertOnlyHours(item?.created_at);
  return getFormatDateTime(date);
}


function getNewTimeLineV2Date(item) {
  const {month, day} = convertOnlyDate(item?.created_at);

  return `${obterNomeDoMes(month)} ${day}`;
}

function formatDateString(inputString) {
  const dateObject = new Date(inputString);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getSeconds().toString().padStart(2, '0');

  const formattedString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedString;
}

function getOccurencesDate(item) {
  const dateFormated = formatDateString(item)
  const {month, day} = convertOnlyDate(dateFormated);

  return `${obterNomeDoMes(month)} ${day}`;
}

function getOccurencesTime(item) {
  const dateFormated = formatDateString(item)
  const date = convertOnlyHours(dateFormated);
  return getFormatDateTime(date);
}

function getNewTimeLineV2FullDateAndTime(item) {
  const {month, day, year, hours, minutes, seconds} = convertOnlyDate(item?.created_at);

  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
}

function getTimelineDescription(item) {
  return `${getDesc(item)}`;
}

export {
  getTimelineUserDescription,
  getTimelineDescription,
  getObs,
  getChangeIn,
  getChangeTo,
  getUser,
  getDate,
  getDesc,
  getNewTimeLineV2Hours,
  getNewTimeLineV2Date,
  getNewTimeLineV2FullDateAndTime,
  getOccurencesDate,
  getOccurencesTime
};
