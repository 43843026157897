import styled, { css } from "styled-components";

export const WrapperCharge = styled.div`
  width: 100%;
  margin-top: 24px;

  border-radius: 8px;
  border: 1px solid var(--neutral-color-gray-5);
  background: #fff;
  padding: 24px;

  button {
    &.charge-btn-unlock {
      margin-top: 16px;
      width: 100%;
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 2px;
      border: 1px solid var(--brand-color-gold-6);
      background: var(--brand-color-gold-6);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

      &.standard {
        border: 1px solid var(--neutral-color-gray-6, #bfbfbf);
        background: var(--neutral-color-gray-1, #fff);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

        p,svg{
          color: #272D3B;
        }
      }

      p {
        padding: 0;
        margin: 0;

        color: var(--neutral-color-gray-9);
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 19px;
          margin-left: 8px;
        }
      }

      &:disabled {
        border: 1px solid var(--neutral-5, #d9d9d9);
        background: var(--neutral-3, #f5f5f5);

        p,
        svg {
          color: #bfbfbf;
        }
      }
    }
  }

  .charge-ticket {
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 16px;

    .charge-ticket__title {
      width: 100%;
      margin-bottom: 12px;

      p {
        color: var(--neutral-color-dark-10);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        padding: 0;
        margin: 0;

        svg {
          font-size: 18px;
        }
      }
    }

    .charge-ticket__btns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 16px;

      button {
        display: flex;
        padding: 5px 24px;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;

        border-radius: 2px;
        border: 1px solid var(--brand-color-gold-6);
        background: var(--brand-color-gold-6);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

        &.other {
          border: 1px solid var(--neutral-color-gray-6);
          background: var(--neutral-color-gray-1);

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        svg {
          margin-right: 8px;
          font-size: 18px;
        }

        p {
          color: var(--neutral-color-dark-10);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .charge-title {
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 16px;

    &.alert {
      svg {
        color: var(--status-color-error);
      }

      p {
        color: var(--status-color-error);
      }
    }

    &.success {
      p,
      svg {
        color: var(--status-color-success);
      }
    }

    &.warning {
      p,
      svg {
        color: var(--status-color-warning);
      }
    }

    svg {
      font-size: 18px;
    }

    p {
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      padding: 0;
      margin: 0;
    }
  }

  .charge-countdown {
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 16px;

    &.alert {
      strong {
        color: var(--status-color-error);
      }
    }

    p {
      padding: 0;
      margin: 0;

      color: var(--neutral-color-gray-9);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      strong {
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-top: 4px;
      }
    }
  }

  .charge-list {
    width: 100%;

    p {
      text-align: center;
      color: var(--gray-600);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 0;
      margin: 0;
      margin-bottom: 4px;

      strong {
        color: var(--gray-900);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  ${(props) =>
    props.grid === "grid-items-2" &&
    css`
      @media (min-width: 768px) {
        grid-template-columns: 324px 1fr 206px;

        .charge-list {
          p {
            width: 100%;
          }
        }
      }
    `}

  ${(props) =>
    props.grid === "grid-items-3" &&
    css`
      @media (min-width: 768px) {
        grid-template-columns: 324px 211px 1fr 206px;

        .charge-list {
          p {
            width: 49%;
          }
        }
      }
    `}

  @media (min-width: 768px) {
    display: grid;

    /* grid-template-columns: 324px 211px 1fr 206px; */

    .charge-title {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
      border-right: 1px solid var(--gray-200);
      padding-right: 32px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        max-width: 200px;
      }
    }

    .charge-ticket {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
      border-right: 1px solid var(--gray-200);
      padding-right: 32px;
    }

    .charge-countdown {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
      border-right: 1px solid var(--gray-200);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    .charge-list {
      border-right: 1px solid var(--gray-200);
      padding-left: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      p {
        /* width: 49%; */
        text-align: left;
        margin-bottom: 12px;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .charge-last {
      padding-left: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    button.charge-btn-unlock {
      margin-top: 0;
      height: 32px;
    }
  }
`;