import React, { useCallback, useEffect, useState } from "react";

import { HomeFilled } from "@ant-design/icons";
import { Button, Select, Switch } from 'antd';
import { Formik, Field, Form, ErrorMessage } from "formik";
import ReactInputMask from "react-input-mask";
import * as Yup from "yup";

import AntInput from '../../components/AntInput';
import Card from "../../components/Card";
import BreadCrumbNavigation from "../../components/DataTable/TopSection/BreadCrumbNavigation";
import Loading from "../../components/Loading";
import api from "../../services/api";
import { notifyError, notifySucces } from "../../utils/notifications";
import { validaCpf } from "../../utils/validations";
import * as S from './styles';

const { Option } = Select;

function LaunchAdjustment() {

  const [formSteps, setFormSteps] = useState(0);
  const [currentDoc, setCurrentDoc] = useState("cpf");
  const [currentMask, setCurrentMask] = useState("999.999.999-99");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [clientId, setClientId] = useState(0);
  const [listClientAccount, setListClientAccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [typesAdjustments, setTypesAdjustments] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [billingDate, setBillingDate] = useState(null);
  const [valueAdjustment, setValueAdjustment] = useState('');
  const [activeInvoice, setActiveInvoice] = useState(false);


  const handleTab = useCallback((doc) => {
    if (doc === "cpf") setCurrentMask("999.999.999-99");
    if (doc === "cnpj") setCurrentMask("99.999.999/9999-99");
    if (doc === "cartao") setCurrentMask("9999 9999 9999 9999 999");
    if (doc === "client_id") setCurrentMask("99999999999999999");
    setCurrentDoc(doc);
  }, []);

  const handleCPF = useCallback(() => {
    handleTab("cpf");
  }, [handleTab]);

  const handleClientId = useCallback(() => {
    handleTab("client_id");
  }, [handleTab]);

  function setPlaceholder() {
    let text = "";
    if (currentDoc === "cpf") text = "do CPF";
    if (currentDoc === "cnpj") text = "do CNPJ";
    if (currentDoc === "cartao") text = "do Cartão";
    if (currentDoc === "client_id") text = "do ID do Cliente";

    return `Digite o número ${text}`;
  }

  const handleAccountSelected = useCallback(async (value) => {
    await setClientId(value);
    await setSelectedAccount(value);
  }, []);

  const handleResetSearch = async () => {
    await setFormSteps(0);
    await setClientId(null);
    await setSelectedType(null);
    await setBillingDate(null);
    await setValueAdjustment('');
    await setActiveInvoice(false);
  }

  const handleStepOne = async (values) => {
    try {
      setLoadingBtn(true);

      const response = await api.get('getAccount', {
        dataDocument: values.doc.replace(/[./-]/g, "").replace(/\s/g, ""),
        document: currentDoc
      });

      if (!Array.isArray(response.data)) {
        response.data = response.data ? [response.data] : [];
      }

      if (!response.data || (Array.isArray(response.data) && response.data.length === 0)) {
        throw new Error("Cliente não encontrado");
      }

      const uniqueAccountSelectList = response.data.map((item) => ({
      id: item.id,
      accountSelect: item.accountSelect
    }));
    setListClientAccount(uniqueAccountSelectList);
    if(uniqueAccountSelectList.length === 1) {
      handleAccountSelected(uniqueAccountSelectList[0].id);
    }


    setTimeout(() => {
      setLoadingBtn(false);
      setFormSteps(1);
    }, 1000);
    } catch (error) {
      setLoadingBtn(false);
      notifyError(error.message);
    }

  };

  const getData = useCallback(async () => {
    try {
      setLoadingTypes(true);
      const { data } = await api.get('get_types_adjustments_launch');
      const types = data.filter((item) => item.ativo !== 'N');
      await setTypesAdjustments(types);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoadingTypes(false);
    }
  }, []);

  useEffect(async () => {
    getData();
  }, [getData]);


  const handlebillingDate = useCallback(async (event) => {
    const { value } = event.target;
    setBillingDate(value);
  }, []);

  const handleValue = useCallback(async (event) => {
    const { value } = event.target;

    if (value === 'R$ ') return setValueAdjustment('');
    // eslint-disable-next-line valid-typeof
    if (value === typeof 'NAN') return setValueAdjustment('0');
    const number = parseInt(value.replace(/[\D]+/g, ''), 10);

    const formatReal = (int) => {
      let valueString = `${int}`;
      valueString = valueString.replace(/([0-9]{2})$/g, ',$1');
      if (valueString.length > 6)
        valueString = valueString.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

      return valueString;
    };

    const money = `R$ ${formatReal(number)}`;

    setValueAdjustment(money);
  }, []);

  const handleActiveInvoice = useCallback(async (event) => {
    setActiveInvoice(event);
  }, []);

  const handleTypeSelected = useCallback(async (value) => {
    await setSelectedType(value);
  }, []);

  const handleSaveAdjustment = useCallback(async () => {
    if (billingDate == null)
      return notifyError('Informe a data de faturamento');

    if (selectedType == null) return notifyError('Informe o tipo de ajuste');
    if (selectedAccount == null || selectedAccount === '0') return notifyError('Informe a conta do portador');


    if (valueAdjustment == null || valueAdjustment === '')
      return notifyError('Informe o valor do ajuste');
    if (valueAdjustment.match(/\d+/g) == null)
      return notifyError('Informe o valor do ajuste');

      const billingDateFormated = billingDate.split('/').reverse().join('-');
      try {
        setLoading(true);
        const numericValue = parseInt(valueAdjustment.replace(/[\D]+/g, ''), 10);
        const value = Number((numericValue / 100).toFixed(2));

        await api.post('launch_adjustment', {
          idSettingLaunchType: `${selectedType}`,
          clientId,
          value,
          billingdate: billingDateFormated,
          activeInvoice,
        });
      notifySucces('Ajuste lançado com sucesso');
    } catch (error) {
      notifyError('Erro ao selecionar produto', error.message);
    } finally {
      setLoading(false);
    }
  });


  return (
      <S.Container>
          <S.UserContainer>
          <BreadCrumbNavigation tableName="Lançar ajuste"  preTableName='Vendas e Pagamentos'   />
          {formSteps > 0 && (
            <div className="select-btn" >
              <Button
               style={{ marginLeft: 22, marginBottom:15 }}
               onClick={() => handleResetSearch()} type="ghost">
                Nova Pesquisa
              </Button>
            </div>
          )}
            <Card title="Lançar ajuste" loading={loading}>
              <S.ContainerEditLaunchParameters>
              {formSteps === 0 && (
                <Formik
                  initialValues={{ doc: "" }}
                  validationSchema={Yup.object({
                    doc: Yup.string()
                      .required(`${currentDoc.toUpperCase()} obrigatório`)
                      .test(
                        "test_cpf",
                        `Informe um ${currentDoc} válido`,
                        (value) => {
                          if (currentDoc === "client_id") return true;
                          if (currentDoc === "cpf") {
                            if (value === undefined || value === null)
                              return false;

                            const cpfFormated = value
                              .split(".")
                              .join("")
                              .replace("-", "");
                            return validaCpf(cpfFormated);
                          }
                          if (currentDoc === 'cartao') {
                            const cardFormated = value ? value
                              .replace(/[./-]/g, '')
                              .replace(/\s/g, '') : '';
                            let cardSize = 16;
                            if( cardFormated.length === 19) cardSize = 19;

                            return cardFormated.length === cardSize;
                          }
                        }
                      ),
                  })}
                  onSubmit={handleStepOne}
                >
                  {() => (
                    <Form>
                      <div className="box-form">
                        <S.FormRow>
                          <div>
                            <b>Tipo:</b>
                          </div>
                          <S.CpfCnpj>
                            <S.DocButton
                              selected={currentDoc === "cpf"}
                              onClick={handleCPF}
                            >
                              CPF
                            </S.DocButton>
                            <S.DocButton
                              selected={currentDoc === "client_id"}
                              onClick={handleClientId}
                            >
                              ID Cliente
                            </S.DocButton>
                          </S.CpfCnpj>

                          <Field name="doc">
                            {({ field, meta }) => (
                              <ReactInputMask
                                placeholder={setPlaceholder()}
                                type="text"
                                mask={currentMask}
                                maskChar={null}
                                autoComplete="off"
                                className={
                                  meta.touched && meta.error ? "error-id" : null
                                }
                                {...field}
                              />
                            )}
                          </Field>

                          <ErrorMessage
                            name="doc"
                            className="error"
                            component="div"
                          />
                        </S.FormRow>

                        <S.FormRow>
                          <Button
                            loading={loadingBtn}
                            htmlType="submit"
                            type="primary"
                          >
                            Buscar
                          </Button>
                        </S.FormRow>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {formSteps === 1 && (
                <S.WrapperSelectBlock>
                {!loadingTypes && (
                  <>
                    <span>
                      <red>* </red> Conta:
                    </span>
                    <S.ModalRow>
                      {
                        listClientAccount.length > 1 ?
                          (
                            <Select
                              placeholder="Selecionar conta"
                              onChange={handleAccountSelected}
                            >
                              <Option value="0">Selecionar</Option>
                              {listClientAccount.map((item) => (
                                <Option value={item.id} key={item.id}>
                                   {item.accountSelect}
                                </Option>
                              ))}
                            </Select>
                          ) :
                          (
                            <Select disabled value={listClientAccount[0].accountSelect}/>
                          )
                      }
                    </S.ModalRow>
                    <span />
                    <span />
                    <span>
                      <red>* </red> Tipo de ajuste:
                    </span>
                    <S.ModalRow>
                      <Select
                        label="Fatura atual (R$)"
                        className="text"
                        defaultValue={selectedType}
                        placeholder="Selecionar"
                        showSearch
                        optionFilterProp="children"
                        notFoundContent="Nenhum ajuste encontrado para esta busca!"
                        filterOption={(input, option) =>
                          String(option.children).toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={handleTypeSelected}
                      >
                        <Option value="0">Selecionar</Option>
                        {typesAdjustments.map((types) => (
                          <Option value={types.id} key={types.id}>
                            {types.descricao[0] +
                            types.descricao.slice(1).toLowerCase()} -   {types.creditoDebito === 'D' ? 'Débito' : 'Crédito'}
                          </Option>
                        ))}
                      </Select>
                    </S.ModalRow>
                    <S.ModalRow>
                      <AntInput
                        addonBefore="Data do faturamento"
                        placeholder="dd/mm/yyyy"
                        id="dataFaturamento"
                        onChange={handlebillingDate}
                        type="date"
                      />
                    </S.ModalRow>
                    <S.ModalRow>
                      <AntInput
                        addonBefore="Valor"
                        placeholder="Valor(R$)"
                        id="valor"
                        onChange={handleValue}
                        value={valueAdjustment}
                      />
                    </S.ModalRow>
                    <S.CheckboxContainer>
                      <Switch
                        onColor="#FF9C00"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={handleActiveInvoice}
                      />
                      <span>Incidir na fatura ativa</span>
                    </S.CheckboxContainer>
                    {!loading && (
                      <>
                        <S.ButtonContainer>
                          <Button
                            className="button-confirm"
                            onClick={handleSaveAdjustment}
                          >
                            Salvar
                          </Button>
                        </S.ButtonContainer>
                      </>
                    )}
                    {loading && <Loading />}
                  </>
                )}
                {loadingTypes && (
                  <>
                  <Loading />
                  <p />
                  <p />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  Carregando lista de ajustes
                  </div>
                  </>
                ) }
              </S.WrapperSelectBlock>
              )}
              </S.ContainerEditLaunchParameters>
            </Card>
          </S.UserContainer>
      </S.Container>

  );
}
export default React.memo(LaunchAdjustment);
