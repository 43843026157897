import React, { memo, useCallback, useEffect, useState } from 'react';

import { Button, Input, Modal, Select } from 'antd';

import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import Loading from '../../Loading';
import * as S from '../style';

const { TextArea } = Input;
const { Option } = Select;

function CancelModal({
  isModalVisible,
  closeModal,
  clientID,
  productID,
  card,
  cancelCard,
  reloadCards,
  reloadCardsHistory,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [typesCancel, setTypesCancel] = useState([]);
  const [observacoes, setObservacoes] = useState('');
  const [selectedType, setSelectedType] = useState(null);

  const getData = useCallback(async () => {
    try {
      setLoadingTypes(true);
      const { data } = await api.get('card_cancelation_type');
      setTypesCancel(data);
    } catch (error) {
      console.error('Falha ao listar tipos de cancelamento');
    } finally {
      setLoadingTypes(false);
    }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  const handleChangeObservacoes = useCallback(async (event) => {
    const { value } = event.target;
    await setObservacoes(value);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await api.patch('card_cancel', {
        card_id: card.id,
        cod_product: productID,
        client_id: clientID,
        cancelation_type_id: selectedType,
        observation: observacoes,
        masked_number: card.numeroMascarado,
      });
      cancelCard(card);
      closeModal();
      notifySucces('Cancelado com sucesso');
    } catch (error) {
      let msgError = 'Falha ao Cancelar';
      if (!observacoes) {
        msgError = 'Digite uma Observação de Cancelamento';
      }
      notifyError(msgError);
    } finally {
      setLoading(false);
      reloadCards();
      reloadCardsHistory();
    }
  }, [
    cancelCard,
    card,
    clientID,
    closeModal,
    observacoes,
    productID,
    selectedType,
  ]);

  return (
    <Modal title="Cancelar" visible={isModalVisible} onCancel={closeModal}>
      <S.WrapperSelectBlock>
        {!loadingTypes && (
          <>
            <span>Tipo de cancelamento</span>
            <Select defaultValue={selectedType} onChange={setSelectedType}>
              <Option value="0">Selecione</Option>
              {typesCancel.map((cancelType) => (
                <Option value={cancelType.id} key={cancelType.id}>
                  {cancelType.descricao}
                </Option>
              ))}
            </Select>
          </>
        )}
        {loadingTypes && <Loading />}
      </S.WrapperSelectBlock>
      <S.WrapperSelectBlock>
        <span>Mensagem</span>
        <TextArea rows={4} onChange={handleChangeObservacoes} />
      </S.WrapperSelectBlock>
      <S.ModalRow>
        {!loading && (
          <>
            <Button onClick={handleDelete}>Confirmar</Button>
            <Button onClick={closeModal}>Cancelar</Button>
          </>
        )}
        {loading && <Loading />}
      </S.ModalRow>
    </Modal>
  );
}

export default memo(CancelModal);
