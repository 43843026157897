import styled, { css } from "styled-components";

export const WrapperLastOrder = styled.div`
  width: 100%;
  margin-top: 1.4rem;

  .infos-boleto {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    padding: 1rem;

    p {
      padding: 0;
      margin: 0;
    }

    .infos-boleto__left,
    .infos-boleot__right {
      width: 100%;
    }

    .infos-boleto__left {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      svg {
        font-size: 24px;
        margin-right: 12px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
      }
    }

    .infos-boleto__right {
      p {
        font-size: 14px;
        font-weight: 500;
        strong {
          font-weight: 800;
          font-size: 24px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .infos-boleto {
      align-items: center;
      height: 82px;
      .infos-boleto__left {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
    .infos-boleto__right {
      width: 50%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 0;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
