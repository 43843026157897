import React, { memo, useCallback, useEffect, useState, forwardRef, Ref, useImperativeHandle } from 'react';

import { List, Modal } from 'antd';
import { format } from 'date-fns';

import { useClient } from '../../../hooks/ClientProvider';
import Api from '../../../services/api';
import { formatDateTime } from '../../../utils/formatter';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const HistoryLimitModal = forwardRef(({ isModalVisible, closeModal }, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [today, setToday] = useState(new Date());

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { client } = useClient();
  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const dataCadastro = await Api.get('clientId', {
        client_id: client.client.id,
      });

      const d = new Date();
      d.setFullYear(d.getFullYear() - 1);
      d.setDate(d.getDate() + 1);
      const dateConvert = format(new Date(d), 'yyyy-MM-dd');

      const { data } = await Api.get('card_limit_history', {
        client_id: client.client.id,
        date1: dateConvert,
        date2: format(today, 'yyyy-MM-dd'),
      });
      const ajusteArray = data.listaHistoricoAlteracaoLimite
        .sort(
          (a, b) =>
            new Date(b.dataProcessamento) - new Date(a.dataProcessamento)
        )
        .splice(0, 5);
      await setItems(
        ajusteArray.slice(0, 3).map((item) => ({
          ...item,
          limiteAplicadoAnterior: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.limiteAplicadoAnterior),
          limiteAplicadoNovo: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(item.limiteAplicadoNovo),
        }))
      );
    } catch (error) {
      setLoading(false);
      console.error('Falha ao listar histórico de limites');
    } finally {
      setLoading(false);
    }
  }, [client.client.id]);

  useImperativeHandle(ref, () => ({
    getData,
  }))

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal
      title="Histórico de Limites"
      visible={isModalVisible}
      onCancel={closeModal}
    >
      <S.WrapperSelectBlock>
        <h2>Histórico de Alteração</h2>
      </S.WrapperSelectBlock>
      {loading && (
        <S.WrapperNewLoading>
          <LoadingElevaty width="30px" height="30px" duration="durFast" />
        </S.WrapperNewLoading>
      )}
      {!loading && (
        <S.WrapperSelectBlock>
          <List
            bordered
            dataSource={items}
            renderItem={(item) => (
              <List.Item>
                <S.ListDate>
                  <span>Data de Alteração:</span>
                  {item.dataProcessamento && (
                    <b>{formatDateTime(new Date(item.dataProcessamento))}</b>
                  )}
                </S.ListDate>
                <S.ListDate>
                  <span>Limite Anterior:</span>
                  {item.limiteAplicadoAnterior && (
                    <b>{item.limiteAplicadoAnterior}</b>
                  )}
                </S.ListDate>
                <S.TypeBlock>
                  <span>Limite Novo:</span>
                  {item.limiteAplicadoNovo && <b>{item.limiteAplicadoNovo}</b>}
                </S.TypeBlock>
              </List.Item>
            )}
          />
        </S.WrapperSelectBlock>
      )}
    </Modal>
  );
});
export default memo(HistoryLimitModal);
