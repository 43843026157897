import React, { memo, useState, useEffect } from 'react';

import { Modal } from 'antd';
import moment from 'moment';

import { useClient } from '../../../hooks/ClientProvider';
import Api from '../../../services/api';
import { cardProcessorService } from '../../../services/cardProcessorService';
import { getFormatDateReverse } from '../../../utils/formatDate';
import * as S from '../style';

function InformationCardModal({ isModalVisible, closeModal, card }) {
  const [updateClient, setUpdateClient] = useState('');
  const [devedorTotal, setDevedorTotal] = useState('');
  const [dataEmissao, setDataEmissao] = useState('');
  const [clientExtractData, setClientExtractData] = useState({});

  const { client } = useClient();

  async function getData() {
    await Api.get('client_information', { client_id: client.client.id })
      .then(async (response) => {
        await setUpdateClient(response.data);
      })
      .catch((erro) => {
        console.error(erro?.message);
      });

    try {
      const clientData = await cardProcessorService.getClientId(client?.client.id);
      setClientExtractData(clientData.extratoClienteResponse);
      await setDevedorTotal(clientData.extratoClienteResponse.devedorTotal);
    } catch (e) {
      // no need to show the user the response error
      console.error('Error trying to get additional client info');
    }
  }

  useEffect(() => {
    if (client) getData();
    if (card && card.dataEmissao) {
      const date = moment.parseZone(
        card.dataEmissao.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')
      );

      setDataEmissao(date.format('DD/MM/YYYY'));
    }
  }, [client, isModalVisible]);

  if (!client) {
    return null;
  }

  return (
    <Modal title="Informações" visible={isModalVisible} onCancel={closeModal}>
      <S.InformationCardContainer>
        <S.CreditCardRow>
          <span>Número:</span>
          <span>{card.numeroMascarado}</span>
        </S.CreditCardRow>
        {dataEmissao && (
          <S.CreditCardRow>
            <span>Data de Emissão:</span>
            <span>{dataEmissao}</span>
          </S.CreditCardRow>
        )}
        <S.CreditCardRow>
          <span>Nome:</span>
          <span>{card.nome}</span>
        </S.CreditCardRow>
        {card.bandeira && (
          <S.CreditCardRow>
            <span>Bandeira:</span>
            <span>{card.bandeira}</span>
          </S.CreditCardRow>
        )}
        {card.funcaoCartao && (
          <S.CreditCardRow>
            <span>Tipo de cartão:</span>
            <span>{card.funcaoCartao}</span>
          </S.CreditCardRow>
        )}
        <S.CreditCardRow>
          <span>Dia do vencimento da fatura:</span>
          <b>{client.information.diaVencimento}</b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Melhor dia para compra:</span>
          <b>{client.information.melhorDiaCompra}</b>
        </S.CreditCardRow>

        {clientExtractData.limite >= 0 && clientExtractData.valorLimiteExtra >= 0 && (
          <S.CreditCardRow>
            <span>Limite total:</span>
            <b>
              R$
              {Number(clientExtractData.limite + clientExtractData.valorLimiteExtra).toFixed(2)}
            </b>
            </S.CreditCardRow>
        )}

        {clientExtractData.limite >= 0 && (
          <S.CreditCardRow>
            <span>Limite praticado:</span>
            <b>
              R$
              {Number(clientExtractData.limite).toFixed(2)}
            </b>
            </S.CreditCardRow>
        )}

        {clientExtractData.valorLimiteExtra >= 0 && clientExtractData.limite >= 0 && (
          <S.CreditCardRow>
            <span>Limite extra (%):</span>
            <b>
              {Number(clientExtractData.valorLimiteExtra / clientExtractData.limite * 100).toFixed(2)}%
            </b>
          </S.CreditCardRow>
        )}

        <S.CreditCardRow>
          <span>Limite disponível:</span>
          <b>
            R$
            {Number(updateClient.saldoDisponivel).toFixed(2)}
          </b>
        </S.CreditCardRow>

        <S.CreditCardRow>
          <span>Devedor Total:</span>
          <b>
            R$
            {Number(devedorTotal).toFixed(2)}
          </b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Limite saque:</span>
          <b>
            R$
            {Number(client.information.limiteSaque).toFixed(2)}
          </b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Saldo disponível saque:</span>
          <b>
            R$
            {Number(client.information.saldoDisponivelSaque).toFixed(2)}
          </b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Data do fechamento da próxima fatura:</span>
          <b>
            {getFormatDateReverse(client.information.dataFechamentoProxFatura)}
          </b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Disponível Rotativo:</span>
          <b>R${Number(updateClient.saldoDisponivelRotativo).toFixed(2)}</b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Disponível para parcelamento:</span>
          <b>R${Number(updateClient.saldoDisponivelParcelado).toFixed(2)}</b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Devedor Rotativo:</span>
          <b>R${Number(updateClient.devedorRotativo).toFixed(2)}</b>
        </S.CreditCardRow>
        <S.CreditCardRow>
          <span>Devedor para parcelamento:</span>
          <b>R${Number(updateClient.devedorParcelado).toFixed(2)}</b>
        </S.CreditCardRow>
      </S.InformationCardContainer>
    </Modal>
  );
}

export default memo(InformationCardModal);
