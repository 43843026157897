import React, { memo } from 'react';

import { Card, Modal, Table } from 'antd';

import * as S from './styles';

const Columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
  },
  {
    title: 'Descrição',
    dataIndex: 'descricao',
    key: 'descricao',
  },
];

const ColumnsService = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Contratado',
    dataIndex: 'contratado',
    key: 'contratado',
    render: (value) => <span>{value ? 'Sim' : 'Não'}</span>,
  },
  {
    title: 'Descrição',
    dataIndex: 'descricao',
    key: 'descricao',
  },
  {
    title: 'Valor da Tarifa',
    dataIndex: 'valorTarifa',
    key: 'valorTarifa',
    render: (value) => <span>R$ {String(value).replace('.', ',')}</span>,
  },
];

function ModalDetailsSMS({ open, closeModal, activePacote }) {
  return (
    <Modal
      title="Detalhes do pacote"
      visible={open}
      width={1000}
      onCancel={closeModal}
    >
      <S.ModalContainer>
        {activePacote && (
          <>
            <Card title="Detalhes">
              <Table columns={ColumnsService} dataSource={[activePacote]} />
            </Card>
            <Card title="Serviços Inclusos" style={{ marginTop: '10px' }}>
              <Table
                columns={Columns}
                dataSource={activePacote.servicosInclusos}
              />
            </Card>{' '}
          </>
        )}
      </S.ModalContainer>
    </Modal>
  );
}

export default memo(ModalDetailsSMS);
