import React from 'react';

import Modal from 'react-modal';

import ContentModalActiveFaturaDigitalSms from './ContentModalActiveFaturaDigitalSms';

function ModalActiveFaturaDigitalSms({ statusOpen, closeModal }) {
  return (
    <Modal
      isOpen={statusOpen}
      onRequestClose={closeModal}
      overlayClassName="react-modal-overlay-situacao"
      className="react-modal-situacao"
    >
      <ContentModalActiveFaturaDigitalSms closeModal={closeModal} />
    </Modal>
  );
}

export default ModalActiveFaturaDigitalSms;
