import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  ConfigProvider,
  DatePicker,
  Input,
  Pagination,
  Select,
  Tag,
} from "antd";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { groupBy } from "lodash";
import moment from "moment";
import {
  FaFilter,
  FaCaretDown,
  FaFlagCheckered,
  FaFlag,
  FaHandPaper,
  FaRegEye,
  FaRegCalendarAlt,
  FaArrowCircleLeft,
} from "react-icons/fa";
import { MdOutlineChat } from "react-icons/md";
import { RiTimerLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import AnimatedError from "../../../components/AnimatedError";
import Can from "../../../components/Can";
import LoadingElevaty from "../../../components/LoadingElevaty";
import { useClient } from "../../../hooks/ClientProvider";
import api from "../../../services/api";
import { SolicitationService } from "../../../services/timelineService";
import { getFormatCalendar } from "../../../utils/formatDate";
import {
  getChangeIn,
  getChangeTo,
  getNewTimeLineV2Date,
  getNewTimeLineV2FullDateAndTime,
  getNewTimeLineV2Hours,
  getObs,
  getOccurencesDate,
  getOccurencesTime,
  getTimelineDescription,
  getTimelineUserDescription,
} from "../../../utils/getTimeline";
import { notifyError } from "../../../utils/notifications";
import * as Style from "./style";

const { RangePicker } = DatePicker;

const { Option } = Select;

export function NewTimelineV2() {
  const [lastYear, setLastYear] = useState(() => {
    const FinishDate = new Date();
    FinishDate.setMonth(FinishDate.getMonth() + 1);
    FinishDate.setDate(0);
    FinishDate.setMonth(FinishDate.getMonth() - 6);
    return FinishDate;
  });
  const [today, setToday] = useState(() => {
    const InitialDate = new Date();
    InitialDate.setMonth(InitialDate.getMonth() + 1);
    InitialDate.setDate(0);
    return InitialDate;
  });
  const [filterRangeActive, setFilterRangeActive] = useState("all");

  const [protocolFilterType, setProtocolFilterType] = useState("protocol");
  const [value, setValue] = useState();

  const { client } = useClient();

  const [timelineHistory, setTimelineHistory] = useState({});
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(null);
  const [protocol, setProtocol] = useState(null);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const [itemSelected, setItemSelected] = useState(null);
  const [occurrence, setOccurrence] = useState(null);

  const [loading, setLoading] = useState(false);

  const [endDate, setEndDate] = useState(() => {
    const FinishDate = new Date();
    FinishDate.setMonth(FinishDate.getMonth() + 1);
    FinishDate.setDate(0);
    FinishDate.setMonth(FinishDate.getMonth() - 6);
    return FinishDate;
  });
  const [startDate, setStartDate] = useState(() => {
    const InitialDate = new Date();
    InitialDate.setMonth(InitialDate.getMonth() + 1);
    InitialDate.setDate(0);
    return InitialDate;
  });

  const changeRouteClick = useCallback((route) => navigate(route), [navigate]);

  const callEndDate = () => {
    const FinishDate = new Date();
    FinishDate.setMonth(FinishDate.getMonth() + 1);
    FinishDate.setDate(0);
    FinishDate.setMonth(FinishDate.getMonth() - 6);
    setEndDate(FinishDate);
  };

  const callInitDate = () => {
    const InitialDate = new Date();
    InitialDate.setMonth(InitialDate.getMonth() + 1);
    InitialDate.setDate(0);
    setStartDate(InitialDate);
  };

  const callEndDateOccurence = () => {
    const FinishDate = getFormatCalendar(new Date());
    setEndDate(FinishDate);
  };

  const callInitDateOccurence = () => {
    let InitialDate = new Date();

    InitialDate.setMonth(InitialDate.getMonth() - 2);
    InitialDate = getFormatCalendar(InitialDate);

    setStartDate(InitialDate);
  };

  const fetchOccurrences = useCallback(
    async (InitialDate, FinishDate, desc, protocol) => {
      setItemSelected(null);

      try {
        setLoading(true);

        const response = await api.get("occurrence_service", {
          client_id: client.client.id,
          dateStart: InitialDate,
          dateEnd: FinishDate,
          desc,
          protocol,
        });

        if (!response?.data?.content[0]) {
          notifyError("Nenhum registro encontrado");
          return;
        }

        setOccurrence(response?.data?.content[0]);

        setTimelineHistory(response);
      } catch (err) {
        notifyError(
          "Erro",
          "Período entre datas informadas superior a 90 dias."
        );
        console.error("Erro ao listar as ocorrências", err.message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const onChangeTodayRangeDate = useCallback(
    (date) => {
      if (filterRangeActive === "ocorrencias") {
        setStartDate(date[0].utc().format("YYYY-MM-DD"));
        setEndDate(date[1].utc().format("YYYY-MM-DD"));
      } else {
        setEndDate(
          moment(date[0].utc().format("YYYY-MM-DD HH:mm:ss")).toDate()
        );
        setStartDate(
          moment(date[1].utc().format("YYYY-MM-DD HH:mm:ss")).toDate()
        );
      }
    },
    [filterRangeActive]
  );

  const fetchItems = useCallback(
    async (startDate, endDate, page, limit, protocol, desc) => {
      setLoading(true);
      try {
        const response = await SolicitationService.list({
          processorClientId: client.client.id,
          protocol,
          desc,
          page,
          limit,
          startDate,
          endDate,
        });

        setTimelineHistory(response);
      } catch (e) {
        notifyError("Falha ao buscar timeline de atendimento do cliente");
      } finally {
        setLoading(false);
      }
    },
    [startDate, endDate]
  );

  const groupedByTicketProtocol = useMemo(
    () => groupBy(timelineHistory.data, "ticket.protocol"),
    [timelineHistory]
  );

  const keys = useMemo(() => {
    if (groupedByTicketProtocol) {
      const List = Object.values(groupedByTicketProtocol)
        .sort((a, b) => {
          const createdAtA = new Date(a[0]?.created_at);
          const createdAtB = new Date(b[0]?.created_at);
          return createdAtB - createdAtA;
        })
        .map((item) => item[0]?.ticket?.protocol);

      setItemSelected({
        id: List[0],
        infos: groupedByTicketProtocol[List[0]],
      });

      return List;
    }
  }, [groupedByTicketProtocol]);

  const onSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      const handlers = {
        protocol: setProtocol,
        desc: setDesc,
      };

      const setter = handlers[protocolFilterType];

      setter(value);
      setPage(1);
      setValue(null);
    },
    [value]
  );

  const getInfosTables = useCallback(() => {
    setTimelineHistory({});
    if (filterRangeActive === "ocorrencias") {
      fetchOccurrences(startDate, endDate, desc, protocol);
    } else {
      fetchItems(startDate, endDate, page, limit, protocol, desc);
    }
  }, [filterRangeActive, startDate, endDate, page, limit, protocol, desc]);

  useEffect(() => {
    getInfosTables();
  }, [
    client,
    page,
    limit,
    protocol,
    desc,
    startDate,
    endDate,
    filterRangeActive,
  ]);

  useEffect(() => {
    callInitDate();
    callEndDate();
  }, []);


const contentTimeline = useCallback(() => {
  if (itemSelected && itemSelected.infos && itemSelected.infos.length > 0) {
    const createdAtFirst = new Date(itemSelected.infos[itemSelected.infos.length - 1].created_at);
    const createdAtLast = new Date(itemSelected.infos[0].created_at);
    const timeDifferenceMilliseconds = createdAtLast - createdAtFirst;

    const totalSeconds = Math.floor(timeDifferenceMilliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 3600));

    const remainingSecondsAfterDays = totalSeconds % (24 * 3600);
    const hours = Math.floor(remainingSecondsAfterDays / 3600);

    const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);

    const seconds = remainingSecondsAfterHours % 60;

    let message = "";
    if (days > 0) {
      message += `${days} dia${days > 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      message += `${hours} hora${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      message += `${minutes} minuto${minutes > 1 ? "s" : ""} `;
    }
    if (seconds > 0) {
      message += `${seconds} segundo${seconds > 1 ? "s" : ""}`;
    }
    if (!message) {
      message = "Em atendimento";
    }

     return (
        <>
          {message ? (
            <div className="timeline__call-duration">
              <div className="timeline__call-duration--title">
                <p>Duração do chamado</p>
              </div>
              <div className="timeline__call-duration--icons">
                <div className="info-icon">
                  <RiTimerLine />
                </div>
                <p> {message}</p>
              </div>
            </div>
          ) : null}

          <div className="timeline-time">
            {itemSelected.infos.length > 1 ? (
              <>
                {itemSelected.infos[itemSelected.infos.length - 1] ? (
                  <p>
                    <FaFlag /> Inicio às{" "}
                    {getNewTimeLineV2Hours(
                      itemSelected.infos[itemSelected.infos.length - 1]
                    )}
                  </p>
                ) : null}

                {itemSelected.infos[0] ? (
                  <p>
                    <FaFlagCheckered /> Termino às{" "}
                    {getNewTimeLineV2Hours(itemSelected.infos[0])}
                  </p>
                ) : null}
              </>
            ) : (
              <>
                {itemSelected.infos[0] ? (
                  <p>
                    <FaFlag /> Inicio às{" "}
                    {getNewTimeLineV2Hours(itemSelected.infos[0])}
                  </p>
                ) : null}
              </>
            )}
          </div>

          {itemSelected.infos &&
            itemSelected.infos.map((item) => (
              <>
                {item.desc ? (
                  <div className="generated-requests">
                    <div className="generated-requests__header">
                      <div className="generated-requests--title">
                        <p>{getTimelineDescription(item)}</p>
                      </div>
                      <div className="generated-requests--date">
                        <p>
                          <FaRegCalendarAlt />{" "}
                          {getNewTimeLineV2FullDateAndTime(item)}
                        </p>
                      </div>
                    </div>

                    <div className="generated-requests--content">
                      <div className="gerenated-requests--content__header">
                        <div className="gerenated-requests--content__header--icon">
                          <FaHandPaper />
                        </div>
                        <div className="gerenated-requests--content__header--text">
                          <p>{item.desc}</p>
                        </div>
                      </div>
                      {/* <div className="gerenated-requests--content__footer">
                          <div className="gerenated-requests--content__footer--status">
                            <span>Resolvido</span>
                          </div>
                          <div className="gerenated-requests--content__footer--action">
                            <button type="button">
                              <FaRegEye />
                            </button>
                          </div>
                        </div> */}
                    </div>
                  </div>
                ) : null}
                {item.change_in && item.change_to ? (
                  <div className="actions">
                    <div className="actions__header">
                      <div className="actions__header--title">
                        <p>{getTimelineDescription(item)}</p>
                      </div>
                      <div className="actions__header--date">
                        <p>
                          <FaRegCalendarAlt />{" "}
                          {getNewTimeLineV2FullDateAndTime(item)}
                        </p>
                      </div>
                    </div>

                    <div className="actions__content">
                      <div className="actions__content--action">
                        <p>
                          <FaArrowCircleLeft /> {getChangeIn(item)}
                        </p>
                      </div>
                      <div className="actions__content--action reverse">
                        <p>
                          <FaArrowCircleLeft /> {getChangeTo(item)}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {item.obs ? (
                  <div className="comments">
                    <div className="comments__header">
                      <div className="comments__header--title">
                        <p>Comentário</p>
                      </div>
                      <div className="comments__header--date">
                        <p>
                          <FaRegCalendarAlt />{" "}
                          {getNewTimeLineV2FullDateAndTime(item)}
                        </p>
                      </div>
                    </div>

                    <div className="comments__content">
                      <div className="comments__content--icon">
                        <MdOutlineChat />
                      </div>
                      <div className="comments__content--message">
                        <p>{getObs(item)}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
        </>
      );
    }
    return null;
  }, [itemSelected]);

  const contentOcurrence = useCallback(() => {
    if (occurrence) {
      return (
        <>
          <div className="generated-requests">
            <div className="generated-requests--content">
              <div className="gerenated-requests--content__header">
                <div className="gerenated-requests--content__header--icon">
                  <FaHandPaper />
                </div>
                <div className="gerenated-requests--content__header--text">
                  <p>{occurrence?.descricaoOcorrencia}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }, [occurrence]);

  const contentChangeViewTable = useCallback(() => {
    return (
      <Style.ActionsBTNSTimelineHistory>
        <div className="actions-grid">
          <button
            type="button"
            className={filterRangeActive === "all" ? "active" : ""}
            onClick={() => {
              setValue(null);
              setProtocol(null);
              setDesc(null);
              setPage(1);
              setProtocolFilterType("protocol");
              setFilterRangeActive("all");
              callInitDate();
              callEndDate();
            }}
          >
            Tudo
          </button>
          <button
            type="button"
            className={filterRangeActive === "ocorrencias" ? "active" : ""}
            onClick={() => {
              setValue(null);
              setProtocol(null);
              setDesc(null);
              setPage(1);
              setProtocolFilterType("protocol");
              setFilterRangeActive("ocorrencias");
              callInitDateOccurence();
              callEndDateOccurence();
            }}
          >
            Ocorrências
          </button>
        </div>
      </Style.ActionsBTNSTimelineHistory>
    );
  }, [filterRangeActive]);

  const FilterDate = useCallback(() => {
    if (startDate && endDate) {
      return (
        <ConfigProvider locale={datePickerLocale}>
          <RangePicker
            value={[moment(startDate), moment(endDate)]}
            format="DD/MM/YYYY"
            onChange={onChangeTodayRangeDate}
          />
        </ConfigProvider>
      );
    }
    return null;
  }, [startDate, endDate]);

  const onPaginationChangeHandler = useCallback((nextPage, pageSize) => {
    setPage(nextPage);
    setLimit(pageSize);
  }, []);

  return (
    <Can permission="view_timeline">
    <Style.ContainerTimeLineV2>
      <div className="timeline-v2__header">
        <div className="timeline-v2__header--left">
          <h2>Histórico de atendimento</h2>
        </div>
        <div className="timeline-v2__header--right">
          <div className="timeline-v2__header--range">{FilterDate()}</div>

          <div className="timeline-v2__header--filter">
            <span>
              <Select
                value={protocolFilterType}
                className="select-filter"
                onChange={setProtocolFilterType}
              >
                <Option value="protocol">Protocolo</Option>
                <Option value="desc">Descrição</Option>
              </Select>
              <Input value={value} onChange={(e) => setValue(e.target.value)} />
            </span>
            <button
              type="submit"
              onClick={onSubmitHandler}
              disabled={value === null}
            >
              <FaFilter /> Filtrar
            </button>
          </div>
        </div>
      </div>

      <div className="timeline-v2__content">
        <Style.CardTimeLineHistory>
          {loading ? (
            <Style.LoadingTimeliveV2>
              <LoadingElevaty />
            </Style.LoadingTimeliveV2>
          ) : (
            <>
              <div className="timeline-v2__content--filters">
                {desc && (
                  <Tag
                    color="blue"
                    closable
                    onClose={() => {
                      setValue(null);
                      setDesc(null);
                      setPage(1);
                    }}
                  >
                    Descrição: {desc}
                  </Tag>
                )}
                {protocol && (
                  <Tag
                    color="green"
                    closable
                    onClose={() => {
                      setValue(null);
                      setProtocol(null);
                      setPage(1);
                    }}
                  >
                    Protocolo: {protocol}
                  </Tag>
                )}
              </div>
              {keys?.length > 0 ? (
                <>
                  <div className="timeline-v2__content__left">
                    {contentChangeViewTable()}

                    {filterRangeActive === "all" ? (
                      <>
                        {timelineHistory &&
                          keys?.map((key) => (
                            <>
                              <div
                                className={`card-timeline-history__item ${
                                  itemSelected?.id === key ? "active" : ""
                                }`}
                                onClick={() => {
                                  setItemSelected({
                                    id: key,
                                    infos: groupedByTicketProtocol[key],
                                  });
                                }}
                              >
                                <div className="card-timeline-history__date">
                                  {groupedByTicketProtocol[key][0] ? (
                                    <>
                                      <p>
                                        {getNewTimeLineV2Date(
                                          groupedByTicketProtocol[key][0]
                                        )}
                                      </p>
                                      <p>
                                        {getNewTimeLineV2Hours(
                                          groupedByTicketProtocol[key][0]
                                        )}{" "}
                                        h
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                                <div className="card-timeline-history__title">
                                  <div className="card-timeline-history__title--left">
                                    <div className="title">
                                      <p>
                                        {groupedByTicketProtocol[key][0]?.desc}
                                      </p>
                                    </div>
                                    <div className="protol">
                                      <p>Prot. #{key}</p>
                                    </div>

                                    <div className="user">
                                      <p>
                                        {groupedByTicketProtocol[key][0]?.user
                                          ?.username
                                          ? `${groupedByTicketProtocol[key][0]?.user?.username}`
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="card-timeline-history__title--right">
                                    <div className="arrow">
                                      <FaCaretDown />
                                    </div>
                                  </div>
                                </div>
                                <div className="card-timeline-history__content mobile">
                                  {contentTimeline()}
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {timelineHistory && timelineHistory?.data?.content && (
                          <>
                            {timelineHistory?.data?.content.map((key) => (
                              <>
                                <div
                                  className={`card-timeline-history__item ${
                                    occurrence?.idOcorrencia ===
                                    key?.idOcorrencia
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    // setItemSelected({
                                    //   id: key,
                                    //   infos: groupedByTicketProtocol[key],
                                    // });
                                  }}
                                >
                                  <div className="card-timeline-history__date">
                                    <p>{getOccurencesDate(key?.dataHora)}</p>
                                    <p>{getOccurencesTime(key?.dataHora)} h</p>
                                  </div>
                                  <div className="card-timeline-history__title">
                                    <div className="card-timeline-history__title--left">
                                      <div className="title">
                                        <p>{key?.descricaoOcorrencia}</p>
                                      </div>
                                      <div className="protol">
                                        <p>Prot. #{key?.idOcorrencia}</p>
                                      </div>

                                      <div className="user">
                                        <p>
                                          {key?.nomeUsuario
                                            ? `${key?.nomeUsuario}`
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="card-timeline-history__title--right">
                                      <div className="arrow">
                                        <FaCaretDown />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-timeline-history__content mobile">
                                    {contentOcurrence()}
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className="timeline-v2__content__right">
                    <div className="card-timeline-history__content desktop">
                      {filterRangeActive === "all" ? (
                        <>{contentTimeline()}</>
                      ) : (
                        <>{contentOcurrence()}</>
                      )}
                    </div>
                  </div>
                  <Style.PaginationTimeliveV2>
                    <Pagination
                      current={page}
                      onChange={onPaginationChangeHandler}
                      total={timelineHistory?.total ?? 1}
                      showSizeChanger={false}
                    />
                  </Style.PaginationTimeliveV2>
                </>
              ) : (
                <div className="timeline-v2__content--empty">
                  {contentChangeViewTable()}
                  <AnimatedError width={120} height={120} />
                  <p>Nenhum registro encontrado</p>
                </div>
              )}
            </>
          )}
        </Style.CardTimeLineHistory>
      </div>
    </Style.ContainerTimeLineV2>

    </Can>
  );
}
