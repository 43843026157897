import React, { memo } from 'react';

import { Tooltip } from 'antd';

import ConfirmTransition from './ConfirmTransition';

function Index({ card, reloadCards }) {
  return (
    <>
      <Tooltip
        title={
          card.status !== 'ATIVO'
            ? null
            : 'Cartão pendente de confirmação de transição'
        }
      >
        <span>
          {card.status &&
            card.status[0] +
              card.status.slice(1).toLowerCase().replace('_', ' ')}
        </span>
      </Tooltip>
      <ConfirmTransition card={card} reloadCards={reloadCards} />
    </>
  );
}

export default memo(Index);
