import React, { useCallback, useEffect, useState } from 'react';

import Functionality from './Functionality';

function Functionalities({
  permissions,
  activeFunctionalities,
  setActiveFunctionalities,
  setEditModalOpen,
  setOpenModalinstallment,
}) {
  
  const [functionalities, setFunctionalities] = useState(null);
  
  const handlePermission = useCallback(
    (value, id) => {
      if (value) {
        return setActiveFunctionalities((previous) => [...previous, id]);
      }

      return setActiveFunctionalities((previous) =>
        previous.filter((_p) => _p !== id)
      );
    },
    [setActiveFunctionalities]
  );

  useEffect(async () => {
    const permissionsGroupedByFunctionalities = permissions.reduce(
      (acc, curr) => {
        if (acc[curr.functionality]) {
          acc[curr.functionality] = [...acc[curr.functionality], curr];

          return acc;
        }

        acc[curr.functionality] = [curr];

        return acc;
      },
      {}
    );
    setFunctionalities(permissionsGroupedByFunctionalities);
  }, [permissions, setFunctionalities]);

  let functionalitiesKeys = [];

  if (functionalities) {
    functionalitiesKeys = Object.keys(functionalities).sort();
  }

  if (!functionalitiesKeys?.length) {
    return null;
  }
  
  return functionalitiesKeys?.map((functionality) => (
    <Functionality
      activeFunctionalities={activeFunctionalities}
      handlePermission={handlePermission}
      functionality={functionality}
      permissions={functionalities[functionality]}
      setEditModalOpen={setEditModalOpen}
      setOpenModalinstallment={setOpenModalinstallment}
    />
  ));

}

export default React.memo(Functionalities);
