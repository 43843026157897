import React, { useCallback, useEffect, useRef, useState } from 'react';

import { HomeFilled } from '@ant-design/icons';

import Card from '../../components/Card';
import DataTable from "../../components/DataTable";
import * as TableStyle from "../../components/DataTable/style";
import BreadCrumbNavigation from "../../components/DataTable/TopSection/BreadCrumbNavigation";
import HeaderWithAction from "../../components/DataTable/TopSection/HeaderWithAction";
import LoadingElevaty from '../../components/LoadingElevaty';
import api from '../../services/api';
import * as S from './style';
import UsersTable from './UsersTable';

function LockAccountList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

   const fieldsMapping = {
    sistema: "S",
    permitirAlterarLimite: "S",
    cancelaCliente: "S",
    bloquearModuloCliente: "S"
  };

  const tableHeadersAndFilters =  [
    {descricao: "Descrição", filterType: 'text'},
    {sistema: "Sistema", filterType: 'uniqueData', options: {Sim: 0, Não: 0}},
    {permitirAlterarLimite: "Altera Limite", filterType: 'uniqueData', options: {Sim: 0, Não: 0}},
    {bloquearModuloCliente: "Bloqueia Módulo Cliente", filterType: 'uniqueData', options: {Sim: 0, Não: 0}},
    {cancelaCliente: "Cancela", filterType: 'uniqueData', options: {Sim: 0, Não: 0}},
  ];

  const fetchLockList = useCallback(async () => {
    try {
      await setLoading(true);
      const response = await api.get('list_lock_account');
       response.data = response.data.map(item => {
        Object.keys(fieldsMapping).forEach(prop => {
          if (Object.prototype.hasOwnProperty.call(item, prop)) {
            item[prop] = item[prop] === fieldsMapping[prop] ? "SIM" : "NÃO";
          }
        });
        return item;
      });
      setData(response.data);
    } catch (err) {
      console.error('Erro ao listar ciclo do emissor', err.message);
    } finally {
      await setLoading(false);
    }
  }, []);

  useEffect(async () => {
    await fetchLockList();
  }, [fetchLockList]);

  return (
    <>
      <S.Container>
        <BreadCrumbNavigation tableName="Tipos de bloqueio de conta" preTableName='Listagens'   />
            <TableStyle.Content>
              <HeaderWithAction
                tableName="Tipos de bloqueio de conta" />
              {data && (
                  <DataTable
                    data={data}
                    loading={loading}
                    tableName="Tipos de bloqueios de conta"
                    tableHeadersAndFilters={tableHeadersAndFilters}
                  />
              )}
            </TableStyle.Content>
      </S.Container>
    </>
  );
}
export default React.memo(LockAccountList);
