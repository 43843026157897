
import React, { useEffect, useState } from 'react';

import {faceDetectionAdapter ,loadFaceDetectorModels} from '@biopassid/face-sdk';
import Modal from 'react-modal';

import LoadingBiometric from '../../components/LoadingBiometric';
import { notifyError, notifySucces , notifyInfo } from '../../utils/notifications';
import api from '../api';

function ModalBiometricRequest({
  statusOpen,
  closeModal,
  cpfClient,
  clientId,
  bodyActiveFunction,
  activefunction,
  requiredBy,
}) {
  const [capturedImage, setCapturedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleTakePicture(takePicture) {
    let stream;
    try {
      await loadFaceDetectorModels();
      const resp = await takePicture({
        element: document.getElementById("elementId"),
        faceDetectionAdapter,
        i18n: {
          noFacesDetected: "Nenhuma face detectada",
          multipleFacesDetected: "Multiplas faces detectadas",
          moveFaceLeft: "Mova o rosto para a esquerda",
          moveFaceRight: "Mova o rosto para a direita",
          moveFaceUp: "Mova o rosto para cima",
          moveFaceDown: "Mova o rosto para baixo",
          keepStill: "Mantenha o rosto parado",
      },
        options: {
          width: "100%",
          height: "100%",
          modelsDirectory: "/models",
          fontFamily: "inherit",
          cameraPresets: {
            aspectRatio: 1.777777777777778,
            preferredResolution: 480
          },
          mask: {
            type: "face",
            enabled: true,
            backgroundColor: "#fa8c16",
            backgroundOpacity: 0.9,
            frameColor: "#FFFFFF",
            frameThickness: 3
          },
          faceDetection: {
            enabled: true,
            autoCapture: false,
            autoCaptureTimeout: 6000,
            autoCaptureTimeoutFeedbackColor: "#008000"
          },
          backButton: {
            enabled: true,
            size: "50px",
            backgroundColor: "#FFFFFF",
            padding: "0",
            label: {
                enabled: false,
                content: "",
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000"
            },
            icon: {
                enabled: true,
                source: "",
                color: "#000000",
                size: "30px"
            }
        },
          switchButton: {
            enabled: true,
            size: "50px",
            backgroundColor: "#FFFFFF",
            padding: "0",
            label: {
                enabled: false,
                content: "",
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000"
            },
            icon: {
                enabled: true,
                source: "",
                color: "#000000",
                size: "30px"
            }
        },
            captureButton: {
              enabled: true,
              size: "80px",
              backgroundColor: "#FFFFFF",
              padding: "0",
              label: {
                  enabled: false,
                  content: "",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "#000000"
              },
              icon: {
                  enabled: true,
                  source: "",
                  color: "#000000",
                  size: "40px"
              }
          },
            cancelButton: {
                enabled: true,
                size: "50px",
                backgroundColor: "#FF0000",
                padding: "0",
                label: {
                    enabled: false,
                    content: "",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#FFFFFF"
                },
                icon: {
                    enabled: true,
                    source: "",
                    color: "#FFFFFF",
                    size: "30px"
                }
            },
          confirmButton: {
              enabled: true,
              size: "50px",
              backgroundColor: "#FFFFFF",
              padding: "0",
              label: {
                  enabled: false,
                  content: "",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#000000"
              },
              icon: {
                  enabled: true,
                  source: "",
                  color: "#000000",
                  size: "30px"
              }
          },
          legend: {
            title: {
                enabled: true,
                content: "Captura de Face",
                fontSize: "26px",
                fontWeight: "700",
                color: "#FFFFFF"
            },
            subtitle: {
                enabled: true,
                content: "Encaixe seu rosto no espaço abaixo",
                fontSize: "35px",
                fontWeight: "500",
                color: "#FFFFFF"
            }
        },
        loading: {
            dotsSize: "6px",
            dotsSpacing: "2px",
            dotsColor: "black",
            backgroundColor: "#FFFFFF",
            size: "50px",
            enabled: false
        }
        }
        });
        setLoading(true);
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const base64String = resp.base64.replace(/^data:image\/\w+;base64,/, "");

        if (stream) {
          stream.getTracks().forEach(async track => {
          await track.stop();
          });
        }

        const itsTrueFace = await api.post('face_check', {
            client_id: clientId,
            infobase64: base64String,
            cpf: cpfClient,
            requiredBy
          });

        if(itsTrueFace.data.status === false) handleTakePicture(takePicture);
          notifySucces("Verificação Biométrica Facial Bem-Sucedida");
        if(itsTrueFace.data.status){
          activefunction(bodyActiveFunction.phoneID, bodyActiveFunction.phoneArea, bodyActiveFunction.phoneNumber, bodyActiveFunction.phone_sms)
        } else{
          notifyInfo("Verificação Biométrica Facial não aprovada")
        }
    } catch (error) {
      closeModal();
      if (error !== 'generic/operation_canceled') notifyError('Erro ao carregar deteccao de face. Tente esta ação novamente!');
      console.error(error);
    }finally {
      if (stream) {
        stream.getTracks().forEach(async track => {
        await track.stop();
        });
        setLoading(false);
        closeModal();
      }
    }      
  };

  async function waitForTakePicture(takePicture) {
  return new Promise(resolve => {
    const checkInterval = setInterval(() => {
      if (takePicture !== null) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 100);
  });
}


useEffect(async() => {
  if (statusOpen) {
    try {
      
      const script = await document.createElement('script');
      script.defer = true;
      script.src = 'https://cdn.jsdelivr.net/npm/@biopassid/face-sdk/face-sdk.min.js';
      await document.head.appendChild(script);
      script.onload = async () => {
        // eslint-disable-next-line no-undef
        const { takePicture } = await faceSdk.camera();
        await waitForTakePicture(takePicture);
        await handleTakePicture(takePicture);
      };
    } catch (error) {
      console.log(error);
    }
  }
}, [statusOpen]);

return (
  <Modal isOpen={statusOpen} onRequestClose={closeModal} ariaHideApp={false}>
    {loading ? (
      <LoadingBiometric message="Validando Biometria Facial..." />
    ) : capturedImage?.data?.status ? (
      <LoadingBiometric message="Biometria aprovada!" />
    ) : (
      <div id="elementId" style={{ width: '100%', height: '100%', margin: 'auto' }} />
    )}
  </Modal>
);
  }
    
  export default ModalBiometricRequest;