import React, { useEffect, useRef, useState } from "react";

import { Divider, Modal } from "antd";
import { FaDownload } from "react-icons/fa";

import api from "../../../services/api";
import { notifySucces } from "../../../utils/notifications";
import * as S from "./style";

const RegisterUserLotModal = ({
  isOpen,
  onCancel,
}) => {
  const fileUploader = useRef(null);
  const userLotRef = useRef(null);
  const [loadingUserLotCreation, setLoadingUserLotCreation] = useState(false);
  const [userLotFile, setUserLotFile] = useState(null);
  const [userLotFileLink, setUserLotFileLink] = useState("");
  const [userLotDownloadResponse, setUserLotDownloadResponse] = useState(null);

  const handleFileUploaded = async () => {
    const formData = new FormData();
    const file = fileUploader.current.files[0]; // Obtém o primeiro arquivo selecionado
    if (!file) {
      throw new Error("Nenhum arquivo selecionado");
    }
    formData.append('file', file);

    try {
      setLoadingUserLotCreation(true);
      const response = await api.post("userLot", formData, true, "blob");
      setUserLotDownloadResponse(response);
      const url = window.URL.createObjectURL(response);
      notifySucces("Criação em lote finalizada. Baixe o relatório para detalhes.");
      setUserLotFileLink(url);
    } catch (error) {
      throw new Error(`Erro ao fazer o upload do arquivo: ${error.message}`);
    } finally {
      setLoadingUserLotCreation(false);
    }
  };

  const handleFileChange = () => {
    setUserLotFile(fileUploader.current?.files[0]);
  };

  useEffect(() => {
    if (!isOpen) {
      setUserLotFile(null);
      setUserLotFileLink("");
      setUserLotDownloadResponse(null);
      if (fileUploader.current) {
        fileUploader.current.value = null;
      }
    }
  }, [isOpen]);

  return (
    <Modal
      visible={isOpen}
      title={<span style={{ fontWeight: "bold" }}>Registrar lote de usuários</span>}
      onCancel={onCancel}
      className="store-modal"
    >
      <p style={{
        fontWeight: "bold",
        fontSize: 14
      }}>Exportar planilha com lote de usuários</p>
      <S.Wrapper>
        <S.WrapperInput type="file" accept=".xlsx, .xls, .csv" ref={fileUploader} onChange={handleFileChange} />
      </S.Wrapper>
      <Divider />
      {
        loadingUserLotCreation
        && !!userLotFile && (
          <S.LoadingWrapper>
            <p>Cadastrando usuários, por favor espere...</p>
          </S.LoadingWrapper>
        )
      }
      <S.Wrapper>
        {!!userLotFile && !loadingUserLotCreation && (
          <S.InteractiveButton
            onClick={handleFileUploaded}
            className="new-user"
            disabled={!!userLotDownloadResponse}
            style={{
              cursor: userLotDownloadResponse ? "not-allowed" : "pointer",
              opacity: userLotDownloadResponse ? 0.7 : 1
            }}
          >
            Criar usuários
          </S.InteractiveButton>
        )}
        {
          !loadingUserLotCreation
          && userLotDownloadResponse && (
            <S.InteractiveButton className="download">
              <FaDownload /><a href={userLotFileLink} ref={userLotRef} download="userLot.xlsx">Baixar relatório</a>
            </S.InteractiveButton>
          )
        }
      </S.Wrapper>
    </Modal>
  );
}

export default RegisterUserLotModal;
