import React, { useCallback, useEffect, useState } from "react";

import { HomeFilled } from "@ant-design/icons";
import { format } from "date-fns";

import ChargeComponent from "../../components/ChargeComponent";
import InfosClient from "../../components/InfosClient";
import { CompanyParams } from "../../Context/companyContext"
import { usePermissions } from "../../Context/permissionContext";
import { useClient } from "../../hooks/ClientProvider";
import useInvoicev2 from "../../hooks/useInvoicev2";
import api from "../../services/api";
import { customerStatus } from "../../utils/customerStatus";
import { getFormatDate } from "../../utils/formatDate";
import CardLimitChart from "./CardLimitChart";
import { Cards } from "./Cards";
import PurchaseTimelineCard from "./Cards/PurchaseTimeline";
import FaturaV2 from "./FaturaV2";
import { InsuranceV2 } from "./InsuranceV2";
import { NewTimelineV2 } from "./NewTimelineV2";
import PremiumAnnuityV2 from "./PremiumAnnuityV2";
import QuickAcessCardsComponent from "./QuickAcessCards";
import * as Style from "./style";


function PageAtendimento() {
  const [agreements, setAgreements] = useState([]);
  const [hasAgreements, setHasAgreements] = useState(false);

  const [assessoriaCobransaas, setAssessoria] = useState("");
  const [hasAssessoria, setHasAssessoria] = useState(false);

  const [agreementsPaidOff, setAgreementsPaidOff] = useState([]);

  const [restricaoCobransaas, setRestricao] = useState([]);
  const [hasRestricao, setHasRestricao] = useState(false);

  const [hasSaldoDevedor, setHasSaldoDevedor] = useState();
  const [invoice, setInvoice] = useState();
  const [today, setToday] = useState(() => {
    const todayDate = new Date();
    todayDate.setMonth(todayDate.getMonth() + 2);
    todayDate.setDate(0);
    return todayDate;
  });
  const [lastYear, setLastYear] = useState(() => {
    const lastYearDate = new Date();
    lastYearDate.setMonth(lastYearDate.getMonth() + 2);
    lastYearDate.setDate(0);
    lastYearDate.setMonth(lastYearDate.getMonth() - 12);
    return lastYearDate;
  });

  const { companyParams } = CompanyParams();
  const { client } = useClient();
  const { permissions } = usePermissions();


  const customStatus = customerStatus(client?.client?.statusCliente);
  const lastClientBlockReason = client?.client?.ultimoMotivoBloqueioCliente;
  const pastDuePaymentDays = client?.client?.diasAtrasoTotal;
  const atCreliq = client?.client?.estanoCobranca;

  const getAgreements = async () => {
    try {

      const { resp } = await api.get("agreementCobransaas", {
        client_id: client.client.id,
      });

      if (resp.length > 0
        && resp.some((a) => a.situacao === "ATIVO"
        || a.situacao === "PENDENTE" || a.situacao === "PARCIAL" || a.situacao === "ABERTO")) {
          await setHasAgreements(true);
          await setAgreements(resp.filter(
            (a) => a.situacao === "ATIVO" || a.situacao === "PENDENTE" || a.situacao === "PARCIAL" || a.situacao === "ABERTO"
          ));
      }
    } catch (error) {
      console.error("Acordos nao retornados");
    }
  };

  const getAssessoria = async () => {
    try {
      const { resp } = await api.get("adviceCobransaas", {
        cic: client.client.cpf,
      });

      if (resp[0]?.assessorias[0]?.situacao === "ATIVO") {
        setAssessoria(resp[0]?.assessorias[0]?.nome);
        setHasAssessoria(true);
      } else {
        setHasAssessoria(false);
      }
    } catch (error) {
      console.error("Erro ao consultar Assessorias do cliente na Cobraansaas");
    }
  };

  const getRestricao = async () => {
    try {
      const { resp } = await api.get("restrictionCobransaas", {
        cic: client.client.cpf,
      });

      if (resp?.length > 0) {
        resp.map(async (rest) => {
          if (rest.ativo === true) {
            setHasRestricao(true);
            await setRestricao(restricaoCobransaas.push(rest.acao.tipo));
          }
        });
        setRestricao(restricaoCobransaas.toString());
      }
    } catch (error) {
      console.error("Erro ao consultar restricoes do cliente na Cobraansaas");
    }
  };

  const { invoices } = useInvoicev2(
    client?.client?.id,
    format(lastYear, "yyyy-MM-dd"),
    format(today, "yyyy-MM-dd")
  );

  const getInfoInvoice = async () => {

    if (invoices === undefined || invoices.length === 0 || invoices.length === undefined) {
      await setHasSaldoDevedor(false);
      return;
    }

    const lastInvoice = await invoices[invoices.length - 1];

    if (!lastInvoice?.devedor) {
      await setHasSaldoDevedor(false);
      return;
    }

    const saldoDevedor = Number(lastInvoice?.devedor?.replace("R$ ", ""));
    await setHasSaldoDevedor(saldoDevedor !== 0);
  };

  useEffect(async () => {
    const fetchData = async () => {
      await getAssessoria();
      await getRestricao();
      const canCobransaas = permissions.includes("agreements_cobransaas");
      if(canCobransaas) await getAgreements();
    };

    if(companyParams?.has_cobransaas) fetchData();

  }, [client, companyParams]);


  useEffect(async () => {
    await getInfoInvoice();
  }, [invoices]);


  return (
    <Style.Container>
      <Style.BreadCrumbPage>
        <p>
          <HomeFilled /> / Atendimento
        </p>
      </Style.BreadCrumbPage>

      <InfosClient />
      {/* Cliente nao está em creliq + possui saldo devedor + dias em atraso de fatura */}
      {atCreliq !== 'S' && hasSaldoDevedor && pastDuePaymentDays > 0 && permissions.includes("invoice_installment") && (
          <ChargeComponent
            clientStatus={{
              text: `Fatura em atraso.\n Realize simulação de parcelamento de fatura.`,
              status: "alert",
            }}
            statusDate="alert"
            invoice={invoice}
            textList
            client={client}
            btnInfos={{ title: "Simular", disabled: !permissions.includes("invoice_installment") }} />
        )}

      {/* Cliente com acordo e boleto de cobrança */}
      {hasAgreements && (
          <ChargeComponent
            clientBoleto
            statusDate="alert"
            textList
            assessoriaValue={{
              title: "Assessoria:",
              text: assessoriaCobransaas || "",
            }}
            restricaoValue={{
              title: "Restrição:",
              text: restricaoCobransaas || "",
            }}
            client={client && client}
            agreements={agreements}
            // btnInfos={{ title: "Desbloquear", disabled: true }}
          />
        )}

        {/* Cliente com bloqueio juridico e sem cobransaas */}
        {customStatus === "Bloqueado" &&
          lastClientBlockReason === "Bloqueio Juridico" &&
          !companyParams?.has_cobransaas && false && (
            <ChargeComponent
              clientStatus={{
                text: "Bloqueio jurídico",
                status: "alert",
              }}
              textList
              descricaoValue={{
                title: "Descrição:",
                text: "Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velitNunc vulputate libero et velitNunc vulputate libero et velit",
              }}
              client={client}
              btnInfos={{ title: "Desbloquear", disabled: !permissions.includes("unlock_account") }} />
          )}

        {/* Cliente com bloqueio temporário e sem cobransaas */}
        {customStatus === "Bloqueado" &&
          lastClientBlockReason === "Bloqueio Temporário" &&
          !companyParams?.has_cobransaas && false && (
            <ChargeComponent
              clientStatus={{
                text: "Bloqueio temporário",
                status: "alert",
              }}
              client={client}
              statusDate="alert"
              btnInfos={{ title: "Desbloquear", disabled: !permissions.includes("unlock_account") }} />
          )}

        {/* Cliente ativo, com dias de pagamento em atraso e sem cobransaas */}
        {!hasAgreements && pastDuePaymentDays > 0 && customStatus === "Ativo" && false && (
          <ChargeComponent
            clientStatus={{
              text: "Cliente em atraso",
              status: "warning",
            }}
            client={client}
            btnInfos={{ title: "Bloquear", disabled: !permissions.includes("lock_account") }} />
        )}


      <div className="section-2">
        <div className="section-2__left">
          <Cards />
          <CardLimitChart />
        </div>
        <div className="section-2__right">
          <FaturaV2 />
        </div>
        {/* <InvoiceV2 /> */}
      </div>

      <QuickAcessCardsComponent />

      <NewTimelineV2 />
    </Style.Container>
  );
}

export default PageAtendimento;
