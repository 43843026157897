import { format } from "date-fns";

export function formatCPF(value = "") {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

export function formatCreditCard(value = "") {
  return value.replace(/(\w{4})(\w{4})(\w{4})(\w{4})/g, "$1 $2 $3 $4");
}

export function formatDateTime(date) {
  if (!date) {
    return "";
  }
  const isoDate = new Date(date);
  const dtDateOnly = new Date(
    isoDate.valueOf() + isoDate.getTimezoneOffset() * 60 * 1000
  );
  return format(dtDateOnly, "dd/MM/yyyy HH:mm");
}

export function formatTime(date) {
  if (!date) {
    return "";
  }
  const isoDate = new Date(date);
  const dtDateOnly = new Date(
    isoDate.valueOf() + isoDate.getTimezoneOffset() * 60 * 1000
  );
  return format(dtDateOnly, "HH:mm");
}

export function formatDate(date) {
  if (!date) {
    return "";
  }
  const isoDate = new Date(date);
  const dtDateOnly = new Date(
    isoDate.valueOf() + isoDate.getTimezoneOffset() * 60 * 1000
  );
  return format(dtDateOnly, "dd/MM/yyyy");
}

export function formatCelular(cel) {
  const parte1 = cel.slice(0, 5);
  const parte2 = cel.slice(5, 9);

  return `${parte1}-${parte2}`;
}
