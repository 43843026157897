import styled from 'styled-components';

export const WrapperHeaderSituacao = styled.div`
  .modal-situacao {
    width: 100%;
    padding: 24px;

    display: flex;
    .modal-situacao__alert {
      width: 22px;
      font-size: 22px;
      margin-right: 16px;
      color: #ff4d4f;
    }

    .modal-situacao__text {
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
      }
      .btns {
        width: 100%;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          height: 40px;
          background: #ffffff;
          border: 1px solid #bfbfbf;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          border-radius: 2px;
          padding: 8px 24px;

          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          text-align: center;
          color: rgba(0, 0, 0, 0.85);

          &.active-button {
            margin-left: 8px;
            background: #ff4d4f;
            border: 1px solid #ff4d4f;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            color: #fff;
          }
        }
      }
    }
  }
`;
