import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form, Input, InputNumber, Row, Tooltip } from 'antd';
import moment from 'moment';

import { cardProcessorService } from '../../../../../../../../services/cardProcessorService';
import { formatDate } from '../../../../../../../../utils/formatter';
import useCheckPermission from '../../../../../../../../utils/useCheckPermission';
import Section from '../../../../components/Section';
import Container, {FormItemsContainer} from './style';

function CalculateInstallment({
  isLoading,
  onSimulateClick,
  installmentOptions,
  selectedInstallmentOption,
}) {
  const [parameters, setParameters] = useState();
  const [isFetchingParameters, setIsFetchingParameters] = useState();
  const hasPermission = useCheckPermission('invoice_installment_tax');

  useEffect(() => {
    const getParameters = async () => {
      setIsFetchingParameters(true);

      try {
        const response = await cardProcessorService.getParameters();
        setParameters(response);
      } catch (err) {
        console.log('Error to get the parameters');
      } finally {
        setIsFetchingParameters(false);
      }
    };

    getParameters();
  }, []);

  const onSubmit = useCallback(
    (form) => {
      onSimulateClick(form);
    },
    [onSimulateClick]
  );

  return (
    <Section title="Calcular Parcelamento">
      <Container>
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
          requiredMark={false}
          key={parameters?.taxaEncargoPadraoParcelamentoFatura ?? 0}
          initialValues={{
            tax: parameters?.taxaEncargoPadraoParcelamentoFatura ?? 0,
          }}
        >
        <FormItemsContainer>
            <Form.Item
              label="Taxa de refinanciamento (%a.m.)"
              name="tax"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informar taxa',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={0.1}
                stringMode
                name="tax"
                disabled={!hasPermission}
                min={parameters?.taxaEncargoMinimoParcelamentoFatura}
                max={parameters?.taxaEncargoPadraoParcelamentoFatura}
              />
            </Form.Item>
           {selectedInstallmentOption && (
            <>
              <Form.Item label="Taxa de IOF diário (%a.d.)">
                  <InputNumber
                    style={{ width: '100%' }}
                    step={0.1}
                    value={(selectedInstallmentOption?.taxaIof?.toFixed(2) || selectedInstallmentOption?.valorIof?.toFixed(2)) ?? 0.0}
                    disabled
                  />
                </Form.Item>

                <Form.Item label="Taxa de IOF adicional (%a.m.)">
                  <InputNumber
                    style={{ width: '100%' }}
                    step={0.1}
                    value={
                      (selectedInstallmentOption?.taxaIofAdicional?.toFixed(2) || selectedInstallmentOption?.valorJuros?.toFixed(2) )?? 0.0
                    }
                    disabled
                  />
                </Form.Item>
            </>
            )}
            {installmentOptions?.[0]?.dataRecomendavelAtivacao && (
              <Form.Item label="Recomendável ativação até : ">
                <Tooltip title="Para garantir a ativação deste parcelamento através de qualquer meio de pagamento, esta data deve ser respeitada.">
                  <Input
                    value={
                      installmentOptions?.[0]?.dataRecomendavelAtivacao
                        ? formatDate(
                            moment.parseZone(
                              installmentOptions?.[0]?.dataRecomendavelAtivacao
                            )
                          )
                        : ''
                    }
                    type="datetime"
                    format="DD/MM/YYYY"
                    disabled
                    style={{ width: '100%' }}
                  />
                </Tooltip>
              </Form.Item>
            )}
        </FormItemsContainer>

        <Row justify="center" align="middle" style={{ width: '100%' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading || isFetchingParameters}
            >
              Simular
            </Button>
        </Row>
        </Form>
      </Container>
    </Section>
  );
}

export default React.memo(CalculateInstallment);
