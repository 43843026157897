import React, { useEffect, useState } from 'react';

import { Input } from 'antd';

import { useClient } from '../../../../../../../../hooks/ClientProvider';
import { cardProcessorService } from '../../../../../../../../services/cardProcessorService';
import Section from '../../../../components/Section';
import style from './style.module.scss';

function ClientInfo() {
  const [clientAdditionalData, setClientAdditionalData] = useState();
  const { client } = useClient();

  useEffect(() => {
    const fetchClientAdditionalData = async () => {
      try {
        const response = await cardProcessorService.getClientId(
          client.client.id
        );
        setClientAdditionalData(response);
      } catch (err) {
        console.error('Falha ao consultar ciclo do cliente');
      }
    };

    fetchClientAdditionalData();
  }, [client]);

  return (
    <Section title="Dados do cliente" className={style.container}>
      <Input addonBefore="Cliente" value={client.client.name} disabled />
      <Input
        addonBefore="Cartão"
        value={client.information.numeroCartaoTruncado}
        disabled
      />
      <Input
        addonBefore="Fatura atual (R$)"
        value={clientAdditionalData?.extratoClienteResponse?.valorFaturaFechada}
        disabled
      />
      <Input
        addonBefore="Saldo devedor (R$)"
        value={clientAdditionalData?.extratoClienteResponse?.devedorTotal}
        disabled
      />
    </Section>
  );
}

export default React.memo(ClientInfo);
