import React, { useCallback, useEffect, useState } from "react";

import {
  Pagination,
  Select,
  Checkbox,
  notification,
  Dropdown,
  Menu,
  Tag,
} from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CiFilter } from "react-icons/ci";
import { FaListOl, FaRegCheckCircle, FaRegUserCircle } from "react-icons/fa";
import { FiAlertTriangle, FiClock } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { MdDelete, MdKeyboardArrowRight } from "react-icons/md";
import { RiTimerLine } from "react-icons/ri";

import ExportToFileButton from "../../pages/solicitation/ExportToFileButton";
import { SolicitationTypeService } from "../../services/solicitationTypeService";
import { formatDateTime } from "../../utils/formatter";
import { notifyError } from "../../utils/notifications";
import LoadingElevaty from "../LoadingElevaty";
import { TRANSLATE_PRIORITY } from "../Priority";
import { TRANSLATE_STATUS, STATUSES } from "../Status";
import * as Style from "./style";

const { Option } = Select;
const { Group: CheckboxGroup } = Checkbox;

function TableRequestsHome({
  fetchSolicitations,
  loadingRequestTable,
  dataSource,
  handleOpenModalRequestHome,
}) {
  const [page, setPage] = useState(dataSource?.page ?? 1);
  const [pageSize, setPageSize] = useState(dataSource?.pageSize ?? 15);
  const [isLoading, setIsLoading] = useState(false);
  const [solicitationTypes, setSolicitationTypes] = useState([]);
  const [filters, setFilters] = useState({});
  const [initialValues, setInitialValues] = useState({
    typeSearch: "id",
    valueSearch: "",
    status: [],
    priority: [],
    type: [],
    sorter: "",
  });

  const [showFilters, setShowFilters] = useState(false);

  const togleShowFilters = () => setShowFilters((prevState) => !prevState);

  const onPaginationChangeHandler = useCallback(
    (nextPage, pageSize) => {
      const { typeSearch, valueSearch, status, priority, type, sorter } =
        initialValues;

      const newFilters = {};
      if (valueSearch) {
        newFilters[typeSearch] = [valueSearch];
      }
      if (status && status.length > 0) {
        newFilters.status = status;
      }
      if (priority && priority.length > 0) {
        newFilters.priority = priority;
      }
      if (type && type.length > 0) {
        newFilters.type = type;
      }

      let sorterObj = {};
      if (sorter && typeof sorter === "string" && sorter.includes(",")) {
        const [field, order] = sorter.split(",");
        sorterObj = { field, order };
      }
      setPage(nextPage);
      setPageSize(pageSize);

      fetchSolicitations(
        { current: nextPage, pageSize },
        newFilters,
        sorterObj
      );
    },
    [filters, initialValues]
  );

  const fetchSolicitationTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await SolicitationTypeService.list();
      setSolicitationTypes(response);
    } catch (err) {
      notifyError("Erro ao listar tipos de solicitações", err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSelectForm = useCallback((value, setFieldValue, name) => {
    setFieldValue(name, value);
  }, []);

  const handleClearSearchFilter = useCallback(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      valueSearch: "",
      status: [],
      priority: [],
      type: [],
      sorter: "",
    }));
    setFilters({});
    setShowFilters(false);
    setPage(1);
    fetchSolicitations({ current: 1, pageSize: 15 });
  }, []);

  const handleSearchFilter = useCallback(
    (value) => {
      const { typeSearch, valueSearch, status, priority, type, sorter } = value;

      const newFilters = {};
      if (valueSearch) {
        newFilters[typeSearch] = [valueSearch];
      }
      if (status && status.length > 0) {
        newFilters.status = status;
      }
      if (priority && priority.length > 0) {
        newFilters.priority = priority;
      }
      if (type && type.length > 0) {
        newFilters.type = type;
      }

      if (
        !valueSearch &&
        !sorter &&
        (!status || status.length === 0) &&
        (!priority || priority.length === 0) &&
        (!type || type.length === 0)
      ) {
        notifyError("Insira ou selecione algum filtro para poder pesquisar");
        return;
      }

      let sorterObj = {};
      if (sorter && typeof sorter === "string" && sorter.includes(",")) {
        const [field, order] = sorter.split(",");
        sorterObj = { field, order };
      }

      setFilters(newFilters);

      fetchSolicitations(
        { current: page, pageSize: 15 },
        newFilters,
        sorterObj
      ); // Defina a página atual e o tamanho da página conforme necessário
      setInitialValues(value);
    },
    [page]
  );

  useEffect(() => {
    fetchSolicitationTypes();
  }, []);

  const getPlaceholderText = (typeSearch) => {
    switch (typeSearch) {
      case "id":
        return "Pesquisar por ID da solicitação";
      case "ticket_protocol":
        return "Pesquisar por Protocolo";
      case "client_name":
        return "Pesquisar por Nome do Cliente";
      case "user_name":
        return "Pesquisar por Usuário Solicitante";
      case "attendant_name":
        return "Pesquisar por Usuário Atendimento";
      default:
        return "Pesquisar";
    }
  };

  return (
    <Style.WrapperItemTableRequestHome>
      {loadingRequestTable ? (
        <>
          <div className="table-request__loading">
            <LoadingElevaty />
          </div>
        </>
      ) : (
        <>
          <div className="table-request__header">
            <div className="table-request__header--left">
              <p>
                <strong>Todos os tickets de solicitações</strong>
              </p>
              <p>
                {dataSource?.data?.length}{" "}
                {dataSource?.data?.length === 1
                  ? "Solicitação"
                  : "Solicitações"}
              </p>
            </div>

            <div className="table-request__header--right">
              <div className="export-files">
                {dataSource?.data?.length > 0 ? (
                  <ExportToFileButton data={dataSource.data} />
                ) : null}
              </div>
            </div>

            <div className="input-search-files">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSearchFilter}
                enableReinitialize
              >
                {({ setFieldValue, values }) => {
                  return (
                    <Form>
                      <div className="align-filter-desk">
                        <div className="input-select-type">
                          <Field name="typeSearch">
                            {({ field, meta }) => (
                              <Select
                                defaultValue=""
                                {...field}
                                onChange={(value) => {
                                  handleSelectForm(
                                    value,
                                    setFieldValue,
                                    "typeSearch"
                                  );
                                }}
                                className={`${
                                  meta.touched && meta.error ? "error-id" : null
                                } grau-card`}
                              >
                                <Option value="id">ID da solicitação</Option>
                                <Option value="ticket_protocol">
                                  Protocolo
                                </Option>
                                <Option value="client_name">
                                  Nome do Cliente
                                </Option>
                                <Option value="user_name">
                                  Usuário Solicitante
                                </Option>
                                <Option value="attendant_name">
                                  Usuário Atendimento
                                </Option>
                              </Select>
                            )}
                          </Field>
                          <Field name="valueSearch">
                            {({ field, meta }) => (
                              <div className="input-search__relative">
                                <input
                                  type="text"
                                  placeholder={getPlaceholderText(
                                    values.typeSearch
                                  )}
                                  className={
                                    meta.touched && meta.error
                                      ? "error-id"
                                      : null
                                  }
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    if (e.target.value.length === 1) {
                                      setFieldValue("status", []);
                                    }
                                  }}
                                />

                                {initialValues.valueSearch && (
                                  <div className="remove-item">
                                    <MdDelete
                                      onClick={handleClearSearchFilter}
                                    />
                                  </div>
                                )}

                                <ErrorMessage
                                  name="valueSearch"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <button type="submit">
                          <IoSearch />
                        </button>
                        <button
                          type="button"
                          className={`btn-filter ${
                            showFilters ? "active" : ""
                          }`}
                          onClick={togleShowFilters}
                        >
                          Filtrar
                          {showFilters ? <IoMdClose /> : <CiFilter />}
                        </button>
                      </div>
                      {showFilters && (
                        <div className="show-filters">
                          <div className="show-filters__title">
                            <p>Opções de filtros</p>

                            <button
                              type="button"
                              onClick={handleClearSearchFilter}
                              className="button-clear"
                            >
                              Limpar Filtros
                            </button>
                          </div>

                          <div className="show-filters__grid">
                            <div className="item-filters">
                              <div className="item-filters__title">
                                <p>Ordenar</p>
                              </div>

                              <Field name="sorter">
                                {({ field, meta }) => (
                                  <Select
                                    defaultValue=""
                                    {...field}
                                    onChange={(value) => {
                                      handleSelectForm(
                                        value,
                                        setFieldValue,
                                        "sorter"
                                      );
                                    }}
                                    className={`${
                                      meta.touched && meta.error
                                        ? "error-id"
                                        : null
                                    } grau-card`}
                                  >
                                    <Option value="">Padrão</Option>
                                    <Option value="id,ascend">
                                      ID <IoIosArrowUp />
                                    </Option>
                                    <Option value="id,descend">
                                      ID <IoIosArrowDown />
                                    </Option>
                                    <Option value="ticket_protocol,ascend">
                                      Protocolo <IoIosArrowUp />
                                    </Option>
                                    <Option value="ticket_protocol,descend">
                                      Protocolo <IoIosArrowDown />
                                    </Option>
                                    <Option value="created_at,ascend">
                                      Data/Hora <IoIosArrowUp />
                                    </Option>
                                    <Option value="created_at,descend">
                                      Data/Hora <IoIosArrowDown />
                                    </Option>
                                    <Option value="client_name,ascend">
                                      Nome do Cliente <IoIosArrowUp />
                                    </Option>
                                    <Option value="client_name,descend">
                                      Nome do Cliente <IoIosArrowDown />
                                    </Option>
                                    <Option value="type,ascend">
                                      Tipo Solicitação <IoIosArrowUp />
                                    </Option>
                                    <Option value="type,descend">
                                      Tipo Solicitação <IoIosArrowDown />
                                    </Option>
                                    <Option value="status,ascend">
                                      Status <IoIosArrowUp />
                                    </Option>
                                    <Option value="status,descend">
                                      Status <IoIosArrowDown />
                                    </Option>
                                    <Option value="priority,ascend">
                                      Prioridade <IoIosArrowUp />
                                    </Option>
                                    <Option value="priority,descend">
                                      Prioridade <IoIosArrowDown />
                                    </Option>
                                    <Option value="user,ascend">
                                      Usúario Solicitante <IoIosArrowUp />
                                    </Option>
                                    <Option value="user,descend">
                                      Usúario Solicitante <IoIosArrowDown />
                                    </Option>
                                    <Option value="attendant,ascend">
                                      Usúario Atendimento <IoIosArrowUp />
                                    </Option>
                                    <Option value="attendant,descend">
                                      Usúario Atendimento <IoIosArrowDown />
                                    </Option>
                                  </Select>
                                )}
                              </Field>
                            </div>
                            {solicitationTypes.length > 0 ? (
                              <div className="item-filters">
                                <div className="item-filters__title">
                                  <p>Tipo de solicitação</p>
                                </div>
                                <Dropdown
                                  overlay={
                                    <>
                                      <div className="show-filters__dropdown">
                                        <CheckboxGroup
                                          options={solicitationTypes.map(
                                            (item) => ({
                                              label: item.name,
                                              value: item.id,
                                            })
                                          )}
                                          onChange={(checkedValues) => {
                                            handleSelectForm(
                                              checkedValues,
                                              setFieldValue,
                                              "type"
                                            );
                                          }}
                                          value={values.type}
                                          className="input-checkbox-group"
                                        />
                                      </div>
                                    </>
                                  }
                                >
                                  <div className="item-filters__status">
                                    {values.type.length > 0 ? (
                                      <>
                                        {values.type.map((type) => (
                                          <Tag key={type}>
                                            {
                                              solicitationTypes.find(
                                                (item) => item.id === type
                                              ).name
                                            }
                                          </Tag>
                                        ))}
                                      </>
                                    ) : (
                                      <div className="justify-between">
                                        <p>Todas</p>
                                        <IoIosArrowDown />
                                      </div>
                                    )}
                                  </div>
                                </Dropdown>
                              </div>
                            ) : null}

                            <div className="item-filters">
                              <div className="item-filters__title">
                                <p>Status</p>
                              </div>
                              <Dropdown
                                overlay={
                                  <>
                                    <div className="show-filters__dropdown">
                                      <CheckboxGroup
                                        options={Object.values(STATUSES).map(
                                          (status) => ({
                                            label:
                                              TRANSLATE_STATUS[status].label,
                                            value: status,
                                          })
                                        )}
                                        onChange={(checkedValues) => {
                                          handleSelectForm(
                                            checkedValues,
                                            setFieldValue,
                                            "status"
                                          );
                                        }}
                                        value={values.status}
                                        className="input-checkbox-group"
                                      />
                                    </div>
                                  </>
                                }
                              >
                                <div className="item-filters__status">
                                  {values.status.length > 0 ? (
                                    <>
                                      {values.status.map((status) => (
                                        <Tag
                                          key={status}
                                          color={TRANSLATE_STATUS[status].color}
                                        >
                                          {TRANSLATE_STATUS[status].label}
                                        </Tag>
                                      ))}
                                    </>
                                  ) : (
                                    <div className="justify-between">
                                      <p>Todas</p>
                                      <IoIosArrowDown />
                                    </div>
                                  )}
                                </div>
                              </Dropdown>
                            </div>
                            <div className="item-filters">
                              <div className="item-filters__title">
                                <p>Prioridade</p>
                              </div>
                              <Dropdown
                                overlay={
                                  <>
                                    <div className="show-filters__dropdown">
                                      <CheckboxGroup
                                        options={Object.keys(
                                          TRANSLATE_PRIORITY
                                        ).map((priorityitem) => ({
                                          label:
                                            TRANSLATE_PRIORITY[priorityitem]
                                              .label,
                                          value: priorityitem,
                                        }))}
                                        onChange={(checkedValues) => {
                                          handleSelectForm(
                                            checkedValues,
                                            setFieldValue,
                                            "priority"
                                          );
                                        }}
                                        value={values.priority}
                                        className="input-checkbox-group"
                                      />
                                    </div>
                                  </>
                                }
                              >
                                <div className="item-filters__status">
                                  {values.priority.length > 0 ? (
                                    <>
                                      {values.priority.map((priority) => (
                                        <Tag
                                          key={priority}
                                          color={
                                            TRANSLATE_PRIORITY[priority].color
                                          }
                                        >
                                          {TRANSLATE_PRIORITY[priority].label}
                                        </Tag>
                                      ))}
                                    </>
                                  ) : (
                                    <div className="justify-between">
                                      <p>Todas</p>
                                      <IoIosArrowDown />
                                    </div>
                                  )}
                                </div>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>

          {dataSource?.data?.length > 0 ? (
            <>
              {dataSource.data?.map((item, index) => (
                <Style.DivItemRequest key={item.key}>
                  <div className={`divitem-content__left ${item?.status}`}>
                    <p className="divitem-content__protocol">
                      <strong>#{item?.id}</strong> - Protoc.{" "}
                      {item?.ticket_protocol}
                    </p>
                    <p className="divitem-content__title">
                      <strong>{item?.type?.name}</strong>
                    </p>
                    <p className="divitem-content__infos">
                      <span className="divitem-content__user">
                        <FaRegUserCircle />
                        {item?.client_name}
                      </span>
                      <span className="divitem-content__user">
                        <RiTimerLine />
                        {formatDateTime(item?.created_at)}
                      </span>
                      <span className="divitem-content__user">
                        <FaListOl />
                        {TRANSLATE_PRIORITY[item?.priority]?.label}
                      </span>
                      <span className="divitem-content__user status">
                        {TRANSLATE_STATUS[item?.status].label === "Pendente" ? (
                          <FiClock />
                        ) : null}
                        {TRANSLATE_STATUS[item?.status].label ===
                        "Cancelado" ? (
                          <FiAlertTriangle />
                        ) : null}
                        {TRANSLATE_STATUS[item?.status].label === "Ativo" ? (
                          <FaRegCheckCircle />
                        ) : null}
                        {TRANSLATE_STATUS[item?.status].label ===
                        "Resolvido" ? (
                          <FaRegCheckCircle />
                        ) : null}
                        {TRANSLATE_STATUS[item?.status].label}
                      </span>
                      {item?.attendant && (
                        <span className="divitem-content__user">
                          <FaRegUserCircle />
                          {item?.attendant}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="divitem-content__right">
                    <button
                      type="button"
                      onClick={() => handleOpenModalRequestHome(item)}
                    >
                      Atender <MdKeyboardArrowRight />
                    </button>
                  </div>
                </Style.DivItemRequest>
              ))}

              <div className="pagination-div">
                <Pagination
                  total={dataSource?.total ?? 1}
                  showSizeChanger
                  onChange={onPaginationChangeHandler}
                  current={page}
                  pageSize={pageSize ?? 200}
                  pageSizeOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
                />
              </div>
            </>
          ) : (
            <>
              <div className="table-request__empty">
                <span>
                  <FiAlertTriangle />
                  <p>Não há tickets de solicitações</p>
                </span>
              </div>
            </>
          )}
        </>
      )}
    </Style.WrapperItemTableRequestHome>
  );
}

export default TableRequestsHome;
