import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { cardProcessorService } from '../services/cardProcessorService';
import { decrypt, encrypt, encodeBase64 } from '../utils/crypto';

const ClientContext = createContext({});

interface ClientProviderInterface {
  children: ReactNode;
}

export function ClientProvider({ children }: ClientProviderInterface) {
  const [client, setClient] = useState(() => {
    const encodedKey = encodeBase64('@SnxPayment:client');
    const encryptedClient = localStorage.getItem(encodedKey);
    if (encryptedClient) {
      const originalText = decrypt(encryptedClient);
      return JSON.parse(originalText);
    }
    return undefined;
  });

  const updateClient = useCallback((newClient) => {
    const ciphertext = encrypt(JSON.stringify(newClient));
    const encodedKey = encodeBase64('@SnxPayment:client');
    localStorage.setItem(encodedKey, ciphertext);
    setClient(newClient);
  }, []);

  const clearClient = useCallback(() => {
    const encodedKey = encodeBase64('@SnxPayment:client');
    localStorage.removeItem(encodedKey);
    setClient(undefined);
  }, []);

  const refreshClient = useCallback(async () => {
    const { transicaoBandeira, produto: { id: codigoProduto, isProdutoBandeirado, descricao } } = await cardProcessorService.getClientId(client?.client.id);

    const updatedClient = {
      ...client,
      client: {
        ...client.client,
        transicaoBandeira,
        isProdutoBandeirado,
      },
      product: {
        ...client.product,
        codigoProduto,
        desc_prod: descricao,
      },
    };

    updateClient(updatedClient);
  }, [client]);

  const value = useMemo(
    () => ({
      client,
      updateClient,
      clearClient,
      refreshClient,
    }),
    [clearClient, client, updateClient, refreshClient]
  );

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
}

export function useClient() {
  const context = useContext(ClientContext);

  if (!context) {
    throw new Error('useClient must be used within an ClientProvider');
  }

  return context;
}
const App = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  return location.pathname !== '/' ? (
    <ClientProvider>{children}</ClientProvider>
  ) : (
    <>{children}</>
  );
};

export default App;
