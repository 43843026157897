import styled from "styled-components";

export const ModalCharge = styled.div`
  width: 100%;

  .modal-charge__item {
    width: 100%;
    margin-bottom: 16px;

    p {
      display: block;
      padding: 0;
      margin: 0;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      padding: 8px 12px;
      border-radius: 2px;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &:disabled {
        color: var(--neutral-color-gray-6);
        border: 1px solid var(--neutral-color-gray-5);
        background: var(--neutral-color-gray-3);
      }
    }
  }

  .modal-charge__division {
    width: 100%;
    height: 1px;
    background: var(--neutral-color-gray-5);
    margin-bottom: 16px;
  }

  .modal-charge-select__item {
    width: 100%;
    border: 2px solid #d9d9d9;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.disabled {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      color: black;
      border: 2px solid var(--neutral-color-gray-5);
      background: var(--neutral-color-gray-3);

      p {
        color: #ccc;

        strong {
          color: #ccc;
        }
      }
    }

    .modal-charge-select__item-left,
    .modal-charge-select__item-right {
      width: 50%;
    }

    .modal-charge-select__item-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: wrap;

      span {
        padding: 5px 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: block;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border-radius: 50px;

        svg {
          margin-right: 5px;
          bottom: -1px;
          position: relative;
        }

        &.active {
          color: var(--status-color-success);
          border: 1px solid var(--status-color-success);
          background: var(--neutral-color-gray-1);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        }

        &.blocked {
          border: 1px solid var(--status-color-error);
          background: var(--neutral-color-gray-1);
          color: var(--status-color-error);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        }

        &.waiting {
          border: 1px solid var(--orange-1);
          color: var(--orange-1);
          background: var(--neutral-color-gray-1);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        }
      }

      p {
        color: var(--status-color-error);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding-right: 10px;
      }
    }

    p {
      padding: 0;
      margin: 0;

      color: var(--neutral-color-gray-8, #595959);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      strong {
        color: var(--neutral-color-gray-9, #434343);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
      }
    }

    .icon-check {
      position: absolute;
      right: -2px;
      background: white;
      width: 25px;
      height: 25px;

      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 50px, 0);
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
      }
    }

    &.active {
      border-color: #ffc53d;

      .icon-check {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
        top: -9px;
        transform: translate3d(0px, 0, 0);
      }
    }
  }

  .footer-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      border-top: 1px solid #d9d9d9;
      gap: 16px;

      button {
        display: flex;
        width: 239px;
        padding: 8px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: 1px solid var(--neutral-color-gray-6);
        background: var(--neutral-1);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        &.submit {
          border-radius: 2px;
          border: 1px solid var(--brand-color-gold-6, #ffb32c);
          background: var(--brand-color-gold-6, #ffb32c);
          /* drop-shadow/button-primary */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

          color: var(--neutral-color-gray-9, #434343);
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .button-content {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
`;

