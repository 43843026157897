import styled, { css } from "styled-components";

export const WrapperFaturaV2 = styled.div`
  width: 100%;

  border-radius: 4px;

  border: ${(props) =>
    props.table ? "transparent" : "1px solid rgba(0, 0, 0, 0.06)"};
  background: var(--neutral-color-gray-1, #fff);

  margin-top: ${(props) => (props.table ? "0" : "32px")};

  .faturas-v2__loading {
    width: 100%;
    height: 400px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fatura-v2__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 16px 8px 16px;
    border-bottom: 1px solid #f0f0f0;

    .fatura-v2__header--left {
      display: flex;
      align-items: center;
      p {
        color: var(--neutral-color-dark-10, #272d3b);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
        padding: 0;
        margin: 0;
      }
    }

    .fatura-v2__header--right {
      display: flex;
      align-items: center;

      button {
        &.filter {
          width: 32px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid var(--brand-color-gold-6, #ffb32c);
          background: var(--brand-color-gold-6, #ffb32c);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
          margin: 0;
          margin-right: 8px;
          display: none;

          align-items: center;
          justify-content: center;
          border: 0;
          cursor: pointer;
          transition: all 0.4s ease-in-out;

          &.close {
            border-radius: 2px;
            border: 1px solid var(--neutral-5, #d9d9d9);
            background: var(--neutral-1, #fff);
            /* drop-shadow/button-secondary */
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
            margin-left: 8px;
          }

          &:hover {
            box-shadow: 0px 2px 8px #ffb32c;
          }
        }

        &.btn-configs {
          display: flex;
          width: 163px;
          height: 32px;
          align-items: center;
          justify-content: flex-end;
          border-radius: 2px;
          border: 1px solid transparent;
          background: var(--neutral-color-gray-1, #fff);

          svg {
            font-size: 24px;
          }

          p {
            display: none;
          }
        }
      }
    }
  }

  .faturas-v2__table--calendar {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
    /* margin-bottom: 22px; */
    position: relative;

    .slick-prev {
      display: block;
      left: -3px;
      width: 24px;
      z-index: 1;

      &.slick-disabled {
        opacity: 0.4;
      }

      svg {
        color: #595959;
        font-size: 21px;
      }

      &:before {
        display: none;
      }
    }
    .slick-next {
      display: block;
      right: -3px;
      width: 24px;
      z-index: 1;

      &.slick-disabled {
        opacity: 0.4;
      }

      svg {
        color: #595959;
        font-size: 21px;
      }

      &:before {
        display: none;
      }
    }

    div {
      width: 100%;
    }

    .slick-list {
      overflow: auto !important;
      overflow-x: hidden !important;
      padding-bottom: 25px;
    }

    .faturas-v2__table--calendar--item {
      width: 100% !important;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 22px;
      height: 108px;
      position: relative;
      background: #f0f0f0;

      .arrow-down {
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 100%;
        display: none;

        justify-content: center;
        align-items: center;

        svg {
          font-size: 23px;
          color: #ffb32c;
        }
      }

      &.active {
        background: var(--neutral-color-dark-10, #272d3b);

        .arrow-down {
          display: flex;
        }

        p {
          strong {
            color: #ffb32c;
          }

          color: #fff;
        }
      }

      svg {
        margin-bottom: 8px;
      }

      p {
        padding: 0;
        margin: 0;

        color: var(--neutral-color-gray-7, #8c8c8c);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        strong {
          color: var(--neutral-color-gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 22px;
        }
      }
    }
  }

  .fatura-v2__last-ticket {
    width: 100%;
    padding: 12px 16px;
    background: var(--neutral-color-dark-10, #272d3b);

    p {
      padding: 0;
      margin: 0;
    }

    .fatura-v2__last-ticket--left {
      width: 100%;
      color: #fff;
      margin-bottom: 16px;
      p {
        text-align: center;
        color: var(--brand-color-gold-1, #fffbe6);
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
    }

    .fatura-v2__last-ticket--center {
      width: 100%;
      color: #fff;
      margin-bottom: 16px;
      p {
        text-align: center;

        color: var(--brand-color-gold-1, #fffbe6);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;

        strong {
          color: var(--brand-color-gold-6, #ffb32c);
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }
    }

    .fatura-v2__last-ticket--right {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 124px;

      .item-send {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        svg {
          font-size: 18px;
          color: #ffb32c;
        }
      }

      .item-pdf {
        border-left: 1px solid #fffbe6;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 7px;

        svg {
          font-size: 18px;
          color: #ffb32c;
        }
      }

      .item-baixar {
        border-left: 1px solid #fffbe6;
        padding-left: 8px;
        margin-left: 8px;
        button {
          width: 100%;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 24px;
          border-radius: 2px;
          border: 1px solid var(--neutral-color-gray-6, #bfbfbf);
          background: var(--neutral-color-gray-1, #fff);
          /* drop-shadow/button-secondary */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

          span {
            display: flex;
            align-items: center;

            .download-invoices {
              font-size: 14px;
              margin-right: 8px;
            }

            p {
              color: var(--neutral-color-dark-10, #272d3b);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .fatura-v2__table {
    width: 100%;
    margin-top: 8px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    min-height: 354px;

    span {
      width: 100%;
    }

    .fatura-v2__table--header {
      width: 100%;
      padding: 16px 24px 8px 24px;

      justify-content: space-around;
      border-bottom: 1px solid #ffb32c;
      display: none;

      p {
        color: var(--neutral-color-gray-7, #8c8c8c);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      .fatura-v2__table--header__data,
      .fatura-v2__table--header__local,
      .fatura-v2__table--header__real,
      .fatura-v2__table--header__cartao {
        width: 25%;
      }

      .fatura-v2__table--header__real {
        text-align: right;
      }

      .fatura-v2__table--header__cartao {
        text-align: center;
      }
    }

    .fatura-v2__table--item {
      display: grid;
      grid-template-columns: 1fr 64px;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      padding: 8px 16px 20px 16px;
      border-bottom: 1px solid var(--neutral-color-gray-5, #d9d9d9);
      margin-bottom: 16px;

      &.first {
        padding: 24px 16px 20px 16px;
      }

      &.PAGAMENTO {
        .item-info,
        .item-dinheiro {
          p,
          svg {
            color: #52c41a;
          }

          svg {
            font-size: 17px;
          }
        }
      }

      &.AJUSTE {
        .item-info,
        .item-dinheiro {
          p,
          svg {
            color: #ff4d4f;
          }
        }
      }

      p {
        padding: 0;
        margin: 0;
      }

      .item-data {
        grid-area: 1 / 1 / 2 / 2;
        margin-bottom: 7px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          font-size: 13px;
          color: #8c8c8c;
        }

        p {
          color: var(--neutral-color-gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .item-info {
        grid-area: 2 / 1 / 3 / 2;
        display: flex;
        align-items: center;
        padding-right: 40px;

        svg {
          margin-right: 8px;
          font-size: 13px;
          color: #8c8c8c;
        }

        p {
          color: var(--neutral-color-dark-10, #272d3b);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .item-dinheiro {
        grid-area: 1 / 2 / 4 / 3;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5px;

        p {
          color: var(--neutral-color-dark-10, #272d3b);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .item-cartao-truncado {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;
      }
    }
  }

  .fatura-v2__table--footer {
    width: 100%;
    .pagination {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;

      p {
        padding: 0;
        margin: 0;
        color: var(--neutral-color-gray-7, #8c8c8c);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-right: 8px;
      }

      button {
        &.arrow {
          display: flex;
          width: 32px;
          height: 32px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          border: 1px solid var(--neutral-color-gray-5, #d9d9d9);
          background: var(--neutral-color-gray-1, #fff);

          &:disabled {
            opacity: 0.6;
          }

          &:last-child {
            margin-left: 8px;
          }
        }
      }
    }

    .invoices-modal {
      width: 100%;
      display: none;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 12px 16px;

      p {
        padding: 0;
        margin: 0;
        color: var(--neutral-color-gray-7, #8c8c8c);
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button {
        display: flex;
        padding: 5px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
        border: 1px solid var(--neutral-color-gray-6, #bfbfbf);
        background: var(--neutral-color-gray-1, #fff);
        /* drop-shadow/button-secondary */
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

        p {
          color: var(--neutral-color-dark-10, #272d3b);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .fatura-v2__last-ticket {
      padding: 12px 16px;
      .fatura-v2__last-ticket--right {
        grid-template-columns: 1fr 143px;
      }
    }
  }

  @media (min-width: 768px) {
    max-width: ${(props) => (props.table ? "100%" : "calc(100% - 25px)")};

    margin-left: ${(props) => (props.table ? "0" : "23px")};

    .fatura-v2__header {
      .fatura-v2__header--left,
      .fatura-v2__header--right {
        width: auto;
      }

      .fatura-v2__header--right {
        button {
          &.filter {
            display: inherit;
          }

          &.btn-configs {
            display: flex;
            width: auto;
            height: 32px;
            align-items: center;
            padding: 5px 24px;
            border-radius: 2px;
            border: 1px solid var(--neutral-color-gray-6, #bfbfbf);
            background: var(--neutral-color-gray-1, #fff);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

            svg {
              margin-right: 8px;
              font-size: auto;
            }

            p {
              display: inherit;
              padding: 0;
              margin: 0;
              color: var(--neutral-color-dark-10, #272d3b);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
          }
        }
      }
    }

    .faturas-v2__table--calendar {
      /* max-width: 397px; */
    }
  }
  @media (min-width: 992px) {
    .fatura-v2__last-ticket {
      display: grid;
      grid-template-columns: 194px 1fr 224px;
      align-items: center;

      .fatura-v2__last-ticket--left {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;

        p {
          text-align: left;
          display: block;

          color: var(--brand-color-gold-1, #fffbe6);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
        }
      }

      .fatura-v2__last-ticket--center {
        margin-bottom: 0;
      }

      .fatura-v2__last-ticket--right {
        height: 32px;
      }
    }

    .fatura-v2__table {
      .fatura-v2__table--header {
        display: flex;
      }
      .fatura-v2__table--item {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 100%;

        .item-data,
        .item-info,
        .item-dinheiro,
        .item-cartao-truncado {
          grid-area: auto;
          p {
            font-size: 14px;
          }
        }

        .item-cartao-truncado {
          justify-content: center;
        }
      }
    }

    .fatura-v2__table--footer {
      .pagination {
        display: none;
      }

      .invoices-modal {
        display: flex;
      }
    }
  }
`;

export const ModalFaturaSend = styled.div`
  width: 100%;

  .modal-fatura__loading {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-select__options {
    width: 100%;
    display: flex;

    &.scroll {
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
  }

  .item-select__user {
    width: 100%;
    &:not(:first-child) {
      margin-top: 1rem;
    }

    p.title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .input-search {
      width: 100%;

      form {
        width: 100%;
        display: flex;
        flex-direction: column;

        .error-message{
          width: 100%;
          background: #f8d7da;
          border: 1px solid #f1aeb5;
          color:#58151c;
          margin-bottom: 5px;
          border-radius: 5px;
          padding: 15px;
        }

        .item-input {
          position: relative;
          input {
            width: 100%;
            /* height: 24px; */
            padding: 8px 12px;
            margin-bottom: 24px;
            border-radius: 2px;
            border: 1px solid var(--neutral-5, #d9d9d9);
            background: var(--neutral-color-gray-1, #fff);

            font-size: 16px;
            outline: 0;
            caret-color: #ffb32c;
            color: #434343;

            &:disabled {
              background: #ccc !important;
              opacity: 0.4;
              cursor: no-drop;

              &::placeholder {
                color: #000;
              }
            }

            &.error-id {
              border: 1px solid #ff4d4f;
            }

            &::placeholder {
              color: #bfbfbf;
              font-weight: 400;
            }
          }

          .ant-checkbox-wrapper {
            width: 100%;
            margin-bottom: 16px;
          }
          .ant-select {
            &.ant-select-disabled {
              .ant-select-selector {
                background: #ccc !important;
                opacity: 0.4;
              }
            }

            &.error-id {
              .ant-select-selector {
                border: 1px solid #ff4d4f;
              }
            }

            .ant-select-arrow {
              top: 35% !important;
            }
            .ant-select-selector {
              width: 100%;
              /* height: 24px; */
              height: 47px;
              padding: 8px 12px;
              margin-bottom: 24px;
              border-radius: 2px;
              border: 1px solid var(--neutral-5, #d9d9d9);
              background: var(--neutral-color-gray-1, #fff);

              font-size: 16px;
              outline: 0;
              caret-color: #ffb32c;
              color: #434343;

              &.error {
                border-color: #ff4d4f;
              }

              &::placeholder {
                color: #bfbfbf;
                font-weight: 400;
              }
            }
          }

          .error-msg {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 0.875rem;
            color: #ff4d4f;
          }
        }

        .item-select-type {
          width: 100%;
          border-top: 1px solid #d9d9d9;
          padding-top: 16px;

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;

          .item-fatura {
            display: flex;
            padding: 16px;
            align-items: flex-start;
            gap: 7px;
            align-self: stretch;
            border-radius: 4px;
            border: 2px solid var(--neutral-color-gray-5, #d9d9d9);
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            position: relative;

            &:hover {
              border: 2px solid #ffc53d;
            }

            .icon-check {
              position: absolute;
              right: -10px;
              background: white;
              width: 25px;
              height: 25px;

              opacity: 0;
              visibility: hidden;
              transform: translate3d(0, 50px, 0);
              position: absolute;

              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 20px;
              }
            }

            &.active {
              border: 2px solid #ffc53d;
              p {
                color: #ffc53d;
              }

              .icon-check {
                opacity: 1;
                visibility: visible;
                transition: 0.3s;
                top: -9px;
                transform: translate3d(0px, 0, 0);
              }
            }

            p {
              padding: 0;
              margin: 0;

              color: var(--neutral-color-gray-9, #434343);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
            }
          }
        }

        .item-submit {
          width: 100%;
          margin-top: 16px;
          border-top: 1px solid #d9d9d9;
          padding-top: 16px;

          display: flex;
          justify-content: flex-end;

          button {
            display: flex;
            padding: 8px 24px;
            justify-content: center;
            align-items: center;

            border-radius: 2px;
            border: 1px solid var(--brand-color-gold-6, #ffb32c);
            background: var(--brand-color-gold-6, #ffb32c);
            /* drop-shadow/button-primary */
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

            svg {
              margin-right: 8px;
              font-size: 19px;
            }

            p {
              padding: 0;
              margin: 0;
              color: var(--neutral-color-gray-9, #434343);
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }

        .user-login__btn {
          width: 100%;
          text-align: center;

          button {
            width: 62px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            cursor: pointer;

            background: #ffb32c;
            border-radius: 50%;
            border: 0;

            svg {
              font-size: 24px;
              color: #272d3b;
            }

            &:hover {
              box-shadow: 0px 2px 8px #ffc53d;
            }
            &:disabled {
              background: #a0a0a0;
              &:hover {
                box-shadow: 0px 2px 8px #686868;
              }
            }
          }

          .item-loading {
            width: 24px;
            height: 24px;
            border: 3px solid #272d3b;
            border-block-start-color: transparent;
            border-radius: 50%;
            animation: loading 500ms linear infinite;
          }
        }
      }
    }
  }
`;

export const DocButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;

  height: 48px;
  border-radius: 2px;
  font-weight: bold;

  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }

  ${(props) =>
    props.scroll
      ? css`
          width: 100%;
          min-width: 124px;
        `
      : css`
          width: 100%;
        `}

  ${(props) =>
    props.selected
      ? css`
          background: #ffb32c;
          border: 1px solid #ffb32c;
          color: #434343;
        `
      : css`
          background: white;
          border: 1px solid #ccc;
        `}

  @media (min-width: 75rem) {
    ${(props) =>
      props.scroll
        ? css`
            width: 100%;
            min-width: auto;
          `
        : css`
            width: 100%;
          `}
  }
`;
