const formatPrice = (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value)
};

module.exports = {
  formatPrice,
};
