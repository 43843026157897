import React from "react";

const HeaderWithAction = ({
  tableName,
  createItemButton
}) => {
  return (
      <div className="content-users__title">
        <div className="content-users__title--left">
          <h2>{tableName}</h2>
        </div>
        {createItemButton ? (<div className="content-users__title--right">
          {createItemButton}</div>) : null}
      </div>
  );
};

export default HeaderWithAction;

