import React from "react";

import * as S from "./style"

function InactiveStatus({ status }) {
  return (
    <S.InactiveStatus>{status}</S.InactiveStatus>
  );
}

export default InactiveStatus;
