import React, { memo, useCallback, useState } from 'react';

import { Modal, Select } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaSpinner } from 'react-icons/fa';

import { schemaPrestador } from '../../../helpers/schemaPrestador';
import { useClient } from '../../../hooks/ClientProvider';
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import InputCard from '../../InputCard';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const { Option } = Select;

function AddCardModal({ closeModal }) {
  const { client, updateClient } = useClient();
  const clientID = client?.client?.id;

  const [valueSMS, setValueSMS] = useState(false);
  const [valueFatura, setValueFatura] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  const [titular, setTitular] = useState({
    cpf: '',
    dataNascimento: '',
    idGrauParentesco: '',
    idPessoa: Number(clientID),
    nome: '',
    nomeCartao: '',
    nomePersonalizado: '',
    rg: '',
    sexo: '',
    nomeMae: '',
  });
  const [endereco, setEndereco] = useState({
    bairro: '',
    cep: '',
    complemento: '',
    localidade: '',
    logradouro: '',
    numero: '',
    pais: 'Brazil',
    uf: '',
  });
  const [contato, setContato] = useState({
    faturaDigital: '',
    receberSMS: '',
    tipo: '',
    ramal: '',
    area: '',
    telefone: '',
  });
  const [loading, setLoading] = useState(false);

  const getCep = async (e, setFieldValue) => {
    let cep = e.target.value;
    cep = cep.replace(/\.|\-/g, '');
    setFieldValue('cep', e.target.value);
    if (cep.length === 8) {
      setLoadingCep(true);

      await api
        .get('get_cep', {
          cep,
        })
        .then((res) => {
          if (res.data && res.data.erro) {
            notifyError('CEP Inválido.');
            setFieldValue('localidade', '');
            setFieldValue('bairro', '');
            setFieldValue('logradouro', '');
            setFieldValue('uf', '');
          } else if (res.data) {
            setFieldValue('localidade', res.data.localidade);
            setFieldValue('bairro', res.data.bairro);
            setFieldValue('logradouro', res.data.logradouro);
            setFieldValue('uf', res.data.uf);
          }
          setTimeout(() => {
            setLoadingCep(false);
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            setLoadingCep(false);
          }, 500);
          notifyError('CEP Inválido.');
        })
        .finally(() =>
          setTimeout(() => {
            setLoadingCep(false);
          }, 500)
        );
    }
  };
  const handleChangeTitular = useCallback((event) => {
    const { id, value } = event.target;
    setTitular((prevTitular) => ({ ...prevTitular, [id]: value }));
  }, []);

  const handleChangeMotherName = useCallback((event) => {
    const { id, value } = event.target;
    setTitular((prevTitular) => ({ ...prevTitular, [id]: value }));
  }, []);

  const handleChangeContato = useCallback((event) => {
    const { id, value } = event.target;
    setContato((prevTitular) => ({ ...prevTitular, [id]: value }));
  }, []);

  const handleChangeEndereco = useCallback((event) => {
    const { id, value } = event.target;
    setEndereco((prevTitular) => ({ ...prevTitular, [id]: value }));
  }, []);

  const handleChangeSelectTitular = useCallback((value, field) => {
    setTitular((prevTitular) => ({ ...prevTitular, [field]: value }));
  }, []);

  const handleSelectForm = useCallback((value, setFieldValue, name) => {
    setFieldValue(name, value);
  }, []);

  const handleChangeSelectContato = useCallback(
    (field) => (value) => {
      setContato((prevTitular) => ({ ...prevTitular, [field]: value }));
    },
    []
  );

  function getValueByPath(path, object) {
    const segments = path.split('.');
    let value = object;

    // eslint-disable-next-line no-restricted-syntax
    for (const segment of segments) {
      if (value && Object.prototype.hasOwnProperty.call(value, segment)) {
        value = value[segment];
      } else {
        value = undefined;
        break;
      }
    }

    return value;
  }

  const send = useCallback(
    async (values) => {
      try {
        const fieldsToCheck = [
          'nome',
          'nomeCartao',
          'nomePersonalizado',
          'cpf',
          'rg',
          'idGrauParentesco',
          'dataNascimento',
          'sexo',
          'nomeMae',
          'bairro',
          'cep',
          'localidade',
          'logradouro',
          'numero',
          'uf',
          'tipo',
          'area',
          'telefone'
        ];

        const fieldLabels = {
          cep: 'Campo CEP de endereço é obrigatório',
          bairro: 'Campo Bairro de endereço é obrigatório',
          area: 'Campo DDD de telefone é obrigatório',
          uf: 'Campo UF de endereço é obrigatório',
          localidade: 'Campo Cidade de endereço é obrigatório',
          telefone: 'Campo Telefone é obrigatório',
          numero: 'Campo Número de endereço é obrigatório',
          logradouro: 'Campo Rua de endereço é obrigatório'
        };

        fieldsToCheck.forEach(field => {
          const value = getValueByPath(field, values);
          if (!value || value === '') {
            throw new Error(fieldLabels[field]);
          }
        });
        setLoading(true);
        const formatedDate = values.dataNascimento.split('/');
        const data = {
          client_id: clientID,
          idPessoa: clientID,
          nome: values.nome,
          nomeCartao: values.nomeCartao,
          nomePersonalizado: values.nomePersonalizado,
          cpf: values.cpf.split('.').join('').replace('-', ''),
          rg: values.rg.split('.').join('').replace('-', ''),
          idGrauParentesco: values.idGrauParentesco,
          dataNascimento: `${formatedDate[2]}-${formatedDate[1]}-${formatedDate[0]}`,
          sexo: values.sexo,
          nomeMae: values.nomeMae,
          endereco: {
            bairro: values.bairro,
            cep: values.cep.replace('-', ''),
            complemento: values.complemento,
            localidade: values.localidade,
            logradouro: values.logradouro,
            numero: values.numero,
            pais: 'Brazil',
            uf: values.uf,
          },
          telefones: [
            {
              tipo: values.tipo,
              ramal: '',
              area: values.area,
              telefone: values.telefone,
              faturaDigital: valueFatura,
              receberSMS: valueSMS,
            },
          ],
        };
        const {
          data: { id },
        } = await api.post('adicional_new_card', { ...data });
        const newCLient = {
          ...client,
          client: {
            ...client.client,
            adicional: [
              ...client.client.adicional,
              {
                id,
                cpf: data.cpf,
                grauParentesco: data.idGrauParentesco,
                nascimento: data.dataNascimento,
                nome: data.nome,
                sexo: data.sexo[0],
                nomeMae: data.nomeMae,
              },
            ],
          },
        };
        updateClient(newCLient);
        closeModal();
        notifySucces('Criado com sucesso');
      } catch (error) {
        notifyError('Não foi possível concluir o cadastro do cartão adicional. Por favor verifique se inseriu os dados corretamente.');
      } finally {
        setLoading(false);
      }
    },
    [
      client,
      clientID,
      closeModal,
      contato,
      endereco,
      titular,
      updateClient,
      valueSMS,
      valueFatura,
    ]
  );

  return (
    <Modal title="Adicionar Cartão" visible onCancel={closeModal}>
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
      <S.ModalAddWrapper>
        <Formik
          initialValues={{
            nome: '',
            nomePersonalizado: '',
            dataNascimento: '',
            cpf: '',
            rg: '',
            idGrauParentesco: '',
            sexo: '',
            nomeMae: '',
            cep: '',
            uf: '',
            localidade: '',
            bairro: '',
            numero: '',
            complemento: '',
            logradouro: '',
            nomeCartao: '',
            tipo: '',
            area: '',
            telefone: '',
          }}
          validationSchema={schemaPrestador}
          onSubmit={send}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form>
              <S.ModalRowWrap>
                <S.inputNameFull>
                  <Field name="nome">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Nome completo"
                        placeholder="Ex.: José Ribeiro Gonçalves"
                        onChange={handleChangeTitular}
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="nome"
                    component="div"
                    className="error-message"
                  />
                </S.inputNameFull>
                <S.inputCreditCard>
                  <Field name="dataNascimento">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Data de nascimento"
                        placeholder="DD/MM/YYYY"
                        mask="99/99/9999"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="dataNascimento"
                    component="div"
                    className="error-message"
                  />
                </S.inputCreditCard>
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <S.inputW50>
                  <Field name="cpf">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="CPF"
                        placeholder="Ex.: 999.999.999-99"
                        mask="999.999.999-99"
                        id="cpf"
                        requiredInfo
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        {...field}
                      />
                    )}
                  </Field>

                  <ErrorMessage
                    name="cpf"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
                <S.inputW50>
                  <Field name="rg">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="RG"
                        placeholder="RG"
                        id="rg"
                        onChange={handleChangeTitular}
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="rg"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>

              </S.ModalRowWrap>
              <S.ModalRowWrap>
                <S.inputW50>
                  <p className="title">
                    <span>*</span>
                    Grau de parentesco
                  </p>

                  <Field name="idGrauParentesco">
                    {({ field, meta }) => (
                      <Select
                        defaultValue=""
                        {...field}
                        onChange={(value) => {
                          handleSelectForm(
                            value,
                            setFieldValue,
                            'idGrauParentesco'
                          );
                        }}
                        className={`${meta.touched && meta.error ? 'error-id' : null
                          } grau-card`}
                      >
                        <Option value="">Grau de parentesco</Option>
                        <Option value="1">Pai</Option>
                        <Option value="2">Mãe</Option>
                        <Option value="3">Filho</Option>
                        <Option value="4">Irmão</Option>
                        <Option value="5">Conjuge</Option>
                        <Option value="6">Outro</Option>
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="idGrauParentesco"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
                <S.inputW50>
                  <p className="title">
                    <span>*</span>
                    Sexo
                  </p>
                  <Field name="sexo">
                    {({ field, meta }) => (
                      <Select
                        defaultValue=""
                        {...field}
                        onChange={(value) => {
                          handleSelectForm(value, setFieldValue, 'sexo');
                        }}
                        className={`${meta.touched && meta.error ? 'error-id' : null
                          } grau-card`}
                      >
                        <Option value="">Sexo</Option>
                        <Option value="MASCULINO">Masculino</Option>
                        <Option value="FEMININO">Feminino</Option>
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="sexo"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <Field name="nomeMae">
                  {({ field, meta }) => (
                    <InputCard
                      addonBefore="Nome da mãe"
                      placeholder="Nome Completo da mãe"
                      onChange={handleChangeMotherName}
                      className={
                        meta.touched && meta.error ? 'error-id' : null
                      }
                      requiredInfo
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="nomeMae"
                  component="div"
                  className="error-message"
                />
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <S.inputW33>
                  <Field name="cep">
                    {({ field, meta }) => (
                      <>
                        <InputCard
                          addonBefore="CEP"
                          placeholder="Ex.: 999999-999"
                          mask="99999-999"
                          maskChar={null}
                          className={`input-cep ${meta.touched && meta.error ? 'error-id' : null
                            }`}
                          {...field}
                          requiredInfo
                          onChange={(e) => getCep(e, setFieldValue)}
                        />
                        <div
                          className={`spinner ${loadingCep ? 'active' : ''}`}
                        >
                          <FaSpinner color="#faad14" />
                        </div>
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="cep"
                    component="div"
                    className="error-message"
                  />
                </S.inputW33>
                <S.inputW33>
                  <Field name="uf">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="UF"
                        placeholder="Sigla"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="uf"
                    component="div"
                    className="error-message"
                  />
                </S.inputW33>
                <S.inputW33>
                  <Field name="localidade">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Cidade"
                        placeholder="Nome da cidade"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="localidade"
                    component="div"
                    className="error-message"
                  />
                </S.inputW33>
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <S.inputW40>
                  <Field name="bairro">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Bairro"
                        placeholder="Nome do bairro"
                        id="bairro"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="bairro"
                    component="div"
                    className="error-message"
                  />
                </S.inputW40>

                <S.inputW20>
                  <Field name="numero">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Número"
                        placeholder="Número"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="numero"
                    component="div"
                    className="error-message"
                  />
                </S.inputW20>
                <S.inputW40>
                  <p className="title">
                    Complemento
                  </p>
                  <Field name="complemento">
                    {({ field, meta }) => (
                      <InputCard
                        placeholder="Ex.: Primeiro andar prédio 3"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        {...field}
                      />
                    )}
                  </Field>
                </S.inputW40>
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <Field name="logradouro">
                  {({ field, meta }) => (
                    <InputCard
                      addonBefore="Rua"
                      placeholder="Nome da rua"
                      className={meta.touched && meta.error ? 'error-id' : null}
                      {...field}
                      requiredInfo
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="logradouro"
                  component="div"
                  className="error-message"
                />
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <S.inputW50>
                  <Field name="nomeCartao">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Nome cartão"
                        placeholder="Ex.: José Ribeiro"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        {...field}
                        requiredInfo
                      />
                    )}
                  </Field>

                  <ErrorMessage
                    name="nomeCartao"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
                <S.inputW50>
                  <Field name="nomePersonalizado">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Nome personalizado"
                        placeholder="Ex.: José R."
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        {...field}
                        requiredInfo
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="nomePersonalizado"
                    component="div"
                    className="error-message"
                  />
                </S.inputW50>
              </S.ModalRowWrap>

              <S.ModalRowWrap>
                <S.inputW40>
                  <p className="title">
                    <span>*</span>
                    Tipo de contato
                  </p>
                  <Field name="tipo">
                    {({ field, meta }) => (
                      <Select
                        defaultValue=""
                        {...field}
                        onChange={(value) => {
                          handleSelectForm(value, setFieldValue, 'tipo');
                        }}
                        className={`${meta.touched && meta.error ? 'error-id' : null
                          } grau-card`}
                      >
                        <Option value="">Tipo de contato</Option>
                        <Option value="RESIDENCIAL">Residencial</Option>
                        <Option value="CELULAR">Celular</Option>
                        <Option value="COMERCIAL">Comercial</Option>
                        <Option value="REFERENCIA">Referência</Option>
                        <Option value="OUTRO">Outro</Option>
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="tipo"
                    component="div"
                    className="error-message"
                  />
                </S.inputW40>

                <S.inputW20>
                  <Field name="area">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="DDD"
                        placeholder="DDD"
                        mask="99"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="area"
                    component="div"
                    className="error-message"
                  />
                </S.inputW20>
                <S.inputW40>
                  <Field name="telefone">
                    {({ field, meta }) => (
                      <InputCard
                        addonBefore="Telefone"
                        placeholder="Ex.: 99999-9999"
                        mask="999999999"
                        className={
                          meta.touched && meta.error ? 'error-id' : null
                        }
                        requiredInfo
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="telefone"
                    component="div"
                    className="error-message"
                  />
                </S.inputW40>
              </S.ModalRowWrap>

              <S.ModalRow>
                {!loading && (
                  <div className="btns-send">
                    <button type="button" onClick={closeModal}>
                      Cancelar
                    </button>
                    <button type="submit">Solicitar</button>
                  </div>
                )}
              </S.ModalRow>
            </Form>
          )}
        </Formik>
      </S.ModalAddWrapper>
    </Modal>
  );
}

export default memo(AddCardModal);
