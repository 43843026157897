import React, { useCallback, useEffect, useState } from "react";

import { ConfigProvider, DatePicker, Modal } from "antd";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { format } from "date-fns";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { FaFilter, FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineDownload } from "react-icons/md";

import { useClient } from "../../hooks/ClientProvider";
import useInvoicev2 from "../../hooks/useInvoicev2";
import api from "../../services/api";
import LoadingElevaty from "../LoadingElevaty";
import * as Style from "./style";


const { RangePicker } = DatePicker;



function ModalHistoricoFaturas({ isOpen, onClose }) {

  const { client } = useClient();

  const [rangeDate, setRangeDate] = useState(false);

  const [lastYear, setLastYear] = useState(() => {
    const lastYearDate = new Date();
    lastYearDate.setMonth(lastYearDate.getMonth() + 2);
    lastYearDate.setDate(0);
    lastYearDate.setMonth(lastYearDate.getMonth() - 12);
    return lastYearDate;
  });
  const [today, setToday] = useState(() => {
    const todayDate = new Date();
    todayDate.setMonth(todayDate.getMonth() + 2);
    todayDate.setDate(0);
    return todayDate;
  });

  const changeRangeFatura = (e) => {
    setLastYear(moment(e[0].utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
    setToday(moment(e[1].utc().format("YYYY-MM-DD HH:mm:ss")).toDate());
  };

  const { invoices, loadingInvoice, getData } = useInvoicev2(
    client?.client?.id,
    format(lastYear, "yyyy-MM-dd"),
    format(today, "yyyy-MM-dd")
  );

  const showBilletPdf = useCallback(async (row) => {
    try {
      const data = await api.get(
        "billet_pdf",
        { invoice_id: row, raw_pdf: "true" },
        { "Content-Type": "application/pdf" },
        true
      );

      const fileURL = URL.createObjectURL(await data.blob());
      const pdfWindow = window.open(fileURL);
      setTimeout(() => {
        pdfWindow.document.title = "Fatura.pdf";
      }, 750);
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [isOpen])

  return (
    <Modal
      title="Histórico de faturas"
      visible={isOpen}
      onCancel={() => onClose(false)}
      width={795}
    >
      <Style.WrapperModalHistoricoFaturas>
        <div className="historico-faturas__filter">
          {rangeDate ? (
            <ConfigProvider locale={datePickerLocale}>
              <RangePicker
                defaultValue={[moment(lastYear), moment(today)]}
                format="DD/MM/YYYY"
                onChange={changeRangeFatura}
              />
              <button
                type="button"
                className="filter close"
                onClick={() => setRangeDate(false)}
              >
                <AiOutlineClose />
              </button>
            </ConfigProvider>
          ) : (
            <button
              type="button"
              className="filter"
              onClick={() => setRangeDate(true)}
            >
              <FaFilter />
            </button>
          )}
        </div>

        {loadingInvoice ? (
          <div className="historico-faturas__loading">
            <LoadingElevaty duration="durFast" />
          </div>
        ) : (
          <div className="historico-faturas__table">
            {invoices ? (
              <>
                <div className="historico-faturas__table--header">
                  <div className="historico-faturas__table--header__data">
                    <p>Vencimento</p>
                  </div>
                  <div className="historico-faturas__table--header__local">
                    <p>Valor Total</p>
                  </div>
                  <div className="historico-faturas__table--header__local">
                    <p>Valor Pago</p>
                  </div>
                  <div className="historico-faturas__table--header__local">
                    <p>Saldo devedor</p>
                  </div>
                  <div className="historico-faturas__table--header__real">
                    <p>Ações</p>
                  </div>
                </div>

                <span>
                  {invoices.map((item, index) => (
                    <div
                      className={`historico-faturas__table--item  ${index === 0 ? "first" : ""
                        }`}
                      key={Math.random()}
                    >
                      <div className="item-data">
                        <div className="historico-faturas__title-mobile">
                          <span className="title">
                            Vencimento
                          </span>
                        </div>

                        <FaRegCalendarAlt />

                        <p>{item.dataVencimento}</p>
                      </div>
                      <div className="item-dinheiro">
                        <div className="historico-faturas__title-mobile">
                          <span className="title">
                            Valor Total
                          </span>
                        </div>
                        <p>{item.valorTotal}</p>
                      </div>
                      <div className="item-dinheiro">
                        <div className="historico-faturas__title-mobile">
                          <span className="title">
                            Valor Pago
                          </span>
                        </div>
                        <p>{item.valorPagamentosEfetuados}</p>
                      </div>
                      <div className="item-dinheiro">
                        <div className="historico-faturas__title-mobile">
                          <span className="title">
                            Saldo devedor
                          </span>
                        </div>
                        <p>{item.devedor}</p>
                      </div>
                      <div className="item-dinheiro btn-desktop">
                        <div className="historico-faturas__title-mobile">
                          <span className="title">
                            Ações
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => showBilletPdf(item?.idFatura)}
                        >
                          <MdOutlineDownload />
                        </button>
                      </div>
                    </div>
                  ))}
                </span>
              </>
            ) : null}


          </div>
        )}
      </Style.WrapperModalHistoricoFaturas>
    </Modal>
  );
}

export default ModalHistoricoFaturas;
