import styled, { css } from "styled-components"

export const InteractiveButton = styled.button`
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid var(--Brand-Color-Gold-6, #ffb32c);
  background: var(--Brand-Color-Gold-6, #ffb32c);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Neutral-Color-Gray-9, #434343);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  transition: ease 0.5s linear;
  width: fit-content;
  a {
    color: var(--Neutral-Color-Gray-9, #434343);
  }
  &.download {
    border: 1px solid #52C41A;
    background: #52C455;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
`

export const WrapperInput = styled.input`
`

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
