import React, { useEffect , useCallback, useState } from "react";

import { Button, Select } from "antd";

import api from "../../../../../services/api";
import { notifySucces } from "../../../../../utils/notifications";

const { Option } = Select;

function SubclassificationProcess({ relateSubclassificationFn }) {
  const [subClassificationsOptions, setSubClassificationsOptions] = useState([]);
  const [subClassificationSelected, setSubClassificationSelected] = useState({});

  const onSubClassificationSelection = useCallback((valueId, { valueName }) => {
    setSubClassificationSelected({id: valueId, name: valueName});
  }, []);

  const fetchSubclassifications = useCallback(async () => {
    try {
      const response = await api.get("subclassifications/issuer/");
      setSubClassificationsOptions(response.data);
    } catch (error) {
      setSubClassificationsOptions([]);
      console.error('Nao foi possivel carregar as subclassificações');
    }
  }, []);

  async function relateSubClassificationFnWrapper() {
    try {
      const didRegistered = await relateSubclassificationFn(subClassificationSelected);
      if(didRegistered)
        notifySucces(`Sub-Classificacão "${subClassificationSelected?.name}" relacionada com sucesso!`);
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    fetchSubclassifications();
  }, [fetchSubclassifications]);

  return (
    <>
      <Select
        onChange={onSubClassificationSelection}
        showSearch
        optionFilterProp="children"
        placeholder="Selecione uma opção"
        notFoundContent="Nenhum registro encontrado para esta busca!"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {subClassificationsOptions.map((classification) => (
          <Option
            key={classification.id}
            valueId={classification.id}
            valueName={classification.name}
          >
            {classification.name}
          </Option>
        ))}
      </Select>
      <Button onClick={relateSubClassificationFnWrapper} type="primary">
        Relacionar
      </Button>
    </>
  )
}

export default React.memo(SubclassificationProcess);