import styled, { css } from "styled-components";

export const FilterContainer = styled.div`
  width: 100%;

  position: relative;
  top: 100%;
  z-index:1;

  .inner-container {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    position: absolute;
    top: 7px;
    right: 0;

    .line-space {
      margin-top: 3px;
    }

    .antd-input {
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 8px;
    }

    .antd-button {
      width: 90px;
    }

    .antd-checkbox-group {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .position-adjustment {
        margin-left: 0px;
    }

    .delimiter {
      height: 1px;
      width: 100%;
      background: #dedede;
      margin-block: 5px;
    }

    .reset-button {
      width: 50px;
      border: none;
      background: none;
    }

  }
`
