import React, { memo } from 'react';

import { Navigate } from 'react-router-dom';

import { PageLoadingElevaty } from '../components/LoadingElevaty/PageLoadingElevaty';
import { usePermissions } from '../Context/permissionContext';
import useSocket from '../hooks/useSocket';

function PrivateRouterComponent({ children }) {
  useSocket('user:deactivateUser');
  const jwt = localStorage.getItem('@SnxPayment:token');
  if (!jwt) {
    return <Navigate to={{ pathname: '/' }} />;
  }
  return children;
}

export const PrivateRouter = memo(PrivateRouterComponent);

function PermissionRouterComponent({ children, permission }) {
  const { permissions, isLoading } = usePermissions();
  if (isLoading) {
    return <PageLoadingElevaty />
  }
  if (!permissions || !permissions.includes(permission)) {
    return <Navigate to={{ pathname: '/' }} />;
  }
  return <PrivateRouter>{children}</PrivateRouter>;
}

export const PermissionRouter = memo(PermissionRouterComponent);
