import React from 'react';

import Lottie from 'react-lottie'

import errorAnimation from '../../assets/error-search.json'

const AnimatedError = ({ width, height }) => {
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie width={width} height={height} options={loadingOptions} />
}

export default AnimatedError
