import styled, { css } from "styled-components";

export const WrapperModalHistoricoFaturas = styled.div`
  width: 100%;

  .historico-faturas__filter {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      &.filter {
        width: 32px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid var(--brand-color-gold-6, #ffb32c);
        background: var(--brand-color-gold-6, #ffb32c);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
        margin: 0;
        margin-right: 8px;

        align-items: center;
        justify-content: center;
        border: 0;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
          box-shadow: 0px 2px 8px #ffb32c;
        }
      }
    }
  }

  .historico-faturas__loading {
    width: 100%;
    height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .historico-faturas__title-mobile {
    width: 100%;

    span.title {
      display: block;
      margin-bottom: 5px;
      color: var(--neutral-color-gray-7, #8c8c8c);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: unset !important;
    }
  }

  .historico-faturas__table {
    width: 100%;
    margin-top: 8px;

    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between;
    align-content: space-between;
    min-height: 354px; */

    span {
      width: 100%;
      max-height: 416px;
      overflow-y: scroll;
    }

    .historico-faturas__table--header {
      width: 100%;
      padding: 16px 24px 8px 24px;

      justify-content: space-between;
      border-bottom: 1px solid #ffb32c;
      display: none;

      p {
        color: var(--neutral-color-gray-7, #8c8c8c);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      .historico-faturas__table--header__data,
      .historico-faturas__table--header__local,
      .historico-faturas__table--header__real {
        width: 25%;
      }

      .historico-faturas__table--header__real {
        text-align: right;
      }
    }

    .historico-faturas__table--item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 9px;
      padding: 8px 16px 20px 16px;
      border-bottom: 1px solid var(--neutral-color-gray-5, #d9d9d9);
      margin-bottom: 16px;

      &.first {
        padding: 24px 16px 20px 16px;
      }

      p {
        padding: 0;
        margin: 0;
      }

      .item-data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        svg {
          margin-right: 8px;
          font-size: 13px;
          color: #8c8c8c;
        }

        p {
          color: var(--neutral-color-gray-7, #8c8c8c);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .item-info {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          font-size: 13px;
          color: #8c8c8c;
        }

        p {
          color: var(--neutral-color-dark-10, #272d3b);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .item-dinheiro {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        /* justify-content: flex-end; */

        p {
          color: var(--neutral-color-dark-10, #272d3b);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        button{
          background: transparent;
          border: 1px solid #8c8c8c;
          border-radius: 4px;
          padding: 4px 8px;
          color: var(--neutral-color-dark-10, #272d3b);
          margin-left: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          svg{
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .historico-faturas__title-mobile {
      display: none;
    }

    .historico-faturas__table {
      .historico-faturas__table--header {
        display: flex;
      }
      .historico-faturas__table--item {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 100%;

        .item-data,
        .item-info,
        .item-dinheiro {
          grid-area: auto;
          p {
            font-size: 14px;
          }

          &.btn-desktop{
            margin-left: auto;
          }
        }
      }
    }
  }
`;
