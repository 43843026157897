import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';

import { eventEmitter } from '../../utils/eventEmitter';

const LogoutListener = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const handleLogout = () => {
            if (!isModalOpen) {
                setIsModalOpen(true);
                Modal.info({
                    title: 'Sessão Encerrada',
                    content: 'Nova sessão iniciada para esse usuário em outra máquina.',
                    onOk() {
                        localStorage.clear();
                        window.location.reload();
                    },
                    afterClose() {
                        setIsModalOpen(false);
                    }
                });
            }
        };

        eventEmitter.on('logout', handleLogout);

        return () => {
            eventEmitter.off('logout', handleLogout);
        };
    }, [isModalOpen]);

    return null;
};

export default LogoutListener;
