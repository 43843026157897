import React, { memo, useCallback, useState } from 'react';

import Modal from 'react-modal';

import { notifyError } from '../../utils/notifications';
import ModalAttend from '../ModalAttend';

function ModalAttendWrapper({ modalIsOpen, modalIsOpenSetter }) {
  const [attendanceStarted, setAttendanceStarted] = useState(false);

  const closeModal = useCallback(
    (shouldVerifyClient = true) => {
      if (attendanceStarted && shouldVerifyClient) {
        notifyError(
          'Por favor, preencha todos os dados do cliente ou clique no botão de "Voltar"'
        );
        return;
      }
      modalIsOpenSetter(false);
    },
    [attendanceStarted, modalIsOpenSetter]
  );

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          background:
            'linear-gradient(rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.55))',
        },
        content: {
          backgroundColor: '#FFFFFF',
          borderColor: '#FFFFFF',
          color: 'black',
          overflow: 'auto',
          top: '45%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '450px',
          maxHeight: '90%',
          TransitionEvent: '0.5s',
        },
      }}
    >
      <ModalAttend
        close={closeModal}
        attendanceStartedSetter={setAttendanceStarted}
      />
    </Modal>
  );
}

export default memo(ModalAttendWrapper);
