import React, { memo, useCallback, useState } from 'react';

import { Menu } from 'antd';

import { PERMISSIONS, usePermissions } from '../../../../Context/permissionContext';
import SetPassword from './index';

const { Item } = Menu;

interface SetPasswordMenuItemProps {
  isHistoryCard: boolean;
  cardId: number;
  clientId: number;
  productId: number;
}
function SetPasswordMenuItem({
  isHistoryCard = false,
  productId,
  clientId,
  cardId,
}: SetPasswordMenuItemProps) {
  const { permissions } = usePermissions() as any;
  const [visible, setVisible] = useState(false);

  const showSetPasswordModal = useCallback(() => {
    setVisible(true);
  }, []);

  const hideSetPasswordModal = useCallback(() => {
    setVisible(false);
  }, []);

  if (!permissions?.includes(PERMISSIONS.SET_CARD_PASSWORD) && isHistoryCard) {
    return null;
  }

  return (
    <>
      <Item key="SetPasswordMenuItem" onClick={showSetPasswordModal}>
        <span>Alterar senha</span>
      </Item>

      {visible && (
        <SetPassword
          visible
          closeModal={hideSetPasswordModal}
          productId={productId}
          cardId={cardId}
          clientId={clientId}
        />
      )}
    </>
  );
}

export default memo(SetPasswordMenuItem);
