import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';

import { Button, Modal, Select } from 'antd';

import { useClient } from "../../../hooks/ClientProvider";
import useDependentCard from "../../../hooks/useDependentCard";
import useHolderCard from "../../../hooks/useHolderCard";
import api from '../../../services/api';
import { notifyError, notifySucces } from '../../../utils/notifications';
import LoadingElevaty from '../../LoadingElevaty';
import * as S from '../style';

const { Option } = Select;

function ModalCopyCard({
  isModalVisible,
  closeModal,
  card,
  isDependent,
  isBandeirado,
  codProduct,
  client_id,
  reloadCards,
  blockTypes
}) {

  const { client } = useClient();

  const {
    copyCardHolder,
  } = useHolderCard(
    client?.client?.id,
    client?.product?.codigoProduto,
    client?.client?.cpf
  );

  const {
    copyCardDdependent,
  } = useDependentCard(
    client?.client?.adicional,
    client?.product?.codigoProduto
  );
  const [blockType, setBlockType] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleBlockTypeSelection = useCallback((value) => {
    setBlockType(value);
  }, []);

  const copyCard = useCallback(
    async (idNovoCartao, idCartaoCancelado, isDependent) => {
      if (isDependent) {
        copyCardDdependent(idCartaoCancelado, idNovoCartao);
      } else {
        copyCardHolder(idCartaoCancelado, idNovoCartao);
      }
    },
    [copyCardDdependent, copyCardHolder]
  );

  const handleSecondViaSolicitation = useCallback(async () => {
    const selectedBlockType = blockTypes.find(type => type.id === blockType);
    const identity = localStorage.getItem("@SnxPayment:identity");
    try {
      setLoading(true);
      const { data } = await api.patch('card_second_copy', {
        identity,
        card_id: card.id,
        block_type_id: blockType,
        client_id,
        block_type_description: selectedBlockType?.descricao ?? null,
        card_number: card.numeroMascarado,
      });
      if (isBandeirado) {
        copyCard(data.idCartao, data.idCartaoCancelado, isDependent);
      } else {
        copyCard(data.idNovoCartao, data.idCartaoCancelado, isDependent);
      }
      closeModal();
      notifySucces('Solicitado com sucesso');
    } catch (error) {
      notifyError(error.message);
    } finally {
      reloadCards();
      setLoading(false);
    }
  }, [
    blockType,
    blockTypes,
    card,
    client_id,
    closeModal,
    copyCard,
    isDependent,
  ]);

  return (
    <Modal
      title="Solicitar Segunda Via"
      visible={isModalVisible}
      onCancel={closeModal}
    >
      {loading && (
        <S.NewLoadingModals>
          <LoadingElevaty width="40px" height="40px" duration="durFast" />
        </S.NewLoadingModals>
      )}
        <>
          <span>Tipo de bloqueio</span>
          <Select defaultValue="Selecione" onChange={handleBlockTypeSelection}>
            <Option value={0}>Selecione</Option>
            {blockTypes.filter(type => type?.cancelaCartao === true).map(type => (
              <Option key={type.id} value={type.id}>
                {type.descricao[0].toUpperCase() +
                type.descricao.slice(1).toLowerCase()}
              </Option>
            ))}
          </Select>
        </>
      <S.WrapperSelectBlock>
          <button className="solicitate"
            type="button"
            onClick={handleSecondViaSolicitation}
            disabled={loading || blockType === 0}
            style={{
              cursor: (loading || blockType === 0) ? "not-allowed" : "pointer",
              opacity: (loading || blockType === 0) ? "0.8" : "1"
            }}>Solicitar</button>
        </S.WrapperSelectBlock>
    </Modal>
  );
}

export default memo(ModalCopyCard);
