import React from 'react';

import { Container } from './style';

function Button({ children, ...rest }) {
  return (
    <Container type="button" {...rest}>
      {children}
    </Container>
  );
}

export default Button;
