import React from "react";

const IconMenuElevaty = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.46236V1.02321C5.19459 0.971097 13.989 1.02312 15.6159 1.02296C17.1791 1.02281 18.9768 2.89865 18.9768 4.46236V11.028C18.9768 9.5273 17.1791 7.66703 15.8504 7.66703H4.20456C2.57883 7.66703 1 5.71235 1 4.46236Z"
      stroke="#212733"
      stroke-width="2"
    />
    <path
      d="M15.5378 19L18.9768 19C19.0289 14.8053 18.9769 6.01057 18.977 4.38359C18.9772 2.82034 17.1014 1.02259 15.5378 1.02259L8.97232 1.02259C10.473 1.02259 12.3332 2.82034 12.3332 4.1491L12.3332 15.7953C12.3332 17.4211 14.2878 19 15.5378 19Z"
      stroke="#212733"
      stroke-width="2"
    />
  </svg>
);

export default IconMenuElevaty;
