import React, { useState, useEffect, useCallback } from "react";

import * as AntIcon from "@ant-design/icons";

import LoadingElevaty from "../LoadingElevaty";
import InputDropdown from "./FilterComponents/InputDropdown";
import UniqueDataDropdown from "./FilterComponents/UniqueDataDropdown";
import Pagination from "./Pagination";
import * as S from "./style";

const DataTable = ({
  data,
  tableName,
  loading,
  tableHeadersAndFilters,
  onUpdateItem,
  onDeleteItem,
  onRestoreItem,
  onNewItem,
  onViewItem,
  createItemButton
}) => {
  const [searchClientText, setSearchClientText] = useState({});
  const [filterVisibility, setFilterVisibility] = useState({});
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const filterData = (filterObject) => {
      const dataAfterFilter = data.filter((user) => {
        return Object.entries(filterObject).every(([key, value]) => {

          const filteredValue = Array.isArray(value)
            ? value.map((v) => String(v).trim().toLowerCase())
            : value.trim().toLowerCase();

          const userValue = String(user[key]).trim().toLowerCase();

          if (Array.isArray(filteredValue)) {
            return filteredValue.length === 0 || filteredValue.includes(userValue);
          }

          return filteredValue === "" || userValue.includes(filteredValue);
        });
      });

      return dataAfterFilter;
  }

  const handleApplyFilter = (filterObject) => {
      const filteredData = filterData(filterObject);
      setFilteredData(filteredData);
      if(filteredData.length > 0) {
        setCurrentPage(1);
      }
  }

  useEffect(() => {
    const initialSelectedDropdownOptions = tableHeadersAndFilters.reduce((acc, header) => {
      const column = Object.keys(header)[0];
      const options = header?.options;
      if (options) {
        acc[column] = Object.keys(options).filter(value => options[value]);
      }
      return acc;
    }, {});

    const initialSearchClient = tableHeadersAndFilters?.reduce((acc, currentValue) => {
      const key = Object.keys(currentValue)[0];
      acc[key] = currentValue.filterType === "uniqueData" ? initialSelectedDropdownOptions[key] : "";
      return acc;
    }, {});
    setSearchClientText(initialSearchClient);
    handleApplyFilter(initialSearchClient);
  }, [data]);


  const handleToggleFilter = (header) => {
      setFilterVisibility((prevVisibility) => {
      const newVisibility = { ...prevVisibility };

      Object.keys(newVisibility).forEach((key) => {
        if(newVisibility[key]!==header)
        newVisibility[key] = false;
      });

      newVisibility[header] = !prevVisibility[header];

      return newVisibility;
    });
  };

  const renderSelectedFilter = (headerName, dataField, mask) => {
    const columnToFilter = Object.keys(dataField)[0];
    switch(dataField.filterType) {
      case "text": {
         return (
          <InputDropdown
              labelName={headerName}
              isVisible={filterVisibility[headerName]}
              onClose={() => handleToggleFilter(headerName)}
              currentFilter={columnToFilter}
              handleFilteredData={handleApplyFilter}
              setSearchClientText={setSearchClientText}
              searchClientText={searchClientText}
              tableName={tableName}
              mask={mask ? (e) => mask(e) : null}
          />
         )
      }
      case "uniqueData": {
        return <UniqueDataDropdown
                  isVisible={filterVisibility[headerName]}
                  onClose={() => handleToggleFilter(headerName)}
                  currentFilter={columnToFilter}
                  handleFilteredData={handleApplyFilter}
                  setSearchClientText={setSearchClientText}
                  searchClientText={searchClientText}
                  options={dataField?.options}
               />
      }
      default:
        return null;
    }
  }

  const renderFilterIcon = useCallback(
    (header, dataField, nameOnJson) => {
      let color;
      if(!Array.isArray(searchClientText[nameOnJson])) {
         color = searchClientText[nameOnJson] ? "#ff9c00" : "#919090";
      } else {
        color = searchClientText[nameOnJson]?.length > 0 ? "#ff9c00" : "#919090"
      }
      switch(dataField.filterType) {
        case "text": {
          return (
            <AntIcon.SearchOutlined
              key={header}
              style={{fontSize:"1.4rem", color}}
              onClick={() => handleToggleFilter(header)}
            />
          );
        }
        case "uniqueData": {
          return (
            <AntIcon.FilterOutlined
              key={header}
              style={{fontSize:"1.4rem", color}}
              onClick={() => handleToggleFilter(header)}
            />
          );
        }
        default: return null;
      }
    },[searchClientText]
  );

  function RenderConditionalIcon({ renderComponent, icon, title, onViewFunction, item }) {
      return renderComponent ? (
        onViewFunction(item)
      ) : (
        React.createElement(icon, {
          title,
          onClick: () => onViewFunction(item),
        })
       );
    }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
        <div className="content-users__content">
          {loading ? (
            <div className="content-users__content--loading">
              <div className="content-logo">
                <LoadingElevaty duration="durFast"/>
              </div>
              <div className="content-text-logo">
                <strong>
                  <p>Carregando {tableName.toLowerCase()}</p>
                </strong>
              </div>
            </div>
          ) : (
            <>
              <table className="rwd-table">
                <tr>
                  {tableHeadersAndFilters.map((dataField, i) => {
                    const headerName = dataField[Object.keys(dataField)[0]];
                    const nameOnJson = Object.keys(dataField)[0];
                    const mask = dataField?.mask;

                    return (
                    <th colSpan="1" style={{ width: dataField?.columnWidth? dataField.columnWidth: "20%"}}>
                      <div className={headerName!=="Ações"? "table-header" : "action-header"} key={dataField}>
                        {headerName}
                        {renderFilterIcon(headerName, dataField, nameOnJson)}
                      </div>
                      {filterVisibility[headerName] ? (
                        renderSelectedFilter(headerName, dataField, mask)) : null }
                    </th>
                  )})}
                </tr>
              {currentItems.map((item) => (
                <tr key={item.id}>
                  {tableHeadersAndFilters.map((header) => (
                    <td key={header} data-th={header[Object.keys(header)[0]]}>
                      {header[Object.keys(header)[0]] === "Status" ? (
                        <p className={`status ${item.status === "Ativo" ? "" : "off"}`}>
                          {item.status}
                        </p>
                      ) : (
                        header[Object.keys(header)[0]] === "Ações" ? (
                          <div className="btns-acoes">
                            {header.allowedActions.includes("edit") &&
                              onUpdateItem && (
                               <div className={onUpdateItem?.renderComponent? "" : "btn-div"}>
                                  <RenderConditionalIcon
                                    renderComponent={onUpdateItem?.renderComponent}
                                    icon={AntIcon.EditOutlined}
                                    title="Editar"
                                    onViewFunction={onUpdateItem.action}
                                    item={item}
                                  />
                                 </div>
                            )}
                            {item.status === "Ativo" && header.allowedActions.includes("delete") &&
                             onDeleteItem && (
                               <div className={onDeleteItem.renderComponent? "" : "btn-div"}>
                                  <RenderConditionalIcon
                                    renderComponent={onDeleteItem.renderComponent}
                                    icon={AntIcon.StopOutlined}
                                    title="Desativar"
                                    onViewFunction={onDeleteItem.action}
                                    item={item}
                                  />
                                 </div>
                             )}

                            {item.status === "Inativo" && header.allowedActions.includes("restore") &&
                             onRestoreItem && (
                                <div className={onRestoreItem.renderComponent? "" : "btn-div"}>
                                  <RenderConditionalIcon
                                    renderComponent={onRestoreItem.renderComponent}
                                    icon={AntIcon.UserSwitchOutlined}
                                    title="Ativar"
                                    onViewFunction={onRestoreItem.action}
                                    item={item}
                                  />
                                </div>
                             )}

                            {header.allowedActions.includes("view") &&
                             onViewItem && (
                               <div className={onViewItem.renderComponent? "" : "btn-div"}>
                                  <RenderConditionalIcon
                                    renderComponent={onViewItem.renderComponent}
                                    icon={AntIcon.EyeOutlined}
                                    title="Visualizar"
                                    onViewFunction={onViewItem.action}
                                    item={item}
                                  />
                                 </div>
                                )}
                          </div>
                        ) : (
                          item[Object.keys(header)[0]] ? item[Object.keys(header)[0]] : ""
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              </table>
              <div className="pagination-users">
                <button
                  type="button"
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <AntIcon.LeftOutlined />
                </button>
                <Pagination
                  currentPage={currentPage}
                  totalPageCount={totalPages}
                  setCurrentPage={setCurrentPage}
                />
                <button
                  type="button"
                  disabled={currentPage === totalPages || currentItems.length===0}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <AntIcon.RightOutlined />
                </button>
              </div>
            </>
          )
        }
        </div>
    </>
  )
}

export default DataTable;
