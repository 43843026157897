// Hook
// Parameter is the boolean, with default "false" value
import { useCallback, useState } from 'react';

const useToggle = (initialState = false) => {
  // Initialize the state
  const [state, setState] = useState(initialState);

  // Define and memorize toggle function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState((prevState) => !prevState), []);

  return [state, toggle];
};

export default useToggle;
